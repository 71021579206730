import { FC } from "react";
import { Card, Divider, Stack } from "@mui/material";
import { AccountFilters } from "./AccountFilters";
import { EnableFilterTemplatesSwitcher } from "../../enable-filter-templates-switcher/EnableFilterTemplatesSwitcher";
import { FilterTemplateView } from "../../filter-templates/FilterTemplateView";
import { CreateWorkflowButton } from "../create-workflow/CreateWorkflowButton";
import { useAppSelector } from "../../../../../../store/hooks";
import { filterTemplatesEnabledSelector } from "../../../../../../store/account/selectors/current-account/resources/filterTemplatesEnabledSelector";

export const Toolbar: FC = () => {
  const filterTemplatesEnabled = useAppSelector(filterTemplatesEnabledSelector);

  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      px={1}
      borderBottom={1}
      borderColor="grey.300"
      minHeight={52}
      boxSizing="content-box"
    >
      <Card variant="outlined" sx={{ borderColor: "grey.100" }}>
        <Stack direction="row" alignItems="center">
          <EnableFilterTemplatesSwitcher />

          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "grey.100" }}
          />
          <Stack
            sx={{
              pr: 1,
              pointerEvents: filterTemplatesEnabled ? "inherit" : "none",
              opacity: filterTemplatesEnabled ? 1 : 0.5,
            }}
            direction="row"
          >
            <FilterTemplateView />

            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "grey.100" }}
            />

            <AccountFilters />
          </Stack>
        </Stack>
      </Card>
      <CreateWorkflowButton />
    </Stack>
  );
};
