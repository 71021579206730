import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { awsBillingStatusSelector } from "../awsBillingStatusSelector";
import { gcpBillingStatusSelector } from "../gcpBillingStatusSelector";
import { azureBillingStatusSelector } from "../azureBillingStatusSelector";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { allAccountsGroupedByProviderSelector } from "../../../../accounts/selectors/all-providers/allAccountsGroupedByProviderSelector";
import { PROVIDERS } from "../../../../../utils/constants/providers";

export const enabledProvidersSelector = createDraftSafeSelector(
  [
    awsBillingStatusSelector,
    gcpBillingStatusSelector,
    azureBillingStatusSelector,
    allAccountsGroupedByProviderSelector,
  ],
  (awsStatus, gcpStatus, azureStatus, accounts) => {
    const statuses = new Set<ProviderType>();

    azureStatus?.is_initial_billing_collection_in_progress !== null &&
      statuses.add("azure");
    awsStatus?.is_initial_billing_collection_in_progress !== null &&
      statuses.add("aws");
    gcpStatus?.is_initial_billing_collection_in_progress !== null &&
      statuses.add("gcp");

    const providerTypes = Object.keys(accounts || {}) as ProviderType[];
    const existingProviderTypes = providerTypes.filter(
      (provider) => accounts?.[provider]?.length,
    );

    const providers = [
      ...existingProviderTypes.map((providerType) => ({
        providerType,
        enabled: true,
      })),
      ...PROVIDERS.filter(
        (providerType) => !existingProviderTypes.includes(providerType),
      ).map((providerType) => ({
        providerType,
        enabled: false,
      })),
    ]
      .filter((p) => p.enabled)
      .map((p) => p.providerType);

    return new Set(providers);
  },
);
