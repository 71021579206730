import { isStringResourceType } from "./isStringResourceType";
import { ResourceType } from "../../../services/cloudchipr.api";
import {
  NestedResourcesHierarchy,
  nestedResourcesHierarchy,
} from "../../constants/resources/nestedResourcesHierarchy";

export const getParentResourceType = (
  resourceType: ResourceType,
): ResourceType => {
  let parent: ResourceType | null = null;

  const recursive = (
    hierarchy: NestedResourcesHierarchy,
    resourceType: ResourceType,
  ) => {
    Object.entries(hierarchy).forEach(([rt, children]) => {
      if (!isStringResourceType(rt)) {
        return;
      }

      if (!children) {
        return;
      }

      if (resourceType in children) {
        parent = rt;
        return;
      }

      return recursive(children, resourceType);
    });
  };

  recursive(nestedResourcesHierarchy, resourceType);

  return parent ?? resourceType;
};
