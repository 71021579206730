import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getDashboardsThunk } from "./getDashboardsThunk";
import { getDashboardByIdThunk } from "./getDashboardByIdThunk";
import {
  cloudChiprApi,
  PatchUsersMeOrganisationsCurrentDashboardsByDashboardIdApiArg,
} from "../../../../services/cloudchipr.api";
import { updateDashboardFixedCacheKey } from "../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";

type UpdateDashboardNameByIdThunkArgs = {
  dashboardId: string;
} & PatchUsersMeOrganisationsCurrentDashboardsByDashboardIdApiArg["body"];

export const updateDashboardNameByIdThunk = createAsyncThunk(
  "dashboards/updateDashboardNameById",
  async (
    { name, dashboardId }: UpdateDashboardNameByIdThunkArgs,
    { dispatch },
  ) => {
    const { patchUsersMeOrganisationsCurrentDashboardsByDashboardId } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        patchUsersMeOrganisationsCurrentDashboardsByDashboardId.initiate(
          {
            dashboardId,
            body: { name },
          },
          { fixedCacheKey: updateDashboardFixedCacheKey },
        ),
      ).unwrap();

      dispatch(getDashboardsThunk());
      dispatch(getDashboardByIdThunk(dashboardId));

      enqueueSnackbar("Dashboard name successfully updated.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "success",
        },
      });
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
