import { RootState } from "../../../../store";
import { DashboardWidgetCostsByCategoryV2 } from "../../../../../services/cloudchipr.api";
import { dashboardWidgetsSelector } from "../../dashboard/dashboardWidgetsSelector";

export const costByCategoryWidgetByIdSelector = (
  state: RootState,
  widgetId?: string,
): DashboardWidgetCostsByCategoryV2 | undefined => {
  if (!widgetId) {
    return;
  }

  const widgets = dashboardWidgetsSelector(state);

  return widgets?.find(
    (widget) => widget.id === widgetId,
  ) as DashboardWidgetCostsByCategoryV2;
};
