import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { getResourceExplorerViewByIdThunk } from "./getResourceExplorerViewByIdThunk";
import {
  cloudChiprApi,
  ResourceExplorerData,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { resourceExplorerProvidersSelector } from "../../selectors/current-resource-explorer/resourceExplorerProvidersSelector";
import { resourceExplorerAppliedFiltersToSaveOnViewSelector } from "../../selectors/filters/resourceExplorerAppliedFiltersToSaveOnViewSelector";

interface UpdateResourceExplorerThunkArgs {
  viewId: string;
  name: string;
  data?: ResourceExplorerData;
}
export const updateResourceExplorerThunk = createAsyncThunk(
  "resourceExplorer/updateResourceExplorer",
  async (
    { viewId, name, data }: UpdateResourceExplorerThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const {
      patchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId,
    } = cloudChiprApi.endpoints;

    const resourceExplorerProviders = resourceExplorerProvidersSelector(state);

    const mutatedFilters =
      resourceExplorerAppliedFiltersToSaveOnViewSelector(state);

    let response;
    try {
      response = await dispatch(
        patchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId.initiate(
          {
            resourceExplorerViewId: viewId,
            body: {
              data,
              name: name,
              filters: mutatedFilters || [],
              cloud_providers: resourceExplorerProviders,
            },
          },
          { fixedCacheKey: viewId },
        ),
      ).unwrap();

      if (response) {
        dispatch(getResourceExplorerViewByIdThunk(viewId));
        const snackbarId: SnackbarKey = enqueueSnackbar(
          "View successfully updated.",
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
              onClose: () => closeSnackbar(snackbarId),
            },
          },
        );
      }
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";

      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
    return response;
  },
);
