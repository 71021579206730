import { costBreakdownV2WidgetByIdSelector } from "./costBreakdownV2WidgetByIdSelector";
import { RootState } from "../../../../../store";
import { DateDataPointNullable } from "../../../../../../services/cloudchipr.api";

export const costBreakdownV2WidgetFrequencySelector = (
  state: RootState,
  widgetId: string,
): DateDataPointNullable | undefined => {
  const widgetData = costBreakdownV2WidgetByIdSelector(state, widgetId);

  return widgetData?.date_granularity;
};
