import { costBreakdownV2WidgetByIdSelector } from "./costBreakdownV2WidgetByIdSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerGrouping } from "../../../../../../services/cloudchipr.api";

export const costBreakdownV2WidgetGroupingSelector = (
  state: RootState,
  widgetId: string,
): ResourceExplorerGrouping | undefined => {
  const widgetData = costBreakdownV2WidgetByIdSelector(state, widgetId);

  return widgetData?.grouping;
};
