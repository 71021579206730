import { FC, useMemo } from "react";
import { Stack, TypographyProps } from "@mui/material";
import { CommitmentsDrawerContentInfoCard } from "../common/CommitmentsDrawerContentInfoCard";
import {
  savingsPlansPaymentOptionLabels,
  savingsPlansStateLabels,
  savingsPlansTypeLabels,
} from "../../../utils/constants/labels";
import { useAppSelector } from "../../../../../../../store/hooks";
import { selectedSavingsPlanDataSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/savings-plan/selectedSavingsPlanDataSelector";
import { TagCell } from "../../../../../../common/data-grid-cells/tag-cell/TagCell";
import { money } from "../../../../../../../utils/numeral/money";
import { AccountCell } from "../../content/data-grid/components/cells/AccountCell";
import { DatesCell } from "../../content/data-grid/components/cells/DatesCell";

export const SavingsPlanDrawerContentInfoCards: FC = () => {
  const savingsPlan = useAppSelector(selectedSavingsPlanDataSelector);

  const data = useMemo(() => {
    const type = savingsPlan ? savingsPlansTypeLabels[savingsPlan.type] : "";
    const status = savingsPlan
      ? savingsPlansStateLabels[savingsPlan?.state]
      : "";
    const paymentOption = savingsPlan
      ? savingsPlansPaymentOptionLabels[savingsPlan.payment_option]
      : "";
    const tags = savingsPlan?.tags?.length ? (
      <TagCell tags={savingsPlan.tags} widthToEllipsis={130} />
    ) : (
      "-"
    );

    return {
      Type: type,
      "Payment Option": paymentOption,
      "Linked Account": (
        <AccountCell
          TypographyProps={typographyProps}
          accountName={savingsPlan?.account?.provider_account_name}
          isRootAccount={savingsPlan?.account?.is_provider_root_account}
        />
      ),
      "Instance Family": savingsPlan?.ec2_instance_family,
      "Start Date": (
        <DatesCell
          TypographyProps={typographyProps}
          date={savingsPlan?.start_date ?? ""}
        />
      ),
      "End Date": (
        <DatesCell
          TypographyProps={typographyProps}
          date={savingsPlan?.end_date ?? ""}
        />
      ),
      Region: savingsPlan?.region,
      Status: status,
      "Total Commitment":
        savingsPlan?.total_commitment === null
          ? "N/A"
          : `${money(savingsPlan?.hourly_commitment)}/hour`,
      Tags: tags,
    };
  }, [savingsPlan]);

  if (!data) {
    return null;
  }

  return (
    <Stack direction="row" gap={1} flexWrap="wrap" p={2}>
      {Object.entries(data).map(([title, value]) => {
        const props = {
          title,
          key: title,
          width: 200,
        };

        if (typeof value === "string") {
          return <CommitmentsDrawerContentInfoCard {...props} value={value} />;
        }

        return (
          <CommitmentsDrawerContentInfoCard {...props}>
            {value}
          </CommitmentsDrawerContentInfoCard>
        );
      })}
    </Stack>
  );
};

const typographyProps: TypographyProps = {
  variant: "body1",
  fontWeight: "medium",
};
