import { FC, Fragment, useCallback, useState } from "react";
import {
  Alert,
  Link,
  Popover,
  PopoverOrigin,
  Stack,
  Typography,
} from "@mui/material";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import { ProviderType } from "../../../../../../../services/cloudchipr.api";
import { ProviderLogo } from "../../../../../../common/provider-logo/ProviderLogo";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
interface ResourceExplorerMissingAccountsAlertProps {
  accounts: {
    provider: ProviderType;
    id: string;
    name: string;
  }[];
  categoryName?: string;
}
export const ResourceExplorerMissingAccountsAlert: FC<
  ResourceExplorerMissingAccountsAlertProps
> = ({ accounts, categoryName }) => {
  const [showAlert, setShowAlert] = useState(true);
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const alertCloseHandler = useCallback(() => {
    setShowAlert(false);
  }, []);
  if (!categoryName || !showAlert) {
    return null;
  }
  return (
    <Fragment>
      <Alert severity="warning" onClose={alertCloseHandler}>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography variant="body2" flexShrink={0}>
            This view does not include all accounts in
          </Typography>
          <TypographyWithTooltip
            title={categoryName}
            fontWeight="bold"
            variant="body2"
          />
          <Typography variant="body2" flexShrink={0}>
            category.{" "}
          </Typography>{" "}
          <Link
            component="button"
            underline="always"
            variant="body2"
            sx={{ textDecorationStyle: "dashed", flexShrink: 0 }}
            color="inherit"
            onClick={openMenu}
          >
            See missing accounts.
          </Link>
        </Stack>
      </Alert>
      <Popover
        open={open}
        anchorEl={anchor}
        onClose={closeMenu}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={paperProps}
      >
        <Stack p={2} spacing={1}>
          {accounts?.map((account) => {
            return (
              <Stack
                key={account.id}
                direction="row"
                spacing={1}
                overflow="hidden"
              >
                <ProviderLogo provider={account.provider} />
                <TypographyWithTooltip
                  title={account.name ?? account.id}
                  placement="right"
                />
              </Stack>
            );
          })}
        </Stack>
      </Popover>
    </Fragment>
  );
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const paperProps = {
  style: {
    maxHeight: 400,
    maxWidth: 300,
  },
};
