import { FC } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ProviderLogo } from "../../../../../../../common/provider-logo/ProviderLogo";
import SlackLogo from "../../../../../../../../assets/images/logos/integrations/slack.svg";

interface ResourceExplorerGcpErrorBillingStatusPanelItemProps {
  error?: string;
  name?: string | null;
  providerAccountId?: string;
}
export const ResourceExplorerGcpErrorBillingStatusPanelItem: FC<
  ResourceExplorerGcpErrorBillingStatusPanelItemProps
> = ({ error, name, providerAccountId }) => {
  return (
    <Stack bgcolor="grey.100" borderRadius={4} gap={1} p={2}>
      <Stack direction="row" gap={1}>
        <ProviderLogo provider="gcp" />
        <div>
          <Typography variant="subtitle1" fontWeight="medium">
            Billing Data Error
          </Typography>
          {!!(name || providerAccountId) && (
            <Typography variant="body2">
              Project:{" "}
              <Typography
                component="span"
                variant="inherit"
                fontWeight="medium"
              >
                {name ?? providerAccountId}
              </Typography>
            </Typography>
          )}
        </div>
      </Stack>
      {error && (
        <Stack bgcolor="grey.300" p={1} borderRadius={2}>
          <Typography fontWeight="bold" variant="tiny" color="text.disabled">
            Error
          </Typography>
          <Typography variant="caption">{error}</Typography>
        </Stack>
      )}
      <Typography variant="body2">
        In order to resolve this error, please contact us via Slack, where our
        support specialists will assist you.
      </Typography>

      <Box display="flex" justifyContent="flex-end">
        <Button
          component={RouterLink}
          to="https://communityinviter.com/apps/cloudchiprcommunity/landing-page"
          variant="outlined"
          target="_blank"
          startIcon={<img src={SlackLogo} alt="slackLogo" width={16} />}
        >
          Contact Us
        </Button>
      </Box>
    </Stack>
  );
};
