import { FC } from "react";
import { Card, LinearProgress } from "@mui/material";
import { CostBreakdownSetupSubHeader } from "./CostBreakdownSetupSubHeader";
import { CostBreakdownWidgetSetupChartView } from "./CostBreakdownWidgetSetupChartView";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { costBreakdownGetWidgetDataLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownGetWidgetDataLoadingSelector";
import { resourceExplorerByIdLoadingSelector } from "../../../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerByIdLoadingSelector";
import { widgetSetupContentChartHeight } from "../../../../../../utils/constants/stylings";
import { WidgetSetupContentWrapper } from "../../common/WidgetSetupContentWrapper";
import { CostBreakdownWidgetTableView } from "../../../../../widgets/cost-breakdown/components/chart/table/CostBreakdownWidgetTableView";
import { CostBreakdownWidgetNumeralView } from "../../../../../widgets/cost-breakdown/components/chart/numeral/CostBreakdownWidgetNumeralView";

export const CostBreakdownWidgetSetupContent: FC = () => {
  const dataLoading = useAppSelector(costBreakdownGetWidgetDataLoadingSelector);
  const setupChartType = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("viewType"),
  );
  const viewId = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("viewId"),
  );
  const resourceExplorerViewLoading = useAppSelector((state) =>
    resourceExplorerByIdLoadingSelector(state, viewId),
  );
  const viewChangeLoading = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("loading"),
  );

  const chartType = setupChartType ?? "line";

  const loading =
    dataLoading || resourceExplorerViewLoading || viewChangeLoading;

  return (
    <WidgetSetupContentWrapper>
      <Card
        elevation={3}
        sx={{
          height: widgetSetupContentChartHeight,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading && <LinearProgress />}
        <CostBreakdownSetupSubHeader />

        {chartType === "table" && <CostBreakdownWidgetTableView />}
        {chartType === "numeral" && <CostBreakdownWidgetNumeralView />}

        {!["numeral", "table"].includes(chartType) && (
          <CostBreakdownWidgetSetupChartView />
        )}
      </Card>
    </WidgetSetupContentWrapper>
  );
};
