import { postUsersMeOrganisationsCurrentWidgetsCostByCategorySelector } from "../data/postUsersMeOrganisationsCurrentWidgetsCostByCategorySelector";
import { RootState } from "../../../../../store";

export const costByCategoryWidgetLoadingSelector = (
  state: RootState,
  id: string,
): boolean => {
  const response =
    postUsersMeOrganisationsCurrentWidgetsCostByCategorySelector(id)(state);

  return !!response?.isLoading;
};
