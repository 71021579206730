import { FC, Fragment, useCallback, useMemo } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { currentAccountSelectedResourcesByResourceTypeSelector } from "../../../../../../../../../../../store/account/selectors/current-account/resources/selection/currentAccountSelectedResourcesByResourceTypeSelector";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import { currentAccountCleanDisabledSelector } from "../../../../../../../../../../../store/account/selectors/current-account/currentAccountCleanDisabledSelector";
import { useAppAbility } from "../../../../../../../../../../../services/permissions";

interface ProtectionMenuItemsProps {
  resourceType: ResourceType;
  onClose(): void;
  openProtectDialog(): void;
  setProtectAction(type: "protect" | "unprotect"): void;
}

export const ProtectionMenuItems: FC<ProtectionMenuItemsProps> = ({
  openProtectDialog,
  resourceType,
  onClose,
  setProtectAction,
}) => {
  const { cannot } = useAppAbility();
  const cleanDisabled = useAppSelector(currentAccountCleanDisabledSelector);
  const canNotCleanResource = cannot("protect", "resource");
  const selectedResources = useAppSelector((state) =>
    currentAccountSelectedResourcesByResourceTypeSelector(state, resourceType),
  );

  const selectedResourcesLength = selectedResources?.length;
  const { hideProtect, hideUnProtect } = useMemo(() => {
    return {
      hideProtect: selectedResources?.every(
        ({ resource }) => resource.is_protected,
      ),
      hideUnProtect: selectedResources?.every(
        ({ resource }) => !resource.is_protected,
      ),
    };
  }, [selectedResources]);

  const onProtectItemClick = useCallback(() => {
    setProtectAction("protect");
    onClose();
    openProtectDialog();
  }, [onClose, setProtectAction, openProtectDialog]);

  const onUnprotectItemClick = useCallback(() => {
    setProtectAction("unprotect");
    onClose();
    openProtectDialog();
  }, [onClose, setProtectAction, openProtectDialog]);

  return (
    <Fragment>
      {!hideProtect && (
        <MenuItem
          dense
          onClick={onProtectItemClick}
          disabled={
            cleanDisabled || canNotCleanResource || !selectedResourcesLength
          }
        >
          <ListItemIcon>
            <LockOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Protect</ListItemText>
        </MenuItem>
      )}
      {!hideUnProtect && (
        <MenuItem
          dense
          onClick={onUnprotectItemClick}
          disabled={
            cleanDisabled || canNotCleanResource || !selectedResourcesLength
          }
        >
          <ListItemIcon>
            <LockOpenIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Unprotect</ListItemText>
        </MenuItem>
      )}
    </Fragment>
  );
};
