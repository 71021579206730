import { RootState } from "../../../../../store";
import { costBreakdownV2WidgetByWidgetIdSelector } from "../widget-view/costBreakdownV2WidgetByWidgetIdSelector";

export const costBreakdownV2WidgetByWidgetIdLoadingSelector = (
  state: RootState,
  widgetId: string,
): boolean => {
  const response = costBreakdownV2WidgetByWidgetIdSelector({ widgetId })(state);

  return !!response?.isLoading;
};
