import { useCallback, useMemo } from "react";
import {
  useGetUsersMeCurrentPreferenceByKeyQuery,
  usePatchUsersMeCurrentPreferenceByKeyMutation,
  usePostUsersMeCurrentPreferenceMutation,
} from "../../../../../services/cloudchipr.api";
import { ResourceExplorerChartType } from "../../../../../store/resource-explorer/utils/types/resourceExplorer";

export const getResourceExplorerPreferencesKey = (viewId: string) => {
  return `c8r:ResourceExplorer:${viewId}`;
};
export function useResourceExplorerPreferences(viewId: string) {
  const key = getResourceExplorerPreferencesKey(viewId);
  const [createPreferences] = usePostUsersMeCurrentPreferenceMutation();
  const [updatePreferences] = usePatchUsersMeCurrentPreferenceByKeyMutation();

  const { data, refetch } = useGetUsersMeCurrentPreferenceByKeyQuery({ key });
  const preferencesExit = !!data?.value;

  const preferences = useMemo(() => {
    return (data?.value ? JSON.parse(data?.value) : {}) as PreferencesType;
  }, [data]);

  const changeHandler = useCallback(
    async (data: PreferencesType) => {
      const value = JSON.stringify(data);

      if (preferencesExit) {
        await updatePreferences({ key, body: { value } });
      } else {
        await createPreferences({ body: { key, value } });
      }

      refetch();
    },
    [key, preferencesExit, refetch, createPreferences, updatePreferences],
  );

  const changeChartPreferences = useCallback(
    (key: string, value: any) => {
      changeHandler({
        ...preferences,
        chart: { ...(preferences.chart ?? {}), [key]: value },
      });
    },
    [preferences, changeHandler],
  );

  return {
    chartPreferences: preferences?.chart,
    changeChartPreferences,
  };
}

type PreferencesType = {
  chart: { type: ResourceExplorerChartType };
};
