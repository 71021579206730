import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { NodePool } from "../../../../../../../../../../../../../../services/cloudchipr.api";
import { totalCostChipCellParentStyles } from "../../../../../cells/TotalCostChipCell";

export const nodePoolColumns: ColumnSetupType<NodePool>[] = [
  { accessorKey: "name", header: "Name", type: "noWrapText" },
  {
    id: "cardTableFilteredResults",
    header: "Count",
    type: "cardTableFilteredResults",
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "nodeGroupTotalCostChip",
    meta: {
      cellStyles: totalCostChipCellParentStyles,
    },
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
];
