import { RootState } from "../../../../../store";
import { ResourceType } from "../../../../../../services/cloudchipr.api";

export const currentAccountSelectedGroupedRowsIdsByResourceTypeSelector = (
  state: RootState,
  resourceType?: ResourceType,
): Record<string, Record<string, boolean>> | undefined => {
  if (!resourceType) {
    return;
  }
  return state.account.selected[resourceType];
};
