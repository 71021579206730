import { FC, memo, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { AccountSelect } from "../../../../common/account-select/AccountSelect";
import { allAccountsSelectOptionsSelector } from "../../../../../store/accounts/selectors/all-providers/allAccountsSelectOptionsSelector";
import { getFilterTemplatesByAccountIdThunk } from "../../../../../store/filters/thunks/filter-set/getFilterTemplatesByAccountIdThunk";

interface SchedulesAccountsProps {
  provider?: ProviderType;
  disabled: boolean;
  roDisabled?: boolean;
  onChange: (ids: string[]) => void;
  accountIds?: string[];
}

export const SchedulesAccounts: FC<SchedulesAccountsProps> = memo(
  ({ accountIds, disabled, onChange, roDisabled, provider }) => {
    const firstAccountId = accountIds?.at(0);

    const dispatch = useAppDispatch();
    const groupedAllAccounts = useAppSelector(allAccountsSelectOptionsSelector);

    const options = useMemo(() => {
      return groupedAllAccounts
        .filter(({ accountType }) => accountType === provider)
        .map((data) => ({
          ...data,
          disabled:
            data.disabled ||
            (roDisabled && data.accessType === "read") ||
            data.status !== "connected",
        }));
    }, [groupedAllAccounts, provider, roDisabled]);

    useEffect(() => {
      firstAccountId &&
        dispatch(
          getFilterTemplatesByAccountIdThunk({
            accountId: firstAccountId,
            forceRefetch: true,
          }),
        );
    }, [dispatch, firstAccountId]);

    return (
      <AccountSelect
        listWidth={513}
        disabled={disabled}
        onChange={onChange}
        options={options}
        selectedValue={accountIds || []}
        wrapperVariant="popover"
        PopoverProps={{
          extendAnchorWidth: true,
        }}
      />
    );
  },
);
