import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAccountFiltersV2Thunk } from "./getAccountFiltersThunk";
import { AccountId } from "../../../services/cloudchipr.api";
import { setAppliedFilters, setAppliedFilterSetId } from "../filtersSlice";

export const applyAccountFiltersThunk = createAsyncThunk(
  "applyAccountFilters",
  async (accountId: AccountId, { dispatch }) => {
    if (!accountId) {
      return;
    }

    const filtersResponse = await dispatch(
      getAccountFiltersV2Thunk(accountId),
    ).unwrap();

    const filters = filtersResponse?.filters || [];

    dispatch(setAppliedFilterSetId(filtersResponse?.filter_set_id || null));

    if (filters) {
      filters.forEach((filters) => {
        dispatch(
          setAppliedFilters({
            accountId,
            filters: filters,
          }),
        );
      });
    }

    return true;
  },
);
