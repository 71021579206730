import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchLargestCostChangesWidgetDataBySetupThunk } from "./fetchLargestCostChangesWidgetDataBySetupThunk";
import { RootState } from "../../../../store";
import { setWidgetSetup } from "../../../dashboardsSlice";
import { LargestCostChangesSetupType } from "../../../utils/types/setups/largestCostChangesSetupType";
import { largestCostChangesSetupSelector } from "../../../selectors/setups/larges-cost-changes/largestCostChangesSetupSelector";

export const largestCostChangesSetupChangeThunk = createAsyncThunk(
  "dashboards/largestCostChangesSetupChange",
  async (
    setup: Partial<LargestCostChangesSetupType>,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const existingSetup = largestCostChangesSetupSelector(state);

    if (!existingSetup) {
      return;
    }

    const newSetup = { ...existingSetup };

    let needToFetch = false;

    if (setup.id) {
      newSetup.id = setup.id;
    }

    if (setup.name !== undefined) {
      newSetup.name = setup.name;
    }

    if (setup.viewId) {
      newSetup.viewId = setup.viewId;
    }
    if (setup.priceDirection) {
      needToFetch = true;
      newSetup.priceDirection = setup.priceDirection;
    }
    if (setup.grouping) {
      needToFetch = true;
      newSetup.grouping = setup.grouping;
      newSetup.group_values = setup.group_values ?? null;
    }
    if (setup.sortingBy) {
      needToFetch = true;
      newSetup.sortingBy = setup.sortingBy;
    }
    if (setup.itemsCount) {
      needToFetch = true;
      newSetup.itemsCount = setup.itemsCount;
    }
    if (setup.minCostChange) {
      needToFetch = setup.minCostChange?.value !== null;
      newSetup.minCostChange = setup.minCostChange;
    }
    if (setup.minPercentageChange) {
      needToFetch = setup.minPercentageChange?.value !== null;
      newSetup.minPercentageChange = setup.minPercentageChange;
    }

    if (setup.grouping) {
      needToFetch = true;
      newSetup.grouping = setup.grouping;
    }

    if (setup.date) {
      needToFetch = true;
      newSetup.date = setup.date;
    }

    dispatch(setWidgetSetup(newSetup));

    if (needToFetch) {
      dispatch(fetchLargestCostChangesWidgetDataBySetupThunk());
    }
  },
);
