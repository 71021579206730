import { FC, memo, useCallback } from "react";
import {
  Autocomplete,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import {
  getTimeZoneObject,
  timeZonesList,
} from "../../../../../../utils/helpers/date-time/timeZones";

interface TimeZonesProps {
  timeZone: string;
  setFieldValue(key: string, value: string): void;
}

export const TimeZones: FC<TimeZonesProps> = memo(
  ({ timeZone, setFieldValue }) => {
    const activeTimeZone = getTimeZoneObject(timeZone);

    const onChangeHandler = useCallback(
      (e: any) => {
        setFieldValue("timeZone", e.target.dataset.tzCode);
      },
      [setFieldValue],
    );

    const PopperComponent = useCallback(
      (props: any) => (
        <Popper {...props} style={{ width: 600 }} placement="top" />
      ),
      [],
    );

    return (
      <Stack direction="row" alignItems="center" mt={4}>
        <LanguageIcon color="primary" />
        <Typography variant="body2" mx={1} color="text.disabled">
          Time Zone:
        </Typography>
        <Autocomplete
          freeSolo
          disablePortal
          disableClearable
          clearOnBlur
          size="small"
          onChange={onChangeHandler}
          value={activeTimeZone}
          getOptionLabel={(option: any) => option.name}
          sx={{ overflow: "unset" }}
          renderOption={(props, option) => (
            <Typography variant="body2" data-tz-code={option.tzCode} {...props}>
              {option.name}
            </Typography>
          )}
          PopperComponent={PopperComponent}
          options={timeZonesList}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ minWidth: 220 }}
              placeholder="Search"
              variant="standard"
            />
          )}
        />
      </Stack>
    );
  },
);
