import { FC } from "react";
import { TypographyProps } from "@mui/material";
import { getTimeZoneObject } from "../../../../../../utils/helpers/date-time/timeZones";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { cutScheduleNextRunDateTimeZoneName } from "../utils/helpers";

interface ScheduledNextRunDateTimeZoneProps extends TypographyProps {
  timeZone?: string;
}

export const ScheduledNextRunDateTimeZone: FC<
  ScheduledNextRunDateTimeZoneProps
> = ({ timeZone, ...props }) => {
  return (
    <TypographyWithTooltip
      title={cutScheduleNextRunDateTimeZoneName(
        getTimeZoneObject(timeZone).name,
      )}
      variant="caption"
      color="text.secondary"
      {...props}
    />
  );
};
