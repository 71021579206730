import { costBreakdownSetupPropertyByKeySelector } from "./costBreakdownSetupPropertyByKeySelector";
import { RootState } from "../../../../store";
import { costBreakdownWidgetEditChangesExistSelector } from "../../widgets/cost-breakdown/costBreakdownWidgetEditChangesExistSelector";

export const costBreakdownSubmitButtonDisabledSelector = (
  state: RootState,
): boolean => {
  const editDisabledByChange =
    costBreakdownWidgetEditChangesExistSelector(state);
  const viewId = costBreakdownSetupPropertyByKeySelector("viewId")(state);
  const name = costBreakdownSetupPropertyByKeySelector("name")(state);
  const id = costBreakdownSetupPropertyByKeySelector("id")(state);

  return (id && !editDisabledByChange) || !name || !viewId;
};
