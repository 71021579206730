import { FC } from "react";
import { Box } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { MultiTypeChartProvider } from "../../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { CostBreakdownV2WidgetSetupContentCosts } from "../components/CostBreakdownV2WidgetSetupContentCosts";
import { CostBreakdownV2WidgetChart } from "../components/chart-view/CostBreakdownV2WidgetChart";
import { costBreakdownV2WidgetSetupChartDataSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupChartDataSelector";
import { costBreakdownV2WidgetSetupTotalCostDateDetailsSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupTotalCostDateDetailsSelector";
import { costBreakdownV2WidgetSetupLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupLoadingSelector";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";

export const CostBreakdownV2WidgetSetupChartView: FC = () => {
  const data = useAppSelector(costBreakdownV2WidgetSetupChartDataSelector);
  const dateDataPoint = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("frequency"),
  );

  const dateDetails = useAppSelector(
    costBreakdownV2WidgetSetupTotalCostDateDetailsSelector,
  );
  const loading = useAppSelector(costBreakdownV2WidgetSetupLoadingSelector);

  const chartType =
    useAppSelector(costBreakdownV2SetupPropertyByKeySelector("viewType")) ??
    "line";

  return (
    <MultiTypeChartProvider
      value={{
        data: data ?? [],
        initialChartType: chartType,
      }}
    >
      <Box p={2}>
        <CostBreakdownV2WidgetSetupContentCosts />
        <CostBreakdownV2WidgetChart
          dateDataPoint={dateDataPoint}
          chartType={chartType}
          dateDetails={dateDetails}
          loading={!data.length && loading}
        />
      </Box>
    </MultiTypeChartProvider>
  );
};
