import { RootState } from "../../store";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { scheduledJobsPatchFixedCacheKey } from "../thunks/toggleScheduledJobsStatusThunk";

export const scheduleStatusToggleLoadingSelector = (state: RootState) => {
  const isPatchLoading =
    cloudChiprApi.endpoints.patchV2UsersMeSchedulesByScheduleId.select(
      scheduledJobsPatchFixedCacheKey,
    )(state)?.isLoading;
  const isGetLoading = cloudChiprApi.endpoints.getV2UsersMeSchedules.select({})(
    state,
  )?.isLoading;

  return isPatchLoading || isGetLoading;
};
