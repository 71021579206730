import { FC, Fragment } from "react";
import { Box, Divider, LinearProgress, Stack, Typography } from "@mui/material";
import { ExecutionLogsFilters } from "./filters/ExecutionLogsFilters";
import { useAppSelector } from "../../../../../store/hooks";
import { executionLogDataLoadingSelector } from "../../../../../store/execution-log/selectors/executionLogDataLoadingSelector";
import { NavigationExpander } from "../../../common/navigation-expander/NavigationExpander";

export const ExecutionLogsHeader: FC = () => {
  const isLoading = useAppSelector(executionLogDataLoadingSelector);

  return (
    <Fragment>
      <Box
        top={0}
        p={2}
        bgcolor="white"
        borderBottom={1}
        position="sticky"
        borderColor="grey.300"
        zIndex={(theme) => theme.zIndex.drawer - 1}
      >
        <Stack direction="row" alignItems="center" mb={2} spacing={1}>
          <NavigationExpander />
          <Typography variant="body1">Execution Logs</Typography>
        </Stack>

        <Divider />

        <ExecutionLogsFilters />
      </Box>

      {isLoading && <LinearProgress />}
    </Fragment>
  );
};
