import { costBreakdownV2WidgetSetupDataPayloadSelector } from "../setup/costBreakdownV2WidgetSetupDataPayloadSelector";
import { costBreakdownV2WidgetSetupSelector } from "../setup/costBreakdownV2WidgetSetupSelector";
import { RootState } from "../../../../../store";

export const costBreakdownV2WidgetSetupDataGettingLoadingSelector = (
  state: RootState,
): boolean => {
  const payload = costBreakdownV2WidgetSetupDataPayloadSelector(state);
  if (!payload) {
    return false;
  }
  const response = costBreakdownV2WidgetSetupSelector(payload)(state);

  return response.isLoading;
};
