import { FC, useCallback } from "react";
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { usePostUsersMeProvidersAwsAccountsByAccountIdCfnChangeSetsMutation } from "../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { currentAccountIdSelector } from "../../../../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import { useAppAbility } from "../../../../../../../../services/permissions";

export const AwsMissingPermissionsApplyButton: FC = () => {
  const { can } = useAppAbility();
  const canEditAccount = can("edit", "account");
  const { enqueueSnackbar } = useSnackbar();

  const currentAccountId = useAppSelector(currentAccountIdSelector);

  const [getChangeSetLink, { isLoading }] =
    usePostUsersMeProvidersAwsAccountsByAccountIdCfnChangeSetsMutation();

  const handleGoToAWSClick = useCallback(async () => {
    if (!currentAccountId) {
      return;
    }

    try {
      const linkResult = await getChangeSetLink({
        accountId: currentAccountId,
      }).unwrap();

      const link = linkResult?.link;
      window.open(link, "_blank");
    } catch (e) {
      // @ts-expect-error // todo: api should be fixed
      enqueueSnackbar(e?.data?.error ?? "Something went wrong", {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });
    }
  }, [enqueueSnackbar, currentAccountId, getChangeSetLink]);

  return (
    <Stack direction="row" spacing={2}>
      <LoadingButton
        onClick={handleGoToAWSClick}
        loading={isLoading}
        disabled={!canEditAccount}
        variant="contained"
        autoFocus
        size="small"
      >
        Go to AWS console
      </LoadingButton>
    </Stack>
  );
};
