import { RootState } from "../../../../store";
import { PostUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse } from "../../../../../services/cloudchipr.api";
import { widgetREViewDataSelector } from "../common/widget-re-view/widgetREViewDataSelector";

export const costBreakdownWidgetDataSelector = (
  state: RootState,
  widgetId?: string,
):
  | PostUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse
  | undefined => {
  return widgetREViewDataSelector(state, widgetId);
};
