import {
  MouseEvent,
  Fragment,
  useCallback,
  useState,
  useEffect,
  useMemo,
  FC,
} from "react";
import { Popover } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { ResourceExplorerCreditTypeFilterContent } from "./components/ResourceExplorerCreditTypeFilterContent";
import {
  ResourceExplorerFilterItem,
  ProviderType,
} from "../../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { resourceExplorerFilterByKeyAndProviderSelector } from "../../../../../../../../../../store/resource-explorer/selectors/filters/resourceExplorerFilterByKeyAndProviderSelector";
import { FilterButton } from "../../../../FilterButton";
import { resourceExplorerIsDefaultSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerIsDefaultSelector";
import { filterChangedByKeySelector } from "../../../../../../../../../../store/resource-explorer/selectors/filters/checkers/filterChangedByKeySelector";
import { creditTypeFilterChangeThunk } from "../../../../../../../../../../store/resource-explorer/thunks/filters/creditTypeFilterChangeThunk";
import { isDeepEqual } from "../../../../../../../../../../utils/is-deep-equal";
import { resourceExplorerPossibleFiltersByProviderSelector } from "../../../../../../../../../../store/resource-explorer/selectors/filters/possible-filters/resourceExplorerPossibleFiltersByProviderSelector";
import { dbSelectedCreditTypeSelector } from "../../../../../../../../../../store/resource-explorer/selectors/filters/selected/dbSelectedCreditTypeSelector";

interface ResourceExplorerCreditTypeFilterProps {
  viewId: string;
  provider: ProviderType;
}
export const ResourceExplorerCreditTypeFilter: FC<
  ResourceExplorerCreditTypeFilterProps
> = ({ viewId, provider }) => {
  const dispatch = useAppDispatch();

  const [filter, setFilter] = useState<ResourceExplorerFilterItem | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isViewDefault = useAppSelector(resourceExplorerIsDefaultSelector);
  const initialFilter = useAppSelector((state) =>
    resourceExplorerFilterByKeyAndProviderSelector(
      state,
      "credit_type",
      provider,
    ),
  );

  const isFilterChanged = useAppSelector((state) =>
    filterChangedByKeySelector(state, {
      viewId,
      key: "credit_type",
      provider,
    }),
  );

  const possibleFilters = useAppSelector((state) =>
    resourceExplorerPossibleFiltersByProviderSelector(state, provider),
  )?.credit_type;

  const selectedFilter = useAppSelector((state) =>
    dbSelectedCreditTypeSelector(state, provider),
  );

  const isAllSelected =
    possibleFilters?.values?.length === filter?.value?.length;

  const filterButtonCount = useMemo(() => {
    if (!filter?.value || isAllSelected) {
      return "";
    }

    const existingFilterCount = filter?.value?.length;
    return existingFilterCount ? `(${existingFilterCount})` : "";
  }, [filter, isAllSelected]);

  const popoverCloseHandler = useCallback(() => {
    setAnchorEl(null);

    const isEqual =
      filter && initialFilter && isDeepEqual(filter, initialFilter);

    if (!isEqual && filter) {
      dispatch(creditTypeFilterChangeThunk({ filter, provider }));
    }
  }, [dispatch, filter, initialFilter, provider]);

  const filterButtonClickHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  useEffect(() => {
    if (initialFilter) {
      setFilter(initialFilter);
    }
  }, [setFilter, initialFilter]);

  useEffectOnceWhen(() => {
    if (selectedFilter.value?.length) {
      setFilter(selectedFilter);
    }
  }, !!selectedFilter && !!possibleFilters);

  if (!possibleFilters) {
    return null;
  }

  return (
    <Fragment>
      <FilterButton
        title="Discounts"
        variant={
          isFilterChanged || filter?.value?.length ? "contained" : "outlined"
        }
        onClick={filterButtonClickHandler}
        color={!isViewDefault && isFilterChanged ? "warning" : undefined}
        count={filterButtonCount}
      />

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={popoverCloseHandler}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{ sx: { width: 350 } }}
        sx={{ p: 0 }}
      >
        <ResourceExplorerCreditTypeFilterContent
          setFilter={setFilter}
          filter={filter}
          provider={provider}
        />
      </Popover>
    </Fragment>
  );
};
