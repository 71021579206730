import { FC, Fragment, useCallback } from "react";
import { Chip, Link, Popover, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import {
  UsageDataType,
  UsageType,
} from "../../../utils/types/integration-usage";
interface InUseCellUsageChipProps {
  usageType: UsageType;
  usages: UsageDataType[];
}

export const InUseCellUsageChip: FC<InUseCellUsageChipProps> = ({
  usageType,
  usages,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const generateLink = useCallback((usage: UsageDataType) => {
    if (usage.type === "budget") {
      return `/budgets/edit/${usage.id}`;
    }

    const type = usage.type === "offHours" ? "off-hours" : "classic";

    return `/schedule/${type}?preview=${usage?.id}`;
  }, []);

  if (!usages.length) {
    return null;
  }

  return (
    <Fragment>
      <Chip
        icon={usageIcons[usageType]}
        size="small"
        variant={open ? "filled" : "outlined"}
        onClick={openMenu}
        label={
          <Typography fontSize="inherit">
            {usageLabels[usageType]} ({usages.length})
          </Typography>
        }
      />

      <Popover
        open={open}
        anchorEl={anchor}
        onClose={closeMenu}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        <Stack py={1} px={2} spacing={1} minWidth={190}>
          {usages.map((usage) => (
            <Link
              key={usage.uniqueKey}
              color="text.primary"
              target="_blank"
              component={RouterLink}
              to={generateLink(usage)}
            >
              <TypographyWithTooltip
                title={usage.name}
                maxWidth={200}
                variant="body2"
              />
            </Link>
          ))}
        </Stack>
      </Popover>
    </Fragment>
  );
};

const usageLabels = {
  offHours: "Off Hours",
  schedule: "Workflow",
  budget: "Budget",
};

const usageIcons = {
  offHours: <AvTimerIcon />,
  schedule: <AvTimerIcon />,
  budget: <PaidOutlinedIcon />,
};
