import { FC, Fragment, memo } from "react";
import { Divider } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { IntegrationMenuItems } from "./integration-menu-items/IntegrationMenuItems";
import { CleanActionItems } from "./clean-actions/CleanActionItems";
import { ProtectActionItem } from "./protect/ProtectActionItem";
import { CreateWorkflowMenuItem } from "./CreateWorkflowMenuItem";
import {
  RdsSnapshot,
  ResourceType,
} from "../../../../../../../../../../../../services/cloudchipr.api";

interface ActionsMenuCellProps {
  resourceType: ResourceType;
  id: string;
  uniqueIdentifier: string;
  isProtected: boolean;
  inProgress?: boolean;
  unavailableActions?: string[];
  type?: RdsSnapshot["type"];
  closeMenu(): void;
  openProtectDialog(): void;
}

export const ActionsMenuItems: FC<ActionsMenuCellProps> = memo(
  ({
    id,
    resourceType,
    isProtected,
    unavailableActions,
    inProgress,
    closeMenu,
    openProtectDialog,
    uniqueIdentifier,
  }) => {
    const enableWorkflowCreationFromAccountPage = useFlag(
      "EnableWorkflowCreationFromAccountPage",
    );

    return (
      <Fragment>
        <CleanActionItems
          id={id}
          closeMenu={closeMenu}
          inProgress={inProgress}
          isProtected={isProtected}
          resourceType={resourceType}
          unavailableActions={unavailableActions}
        />

        <IntegrationMenuItems resourceType={resourceType} id={id} />

        <Divider />

        {enableWorkflowCreationFromAccountPage && (
          <CreateWorkflowMenuItem
            onClose={closeMenu}
            resourceType={resourceType}
            uniqueIdentifier={uniqueIdentifier}
          />
        )}

        <ProtectActionItem
          onOpen={openProtectDialog}
          isProtected={isProtected}
        />
      </Fragment>
    );
  },
);
