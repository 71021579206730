import { FC } from "react";
import { Stack } from "@mui/material";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { ProviderLogo } from "../../../../../common/provider-logo/ProviderLogo";

interface GroupingByTagValueOptionProps {
  value: string;
  provider: ProviderType;
}

export const GroupingByTagValueOption: FC<GroupingByTagValueOptionProps> = ({
  value,
  provider,
}) => {
  return (
    <Stack direction="row" justifyContent="space-between" pr={1}>
      <TypographyWithTooltip title={value} variant="inherit">
        {value}
      </TypographyWithTooltip>
      <ProviderLogo provider={provider} />
    </Stack>
  );
};
