import { getWidgetDataByViewIdSelector } from "./getWidgetDataByViewIdSelector";
import { RootState } from "../../../../../store";
import { getFetchWidgetViewDataThunkFixedCacheKey } from "../../../../utils/helpers/fixedCahcheKeys";

export const widgetREViewDataLoadingSelector = (
  state: RootState,
  widgetId: string,
): boolean => {
  const response = getWidgetDataByViewIdSelector(
    getFetchWidgetViewDataThunkFixedCacheKey(widgetId),
  )(state);

  return !!response?.isLoading;
};
