import { coverageCSVDataSelector } from "./coverageCSVDataSelector";
import { savingsPlansCSVDataSelector } from "./savingsPlansCSVDataSelector";
import { RootState } from "../../../../../store";
import { utilizationAndCoverageDataViewTabSelector } from "../../view-options/utilizationAndCoverageDataViewTabSelector";

export const commitmentsCSVDataSelector = (state: RootState) => {
  const coverageCSV = coverageCSVDataSelector(state);
  const savingsCSV = savingsPlansCSVDataSelector(state);
  const tab = utilizationAndCoverageDataViewTabSelector(state);

  if (tab === "coverage") {
    return coverageCSV;
  }

  if (tab === "savingsPlans") {
    return savingsCSV;
  }

  return null;
};
