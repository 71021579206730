import { RootState } from "../../../../store";
import { commitmentsRecommendationsPayloadDataSelector } from "../commitmentsRecommendationsPayloadDataSelector";
import { SavingsPlanPaymentOption } from "../../../../../services/cloudchipr.api";

export const commitmentsRecommendationsPaymentOptionSelector = (
  state: RootState,
): SavingsPlanPaymentOption | undefined => {
  const data = commitmentsRecommendationsPayloadDataSelector(state);

  return data.payment_option;
};
