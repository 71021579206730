import { FC, Fragment } from "react";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { CountChipWithTooltip } from "./CountChipWithTooltip";

export const GroupingByTagValuesTriggerEndAdornment: FC<{
  open: boolean;
  values?: string[];
}> = ({ open, values }) => {
  return (
    <Fragment>
      {!!values?.length && <CountChipWithTooltip values={values} />}
      {open ? (
        <ArrowDropUpOutlinedIcon color="action" />
      ) : (
        <ArrowDropDownOutlinedIcon color="action" />
      )}
    </Fragment>
  );
};
