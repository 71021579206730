import { createAsyncThunk } from "@reduxjs/toolkit";
import { AccountId, cloudChiprApi } from "../../../services/cloudchipr.api";

export const getCurrentAccountThunk = createAsyncThunk(
  "account/getCurrentAccountThunk",
  async (accountId: AccountId, { dispatch }) => {
    await dispatch(
      cloudChiprApi.endpoints.getUsersMeAccountsByAccountId.initiate(
        {
          accountId,
        },
        {
          forceRefetch: true,
        },
      ),
    );
  },
);
