import { generateApplyFilterSetToAccountFixedCacheKey } from "./utils/helpers/fixedCacheKeyGenerator";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const postUsersMeAccountsByAccountIdFiltersV2Selector = (
  state: RootState,
  filterSetId: string,
) =>
  cloudChiprApi.endpoints.postUsersMeAccountsByAccountIdFiltersV2.select(
    generateApplyFilterSetToAccountFixedCacheKey(filterSetId),
  )(state);
