import {
  costByCategoryChartPrefixes,
  multiStackChartPrefixSeparator,
} from "../../../../../../storybook/charts/multi-type-chart/components/chart-by-type/multi-stack-chart/utils/constants/constants";
import { ChartDataType } from "../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { PostUsersMeOrganisationsCurrentWidgetsCostByCategoryApiResponse } from "../../../../../../services/cloudchipr.api";
import { chartDataPointKey } from "../../../../../../storybook/charts/multi-type-chart/utils/constants/constants";

export const costByCategoryChartDataCombiner = (
  data: PostUsersMeOrganisationsCurrentWidgetsCostByCategoryApiResponse["categories_data"],
  addFilteredResources: boolean,
) => {
  return data.reduce(
    (acc, category) => {
      const key = category.category;

      category.data_points.forEach((point) => {
        if (!point.date) {
          return acc;
        }

        const date = point.date;

        const existingData = acc[date] ?? { [chartDataPointKey]: date };

        if (addFilteredResources) {
          const primaryPrefix = costByCategoryChartPrefixes.primary;
          const secondaryPrefix = costByCategoryChartPrefixes.secondary;

          const totalCostKey = `${primaryPrefix}${multiStackChartPrefixSeparator}${key}`;
          const filteredResourcesKey = `${secondaryPrefix}${multiStackChartPrefixSeparator}${key}`;

          acc[date] = {
            ...existingData,
            [filteredResourcesKey]: point.filtered_resources_cost ?? 0,
            [totalCostKey]: point.cost ?? 0,
          } as unknown as ChartDataType;
        } else {
          acc[date] = {
            ...existingData,
            [key]: point.cost ?? 0,
          };
        }
      });

      return acc;
    },
    {} as Record<string, ChartDataType>,
  );
};
