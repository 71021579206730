import { FC, Fragment, MouseEvent, ReactNode } from "react";
import { SxProps } from "@mui/system";
import { Button, Theme, Typography } from "@mui/material";
import { ButtonProps } from "@mui/material/Button/Button";
import { orange } from "@mui/material/colors";
import { ArrowDropDown } from "@mui/icons-material";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";

interface FilterButtonProps extends ButtonProps {
  title?: string;
  variant: ButtonProps["variant"];
  disabled?: boolean;
  onClick?(event: MouseEvent<HTMLButtonElement>): void;
  count?: string;
  startIcon?: ReactNode;
  children?: ReactNode;
}

export const FilterButton: FC<FilterButtonProps> = ({
  title,
  onClick,
  variant,
  disabled,
  color,
  count,
  startIcon,
  children,
}) => {
  const colorsStyle =
    color && variant === "contained" ? FilterButtonColors.get(color) : {};
  const variantStyle = FilterButtonVariants.get(variant);

  return (
    <Button
      disableElevation
      size="small"
      onClick={onClick}
      endIcon={<ArrowDropDown />}
      sx={{
        borderRadius: 4,
        textTransform: "none",
        whiteSpace: "nowrap",
        height: 24,
        mr: 1,
        "& .MuiButton-endIcon": {
          ml: 0.5,
        },
        ...variantStyle,
        ...colorsStyle,
      }}
      variant={variant}
      disabled={!!disabled}
      startIcon={startIcon}
    >
      {children ?? (
        <Fragment>
          <TypographyWithTooltip
            title={`${!count ? "All" : ""} ${title}`}
            variant="inherit"
          />

          {!!count && (
            <Typography
              sx={{
                minWidth: 24,
                paddingLeft: 0.5,
                ...colorsStyle,
              }}
              variant="inherit"
            >
              {count}
            </Typography>
          )}
        </Fragment>
      )}
    </Button>
  );
};

const FilterButtonBlue: SxProps<Theme> = {
  bgcolor: ({ palette }) => palette.primary.light,
  color: ({ palette }) => palette.primary.main,
  "&:hover": {
    bgcolor: ({ palette }) => palette.primary.light,
    borderColor: ({ palette }) => palette.primary.main,
  },
};

const FilterButtonWarning: SxProps<Theme> = {
  bgcolor: orange[50],
  color: ({ palette }) => palette.warning.main,
  "&:hover": {
    bgcolor: orange[50],
    borderColor: ({ palette }) => palette.warning.main,
  },
};

const FilterButtonColors = new Map<
  ButtonProps["color"],
  SxProps<Theme> | object
>([
  ["primary", FilterButtonBlue],
  ["warning", FilterButtonWarning],
]);

const FilterButtonOutlined: SxProps<Theme> = {
  bgcolor: "tertiary",
  color: ({ palette }) => palette.tertiary.main,
  borderColor: ({ palette }) => palette.tertiary.main,
  "&:hover": {
    bgcolor: "tertiary",
    borderColor: ({ palette }) => palette.tertiary.main,
  },
};

const FilterButtonContained: SxProps<Theme> = {
  bgcolor: ({ palette }) => palette.primary.light,
  color: ({ palette }) => palette.primary.main,
  borderColor: ({ palette }) => palette.primary.main,
  "&:hover": {
    bgcolor: ({ palette }) => palette.primary.light,
    borderColor: ({ palette }) => palette.primary.main,
  },
};

const FilterButtonVariants = new Map<ButtonProps["variant"], SxProps<Theme>>([
  ["outlined", FilterButtonOutlined],
  ["contained", FilterButtonContained],
]);
