import { FC, ReactNode, useCallback } from "react";
import { Link } from "@mui/material";
import {
  setCoverageAndUtilizationDrawerDates,
  setCoverageAndUtilizationDrawerTarget,
} from "../../../../../../../../../store/commitments/commitmentsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { SavingsPlanFilterType } from "../../../../../../../../../services/cloudchipr.api";
import { utilizationAndCoverageDatesSelector } from "../../../../../../../../../store/commitments/selectors/coverage-and-utilization/view-options/utilizationAndCoverageDatesSelector";

interface CommitmentsTableNameProps {
  id: string;
  name: ReactNode;
  type: SavingsPlanFilterType;
}

export const CommitmentsTableName: FC<CommitmentsTableNameProps> = ({
  name,
  id,
  type,
}) => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(utilizationAndCoverageDatesSelector);

  const clickHandler = useCallback(() => {
    dispatch(setCoverageAndUtilizationDrawerDates(dates));
    dispatch(setCoverageAndUtilizationDrawerTarget({ id, type }));
  }, [dispatch, dates, type, id]);

  return (
    <Link component="button" underline="none" onClick={clickHandler}>
      {name}
    </Link>
  );
};
