import { generateCostAndUsageSumDataGridData } from "./utils/helpers/generateCostAndUsageSumDataGridData";
import { RootState } from "../../../../store";
import { widgetREViewDataSelector } from "../common/widget-re-view/widgetREViewDataSelector";
import { buildingInProgressWidgetIdWithNoFilter } from "../../../thunks/widgets/utils/constants";
import { CostAndUsageDataGridType } from "../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/cost-and-usage/content/components/data-grid/utils/types";

export const costAndUsageSumWidgetDataGridDataSelector = (
  state: RootState,
  widgetId?: string,
): CostAndUsageDataGridType[] => {
  const data = widgetREViewDataSelector(
    state,
    widgetId ?? buildingInProgressWidgetIdWithNoFilter,
  );

  if (!data) {
    return [];
  }
  return generateCostAndUsageSumDataGridData(data);
};
