import { Row } from "@tanstack/react-table";
import { ResourceExplorerGridDataWithId } from "../../../../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";

export const accountColumnFilter = (
  row: Row<ResourceExplorerGridDataWithId>,
  columnId: string,
  keyword: string,
) => {
  const metadata = row.original.metadata;

  const aim =
    `${metadata?.provider_account_id} ${metadata?.provider_account_name}`.toLowerCase();

  return aim.includes(keyword?.toLowerCase().trim());
};

export const regionColumnFilter = (
  row: Row<ResourceExplorerGridDataWithId>,
  columnId: string,
  keyword: string,
) => {
  const aim = row.original?.metadata?.region?.toLowerCase()?.trim();

  return aim?.includes(keyword?.toLowerCase().trim());
};
