import { FC } from "react";
import AvTimerOutlinedIcon from "@mui/icons-material/AvTimerOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import { useLocation } from "react-router-dom";
import { NavItem } from "../nav-item/NavItem";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";

export const ScheduleNavigation: FC = () => {
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  const location = useLocation();
  const selected = location.pathname.startsWith("/schedule");

  return (
    <NavItem
      primary="Automations"
      icon={AvTimerOutlinedIcon}
      navCollapsed={navigationCollapsed}
      selected={selected}
    >
      <NavItem
        nested
        primary="Workflows"
        to="/schedule/classic"
        icon={AccountTreeOutlinedIcon}
      />
      <NavItem
        nested
        primary="Off Hours"
        to="/schedule/off-hours"
        icon={HistoryToggleOffOutlinedIcon}
      />
    </NavItem>
  );
};
