import { RootState } from "../../../store";
import {
  cloudChiprApi,
  ProviderType,
} from "../../../../services/cloudchipr.api";

export const currentAccountProviderTypeSelector = (
  state: RootState,
): ProviderType | null => {
  if (!state.account.id) {
    return null;
  }

  return (
    cloudChiprApi.endpoints.getUsersMeAccountsByAccountId.select({
      accountId: state.account.id,
    })(state).data?.type || null
  );
};
