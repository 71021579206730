import { FC, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUsersMeOrganisationsCurrentBudgetsQuery } from "../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { resourceExplorerViewsDataSelector } from "../../../../../../../../../store/resource-explorer/selectors/all-resource-explorers/resourceExplorerViewsDataSelector";

interface ViewSelectionProps {
  value: string;
  onChange(id: string): void;
}

export const ViewSelection: FC<ViewSelectionProps> = ({ value, onChange }) => {
  const navigate = useNavigate();
  const { id: currentBudgetId } = useParams();

  const { data: budgets } = useGetUsersMeOrganisationsCurrentBudgetsQuery();
  const views = useAppSelector(resourceExplorerViewsDataSelector);

  const selectedViews = useMemo(() => {
    return budgets
      ?.filter(({ id }) => id !== currentBudgetId)
      ?.map(({ view }) => view.id);
  }, [budgets, currentBudgetId]);

  const defaultViewId = useMemo(
    () => views.find((view) => view.is_default)?.id,
    [views],
  );

  const chaneHandler = useCallback(
    (event: any) => {
      const value = event.target.value;

      value && onChange(value);
    },
    [onChange],
  );

  const toResourceExplorerHandler = useCallback(() => {
    navigate(`/resource-explorer/${defaultViewId}`);
  }, [navigate, defaultViewId]);

  const noAvailableView = (selectedViews?.length ?? 0) === views?.length;

  return (
    <div>
      <Typography variant="body2" mb={1}>
        Select View*
      </Typography>
      <FormControl size="small" fullWidth>
        {!value && <InputLabel>Select resource explorer view</InputLabel>}

        <Select
          fullWidth
          name="viewId"
          size="small"
          value={value}
          onChange={chaneHandler}
          renderValue={(selected) =>
            views?.find(({ id }) => id === selected)?.name ?? selected
          }
          MenuProps={{
            sx: {
              maxHeight: 400,
            },
          }}
          label={value ? "" : "Select resource explorer view"}
        >
          {views?.map(({ id, name }) => {
            if (selectedViews?.includes(id)) {
              return (
                <Tooltip
                  key={id}
                  title="This view is currently in use."
                  placement="left"
                  arrow
                >
                  <div>
                    <MenuItem value={id} disabled>
                      {name}
                    </MenuItem>
                  </div>
                </Tooltip>
              );
            }

            return (
              <MenuItem value={id} key={id}>
                {name}
              </MenuItem>
            );
          })}

          {noAvailableView && (
            <Box>
              <Divider />
              <Stack alignItems="center" spacing={0.5} py={1}>
                <Typography textAlign="center" variant="body2" width="100%">
                  Please add view in Resource Explorer to create budget
                </Typography>

                <Button
                  sx={{ textTransform: "none" }}
                  onClick={toResourceExplorerHandler}
                >
                  Go to Resource Explorer
                </Button>
              </Stack>
            </Box>
          )}
        </Select>
      </FormControl>
    </div>
  );
};
