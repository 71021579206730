import { commitmentsPayloadDataSelector } from "./commitmentsPayloadDataSelector";
import { RootState } from "../../../../../store";
import { GetUsersMeOrganisationsCurrentSavingsPlansApiArg } from "../../../../../../services/cloudchipr.api";

export const commitmentsGranularPayloadDataSelector = (
  state: RootState,
): GetUsersMeOrganisationsCurrentSavingsPlansApiArg => {
  const { coverageGrouping, ...payload } =
    commitmentsPayloadDataSelector(state);

  return {
    ...payload,
    loadTableData: false,
  };
};
