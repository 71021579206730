import { preDefinedRanges } from "../../../../common/date-range-picker-v2/utils/preDefinedRanges/preDefinedRanges";

export const executionLogDateAllTimeLabel = "all_time" as const;

export const MAX_DATE_MONTHS = 6;

const { past_one_hundred_eighty_days, last_twelve_months, ...restRanges } =
  preDefinedRanges;

export const preDefinedAllRangesForExecutionLogs = {
  ...restRanges,

  [executionLogDateAllTimeLabel]: {
    dateLabel: executionLogDateAllTimeLabel,
    dateTo: "",
    dateFrom: "",
    label: "All Time",
    shortLabel: "All Time",
  },
};
