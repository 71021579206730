import { FC } from "react";
import { getCostBreakdownV2NumeralItemSecondaryLabel } from "./utils/helpers/getCostBreakdownV2NumeralItemSecondaryLabel";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { costBreakdownV2WidgetTableViewDataSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetTableViewDataSelector";
import { costBreakdownV2WidgetForecastOptionSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetForecastOptionSelector";
import { WidgetNumeralViewItem } from "../../../../../adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralViewItem";
import { WidgetNumeralView } from "../../../../../adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralView";
import { costBreakdownV2WidgetByWidgetIdLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/loading/costBreakdownV2WidgetByWidgetIdLoadingSelector";
import { costBreakdownV2WidgetGroupingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetGroupingSelector";

interface CostBreakdownWidgetNumeralViewProps {
  widgetId: string;
}
export const CostBreakdownV2WidgetNumeralView: FC<
  CostBreakdownWidgetNumeralViewProps
> = ({ widgetId }) => {
  const data = useAppSelector((state) =>
    costBreakdownV2WidgetTableViewDataSelector(state, widgetId),
  );
  const forecastOption = useAppSelector((state) =>
    costBreakdownV2WidgetForecastOptionSelector(state, widgetId),
  );

  const loading = useAppSelector((state) =>
    costBreakdownV2WidgetByWidgetIdLoadingSelector(state, widgetId),
  );

  const grouping = useAppSelector((state) =>
    costBreakdownV2WidgetGroupingSelector(state, widgetId),
  );

  if (!forecastOption) {
    return null;
  }

  return (
    <WidgetNumeralView loading={loading} skeletonCount={data?.length}>
      {data?.map((item) => {
        const providers = item?.multiProviders ?? [item.cloud_provider];

        const secondaryLabel = getCostBreakdownV2NumeralItemSecondaryLabel({
          monthlyCost: item?.monthly_forecast?.cost,
          quarterlyCost: item?.quarterly_forecast?.cost,
          grouping,
          forecastOption,
        });

        return (
          <WidgetNumeralViewItem
            key={item.cloud_provider + item.field}
            providers={item.cloud_provider ? providers : []}
            label={item.field}
            cost={item.total_cost}
            trend={item.trend}
            itemsCount={data.length}
            secondaryLabel={secondaryLabel}
          />
        );
      })}
    </WidgetNumeralView>
  );
};
