import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { commitmentsDataForTableSelector } from "./commitmentsDataForTableSelector";
import { SavingsPlanCoverageDataWithId } from "../../../../../components/pages/commtiments/utilization-and-coverage/utils/types";
import { addIdInCoverageData } from "../../../utils/helpers/addIdInCoverageData";

export const coverageDataSelector = createDraftSafeSelector(
  [commitmentsDataForTableSelector],
  (data): SavingsPlanCoverageDataWithId[] | undefined => {
    return addIdInCoverageData(data?.utilization_and_coverage?.coverage_data);
  },
);
