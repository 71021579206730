import { costByCategoryWidgetByIdSelector } from "../costByCategoryWidgetByIdSelector";
import { RootState } from "../../../../../store";
import { uniqueCategoryGroupNamesSelector } from "../../../../../categories/selectors/uniqueCategoryGroupNamesSelector";

export const costByCategoryWidgetNotFoundSelector = (
  state: RootState,
  widgetId: string,
) => {
  const widget = costByCategoryWidgetByIdSelector(state, widgetId);
  const categoryNames = uniqueCategoryGroupNamesSelector(state);

  if (!widget) {
    return false;
  }

  return !categoryNames.includes(widget.category_group_key);
};
