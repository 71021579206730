import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { liveFilteredResourcesSelector } from "../../../resource-explorer/selectors/live-filtered-resources/liveFilteredResourcesSelector";

export const filteredResourcesChildResourcesCountSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const data = liveFilteredResourcesSelector(state, resourceType);
  return data?.reduce(
    (total, resource) =>
      total + (resource?.child_resource?.resources?.length ?? 0),
    0,
  );
};
