import { RootState } from "../../store";
import { offHoursByIdSelector } from "../../schedules/selectors/off-hours/offHoursByIdSelector";
import { workflowByIdSelector } from "../../schedules/selectors/workflows/workflowByIdSelector";

export const executionLogAutomationExistSelector = (
  state: RootState,
  id: string | null,
) => {
  const offHours = offHoursByIdSelector(state, id ?? "");
  const workflow = workflowByIdSelector(state, id ?? "");

  return !!(offHours ?? workflow);
};
