import { generateForecastedCostsData } from "./generateForecastedCostData";
import { generateCostAndUsageSumFilterTypeData } from "./generateCostAndUsageSumFilterTypeData";
import { PostUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse } from "../../../../../../../services/cloudchipr.api";
import { CostAndUsageDataGridType } from "../../../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/cost-and-usage/content/components/data-grid/utils/types";
import { calculateTotalCostTrend } from "../../../../../../../components/pages/utils/helpers/calculateTotalCostTrend";

export const generateCostAndUsageSumDataGridData = (
  data: PostUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse,
): CostAndUsageDataGridType[] => {
  const costAndUsageSumDataGridData: CostAndUsageDataGridType[] = [];

  costAndUsageSumDataGridData.push(
    generateCostAndUsageSumFilterTypeData(
      "total_cost",
      "Total Cost",
      data.total_cost,
      data.previous_period_total_cost
        ? calculateTotalCostTrend(
            data.total_cost ?? 0,
            data.previous_period_total_cost ?? 0,
          )
        : null,
      data.total_cost_date_details.from_date,
      data.total_cost_date_details.to_date,
      data.previous_period_cost_date_details.from_date,
      data.previous_period_cost_date_details.to_date,
    ),
  );

  costAndUsageSumDataGridData.push(
    generateCostAndUsageSumFilterTypeData(
      "previous_period_total_cost",
      "Previous Period Cost",
      data.previous_period_total_cost,
      null,
    ),
  );

  if (data.average_monthly_cost_details.average_monthly_cost !== null) {
    costAndUsageSumDataGridData.push(
      generateCostAndUsageSumFilterTypeData(
        "average_monthly_cost_details",
        "Average Monthly Cost",
        data.average_monthly_cost_details.average_monthly_cost,
        data.average_monthly_cost_details.trend,
        data.average_monthly_cost_details.from_date,
        data.average_monthly_cost_details.to_date,
        data.previous_period_cost_date_details.from_date,
        data.previous_period_cost_date_details.to_date,
      ),
    );
  }

  if (data.average_daily_cost_details.average_daily_cost !== null) {
    costAndUsageSumDataGridData.push(
      generateCostAndUsageSumFilterTypeData(
        "average_daily_cost_details",
        "Average Daily Cost",
        data.average_daily_cost_details.average_daily_cost,
        data.average_daily_cost_details.trend,
        data.average_daily_cost_details.from_date,
        data.average_daily_cost_details.to_date,
        data.previous_period_cost_date_details.from_date,
        data.previous_period_cost_date_details.to_date,
      ),
    );
  }
  costAndUsageSumDataGridData.push(
    ...generateForecastedCostsData(data?.forecasted_costs),
  );

  costAndUsageSumDataGridData.push(
    generateCostAndUsageSumFilterTypeData(
      "live_usage_total_cost",
      "Live Filtered",
      data.live_usage_total_cost,
      null,
    ),
  );

  return costAndUsageSumDataGridData;
};
