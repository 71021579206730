import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { costBreakdownV2WidgetSetupCostsSelector } from "./costBreakdownV2WidgetSetupCostsSelector";
import { calculateTotalCostTrend } from "../../../../../../components/pages/utils/helpers/calculateTotalCostTrend";

export const costBreakdownV2WidgetSetupTableTrendSelector =
  createDraftSafeSelector(
    [costBreakdownV2WidgetSetupCostsSelector],
    (costs): number | null => {
      const total = costs.total.cost ?? 0;
      const previous = costs.previousPeriodTotal?.cost ?? 0;

      const trend = calculateTotalCostTrend(total, previous);

      return previous > 0 ? trend : null;
    },
  );
