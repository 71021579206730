import { generateLargesCostChangesDateInfo } from "./generateLargesCostChangesDateInfo";
import { formatLargestCostChangesWidgetPrevDate } from "./formatLargestCostChangesWidgetPrevDate";
import { DateLabelNullable } from "../../../../../../../../../../services/cloudchipr.api";

interface GenerateLargestCostChangesWidgetDateCompareInfoArgs {
  prevDate: {
    from?: string | null;
    to?: string | null;
    label?: DateLabelNullable;
  };
  date?: {
    from?: string | null;
    to?: string | null;
    label?: DateLabelNullable;
  };
  quarterStartMonth?: number;
}
export const generateLargestCostChangesWidgetDateCompareInfo = ({
  prevDate,
  date,
  quarterStartMonth,
}: GenerateLargestCostChangesWidgetDateCompareInfoArgs) => {
  const formattedDate = date
    ? generateLargesCostChangesDateInfo(date, quarterStartMonth)
    : undefined;

  const endDateLabel = formatLargestCostChangesWidgetPrevDate({
    date: prevDate,
    quarterStartMonth,
  });

  return {
    startDateLabel: formattedDate?.label,
    startDateRange: formattedDate?.dateRange,
    endDateLabel: endDateLabel?.label,
    endDateRange: endDateLabel?.range,
  };
};
