import { FC, useCallback, useState } from "react";
import { Dialog } from "@mui/material";
import { SnackbarKey, useSnackbar } from "notistack";
import { CleanDialogActions } from "./components/CleanDialogActions";
import { CleanDialogContent } from "./components/dialog-content/CleanDialogContent";
import { cleanResourcesThunk } from "../../../../../store/clean/thunks/cleanResourceThunk";
import { closeCleanThunk } from "../../../../../store/clean/thunks/closeCleanThunk";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { isCleanDialogOpenSelector } from "../../../../../store/clean/selectors/isCleanDialogOpenSelector";
import { DialogTitleClosable } from "../../../../common/dialog-utils/DialogTitleClosable";
import { cleanActionTypeSelector } from "../../../../../store/clean/selectors/cleanActionTypeSelector";
import { getCleanActionOptions } from "../../../../../utils/clean-options";
import { newCleanProcessSelector } from "../../../../../store/clean/selectors/newCleanProcessSelector";
import { getResourceTypeName } from "../../../../../utils/helpers/resources/getResourceTypeName";
import { cleanResourcesGridDataSelector } from "../../../../../store/clean/selectors/cleanResourcesGridDataSelector";

export const CleanDialog: FC = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [cleanInputValue, setCleanInputValue] = useState("");
  const resourceType = useAppSelector(newCleanProcessSelector)?.resourceType;
  const isOpen = useAppSelector(isCleanDialogOpenSelector);
  const cleanType = useAppSelector(cleanActionTypeSelector);
  const data = useAppSelector(cleanResourcesGridDataSelector);
  const dispatch = useAppDispatch();

  const cleanActionOptions = resourceType
    ? getCleanActionOptions(resourceType)
    : undefined;

  const cleanActionType = cleanActionOptions?.find(
    (item) => item.value === cleanType,
  );

  const closeDialogHandler = useCallback(() => {
    dispatch(closeCleanThunk());
    setCleanInputValue("");
  }, [setCleanInputValue, dispatch]);

  const onCleanResourceHandler = useCallback(() => {
    closeDialogHandler();
    if (resourceType) {
      const key: SnackbarKey = enqueueSnackbar(
        `${cleanActionType?.presentContinueLabel} ${getResourceTypeName(
          resourceType,
          {
            singular: data?.length === 1,
          },
        )}`,
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            onClose: () => closeSnackbar(key),
            severity: "info",
            icon: false,
          },
        },
      );
    }
    dispatch(cleanResourcesThunk());
  }, [
    resourceType,
    dispatch,
    cleanActionType,
    closeDialogHandler,
    enqueueSnackbar,
    closeSnackbar,
    data,
  ]);

  if (!resourceType) {
    return null;
  }
  return (
    <Dialog open={isOpen} fullWidth maxWidth="md" onClose={closeDialogHandler}>
      <DialogTitleClosable
        title={`${cleanActionType?.label} ${getResourceTypeName(resourceType, {
          singular: data.length === 1,
        })}?`}
        onClose={closeDialogHandler}
      />

      <CleanDialogContent
        cleanInputValue={cleanInputValue}
        onCleanInputChange={setCleanInputValue}
        actionType={cleanActionType?.label.toLowerCase() ?? ""}
      />

      <CleanDialogActions
        onClean={onCleanResourceHandler}
        onCancel={closeDialogHandler}
        disabled={
          !cleanInputValue.length ||
          cleanInputValue !== cleanActionType?.label.toLowerCase()
        }
      />
    </Dialog>
  );
};
