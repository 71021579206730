import { ChangeEvent, FC } from "react";
import { Stack, TextField } from "@mui/material";

interface ResourceExplorerCreateOrEditDialogContentProps {
  value: string;
  onTextFieldChange: (e: ChangeEvent<HTMLInputElement>) => void;
}
export const ResourceExplorerCreateOrEditDialogContent: FC<
  ResourceExplorerCreateOrEditDialogContentProps
> = ({ value, onTextFieldChange }) => {
  return (
    <Stack gap={3} pt={1}>
      <Stack gap={0.5}>
        <TextField
          value={value}
          onChange={onTextFieldChange}
          name="name"
          label="View Name"
          fullWidth
        />
      </Stack>
    </Stack>
  );
};
