import { FC, useCallback } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import WifiTetheringOutlinedIcon from "@mui/icons-material/WifiTetheringOutlined";
import AreaChartSvgIcon from "../../../../../../assets/images/icons/AreaChartSvgIcon";
import { setCoverageAndUtilizationChartType } from "../../../../../../store/commitments/commitmentsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { utilizationAndCoverageChartTypeSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/view-options/utilizationAndCoverageChartTypeSelector";
import { UtilizationAndCoverageChartType } from "../../../../../../store/commitments/utils/types/types";

export const UtilizationAndCoverageChartTypeSelect: FC = () => {
  const dispatch = useAppDispatch();
  const chartType = useAppSelector(utilizationAndCoverageChartTypeSelector);

  const chartTypeSelectHandler = useCallback(
    (event: SelectChangeEvent) => {
      const chartType = event.target.value as UtilizationAndCoverageChartType;

      if (!chartType) {
        return;
      }

      dispatch(setCoverageAndUtilizationChartType(chartType));
    },
    [dispatch],
  );

  const renderValue = useCallback((value: UtilizationAndCoverageChartType) => {
    return <Box lineHeight={0}>{icons[value]}</Box>;
  }, []);

  return (
    <FormControl size="xsmall">
      <Select
        sx={selectSx}
        value={chartType}
        onChange={chartTypeSelectHandler}
        renderValue={renderValue}
      >
        <MenuItem value="bar">{icons.bar}</MenuItem>
        <MenuItem value="area">{icons.area}</MenuItem>
        <MenuItem value="gauge">{icons.gauge}</MenuItem>
      </Select>
    </FormControl>
  );
};

const icons = {
  bar: <BarChartIcon color="action" sx={{ fontSize: 23 }} />,
  area: <AreaChartSvgIcon color="action" sx={{ fontSize: 23 }} />,
  gauge: <WifiTetheringOutlinedIcon color="action" sx={{ fontSize: 23 }} />,
};

const selectSx: SelectProps["sx"] = {
  bgcolor: "white",
  alignSelf: "end",
  "& .MuiSelect-select": { p: 0.5 },
};
