import { FC } from "react";
import { TrendChip } from "../../../../../../../../../../common/chips/TrendChip";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { resourceExplorerDataGridDataTotalCostDateDetailsSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataTotalCostDateDetailsSelector";
import { formatDate } from "../../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { TooltipText } from "../../../../../../../../../../common/TooltipText";

interface TrendCellProps {
  value: number | null;
}

export const TrendCell: FC<TrendCellProps> = ({ value }) => {
  const dateDetails = useAppSelector(
    resourceExplorerDataGridDataTotalCostDateDetailsSelector,
  );
  const fromDate = formatDate(dateDetails?.from_date ?? "", { type: "date" });
  const toDate = formatDate(dateDetails?.to_date ?? "", { type: "date" });

  if (value === null) {
    return (
      <TooltipText
        tooltipTitle={`No data for Previous Period - ${fromDate} - ${toDate}`}
      >
        N/A
      </TooltipText>
    );
  }

  return <TrendChip value={value} />;
};
