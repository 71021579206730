import { FC, Fragment, ReactNode } from "react";
import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { TooltipProps, YAxisProps } from "recharts";
import { ReferenceLineType } from "./utils/types/types";
import {
  ChartTypeSelect,
  ChartTypeSelectProps,
} from "./components/common/ChartTypeSelect";
import { YAxisFormatter } from "./components/common/CustomizedYAxisTick";
import { ChartsTooltipProps } from "./components/common/ChartsTooltipContent";
import { XAxisTickProps } from "./components/common/CustomizedXAxisTick";
import { LineChart } from "./components/chart-by-type/line-chart/LineChart";
import { BarChart } from "./components/chart-by-type/bar-chart/BarChart";
import { MultiStackChart } from "./components/chart-by-type/multi-stack-chart/MultiStackChart";
import { useMultiTypeChartContext } from "./MultiTypeChartProvider";
import { PieChart } from "./components/chart-by-type/pie-chart/PieChart";
import { PieLegendContentProps } from "./components/chart-by-type/pie-chart/PieLegentContent";
import { AreaChart } from "./components/chart-by-type/area-chart/AreaChart";
import { ZoomChangeFunction } from "./utils/hooks/useChartZoom.hook";

interface MultiTypeChartProps extends ChartTypeSelectProps {
  zoom?: boolean;
  yAxisLabel?: ReactNode;
  highlight?: boolean;
  selectable?: boolean;
  showLoader?: boolean;
  loading?: boolean;
  yAxisFormatter?: YAxisFormatter;
  xAxisTickProps?: XAxisTickProps;
  tooltipProps?: ChartsTooltipProps;
  referenceLines?: ReferenceLineType[];
  TooltipContent?: FC<TooltipProps<any, any>>;
  LegendContent?: FC<PieLegendContentProps>;
  emptyText?: string;
  yAxisProps?: YAxisProps;
  legendItemLabelFormatter?(key: string): string;
  onZoomChange?: ZoomChangeFunction;
}

export const MultiTypeChart: FC<MultiTypeChartProps> = ({
  availableTypes,
  yAxisLabel,
  highlight,
  yAxisFormatter,
  loading,
  xAxisTickProps,
  selectable,
  tooltipProps,
  referenceLines,
  selectionType,
  zoom,
  onChartTypeChange,
  showLoader = true,
  TooltipContent,
  LegendContent,
  emptyText,
  yAxisProps,
  legendItemLabelFormatter,
  onZoomChange,
}) => {
  const { wrappedWithProvider, chartType } = useMultiTypeChartContext();
  const type = chartType.type;

  if (!wrappedWithProvider) {
    console.error(
      'MultiTypeChart must be wrapped by "MultiTypeChartProvider".',
    );
    return null;
  }

  return (
    <Box position="relative">
      {loading && (
        <Fragment>
          {showLoader && <LinearProgress />}
          <Box
            width="100%"
            height="100%"
            zIndex="appBar"
            fontWeight="bold"
            position="absolute"
            bgcolor="rgba(255, 255, 255, 0.5)"
          />
        </Fragment>
      )}

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2">{yAxisLabel}</Typography>

        {availableTypes && availableTypes?.length > 1 && (
          <ChartTypeSelect
            selectionType={selectionType}
            availableTypes={availableTypes}
            onChartTypeChange={onChartTypeChange}
          />
        )}
      </Stack>

      {type === "bar" && (
        <BarChart
          zoom={!!zoom}
          yAxisProps={yAxisProps}
          loading={!!loading}
          highlight={!!highlight}
          selectable={!!selectable}
          tooltipProps={tooltipProps}
          referenceLines={referenceLines}
          yAxisFormatter={yAxisFormatter}
          xAxisTickProps={xAxisTickProps}
          emptyText={emptyText}
          legendItemLabelFormatter={legendItemLabelFormatter}
          onZoomChange={onZoomChange}
        />
      )}

      {type === "line" && (
        <LineChart
          zoom={!!zoom}
          loading={!!loading}
          highlight={!!highlight}
          selectable={!!selectable}
          tooltipProps={tooltipProps}
          referenceLines={referenceLines}
          yAxisFormatter={yAxisFormatter}
          xAxisTickProps={xAxisTickProps}
          emptyText={emptyText}
          onZoomChange={onZoomChange}
        />
      )}

      {type === "stack" && (
        <BarChart
          stack
          zoom={!!zoom}
          loading={!!loading}
          yAxisProps={yAxisProps}
          highlight={!!highlight}
          selectable={!!selectable}
          tooltipProps={tooltipProps}
          referenceLines={referenceLines}
          yAxisFormatter={yAxisFormatter}
          xAxisTickProps={xAxisTickProps}
          emptyText={emptyText}
          legendItemLabelFormatter={legendItemLabelFormatter}
          onZoomChange={onZoomChange}
        />
      )}

      {type === "multiStack" && (
        <MultiStackChart
          loading={!!loading}
          highlight={!!highlight}
          selectable={!!selectable}
          referenceLines={referenceLines}
          yAxisFormatter={yAxisFormatter}
          xAxisTickProps={xAxisTickProps}
          tooltipProps={tooltipProps}
          emptyText={emptyText}
        />
      )}

      {type === "pie" && (
        <PieChart
          loading={!!loading}
          highlight={!!highlight}
          selectable={!!selectable}
          tooltipProps={tooltipProps}
          // todo: make it possible to pass "TooltipContent" and "LegendContent" to all charts
          TooltipContent={TooltipContent}
          LegendContent={LegendContent}
          emptyText={emptyText}
        />
      )}

      {type === "area" && (
        <AreaChart
          yAxisProps={yAxisProps}
          loading={!!loading}
          highlight={!!highlight}
          selectable={!!selectable}
          tooltipProps={tooltipProps}
          referenceLines={referenceLines}
          yAxisFormatter={yAxisFormatter}
          xAxisTickProps={xAxisTickProps}
          emptyText={emptyText}
          TooltipContent={TooltipContent}
          LegendContent={LegendContent}
        />
      )}
    </Box>
  );
};
