import { getResourceExplorerRestructuredFilters } from "./getResourceExplorerRestructuredFilters";
import { getResourceExplorerFiltersWithInitialValues } from "./getResourceExplorerFiltersWithInitialValues";
import { MultiFilters } from "../types/resourceExplorer";
import {
  ResourceExplorerFilterItem,
  ResourceExplorerPossibleFilter,
  ProviderType,
} from "../../../../services/cloudchipr.api";

export const getResourceExplorerMutatedFilters = (
  filters: ResourceExplorerFilterItem[],
  possibleFilters: Partial<
    Record<ProviderType, ResourceExplorerPossibleFilter[]>
  >,
  providers: ProviderType[],
  enabledProviders: ProviderType[],
): MultiFilters => {
  const restructuredFilters = getResourceExplorerRestructuredFilters(
    filters,
    providers,
    enabledProviders,
  );

  return getResourceExplorerFiltersWithInitialValues(
    restructuredFilters,
    possibleFilters,
  );
};
