import { ExportedResourceDataOptions } from "./types";
import { generateValue } from "./valueGenerators/valueGenerator";
import { addInstancesCount } from "./addInstancesCount";
import { sizeGenerator } from "./sizeGenerator";
import { generateExportedResourceData } from "./generateExportedResourceData";
import { generateNonPrimitiveHeaderKey } from "./generateNonPrimitiveHeaderKey";
import {
  RdsSnapshot,
  RdsSnapshotSource,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { getFirstResourceTypeFromResources } from "../helpers/getFirstResourceTypeFromResources";
import {
  ResourceDataType,
  ResourceItemType,
} from "../../../../../../../../../../../store/account/utils/types/types";
import { nestedResourcesExcludedRowsByParentDataGenerator } from "../data-grid/columns-setup/nestedResourcesExcludedRowsByParentDataGenerator";

export const generateExportedResourceDataByColumns = (
  resourceData: ResourceDataType[],
  columns: ColumnSetupType<ResourceItemType>[],
  disabledColumns: string[],
  options?: ExportedResourceDataOptions,
) => {
  return resourceData?.reduce(
    (acc, { resource, child_resource }) => {
      const resourceRowInfo = columns.reduce(
        (acc, col, index) => {
          if (col.id === "smart_tag" || !col.accessorKey) {
            return acc;
          }

          if (
            col.accessorKey === ("cpu_value" as keyof ResourceItemType) &&
            columns.find((col) => col.id === "cpu_max")
          ) {
            return acc;
          }

          let key = col.header;
          if (typeof key !== "string") {
            key = generateNonPrimitiveHeaderKey(
              col,
              options?.metricsFrequencies,
            );
          }

          if (options?.headerPrefix) {
            key = options.headerPrefix + key;
          }

          if (!options?.includeNestedRows && index === 1) {
            acc = addInstancesCount(acc, child_resource);
          }

          const isSize =
            // as "size" key not includes in all resources
            col.accessorKey.includes("size") &&
            col.accessorKey.length === "size".length;

          if (isSize && "size" in resource) {
            acc = {
              ...acc,
              ...sizeGenerator(`${resource?.size ?? ""}`),
            };

            return acc;
          }

          acc[key] = generateValue(resource, col, options);

          return acc;
        },
        {} as Record<string, string | number>,
      );

      acc.push(resourceRowInfo);

      const childrenResourceType = getFirstResourceTypeFromResources(
        child_resource?.resources,
      );

      if (
        options?.includeNestedRows &&
        child_resource &&
        childrenResourceType
      ) {
        const childrenData = generateExportedResourceData(
          childrenResourceType,
          child_resource.resources,
          // TODO: Think about this logic separation
          disabledColumns.concat(
            nestedResourcesExcludedRowsByParentDataGenerator(
              resource.resource_type,
              {
                isAurora: (resource as RdsSnapshot | RdsSnapshotSource)
                  .is_aurora,
              },
            ),
          ),
          {
            ...options,
            headerPrefix: "Instances_",
          },
        );

        acc.push(...childrenData);
      }

      return acc;
    },
    [] as Record<string, string | number>[],
  );
};
