import { selectedCoverageDataSelector } from "./selectedCoverageDataSelector";
import { RootState } from "../../../../../store";

export const selectedCoverageDataAccountNameAndIdSelector = (
  state: RootState,
): string => {
  const data = selectedCoverageDataSelector(state);

  return `${data?.account?.name} (${data?.account?.provider_account_id})`;
};
