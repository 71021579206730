import { RootState } from "../../../../store";
import { commitmentsRecommendationsPayloadDataSelector } from "../commitmentsRecommendationsPayloadDataSelector";

export const commitmentsRecommendationsAccountIdsSelector = (
  state: RootState,
): string[] | undefined => {
  const data = commitmentsRecommendationsPayloadDataSelector(state);

  return data.account_ids;
};
