import { utilizationAndCoverageDrawerDetailsSelector } from "./utilizationAndCoverageDrawerDetailsSelector";
import { RootState } from "../../../../../store";

export const utilizationAndCoverageDrawerTargetTypeSelector = (
  state: RootState,
) => {
  const details = utilizationAndCoverageDrawerDetailsSelector(state);

  return details.targetType;
};
