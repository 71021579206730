import { costByCategoryWidgetByIdSelector } from "../costByCategoryWidgetByIdSelector";
import { RootState } from "../../../../../store";

export const costByCategoryWidgetSelectedCategoryIdsSelector = (
  state: RootState,
  widgetId: string,
): string[] | undefined => {
  const widget = costByCategoryWidgetByIdSelector(state, widgetId);

  return widget?.categories?.map(({ id }) => id);
};
