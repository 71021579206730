import { FC, Fragment } from "react";
import { Card, LinearProgress } from "@mui/material";
import { CostAndUsageSumWidgetSetupDataGrid } from "./components/data-grid/CostAndUsageSumWidgetSetupDataGrid";
import { CostAndUsageSumWidgetSetupHeader } from "./components/CostAndUsageSumWidgetSetupHeader";
import { CostAndUsageSumWidgetView } from "./components/CostAndUsageSumWidgetView";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { widgetSetupContentChartHeight } from "../../../../../../utils/constants/stylings";
import { resourceExplorerByIdLoadingSelector } from "../../../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerByIdLoadingSelector";
import { costAndUsageGetWidgetDataLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-and-usage/loading/costAndUsageGetWidgetDataLoadingSelector";
import { costAndUsageSumSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-and-usage/costAndUsageSumSetupPropertyByKeySelector";
import { DateLabelNullable } from "../../../../../../../../../services/cloudchipr.api";
import { WidgetSetupContentWrapper } from "../../common/WidgetSetupContentWrapper";

interface CostAndUsageSumWidgetSetupContentProps {
  dateLabel?: DateLabelNullable;
}

export const CostAndUsageSumWidgetSetupContent: FC<
  CostAndUsageSumWidgetSetupContentProps
> = ({ dateLabel }) => {
  const dataLoading = useAppSelector(costAndUsageGetWidgetDataLoadingSelector);

  const viewId = useAppSelector(
    costAndUsageSumSetupPropertyByKeySelector("viewId"),
  );

  const resourceExplorerViewLoading = useAppSelector((state) =>
    resourceExplorerByIdLoadingSelector(state, viewId),
  );

  const loading = dataLoading || resourceExplorerViewLoading;

  return (
    <Fragment>
      <WidgetSetupContentWrapper>
        <Card
          elevation={3}
          sx={{
            height: widgetSetupContentChartHeight,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {loading && <LinearProgress />}
          <CostAndUsageSumWidgetSetupHeader />
          <CostAndUsageSumWidgetView loading={loading} dateLabel={dateLabel} />
        </Card>
      </WidgetSetupContentWrapper>

      <CostAndUsageSumWidgetSetupDataGrid loading={loading} />
    </Fragment>
  );
};
