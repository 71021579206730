import { currentAccountFilterTemplatesSelector } from "./currentAccountFilterTemplatesSelector";
import { appliedFilterTemplateSelector } from "./applied/appliedFilterTemplateSelector";
import { RootState } from "../../../store";
import {
  ResourceFilters,
  ResourceType,
} from "../../../../services/cloudchipr.api";

export const currentAccountApplyingFilterTemplateFiltersByResourceTypeSelector =
  (
    state: RootState,
    resourceType: ResourceType,
  ): ResourceFilters | undefined => {
    const filterTemplates = currentAccountFilterTemplatesSelector(state);

    let filters = appliedFilterTemplateSelector(state)?.filters;

    if (!filters) {
      filters = filterTemplates?.find(({ filter_set }) => filter_set.is_default)
        ?.filter_set?.filters;
    }

    return filters?.find(({ type }) => type === resourceType);
  };
