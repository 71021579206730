import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { utilizationAndCoverageDetailsCoverageDataSelector } from "./utilizationAndCoverageDetailsCoverageDataSelector";
import { commitmentsCSVDataGenerator } from "../../../../utils/helpers/commitmentsCSVDataGenerator";
import { utilizationAndCoverageDataViewTabSelector } from "../../view-options/utilizationAndCoverageDataViewTabSelector";
import { utilizationAndCoverageDrawerCoverageGroupingSelector } from "../options/utilizationAndCoverageDrawerCoverageGroupingSelector";

export const utilizationAndCoverageDetailsCSVDataSelector =
  createDraftSafeSelector(
    [
      utilizationAndCoverageDetailsCoverageDataSelector,
      utilizationAndCoverageDataViewTabSelector,
      utilizationAndCoverageDrawerCoverageGroupingSelector,
    ],
    (data, viewTab, grouping) => {
      return commitmentsCSVDataGenerator({ viewTab, data, grouping });
    },
  );
