import { resourceTypeDataSelector } from "./resourceTypeDataSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { resourceViewIsWithoutFiltersSelector } from "../current-account/resources/resourceViewIsWithoutFiltersSelector";
import { getResourceTypeDataWithoutFiltersFixedCacheKey } from "../../thunks/filters-get/getCurrentAccountResourceTypeWithoutFiltersDataThunk";
import { filterTemplatesEnabledSelector } from "../current-account/resources/filterTemplatesEnabledSelector";

export const resourceTypeDataTotalCountSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const withoutFilters = resourceViewIsWithoutFiltersSelector(
    state,
    resourceType,
  );

  const filterTemplatesEnabled = filterTemplatesEnabledSelector(state);

  const fixedCacheKey =
    withoutFilters || !filterTemplatesEnabled
      ? getResourceTypeDataWithoutFiltersFixedCacheKey(resourceType)
      : resourceType;

  return resourceTypeDataSelector(state, fixedCacheKey)?.total_resources;
};
