import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { currentDashboardSelector } from "./currentDashboardSelector";
import { DashboardWidgetType } from "../../utils/types/types";

export const dashboardWidgetsSelector = createDraftSafeSelector(
  [currentDashboardSelector],
  (dashboard): DashboardWidgetType[] => {
    if (!dashboard) {
      return [];
    }

    const widgets = dashboard?.widgets ?? [];

    // todo: remove filter logic when all old widgets will be removed
    return widgets.filter(
      (widget) =>
        widget.type !== "resource_explorer" &&
        widget.type !== "costs_by_category",
      // todo: remove this `as` when all widgets types will correct
    ) as DashboardWidgetType[];
  },
);
