import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { currentAccountProviderTypeSelector } from "../../../account/selectors/current-account/currentAccountProviderTypeSelector";
import { getProviderName } from "../../../../utils/helpers/providers/getProviderName";
import { currentAccountSelectedResourcesByResourceTypeSelector } from "../../../account/selectors/current-account/resources/selection/currentAccountSelectedResourcesByResourceTypeSelector";
import { currentAccountNameIdSelector } from "../../../account/selectors/current-account/currentAccountNameIdSelector";
import { generateExportedResourceData } from "../../../../components/pages/account/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/generateExportedResourceData";
import { generateTableFromTheJson } from "../../../../components/common/integration-dialogs/components/jira/utils/helpers/generateTableFromTheJson";
import { getResourceTypeName } from "../../../../utils/helpers/resources/getResourceTypeName";

export const jiraDescriptionSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const accountType = currentAccountProviderTypeSelector(state);
  const accountName = currentAccountNameIdSelector(state);
  const selectedResources =
    currentAccountSelectedResourcesByResourceTypeSelector(state, resourceType);

  if (!selectedResources) {
    return "";
  }

  const csvData = generateExportedResourceData(
    resourceType,
    selectedResources,
    ["issues"],
    { onlyNameTag: true },
  );

  const resource = getResourceTypeName(resourceType, {
    type: "long",
  });
  const provider = accountType
    ? getProviderName(accountType, { title: true })
    : "";

  const accountURL = `<a href="${window.location.href}" target="_blank">${accountName}</a>`;

  let description = `<b>${provider}</b> - ${accountURL} <br/> <b>${resource}</b> <br/> <br/> `;

  if (csvData) {
    const table = generateTableFromTheJson(csvData);

    if (table) {
      description += table.innerHTML;
    }
  }

  return description;
};
