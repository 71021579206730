import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCurrentAccountThunk } from "./getCurrentAccountThunk";
import { getCurrentAccountVisibilitiesThunk } from "./getCurrentAccountVisibilitiesThunk";
import { getCurrentAccountAllResourceTypesDataThunk } from "./filters-get/getCurrentAccountAllResourceTypesDataThunk";
import { setAccountPageState, setCurrentAccountId } from "../accountSlice";
import { applyAccountFiltersThunk } from "../../filters/thunks/applyAccountFiltersThunk";
import { getCurrentAccountFilterTemplatesThunk } from "../../filters/thunks/filter-set/getCurrentAccountFilterTemplatesThunk";

export const accountInitialDataThunk = createAsyncThunk(
  "accountInitialDataThunk",
  async (accountId: string, { dispatch }) => {
    // TODO: Refactor the logic
    // Some of the thunks are using passed argument accountId, and some of them just getting it from state

    dispatch(setAccountPageState("pending"));
    dispatch(setCurrentAccountId(accountId));
    await dispatch(getCurrentAccountThunk(accountId));
    await dispatch(getCurrentAccountFilterTemplatesThunk());

    await dispatch(applyAccountFiltersThunk(accountId));

    await dispatch(getCurrentAccountVisibilitiesThunk(accountId));
    await dispatch(getCurrentAccountAllResourceTypesDataThunk());
    dispatch(setAccountPageState("fulfilled"));
  },
);
