import { FC, memo, useCallback, useMemo } from "react";
import moment from "moment/moment";
import { DateRange } from "mui-daterange-picker";
import { useDidMount } from "rooks";
import { DateRangePickerWithMore } from "../../../../../../../../common/date-range-picker-with-more/DateRangePickerWithMore";
import { generateDateFromDateLabelV1 } from "../../../../../../../../common/date-range-picker/utils/helpers/generateDateFromDateLabelV1";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { resourceExplorerDatePeriodByIdSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerDatePeriodByIdSelector";
import { DateRangePicker } from "../../../../../../../../common/date-range-picker/DateRangePicker";
import { formatDate } from "../../../../../../../../../utils/helpers/date-time/datetime-format";
import { resourceExplorerPathLastItemFiltersSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/path/resourceExplorerPathLastItemFiltersSelector";
import {
  setResourceExplorerDateRanges,
  setResourceExplorerSearch,
} from "../../../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { getResourceExplorerDataThunk } from "../../../../../../../../../store/resource-explorer/thunks/widgets/getResourceExplorerDataThunk";
import { resourceExplorerDateLabelSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerDateLabelSelector";
import { getResourceExplorerMinAvailableDateThunk } from "../../../../../../../../../store/resource-explorer/thunks/widgets/getResourceExplorerMinAvailableDateThunk";
import { resourceExplorerMinAvailableDateSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerMinAvailableDateSelector";
import { DateData } from "../../../../../../../dashboard/components/adding-widget/widget-create/widget-setups/common/toolbar/date-picker/WidgetSetupDateRangeSelect";
import { allDateRangesByQuarterStartMonthSelectorV1 } from "../../../../../../../../../store/common/selectors/date-ranges/allDateRangesByQuarterStartMonthSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { DatePeriodInputProps } from "../../../../../../../../common/date-range-picker/utils/types";

export const ResourceExplorerDateRangeSelector: FC = memo(() => {
  const dispatch = useAppDispatch();
  const datePeriod = useAppSelector(resourceExplorerDatePeriodByIdSelector);
  const dateLabel = useAppSelector(resourceExplorerDateLabelSelector);
  const minDate = useAppSelector(resourceExplorerMinAvailableDateSelector);

  const allDateRanges = useAppSelector(
    allDateRangesByQuarterStartMonthSelectorV1,
  );
  const orgQuarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const pathLastItemFilters = useAppSelector(
    resourceExplorerPathLastItemFiltersSelector,
  );

  const initialDate = useMemo(() => {
    const range = generateDateFromDateLabelV1(dateLabel, orgQuarterStartMonth);
    const dateTo = formatDate(moment(range?.endDate), {
      type: "yearMonthDay",
    });
    const dateFrom = formatDate(moment(range?.startDate), {
      type: "yearMonthDay",
    });

    return {
      to: datePeriod.to || dateTo,
      from: datePeriod.from || dateFrom,
    };
  }, [datePeriod, dateLabel, orgQuarterStartMonth]);

  const dateSubmitHandler = useCallback(
    (range: DateRange) => {
      const dateTo = formatDate(moment(range?.endDate), {
        type: "yearMonthDay",
      });
      const dateFrom = formatDate(moment(range?.startDate), {
        type: "yearMonthDay",
      });

      if (!dateTo || !dateFrom) {
        return;
      }

      dispatch(
        setResourceExplorerDateRanges({
          to: dateTo,
          from: dateFrom,
          label: null,
        }),
      );
      dispatch(
        getResourceExplorerDataThunk({ filtersFromPath: pathLastItemFilters }),
      );
      dispatch(setResourceExplorerSearch(""));
    },
    [dispatch, pathLastItemFilters],
  );

  useDidMount(() => {
    dispatch(getResourceExplorerMinAvailableDateThunk());
  });

  const dateRangeChangeHandler = useCallback(
    (date: DateData) => {
      const label = date.label;
      dispatch(
        setResourceExplorerDateRanges({
          to: null,
          from: null,
          label: label,
        }),
      );

      dispatch(
        getResourceExplorerDataThunk({ filtersFromPath: pathLastItemFilters }),
      );
      dispatch(setResourceExplorerSearch(""));
    },
    [dispatch, pathLastItemFilters],
  );
  const InputComponent = useCallback(
    (props: DatePeriodInputProps) => {
      return (
        <DateRangePickerWithMore
          {...props}
          options={allDateRanges}
          displayThreshold={6}
          onChange={dateRangeChangeHandler}
          dateLabel={dateLabel}
        />
      );
    },
    [dateRangeChangeHandler, dateLabel, allDateRanges],
  );

  return (
    <DateRangePicker
      viewType="popover"
      dateTo={initialDate.to}
      dateFrom={initialDate.from}
      onSubmit={dateSubmitHandler}
      minDate={minDate}
      maxDate={moment().format()}
      InputComponent={InputComponent}
    />
  );
});
