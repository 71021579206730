import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { EbsSnapshot } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const ebsSnapshotColumns: ColumnSetupType<EbsSnapshot>[] = [
  {
    accessorKey: "name",
    header: "Name",
    type: "identifier",
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "snapshot_id",
    header: "Snapshot ID",
    type: "copyWithTooltip",
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "creation_data",
    header: "Created By",
    type: "createdBy",
    headerTooltip:
      "The Created By value is only available for resources created within the last 90 days.",
  },
  {
    accessorKey: "created_at",
    header: "Start Time",
    type: "date",
  },
  {
    accessorKey: "storage_tier",
    header: "Storage Tier",
  },
  {
    accessorKey: "volume_size",
    header: "Volume size",
    type: "byteToGib",
  },
  {
    accessorKey: "status",
    header: "Snapshot Status",
  },
  {
    accessorKey: "volume_id",
    header: "Volume ID",
    type: "copyWithTooltip",
  },
  {
    accessorKey: "total_siblings",
    header: "Snapshots per Volume",
    minSize: 210,
  },
  {
    accessorKey: "is_encrypted",
    header: "Encrypted",
    cell: (cell) => String(!!cell.getValue()),
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "is_snapshot_used", header: "In Use", type: "inUse" },
  {
    accessorKey: "billable",
    header: "Billable",
    type: "billable",
  },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Tags",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "Ticket Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
