import { FC, Fragment, memo, ReactChild, RefObject } from "react";
import { SxProps } from "@mui/system";
import { flexRender, Row } from "@tanstack/react-table";
import { TableCell, Theme } from "@mui/material";
import { ExpandedRow, ExpandedRowProps } from "../ExpandedRow";
import { RowDnDWrapper, WithGetRowProps } from "../RowDnDWrapper";
import { TableStickyCell } from "../TableStickyCell";
import { isCellStylesCallable } from "../../utils/types/types";

export interface TableRowNotVirtualizedCellsProps extends WithGetRowProps {
  row: Row<any>;
  expandingRowConfig?: ExpandedRowProps["config"];
  dndEnabled: boolean;
  stickyColumnsEnabled: boolean;
  styles?: SxProps<Theme>;
  globalFilter?: string;
  tableContainerRef: RefObject<HTMLTableElement>;
}

export const TableRowNotVirtualizedCells: FC<TableRowNotVirtualizedCellsProps> =
  memo(
    ({
      row,
      expandingRowConfig,
      dndEnabled,
      stickyColumnsEnabled,
      styles,
      globalFilter,
      tableContainerRef,
      getRowProps,
    }) => {
      return (
        <Fragment>
          <RowDnDWrapper
            wrap={dndEnabled}
            row={row}
            styles={styles}
            getRowProps={getRowProps}
          >
            {row.getVisibleCells().map((cell, i, arr) => {
              const child = flexRender(
                cell.column.columnDef.cell,
                cell.getContext(),
              ) as ReactChild;

              let cellStyles = cell?.column?.columnDef?.meta?.cellStyles;
              if (isCellStylesCallable(cellStyles)) {
                cellStyles = cellStyles(row);
              }

              const stickySide = cell?.column?.columnDef?.meta?.sticky;
              const isSticky = stickySide && stickyColumnsEnabled;

              if (isSticky) {
                const isLeftLastSticky =
                  stickySide === "left" &&
                  !arr[i + 1]?.column?.columnDef?.meta?.sticky;

                const isRightFirstSticky =
                  stickySide === "right" &&
                  !arr[i - 1]?.column?.columnDef?.meta?.sticky;

                const multiplySticky =
                  !!arr[i + 1]?.column?.columnDef?.meta?.sticky ||
                  !!arr[i - 1]?.column?.columnDef?.meta?.sticky;

                return (
                  <TableStickyCell
                    key={cell.id}
                    side={stickySide}
                    isLeftLastSticky={isLeftLastSticky}
                    isRightFirstSticky={isRightFirstSticky}
                    customStyles={cellStyles}
                    multiplySticky={multiplySticky}
                  >
                    {child}
                  </TableStickyCell>
                );
              }

              return (
                <TableCell
                  key={cell.id}
                  sx={{ p: 1, whiteSpace: "nowrap", ...cellStyles }}
                >
                  {child}
                </TableCell>
              );
            })}
          </RowDnDWrapper>

          <ExpandedRow
            row={row}
            config={expandingRowConfig}
            globalFilter={globalFilter}
            tableContainerRef={tableContainerRef}
          />
        </Fragment>
      );
    },
  );
