import { FC, useCallback } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { IntegrationLogo } from "../../../../../../../../../../../../common/integration-dialogs/components/common/IntegrationLogo";
import { setSelectedResources } from "../../../../../../../../../../../../../store/account/accountSlice";
import { initiateCurrentIntegration } from "../../../../../../../../../../../../../store/integrations/integrationsSlice";
import { IntegrationType } from "../../../../../../../../../../../integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";
import { ResourceType } from "../../../../../../../../../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../../../../../../../../../store/hooks";

interface IntegrationMenuItemProps {
  id: string;
  type: IntegrationType;
  resourceType: ResourceType;
  disabled?: boolean;
  label: string;
}

export const IntegrationMenuItem: FC<IntegrationMenuItemProps> = ({
  id,
  type,
  resourceType,
  label,
  disabled,
}) => {
  const dispatch = useAppDispatch();

  const clickHandler = useCallback(() => {
    dispatch(
      setSelectedResources({
        resourceType,
        selectedResources: { [id]: true },
        parentSource: resourceType,
      }),
    );
    dispatch(initiateCurrentIntegration({ type, resourceType }));
  }, [dispatch, type, resourceType, id]);

  return (
    <MenuItem dense onClick={clickHandler} disabled={disabled}>
      <ListItemIcon>
        <IntegrationLogo type={type} width={20} />
      </ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </MenuItem>
  );
};
