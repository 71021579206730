import { FC, memo } from "react";
import { useSearchParams } from "react-router-dom";
import { useEffectOnceWhen } from "rooks";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { ResourceExplorerMissingAccountsAlert } from "../filters/components/alerts/ResourceExplorerMissingAccountsAlert";
import { isCurrentResourceExplorerInitializedSelector } from "../../../../../store/resource-explorer/selectors/current-resource-explorer/checkers/isCurrentResourceExplorerInitializedSelector";
import { accountExcludedFiltersSelector } from "../../../../../store/resource-explorer/selectors/filter-by-category/accountExcludedFiltersSelector";
import { categoryNameByCategoryIdSelector } from "../../../../../store/categories/selectors/categoryNameByCategoryIdSelector";
import { syncAccountFiltersWithCategoryAccountsThunk } from "../../../../../store/resource-explorer/thunks/filters/syncAccountFiltersWithCategoryAccountsThunk";

interface ResourceExplorerAlertsProps {
  viewId: string;
}

export const ResourceExplorerAlerts: FC<ResourceExplorerAlertsProps> = memo(
  ({ viewId }) => {
    // todo: remove getting `category` from searchParams from `ResourceExplorerResetButton` component when dimensions will be ready

    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const categoryId = searchParams.get("category") as string;

    const resourceExplorerInitialized = useAppSelector((state) =>
      isCurrentResourceExplorerInitializedSelector(state, viewId),
    );
    const categoryName = useAppSelector((state) =>
      categoryNameByCategoryIdSelector(state, categoryId),
    );
    const excludedAccounts = useAppSelector((state) =>
      accountExcludedFiltersSelector(state, categoryId),
    );

    useEffectOnceWhen(async () => {
      dispatch(syncAccountFiltersWithCategoryAccountsThunk(categoryId));
    }, !!categoryId && resourceExplorerInitialized);

    if (!excludedAccounts?.length) {
      return null;
    }

    return (
      <ResourceExplorerMissingAccountsAlert
        accounts={excludedAccounts ?? []}
        categoryName={categoryName}
      />
    );
  },
);
