import { FC, Fragment, useCallback } from "react";
import {
  Collapse,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useToggle } from "rooks";
import { IconByResourceType } from "../../../../../account/components/navigation/IconByResourceType";
import { getResourceTypeName } from "../../../../../../../utils/helpers/resources/getResourceTypeName";
import { ResourceGroupType } from "../../../../../../../utils/constants/resources/resourceGroups";
import { ResourceType } from "../../../../../../../services/cloudchipr.api";

const collapseTransitionDuration = 200;

interface ScheduleResourcesSelectListItemProps {
  selectedValue: ResourceType;
  resourcesGroup: ResourceGroupType;
  allNestedResourcesSelected: boolean;
  onChange(rt: ResourceType): void;
  recalculateMenuPosition(): void;
  isResourceTypeVisible(rt: string): boolean;
  isResourceTypeDisabled(rt: string): boolean;
}

export const ScheduleResourcesSelectListItem: FC<
  ScheduleResourcesSelectListItemProps
> = ({
  resourcesGroup,
  selectedValue,
  isResourceTypeVisible,
  isResourceTypeDisabled,
  onChange,
  recalculateMenuPosition,
  allNestedResourcesSelected,
}) => {
  const selected =
    resourcesGroup.actionableResourceTypes.includes(selectedValue);

  const [collapsed, setCollapsed] = useToggle(selected);

  const collapseHandler = useCallback(
    (e: any) => {
      if (allNestedResourcesSelected) {
        return;
      }

      setCollapsed();
      e.stopPropagation();
      setTimeout(recalculateMenuPosition, collapseTransitionDuration);
    },
    [allNestedResourcesSelected, setCollapsed, recalculateMenuPosition],
  );

  return (
    <Fragment>
      {[
        <MenuItem
          onClickCapture={collapseHandler}
          disabled={allNestedResourcesSelected}
          key={`${resourcesGroup.value}_collapse-button`}
        >
          <ListItemIcon
            sx={{
              filter: selected ? "none" : "grayscale(1)",
            }}
          >
            <IconByResourceType resourceType={resourcesGroup.value} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" color="text.primary">
                {getResourceTypeName(resourcesGroup.value, {
                  service: true,
                  type: "long",
                })}
              </Typography>
            }
          />

          {collapsed ? <ExpandLess /> : <ExpandMore />}
        </MenuItem>,

        <Collapse
          in={collapsed}
          unmountOnExit
          timeout={collapseTransitionDuration}
          key={`${resourcesGroup.value}_collapse`}
        >
          {resourcesGroup.actionableResourceTypes.map((rt) => {
            if (!isResourceTypeVisible(rt)) {
              return null;
            }

            return (
              <MenuItem
                key={`${rt}_collapse-item`}
                className={rt === selectedValue ? "Mui-selected" : ""}
                sx={{ pl: 6.25 }}
                disabled={isResourceTypeDisabled(rt) && rt !== selectedValue}
                onClick={onChange.bind(null, rt)}
              >
                {getResourceTypeName(rt, { type: "long" })}
              </MenuItem>
            );
          })}
        </Collapse>,
      ]}
    </Fragment>
  );
};
