import { IconButton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { DropdownHeaderComponent } from "../../../../../../common/select/dropdown-select/utils/types/types";

export const DropdownHeader: DropdownHeaderComponent = ({ onClose }) => {
  return (
    <Stack
      px={1.5}
      py={1}
      width="100%"
      borderBottom={1}
      borderColor="grey.300"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="subtitle2">Select account to navigate</Typography>
      <IconButton onClick={onClose} size="small">
        <Close />
      </IconButton>
    </Stack>
  );
};
