import {
  ProviderType,
  ResourceExplorerGrouping,
} from "../../../../services/cloudchipr.api";
import { ResourceExplorerPath } from "../types/resourceExplorer";
import { getDefaultGroupings } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/helpers/getDefaultGroupings";
import { getResourcesExplorerGroupingLabel } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";

export const generateDefaultPath = (
  providers: ProviderType[],
  grouping?: ResourceExplorerGrouping,
  groupValues?: string[],
): ResourceExplorerPath[] => {
  const { groupingOptions } = getDefaultGroupings(providers);

  return [
    {
      name: grouping
        ? `${getResourcesExplorerGroupingLabel(providers, grouping)}`
        : "Services",
      groupingOptions,
      groupValues: groupValues,
      groupedBy: grouping ?? "service",
      includedFilters: {} as ResourceExplorerPath["includedFilters"],
      providers,
    },
  ];
};
