import { FC, useCallback } from "react";
import { PopoverProps } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { utilizationAndCoverageDatesSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/view-options/utilizationAndCoverageDatesSelector";
import { setCoverageAndUtilizationDates } from "../../../../../../store/commitments/commitmentsSlice";
import { CommitmentsDateRangeSelector } from "../common/CommitmentsDateRangeSelector";
import { Dates } from "../../../../../../services/cloudchipr.api";
import { getSavingsPlansThunk } from "../../../../../../store/commitments/thunks/savings-plans/getSavingsPlansThunk";

export const UtilizationAndCoverageDateRange: FC = () => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(utilizationAndCoverageDatesSelector);

  const dateChangeHandler = useCallback(
    (dates: Dates) => {
      dispatch(setCoverageAndUtilizationDates(dates));
      dispatch(getSavingsPlansThunk());
    },
    [dispatch],
  );

  return (
    <CommitmentsDateRangeSelector
      size="xsmall"
      dates={dates}
      PopoverProps={popoverProps}
      onChange={dateChangeHandler}
    />
  );
};

const popoverProps: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};
