import { MultiFilters } from "../types/resourceExplorer";
import {
  ResourceExplorerPossibleFilter,
  ProviderType,
} from "../../../../services/cloudchipr.api";

export const getResourceExplorerFiltersWithInitialValues = (
  filters: MultiFilters,
  possibleFilters: Partial<
    Record<ProviderType, ResourceExplorerPossibleFilter[]>
  >,
): MultiFilters => {
  if (filters["gcp"] && !filters["gcp"]?.credit_type) {
    const possibleCredits = possibleFilters?.["gcp"]?.find(
      (item) => item.key === "credit_type",
    );

    filters["gcp"] = {
      ...filters["gcp"],
      credit_type: {
        cloud_provider: "gcp",
        include_values: true,
        key: "credit_type",
        operator: "in",
        value: possibleCredits?.values.map((item) => item.value) ?? [],
      },
    };
  }

  const chargeTypeFilter = filters?.aws?.charge_type;
  if (chargeTypeFilter?.operator === "not_in") {
    const possibleValues = possibleFilters?.aws?.find(
      (item) => item.key === "charge_type",
    )?.values;

    const value =
      possibleValues
        ?.filter(({ value }) => !chargeTypeFilter?.value?.includes(value))
        ?.map(({ value }) => value) ?? [];

    filters.aws = {
      ...filters.aws,
      charge_type: { ...chargeTypeFilter, value, operator: "in" },
    };
  }

  return filters;
};
