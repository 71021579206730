import { RootState } from "../../../../../../store";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { costBreakdownWidgetByIdSelector } from "../../costBreakdownWidgetByIdSelector";

export const costBreakdownWidgetTableViewGroupingSelector = (
  state: RootState,
  widgetId?: string,
) => {
  if (!widgetId) {
    return costBreakdownSetupPropertyByKeySelector("grouping")(state);
  }
  const widget = costBreakdownWidgetByIdSelector(state, widgetId);

  return widget?.grouping;
};
