import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAccountFilterByCategoryThunk } from "./setAccountFilterByCategoryThunk";
import { getCategoryByIdThunk } from "../../../categories/thunks/getCategoryByIdThunk";
import { getResourceExplorerWidgetDataThunk } from "../widgets/getResourceExplorerWidgetDataThunk";
import {
  setResourceExplorerDateRanges,
  setResourceExplorerDates,
  setResourceExplorerGrouping,
} from "../../resourceExplorerSlice";

export const syncAccountFiltersWithCategoryAccountsThunk = createAsyncThunk(
  "syncAccountFiltersWithCategoryAccounts",
  async (categoryId: string, { dispatch }) => {
    await dispatch(getCategoryByIdThunk(categoryId));
    dispatch(setAccountFilterByCategoryThunk(categoryId));
    dispatch(setResourceExplorerGrouping({ grouping: "account" }));

    dispatch(
      setResourceExplorerDateRanges({
        to: null,
        from: null,
        label: "month_to_date",
      }),
    );

    dispatch(
      setResourceExplorerDates({
        to: null,
        from: null,
        label: "month_to_date",
      }),
    );
    dispatch(getResourceExplorerWidgetDataThunk());
  },
);
