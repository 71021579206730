import { FC } from "react";
import { PopoverProps } from "@mui/material";
import { useAppSelector } from "../../../../../../store/hooks";
import { allDateRangesByQuarterStartMonthSelector } from "../../../../../../store/common/selectors/date-ranges/allDateRangesByQuarterStartMonthSelector";
import {
  Dates,
  useGetUsersMeOrganisationsCurrentSavingsPlansMinimumAvailableDateQuery,
} from "../../../../../../services/cloudchipr.api";
import { DateRangePicker } from "../../../../../common/date-range-picker-v2/DateRangePicker";
import { organisationQuarterStartMonthSelector } from "../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";

interface CommitmentsDateRangeSelectorProps {
  dates: Dates;
  size?: "small" | "xsmall";
  onChange(dates: Dates): void;
  PopoverProps?: Partial<Omit<PopoverProps, "open">>;
}

export const CommitmentsDateRangeSelector: FC<
  CommitmentsDateRangeSelectorProps
> = ({ dates, onChange, PopoverProps, size = "small" }) => {
  const allDateRanges = useAppSelector(
    allDateRangesByQuarterStartMonthSelector,
  );

  const orgQuarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const { data } =
    useGetUsersMeOrganisationsCurrentSavingsPlansMinimumAvailableDateQuery();

  return (
    <DateRangePicker
      highlightToday
      size={size}
      ranges={allDateRanges}
      selectedDates={dates}
      onDateSelect={onChange}
      PopoverProps={PopoverProps}
      quarterStartMonth={orgQuarterStartMonth}
      availableMinDate={data?.minimum_available_date ?? undefined}
    />
  );
};
