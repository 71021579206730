import { FC } from "react";
import { Stack } from "@mui/material";

import { WidgetCardHeaderActionRow } from "./WidgetCardHeaderActionRow";
import { WidgetTitleProps } from "./source-and-name/WidgetSourceInfo";
import { WidgetSourceInfoV2 } from "./source-and-name/WidgetSourceInfoV2";
import { WidgetType } from "../../../../../../../services/cloudchipr.api";

interface WidgetCardHeaderProps {
  hovered: boolean;
  widgetId: string;
  widgetType: WidgetType;
  widgetTitleProps: WidgetTitleProps | null;
}

export const WidgetCardHeaderV2: FC<WidgetCardHeaderProps> = ({
  widgetId,
  widgetTitleProps,
  widgetType,
  hovered,
}) => {
  return (
    <Stack
      pl={2}
      pr={0}
      py={1}
      borderBottom={1}
      sx={{
        borderBottomColor: "grey.300",
        position: "sticky",
        top: 0,
        bgcolor: "white",
        zIndex: 2,
      }}
    >
      <WidgetCardHeaderActionRow
        widgetId={widgetId}
        hovered={hovered}
        widgetType={widgetType}
        widgetTitleProps={widgetTitleProps}
      />
      {widgetTitleProps && (
        <Stack spacing={0.5} overflow="hidden">
          <WidgetSourceInfoV2 {...widgetTitleProps} />
        </Stack>
      )}
    </Stack>
  );
};
