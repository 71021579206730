import { RootState } from "../../../../../store";
import { resourceExplorerProviderByProviderPossibleFiltersSelector } from "../resourceExplorerProviderByProviderPossibleFiltersSelector";
import { ResourceExplorerPossibleFilter } from "../../../../../../services/cloudchipr.api";

export const resourceExplorerGlobalPossibleFiltersSelector = (
  state: RootState,
): ResourceExplorerPossibleFilter[] | undefined => {
  return resourceExplorerProviderByProviderPossibleFiltersSelector({
    provider: "global",
  })(state)?.data;
};
