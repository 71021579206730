import { FC, useCallback, useState, Dispatch, SetStateAction } from "react";
import { List } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { ResourceExplorerCreditTypeFilterContentItem } from "./ResourceExplorerCreditTypeFilterContentItem";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { resourceExplorerPossibleFiltersByProviderSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/filters/possible-filters/resourceExplorerPossibleFiltersByProviderSelector";
import {
  ResourceExplorerFilterItem,
  ProviderType,
} from "../../../../../../../../../../../services/cloudchipr.api";

interface ResourceExplorerCreditTypeFilterContentProps {
  filter: ResourceExplorerFilterItem | null;
  setFilter: Dispatch<SetStateAction<ResourceExplorerFilterItem | null>>;
  provider: ProviderType;
}
export const ResourceExplorerCreditTypeFilterContent: FC<
  ResourceExplorerCreditTypeFilterContentProps
> = ({ setFilter, filter, provider }) => {
  const [options, setOptions] = useState<
    {
      value: string;
      title: string | null;
    }[]
  >([]);

  const possibleFilters = useAppSelector((state) =>
    resourceExplorerPossibleFiltersByProviderSelector(state, provider),
  )?.credit_type;

  useEffectOnceWhen(() => {
    setOptions(possibleFilters?.values || []);
  }, !!possibleFilters);

  const getUpdatedFilterValue = useCallback(
    (itemKey: string, prevFilter: ResourceExplorerFilterItem) => {
      if (!prevFilter?.value) {
        return [];
      }
      const index = prevFilter.value.indexOf(itemKey);
      if (index === -1) {
        return [...prevFilter.value, itemKey];
      } else {
        return prevFilter?.value?.filter((item) => item !== itemKey) ?? [];
      }
    },
    [],
  );

  const creditTypeValueChangeHandler = useCallback(
    (itemKey: string) => {
      setFilter((prevValue) => {
        if (!prevValue) {
          return null;
        }
        if (!prevValue?.value?.length) {
          return {
            ...prevValue,
            value: [itemKey],
          };
        }
        return {
          ...prevValue,
          value: getUpdatedFilterValue(itemKey, prevValue),
          include_values: !!getUpdatedFilterValue(itemKey, prevValue).length,
        };
      });
    },
    [setFilter, getUpdatedFilterValue],
  );
  const isAllSelected =
    possibleFilters?.values?.length === filter?.value?.length;

  const discountClickHandler = useCallback(() => {
    let value: string[] = [];
    if (!isAllSelected) {
      value = possibleFilters?.values.map((item) => item.value) ?? [];
    }
    setFilter((prevValue) => {
      if (!prevValue) {
        return null;
      }
      return {
        ...prevValue,
        value,
        include_values: !!value.length,
      };
    });
  }, [isAllSelected, setFilter, possibleFilters]);

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper", p: 0 }}
      component="nav"
    >
      <ResourceExplorerCreditTypeFilterContentItem
        title="Discounts"
        onClick={discountClickHandler}
        checked={isAllSelected}
        selected={isAllSelected}
        sx={{ pl: 1, pr: 0 }}
      />

      <List component="div" disablePadding>
        {options.map((item) => {
          return (
            <ResourceExplorerCreditTypeFilterContentItem
              title={item.title ?? item.value ?? ""}
              itemKey={item.value}
              onClick={creditTypeValueChangeHandler}
              checked={!!filter?.value?.includes(item.value)}
              selected={!!filter?.value?.includes(item.value)}
              key={item.value}
            />
          );
        })}
      </List>
    </List>
  );
};
