import { FC, memo } from "react";
import { CostByCategoryWidgetChart } from "./chart/CostByCategoryWidgetChart";
import { useAppSelector } from "../../../../../../../store/hooks";
import { MultiTypeChartProvider } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { costByCategoryViewChartDataSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-by-category/chart/costByCategoryViewChartDataSelector";
import { costByCategoryWidgetByIdSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-by-category/costByCategoryWidgetByIdSelector";
import { ChartType } from "../../../../../../../storybook/charts/multi-type-chart/utils/types/types";

interface CostByCategoryWidgetChartViewChartProps {
  widgetId: string;
}

export const CostByCategoryWidgetChartViewChart: FC<CostByCategoryWidgetChartViewChartProps> =
  memo(({ widgetId }) => {
    const widget = useAppSelector((state) =>
      costByCategoryWidgetByIdSelector(state, widgetId),
    );

    const chartType = widget?.chart_type as ChartType;
    const displayFilteredResources = widget?.display_filtered_resources;

    const chartData = useAppSelector((state) =>
      costByCategoryViewChartDataSelector(
        state,
        widgetId,
        chartType !== "pie" && displayFilteredResources,
      ),
    );

    if (!chartData || !widget) {
      return null;
    }

    return (
      <MultiTypeChartProvider
        value={{
          initialChartType: chartType,
          data: chartData,
        }}
      >
        <CostByCategoryWidgetChart
          date={{
            from: widget.date_from,
            to: widget.date_to,
            label: widget.date_label,
          }}
          frequency={widget?.date_granularity}
          chartType={chartType}
          widgetId={widgetId}
          displayFilteredResources={widget.display_filtered_resources}
        />
      </MultiTypeChartProvider>
    );
  });
