import { FC } from "react";
import { CountChipWithTooltip } from "../../../../../../../../common/resource-explorer/group-by-selector/components/CountChipWithTooltip";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { resourceExplorerGroupValuesSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupValuesSelector";

export const GroupingByTagChip: FC = () => {
  const groupByValues = useAppSelector(resourceExplorerGroupValuesSelector);

  if (!groupByValues?.length) {
    return null;
  }

  return <CountChipWithTooltip values={groupByValues} />;
};
