import { FC, useCallback } from "react";
import { CSVLink } from "react-csv";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { IconButton } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { resourceExplorerCsvFileNameSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/csv/resourceExplorerCsvFileNameSelector";
import { resourceExplorerCsvDataSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/csv/resourceExplorerCsvDataSelector";
import { resourceExplorerGroupingSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { resourceExplorerCSVDataLinkFetcherThunk } from "../../../../../../../../../store/resource-explorer/thunks/widgets/csv/resourceExplorerCSVDataLinkFetcherThunk";
import { resourceExplorerGranularViewCsvDataSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/csv/resourceExplorerGranularViewCsvDataSelector";
import { resourceExplorerDataGridViewSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerDataGridViewSelector";
import { resourceExplorerCsvDownloadLoadingSelector } from "../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/csv/resourceExplorerCsvDownloadLoadingSelector";

export const ResourceExplorerCsvDownloader: FC = () => {
  const dispatch = useAppDispatch();
  const csvFileName = useAppSelector(resourceExplorerCsvFileNameSelector);
  const groupBy = useAppSelector(resourceExplorerGroupingSelector);
  const preparingLoading = useAppSelector(
    resourceExplorerCsvDownloadLoadingSelector,
  );
  const dataGridView = useAppSelector(resourceExplorerDataGridViewSelector);
  const totalCsvData = useAppSelector(resourceExplorerCsvDataSelector);
  const granularCsvData = useAppSelector(
    resourceExplorerGranularViewCsvDataSelector,
  );
  const csvData = dataGridView === "granular" ? granularCsvData : totalCsvData;

  const downloadHandler = useCallback(async () => {
    await dispatch(resourceExplorerCSVDataLinkFetcherThunk());
  }, [dispatch]);

  if (groupBy === "resource") {
    return (
      <IconButton
        onClick={downloadHandler}
        size="small"
        disabled={preparingLoading}
      >
        <DownloadOutlinedIcon fontSize="small" />
      </IconButton>
    );
  }

  return (
    <CSVLink filename={`${csvFileName}.csv`} data={csvData ?? []}>
      <IconButton size="small">
        <DownloadOutlinedIcon color="action" fontSize="small" />
      </IconButton>
    </CSVLink>
  );
};
