import { generateFromToByPeriod } from "./period/generateFromToByPeriod";
import { generateDateFromDateLabel } from "./generateDateFromDateLabel";
import { Dates } from "../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";

export const calculateFromToByDatesData = (
  selectedDates: Dates,
  quarterStartMonth?: number,
): Record<"fromDate" | "toDate", string> => {
  let fromDate = "";
  let toDate = "";

  if (selectedDates.label) {
    const dates = generateDateFromDateLabel(
      selectedDates.label,
      quarterStartMonth,
    );

    const startDate = formatDate(dates.dateFrom?.toString(), {
      type: "date",
    });
    const endDate = formatDate(dates.dateTo?.toString(), {
      type: "date",
    });

    fromDate = startDate;
    toDate = endDate;
  }

  if (selectedDates.unit && selectedDates.value && selectedDates.type) {
    const { to, from } = generateFromToByPeriod(
      selectedDates.value,
      selectedDates.type,
      selectedDates.unit,
    );

    fromDate = from;
    toDate = to;
  }

  return { fromDate, toDate };
};
