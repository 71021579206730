import { FC, ReactNode } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import { PieChartOutlineOutlined } from "@mui/icons-material";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import StackedBarChartOutlinedIcon from "@mui/icons-material/StackedBarChartOutlined";
import TagIcon from "@mui/icons-material/Tag";
import { WidgetViewType } from "../../../../../../../../store/dashboards/utils/types/types";
interface WidgetChartTypeSelectProps {
  viewType: WidgetViewType;
  options: WidgetViewType[];
  onChange(e: SelectChangeEvent<WidgetViewType>): void;
}
export const WidgetViewTypeSelect: FC<WidgetChartTypeSelectProps> = ({
  viewType,
  onChange,
  options,
}) => {
  return (
    <Select
      size="small"
      fullWidth
      value={viewType}
      onChange={onChange}
      renderValue={(selected: WidgetViewType) => {
        return (
          <Stack direction="row" spacing={1}>
            {icons[selected]}

            <Typography variant="body1">{labels[selected]}</Typography>
          </Stack>
        );
      }}
    >
      {options.map((item) => {
        return (
          <MenuItem value={item} key={item}>
            <ListItemIcon>{icons[item]} </ListItemIcon>
            <ListItemText>{labels[item]}</ListItemText>
          </MenuItem>
        );
      })}
    </Select>
  );
};

const icons: Partial<Record<WidgetViewType, ReactNode>> = {
  bar: <BarChartIcon fontSize="small" color="action" />,
  stack: <StackedBarChartOutlinedIcon fontSize="small" color="action" />,
  line: <StackedLineChartIcon fontSize="small" color="action" />,
  pie: <PieChartOutlineOutlined fontSize="small" color="action" />,
  table: <TableChartOutlinedIcon fontSize="small" color="action" />,
  numeral: <TagIcon fontSize="small" color="action" />,
};

const labels: Partial<Record<WidgetViewType, string>> = {
  stack: "Stacked Chart",
  bar: "Bar Chart",
  line: "Line Chart",
  pie: "Pie Chart",
  table: "Table",
  numeral: "Number",
};
