import { FC } from "react";
import { LinearProgress, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

interface ResourceExplorerBudgetProgressProps {
  progress: number;
}

export const ResourceExplorerBudgetProgress: FC<
  ResourceExplorerBudgetProgressProps
> = ({ progress }) => {
  return (
    <Stack width={144}>
      <LinearProgress
        color="info"
        sx={{
          flexGrow: 1,
          height: 6,
          borderRadius: 1,
          bgcolor: "grey.100",
          "& .MuiLinearProgress-bar": {
            bgcolor: progress > 100 ? "red" : "primary",
          },
        }}
        variant="determinate"
        value={Math.min(progress, 100)}
      />

      <Stack justifyContent="space-between" direction="row" mt={0.2}>
        <Typography fontSize={10} color="text.secondary">
          {progress}%
        </Typography>

        {progress < 100 && (
          <Typography fontSize={10} color="text.secondary">
            {100 - progress}%
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
