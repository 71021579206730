import { FC, useEffect, useState } from "react";
import { DialogContent, Divider } from "@mui/material";
import { CleanResourceGrid } from "./components/CleanResourceGrid";
import { CleanConfirmationSection } from "./components/CleanConfirmationSection";
import { CleanDescriptionSection } from "./components/CleanDescriptionSection";
import { CleanExcludedResourcesInfo } from "./components/CleanExcludedResourcesInfo";
import { useAppSelector } from "../../../../../../../store/hooks";
import { newCleanResourceTypeSelector } from "../../../../../../../store/clean/selectors/newCleanResourceTypeSelector";
import { cleanResourcesGridDataSelector } from "../../../../../../../store/clean/selectors/cleanResourcesGridDataSelector";
import { CleanResourcesDataType } from "../../utils/types";

interface CleanDialogContentProps {
  cleanInputValue: string;
  onCleanInputChange(value: string): void;
  actionType: string;
}
export const CleanDialogContent: FC<CleanDialogContentProps> = ({
  cleanInputValue,
  onCleanInputChange,
  actionType,
}) => {
  const resourceType = useAppSelector(newCleanResourceTypeSelector);
  const [localCleanableData, setLocalCleanableData] =
    useState<CleanResourcesDataType>([]);

  const data = useAppSelector(cleanResourcesGridDataSelector);

  useEffect(() => {
    data?.length && setLocalCleanableData(data);
  }, [data]);

  if (!resourceType) {
    return null;
  }

  return (
    <DialogContent dividers sx={{ pb: 3, p: 0 }}>
      <CleanDescriptionSection resourceType={resourceType} />
      <CleanResourceGrid
        resourceType={resourceType}
        data={localCleanableData}
      />
      <CleanExcludedResourcesInfo actionType={actionType} />
      <Divider />
      <CleanConfirmationSection
        cleanInputValue={cleanInputValue}
        onCleanInputChange={onCleanInputChange}
        placeholder={actionType}
      />
    </DialogContent>
  );
};
