import { FC } from "react";
import { Stack } from "@mui/material";
import { Regions } from "./regions/Regions";
import { Toolbar } from "./toolbar/Toolbar";
import { AccountBreadcrumbs } from "./account-breadcrumbs/AccountBreadcrumbs";
import { HeaderLastUpdatedDate } from "./HeaderLastUpdatedDate";
import { Messages } from "../missing-permissions/Messages";
import { PageHeader } from "../../../common/PageHeader";
import { useAppSelector } from "../../../../../store/hooks";
import { currentAccountProviderTypeSelector } from "../../../../../store/account/selectors/current-account/currentAccountProviderTypeSelector";

export const HeaderV2: FC = () => {
  const currentAccountProviderType = useAppSelector(
    currentAccountProviderTypeSelector,
  );

  return (
    <PageHeader
      title={<AccountBreadcrumbs />}
      actions={
        <Stack direction="row" spacing={2} alignItems="center">
          <HeaderLastUpdatedDate />

          <Messages />

          {currentAccountProviderType && (
            <Regions provider={currentAccountProviderType} />
          )}
        </Stack>
      }
      extra={<Toolbar />}
    />
  );
};
