import { appliedFiltersSelector } from "./appliedFiltersSelector";
import {
  AccountResourceFilter2,
  ResourceType,
} from "../../../services/cloudchipr.api";
import { RootState } from "../../store";

export const appliedFiltersByResourceTypeSelector = (
  state: RootState,
  resourceType: ResourceType,
): AccountResourceFilter2 | null => {
  const appliedFilters = appliedFiltersSelector(state);

  return appliedFilters?.[resourceType] ?? null;
};
