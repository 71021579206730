import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { FC, useCallback } from "react";
import { Box } from "@mui/material";
import { Responsive, WidthProvider, Layout, Layouts } from "react-grid-layout";
import { useFlag } from "@unleash/proxy-client-react";
import { DashboardCostByCategoryWidget } from "./widgets/cost-by-category/DashboardCostByCategoryWidget";
import { EmptyDashboard } from "./corner-components/EmptyDashboard";
import { DashboardCostAndUsageSumWidget } from "./widgets/cost-and-usage/DashboardCostAndUsageSumWidget";
import { useDashboardWidgetsHook } from "./widgets/utils/hooks/useDashboardWidgets.hook";
import { DashboardLayoutAddingWidgetCardButton } from "./adding-widget/DashboardLayoutAddingWidgetCardButton";
import { WidgetWrapper } from "./WidgetWrapper";
import { LargestCostChangesWidget } from "./widgets/largest-cost-change/LargestCostChangesWidget";
import { DashboardCostBreakdownWidgetVersionsWrapper } from "./widgets/cost-breakdown/DashboardCostBreakdownWidgetVersionsWrapper";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { WidgetType } from "../../../../services/cloudchipr.api";
import { widgetSetupContentChartHeight } from "../utils/constants/stylings";
import { currentDashboardLayoutSelector } from "../../../../store/dashboards/selectors/dashboard/currentDashboardLayoutSelector";
import { updateDashboardByIdLayoutThunk } from "../../../../store/dashboards/thunks/dashboard/updateDashboardByIdLayoutThunk";
import {
  draggableHandleClassnames,
  reactGridLayoutBreakpoints,
  reactGridLayoutCols,
  reactGridLayoutMargin,
  reactGridLayoutStyles,
} from "../utils/constants/reactGridLayout";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface DashboardLayoutProps {
  dashboardId: string;
}

export const DashboardLayout: FC<DashboardLayoutProps> = ({ dashboardId }) => {
  const dispatch = useAppDispatch();
  const widgetsData = useDashboardWidgetsHook(dashboardId);
  const enableWidgetResize = useFlag("EnableWidgetResize");

  const layouts = useAppSelector(currentDashboardLayoutSelector);

  const handleLayoutChange = useCallback(
    (_: Layout[], newLayout: Layouts) => {
      if (dashboardId && enableWidgetResize) {
        dispatch(updateDashboardByIdLayoutThunk({ dashboardId, newLayout }));
      }
    },
    [dispatch, enableWidgetResize, dashboardId],
  );

  if (!widgetsData?.length) {
    return <EmptyDashboard />;
  }

  if (!layouts) {
    return null;
  }

  return (
    <Box pb={3} sx={reactGridLayoutStyles}>
      <ResponsiveGridLayout
        isDraggable={enableWidgetResize}
        isResizable={enableWidgetResize}
        layouts={layouts}
        className="layout"
        cols={reactGridLayoutCols}
        margin={reactGridLayoutMargin}
        onLayoutChange={handleLayoutChange}
        rowHeight={widgetSetupContentChartHeight}
        breakpoints={reactGridLayoutBreakpoints}
        draggableHandle={draggableHandleClassnames.wrapper}
      >
        {widgetsData?.map((widget) => {
          const Component = widgetsComponents[widget.type];

          if (!Component) {
            return null;
          }

          return (
            <WidgetWrapper key={widget.id} widgetId={widget.id}>
              <Component widgetId={widget.id} />
            </WidgetWrapper>
          );
        })}
      </ResponsiveGridLayout>

      <DashboardLayoutAddingWidgetCardButton />
    </Box>
  );
};

const widgetsComponents: Partial<Record<WidgetType, FC<any>>> = {
  costs_by_category_v2: DashboardCostByCategoryWidget,
  cost_breakdown: DashboardCostBreakdownWidgetVersionsWrapper,
  cost_and_usage_summary: DashboardCostAndUsageSumWidget,
  largest_cost_changes: LargestCostChangesWidget,
};
