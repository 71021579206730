import { FC, Fragment } from "react";
import { Container, Card, Divider, Typography } from "@mui/material";
import { useDidMount } from "rooks";
import { IntegrationsEmptyState } from "./IntegrationsEmptyState";
import { IntegrationsListGrid } from "./IntegrationsListGrid";
import { useGetUsersMeIntegrationsQuery } from "../../../../../services/cloudchipr.api";
import { getSchedulesThunk } from "../../../../../store/schedules/thunks/getSchedulesThunk";
import { useAppDispatch } from "../../../../../store/hooks";

export const IntegrationsList: FC = () => {
  const dispatch = useAppDispatch();
  const { data } = useGetUsersMeIntegrationsQuery({});

  useDidMount(() => {
    dispatch(getSchedulesThunk());
  });

  return (
    <Container sx={{ p: 2 }} maxWidth={false} disableGutters>
      <IntegrationsEmptyState />

      {!!data?.length && (
        <Fragment>
          <Card variant="outlined">
            <Typography variant="h5" fontWeight="bold" my={3} pl={3}>
              Integrations
            </Typography>
            <Divider />
            <IntegrationsListGrid />
          </Card>
        </Fragment>
      )}
    </Container>
  );
};
