import { currentAccountSelector } from "./currentAccountSelector";
import { RootState } from "../../../store";

export const currentAccountHasSatisfiedPermissionsSelector = (
  state: RootState,
): boolean | undefined => {
  const account = currentAccountSelector(state);

  return account?.has_satisfied_permissions;
};
