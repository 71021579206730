import { generateLargesCostChangesDateInfo } from "./generateLargesCostChangesDateInfo";
import { DateLabelNullable } from "../../../../../../../../../../services/cloudchipr.api";

interface FormatLargestCostChangesWidgetPrevDateArgs {
  date: {
    from?: string | null;
    to?: string | null;
    label?: DateLabelNullable;
  };
  quarterStartMonth?: number;
}
export const formatLargestCostChangesWidgetPrevDate = ({
  date,
  quarterStartMonth,
}: FormatLargestCostChangesWidgetPrevDateArgs) => {
  const formattedDate = date
    ? generateLargesCostChangesDateInfo(
        date,
        quarterStartMonth,
        "withoutPrefix",
      )
    : undefined;

  let midText = `${date?.label ? "Preceding" : ""}`;
  let label = formattedDate?.label;
  if (date?.label === "month_to_date") {
    label = "the same period in last month";
    midText = "";
  }
  if (date?.label === "quarter_to_date") {
    label = "the same period in last quarter";
    midText = "";
  }
  return {
    label: `${midText} ${label}`,
    range: `${formattedDate?.dateRange}`,
  };
};
