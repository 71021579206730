import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCostBreakdownWidgetDataBySetupThunk } from "./fetchCostBreakdownWidgetDataBySetupThunk";
import { RootState } from "../../../../store";
import { setWidgetSetup } from "../../../dashboardsSlice";
import { costBreakdownWidgetByIdSelector } from "../../../selectors/widgets/cost-breakdown/costBreakdownWidgetByIdSelector";
import { CostBreakdownWidgetSetupType } from "../../../utils/types/setups/costBreakdownSetupType";

export const editCostBreakdownWidgetThunk = createAsyncThunk(
  "dashboards/editCostBreakdownWidget",
  (widgetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const widget = costBreakdownWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    dispatch(
      setWidgetSetup({
        widgetType: "cost_breakdown",
        id: widget.id,
        viewType: widget.chart_type,
        frequency: widget.date_granularity,
        forecastOption: widget.forecast_option,
        name: widget.name,
        date: {
          from: widget.date_from,
          to: widget.date_to,
          label: widget.date_label ?? null,
        },
        grouping: widget.grouping,
        group_values: widget.group_values,
        viewId: widget.view_id,
      } as CostBreakdownWidgetSetupType),
    );

    dispatch(fetchCostBreakdownWidgetDataBySetupThunk());
  },
);
