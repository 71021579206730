import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCurrentAccountResourceTypeWithFiltersDataThunk } from "./getCurrentAccountResourceTypeWithFiltersDataThunk";
import { resourceTypesByProvider } from "../../../../utils/helpers/providers/resourceTypesByProvider";
import { currentAccountProviderTypeSelector } from "../../selectors/current-account/currentAccountProviderTypeSelector";
import { RootState } from "../../../store";

export const getCurrentAccountAllResourceTypesDataThunk = createAsyncThunk(
  "getCurrentAccountAllResourceTypesData",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;
    const provider = currentAccountProviderTypeSelector(state);

    if (!provider) {
      return;
    }

    const resourceTypes = resourceTypesByProvider(provider);
    resourceTypes?.forEach((resourceType) => {
      dispatch(getCurrentAccountResourceTypeWithFiltersDataThunk(resourceType));
    });
  },
);
