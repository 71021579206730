import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { costByCategorySetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-by-category/costByCategorySetupPropertyByKeySelector";
import { WidgetSetupSubHeader } from "../../../common/WidgetSetupSubHeader";

export const CostByCategorySetupHeader: FC = () => {
  const name = useAppSelector(costByCategorySetupPropertyByKeySelector("name"));
  const date = useAppSelector(costByCategorySetupPropertyByKeySelector("date"));
  const categoryGroup = useAppSelector(
    costByCategorySetupPropertyByKeySelector("categoryGroup"),
  );

  return (
    <WidgetSetupSubHeader
      name={name}
      icon="list"
      date={date}
      title={categoryGroup}
      menuTitle="Manage Categories"
      menuUrl="/settings/categories"
    />
  );
};
