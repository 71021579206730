import { FC, useCallback } from "react";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { ProviderLogo } from "../../../../common/provider-logo/ProviderLogo";
import { useAppSelector } from "../../../../../store/hooks";
import { resourceExplorerDimensionSelector } from "../../../../../store/resource-explorer/selectors/filters/dimension/resourceExplorerDimensionSelector";

interface ResourceExplorerProviderItemProps {
  value: ProviderType;
  onClick(value: string): void;
  selected?: boolean;
  disabled?: boolean;
}
export const ResourceExplorerProviderItem: FC<
  ResourceExplorerProviderItemProps
> = ({ disabled, value, onClick, selected }) => {
  const selectedDimension = useAppSelector(resourceExplorerDimensionSelector);

  const onClickHandler = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  return (
    <Tooltip
      arrow
      title={
        disabled ? "It is not possible to deselect all cloud providers" : ""
      }
    >
      <FormControlLabel
        sx={{
          ...formControlLabelStyles,
          opacity: selectedDimension ? 0.6 : 1,
          borderColor: selected && !disabled ? "primary.main" : "primary.light",
        }}
        onChange={onClickHandler}
        control={
          selectedDimension ? (
            <span />
          ) : (
            <Checkbox
              size="small"
              checked={selected}
              sx={{ p: 0, mr: 1, width: 15, height: 15 }}
            />
          )
        }
        disabled={disabled}
        label={<ProviderLogo provider={value} />}
      />
    </Tooltip>
  );
};

const formControlLabelStyles = {
  p: 1,
  m: 0,
  pr: 1.5,
  border: 1,
  width: 65,
  height: 36,
  borderRadius: 2,
  justifyContent: "center",
  "& span": { fontSize: 0 },
};
