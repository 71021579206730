import { RootState } from "../../../../../store";
import { coverageDataSelector } from "../coverageDataSelector";
import { commitmentsCSVDataGenerator } from "../../../../utils/helpers/commitmentsCSVDataGenerator";
import { utilizationAndCoverageDataViewTabSelector } from "../../view-options/utilizationAndCoverageDataViewTabSelector";
import { utilizationAndCoverageDrawerCoverageGroupingSelector } from "../../details-drawer/options/utilizationAndCoverageDrawerCoverageGroupingSelector";

export const coverageCSVDataSelector = (state: RootState) => {
  const data = coverageDataSelector(state);
  const viewTab = utilizationAndCoverageDataViewTabSelector(state);
  const grouping = utilizationAndCoverageDrawerCoverageGroupingSelector(state);
  return commitmentsCSVDataGenerator({ viewTab, grouping, data });
};
