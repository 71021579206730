import { resourceExplorerViewsDataSelector } from "./resourceExplorerViewsDataSelector";
import { RootState } from "../../../store";

export const resourceExplorerViewNameByViewIdSelector = (
  state: RootState,
  viewId?: string,
): string | undefined => {
  if (!viewId) {
    return;
  }

  const views = resourceExplorerViewsDataSelector(state);

  return views.find((item) => item.id === viewId)?.name;
};
