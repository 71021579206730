import { FC, useMemo } from "react";
import { grey } from "@mui/material/colors";
import { Theme } from "@mui/material";
import { getCostBreakdownWidgetTableViewColumns } from "./columns/getCostBreakdownWidgetTableViewColumns";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { DataGrid } from "../../../../../../../../../storybook/data-grid/DataGrid";
import { costBreakdownWidgetTableViewDataSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/table/costBreakdownWidgetTableViewDataSelector";
import { widgetSetupContentChartHeight } from "../../../../../../utils/constants/stylings";
import { costBreakdownWidgetForecastOptionSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownWidgetForecastOptionSelector";
import { costBreakdownWidgetGroupingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownWidgetGroupingSelector";

interface CostBreakdownWidgetTableViewProps {
  widgetId?: string;
}

export const CostBreakdownWidgetTableView: FC<
  CostBreakdownWidgetTableViewProps
> = ({ widgetId }) => {
  const gridData =
    useAppSelector((state) =>
      costBreakdownWidgetTableViewDataSelector(state, widgetId),
    ) ?? [];
  const grouping = useAppSelector((state) =>
    costBreakdownWidgetGroupingSelector(state, widgetId),
  );

  const forecastOption = useAppSelector((state) =>
    costBreakdownWidgetForecastOptionSelector(state, widgetId),
  );

  const columns = useMemo(() => {
    return getCostBreakdownWidgetTableViewColumns({
      widgetId,
      forecastOption,
      grouping,
    });
  }, [widgetId, forecastOption, grouping]);

  return (
    <DataGrid
      styles={styles}
      columns={columns}
      data={gridData ?? []}
      enableRowsVirtualization={gridData.length > 30}
    />
  );
};

const styles = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
    "& tr:hover td": { bgcolor: grey[100] },
    "& tr td": { borderColor: grey[100], py: 0.5, pl: 2 },
  },

  tableHeaderRow: {
    "& th": {
      p: 1,
      verticalAlign: "inherit",
      bgcolor: grey[100],
    },
    top: 0,
    position: "sticky",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 1,
  },
  tableRow: {
    height: 40,
  },

  tableContainer: {
    border: 1,
    borderColor: grey[200],
    maxHeight: `${widgetSetupContentChartHeight}px`,
  },
};
