import { RootState } from "../../../store";
import {
  cloudChiprApi,
  ProviderOrganisation,
} from "../../../../services/cloudchipr.api";

const selector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentProviderOrganisations.select();

export const organizationsForCommitmentsSelector = (
  state: RootState,
): ProviderOrganisation[] | undefined => {
  return selector(state)?.data;
};
