import { Integration } from "../../../../../../services/cloudchipr.api";
import { IntegrationType } from "../../../../integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";
import { IntegrationNotification } from "../types/types";

export const generateIntegrationMetadata = (
  integration: Integration,
  type: IntegrationType,
): IntegrationNotification => {
  const conversation =
    integration?.conversation ?? integration?.metadata?.conversation;
  const slackConversations = conversation ? [conversation] : undefined;
  const metadataEmails = integration?.emails ?? integration?.metadata?.emails;

  const integrationMetadata: IntegrationNotification = {
    integration_id: integration.id,
    type: type,
  };

  if (type === "slack" && slackConversations) {
    integrationMetadata.conversations = slackConversations;
  } else if (type === "email" && metadataEmails) {
    integrationMetadata.to = metadataEmails;
  }

  return integrationMetadata;
};
