import moment from "moment/moment";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";
import { PreDefinedRange } from "../constants/types";

export const generateDateRangeString = (range: PreDefinedRange) => {
  const isToDateInFuture = range.endDate > new Date();
  const dateTo = isToDateInFuture ? moment() : range.endDate.toString();

  return `${formatDate(range.startDate.toString(), {
    type: "date",
  })} - ${formatDate(dateTo, { type: "date" })}`;
};
