import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { AddingWidget } from "../adding-widget/AddingWidget";
import EmptyWidgets from "../../../../../assets/images/empty_widgets.svg";

export const EmptyDashboard: FC = () => {
  return (
    <Stack alignItems="center" mt={6} pt={16}>
      <img src={EmptyWidgets} alt="EmptyDashboard" />

      <Typography variant="h5" fontWeight="bold" mt={5}>
        Start building your dashboard
      </Typography>

      <Typography
        mt={2}
        variant="h6"
        fontWeight="normal"
        color="text.secondary"
        width={640}
        align="center"
      >
        Click the "Add Widget" button to choose from a variety of widgets that
        suit your needs. Widgets allow you to visualize data in different ways,
        providing insights and trends.{" "}
      </Typography>

      <Box mt={4}>
        <AddingWidget />
      </Box>
    </Stack>
  );
};
