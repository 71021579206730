import { RootState } from "../../../../../../store";
import { costBreakdownWidgetViewDataGridDataSelector } from "../../costBreakdownWidgetViewDataGridDataSelector";
import { costBreakdownWidgetDataSelector } from "../../costBreakdownWidgetDataSelector";
import { formatCostBreakdownREDataGridData } from "../../../../../utils/helpers/costBreakdown/formatCostBreakdownREDataGridData";
import { ResourceExplorerGridDataWithId } from "../../../../../../resource-explorer/utils/types/resourceExplorerDataGrid";

export const costBreakdownWidgetViewDataSelector = (
  state: RootState,
  widgetId?: string,
): ResourceExplorerGridDataWithId[] | undefined => {
  if (!widgetId) {
    return costBreakdownWidgetViewDataGridDataSelector(state, true) ?? [];
  }

  const widgetWithIdTableData = costBreakdownWidgetDataSelector(
    state,
    widgetId,
  )?.total;

  return (
    formatCostBreakdownREDataGridData(widgetWithIdTableData ?? [], true) ?? []
  );
};
