import { FC, memo, useCallback, useState } from "react";
import { Stack, TextField } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useDebounce } from "rooks";
import { WidgetSetupHeaderActions } from "./WidgetSetupHeaderActions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { buildWidgetLoadingSelector } from "../../../../../../../../store/dashboards/selectors/widgets/common/buildWidgetLoadingSelector";
import { widgetSetupWidgetNameSelector } from "../../../../../../../../store/dashboards/selectors/setups/common/widgetSetupWidgetNameSelector";
import { widgetSetupWidgetIdSelector } from "../../../../../../../../store/dashboards/selectors/setups/common/widgetSetupWidgetIdSelector";
import { setWidgetSetupName } from "../../../../../../../../store/dashboards/dashboardsSlice";

interface WidgetSetupHeaderProps {
  disabled?: boolean;
  onAdd(): void;
  onCancel(): void;
}

export const WidgetSetupHeader: FC<WidgetSetupHeaderProps> = memo(
  ({ onCancel, onAdd, disabled }) => {
    const dispatch = useAppDispatch();

    const loading = useAppSelector(buildWidgetLoadingSelector);
    const setupId = useAppSelector(widgetSetupWidgetIdSelector);
    const name = useAppSelector(widgetSetupWidgetNameSelector);

    const [nameValue, setNameValue] = useState(name);

    const onNameDebouncedChange = useDebounce(
      useCallback(
        (name: string) => dispatch(setWidgetSetupName(name)),
        [dispatch],
      ),
      500,
    );

    const nameChangeHandler = useCallback(
      (e: any) => {
        const value = e.target.value;

        setNameValue(value);
        onNameDebouncedChange(value);
      },
      [onNameDebouncedChange],
    );

    return (
      <Stack
        p={2}
        borderBottom={1}
        borderColor={grey[300]}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <TextField
          size="small"
          value={nameValue}
          label="Widget Name"
          sx={{ minWidth: 430 }}
          onChange={nameChangeHandler}
        />

        <WidgetSetupHeaderActions
          disabled={disabled}
          action={setupId ? "update" : "create"}
          onAdd={onAdd}
          onCancel={onCancel}
          loading={loading}
        />
      </Stack>
    );
  },
);
