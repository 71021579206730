import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { costBreakdownWidgetTableViewDataCountSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/table/costBreakdownWidgetTableViewDataCountSelector";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { costBreakdownWidgetTableViewGroupingSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/table/costBreakdownWidgetTableViewGroupingSelector";
import { costBreakdownWidgetProvidersSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownWidgetProvidersSelector";
import { getResourcesExplorerGroupingLabel } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";

interface CostBreakdownWidgetTableViewLabelHeaderCellProps {
  widgetId?: string;
}
export const CostBreakdownWidgetTableViewLabelHeaderCell: FC<
  CostBreakdownWidgetTableViewLabelHeaderCellProps
> = ({ widgetId }) => {
  const dataCount = useAppSelector((state) =>
    costBreakdownWidgetTableViewDataCountSelector(state, widgetId),
  );
  const grouping = useAppSelector((state) =>
    costBreakdownWidgetTableViewGroupingSelector(state, widgetId),
  );
  const providers = useAppSelector((state) =>
    costBreakdownWidgetProvidersSelector(state, widgetId),
  );
  if (!grouping) {
    return null;
  }

  return (
    <Stack pl={1}>
      {getResourcesExplorerGroupingLabel(providers, grouping, true)}
      <Typography fontSize={10} color="text.disabled" fontWeight={500}>
        {dataCount}
      </Typography>
    </Stack>
  );
};
