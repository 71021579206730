import { costBreakdownV2WidgetTotalDataWithIdsSelector } from "./costBreakdownV2WidgetTotalDataWithIdsSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerGridDataWithId } from "../../../../../resource-explorer/utils/types/resourceExplorerDataGrid";
import { formatCostBreakdownV2WidgetTableViewLastGridItem } from "../utils/helpers/formatCostBreakdownV2WidgetTableViewLastGridItem";

export const costBreakdownV2WidgetTableViewDataSelector = (
  state: RootState,
  widgetId: string,
): ResourceExplorerGridDataWithId[] => {
  const data = costBreakdownV2WidgetTotalDataWithIdsSelector(state, widgetId);

  if (!data) {
    return [];
  }

  const lastItems = data.slice(itemsCount, data?.length);
  const startItems = data.slice(0, itemsCount);

  const lastItem = formatCostBreakdownV2WidgetTableViewLastGridItem(lastItems);

  const completeData = [...startItems];

  if (lastItems.length) {
    completeData.push(lastItem);
  }

  return completeData;
};

const itemsCount = 9;
