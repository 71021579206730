import { RootState } from "../../../../../store";
import { SavingsPlanDateGranularDataPoint } from "../../../../../../services/cloudchipr.api";
import { commitmentsDataForGranularSelector } from "../commitmentsDataForGranularSelector";

export const savingsPlansGranularDataSelector = (
  state: RootState,
): SavingsPlanDateGranularDataPoint[] | undefined => {
  const data = commitmentsDataForGranularSelector(state);

  return data?.date_granular_data?.data;
};
