import { FC, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { FilterDropdownTrigger } from "../../../FilterDropdownTrigger";
import { getResourcesExplorerGroupingLabel } from "../../../../../resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import {
  ResourceExplorerFilterItem,
  ProviderType,
} from "../../../../../../../../../services/cloudchipr.api";
import { resourceExplorerFilterByKeyAndProviderSelector } from "../../../../../../../../../store/resource-explorer/selectors/filters/resourceExplorerFilterByKeyAndProviderSelector";
import { resourceExplorerPossibleFiltersByProviderSelector } from "../../../../../../../../../store/resource-explorer/selectors/filters/possible-filters/resourceExplorerPossibleFiltersByProviderSelector";
import { isDeepEqual } from "../../../../../../../../../utils/is-deep-equal";
import { DropdownSelect } from "../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { instanceFamilyFilterChangeThunk } from "../../../../../../../../../store/resource-explorer/thunks/filters/instanceFamilyFilterChangeThunk";
import { dbSelectedInstanceFamilySelector } from "../../../../../../../../../store/resource-explorer/selectors/filters/selected/dbSelectedInstanceFamilySelector";

interface ResourceExplorerInstanceFamilyFilterProps {
  viewId: string;
  provider: ProviderType;
}

export const ResourceExplorerInstanceFamilyFilter: FC<
  ResourceExplorerInstanceFamilyFilterProps
> = ({ viewId, provider }) => {
  const dispatch = useDispatch();
  const filter = useAppSelector((state) =>
    resourceExplorerFilterByKeyAndProviderSelector(
      state,
      "instance_family",
      provider,
    ),
  );

  const possibleFilters = useAppSelector((state) =>
    resourceExplorerPossibleFiltersByProviderSelector(state, provider),
  )?.instance_family;

  const selectedFilter = useAppSelector((state) =>
    dbSelectedInstanceFamilySelector(state, provider),
  );

  const options = useMemo(() => {
    return (
      possibleFilters?.values?.map((option) => {
        return {
          value: option.value,
          label: option.title || option.value,
        };
      }) ?? []
    );
  }, [possibleFilters]);

  const submitHandlerOnClose = useCallback(
    (value: string[]) => {
      const newFilter: ResourceExplorerFilterItem = {
        ...filter,
        value,
        key: "instance_family",
        operator: "in",
        cloud_provider: provider,
        include_values: filter?.include_values ?? null,
      };

      const isEqual = filter && isDeepEqual(newFilter, filter);

      if (!isEqual) {
        dispatch(
          instanceFamilyFilterChangeThunk({
            filter: newFilter,
            provider,
          }),
        );
      }
    },
    [dispatch, filter, provider],
  );

  const label =
    getResourcesExplorerGroupingLabel([provider], "instance_family") ?? "";

  const TriggerComponent = useCallback(
    (props: any) => (
      <div>
        <FilterDropdownTrigger
          {...props}
          viewId={viewId}
          provider={provider}
          filterKey="instance_family"
          title={label}
        />
      </div>
    ),
    [provider, viewId, label],
  );

  return (
    <DropdownSelect
      label="Instance Family"
      options={options}
      initialSelectedValues={(selectedFilter.value ?? []) as string[]}
      submitHandlerOnClose={submitHandlerOnClose}
      TriggerComponent={TriggerComponent}
      values={(selectedFilter?.value ?? []) as string[]}
    />
  );
};
