import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCurrentAccountAllResourceTypesDataWithoutFilterThunk } from "./filters-get/getCurrentAccountAllResourceTypesDataWithoutFilterThunk";
import { filterTemplatesEnabledSelector } from "../selectors/current-account/resources/filterTemplatesEnabledSelector";
import { ResourceType } from "../../../services/cloudchipr.api";
import { setFilterTemplatesEnabled } from "../accountSlice";
import { RootState } from "../../store";

interface FilterTemplateChangeThunkArgs {
  resourceTypes: ResourceType[];
  toggledResourceTypes?: Map<ResourceType, boolean>;
}
export const filterTemplateEnabledChangeThunk = createAsyncThunk(
  "account/filterTemplateChange",
  async (
    { resourceTypes, toggledResourceTypes }: FilterTemplateChangeThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const filterTemplatesEnabled = filterTemplatesEnabledSelector(state);
    const value = !filterTemplatesEnabled;
    dispatch(setFilterTemplatesEnabled(value));
    if (!value) {
      dispatch(getCurrentAccountAllResourceTypesDataWithoutFilterThunk());
    }
  },
);
