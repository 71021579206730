import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../services/cloudchipr.api";

export const getCategoryByIdThunk = createAsyncThunk(
  "dashboards/getCategoryById",
  async (categoryId: string, { dispatch }) => {
    const { getUsersMeCategoriesByCategoryId } = cloudChiprApi.endpoints;

    try {
      return await dispatch(
        getUsersMeCategoriesByCategoryId.initiate({
          categoryId: categoryId,
        }),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
