import { FC, Fragment, useCallback } from "react";
import { Table } from "@tanstack/react-table";
import { Pagination as MuiPagination, Stack, Typography } from "@mui/material";

export type PaginationChangeHandler = (
  pageIndex: number,
  table: Table<any>,
) => void;

interface PaginationProps {
  table: Table<any>;
  placement?: "right" | "left";
  onPageChange?: PaginationChangeHandler;
}

export const Pagination: FC<PaginationProps> = ({
  placement = "right",
  table,
  onPageChange,
}) => {
  const pageChangeHandler = useCallback(
    (_: unknown, page: number) => {
      table.setPageIndex(page - 1);
      onPageChange?.(page - 1, table);
    },
    [table, onPageChange],
  );

  const pageIndex = table.getState().pagination.pageIndex + 1;

  const pageSize = table.getState().pagination.pageSize;

  const tablePrePaginationRowsCount =
    table.getPrePaginationRowModel().rows.length;

  const firstNumber = pageSize * pageIndex - pageSize + 1;

  const secondNumber =
    pageSize * pageIndex > tablePrePaginationRowsCount
      ? tablePrePaginationRowsCount
      : pageSize * pageIndex;

  return (
    <Fragment>
      {table.getPageCount() > 1 && (
        <Stack
          direction="row"
          justifyContent={placementMap.get(placement)}
          pl={1}
          pr={0}
          gap={2}
        >
          <Typography
            fontSize={12}
            variant="caption"
            alignSelf="center"
            color="text.primary"
          >
            {firstNumber} - {secondNumber} of {tablePrePaginationRowsCount}
          </Typography>
          <MuiPagination
            page={pageIndex}
            onChange={pageChangeHandler}
            count={table.getPageCount()}
          />
        </Stack>
      )}
    </Fragment>
  );
};

const placementMap = new Map([
  ["left", "start"],
  ["right", "end"],
]);
