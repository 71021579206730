import { FC, memo, useCallback, useState } from "react";
import {
  Box,
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import { ResourceExplorerMetadata } from "./components/resource-explorer-metadata/ResourceExplorerMetadata";
import { ResourceExplorerChart } from "./components/resource-explorer-chart/ResourceExplorerChart";
import { ResourceExplorerTable } from "./components/resource-explorer-data-grid/ResourceExplorerTable";
import { useAppSelector } from "../../../../../store/hooks";
import { isCurrentResourceExplorerInitializedSelector } from "../../../../../store/resource-explorer/selectors/current-resource-explorer/checkers/isCurrentResourceExplorerInitializedSelector";
import { ProviderBillingDataAccessController } from "../../../common/billing/billing-data-access-controller/ProviderBillingDataAccessController";
import { enabledProvidersSelector } from "../../../../../store/common/selectors/billing-analytics/enabled-providers/enabledProvidersSelector";
import { isResourceExplorerAvailableSelector } from "../../../../../store/resource-explorer/selectors/current-resource-explorer/checkers/isResourceExplorerAvailableSelector";
import { resourceExplorerChartDataSelector } from "../../../../../store/resource-explorer/selectors/current-resource-explorer/chart/resourceExplorerChartDataSelector";
import { MultiTypeChartProvider } from "../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";

interface ResourceExplorerCardProps {
  viewId: string;
}
export const ResourceExplorerCard: FC<ResourceExplorerCardProps> = memo(
  ({ viewId }) => {
    const chartData = useAppSelector(resourceExplorerChartDataSelector);
    const enabledProviders = useAppSelector(enabledProvidersSelector);
    const enabledProvidersArr = Array.from(enabledProviders);

    const resourceExplorerInitialized = useAppSelector((state) =>
      isCurrentResourceExplorerInitializedSelector(state, viewId),
    );
    const resourceExplorerAvailable = useAppSelector(
      isResourceExplorerAvailableSelector,
    );

    const [visibleContent, setVisibleContent] = useState<
      "chart" | "grid" | "chart_grid"
    >("chart_grid");

    const visibleContentChangeHandler = useCallback(
      (_: unknown, visibleContent: "chart" | "grid" | "chart_grid") => {
        if (!visibleContent) {
          return;
        }
        setVisibleContent(visibleContent);
      },
      [],
    );

    if (!resourceExplorerInitialized) {
      return null;
    }

    if (!resourceExplorerAvailable) {
      const provider = enabledProvidersArr[0];
      return <ProviderBillingDataAccessController provider={provider} />;
    }

    return (
      <Stack bgcolor="white" p={2} flexGrow={1}>
        <ResourceExplorerMetadata />

        <MultiTypeChartProvider
          value={{
            initialChartType: "stack",
            data: chartData ?? [],
          }}
        >
          {visibleContent !== "grid" && (
            <Box position="relative" zIndex={2}>
              <ResourceExplorerChart viewId={viewId} />
            </Box>
          )}

          <Stack direction="row" alignItems="center" my={2}>
            <Divider sx={{ flexGrow: 1 }} />

            <ToggleButtonGroup
              exclusive
              size="small"
              sx={{
                "& .Mui-selected svg": {
                  color: "primary.main",
                },
              }}
              value={visibleContent}
              onChange={visibleContentChangeHandler}
            >
              <ToggleButton
                value="chart_grid"
                sx={{ borderRadius: "8px 0 0 8px" }}
              >
                <ViewAgendaIcon />
              </ToggleButton>

              <ToggleButton value="chart">
                <VideoLabelIcon />
              </ToggleButton>

              <ToggleButton value="grid" sx={{ borderRadius: "0 8px 8px 0" }}>
                <VideoLabelIcon sx={{ transform: "rotate(180deg)" }} />
              </ToggleButton>
            </ToggleButtonGroup>

            <Divider sx={{ flexGrow: 1 }} />
          </Stack>

          {visibleContent !== "chart" && (
            <ResourceExplorerTable fullHeight={visibleContent === "grid"} />
          )}
        </MultiTypeChartProvider>
      </Stack>
    );
  },
);
