import { getCurrentProvidersByProviderBillingStatusSelector } from "../../common/selectors/billing-status/getCurrentProvidersByProviderBillingStatusSelector";
import { RootState } from "../../store";

export const missingSetupSelector = (state: RootState): boolean => {
  const billingStatuses = getCurrentProvidersByProviderBillingStatusSelector(
    state,
    "aws",
  ).data?.billing_account_statuses;

  return !!billingStatuses?.some(({ status }) => status === "missing_setup");
};
