import { FC, useCallback } from "react";
import { Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { setWidgetSelectorState } from "../../../../../../../store/dashboards/dashboardsSlice";
import { useAppDispatch } from "../../../../../../../store/hooks";

export const CostByCategoryCardDisabledAction: FC = () => {
  const dispatch = useAppDispatch();
  const clickHandler = useCallback(() => {
    dispatch(setWidgetSelectorState(false));
  }, [dispatch]);

  return (
    <Typography variant="caption" color="white" align="center" component="div">
      Please{" "}
      {
        <Link
          to="/settings/categories"
          underline="always"
          component={RouterLink}
          onClick={clickHandler}
          sx={{ color: "info.main" }}
        >
          add category group
        </Link>
      }{" "}
      to get started
    </Typography>
  );
};
