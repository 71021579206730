import { FC, Fragment, useMemo } from "react";
import { Stack, Tooltip } from "@mui/material";
import { useAppSelector } from "../../../../../../../../store/hooks";
import CompareIcon from "../../../../../../../../assets/images/icons/compare.svg";
import { generateLargestCostChangesWidgetDateCompareInfo } from "../../../../adding-widget/widget-create/widget-setups/larges-cost-changes/utils/helpers/generateLargestCostChangesWidgetDateCompareInfo";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { LargestCostChangesPrevDateDetails } from "../../../../adding-widget/widget-create/widget-setups/larges-cost-changes/content/components/LargestCostChangesPrevDateDetails";
import { DateInfoChip } from "../../../common/widget-header/source-and-name/DateInfoChip";
import {
  DateLabelNullable,
  NullableDate,
} from "../../../../../../../../services/cloudchipr.api";

export interface LargestCostChangesWidgetCardHeaderDateProps {
  prevDate?: {
    from?: NullableDate;
    to?: NullableDate;
  };
  date?: { from?: NullableDate; to?: NullableDate; label?: DateLabelNullable };
}

export const LargestCostChangesWidgetCardHeaderDate: FC<
  LargestCostChangesWidgetCardHeaderDateProps
> = ({ prevDate, date }) => {
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const dateInfo = useMemo(() => {
    return generateLargestCostChangesWidgetDateCompareInfo({
      prevDate: {
        ...prevDate,
        label: date?.label,
      },
      date,
      quarterStartMonth,
    });
  }, [quarterStartMonth, prevDate, date]);

  const tooltipTitle = `${dateInfo.startDateLabel} ${
    dateInfo.startDateRange && `(${dateInfo.startDateRange})`
  } compared with ${dateInfo.endDateLabel} ${
    dateInfo.endDateRange && `(${dateInfo.endDateRange})`
  } `;

  return (
    <Fragment>
      <DateInfoChip {...date} tooltipPlacement="top" />
      <Tooltip
        title={tooltipTitle}
        arrow
        placement="top"
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: 630,
            },
          },
        }}
      >
        <Stack alignItems="center">
          <img src={CompareIcon} alt="compare" />
        </Stack>
      </Tooltip>
      <LargestCostChangesPrevDateDetails
        date={{
          ...prevDate,
          label: date?.label,
        }}
      />
    </Fragment>
  );
};
