import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { resourceExplorerIdSelector } from "../../selectors/current-resource-explorer/resourceExplorerIdSelector";
import { resourceExplorerViewDataByIdSelector } from "../../selectors/resource-explorer-by-id/resourceExplorerViewDataByIdSelector";
import { resourceExplorerDefaultData } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/constants/common";
import {
  setMultiFilters,
  setProviders,
  setResourceExplorerData,
} from "../../resourceExplorerSlice";
import { setResourceExplorerPathThunk } from "../widgets/path/setResourceExplorerPathThunk";
import { enabledProvidersSelector } from "../../../common/selectors/billing-analytics/enabled-providers/enabledProvidersSelector";
import { getResourceExplorerMutatedFilters } from "../../utils/helpers/getResourceExplorerMutatedFilters";
import { resourceExplorerAllPossibleInitialFiltersSelector } from "../../selectors/filters/possible-filters/resourceExplorerAllPossibleInitialFiltersSelector";
import { getResourceExplorerDataThunk } from "../widgets/getResourceExplorerDataThunk";

export const resetResourceExplorerThunk = createAsyncThunk(
  "resourceExplorer/resetResourceExplorer",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const viewId = resourceExplorerIdSelector(state);
    const viewData = resourceExplorerViewDataByIdSelector(state, viewId);

    if (!viewData) {
      return;
    }

    const enabledProviders = enabledProvidersSelector(state);
    const enabledProviderArr = Array.from(enabledProviders);
    const cloudProviders = viewData.cloud_providers ?? [];

    const filters = getResourceExplorerMutatedFilters(
      viewData.filters,
      resourceExplorerAllPossibleInitialFiltersSelector(state),
      cloudProviders,
      enabledProviderArr,
    );
    const data =
      viewData.is_default && !viewData.data
        ? resourceExplorerDefaultData
        : { ...viewData.data, grouping: viewData.data.grouping ?? "service" };

    dispatch(setProviders(cloudProviders));
    dispatch(setMultiFilters(filters));

    dispatch(setResourceExplorerData(data));
    dispatch(getResourceExplorerDataThunk());
    dispatch(setResourceExplorerPathThunk(false));
  },
);
