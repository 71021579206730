import { useCallback } from "react";
import { Button, Stack, Theme } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { SxProps } from "@mui/system";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { appliedFiltersByResourceTypeSelector } from "../../../../../../../../store/filters/selectors/appliedFiltersByResourceTypeSelector";
import { getResourceTypeName } from "../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { RootState } from "../../../../../../../../store/store";
import { FilterSourceButton } from "../../../../../../common/filters/filters-selection/utils/types/types";

export const ResourceFiltersButtonViewSourceButton: FilterSourceButton = ({
  resourceType,
  openDialog,
}) => {
  const selector = useCallback(
    (state: RootState) => {
      return appliedFiltersByResourceTypeSelector(state, resourceType)
        ?.filter_items?.length;
    },
    [resourceType],
  );

  const appliedFiltersLength = useAppSelector(selector);

  return (
    <Button
      disableElevation
      size="small"
      onClick={openDialog}
      endIcon={<KeyboardArrowDownOutlinedIcon />}
      sx={filterSourceButtonStyles(!!appliedFiltersLength)}
      variant={appliedFiltersLength ? "contained" : "outlined"}
    >
      <Stack direction="row" gap={1}>
        <FilterAltOutlinedIcon
          fontSize="small"
          color={appliedFiltersLength ? "primary" : "inherit"}
        />
        {getResourceTypeName(resourceType)} ({appliedFiltersLength})
      </Stack>
    </Button>
  );
};

const filterSourceButtonStyles = (active: boolean): SxProps<Theme> => ({
  py: 0.5,
  height: 32,
  borderRadius: 4,
  textTransform: "none",
  whiteSpace: "nowrap",
  minWidth: "auto",
  bgcolor: active ? "grey.100" : "tertiary",
  color: ({ palette }) =>
    active ? palette.primary.main : palette.tertiary.main,
  borderColor: ({ palette }) =>
    active ? palette.primary.main : palette.tertiary.main,
  "&:hover": {
    bgcolor: ({ palette }) => (active ? palette.primary.light : "tertiary"),
    borderColor: ({ palette }) =>
      active ? palette.primary.main : palette.tertiary.main,
  },
});
