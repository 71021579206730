import { RootState } from "../../../../store";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { getCommitmentsRecommendationsPostFixedCacheKey } from "../../../utils/helpers/fixedCacheKeys";
import { commitmentsCurrentOrgIdSelector } from "../../commitmentsCurrentOrgIdSelector";

export const commitmentsRecommendationsSelector = (state: RootState) => {
  const orgId = commitmentsCurrentOrgIdSelector(state);

  return cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentSavingsPlansRecommendationsSummary.select(
    getCommitmentsRecommendationsPostFixedCacheKey(orgId),
  )(state);
};
