import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCurrentAccountResourceTypeWithoutFiltersDataThunk } from "./getCurrentAccountResourceTypeWithoutFiltersDataThunk";
import { getCurrentAccountResourceTypeWithFiltersDataThunk } from "./getCurrentAccountResourceTypeWithFiltersDataThunk";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { currentAccountIdSelector } from "../../selectors/current-account/currentAccountIdSelector";
import { RootState } from "../../../store";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { resourceViewIsWithoutFiltersSelector } from "../../selectors/current-account/resources/resourceViewIsWithoutFiltersSelector";
import { filterTemplatesEnabledSelector } from "../../selectors/current-account/resources/filterTemplatesEnabledSelector";

export const getCurrentAccountResourceTypeDataThunk = createAsyncThunk(
  // todo: remove "...Thunk" word from the end, from all thunks, webstorm understands that as a usage, it's confusing
  "getCurrentAccountResourceTypeDataThunk",
  async (resourceType: ResourceType, { dispatch, getState }) => {
    const state = getState() as RootState;

    const showingAllResources = resourceViewIsWithoutFiltersSelector(
      state,
      resourceType,
    );
    const filterTemplatesEnabled = filterTemplatesEnabledSelector(state);

    const accountId = currentAccountIdSelector(state);

    const type =
      getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;

    if (!accountId) {
      return;
    }

    if (showingAllResources || !filterTemplatesEnabled) {
      await dispatch(
        getCurrentAccountResourceTypeWithoutFiltersDataThunk(type),
      );
    } else {
      await dispatch(getCurrentAccountResourceTypeWithFiltersDataThunk(type));
    }
  },
);
