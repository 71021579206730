import { FC, useCallback, useMemo } from "react";
import { PopoverProps, Typography } from "@mui/material";
import { FilterButton } from "../../filters/components/FilterButton";
import { DropdownSelect } from "../../../../../common/select/dropdown-select/DropdownSelect";
import { filterByDimensionDropdownFilterFn } from "../utils/filterByDimensionDropdownFilterFn";
import { FilterTriggerComponentProps } from "../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { setResourceExplorerCategoryIds } from "../../../../../../store/resource-explorer/resourceExplorerSlice";
import { possibleFilterSelectedDimensionCategoriesSelector } from "../../../../../../store/resource-explorer/selectors/filters/possible-filters/global/possibleFilterSelectedDimensionCategoriesSelector";
import { resourceExplorerCategoriesIdsSelector } from "../../../../../../store/resource-explorer/selectors/filters/dimension/resourceExplorerCategoriesIdsSelector";
import { getResourceExplorerDataThunk } from "../../../../../../store/resource-explorer/thunks/widgets/getResourceExplorerDataThunk";
import { isDeepEqual } from "../../../../../../utils/is-deep-equal";
import { resourceExplorerDimensionIdSelector } from "../../../../../../store/resource-explorer/selectors/filters/dimension/resourceExplorerDimensionIdSelector";

export const ResourceExplorerCategorySelect: FC = () => {
  const dispatch = useAppDispatch();
  const dimensionCategories = useAppSelector(
    possibleFilterSelectedDimensionCategoriesSelector,
  );
  const selectedCategories = useAppSelector(
    resourceExplorerCategoriesIdsSelector,
  );
  const dimensionId = useAppSelector(resourceExplorerDimensionIdSelector);

  const renderOptions = useMemo(() => {
    return (
      dimensionCategories?.map((category) => {
        return {
          value: category.value,
          rawValue: category,
          label: (
            <TypographyWithTooltip variant="body2" title={category.title} />
          ),
        };
      }) ?? []
    );
  }, [dimensionCategories]);

  const changeHandler = useCallback(
    (selected: string[]) => {
      if (!isDeepEqual(selectedCategories ?? [], selected)) {
        dispatch(
          setResourceExplorerCategoryIds(
            selected.length === dimensionCategories?.length ? [] : selected,
          ),
        );
        dispatch(getResourceExplorerDataThunk());
      }
    },
    [dispatch, selectedCategories, dimensionCategories?.length],
  );

  const TriggerComponent = useCallback(
    (props: FilterTriggerComponentProps) => (
      <FilterButton
        {...props}
        color="primary"
        variant={selectedCategories?.length ? "contained" : "outlined"}
        title="Categories"
        count={
          selectedCategories?.length ? `(${selectedCategories?.length})` : ""
        }
      />
    ),
    [selectedCategories?.length],
  );

  return (
    <DropdownSelect
      label=""
      disabled={!dimensionId}
      options={renderOptions}
      wrapperVariant="popover"
      PopoverProps={popoverProps}
      noItemsLabel={noItemsLabel}
      submitHandlerOnClose={changeHandler}
      TriggerComponent={TriggerComponent}
      values={selectedCategories}
      filterFn={filterByDimensionDropdownFilterFn}
    />
  );
};

const noItemsLabel = (keyword: string) => (
  <Typography variant="body2">No categories found for “{keyword}”</Typography>
);

const popoverProps: Partial<PopoverProps> = {
  anchorOrigin: { vertical: "bottom", horizontal: "left" },
  transformOrigin: { vertical: "top", horizontal: "left" },
};
