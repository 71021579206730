import { FC, Fragment } from "react";
import { GroupingsPopover } from "./components/GroupingsPopover";
import { GroupingByTagValuesTrigger } from "./components/GroupingByTagValuesTrigger";
import { useMenuHook } from "../../../../../../../../../utils/hooks/useMenu.hook";

// TODO: This component should be replaced with src/components/pages/common/resource-explorer/group-by-selector/GroupBySelector.tsx
export const GroupByV2Selector: FC = () => {
  const { open, anchor, openMenu, closeMenu } = useMenuHook();

  return (
    <Fragment>
      <GroupingByTagValuesTrigger open={open} onClick={openMenu} />
      <GroupingsPopover open={open} anchor={anchor} onClose={closeMenu} />
    </Fragment>
  );
};
