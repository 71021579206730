import {
  MouseEvent,
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  DateRange,
  DateRangePicker as MuiDateRangePicker,
  DefinedRange,
} from "mui-daterange-picker";
import { DatePeriodInput } from "./components/DatePeriodInput";
import { DatePeriodInputProps } from "./utils/types";
import { useDialog } from "../../../utils/hooks/useDialog.hook";
import { useMenuHook } from "../../../utils/hooks/useMenu.hook";
import { formatDate } from "../../../utils/helpers/date-time/datetime-format";

interface DateRangePickerProps {
  dateFrom?: string;
  dateTo?: string;
  viewType?: "popover" | "dialog";
  definedRanges?: DefinedRange[];
  InputComponent?: FC<DatePeriodInputProps>;
  onSubmit(range: DateRange): void;
  minDate?: Date | string;
  maxDate?: Date | string;
  disabled?: boolean;
}

export const DateRangePicker: FC<DateRangePickerProps> = ({
  dateTo,
  dateFrom,
  onSubmit,
  definedRanges,
  viewType = "dialog",
  maxDate,
  minDate,
  InputComponent,
  disabled,
}) => {
  const { open, openDialog, closeDialog } = useDialog();
  const { open: popoverOpen, openMenu, closeMenu, anchor } = useMenuHook();

  const [range, setRange] = useState<DateRange>({
    endDate: undefined,
    startDate: undefined,
  });

  const selectedPeriod = useMemo(() => {
    const from = formatDate(moment(range.startDate), { type: "date" });
    const to = formatDate(moment(range.endDate), { type: "date" });

    return `${from} - ${to}`;
  }, [range]);

  const cancelHandler = useCallback(() => {
    setRange({
      endDate: dateTo ? new Date(moment(dateTo).format()) : undefined,
      startDate: dateFrom ? new Date(moment(dateFrom).format()) : undefined,
    });

    closeDialog();
    closeMenu();
  }, [dateTo, dateFrom, closeDialog, closeMenu]);

  const submitHandler = useCallback(() => {
    onSubmit(range);
    closeDialog();
    closeMenu();
  }, [onSubmit, range, closeDialog, closeMenu]);

  // this just needed because the DateRangePicker it's required
  const toggle = useCallback(() => {}, []);

  const openHandler = useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      openMenu(e);
      openDialog();
    },
    [openDialog, openMenu],
  );

  useEffect(() => {
    setRange({
      endDate: dateTo ? new Date(moment(dateTo).format()) : undefined,
      startDate: dateFrom ? new Date(moment(dateFrom).format()) : undefined,
    });
  }, [dateTo, dateFrom]);

  const picker = (
    <MuiDateRangePicker
      open={open}
      initialDateRange={range}
      maxDate={maxDate}
      minDate={minDate}
      toggle={toggle}
      closeOnClickOutside={false}
      onChange={setRange}
      definedRanges={definedRanges ?? []}
    />
  );

  const actions = (
    <Stack
      px={3}
      py={2}
      width="100%"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="body2" color="text.secondary">
        Selected Period:{" "}
        <Typography variant="body2" component="span" color="text.primary">
          {selectedPeriod}
        </Typography>
      </Typography>

      <div>
        <Button onClick={cancelHandler} sx={{ mr: 2 }} color="tertiary">
          Cancel
        </Button>
        <Button onClick={submitHandler} variant="contained">
          Apply
        </Button>
      </div>
    </Stack>
  );

  return (
    <Fragment>
      {InputComponent ? (
        <InputComponent
          range={range}
          minDate={minDate}
          maxDate={maxDate}
          onClick={openHandler}
        />
      ) : (
        <DatePeriodInput
          range={range}
          onClick={openHandler}
          disabled={disabled}
        />
      )}
      {viewType === "popover" ? (
        <Popover
          open={popoverOpen}
          anchorEl={anchor}
          onClose={cancelHandler}
          PaperProps={{ sx: { borderRadius: 2 } }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={styles}>
            {picker}
            <Divider />
            {actions}
          </Box>
        </Popover>
      ) : (
        <Dialog open={open} maxWidth="lg">
          <DialogContent sx={styles}>{picker}</DialogContent>
          <Divider />
          <DialogActions>{actions}</DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};

const styles = {
  p: 0,
  "& .MuiPaper-root": { boxShadow: "none" },
  "& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper":
    { height: 300, boxShadow: "0 0 5px rgba(0,0,0,0.2)" },
  "& .css-1j13ek9-MuiGrid-root, .css-1fre31j": { display: "none" },
  "& .css-nu1h35-MuiButtonBase-root-MuiIconButton-root, .css-nid5m7 > button": {
    bgcolor: "primary.main",
  },
  "& .css-9w241k-MuiButtonBase-root-MuiListItem-root, .css-109490c": {
    bgcolor: "grey.100",
    color: "text.primary",
  },
};
