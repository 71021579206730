import { RootState } from "../../../store";
import {
  cloudChiprApi,
  ProviderType,
} from "../../../../services/cloudchipr.api";
import { AccountTabs } from "../../../../components/pages/accounts-group/utils/types/types";
import { getAccountsViewSettingsKey } from "../../../../components/pages/account/utils/hooks/useAccountsView.hook";
import { accountsViewSelector } from "../slice-data/accountsViewSelector";

const selector =
  cloudChiprApi.endpoints.getUsersMeCurrentPreferenceByKey.select;

export const accountsViewLoadingSelector = (
  state: RootState,
  provider: ProviderType,
  tabValue: AccountTabs,
  orgId?: string,
) => {
  const view = accountsViewSelector(state);
  const key = getAccountsViewSettingsKey(provider, tabValue, orgId);

  const data = selector({ key })(state);

  return data?.error || view ? false : data.isLoading;
};
