import { FC } from "react";
import { Card, Divider, LinearProgress } from "@mui/material";
import { LargestCostChangesWidgetSetupHeader } from "./components/LargestCostChangesWidgetSetupHeader";
import { LargestCostChangesWidgetBody } from "./components/LargestCostChangesWidgetBody";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { resourceExplorerByIdLoadingSelector } from "../../../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerByIdLoadingSelector";
import { largestCostChangesSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/largestCostChangesSetupPropertyByKeySelector";
import { largestCostChangesGetWidgetDataLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/loading/largestCostChangesGetWidgetDataLoadingSelector";
import { widgetSetupContentChartHeight } from "../../../../../../utils/constants/stylings";
import { largestCostChangesWidgetDataSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/largestCostChangesWidgetDataSelector";
import { widgetREPrevDateDetailsSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/common/widget-re-view/WidgetREPrevDateDetailsSelector";
import { LargestCostChangesWidgetFooter } from "../../../../../widgets/largest-cost-change/components/footer/LargestCostChangesWidgetFooter";
import { WidgetSetupContentWrapper } from "../../common/WidgetSetupContentWrapper";

interface LargestCostChangesWidgetSetupContentProps {
  widgetId: string;
}
export const LargestCostChangesWidgetSetupContent: FC<
  LargestCostChangesWidgetSetupContentProps
> = ({ widgetId }) => {
  const viewId = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("viewId"),
  );
  const dataLoading = useAppSelector(
    largestCostChangesGetWidgetDataLoadingSelector,
  );

  const resourceExplorerViewLoading = useAppSelector((state) =>
    resourceExplorerByIdLoadingSelector(state, viewId),
  );
  const data = useAppSelector((state) =>
    largestCostChangesWidgetDataSelector(state, {
      widgetId,
    }),
  );

  const prevDate = useAppSelector((state) =>
    widgetREPrevDateDetailsSelector(state, widgetId),
  );
  const date = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("date"),
  );

  const loading = resourceExplorerViewLoading || dataLoading;
  return (
    <WidgetSetupContentWrapper>
      <Card
        elevation={3}
        sx={{
          height: widgetSetupContentChartHeight,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading && <LinearProgress />}
        <LargestCostChangesWidgetSetupHeader widgetId={widgetId} />
        <Divider />
        <LargestCostChangesWidgetBody widgetId={widgetId} />
        {!loading && !!data.length && (
          <LargestCostChangesWidgetFooter prevDate={prevDate} date={date} />
        )}
      </Card>
    </WidgetSetupContentWrapper>
  );
};
