import {
  ProviderType,
  ResourceExplorerGrouping,
} from "../../../../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../../../../utils/helpers/providers/getProviderName";
import {
  resourcesExplorerGroupingLabels,
  resourcesExplorerGroupingSingularLabels,
} from "../constants/groupings";

export const getResourcesExplorerGroupingLabel = (
  providers: ProviderType[],
  grouping: ResourceExplorerGrouping,
  singular?: boolean,
): string => {
  const tagsLabel = getTagsLabel(providers, grouping);
  const productFamilyLabel = getProductFamilyLabel(
    providers,
    grouping,
    singular,
  );
  const accountLabel = getAccountLabel(providers, grouping, singular);

  const mapping = singular
    ? resourcesExplorerGroupingSingularLabels
    : resourcesExplorerGroupingLabels;

  return (
    tagsLabel ??
    productFamilyLabel ??
    accountLabel ??
    mapping.get(grouping) ??
    ""
  );
};

const getTagsLabel = (
  providers: ProviderType[],
  grouping: ResourceExplorerGrouping,
): string | null => {
  if (
    grouping !== "cost_allocation_tag" &&
    grouping !== "cost_allocation_tag_value"
  ) {
    return null;
  }

  const isTagValue = grouping === "cost_allocation_tag_value";
  const tagType = isTagValue ? "(Values)" : "(Keys)";
  let tagTypePrefix = "Tag/Label";

  if (!providers?.includes("gcp")) {
    tagTypePrefix = "Tag";
  }

  if (providers?.length === 1 && providers?.at(0) === "gcp") {
    tagTypePrefix = "Label";
  }
  return `${tagTypePrefix} ${tagType}`;
};

const getProductFamilyLabel = (
  providers: ProviderType[],
  grouping: ResourceExplorerGrouping,
  singular?: boolean,
) => {
  if (grouping !== "product_family") {
    return null;
  }

  if (!providers?.includes("gcp")) {
    return singular ? "Product Family" : "Product Families";
  }

  if (providers?.length === 1 && providers?.at(0) === "gcp") {
    return singular ? "Product Family (SKU)" : "Product Families (SKU)";
  }
};

const getAccountLabel = (
  providers: ProviderType[],
  grouping: ResourceExplorerGrouping,
  singular?: boolean,
) => {
  if (grouping !== "account") {
    return null;
  }

  if (providers.length > 1) {
    return singular ? "Account" : "Accounts";
  }

  const provider = providers.at(0);

  if (provider) {
    return getProviderName(provider, { capitalize: true, plural: !singular });
  }
};
