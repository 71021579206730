import moment from "moment/moment";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { currentAccountNameIdSelector } from "../current-account/currentAccountNameIdSelector";
import { getResourceTypeName } from "../../../../utils/helpers/resources/getResourceTypeName";
import { getProviderTypeByResourceType } from "../../../../utils/helpers/providers/getProviderTypeByResourceType";

export const resourceTypeCsvDataFileNameSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const currentAccountName = currentAccountNameIdSelector(state);

  const provider = getProviderTypeByResourceType(resourceType);

  const date = moment().format("MM.DD.YY");

  const resourceName =
    getResourceTypeName(resourceType, {
      type: "long",
    })
      ?.toLowerCase()
      ?.replaceAll(" ", "-") ?? "";

  return `${provider}-${currentAccountName}-${date}-${resourceName}`;
};
