import { generateCostAndUsageSumFilterTypeData } from "./generateCostAndUsageSumFilterTypeData";
import { forecastedCostOptionLabels } from "../../../../../../../components/pages/resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/utils/constants";
import { ForecastedCostRe } from "../../../../../../../services/cloudchipr.api";
import { CostAndUsageDataGridType } from "../../../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/cost-and-usage/content/components/data-grid/utils/types";

export const generateForecastedCostsData = (
  forecastedCosts?: ForecastedCostRe,
): CostAndUsageDataGridType[] => {
  const monthEndForecast = forecastedCosts?.find(
    (item) => item.forecast_option === "month",
  );
  const quarterEndForecast = forecastedCosts?.find(
    (item) => item.forecast_option === "quarter",
  );

  return [
    generateCostAndUsageSumFilterTypeData(
      "forecasted_month_end_cost",
      `Forecasted ${forecastedCostOptionLabels.get("month")} Cost`,
      monthEndForecast?.cost ?? null,
      monthEndForecast?.trend,
      monthEndForecast?.date_from,
      monthEndForecast?.date_to,
      monthEndForecast?.previous_period_from,
      monthEndForecast?.previous_period_to,
    ),
    generateCostAndUsageSumFilterTypeData(
      "forecasted_quarter_end_cost",
      `Forecasted ${forecastedCostOptionLabels.get("quarter")} Cost`,
      quarterEndForecast?.cost ?? null,
      quarterEndForecast?.trend,
      quarterEndForecast?.date_from,
      quarterEndForecast?.date_to,
      quarterEndForecast?.previous_period_from,
      quarterEndForecast?.previous_period_to,
    ),
  ];
};
