import { currentOpenCleanProcessSelector } from "./currentOpenCleanProcessSelector";
import { RootState } from "../../store";
import { getFirstParentInHierarchyResourceType } from "../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { resourceTypeDataWithChildrenSelector } from "../../account/selectors/resource-type-data/resourceTypeDataWithChildrenSelector";
import { columnsMap } from "../../../components/pages/account/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/columns-setup/columnsMap";
import { ResourceItemType } from "../../account/utils/types/types";

export const cleanExcludedResourcesIdentifiersSelector = (
  state: RootState,
): string[] => {
  const currentProcess = currentOpenCleanProcessSelector(state);

  if (!currentProcess?.resourceType) {
    return [];
  }

  const resources = resourceTypeDataWithChildrenSelector(
    state,
    getFirstParentInHierarchyResourceType(currentProcess?.resourceType) ??
      currentProcess?.resourceType,
  );

  const resourcesForClean = new Set(
    currentProcess?.excludedResources?.map((r) => r.id),
  );

  if (!resources) {
    return [];
  }

  return resources.reduce((result, { resource }) => {
    if (!resource) {
      return result;
    }
    const id = resource?.id;

    const accessorKey = columnsMap.get(resource?.resource_type)?.[0]
      ?.accessorKey as keyof ResourceItemType;
    if (resourcesForClean.has(id) && accessorKey) {
      const accessorKeyValue = resource?.[
        accessorKey
      ] as keyof ResourceItemType;
      result.push(
        accessorKeyValue.length
          ? accessorKeyValue
          : resource.provider_unique_identifier,
      );
    }

    return result;
  }, [] as string[]);
};
