import { FC, useState, useCallback, Fragment } from "react";
import { Alert, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useAppSelector } from "../../../store/hooks";
import {
  currentSubscriptionPlanNameSelector,
  hasPaymentMethodAttachedSelector,
  isSubscriptionPlanOverdueSelector,
  isSubscriptionPlanTrialSelector,
  trialPeriodEndsOnSelector,
  trialPeriodExpiresInSelector,
} from "../../../store/subscriptions/subscriptionsSelectors";
import { MessageLinkToBilling } from "../../common/MessageLinkToBilling";

export const TrialSystemMessage: FC = () => {
  const isOverdue = useAppSelector(isSubscriptionPlanOverdueSelector);
  const isTrial = useAppSelector(isSubscriptionPlanTrialSelector);
  const trialPeriod = useAppSelector(trialPeriodExpiresInSelector);
  const trialEnds = useAppSelector(trialPeriodEndsOnSelector);
  const currentPlanName = useAppSelector(currentSubscriptionPlanNameSelector);
  const hasPaymentMethodAttached = useAppSelector(
    hasPaymentMethodAttachedSelector,
  );
  const [open, setOpen] = useState(true);

  const handleCloseMessage = useCallback(() => {
    setOpen(false);
  }, []);

  if (isOverdue || !isTrial || !trialPeriod || trialPeriod < 0) {
    return null;
  }

  if (open && isTrial && hasPaymentMethodAttached) {
    return (
      <Fragment>
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          variant="filled"
          shape="sharp"
        >
          Thank you for adding the payment method, you can still enjoy the free
          trial for {trialPeriod} more days!
        </Alert>
      </Fragment>
    );
  }

  if (open && trialPeriod && trialPeriod > 7) {
    return (
      <Fragment>
        <Alert
          onClose={handleCloseMessage}
          severity="info"
          variant="filled"
          shape="sharp"
        >
          Your Free Trial on {currentPlanName} Plan ends in {trialPeriod} Days.{" "}
          <MessageLinkToBilling title="Upgrade plan" />
        </Alert>
      </Fragment>
    );
  }

  if (open) {
    return (
      <Alert
        severity="warning"
        variant="filled"
        onClose={handleCloseMessage}
        shape="sharp"
      >
        Your free trial subscription is going to expire in {trialPeriod} days.{" "}
        {trialEnds && (
          <Tooltip title={trialEnds} arrow>
            <InfoOutlinedIcon sx={{ verticalAlign: "middle", pr: 0.5 }} />
          </Tooltip>
        )}
        <MessageLinkToBilling />
        to continue using Cloudchipr.
      </Alert>
    );
  }

  return null;
};
