import { costBreakdownWidgetByIdSelector } from "./costBreakdownWidgetByIdSelector";
import { RootState } from "../../../../store";
import { costBreakdownSetupPropertyByKeySelector } from "../../setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { ForecastOption } from "../../../../../services/cloudchipr.api";

export const costBreakdownWidgetForecastOptionSelector = (
  state: RootState,
  widgetId?: string,
): ForecastOption => {
  let forecastOption =
    costBreakdownSetupPropertyByKeySelector("forecastOption")(state);

  const data = costBreakdownWidgetByIdSelector(state, widgetId);
  if (data) {
    forecastOption = data?.forecast_option;
  }
  return forecastOption;
};
