import { FC, useCallback } from "react";
import { Drawer } from "@mui/material";
import { SavingsPlanDrawerContent } from "./savings-plan-drawer/SavingsPlanDrawerContent";
import { CoverageDrawerContent } from "./coverage-drawer/CoverageDrawerContent";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { utilizationAndCoverageDrawerTargetIdSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/options/utilizationAndCoverageDrawerTargetIdSelector";
import { setCoverageAndUtilizationDrawerTarget } from "../../../../../../store/commitments/commitmentsSlice";
import { utilizationAndCoverageDrawerTargetTypeSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/options/utilizationAndCoverageDrawerTargetTypeSelector";

export const UtilizationAndCoverageDrawers: FC = () => {
  const dispatch = useAppDispatch();
  const targetId = useAppSelector(utilizationAndCoverageDrawerTargetIdSelector);
  const targetType = useAppSelector(
    utilizationAndCoverageDrawerTargetTypeSelector,
  );

  const open = !!(targetId && targetType);

  const closeHandler = useCallback(() => {
    dispatch(setCoverageAndUtilizationDrawerTarget(undefined));
  }, [dispatch]);

  return (
    <Drawer open={open} anchor="right" onClose={closeHandler}>
      {targetType === "savings_plan" && (
        <SavingsPlanDrawerContent onClose={closeHandler} />
      )}

      {targetType === "account" && (
        <CoverageDrawerContent onClose={closeHandler} />
      )}

      {targetType === "resource_type" && (
        <CoverageDrawerContent onClose={closeHandler} />
      )}
    </Drawer>
  );
};
