import { costByCategoryWidgetByIdSelector } from "./costByCategoryWidgetByIdSelector";
import { RootState } from "../../../../store";
import { costByCategorySetupPropertyByKeySelector } from "../../setups/cost-by-category/costByCategorySetupPropertyByKeySelector";
import { DateDataPoint } from "../../../../../services/cloudchipr.api";

export const costByCategoryWidgetFrequencySelector = (
  state: RootState,
  widgetId?: string,
): DateDataPoint => {
  let frequency = costByCategorySetupPropertyByKeySelector("frequency")(state);

  const data = costByCategoryWidgetByIdSelector(state, widgetId);

  if (data) {
    frequency = data?.date_granularity;
  }

  return frequency;
};
