import { costByCategoryWidgetDataCategoriesWithoutFiltersSelector } from "./costByCategoryWidgetDataCategoriesWithoutFiltersSelector";
import { RootState } from "../../../../../store";
import { CostByCategoryWidgetCategory } from "../../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/cost-by-category/utils/types/types";

export const costByCategoryDataGridDataSelector = (
  state: RootState,
): CostByCategoryWidgetCategory[] | undefined => {
  const widgetData =
    costByCategoryWidgetDataCategoriesWithoutFiltersSelector(state);

  return widgetData?.map((widget) => {
    return {
      ...widget,
      id: widget.category_id,
    };
  });
};
