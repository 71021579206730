import { FC, useCallback } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import {
  DateData,
  WidgetSetupDateRangeSelect,
} from "./date-picker/WidgetSetupDateRangeSelect";
import { WidgetSetupDateRangeSelectV2 } from "./date-picker-v2/WidgetSetupDateRangeSelectV2";
import { Dates } from "../../../../../../../../../services/cloudchipr.api";

interface WidgetDatePickerVersionsWrapperProps {
  newDates: Dates;
  oldDates: DateData;
  disabled?: boolean;
  onOldDateChange?(dates: DateData): void;
  onNewDateChange?(dates: Dates): void;
}
export const WidgetDatePickerVersionsWrapper: FC<
  WidgetDatePickerVersionsWrapperProps
> = ({ newDates, oldDates, disabled, onOldDateChange, onNewDateChange }) => {
  const enableCostBreakdownNewEndpoints = useFlag(
    "EnableCostBreakdownNewEndpoints",
  );

  const dateChangeHandler = useCallback(
    (dates: any) => {
      onOldDateChange?.(dates);
    },
    [onOldDateChange],
  );

  const dateRangeChangeHandler = useCallback(
    (dates: Dates) => {
      onNewDateChange?.(dates);
    },
    [onNewDateChange],
  );

  if (enableCostBreakdownNewEndpoints) {
    return (
      <WidgetSetupDateRangeSelectV2
        dates={newDates}
        onDateChange={dateRangeChangeHandler}
      />
    );
  }

  return (
    <WidgetSetupDateRangeSelect
      dates={oldDates}
      onChange={dateChangeHandler}
      disabled={disabled}
    />
  );
};
