import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { AzDisk } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const azDiskColumns: ColumnSetupType<AzDisk>[] = [
  {
    accessorKey: "name",
    header: "Disk Name",
    type: "identifier",
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "creation_data",
    header: "Created By",
    headerTooltip:
      "The Created By value is only available for resources created within the last 90 days.",
    type: "createdBy",
  },
  {
    accessorKey: "created_at",
    header: "Creation Time",
    type: "date",
  },
  {
    accessorKey: "unused_since",
    header: "Last Detached",
    type: "unusedSince",
  },
  { accessorKey: "size", header: "Size", type: "byteToGib" },
  { accessorKey: "sku", header: "Disk SKU" },
  {
    accessorKey: "resource_group",
    header: "Resource Group",
    type: "copyWithTooltip",
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Tags",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "Ticket Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
