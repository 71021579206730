import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResourceSelectionType } from "./utils/types/selectedResources";
import { AccountId, ResourceType } from "../../services/cloudchipr.api";

export type AccountPageStateType = "uninitialized" | "pending" | "fulfilled";

interface AccountSlice {
  id: AccountId | null;
  state: AccountPageStateType;
  regions: string[] | null;
  selected: ResourceSelectionType;
  viewWithoutFiltersResourceTypes: Partial<Record<ResourceType, boolean>>;
  filterTemplatesEnabled: boolean;
}

const initialState: AccountSlice = {
  id: null,
  state: "uninitialized",
  regions: null,
  selected: {},
  viewWithoutFiltersResourceTypes: {},
  filterTemplatesEnabled: true,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setCurrentAccountId: (state, action: PayloadAction<AccountSlice["id"]>) => {
      state.id = action.payload;
    },
    setFilterTemplatesEnabled: (state, action: PayloadAction<boolean>) => {
      state.filterTemplatesEnabled = action.payload;
    },
    setAccountPageState: (
      state,
      action: PayloadAction<AccountPageStateType>,
    ) => {
      state.state = action.payload;
    },
    setRegion: (state, action: PayloadAction<string[] | null>) => {
      state.regions = action.payload;
    },
    setSelectedResources: (
      state,
      action: PayloadAction<{
        resourceType: ResourceType;
        selectedResources: Record<string, boolean>;
        parentSource: string;
      }>,
    ) => {
      const { resourceType, selectedResources, parentSource } = action.payload;

      state.selected[resourceType] = {
        ...state.selected[resourceType],
        [parentSource]: { ...selectedResources },
      };
    },
    resetSelectedResources: (state, action: PayloadAction<ResourceType>) => {
      state.selected[action.payload] = {};
    },
    resetAllSelectedResources: (state) => {
      state.selected = {};
    },
    setViewWithoutFiltersResourceTypes: (
      state,
      action: PayloadAction<{
        resourceType: ResourceType;
        value: boolean;
      }>,
    ) => {
      const { resourceType, value } = action.payload;
      state.viewWithoutFiltersResourceTypes[resourceType] = value;
    },
    resetViewWithoutFiltersResourceTypes: (state) => {
      state.viewWithoutFiltersResourceTypes = {};
    },
    reset: () => initialState,
  },
});

export const {
  reset,
  setRegion,
  setCurrentAccountId,
  setAccountPageState,
  setSelectedResources,
  resetSelectedResources,
  setViewWithoutFiltersResourceTypes,
  setFilterTemplatesEnabled,
  resetViewWithoutFiltersResourceTypes,
  resetAllSelectedResources,
} = accountSlice.actions;

export default accountSlice.reducer;
