import { FC, useState, useRef, useMemo, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useBoundingclientrect } from "rooks";
import { useFlag } from "@unleash/proxy-client-react";
import { useSearchParams } from "react-router-dom";
import { AccountResourceAreaDrawer } from "./components/AccountResourceAreaDrawer";
import { useAppSelector } from "../../../../../store/hooks";
import { currentAccountIdSelector } from "../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import { currentAccountProviderTypeSelector } from "../../../../../store/account/selectors/current-account/currentAccountProviderTypeSelector";
import { resourceGroups } from "../../../../../utils/constants/resources/resourceGroups";
import {
  generateResourceCardId,
  ResourceCard,
} from "../resource-card/ResourceCard";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { useRefs } from "../../../../../utils/hooks/useRefs";
import { COMPONENT_ID } from "../../../../../utils/constants/components-ids";
import { useToggledResourceTypes } from "../../utils/hooks/useToggledResources";
import { resourceTypeSearchParams } from "../navigation/utils/constants";

export const AccountResourcesArea: FC = () => {
  const { refs, setRefFromKey } = useRefs();
  const [searchParams] = useSearchParams();
  const resourceTypeFromRouter = searchParams.get(
    resourceTypeSearchParams,
  ) as ResourceType;

  const tabListRef = useRef<HTMLElement>(null);
  const getBoundingClientRect = useBoundingclientrect(tabListRef);
  const enableResourceCardScrollingNavigation = useFlag(
    "EnableResourceCardScrollingNavigation",
  );
  const currentAccountId = useAppSelector(currentAccountIdSelector);
  const currentAccountProviderType = useAppSelector(
    currentAccountProviderTypeSelector,
  );

  const toggledResourceTypes = useToggledResourceTypes();

  const resourceGroup =
    currentAccountProviderType &&
    resourceGroups.get(currentAccountProviderType);

  const [activeResourceType, setActiveResourceType] = useState(
    resourceGroup?.[0]?.value,
  );

  const activeResourceGroup = useMemo(() => {
    return resourceGroup?.find((rg) => rg.value === activeResourceType);
  }, [resourceGroup, activeResourceType]);

  const scrollHandler = useCallback((resourceType: ResourceType) => {
    setTimeout(() => {
      const resourceCardId = generateResourceCardId(resourceType);
      const card = document.getElementById(resourceCardId);
      const commonWrapper = document.getElementById(
        COMPONENT_ID.ACCOUNT_PAGE_SCROLL_AREA,
      );
      const top = (card?.offsetTop ?? 0) - 168;

      commonWrapper?.scrollTo({
        top,
        behavior: "smooth",
      });
    });
  }, []);

  useEffect(() => {
    const resourceType = resourceGroup?.find(
      ({ value, resourceTypes }) =>
        value === resourceTypeFromRouter ||
        resourceTypes.includes(resourceTypeFromRouter),
    )?.value;

    if (resourceType) {
      setActiveResourceType(resourceType);
      scrollHandler(resourceTypeFromRouter);
    }
  }, [resourceTypeFromRouter, resourceGroup, scrollHandler]);

  if (
    !currentAccountId ||
    !resourceGroup ||
    !currentAccountProviderType ||
    !activeResourceType
  ) {
    return null;
  }

  return (
    <Stack direction="row" overflow="auto" flexGrow={1}>
      <Box flexGrow={1} overflow="auto">
        <Stack gap={1} p={1}>
          {activeResourceGroup?.resourceTypes?.map((resourceType) => {
            return toggledResourceTypes.get(resourceType) ? null : (
              <Box key={resourceType} ref={setRefFromKey(resourceType)}>
                <ResourceCard key={resourceType} resourceType={resourceType} />
              </Box>
            );
          })}
        </Stack>
      </Box>

      {enableResourceCardScrollingNavigation && (
        <AccountResourceAreaDrawer
          cardItemsRef={refs}
          resourceGroup={resourceGroup}
          activeGroupType={activeResourceType}
          parentHeight={getBoundingClientRect?.height}
        />
      )}
    </Stack>
  );
};
