import { possibleFilterCategoriesSelector } from "./possibleFilterCategoriesSelector";
import { resourceExplorerDimensionIdSelector } from "../../dimension/resourceExplorerDimensionIdSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerPossibleFilter } from "../../../../../../services/cloudchipr.api";

export const possibleFilterSelectedDimensionCategoriesSelector = (
  state: RootState,
): ResourceExplorerPossibleFilter["values"] | undefined => {
  const categories = possibleFilterCategoriesSelector(state);
  const selectedDimension = resourceExplorerDimensionIdSelector(state);

  return categories?.values?.filter(
    (category) => category.dimension_id === selectedDimension,
  );
};
