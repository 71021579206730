import { costBreakdownV2WidgetViewIdByWidgetIdSelector } from "./costBreakdownV2WidgetViewIdByWidgetIdSelector";
import { RootState } from "../../../../../store";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { selectedViewProvidersSelector } from "../../common/SelectedViewProvidersSelector";
import { sortedResourceExplorersSelector } from "../../../../../resource-explorer/selectors/all-resource-explorers/sortedResourceExplorersSelector";

export const costBreakdownV2WidgetProvidersSelector = (
  state: RootState,
  widgetId?: string,
): ProviderType[] => {
  if (!widgetId) {
    return selectedViewProvidersSelector(state);
  }
  const views = sortedResourceExplorersSelector(state);

  const viewId = costBreakdownV2WidgetViewIdByWidgetIdSelector(state, widgetId);

  return (views?.find((item) => item.id === viewId)?.cloud_providers ??
    []) as ProviderType[];
};
