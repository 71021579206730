import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import {
  setResourceExplorerData,
  setResourceExplorerId,
} from "../../resourceExplorerSlice";
import { resourceExplorerDefaultData } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/constants/common";
import { setInitialFiltersDataThunk } from "../filters/setInitialFiltersDataThunk";

export const getResourceExplorerViewByIdThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerViewById",
  async (viewId: string, { dispatch }) => {
    const {
      getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId,
    } = cloudChiprApi.endpoints;
    try {
      const response = await dispatch(
        getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId.initiate(
          {
            resourceExplorerViewId: viewId,
          },
          { forceRefetch: true },
        ),
      ).unwrap();

      await dispatch(setInitialFiltersDataThunk(response));

      const data =
        response.is_default && !response.data
          ? resourceExplorerDefaultData
          : { ...response.data, grouping: response.data.grouping ?? "service" };

      dispatch(setResourceExplorerData(data));
      dispatch(setResourceExplorerId(viewId));
    } catch (e) {
      // @ts-expect-error//TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
