import { currentAccountSelectedGroupedRowsIdsByResourceTypeSelector } from "./currentAccountSelectedGroupedRowsIdsByResourceTypeSelector";
import { RootState } from "../../../../../store";
import { ResourceType } from "../../../../../../services/cloudchipr.api";

export const currentAccountSelectedRowsIdsByResourceTypeSelector = (
  state: RootState,
  resourceType?: ResourceType,
) => {
  if (!resourceType) {
    return;
  }
  const resources = currentAccountSelectedGroupedRowsIdsByResourceTypeSelector(
    state,
    resourceType,
  );

  const selectedResourcesGroupedArr = Object.values(resources ?? {});

  return selectedResourcesGroupedArr.reduce(
    (result, item) => {
      result = {
        ...result,
        ...item,
      };
      return result;
    },
    {} as Record<string, boolean>,
  );
};
