import { categoriesGroupedByGroupSelector } from "./categoriesGroupedByGroupSelector";
import { RootState } from "../../store";

export const categoryIdsByCategoryGroupNameSelector = (
  state: RootState,
  name: string,
): string[] => {
  const groupedCategories = categoriesGroupedByGroupSelector(state);

  const categories = groupedCategories[name];

  if (!categories) {
    return [];
  }

  return categories.map((category) => category.id);
};
