import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { updateDashboardHierarchyThunk } from "./updateDashboardHierarchyThunk";
import { createDashboardHierarchyItemThunk } from "./createDashboardHierarchyItemThunk";

interface CreateDashboardHierarchyItemByFolderIdThunkArg {
  name: string;
  folderId: string;
}

export const createDashboardHierarchyItemByFolderIdThunk = createAsyncThunk(
  "dashboard/createDashboardHierarchyItemByFolderId",
  async (
    { name, folderId }: CreateDashboardHierarchyItemByFolderIdThunkArg,
    { dispatch },
  ) => {
    try {
      const response = await dispatch(
        createDashboardHierarchyItemThunk(name),
      ).unwrap();

      if (!response?.id) {
        return;
      }

      await dispatch(
        updateDashboardHierarchyThunk({
          folderId,
          id: response.id,
        }),
      );

      return response;
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
