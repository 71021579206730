import { FC, Fragment, useCallback } from "react";
import { Collapse } from "@mui/material";
import { useToggle } from "rooks";
import { AccountNestedNavItem } from "./components/AccountNestedNavItem";
import { AccountNavItemCollapsed } from "./components/AccountNavItemCollapsed";
import { AccountNavItemMain } from "./components/AccountNavItemMain";
import { useAccountPageNavigate } from "../../utils/hooks/useAccountPageNavigate.hook";
import { ResourceGroupType } from "../../../../../../../utils/constants/resources/resourceGroups";
import {
  ProviderType,
  ResourceType,
} from "../../../../../../../services/cloudchipr.api";

interface AccountNavItemProps extends ResourceGroupType {
  provider: ProviderType;
  selectedResourceType?: ResourceType;
  sidebarCollapsed?: boolean;
}

export const AccountNavItem: FC<AccountNavItemProps> = ({
  expandable,
  actionableResourceTypes,
  value,
  provider,
  selectedResourceType,
  sidebarCollapsed,
}) => {
  const [expanded, toggleExpanded] = useToggle();
  const handleNavigationClick = useAccountPageNavigate(value);

  const nestedSelected = actionableResourceTypes.some(
    (resourceType) => resourceType === selectedResourceType,
  );

  const mainSelected =
    (!expandable || (expandable && !expanded)) && nestedSelected;

  const handleMainItemClick = useCallback(() => {
    toggleExpanded();

    if (!expanded) {
      handleNavigationClick();
    }
  }, [toggleExpanded, expanded, handleNavigationClick]);

  if (sidebarCollapsed) {
    return (
      <AccountNavItemCollapsed
        resourceType={value}
        selected={nestedSelected}
        provider={provider}
        expandable={expandable}
        actionableResourceTypes={actionableResourceTypes}
        selectedResourceType={selectedResourceType}
      />
    );
  }

  return (
    <Fragment>
      <AccountNavItemMain
        resourceType={value}
        provider={provider}
        selected={mainSelected}
        expandable={expandable}
        expanded={expanded}
        onClick={handleMainItemClick}
      />

      {expandable && (
        <Collapse in={expanded}>
          {actionableResourceTypes.map((resourceType) => (
            <AccountNestedNavItem
              key={resourceType}
              resourceType={resourceType}
              selected={resourceType === selectedResourceType}
            />
          ))}
        </Collapse>
      )}
    </Fragment>
  );
};
