import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import { costByCategoryWidgetByIdSelector } from "../../../selectors/widgets/cost-by-category/costByCategoryWidgetByIdSelector";
import { openWidgetBuildingSnackbar } from "../utils/openWidgetBuildingSnackbar";
import { createWidgetThunk } from "../common/createWidgetThunk";

export const duplicateCostByCategoryWidgetThunk = createAsyncThunk(
  "dashboards/duplicateCostByCategoryWidgetThunk",
  async (
    { widgetId, widgetName }: { widgetId: string; widgetName: string },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const widget = costByCategoryWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    const response = await dispatch(
      createWidgetThunk({
        name: widgetName,
        date_to: widget.date_to,
        type: "costs_by_category_v2",
        date_from: widget.date_from,
        date_label: widget.date_label,
        chart_type: widget.chart_type,
        forecast_option: widget.forecast_option,
        date_granularity: widget.date_granularity,
        category_group_key: widget.category_group_key,
        category_ids: widget.categories.map(({ id }) => id),
        display_filtered_resources: widget.display_filtered_resources,
        layout: null,
      }),
    ).unwrap();

    if (response) {
      openWidgetBuildingSnackbar(widget.type, "duplicate");
    }

    return response;
  },
);
