import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { removeDashboardFixedCacheKey } from "../../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";

const selector =
  cloudChiprApi.endpoints
    .deleteUsersMeOrganisationsCurrentDashboardsByDashboardId.select;

export const removeDashboardByIdLoadingSelector = (
  state: RootState,
): boolean => {
  return !!selector(removeDashboardFixedCacheKey)(state)?.isLoading;
};
