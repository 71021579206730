import { FC, useCallback, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { currentDashboardIdSelector } from "../../../../../store/dashboards/selectors/dashboard/currentDashboardIdSelector";
import { useAppSelector } from "../../../../../store/hooks";

interface UrlCopyButtonProps {
  disabled: boolean;
}

export const UrlCopyButton: FC<UrlCopyButtonProps> = ({ disabled }) => {
  const dashboardId = useAppSelector(currentDashboardIdSelector);

  const [copied, setCopied] = useState(false);

  const copyHandler = useCallback(() => {
    setCopied(true);

    setTimeout(() => setCopied(false), 3000);
  }, []);

  return (
    <CopyToClipboard
      onCopy={copyHandler}
      text={`${window.location.host}/dashboards/${dashboardId}`}
    >
      <Button
        startIcon={<LinkIcon />}
        sx={{ whiteSpace: "nowrap" }}
        disabled={copied || disabled}
      >
        {copied ? "Copied!" : "Copy URL"}
      </Button>
    </CopyToClipboard>
  );
};
