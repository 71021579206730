import { Box, Stack } from "@mui/material";
import {
  GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse,
  ResourceType,
  Tags,
} from "../../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../../storybook/data-grid/utils/types/types";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { SelectAllHeaderCheckbox } from "../../../../../../../storybook/data-grid/common/SelectAllCheckboxHeaderCell";
import { TagCell } from "../../../../../../common/data-grid-cells/tag-cell/TagCell";
import { ExecutionLogsDataGridResourcesType } from "../../../../../execution-log/utils/types/types";
import { AccountInfoData } from "../../../../../../common/AccountInfoData";
import { getResourceTypeName } from "../../../../../../../utils/helpers/resources/getResourceTypeName";
import { ResourceRestoreButton } from "../../../common/dismiss/by-resource/ResourceRestoreButton";
import { RecommendationGroupType } from "../../utils/types/types";
import { IdentifierCell } from "../../../../../account/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/identifier-cell/IdentifierCell";
import { RowSelectionCellCheckbox } from "../../../../../../../storybook/data-grid/common/RowSelectionCellCheckbox";

export const offHoursDismissedResourcesDataGridColumns = (
  group: RecommendationGroupType,
): ColumnSetupType<
  GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse[0]
>[] => [
  {
    id: "resource_name",
    header: (props) => (
      <Stack direction="row" spacing={0.5} alignItems="center">
        <SelectAllHeaderCheckbox {...props} sxProps={{ pl: 0 }} />
        {group === "compute" ? "Instance ID" : "Database ID"}
      </Stack>
    ),
    cell: ({ row, table }) => (
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Box sx={{ "& span": { pl: 0 } }}>
          <RowSelectionCellCheckbox row={row} table={table} />
        </Box>

        <Box overflow="hidden">
          <IdentifierCell
            data={row?.original.name || row?.original.resource_identifier}
          />
        </Box>
      </Stack>
    ),
  },
  {
    header: "Resource",
    accessorKey: "resource_type",
    cell: (cell) => {
      return getResourceTypeName(cell.getValue() as ResourceType, {
        type: "long",
        singular: true,
      });
    },
  },
  {
    header: "Tags/Labels",
    accessorKey: "tags",
    cell: (cell) => {
      return <TagCell tags={cell.getValue() as Tags} widthToEllipsis={100} />;
    },
  },
  {
    header: "Account",
    accessorKey: "account",
    cell: (cell) => {
      const account =
        cell.getValue() as ExecutionLogsDataGridResourcesType["account"];

      return (
        <AccountInfoData
          chipView
          accountId={
            account.provider_account_name ||
            account.provider_account_id ||
            account.id
          }
          provider={account.provider}
        />
      );
    },
  },
  {
    header: "Resource Type",
    accessorKey: "type",
  },
  {
    header: "Dismissed By",
    accessorKey: "dismissed_by",
    cell: (cell) => {
      return (
        <TypographyWithTooltip
          maxWidth={300}
          title={(cell.getValue() as string) ?? ""}
          variant="body2"
        />
      );
    },
  },
  {
    header: "Actions",
    id: "actions",
    cell: (cell) => {
      const data = cell.row
        .original as GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse[0];

      return <ResourceRestoreButton resourceId={data.id} />;
    },
  },
];
