import { RootState } from "../../../../store";
import { resourceExplorerFilterKeysAsProvidersSelector } from "../filter-providers/resourceExplorerFilterKeysAsProvidersSelector";
import { resourceExplorerFilterByKeyAndProviderSelector } from "../resourceExplorerFilterByKeyAndProviderSelector";
import {
  ProviderType,
  ResourceExplorerTag,
  ResourceExplorerTags,
} from "../../../../../services/cloudchipr.api";

export const appliedCostAllocationTagsSelector = (state: RootState) => {
  const providers = resourceExplorerFilterKeysAsProvidersSelector(state);

  return providers.reduce(
    (acc, provider) => {
      const tagFilter = resourceExplorerFilterByKeyAndProviderSelector(
        state,
        "cost_allocation_tag",
        provider,
      );

      if (!tagFilter) {
        return acc;
      }

      acc[provider] = {
        items: (tagFilter?.value as ResourceExplorerTag[]) ?? [],
        combination_operator: tagFilter?.combination_operator ?? "and",
      };

      return acc;
    },
    {} as Record<ProviderType, ResourceExplorerTags>,
  );
};
