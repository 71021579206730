import { FC, useMemo } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { UnprotectDialogTable } from "./UnprotectDialogTable";
import { ResourceType } from "../../../../../../../services/cloudchipr.api";
import { getResourceTypeColumns } from "../../../../../account/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/columns-setup/getResourceTypeColumns";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";
import { ResourceItemType } from "../../../../../../../store/account/utils/types/types";

export interface ResourceUnprotectDialogProps {
  open: boolean;
  isLoading?: boolean;
  onClose(): void;
  onSubmit(): void;
  resourceTypeActions?: Record<ResourceType, string>;
  resources: ResourceItemType[];
}

export const ResourceUnprotectDialog: FC<ResourceUnprotectDialogProps> = ({
  open,
  onClose,
  onSubmit,
  resourceTypeActions,
  resources,
  isLoading,
}) => {
  const data = useMemo(() => {
    return resources.map((resource) => {
      const resourceType = resource?.resource_type as ResourceType;

      const nameKey = getResourceTypeColumns(resourceType)?.at(0)
        ?.accessorKey as keyof ResourceItemType;

      const upcomingActionDate = resource?.upcoming_actions?.at(0)?.action_date;

      return {
        id: resource?.id,

        actionDate: upcomingActionDate
          ? formatDate(upcomingActionDate ?? "", { type: "dateTime" })
          : "-",
        resource: (resource?.[nameKey] ??
          resource?.provider_unique_identifier) as string,
        action: resourceTypeActions?.[resourceType] ?? "",
        account:
          resource?.account?.provider_account_name ||
          resource?.account?.provider_account_id,
        provider: resource?.account?.provider,
      };
    });
  }, [resourceTypeActions, resources]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Disable Protection?</DialogTitle>

      <DialogContent>
        <Typography variant="body1" color="text.secondary">
          Users and automation workflows will be able to take actions on
          resources with disabled protection.
        </Typography>

        <Box bgcolor="grey.100" p={1} borderRadius={2} mt={2}>
          <Stack direction="row" spacing={1} mb={2}>
            <WarningAmberRoundedIcon color="warning" fontSize="small" />

            <Typography variant="body1" color="text.secondary">
              There are actions scheduled on resources below. Disabling
              protection, will enable following scheduled actions.
            </Typography>
          </Stack>

          <UnprotectDialogTable data={data} />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="tertiary">
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          color="primary"
          loading={isLoading}
          onClick={onSubmit}
        >
          Disable
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
