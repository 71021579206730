import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "../../../../store";
import { buildingInProgressWidgetId } from "../utils/constants";
import { getWidgetDataByViewIdThunk } from "../common/getWidgetDataByViewIdThunk";
import { largestCostChangesSetupSelector } from "../../../selectors/setups/larges-cost-changes/largestCostChangesSetupSelector";

export const fetchLargestCostChangesWidgetDataBySetupThunk = createAsyncThunk(
  "dashboards/fetchLargesCostChangesWidgetDataBySetup",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const setup = largestCostChangesSetupSelector(state);

    if (!setup?.viewId) {
      return;
    }

    return await dispatch(
      getWidgetDataByViewIdThunk({
        widgetType: setup.widgetType,
        resourceExplorerViewId: setup.viewId,
        grouping: setup.grouping,
        groupValues: setup.group_values,
        dateLabel: setup.date.label,
        dateTo: setup.date.to,
        dateFrom: setup.date.from,
        priceDirection: setup.priceDirection ?? undefined,
        sortingBy: setup.sortingBy ?? undefined,
        itemsCount: setup.itemsCount ?? undefined,
        minCostChange:
          setup.minCostChange?.state && setup.minCostChange?.value
            ? setup.minCostChange?.value
            : null,
        minPercentageChange:
          setup.minPercentageChange?.state && setup.minPercentageChange?.value
            ? setup.minPercentageChange?.value
            : null,
        widgetId: buildingInProgressWidgetId,
      }),
    ).unwrap();
  },
);
