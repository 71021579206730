import { FC, Fragment, useMemo } from "react";
import { Card, CardContent, Container, Grid, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import * as yup from "yup";
import { Navigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import { useAppAbility } from "../../../../../services/permissions";
import {
  useGetUsersMeOrganisationsCurrentQuery,
  usePatchUsersMeOrganisationsCurrentMutation,
} from "../../../../../services/cloudchipr.api";
import { PageHeader } from "../../../common/PageHeader";

interface OrganizationFormValues {
  name: string;
  email: string;
}

export const Organization: FC = () => {
  const { data, refetch } = useGetUsersMeOrganisationsCurrentQuery();
  const [patchOrg, { isLoading }] =
    usePatchUsersMeOrganisationsCurrentMutation();
  const { can } = useAppAbility();
  const { enqueueSnackbar } = useSnackbar();

  const organizationInitialValues = useMemo<OrganizationFormValues>(
    () => ({
      name: data?.name || "",
      email: data?.email || "",
    }),
    [data?.name, data?.email],
  );

  const formik = useFormik<OrganizationFormValues>({
    initialValues: organizationInitialValues,
    validationSchema: yup.object({
      name: yup.string().required(),
      email: yup.string().trim().email().required(),
    }),
    onSubmit: async (values) => {
      await patchOrg({
        body: {
          name: values.name,
          email: values.email,
        },
      });
      refetch();

      enqueueSnackbar("Organization updated successfully!", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "success",
        },
      });
    },
    enableReinitialize: true,
  });

  if (!can("edit", "organisation")) {
    return <Navigate to="/" />;
  }

  return (
    <Fragment>
      <PageHeader title="Organization" />

      <Container sx={{ p: 2 }} disableGutters maxWidth={false}>
        <Card variant="outlined">
          <CardContent>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={10} lg={6}>
                <Typography variant="h6">
                  Update your organization information.
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Organization name*"
                    name="name"
                    value={formik.values.name}
                    size="small"
                    margin="normal"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!(formik.errors.name && formik.touched.name)}
                  />
                  <TextField
                    fullWidth
                    type="email"
                    label="Billing Email*"
                    name="email"
                    value={formik.values.email}
                    size="small"
                    margin="normal"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!(formik.errors.email && formik.touched.email)}
                  />
                  <LoadingButton
                    fullWidth
                    type="submit"
                    disabled={!(formik.isValid && formik.dirty)}
                    color="primary"
                    variant="contained"
                    loading={isLoading}
                    sx={{ mt: 2 }}
                  >
                    Update Organization
                  </LoadingButton>
                </form>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Fragment>
  );
};
