import { utilizationAndCoverageDetailsDataSelector } from "../data/utilizationAndCoverageDetailsDataSelector";
import { RootState } from "../../../../../store";
import { SavingsPlanCoverageMetadata } from "../../../../../../services/cloudchipr.api";

export const coverageDetailsMetaDataSelector = (
  state: RootState,
): SavingsPlanCoverageMetadata | undefined => {
  return utilizationAndCoverageDetailsDataSelector(state)
    ?.metadata as SavingsPlanCoverageMetadata;
};
