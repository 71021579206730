import { RootState } from "../../../../store";
import { commitmentsRecommendationsPayloadDataSelector } from "../commitmentsRecommendationsPayloadDataSelector";
import { LookbackPeriod } from "../../../../../services/cloudchipr.api";

export const commitmentsRecommendationsLockBackPeriodSelector = (
  state: RootState,
): LookbackPeriod | undefined => {
  const data = commitmentsRecommendationsPayloadDataSelector(state);

  return data.look_back_period;
};
