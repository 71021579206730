import { FC, memo } from "react";
import { CSVLink } from "react-csv";
import { IconButton, Tooltip } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { MetricsFrequenciesType } from "../utils/types/metricsFrequenciesType";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { ResourceType } from "../../../../../../../../../../services/cloudchipr.api";
import { resourceTypeCsvDataSelector } from "../../../../../../../../../../store/account/selectors/resource-type-data/resourceTypeCsvDataSelector";
import { resourceTypeCsvDataFileNameSelector } from "../../../../../../../../../../store/account/selectors/resource-type-data/resourceTypeCsvDataFileNameSelector";

interface CsvDataDownloaderProps {
  resourceType: ResourceType;
  data?: Record<string, string | number>[] | null;
  metricsFrequencies?: MetricsFrequenciesType;
}

export const CsvDataDownloader: FC<CsvDataDownloaderProps> = memo(
  ({ resourceType, data, metricsFrequencies }) => {
    let csvData = useAppSelector((state) =>
      resourceTypeCsvDataSelector(state, resourceType, [], metricsFrequencies),
    ); // TODO: check do we need to load csv data keeping sorting

    if (data) {
      csvData = data;
    }

    const fileName = useAppSelector((state) =>
      resourceTypeCsvDataFileNameSelector(state, resourceType),
    );

    if (!csvData) {
      return null;
    }

    return (
      <Tooltip arrow title="Export CSV" placement="top">
        <span>
          <IconButton
            disabled={!csvData?.length}
            size="small"
            sx={{ "& a": { textDecoration: "none", color: "inherit" }, pb: 0 }}
          >
            <CSVLink
              filename={`${fileName}.csv`}
              data={csvData}
              enclosingCharacter=""
              separator=","
            >
              <DownloadOutlinedIcon fontSize="small" />
            </CSVLink>
          </IconButton>
        </span>
      </Tooltip>
    );
  },
);
