import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResourceType } from "../../../services/cloudchipr.api";
import { RootState } from "../../store";
import {
  CleanDetails,
  setCleanOpen,
  setCurrentCleanId,
  setNewClean,
} from "../cleanSlice";
import { currentAccountIdSelector } from "../../account/selectors/current-account/currentAccountIdSelector";
import { currentAccountProviderTypeSelector } from "../../account/selectors/current-account/currentAccountProviderTypeSelector";
import { NEW_CLEAN_ID } from "../utils/constants";
import { cleanProcessResourcesSelector } from "../selectors/cleanProcessResourcesSelector";

interface CreateSingleCleanThunkArgs {
  resourceType: ResourceType;
  resourceId: string;
}
export const createSingleCleanThunk = createAsyncThunk(
  "createSingleCleanThunk",
  (
    { resourceType, resourceId }: CreateSingleCleanThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const accountId = currentAccountIdSelector(state);
    const currentAccountType = currentAccountProviderTypeSelector(state);
    const { resources, excludedResources } = cleanProcessResourcesSelector(
      state,
      resourceType,
      {
        [resourceId]: true,
      },
    );

    if (accountId && currentAccountType) {
      const cleanProcess: CleanDetails = {
        accountId,
        resourceType,
        resources,
        excludedResources,
      };

      dispatch(setNewClean(cleanProcess));
      dispatch(setCurrentCleanId(NEW_CLEAN_ID));
      dispatch(setCleanOpen(true));
    }
  },
);
