import { FC, useMemo } from "react";
import { Stack, TooltipProps, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { DefinedRange } from "mui-daterange-picker";
import moment from "moment/moment";
import { InfoDataChip } from "./InfoDataChip";
import { TooltipText } from "../../../../../../../common/TooltipText";
import { generateDateFromDateLabelV1 } from "../../../../../../../common/date-range-picker/utils/helpers/generateDateFromDateLabelV1";
import { formatDate } from "../../../../../../../../utils/helpers/date-time/datetime-format";
import { labelByDateLabel } from "../../../../../../../common/date-range-picker/utils/constants/preDefinedRanges";
import {
  DateLabelNullable,
  NullableDate,
} from "../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";

export interface DateInfoChipProps {
  label?: DateLabelNullable;
  from?: NullableDate;
  to?: NullableDate;
  tooltipPlacement?: TooltipProps["placement"];
}

export const DateInfoChip: FC<DateInfoChipProps> = ({
  label,
  from,
  to,
  tooltipPlacement,
}) => {
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const dateLabels = useMemo(() => {
    const datesFromLabel: DefinedRange = generateDateFromDateLabelV1(
      label || null,
      quarterStartMonth,
    );

    let dateTo = to || datesFromLabel?.endDate;
    let dateFrom = from || datesFromLabel?.startDate;

    dateTo = formatDate(moment(dateTo), {
      type: "date",
    });
    dateFrom = formatDate(moment(dateFrom), {
      type: "date",
    });

    const dateLabel = label && labelByDateLabel.get(label)?.long;

    return {
      label: label ? dateLabel : `${dateFrom} - ${dateTo}`,
      tooltip: label ? `${dateFrom} - ${dateTo}` : "",
    };
  }, [label, from, to, quarterStartMonth]);

  return (
    <InfoDataChip>
      <Stack direction="row" spacing={0.5} alignItems="center" component="span">
        <AccessTimeIcon sx={{ fontSize: 18 }} />

        {dateLabels.tooltip ? (
          <TooltipText
            tooltipTitle={dateLabels.tooltip}
            tooltipPlacement={tooltipPlacement ?? "right"}
          >
            {dateLabels.label}
          </TooltipText>
        ) : (
          <Typography variant="body2" color="text.primary" component="span">
            {dateLabels.label}
          </Typography>
        )}
      </Stack>
    </InfoDataChip>
  );
};
