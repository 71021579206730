import { FC } from "react";
import { Skeleton, Stack, Typography } from "@mui/material";
import { Cube } from "../../../../../../../../storybook/charts/multi-type-chart/components/common/Cube";
import { money } from "../../../../../../../../utils/numeral/money";

interface UtilizationAndCoverageGaugeChartCardLabelProps {
  color: string;
  label: string;
  value?: number | null;
  percent?: number | null;
  loading?: boolean;
}

export const UtilizationAndCoverageGaugeChartCardLabel: FC<
  UtilizationAndCoverageGaugeChartCardLabelProps
> = ({ label, loading, value, color, percent }) => {
  return (
    <Stack spacing={0.5}>
      <Stack direction="row" alignItems="center">
        <Cube color={color} width={12} height={12} />
        <Typography variant="caption" color="text.secondary" lineHeight={1}>
          {label}
        </Typography>
      </Stack>

      {loading ? (
        <Skeleton variant="text" height={24} width={150} />
      ) : (
        <Typography variant="body1" fontWeight="bold">
          {money(value)}{" "}
          {percent && (
            <Typography
              variant="inherit"
              color="text.secondary"
              component="span"
            >
              ({percent}%)
            </Typography>
          )}
        </Typography>
      )}
    </Stack>
  );
};
