import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

// TODO: Replace this function usage to getAllProvidersBillingStatusThunk
export const getBillingAllProvidersStatusesThunk = createAsyncThunk(
  "getBillingAllProvidersStatusesThunk",
  (_: void, { dispatch }) => {
    // todo: `getUsersMeOrganisationsCurrentProvidersByProviderBillingStatus` and `getV2UsersMeOrganisationsCurrentProvidersByProviderBillingStatus`
    //  are two billing endpoints which should be merged as one

    dispatch(
      cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentProvidersByProviderBillingStatus.initiate(
        { provider: "aws" },
      ),
    );
    dispatch(
      cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentProvidersByProviderBillingStatus.initiate(
        { provider: "gcp" },
      ),
    );
    dispatch(
      cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentProvidersByProviderBillingStatus.initiate(
        { provider: "azure" },
      ),
    );
  },
);
