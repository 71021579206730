import { RootState } from "../../../../store";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { scheduleResourcesSelector } from "../scheduleResourcesSelector";

export const scheduleResourcesTotalMonthlySpendingSelector = (
  state: RootState,
  resourceType: ResourceType,
  scheduleId: string,
) => {
  const response = scheduleResourcesSelector(state, resourceType, scheduleId);

  return response?.data?.estimated_monthly_waste;
};
