import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../services/cloudchipr.api";

export const getAccountFiltersV2Thunk = createAsyncThunk(
  "filters/getAccountFiltersV2",
  async (accountId: string, { dispatch }) => {
    const { getUsersMeAccountsByAccountIdFiltersCurrentV2 } =
      cloudChiprApi.endpoints;

    const { data } = await dispatch(
      getUsersMeAccountsByAccountIdFiltersCurrentV2.initiate(
        { accountId },
        { forceRefetch: true },
      ),
    );

    return data;
  },
);
