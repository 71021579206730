import { costBreakdownWidgetDataSelector } from "./costBreakdownWidgetDataSelector";
import { RootState } from "../../../../store";
import { buildingInProgressWidgetId } from "../../../thunks/widgets/utils/constants";
import { ForecastedCostRe } from "../../../../../services/cloudchipr.api";

export const costBreakdownWidgetForecastedOptionsSelector = (
  state: RootState,
  widgetId?: string,
): ForecastedCostRe | undefined => {
  const widgetData = costBreakdownWidgetDataSelector(
    state,
    widgetId ?? buildingInProgressWidgetId,
  );

  return widgetData?.forecasted_costs;
};
