import { FC, Fragment } from "react";
import { Card, CardContent } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { useDidMount, useEffectOnceWhen, useWillUnmount } from "rooks";
import { ExecutionLogsHeader } from "./components/header/ExecutionLogsHeader";
import { ExecutionLogsDataGrid } from "./components/data-grid/ExecutionLogsDataGrid";
import { ExecutionLogsToolbar } from "./components/ExecutionLogsToolbar";
import { resetExecutionLogs } from "../../../store/execution-log/executionLogSlice";
import { useAppDispatch } from "../../../store/hooks";
import { getExecutionLogThunk } from "../../../store/execution-log/thunks/getExecutionLogThunk";
import { getSchedulesThunk } from "../../../store/schedules/thunks/getSchedulesThunk";
import { getExecutionLogByIdsThunk } from "../../../store/execution-log/thunks/getExecutionLogByIdsThunk";

export const ExecutionLogs: FC = () => {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const executionLogIds = id ? [id] : searchParams.get("ids")?.split(",");
  const dispatch = useAppDispatch();

  useEffectOnceWhen(() => {
    dispatch(getExecutionLogByIdsThunk(executionLogIds));
  }, !!executionLogIds?.length);

  useEffectOnceWhen(() => {
    dispatch(getExecutionLogThunk());
  }, !executionLogIds?.length);

  useWillUnmount(() => {
    dispatch(resetExecutionLogs());
  });

  useDidMount(() => {
    dispatch(getSchedulesThunk());
  });

  return (
    <Fragment>
      <ExecutionLogsHeader />

      <Card variant="outlined" sx={{ m: 2 }}>
        <CardContent sx={{ p: 2 }}>
          <ExecutionLogsToolbar />

          <ExecutionLogsDataGrid executionLogIds={executionLogIds} />
        </CardContent>
      </Card>
    </Fragment>
  );
};
