import { FC, memo } from "react";
import { Stack, Typography } from "@mui/material";
import { TimePicker } from "../../../off-hours/components/create/TimePicker";

interface RunAtProps {
  value: string;
  mr?: number;
  setFieldValue(key: string, date: string): void;
}

export const RunAt: FC<RunAtProps> = memo(({ setFieldValue, value, mr }) => {
  return (
    <Stack direction="row" alignItems="baseline" mt={4}>
      <Typography variant="subtitle2" mr={mr || 2}>
        Run at
      </Typography>

      <TimePicker
        withIcon
        name="runAt"
        setFieldValue={setFieldValue}
        value={value}
      />
    </Stack>
  );
});
