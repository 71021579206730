import { FC, memo, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useFlag } from "@unleash/proxy-client-react";
import { AccountCardContent } from "./AccountCardContent";
import { AccountCardHeaderInfo } from "./AccountCardHeaderInfo";
import { AccountCardRouterWrapper } from "./AccountCardRouterWrapper";
import { TooltipCardWrapper } from "./TooltipCardWrapper";
import { AccountCardFooter } from "./AccountCardFooter";
import { AccountCardPeriodComparison } from "./AccountCardPeriodComparison";
import { AccountCardHeaderTitle } from "./AccountCardHeaderTitle";
import { AccountActions } from "./actions/AccountActions";
import { AccountCardFilterTemplate } from "./filter-template/AccountCardFilterTemplate";
import { scrollingOnDnD } from "../../../utils/helpers/dnd-helpers";
import { Account } from "../../../../../../services/cloudchipr.api";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";
import { nonDetachableAccountStatuses } from "../../../../../../utils/constants/accounts";

interface AccountCardProps extends Account {
  index: number;
  disableDnD: boolean;
  changeAccountInfoDrawerState: (state: boolean) => void;
  onCardMove: (dragIndex: number, hoverIndex: number) => void;
  onCardDrop: (dragIndex: number, hoverIndex: number, drop: boolean) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const AccountCard: FC<AccountCardProps> = memo(
  ({
    id,
    provider_account_id,
    provider_account_name,
    type,
    ems,
    status,
    total_costs,
    provider_details,
    access_type,
    permission_failed_for_all_resources,
    last_month_total_cost,
    last_resource_updated_date,
    changeAccountInfoDrawerState,
    missing_permissions,
    index,
    onCardMove,
    onCardDrop,
    billing,
    disableDnD,
    is_sub_account,
    filter_set_id,
  }) => {
    const { ref, hovered } = useHover();

    let isRoot = false;

    const enableFilterTemplatesForAccountsList = useFlag(
      "EnableFilterTemplatesForAccountsList",
    );
    const connectedForBilling = status === "connected_for_billing";
    const disableDetach = nonDetachableAccountStatuses.has(status);
    const isDeleting = status === "deleting";
    const isInactive = status === "inactive";
    const accountId = id;

    const [, dropRef] = useDrop({
      accept: "card",
      hover: (item: DragItem, monitor) => {
        const dragIndex = item.index;

        scrollingOnDnD(20, monitor.getSourceClientOffset()?.y);

        if (dragIndex === index) {
          return;
        }

        onCardMove(dragIndex, index);
        item.index = index;
      },
      drop: (draggedRow) => {
        onCardDrop(draggedRow.index, index, true);
      },
    });

    const [{ isDragging }, dragRef, previewRef] = useDrag({
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      item: () => ({ id: accountId, index }),
      type: "card",
      end: (item, monitor) => {
        if (!monitor.didDrop()) {
          onCardDrop(item.index, index, false);
        }
      },
    });

    if (provider_details && "aws" in provider_details) {
      isRoot = !!provider_details?.aws?.is_root;
    }

    useEffect(() => {
      previewRef(getEmptyImage());
    }, [previewRef]);

    return (
      <Box id={id} sx={{ opacity: isDragging ? 0.3 : 1 }}>
        <AccountCardRouterWrapper status={status} navigateTo={`/${type}/${id}`}>
          <Card
            sx={{
              position: "relative",
              borderRadius: 3,
              boxShadow: (theme) => {
                return hovered ? theme.shadows[5] : theme.shadows[0];
              },
            }}
            variant="outlined"
            ref={ref}
          >
            <Box ref={dropRef}>
              <TooltipCardWrapper
                status={status}
                provider={type}
                accountId={id}
                hovered={hovered}
              >
                <CardHeader
                  action={
                    <AccountActions
                      accountId={id}
                      provider={type}
                      attach={connectedForBilling}
                      disableDetach={disableDetach || isDeleting}
                      retry={isInactive}
                      accessMode={access_type}
                      status={status}
                      subAccount={is_sub_account}
                    />
                  }
                  sx={{
                    "& .MuiCardHeader-content": { overflow: "hidden" },
                    py: 1,
                    pr: 1,
                    pl: 0.5,
                  }}
                  title={
                    <Stack direction="row" alignItems="center">
                      <IconButton
                        ref={dragRef}
                        sx={{ cursor: "grab" }}
                        disabled={disableDnD}
                        size="small"
                      >
                        <DragIndicatorIcon color="action" fontSize="small" />
                      </IconButton>

                      <AccountCardHeaderTitle
                        name={provider_account_name || ""}
                        isRoot={isRoot}
                        accountId={provider_account_id || ""}
                      />
                    </Stack>
                  }
                />
                <AccountCardHeaderInfo
                  accountId={provider_account_id || ""}
                  status={status}
                  accessType={access_type}
                  missingPermissions={missing_permissions}
                />
                {enableFilterTemplatesForAccountsList && (
                  <AccountCardFilterTemplate
                    accountId={id ?? ""}
                    provider={type}
                    filterSetId={filter_set_id}
                    status={status}
                  />
                )}

                <Divider />
                <CardContent>
                  <AccountCardContent
                    ems={ems}
                    spending={total_costs}
                    failed={
                      permission_failed_for_all_resources ||
                      connectedForBilling ||
                      isInactive
                    }
                    failedTooltip={
                      permission_failed_for_all_resources
                        ? "No resources have been collected. Please check the missing permissions list in account page."
                        : undefined
                    }
                    changeAccountInfoDrawerState={changeAccountInfoDrawerState}
                    last_resource_updated_date={last_resource_updated_date}
                  />
                </CardContent>

                <Divider />
                <AccountCardPeriodComparison
                  lastMonthTrend={billing?.last_month_trend}
                />
                <Divider />
                <AccountCardFooter
                  spending={last_month_total_cost}
                  billing={billing}
                  provider={type}
                />
              </TooltipCardWrapper>
            </Box>
          </Card>
        </AccountCardRouterWrapper>
      </Box>
    );
  },
);
