import { appliedFiltersByResourceTypeSelector } from "./appliedFiltersByResourceTypeSelector";
import { RootState } from "../../store";
import { ResourceType } from "../../../services/cloudchipr.api";

export const isFilterAppliedToResourceSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const filters = appliedFiltersByResourceTypeSelector(state, resourceType);

  return !!filters?.filter_items?.length;
};
