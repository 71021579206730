import { FC, Fragment, useCallback } from "react";
import { Button, Divider, Tooltip } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { anyFilterAppliedSelector } from "../../../../../../store/resource-explorer/selectors/filters/checkers/anyFilterAppliedSelector";
import { setResourceExplorerDimension } from "../../../../../../store/resource-explorer/resourceExplorerSlice";
import { resourceExplorerDimensionSelector } from "../../../../../../store/resource-explorer/selectors/filters/dimension/resourceExplorerDimensionSelector";

export const FilterByDimensionButton: FC = () => {
  const dispatch = useAppDispatch();

  const selectedDimension = useAppSelector(resourceExplorerDimensionSelector);
  const filterApplied = useAppSelector(anyFilterAppliedSelector);

  const clickHandler = useCallback(() => {
    dispatch(setResourceExplorerDimension({}));
  }, [dispatch]);

  if (selectedDimension) {
    return null;
  }

  return (
    <Fragment>
      <Divider orientation="vertical" flexItem />

      <Tooltip
        placement="top"
        arrow
        title={
          filterApplied ? "Remove custom filters to filter by Dimension." : ""
        }
      >
        <span>
          <Button
            startIcon={<ListIcon />}
            onClick={clickHandler}
            disabled={filterApplied}
          >
            Filter by Dimension
          </Button>
        </span>
      </Tooltip>
    </Fragment>
  );
};
