import { FC, Fragment } from "react";
import { useDidMount } from "rooks";
import { Stack } from "@mui/material";
import { CostBreakdownWidgetContent } from "./components/CostBreakdownWidgetContent";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { fetchCostBreakdownWidgetDataByWidgetIdThunk } from "../../../../../../store/dashboards/thunks/widgets/cost-breakdown/fetchCostBreakdownWidgetDataByWidgetIdThunk";
import { WidgetLoadingState } from "../common/WidgetLoadingState";
import { costBreakdownGetWidgetDataLoadingSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownGetWidgetDataLoadingSelector";
import { WidgetCardHeader } from "../common/widget-header/WidgetCardHeader";
import { costBreakdownWidgetTitlePropsSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownWidgetTitlePropsSelector";
import { resourceExplorerViewNameByViewIdSelector } from "../../../../../../store/resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";
import { costBreakdownWidgetViewIdByWidgetIdSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownWidgetViewIdByWidgetIdSelector";

interface DashboardCostBreakdownWidgetProps {
  widgetId: string;
}

export const DashboardCostBreakdownWidget: FC<
  DashboardCostBreakdownWidgetProps
> = ({ widgetId }) => {
  const dispatch = useAppDispatch();
  const { ref, hovered } = useHover();

  const viewId = useAppSelector((state) =>
    costBreakdownWidgetViewIdByWidgetIdSelector(state, widgetId),
  );

  const widgetTitleProps = useAppSelector((state) =>
    costBreakdownWidgetTitlePropsSelector(state, widgetId),
  );

  const loading = useAppSelector((state) =>
    costBreakdownGetWidgetDataLoadingSelector(state, widgetId),
  );

  const viewName = useAppSelector((state) =>
    resourceExplorerViewNameByViewIdSelector(state, viewId),
  );

  useDidMount(() => {
    dispatch(fetchCostBreakdownWidgetDataByWidgetIdThunk(widgetId));
  });

  return (
    <Stack ref={ref} flexGrow={1} overflow="auto">
      {loading ? (
        <WidgetLoadingState />
      ) : (
        <Fragment>
          <WidgetCardHeader
            hovered={hovered}
            widgetId={widgetId}
            widgetType="cost_breakdown"
            widgetTitleProps={widgetTitleProps}
          />

          <CostBreakdownWidgetContent viewName={viewName} widgetId={widgetId} />
        </Fragment>
      )}
    </Stack>
  );
};
