import { RootState } from "../../../../../store";
import { savingsPlansDataSelector } from "../savingsPlansDataSelector";
import {
  savingsPlansPaymentOptionLabels,
  savingsPlansStateLabels,
  savingsPlansTypeLabels,
} from "../../../../../../components/pages/commtiments/utilization-and-coverage/utils/constants/labels";
import { getPercentage } from "../../../../../../utils/numeral/getPercentage";
import { Tag } from "../../../../../../services/cloudchipr.api";

export const savingsPlansCSVDataSelector = (state: RootState) => {
  const data = savingsPlansDataSelector(state);

  return data?.map((item) => {
    const utilizationPercentage = getPercentage(
      item.used_commitment_to_date ?? 0,
      item.total_commitment_to_date ?? 0,
    ).toFixed(2);

    const utilizationIsNA =
      item.used_commitment_to_date === null ||
      item.total_commitment_to_date === null;

    return {
      "Savings Plan ID": item.provider_unique_id,
      Type: savingsPlansTypeLabels[item.type],
      "Linked Account":
        item.account.provider_account_name ?? item.account.provider_account_id,
      Region: item.region,
      "Payment Option": savingsPlansPaymentOptionLabels[item.payment_option],
      Utilization: utilizationIsNA
        ? "N/A"
        : `${item.used_commitment_to_date} / ${item.total_commitment_to_date} (${utilizationPercentage}%)`,
      "Net Savings": item.net_savings_to_date,
      "Start Date": item.start_date,
      "End Date": item.end_date,
      Status: savingsPlansStateLabels[item.state],
      Tags: item.tags
        ?.map((tag: Tag) => `${tag?.key}:${tag?.value}`)
        .join(" , "),
    };
  });
};
