import { resourceExplorerDataSelector } from "./resourceExplorerDataSelector";
import { RootState } from "../../../../store";
import { DatePeriod } from "../../../../../components/common/date-range-picker/utils/constants/types";

export const resourceExplorerDatePeriodByIdSelector = (
  state: RootState,
): DatePeriod => {
  const data = resourceExplorerDataSelector(state);

  return {
    from: data?.from_date ?? "",
    to: data?.to_date ?? "",
  };
};
