import { FC, useMemo } from "react";
import { InputAdornment, TextField } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import moment from "moment";
import { DatePeriodInputProps } from "../utils/types";

export const DatePeriodInput: FC<DatePeriodInputProps> = ({
  range,
  onClick,
  disabled,
}) => {
  const value = useMemo(() => {
    if (!range.startDate || !range.endDate) {
      return "";
    }

    const from = moment(range.startDate).format("MM/DD/YY");
    const to = moment(range.endDate).format("MM/DD/YY");

    return `${from} - ${to}`;
  }, [range]);
  return (
    <TextField
      fullWidth
      value={value ?? ""}
      onClick={onClick}
      autoComplete="off"
      disabled={disabled}
      size="xsmall"
      placeholder="Select Dates"
      InputProps={{
        sx: { cursor: "pointer" },
        readOnly: true,
        startAdornment: (
          <InputAdornment position="start">
            <CalendarTodayIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <ArrowDropDownIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
