import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { RootState } from "../../store";
import { getProtectResourceCacheKey } from "../utils/helpers";

export const resourcesProtectionLoadingSelector = (
  state: RootState,
  accountId: string,
  resourceIds: string[],
) => {
  return !!cloudChiprApi.endpoints.patchUsersMeAccountsByAccountIdResources.select(
    getProtectResourceCacheKey(accountId, resourceIds),
  )(state)?.isLoading;
};
