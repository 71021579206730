import { FC, ReactNode } from "react";
import { Stack } from "@mui/material";
import { CommitmentsDrawerDateRange } from "../CommitmentsDrawerDateRange";
import { CommitmentsDrawerDateGranularity } from "../CommitmentsDrawerDateGranularity";
import { ChartTypeSelect } from "../../../../../../../../storybook/charts/multi-type-chart/components/common/ChartTypeSelect";

interface DrawerChartHeaderProps {
  children?: ReactNode;
}

export const DrawerChartHeader: FC<DrawerChartHeaderProps> = ({ children }) => {
  return (
    <Stack pb={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1}>
          <CommitmentsDrawerDateRange />
          <CommitmentsDrawerDateGranularity />
        </Stack>

        <ChartTypeSelect
          selectionType="select"
          availableTypes={["area", "bar"]}
        />
      </Stack>

      {children}
    </Stack>
  );
};
