import { FC } from "react";
import { Skeleton, Stack, Typography } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { ExecutionLogsDateRangeSelector } from "./ExecutionLogsDateRangeSelector";
import { ExecutionLogsSearch } from "./ExecutionLogsSearch";
import { useAppSelector } from "../../../../store/hooks";
import { money } from "../../../../utils/numeral/money";
import { executionLogTotalPriceSelector } from "../../../../store/execution-log/selectors/executionLogTotalPriceSelector";
import { executionLogDetailsLoadingSelector } from "../../../../store/execution-log/selectors/executionLogDetailsLoadingSelector";

export const ExecutionLogsToolbar: FC = () => {
  const total = useAppSelector(executionLogTotalPriceSelector);
  const totalLoading = useAppSelector(executionLogDetailsLoadingSelector);
  const enableExecutionLogTotal = useFlag("EnableExecutionLogTotal");

  return (
    <Stack mb={2} spacing={2} alignItems="start">
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <ExecutionLogsDateRangeSelector />
        <ExecutionLogsSearch />
      </Stack>

      {enableExecutionLogTotal && (
        <Typography
          px={1}
          py={0.25}
          borderRadius={1}
          variant="caption"
          bgcolor="grey.200"
        >
          Total:{" "}
          <Typography fontWeight="medium" component="span" variant="inherit">
            {totalLoading ? (
              <Skeleton
                variant="text"
                height={18}
                width={50}
                sx={{ display: "inline-block" }}
              />
            ) : (
              money(total)
            )}
          </Typography>
        </Typography>
      )}
    </Stack>
  );
};
