import { FC, useCallback } from "react";
import { useDidMount } from "rooks";
import { DateRangePicker } from "../../../../../../../common/date-range-picker-v2/DateRangePicker";
import { Dates } from "../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { resourceExplorerDatesSelector } from "../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerDatesSelector";
import { getResourceExplorerMinAvailableDateThunk } from "../../../../../../../../store/resource-explorer/thunks/widgets/getResourceExplorerMinAvailableDateThunk";
import { resourceExplorerMinAvailableDateSelector } from "../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerMinAvailableDateSelector";
import { allDateRangesByQuarterStartMonthSelector } from "../../../../../../../../store/common/selectors/date-ranges/allDateRangesByQuarterStartMonthSelector";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { changeResourceExplorerDateThunk } from "../../../../../../../../store/resource-explorer/thunks/resource-explorer/changeResourceExplorerDateThunk";

export const ResourceExplorerDateRange: FC = () => {
  const dispatch = useAppDispatch();

  const dates = useAppSelector(resourceExplorerDatesSelector);

  const minDate = useAppSelector(resourceExplorerMinAvailableDateSelector);

  const dateRanges = useAppSelector(allDateRangesByQuarterStartMonthSelector);
  const orgQuarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const dateSelectHandler = useCallback(
    (dates: Dates) => {
      dispatch(changeResourceExplorerDateThunk(dates));
    },
    [dispatch],
  );

  useDidMount(() => {
    dispatch(getResourceExplorerMinAvailableDateThunk());
  });

  if (!dates) {
    return null;
  }

  return (
    <DateRangePicker
      highlightToday
      size="xsmall"
      ranges={dateRanges}
      selectedDates={dates}
      availableMinDate={minDate}
      onDateSelect={dateSelectHandler}
      quarterStartMonth={orgQuarterStartMonth}
    />
  );
};
