import { FC } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { DashboardNotFoundWidget } from "../../common/DashboardNotFoundWidget";
import { DashboardEmptyWidgetState } from "../../common/DashboardEmptyWidgetState";
import { largestCostChangesWidgetNotFoundSelector } from "../../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/largestCostChangesWidgetNotFoundSelector";
import { largestCostChangesWidgetByIdSelector } from "../../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/largestCostChangesWidgetByIdSelector";
import { LargestCostChangesWidgetBody } from "../../../adding-widget/widget-create/widget-setups/larges-cost-changes/content/components/LargestCostChangesWidgetBody";

interface LargestCostChangesWidgetContentProps {
  widgetId: string;
  viewName?: string;
}
export const LargestCostChangesWidgetContent: FC<
  LargestCostChangesWidgetContentProps
> = ({ widgetId, viewName }) => {
  const widgetDataNotFound = useAppSelector((state) =>
    largestCostChangesWidgetNotFoundSelector(state, widgetId),
  );

  const widget = useAppSelector((state) =>
    largestCostChangesWidgetByIdSelector(state, widgetId),
  );

  if (widgetDataNotFound) {
    return (
      <DashboardNotFoundWidget
        name={viewName ?? ""}
        label="resource explorer view"
      />
    );
  }

  if (!widget) {
    return <DashboardEmptyWidgetState />;
  }

  return <LargestCostChangesWidgetBody widgetId={widgetId} />;
};
