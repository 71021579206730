import { FC, ReactNode } from "react";
import { FilterButton } from "./FilterButton";
import { useAppSelector } from "../../../../../../store/hooks";
import { filterChangedByKeySelector } from "../../../../../../store/resource-explorer/selectors/filters/checkers/filterChangedByKeySelector";
import { resourceExplorerIsDefaultSelector } from "../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerIsDefaultSelector";
import {
  ProviderType,
  ResourceExplorerFilterKeyType,
} from "../../../../../../services/cloudchipr.api";
import { FilterTriggerComponentProps } from "../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";

interface FilterDropdownTriggerProps extends FilterTriggerComponentProps {
  viewId: string;
  provider: ProviderType;
  filterKey: ResourceExplorerFilterKeyType;
  title: string;
  startIcon?: ReactNode;
}

export const FilterDropdownTrigger: FC<FilterDropdownTriggerProps> = ({
  viewId,
  provider,
  selectedValues,
  onClick,
  title,
  filterKey,
  startIcon,
}) => {
  const isViewDefault = useAppSelector(resourceExplorerIsDefaultSelector);

  const isFilterChanged = useAppSelector((state) =>
    filterChangedByKeySelector(state, {
      viewId,
      key: filterKey,
      provider,
    }),
  );

  const changedColor = isViewDefault ? undefined : "warning";

  return (
    <FilterButton
      title={title}
      onClick={onClick}
      startIcon={startIcon}
      count={selectedValues.length ? `(${selectedValues.length})` : ""}
      color={isFilterChanged ? changedColor : undefined}
      variant={
        isFilterChanged || selectedValues.length ? "contained" : "outlined"
      }
    />
  );
};
