import { LargestCostChangesSetupType } from "../types/setups/largestCostChangesSetupType";
import { dashboardWidgetLabels } from "../../../../components/pages/dashboard/utils/constants/constants";
export const largestCostChangesDefaultData: LargestCostChangesSetupType = {
  widgetType: "largest_cost_changes",
  name: dashboardWidgetLabels.largest_cost_changes ?? "",
  date: { from: null, to: null, label: "past_fourteen_days" },
  grouping: "service",
  viewId: undefined,
  priceDirection: "increasing",
  sortingBy: "sort_by_percentage",
  itemsCount: 10,
  minCostChange: null,
  minPercentageChange: null,
};
