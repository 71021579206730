import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { awsPossibleFiltersSelector } from "./aws/awsPossibleFiltersSelector";
import { gcpPossibleFiltersSelector } from "./gcp/gcpPossibleFiltersSelector";
import { azurePossibleFiltersSelector } from "./azure/azurePossibleFiltersSelector";
import { resourceExplorerFilterKeysAsProvidersSelector } from "../filter-providers/resourceExplorerFilterKeysAsProvidersSelector";
import {
  ResourceExplorerFilterKeyType,
  ResourceExplorerPossibleFilter,
  ProviderType,
} from "../../../../../services/cloudchipr.api";

export const resourceExplorerAllPossibleFiltersSelector =
  createDraftSafeSelector(
    [
      resourceExplorerFilterKeysAsProvidersSelector,
      awsPossibleFiltersSelector,
      gcpPossibleFiltersSelector,
      azurePossibleFiltersSelector,
    ],
    (providers, aws, gcp, azure) => {
      const providersPossibleFilters = {
        aws,
        gcp,
        azure,
      };

      return providers.reduce(
        (acc, item) => {
          const filters = providersPossibleFilters[item];

          const structuredFilters = filters?.reduce(
            (result, item) => {
              result[item.key] = item;
              return result;
            },
            {} as Record<
              ResourceExplorerFilterKeyType,
              ResourceExplorerPossibleFilter
            >,
          );

          if (structuredFilters) {
            acc[item] = structuredFilters;
          }

          return acc;
        },
        {} as Record<
          ProviderType,
          Record<ResourceExplorerFilterKeyType, ResourceExplorerPossibleFilter>
        >,
      );
    },
  );
