import { costBreakdownV2WidgetSetupDataSelector } from "./costBreakdownV2WidgetSetupDataSelector";
import { RootState } from "../../../../../store";
import { WidgetCosts } from "../../../../utils/types/types";
import { calculateTotalCostTrend } from "../../../../../../components/pages/utils/helpers/calculateTotalCostTrend";
import { getAverageDataByFrequency } from "../../../../utils/helpers/costBreakdown/getAverageDataByFrequency";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";

export const costBreakdownV2WidgetSetupCostsSelector = (
  state: RootState,
): WidgetCosts => {
  const widgetData = costBreakdownV2WidgetSetupDataSelector(state);
  const frequency =
    costBreakdownV2SetupPropertyByKeySelector("frequency")(state);

  const totalCost = +(widgetData?.total_cost ?? 0).toFixed(2);
  const previousPeriodTotalCost = +(
    widgetData?.previous_period_total_cost ?? 0
  ).toFixed(2);

  const totalCostTrend = calculateTotalCostTrend(
    totalCost,
    previousPeriodTotalCost,
  );

  const average = getAverageDataByFrequency(frequency, widgetData);

  return {
    total: {
      cost: totalCost,
      trend: previousPeriodTotalCost > 0 ? totalCostTrend : null,
      dateDetails: widgetData?.total_cost_date_details,
    },

    previousPeriodTotal: {
      cost: previousPeriodTotalCost,
      dateDetails: widgetData?.previous_period_cost_date_details,
    },

    average,

    liveUsageTotal: {
      cost: +(widgetData?.live_usage_total_cost ?? 0)?.toFixed(2),
    },
    forecasted_costs: widgetData?.forecasted_costs,
  };
};
