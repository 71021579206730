import { postUsersMeOrganisationsCurrentWidgetsCostByCategorySelector } from "./postUsersMeOrganisationsCurrentWidgetsCostByCategorySelector";
import { RootState } from "../../../../../store";
import { PostUsersMeOrganisationsCurrentWidgetsCostByCategoryApiResponse } from "../../../../../../services/cloudchipr.api";

export const costByCategoryWidgetDataSelector = (
  state: RootState,
  id: string,
):
  | PostUsersMeOrganisationsCurrentWidgetsCostByCategoryApiResponse
  | undefined => {
  const response =
    postUsersMeOrganisationsCurrentWidgetsCostByCategorySelector(id)(state);

  return response?.data;
};
