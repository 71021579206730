import { costBreakdownV2WidgetDataByWidgetIdSelector } from "./costBreakdownV2WidgetDataByWidgetIdSelector";
import { costBreakdownV2WidgetFrequencySelector } from "./costBreakdownV2WidgetFrequencySelector";
import { RootState } from "../../../../../store";
import { ChartDataType } from "../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { formatResourceExplorerChartData } from "../../../../../resource-explorer/utils/helpers/formatResourceExplorerChartData";

export const costBreakdownV2WidgetChartDataSelector = (
  state: RootState,
  widgetId: string,
): ChartDataType[] | [] => {
  const data = costBreakdownV2WidgetDataByWidgetIdSelector(
    state,
    widgetId,
  )?.data;

  const frequency = costBreakdownV2WidgetFrequencySelector(state, widgetId);

  if (!data || !frequency) {
    return [];
  }

  return formatResourceExplorerChartData(data, frequency);
};
