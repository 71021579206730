import { resourceTypeDataSelector } from "./resourceTypeDataSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { currentAccountPageStateSelector } from "../current-account/currentAccountPageStateSelector";

export const resourceTypeDataSpendingSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const pageState = currentAccountPageStateSelector(state);

  if (pageState === "pending") {
    return 0;
  }

  return (
    resourceTypeDataSelector(state, resourceType)?.total_monthly_spending ?? 0
  );
};
