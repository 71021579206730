import { FC, useCallback } from "react";
import { Drawer, Grid, Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CostAndUsageSumCard } from "./cards/CostAndUsageSumCard";
import { CostBreakdownCard } from "./cards/CostBreakdownCard";
import { CostComparisonCard } from "./cards/CostComparisonCard";
import { LargestCostCard } from "./cards/LargestCostCard";
import { CostByCategoryCard } from "./cards/CostByCategoryCard";
import { LiveUsageCard } from "./cards/LiveUsageCard";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { widgetSelectorStateSelector } from "../../../../../../store/dashboards/selectors/dashboard/widgetSelectorStateSelector";
import { setWidgetSelectorState } from "../../../../../../store/dashboards/dashboardsSlice";

export const WidgetSelectDrawer: FC = () => {
  const dispatch = useAppDispatch();
  const widgetSelectorOpen = useAppSelector(widgetSelectorStateSelector);
  const closeWidgetSelector = useCallback(() => {
    dispatch(setWidgetSelectorState(false));
  }, [dispatch]);

  return (
    <Drawer
      anchor="right"
      open={widgetSelectorOpen}
      onClose={closeWidgetSelector}
    >
      <Stack width={736} px={2}>
        <Stack
          my={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle1" fontWeight="medium">
            Add Widget
          </Typography>

          <IconButton onClick={closeWidgetSelector} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Stack>

        <Grid container spacing={2}>
          <Grid item sm={6}>
            <CostAndUsageSumCard />
          </Grid>

          <Grid item sm={6}>
            <CostBreakdownCard />
          </Grid>

          <Grid item sm={6}>
            <CostComparisonCard />
          </Grid>

          <Grid item sm={6}>
            <LargestCostCard />
          </Grid>

          <Grid item sm={6}>
            <CostByCategoryCard />
          </Grid>

          <Grid item sm={6}>
            <LiveUsageCard />
          </Grid>
        </Grid>
      </Stack>
    </Drawer>
  );
};
