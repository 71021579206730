import { FC } from "react";
import { ResourceType } from "../../../../../services/cloudchipr.api";
// AWS
import S3SvgIcon from "../../../../../assets/images/logos/resources/aws/S3SvgIcon";
import Ec2SvgIcon from "../../../../../assets/images/logos/resources/aws/Ec2SvgIcon";
import EksSvgIcon from "../../../../../assets/images/logos/resources/aws/EksSvgIcon";
import ElcSvgIcon from "../../../../../assets/images/logos/resources/aws/ElcSvgIcon";
import RdsSvgIcon from "../../../../../assets/images/logos/resources/aws/RdsSvgIcon";
import DynamoDbTableSvgIcon from "../../../../../assets/images/logos/resources/aws/DynamoDbTableSvgIcon";
import NatGatewaySvgIcon from "../../../../../assets/images/logos/resources/aws/NatGatewaySvgIcon";
import EcsSvgIcon from "../../../../../assets/images/logos/resources/aws/EcsSvgIcon";
// Azure
import AzAksSvgIcon from "../../../../../assets/images/logos/resources/azure/AzAksSvgIcon";
import AzDiskSvgIcon from "../../../../../assets/images/logos/resources/azure/AzDiskSvgIcon";
import AzIpSvgIcon from "../../../../../assets/images/logos/resources/azure/AzIpSvgIcon";
import AzSqlSvgIcon from "../../../../../assets/images/logos/resources/azure/AzSqlSvgIcon";
import AzVmSvgIcon from "../../../../../assets/images/logos/resources/azure/AzVmSvgIcon";
import AzVmssSvgIcon from "../../../../../assets/images/logos/resources/azure/AzVmssSvgIcon";
import AzLbSvgIcon from "../../../../../assets/images/logos/resources/azure/AzLbSvgIcon";
// GCP
import VmSvgIcon from "../../../../../assets/images/logos/resources/gcp/VmSvgIcon";
import SqlSvgIcon from "../../../../../assets/images/logos/resources/gcp/SqlSvgIcon";
import GkeSvgIcon from "../../../../../assets/images/logos/resources/gcp/GkeSvgIcon";
import IpSvgIcon from "../../../../../assets/images/logos/resources/gcp/IpSvgIcon";

interface IconByResourceTypeProps {
  resourceType: ResourceType;
  primary?: boolean;
}

export const IconByResourceType: FC<IconByResourceTypeProps> = ({
  resourceType,
  primary,
}) => {
  switch (resourceType) {
    // AWS
    case "ec2":
      return <Ec2SvgIcon primary={primary} />;
    case "eks":
      return <EksSvgIcon primary={primary} />;
    case "elc":
      return <ElcSvgIcon primary={primary} />;
    case "rds":
      return <RdsSvgIcon primary={primary} />;
    case "s3":
      return <S3SvgIcon primary={primary} />;
    case "dynamo_db_table":
      return <DynamoDbTableSvgIcon primary={primary} />;
    case "nat_gateway":
      return <NatGatewaySvgIcon primary={primary} />;
    case "ecs":
      return <EcsSvgIcon primary={primary} />;

    // AZURE
    case "az_aks":
      return <AzAksSvgIcon primary={primary} />;
    case "az_disk":
      return <AzDiskSvgIcon primary={primary} />;
    case "az_ip":
      return <AzIpSvgIcon primary={primary} />;
    case "az_lb":
      return <AzLbSvgIcon primary={primary} />;
    case "az_sql":
      return <AzSqlSvgIcon primary={primary} />;
    case "az_vm":
      return <AzVmSvgIcon primary={primary} />;
    case "az_vmss":
      return <AzVmssSvgIcon primary={primary} />;

    // GCP
    case "vm":
      return <VmSvgIcon primary={primary} />;
    case "sql":
      return <SqlSvgIcon primary={primary} />;
    case "gke":
      return <GkeSvgIcon primary={primary} />;
    case "ip":
      return <IpSvgIcon primary={primary} />;
  }
};
