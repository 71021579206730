import { FC, Fragment, SyntheticEvent, useMemo } from "react";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { AccountFilterSetV2 } from "../../../../../services/cloudchipr.api";

interface FilterExistingTemplatesProps {
  saveAsNew: boolean;
  onSaveAsNew(event: SyntheticEvent): void;
  selectHandler(event: SelectChangeEvent): void;
  selectedFilterTemplateId: string;
  templates: AccountFilterSetV2[];
}

export const FilterExistingTemplates: FC<FilterExistingTemplatesProps> = ({
  saveAsNew,
  onSaveAsNew,
  selectHandler,
  selectedFilterTemplateId,
  templates,
}) => {
  const notDefaultFilterTemplates = useMemo(() => {
    return templates?.filter(
      ({ filter_set }) => filter_set?.source !== "cloudchipr",
    );
  }, [templates]);

  return (
    <Fragment>
      <Divider sx={{ my: 3 }}>
        <Typography variant="caption" color="text.secondary" px={1}>
          OR
        </Typography>
      </Divider>

      <FormControlLabel
        sx={{ mb: 3 }}
        onChange={onSaveAsNew}
        control={<Checkbox size="small" checked={saveAsNew} />}
        label="Select existing filter template to overwrite"
      />

      <FormControl fullWidth size="small">
        <Typography
          variant="body2"
          color={saveAsNew ? "text.primary" : "text.disabled"}
          pb={1}
        >
          Select Filter Template
        </Typography>

        <Select
          disabled={!saveAsNew}
          placeholder="Filter Templates"
          onChange={selectHandler}
          value={selectedFilterTemplateId}
        >
          <ListSubheader sx={{ pb: 1 }}>
            <Typography variant="subtitle2" color="text.secondary">
              Filter Templates
            </Typography>
          </ListSubheader>

          {notDefaultFilterTemplates?.map(({ filter_set }) => (
            <MenuItem key={filter_set?.id} value={filter_set?.id}>
              {filter_set?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Fragment>
  );
};
