import { currentOpenCleanProcessSelector } from "./currentOpenCleanProcessSelector";
import { cleanActionTypeSelector } from "./cleanActionTypeSelector";
import { RootState } from "../../store";
import { getFirstParentInHierarchyResourceType } from "../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { resourceTypeDataWithChildrenSelector } from "../../account/selectors/resource-type-data/resourceTypeDataWithChildrenSelector";
import { CleanResourcesDataType } from "../../../components/pages/common/clean/clean-dialog/utils/types";

export const cleanResourcesSelector = (
  state: RootState,
): CleanResourcesDataType | null => {
  const currentProcess = currentOpenCleanProcessSelector(state);
  if (!currentProcess?.resourceType) {
    return null;
  }

  const resources = resourceTypeDataWithChildrenSelector(
    state,
    getFirstParentInHierarchyResourceType(currentProcess?.resourceType) ??
      currentProcess?.resourceType,
  );

  const resourcesForClean = new Set(
    currentProcess?.resources?.map((r) => r.id),
  );

  const cleanActionType = cleanActionTypeSelector(state);

  if (!resources) {
    return null;
  }

  return resources
    .filter(({ resource }) => resourcesForClean.has(resource.id))
    .map(({ resource }) => ({
      ...resource,
      cleanAction: cleanActionType || "delete",
    }));
};
