import { FC, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import { PieLegendContentProps } from "../../../../../../../../storybook/charts/multi-type-chart/components/chart-by-type/pie-chart/PieLegentContent";
import { LegendItem } from "../../../../../../../../storybook/charts/multi-type-chart/components/common/legend/LegendItem";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { money } from "../../../../../../../../utils/numeral/money";
import { RootState } from "../../../../../../../../store/store";
import { costByCategoryFilteredResourcesTotalData } from "../../../../../../../../store/dashboards/selectors/widgets/cost-by-category/chart/costByCategoryFilteredResourcesTotalData";
import { useAppSelector } from "../../../../../../../../store/hooks";
import {
  costByCategoryChartPrefixes,
  multiStackChartPrefixSeparator,
} from "../../../../../../../../storybook/charts/multi-type-chart/components/chart-by-type/multi-stack-chart/utils/constants/constants";

interface CostByCategoryChartLegendContentProps extends PieLegendContentProps {
  widgetId: string;
  displayFilteredResources: boolean;
}
export const CostByCategoryChartLegendContent: FC<
  CostByCategoryChartLegendContentProps
> = ({
  colors,
  keys,
  highlightable,
  setVisibleKeys,
  selectable,
  visibleKeys,
  legendItemLabelFormatter,
  data,
  widgetId,
  displayFilteredResources,
}) => {
  const selector = useCallback(
    (state: RootState) => {
      if (!displayFilteredResources) {
        return null;
      }

      return costByCategoryFilteredResourcesTotalData(state, widgetId);
    },
    [widgetId, displayFilteredResources],
  );

  const filteredResourcesTotalData = useAppSelector(selector);

  return (
    <Stack>
      {keys.map((key) => {
        const value = data?.find((item) => item.name === key)?.value ?? 0;

        const filteredResourceKey = `${costByCategoryChartPrefixes.secondary}${multiStackChartPrefixSeparator}${key}`;
        const filteredResourceValue =
          filteredResourcesTotalData?.[filteredResourceKey];

        return (
          <LegendItem
            key={key}
            itemKey={key}
            allKeys={keys}
            colors={colors}
            selectable={selectable}
            visibleKeys={visibleKeys}
            setVisibleKeys={setVisibleKeys}
            highlightable={highlightable}
            legendItemLabelFormatter={legendItemLabelFormatter}
          >
            <Stack direction="row" alignItems="center" maxWidth="100%">
              <TypographyWithTooltip title={`${key} -`} variant="body1" />

              <Typography variant="body2" fontWeight="bold" ml={0.5}>
                {money(value)} /{" "}
                <Typography variant="inherit" color="error" component="span">
                  {money(filteredResourceValue)}
                </Typography>
              </Typography>
            </Stack>
          </LegendItem>
        );
      })}
    </Stack>
  );
};
