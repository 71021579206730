import { costBreakdownV2WidgetSetupDataSelector } from "./costBreakdownV2WidgetSetupDataSelector";
import { RootState } from "../../../../../store";

export const costBreakdownV2WidgetSetupForecastedOptionsSelector = (
  state: RootState,
) => {
  const widgetData = costBreakdownV2WidgetSetupDataSelector(state);

  return widgetData?.forecasted_costs;
};
