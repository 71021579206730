import { FC } from "react";
import { Navigate } from "react-router-dom";
import { useDidMount } from "rooks";
import { getBillingAllProvidersStatusesThunk } from "../../../store/common/thunks/billing-status/getBillingAllProvidersStatusesThunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { billingProvidersStatusesSelector } from "../../../store/common/selectors/billing-analytics/billingProvidersStatusesSelector";
import { useAppAbility } from "../../../services/permissions";
import { allAccountsGroupedByProviderAndOrgSelector } from "../../../store/accounts/selectors/all-providers/allAccountsGroupedByProviderAndOrgSelector";
import { Account } from "../../../services/cloudchipr.api";

export const RedirectPage: FC = () => {
  const dispatch = useAppDispatch();

  const { cannot } = useAppAbility();
  const cannotView = cannot("view", "account");

  const existingProviders = useAppSelector(billingProvidersStatusesSelector);
  const allAccountsGroupedByProviderAndOrg = useAppSelector(
    allAccountsGroupedByProviderAndOrgSelector,
  );
  const awsAccounts = allAccountsGroupedByProviderAndOrg?.aws;
  const gcpAccounts = allAccountsGroupedByProviderAndOrg?.gcp;
  const azureAccounts = allAccountsGroupedByProviderAndOrg?.azure;

  const awsExistingOrg = getExistingOrg(awsAccounts);
  const gcpExistingOrg = getExistingOrg(gcpAccounts);
  const azureExistingOrg = getExistingOrg(azureAccounts);

  useDidMount(() => {
    if (cannotView) {
      return;
    }

    dispatch(getBillingAllProvidersStatusesThunk());
  });

  if (cannotView) {
    return <Navigate to="/access-denied" replace />;
  }

  if (!existingProviders) {
    return null;
  }

  if (!awsAccounts || !gcpAccounts || !azureAccounts) {
    return null;
  }

  if (awsExistingOrg) {
    return <Navigate to={`/aws/${awsExistingOrg}/accounts`} />;
  }

  if (gcpExistingOrg) {
    return <Navigate to={`/gcp/${gcpExistingOrg}/accounts`} />;
  }

  if (azureExistingOrg) {
    return <Navigate to={`/azure/${azureExistingOrg}/accounts`} />;
  }

  return <Navigate to="/account/setup" />;
};

const getExistingOrg = (accountsGroupedByOrg?: Record<string, Account[]>) => {
  if (!accountsGroupedByOrg) {
    return "";
  }

  const orgWithAccounts = Object.entries(accountsGroupedByOrg).find(
    ([, accounts]) => {
      return !!accounts.length;
    },
  );

  return orgWithAccounts?.at(0) ?? "";
};
