import { RootState } from "../../../../../store";
import { commitmentsSliceSelector } from "../../../commitmentsSliceSelector";
import { UtilizationAndCoverageDetailsDrawer } from "../../../../utils/types/types";

export const utilizationAndCoverageDrawerDetailsSelector = (
  state: RootState,
): UtilizationAndCoverageDetailsDrawer => {
  const slice = commitmentsSliceSelector(state);

  return slice.coverageAndUtilization.detailsDrawer;
};
