import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../services/cloudchipr.api";
import { setAccounts } from "../accountsSlice";

export const getAccountsThunk = createAsyncThunk(
  "accounts/getAccounts",
  async (accountStatuses: string | void, { dispatch }) => {
    const statuses =
      accountStatuses ??
      "initial_pulling,connected,failed,deactivated,connected_for_billing,inactive";

    const { data } = await dispatch(
      cloudChiprApi.endpoints.getUsersMeAccounts.initiate(
        { statuses },
        { forceRefetch: true },
      ),
    );

    data && dispatch(setAccounts(data));
  },
);
