import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { useDidMount } from "rooks";
import { SavingsPlanDrawerContentInfoCards } from "./SavingsPlanDrawerContentInfoCards";
import { SavingsPlanDrawerCollapsableSection } from "./SavingsPlanDrawerCollapsableSection";
import { SavingsPlanDrawerRecommendationsSection } from "./recommendations/SavingsPlanDrawerRecommendationsSection";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { utilizationAndCoverageDrawerTargetIdSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/options/utilizationAndCoverageDrawerTargetIdSelector";
import { CommitmentsDrawerHeader } from "../common/CommitmentsDrawerHeader";
import { selectedSavingsPlanURLSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/savings-plan/selectedSavingsPlanURLSelector";
import { getUtilizationAndCoverageDetailsThunk } from "../../../../../../../store/commitments/thunks/getUtilizationAndCoverageDetailsThunk";

interface SavingsPlanDrawerContentProps {
  onClose(): void;
}

export const SavingsPlanDrawerContent: FC<SavingsPlanDrawerContentProps> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const savingsPlanId = useAppSelector(
    utilizationAndCoverageDrawerTargetIdSelector,
  );
  const savingsPlanUrl = useAppSelector(selectedSavingsPlanURLSelector);

  useDidMount(() => {
    dispatch(getUtilizationAndCoverageDetailsThunk());
  });

  return (
    <Fragment>
      <CommitmentsDrawerHeader
        title={savingsPlanId}
        onClose={onClose}
        url={savingsPlanUrl}
      />

      <Box width="80dvw">
        <SavingsPlanDrawerContentInfoCards />
        <SavingsPlanDrawerCollapsableSection />
        <SavingsPlanDrawerRecommendationsSection />
      </Box>
    </Fragment>
  );
};
