import { FC, Fragment } from "react";
import { useDidMount } from "rooks";
import { Stack } from "@mui/material";
import { LargestCostChangesWidgetContent } from "./components/LargestCostChangesWidgetContent";
import { LargestCostChangesWidgetCardHeader } from "./components/header/LargestCostChangesWidgetCardHeader";
import { LargestCostChangesWidgetFooter } from "./components/footer/LargestCostChangesWidgetFooter";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { WidgetLoadingState } from "../common/WidgetLoadingState";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";
import { fetchLargestCostChangesWidgetDataByWidgetIdThunk } from "../../../../../../store/dashboards/thunks/widgets/largest-cost-changes/fetchLargestCostChangesWidgetDataByWidgetIdThunk";
import { largestCostChangesWidgetViewIdByWidgetIdSelector } from "../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/largestCostChangesWidgetViewIdByWidgetIdSelector";
import { largestCostChangesGetWidgetDataLoadingSelector } from "../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/loading/largestCostChangesGetWidgetDataLoadingSelector";
import { resourceExplorerViewNameByViewIdSelector } from "../../../../../../store/resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";
import { largestCostChangesWidgetDataSelector } from "../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/largestCostChangesWidgetDataSelector";
import { largestCostChangesWidgetTitlePropsSelector } from "../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/largestCostChangesWidgetTitlePropsSelector";
import { widgetREPrevDateDetailsSelector } from "../../../../../../store/dashboards/selectors/widgets/common/widget-re-view/WidgetREPrevDateDetailsSelector";

interface LargestCostChangesWidgetProps {
  widgetId: string;
}

export const LargestCostChangesWidget: FC<LargestCostChangesWidgetProps> = ({
  widgetId,
}) => {
  const dispatch = useAppDispatch();
  const { ref, hovered } = useHover();

  const viewId = useAppSelector((state) =>
    largestCostChangesWidgetViewIdByWidgetIdSelector(state, widgetId),
  );

  const loading = useAppSelector((state) =>
    largestCostChangesGetWidgetDataLoadingSelector(state, widgetId),
  );
  const viewName = useAppSelector((state) =>
    resourceExplorerViewNameByViewIdSelector(state, viewId),
  );
  const data = useAppSelector((state) =>
    largestCostChangesWidgetDataSelector(state, {
      widgetId,
    }),
  );

  const widgetTitleProps = useAppSelector((state) =>
    largestCostChangesWidgetTitlePropsSelector(state, widgetId),
  );

  const prevDate = useAppSelector((state) =>
    widgetREPrevDateDetailsSelector(state, widgetId),
  );
  useDidMount(() => {
    dispatch(fetchLargestCostChangesWidgetDataByWidgetIdThunk(widgetId));
  });

  return (
    <Stack ref={ref} flexGrow={1} overflow="auto">
      {loading ? (
        <WidgetLoadingState />
      ) : (
        <Fragment>
          <LargestCostChangesWidgetCardHeader
            widgetId={widgetId}
            hovered={hovered}
          />

          <LargestCostChangesWidgetContent
            widgetId={widgetId}
            viewName={viewName}
          />
          {!loading && !!data.length && (
            <LargestCostChangesWidgetFooter
              prevDate={prevDate}
              date={widgetTitleProps?.dates}
            />
          )}
        </Fragment>
      )}
    </Stack>
  );
};
