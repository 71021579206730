import { createAsyncThunk } from "@reduxjs/toolkit";
import { getExecutionLogThunk } from "./getExecutionLogThunk";
import { setExecutionLogFilters } from "../executionLogSlice";

export const getExecutionLogByIdsThunk = createAsyncThunk(
  "executionLog/getExecutionLogByIds",
  async (executionLogIds: string[] | undefined, { dispatch }) => {
    dispatch(setExecutionLogFilters({ executionLogIds }));
    await dispatch(getExecutionLogThunk());
    dispatch(setExecutionLogFilters({ executionLogIds: null }));
  },
);
