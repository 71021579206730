import { RootState } from "../../store";
import { ResourceType } from "../../../services/cloudchipr.api";

interface FilteredProtectItem {
  type?: string;
  id?: string;
  is_protected?: boolean;
}
export const protectProcessResourcesSelector = (
  state: RootState,
  resourceType: ResourceType,
  protectedResources: string[],
  actionType: "protect" | "unprotect",
): FilteredProtectItem[] => {
  return protectedResources.map((item) => {
    return {
      id: item,
      type: resourceType,
      is_protected: actionType === "protect",
    };
  });
};
