import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { getScheduleNestRunDatesFixedCacheKey } from "../../../../../components/pages/schedule/classic/components/create/tabs/utils/constants";
import { AutomationTabType } from "../../../../../components/pages/schedule/common/utils/types/types";

const selector = cloudChiprApi.endpoints.postUsersMeSchedulesNextRunDate.select;
export const scheduleNextRunDatesLoadingSelector = (
  state: RootState,
  frequency?: AutomationTabType,
): boolean => {
  return !!selector(getScheduleNestRunDatesFixedCacheKey(frequency))(state)
    ?.isLoading;
};
