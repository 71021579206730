import { currentAccountSelector } from "./currentAccountSelector";
import { RootState } from "../../../store";

export const azureAccountServicePrincipalSelector = (state: RootState) => {
  const currentAccount = currentAccountSelector(state);

  if (
    !currentAccount ||
    !(
      currentAccount?.provider_details &&
      "azure" in currentAccount.provider_details
    ) ||
    !currentAccount.provider_details.azure
  ) {
    return null;
  }

  return currentAccount.provider_details.azure.service_principal;
};
