import { FC } from "react";
import { Typography, TypographyProps } from "@mui/material";
import moment from "moment/moment";
import { NullableDate } from "../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";

interface ScheduledNextRunDateTimeProps extends TypographyProps {
  nextRun?: NullableDate;
}

export const ScheduledNextRunDateTime: FC<ScheduledNextRunDateTimeProps> = ({
  nextRun,
  ...props
}) => {
  if (!nextRun) {
    return null;
  }

  return (
    <Typography variant="body2" sx={{ whiteSpace: "nowrap" }} {...props}>
      {moment(nextRun).format("ddd")},{" "}
      {formatDate(nextRun, {
        type: "dateWithComma",
      })}{" "}
      {formatDate(nextRun, {
        type: "time",
      })}
    </Typography>
  );
};
