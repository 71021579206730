import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { getNumeralViewItemsPaddings } from "./utils/helpers/getNumeralViewItemsPaddings";
import { ProviderLogo } from "../../../../../../../../common/provider-logo/ProviderLogo";
import { ProviderType } from "../../../../../../../../../services/cloudchipr.api";
import { TrendChip } from "../../../../../../../../common/chips/TrendChip";
import { money } from "../../../../../../../../../utils/numeral/money";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";

interface WidgetNumeralViewItemProps {
  label: string;
  cost: number | null;
  trend: number | null;
  itemsCount: number;
  providers?: ProviderType[];
  tooltipTitle?: string;
  CustomLabel?: FC<{ fontSize: number; label: string }>;
  secondaryLabel?: string;
  postfix?: string;
  approximate?: boolean;
}

export const WidgetNumeralViewItem: FC<WidgetNumeralViewItemProps> = ({
  label,
  providers,
  cost,
  trend,
  itemsCount,
  tooltipTitle,
  CustomLabel,
  secondaryLabel,
  postfix,
  approximate,
}) => {
  const bigFontSize = Math.max(14, 32 - itemsCount * 2);
  const smallFontSize = Math.max(14, 26 - itemsCount * 2);

  return (
    <Box
      p={getNumeralViewItemsPaddings(itemsCount)}
      width="50%"
      flexGrow="1"
      boxSizing="border-box"
      justifyContent="center"
    >
      <Stack
        spacing={0.5}
        bgcolor="grey.50"
        borderRadius={1}
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          width="100%"
          spacing={0.5}
          px={1}
        >
          {providers?.map((item) => {
            return <ProviderLogo key={item} provider={item} width={16} />;
          })}
          {CustomLabel ? (
            <CustomLabel fontSize={smallFontSize} label={label} />
          ) : (
            <TypographyWithTooltip
              variant="caption"
              color="text.secondary"
              whiteSpace="nowrap"
              fontSize={smallFontSize}
              title={label}
            />
          )}
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Stack direction="row" spacing={0.25} alignItems="center">
            <Typography variant="body2" fontWeight={500} fontSize={bigFontSize}>
              {approximate && "~"}
              {money(cost)}
            </Typography>
            {postfix && (
              <Typography variant="caption" color="text.secondary">
                {postfix}
              </Typography>
            )}
          </Stack>

          <TrendChip value={trend} tooltipTitle={tooltipTitle} />
        </Stack>
        {secondaryLabel && (
          <TypographyWithTooltip
            title={secondaryLabel}
            variant="body2"
            color="text.secondary"
            fontSize={smallFontSize}
          />
        )}
      </Stack>
    </Box>
  );
};
