import { Typography } from "@mui/material";
import { ColumnSetupType } from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { SavingsPlanCoverageDataWithId } from "../../../../../../../utils/types";
import { money } from "../../../../../../../../../../../utils/numeral/money";
import { CommitmentsProgressBar } from "../../../../../../common/CommitmentsProgressBar";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../../../services/cloudchipr.api";
import { commitmentsDrawerDynamicHeaderCellTitles } from "../../../../../../../utils/constants/commitmentsDrawerDynamicHeaderCellTitles";

export const getSavingsPlanCommonColumns = (
  grouping: SavingsPlanCoverageGrouping,
): ColumnSetupType<SavingsPlanCoverageDataWithId>[] => [
  {
    accessorKey: "covered_usage",
    header: () => (
      <Typography
        align="right"
        width="100%"
        variant="body2"
        fontWeight="medium"
      >
        {
          commitmentsDrawerDynamicHeaderCellTitles.savingsPlans[grouping]
            .coveredUsageCell
        }
      </Typography>
    ),
    meta: {
      headerTitle:
        commitmentsDrawerDynamicHeaderCellTitles.savingsPlans[grouping]
          .coveredUsageCell,
    },
    cell: ({ row }) => (
      <Typography align="right" pr={4} variant="body2">
        {money(row.original.covered_usage)}
      </Typography>
    ),
  },

  {
    accessorKey: "coverage",
    header:
      commitmentsDrawerDynamicHeaderCellTitles.savingsPlans[grouping]
        .coverageCell,
    cell: ({ row }) => (
      <CommitmentsProgressBar
        overrideColor="info.light"
        value={row.original.coverage}
      />
    ),
  },
];
