import { ColumnSetupType } from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { SavingsPlanCoverageDataWithId } from "../../../../../../../utils/types";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../../../services/cloudchipr.api";
import { CommitmentsProgressBar } from "../../../../../../common/CommitmentsProgressBar";
import { commitmentsDrawerDynamicHeaderCellTitles } from "../../../../../../../utils/constants/commitmentsDrawerDynamicHeaderCellTitles";

export const getCoverageColumn = (
  grouping: SavingsPlanCoverageGrouping,
): ColumnSetupType<SavingsPlanCoverageDataWithId> => {
  return {
    accessorKey: "coverage",
    header:
      commitmentsDrawerDynamicHeaderCellTitles.coverage[grouping].coverageCell,
    cell: ({ row }) => <CommitmentsProgressBar value={row.original.coverage} />,
  };
};
