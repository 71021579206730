import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

const CommitmentsSvgIcon = ({ color }: SvgIconProps) => {
  const { palette } = useTheme();

  const fillColor =
    color === "primary" ? palette.primary.main : palette.action.active;

  return (
    <SvgIcon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.33333 14.9999H12.5V16.6666H3.33333C2.40833 16.6666 1.66666 15.9249 1.66666 14.9999L1.675 4.99992C1.675 4.07492 2.40833 3.33325 3.33333 3.33325H16.6667C17.5917 3.33325 18.3333 4.07492 18.3333 4.99992V8.33325H16.6667V4.99992H3.33333V14.9999ZM9.16666 14.1666H10.8333V13.3333H11.6667C12.125 13.3333 12.5 12.9583 12.5 12.4999V9.99992C12.5 9.54158 12.125 9.16658 11.6667 9.16658H9.16666V8.33325H12.5V6.66659H10.8333V5.83325H9.16666V6.66659H8.33333C7.875 6.66659 7.5 7.04158 7.5 7.49992V9.99992C7.5 10.4583 7.875 10.8333 8.33333 10.8333H10.8333V11.6666H7.5V13.3333H9.16666V14.1666ZM18.3333 11.6666V12.4999C18.7917 12.4999 19.1667 12.8749 19.1667 13.3333V15.8333C19.1667 16.2916 18.7917 16.6666 18.3333 16.6666H15C14.5417 16.6666 14.1667 16.2916 14.1667 15.8333V13.3333C14.1667 12.8749 14.5417 12.4999 15 12.4999V11.6666C15 10.7499 15.75 9.99992 16.6667 9.99992C17.5833 9.99992 18.3333 10.7499 18.3333 11.6666ZM15.8333 12.4999H17.5V11.6666C17.5 11.2083 17.125 10.8333 16.6667 10.8333C16.2083 10.8333 15.8333 11.2083 15.8333 11.6666V12.4999Z"
          fill={fillColor}
        />
      </svg>
    </SvgIcon>
  );
};

CommitmentsSvgIcon.muiName = "CommitmentsSvgIcon";

export default CommitmentsSvgIcon;
