import { FC, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../store/hooks";
import { currentAccountProviderTypeSelector } from "../../../../../../store/account/selectors/current-account/currentAccountProviderTypeSelector";
import { resourceGroups } from "../../../../../../utils/constants/resources/resourceGroups";
import { ResourceType } from "../../../../../../services/cloudchipr.api";
import { useToggledResourceTypes } from "../../../utils/hooks/useToggledResources";
import { isStringResourceType } from "../../../../../../utils/helpers/resources/isStringResourceType";
import { ResourceFilters } from "../../resource-card/resource-card-content/resource-filters/ResourceFilters";
import { ResourceFiltersButtonViewSourceButton } from "../../resource-card/resource-card-content/resource-filters/sourceButtons/ResourceFiltersButtonViewSourceButton";
import { resourceTypeSearchParams } from "../../navigation/utils/constants";

export const AccountFilters: FC = () => {
  const [searchParams] = useSearchParams();

  const toggledResources = useToggledResourceTypes();

  const provider = useAppSelector(currentAccountProviderTypeSelector);
  const resourceGroup = resourceGroups.get(provider ?? "aws");
  let resourceType = searchParams.get(resourceTypeSearchParams) as ResourceType;

  if (!isStringResourceType(resourceType)) {
    resourceType = resourceGroup?.[0].value as ResourceType;
  }

  const activeResourceGroup = useMemo(() => {
    return resourceGroup?.find(
      ({ value, resourceTypes }) =>
        value === resourceType || resourceTypes.includes(resourceType),
    );
  }, [resourceGroup, resourceType]);

  if (!activeResourceGroup) {
    return null;
  }

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      pl={1}
      py={0.5}
      gap={1}
      alignItems="center"
    >
      {activeResourceGroup.resourceTypes
        .filter((rt) => !toggledResources.get(rt))
        .map((rt) => (
          <ResourceFilters
            key={rt}
            resourceType={rt}
            SourceButton={ResourceFiltersButtonViewSourceButton}
          />
        ))}
    </Stack>
  );
};
