import React, { FC, Fragment } from "react";
import { CleanProcess } from "./CleanProcess";
import { useAppSelector } from "../../../../store/hooks";
import { cleanProcessesSelector } from "../../../../store/clean/selectors/cleanProcessesSelector";

export const CleanFAB: FC = () => {
  const cleanProcesses = useAppSelector(cleanProcessesSelector);

  return (
    <Fragment>
      {Object.entries(cleanProcesses).map(([executionLogId, details]) => {
        return (
          <CleanProcess
            key={executionLogId}
            executionLogId={executionLogId}
            resourceType={details.resourceType}
          />
        );
      })}
    </Fragment>
  );
};
