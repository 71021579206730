import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  ResourceFilters,
  ResourceType,
} from "../../../../services/cloudchipr.api";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";

interface GetLiveFilteredResourcesThunkArgs {
  accountIds: string[];
  resourceType: ResourceType;
  resourceIds: string[];
}
export const getLiveFilteredResourcesThunk = createAsyncThunk(
  "resourceExplorer/getLiveFilteredResources",
  async (
    {
      accountIds,
      resourceIds,
      resourceType,
    }: GetLiveFilteredResourcesThunkArgs,
    { dispatch },
  ) => {
    const { postUsersMeV2OrganisationsCurrentByTypeResources } =
      cloudChiprApi.endpoints;

    if (!accountIds) {
      return;
    }
    const parentResourceType =
      getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;

    const filter: ResourceFilters = {
      filter_groups: [0],
      filter_items: [
        {
          type: resourceType,
          filter: {
            key: "resource_unique_identifier",
            operator: "in",
            value: resourceIds,
          },
        },
      ],
      type: parentResourceType,
      operators: ["AND"],
    };

    return await dispatch(
      postUsersMeV2OrganisationsCurrentByTypeResources.initiate(
        {
          type: parentResourceType,
          body: {
            account_ids: accountIds ?? [],
            filter: filter,
          },
        },
        {
          fixedCacheKey: parentResourceType,
        },
      ),
    ).unwrap();
  },
);
