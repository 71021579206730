import { FC } from "react";
import { Stack } from "@mui/material";
import { EmailIntegrationGracePeriodMessage } from "./email/grace-period/EmailIntegrationGracePeriodMessage";
import { SlackIntegrationGracePeriodMessage } from "./slack/grace-period/SlackIntegrationGracePeriodMessage";
import { EmailIntegrationMessageNotify } from "./email/notify/EmailIntegrationMessageNotify";
import { SlackIntegrationMessageNotify } from "./slack/notify/SlackIntegrationMessageNotify";
import { EmailActionExecuted } from "./email/action-executed/EmailActionExecuted";
import { SlackIntegrationActionExecuted } from "./slack/action-execute/SlackIntegrationActionExecuted";
import { CustomMessageIntegrationActionFromType } from "./utils/types";
import { NestedIntegration } from "../../../../../../services/cloudchipr.api";

export interface IntegrationCustomMessageContentProps {
  type: NestedIntegration["type"];
  message: string;
  actionFrom?: CustomMessageIntegrationActionFromType;
}

export const IntegrationCustomMessageContent: FC<
  IntegrationCustomMessageContentProps
> = ({ type, actionFrom, message }) => {
  if (actionFrom === "grace-period") {
    return (
      <Stack spacing={1.5} pt={2}>
        {type === "email" && (
          <EmailIntegrationGracePeriodMessage message={message} />
        )}
        {type === "slack" && (
          <SlackIntegrationGracePeriodMessage message={message} />
        )}
      </Stack>
    );
  }

  if (actionFrom === "notify") {
    return (
      <Stack spacing={1.5} pt={2}>
        {type === "email" && (
          <EmailIntegrationMessageNotify message={message} />
        )}
        {type === "slack" && (
          <SlackIntegrationMessageNotify message={message} />
        )}
      </Stack>
    );
  }
  if (actionFrom === "clean") {
    return (
      <Stack spacing={1.5} pt={2}>
        {type === "email" && <EmailActionExecuted message={message} />}
        {type === "slack" && (
          <SlackIntegrationActionExecuted message={message} />
        )}
      </Stack>
    );
  }

  return null;
};
