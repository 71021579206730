import { MultiFilters } from "../types/resourceExplorer";
import {
  ResourceExplorerFilterItem,
  ProviderType,
} from "../../../../services/cloudchipr.api";
import { isStringProviderType } from "../../../../utils/helpers/providers/isStringProviderType";

export const getResourceExplorerRestructuredFilters = (
  filters: ResourceExplorerFilterItem[],
  providers?: ProviderType[],
  enabledProviders?: ProviderType[],
): MultiFilters => {
  if (
    enabledProviders?.length === 1 &&
    !filters.find((item) => item.cloud_provider === enabledProviders[0])
  ) {
    return {
      [enabledProviders[0]]: {},
    };
  }

  if (!filters.length) {
    return {};
  }

  const structuredFilters = filters.reduce((result, item) => {
    const provider = item.cloud_provider;

    if (!isStringProviderType(provider)) {
      return result;
    }

    if (!providers?.includes(provider)) {
      return result;
    }

    result[provider] = {
      ...result[provider],
      [item.key]: {
        ...result?.[provider]?.[item.key],
        ...item,
      },
    };

    return result;
  }, {} as MultiFilters);

  if (providers?.length === 1 && !structuredFilters[providers[0]]) {
    const provider = providers[0];
    return {
      [provider]: {},
    };
  }

  return structuredFilters;
};
