import { FC } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { DashboardCostBreakdownWidget } from "./DashboardCostBreakdownWidget";
import { DashboardCostBreakdownV2Widget } from "../cost-breakdown-v2/DashboardCostBreakdownV2Widget";

interface DashboardCostBreakdownWidgetVersionsWrapperProps {
  widgetId: string;
}

export const DashboardCostBreakdownWidgetVersionsWrapper: FC<
  DashboardCostBreakdownWidgetVersionsWrapperProps
> = ({ widgetId }) => {
  const enableCostBreakdownNewEndpoints = useFlag(
    "EnableCostBreakdownNewEndpoints",
  );

  if (enableCostBreakdownNewEndpoints) {
    return <DashboardCostBreakdownV2Widget widgetId={widgetId} />;
  }

  return <DashboardCostBreakdownWidget widgetId={widgetId} />;
};
