import { FC } from "react";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import PhotoSizeSelectSmallOutlinedIcon from "@mui/icons-material/PhotoSizeSelectSmallOutlined";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import { useLocation } from "react-router-dom";
import { NavItem } from "../nav-item/NavItem";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";

export const RecommendationsNavigation: FC = () => {
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  const location = useLocation();
  const selected = location.pathname.startsWith("/recommendations");

  return (
    <NavItem
      primary="Recommendations"
      icon={LightbulbOutlinedIcon}
      navCollapsed={navigationCollapsed}
      selected={selected}
    >
      <NavItem
        nested
        to="/recommendations/off-hours"
        primary="Off Hours"
        icon={HistoryToggleOffOutlinedIcon}
      />
      <NavItem
        nested
        to="/recommendations/rightsizing"
        primary="Rightsizing"
        icon={PhotoSizeSelectSmallOutlinedIcon}
      />
    </NavItem>
  );
};
