import { FC } from "react";
import { ProviderType } from "../../../services/cloudchipr.api";
import AwsLogo from "../../../assets/images/logos/providers/aws.svg";
import GcpLogo from "../../../assets/images/logos/providers/gcp.svg";
import AzureLogo from "../../../assets/images/logos/providers/azure.svg";

const logos = {
  aws: AwsLogo,
  gcp: GcpLogo,
  azure: AzureLogo,
};

interface ProviderLogoProps {
  provider: ProviderType;
  width?: string | number;
}

export const ProviderLogo: FC<ProviderLogoProps> = ({
  provider,
  width = 20,
}) => {
  return <img alt={provider} src={logos[provider]} width={width} />;
};
