import { schedulesDataSelector } from "../schedulesDataSelector";
import { RootState } from "../../../../store";
import { FutureRunDate } from "../../../../../services/cloudchipr.api";
import { workflowNextRunsMaxCount } from "../../../../../components/pages/schedule/classic/components/utils/constants";

export const scheduleNextRunsByScheduleIdSelector = (
  state: RootState,
  scheduleId: string,
): FutureRunDate[] | undefined => {
  const schedules = schedulesDataSelector(state);

  const data = schedules?.find((item) => item.id === scheduleId);
  const filteredData = data?.metadata?.future_run_dates?.filter(
    (item) => item.should_execute,
  );
  return filteredData?.slice(1, workflowNextRunsMaxCount) ?? [];
};
