import { AccountFilterSetV2 } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { getUsersMeAccountsByAccountIdFilterSetsV2Selector } from "../getUsersMeAccountsByAccountIdFilterSetsV2Selector";

export const currentAccountFilterTemplatesByAccountIdSelector = (
  state: RootState,
  accountId: string,
): AccountFilterSetV2[] | undefined => {
  if (!accountId) {
    return undefined;
  }

  return getUsersMeAccountsByAccountIdFilterSetsV2Selector(state, accountId)
    ?.data;
};
