import { useFlag } from "@unleash/proxy-client-react";
import { useMemo } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { WidgetType } from "../../../../../../../services/cloudchipr.api";
import { dashboardByIdSelector } from "../../../../../../../store/dashboards/selectors/dashboard/dashboardByIdSelector";

export const useDashboardWidgetsHook = (dashboardId: string) => {
  const enableCostAndUsageSumWidget = useFlag(
    "EnableCostAndUsageSummaryWidget",
  );

  const enableLargestCostChangesWidget = useFlag(
    "EnableLargestCostChangesWidget",
  );

  const disabledWidgets: Partial<Record<WidgetType, boolean>> = useMemo(() => {
    return {
      cost_and_usage_summary: !enableCostAndUsageSumWidget,
      largest_cost_changes: !enableLargestCostChangesWidget,
    };
  }, [enableCostAndUsageSumWidget, enableLargestCostChangesWidget]);

  const dashboard = useAppSelector((state) =>
    dashboardByIdSelector(state, dashboardId),
  );

  const widgets = dashboard?.widgets;

  return useMemo(() => {
    return widgets?.filter((item) => !disabledWidgets?.[item.type]);
  }, [widgets, disabledWidgets]);
};
