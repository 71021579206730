import { resourceTypeDataResourcesSelector } from "./resourceTypeDataResourcesSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const resourceTypeResourcesCountSelector = (
  state: RootState,
  resourceType: ResourceType,
): number | undefined => {
  const resources = resourceTypeDataResourcesSelector(state, resourceType);

  return resources?.length;
};
