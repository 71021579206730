import { viewAccountsFilterSelector } from "./viewAccountsFilterSelector";
import { viewRegionsFilterSelector } from "./viewRegionsFilterSelector";
import { viewServicesFilterSelector } from "./viewServicesFilterSelector";
import { viewCreditTypeFilterSelector } from "./viewCreditTypeFilterSelector";
import { viewChargeTypesFilterSelector } from "./viewChargeTypesFilterSelector";
import { viewTagsFilterSelector } from "./viewTagsFilterSelector";
import { viewProductFamilyFilterSelector } from "./viewProductFamilyFilterSelector";
import { viewInstanceFamilyFilterSelector } from "./viewInstanceFamilyFilterSelector";
import { viewInstanceTypeFilterSelector } from "./viewInstanceTypeFilterSelector";
import { RootState } from "../../../../store";
import {
  ResourceExplorerAwsFilterRequest,
  ResourceExplorerAzureFilterRequest,
  ResourceExplorerGcpFilterRequest,
} from "../../../../../services/cloudchipr.api";

export const viewFiltersSelector = (
  state: RootState,
): {
  aws_filters?: ResourceExplorerAwsFilterRequest;
  gcp_filters?: ResourceExplorerGcpFilterRequest;
  azure_filters?: ResourceExplorerAzureFilterRequest;
} => {
  const accountIds = viewAccountsFilterSelector(state);
  const regions = viewRegionsFilterSelector(state);
  const services = viewServicesFilterSelector(state);
  const creditTypes = viewCreditTypeFilterSelector(state);
  const chargeTypes = viewChargeTypesFilterSelector(state);
  const tags = viewTagsFilterSelector(state);
  const productFamilies = viewProductFamilyFilterSelector(state);
  const instanceFamilies = viewInstanceFamilyFilterSelector(state);
  const instanceTypes = viewInstanceTypeFilterSelector(state);

  return {
    aws_filters: {
      account_ids: accountIds.aws,
      regions: regions.aws,
      services: services.aws,
      product_families: productFamilies.aws,
      charge_types: chargeTypes?.aws,
      instance_types: instanceTypes.aws,
      instance_families: instanceFamilies.aws,
      tags: tags?.aws,
    },
    gcp_filters: {
      account_ids: accountIds.gcp,
      regions: regions.gcp,
      services: services.gcp,
      product_families: productFamilies.gcp,
      credits: creditTypes,
      tags: tags?.gcp,
    },
    azure_filters: {
      account_ids: accountIds.azure,
      regions: regions.azure,
      services: services.azure,
      product_families: productFamilies.azure,
      charge_types: chargeTypes?.azure,
    },
  };
};
