import { Fragment } from "react";
import { AzureMissingRolesDialog } from "./components/AzureMissingRolesDialog";
import { useAppSelector } from "../../../../../../../store/hooks";
import { useGetUsersMeProvidersAzureAccountsByAccountIdMissingRolesQuery } from "../../../../../../../services/cloudchipr.api";
import { currentAccountIdSelector } from "../../../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { MissingPermissionsAlertButton } from "../common/MissingPermissionsAlertButton";

export const AzureMissings = () => {
  const { open, openDialog, closeDialog } = useDialog();
  const currentAccountId = useAppSelector(currentAccountIdSelector);

  const { data } =
    useGetUsersMeProvidersAzureAccountsByAccountIdMissingRolesQuery(
      { accountId: currentAccountId || "" },
      { skip: !currentAccountId },
    );

  if (!data?.length) {
    return null;
  }

  return (
    <Fragment>
      <MissingPermissionsAlertButton
        onOpen={openDialog}
        title="Missing Permissions"
      />

      <AzureMissingRolesDialog open={open} onClose={closeDialog} />
    </Fragment>
  );
};
