import { FC, Fragment, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Stack } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { ScheduledJobsForm } from "./components/create/ScheduledJobsForm";
import { PageHeader } from "../../common/PageHeader";
import { useGetV2UsersMeSchedulesByScheduleIdQuery } from "../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../services/permissions";

export const ScheduledJobsItem: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { cannot } = useAppAbility();
  const cannotCreate = cannot("create", "schedule");
  const cannotEdit = cannot("edit", "schedule");
  const disabledForUser = cannotCreate || cannotEdit;

  const { data, isLoading, error, isFetching } =
    useGetV2UsersMeSchedulesByScheduleIdQuery(
      { scheduleId: id || "" },
      { skip: !id || disabledForUser },
    );

  const breadcrumbs = useMemo(() => {
    return [
      { title: "Workflows", to: "/schedule/classic" },
      { title: `${id ? "Edit" : "Create new"} Workflow` },
    ];
  }, [id]);

  const redirect =
    (error && "status" in error && +error.status >= 400) || disabledForUser;

  useEffectOnceWhen(() => {
    navigate("/schedule/classic", { replace: true });
  }, redirect);

  return (
    <Fragment>
      <PageHeader breadcrumbs={breadcrumbs} />
      {isLoading || isFetching ? (
        <Stack alignItems="center" pt={4}>
          <CircularProgress />
        </Stack>
      ) : (
        <ScheduledJobsForm schedule={data} />
      )}
    </Fragment>
  );
};
