import { FC } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { WidgetTotalsSummary } from "../../common/WidgetTotalsSummary";
import { costByCategoryWidgetCostsSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-by-category/costByCategoryWidgetCostsSelector";
import { costByCategoryWidgetFrequencySelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-by-category/costByCategoryWidgetFrequencySelector";
import { costByCategoryWidgetForecastOptionSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-by-category/costByCategoryWidgetForecastOptionSelector";

interface CostByCategoryWidgetContentCostsProps {
  widgetId?: string;
}

export const CostByCategoryWidgetContentCosts: FC<
  CostByCategoryWidgetContentCostsProps
> = ({ widgetId }) => {
  const costsData = useAppSelector((state) =>
    costByCategoryWidgetCostsSelector(state, widgetId),
  );

  const frequency = useAppSelector((state) =>
    costByCategoryWidgetFrequencySelector(state, widgetId),
  );

  const forecastOption = useAppSelector((state) =>
    costByCategoryWidgetForecastOptionSelector(state, widgetId),
  );

  if (!costsData) {
    return null;
  }

  return (
    <WidgetTotalsSummary
      frequency={frequency}
      costsData={costsData}
      forecastOption={forecastOption}
    />
  );
};
