import { useCallback, useMemo, useState } from "react";
import { Updater, VisibilityState } from "@tanstack/react-table";
import { ColumnVisibilityConfig } from "../types/prop-types";

export function useColumnsVisibility(
  columnVisibilityConfig?: ColumnVisibilityConfig,
) {
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    typeof columnVisibilityConfig === "boolean"
      ? {}
      : columnVisibilityConfig?.initialVisibility || {},
  );

  const columnsVisibilityChangeHandler = useCallback(
    (updater: Updater<VisibilityState>) => {
      setColumnVisibility((oldState) => {
        let updatedState = updater as VisibilityState;

        if (typeof updater === "function") {
          updatedState = updater(oldState);
        }

        if (typeof columnVisibilityConfig !== "boolean") {
          const onChange = columnVisibilityConfig?.onColumnsVisibilityChange;
          onChange?.(updatedState);
        }

        return updatedState;
      });
    },
    [columnVisibilityConfig],
  );

  return useMemo(() => {
    if (!columnVisibilityConfig) {
      return {
        columnVisibility: undefined,
        columnsVisibilityChangeHandler: undefined,
      };
    }

    return {
      columnVisibility,
      columnsVisibilityChangeHandler,
    };
  }, [
    columnVisibilityConfig,
    columnVisibility,
    columnsVisibilityChangeHandler,
  ]);
}
