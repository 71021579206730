import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCurrentAccountThunk } from "./getCurrentAccountThunk";
import { getCurrentAccountAllResourceTypesDataThunk } from "./filters-get/getCurrentAccountAllResourceTypesDataThunk";
import { RootState } from "../../store";
import { currentAccountIdSelector } from "../selectors/current-account/currentAccountIdSelector";
import { getCurrentAccountFilterTemplatesThunk } from "../../filters/thunks/filter-set/getCurrentAccountFilterTemplatesThunk";

export const accountRefreshDataThunk = createAsyncThunk(
  "accountRefreshDataThunk",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;
    const accountId = currentAccountIdSelector(state);

    if (!accountId) {
      return;
    }

    dispatch(getCurrentAccountAllResourceTypesDataThunk());
    dispatch(getCurrentAccountFilterTemplatesThunk());
    await dispatch(getCurrentAccountThunk(accountId));
  },
);
