import { FC, Fragment, useMemo } from "react";
import { Divider, ListSubheader, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ToolbarCleanActionItem } from "./ToolbarCleanActionItem";
import { getResourcesCleanActionTypes } from "../../../../../../../../../../../utils/clean-options";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import { currentAccountVisibilitiesByResourceTypeSelector } from "../../../../../../../../../../../store/account/selectors/current-account/currentAccountVisibilitiesByResourceTypeSelector";
import { currentAccountAccessTypeSelector } from "../../../../../../../../../../../store/account/selectors/current-account/currentAccountAccessTypeSelector";
import { currentAccountSelectedResourcesByResourceTypeSelector } from "../../../../../../../../../../../store/account/selectors/current-account/resources/selection/currentAccountSelectedResourcesByResourceTypeSelector";

interface ToolbarCleanActionItemsProps {
  onClose(): void;
  resourceType: ResourceType;
}

export const ToolbarCleanActionItems: FC<ToolbarCleanActionItemsProps> = ({
  onClose,
  resourceType,
}) => {
  const accessType = useAppSelector(currentAccountAccessTypeSelector);

  const visibility = useAppSelector((state) =>
    currentAccountVisibilitiesByResourceTypeSelector(state, resourceType),
  );

  const selectedResources = useAppSelector((state) =>
    currentAccountSelectedResourcesByResourceTypeSelector(state, resourceType),
  );

  const isAnySelectedResourceProtected = selectedResources?.some(
    ({ resource }) => resource.is_protected,
  );

  const options = useMemo(() => {
    if (!visibility?.actions) {
      return null;
    }

    return getResourcesCleanActionTypes(resourceType).filter((option) => {
      return visibility?.actions?.[option.type] !== null;
    });
  }, [resourceType, visibility]);

  if (!options?.length) {
    return null;
  }

  return (
    <Fragment>
      <ListSubheader sx={{ lineHeight: 2 }}>
        <Typography fontSize="smaller">
          ACTIONS
          {(accessType === "read" || isAnySelectedResourceProtected) && (
            <Tooltip
              arrow
              title={
                accessType === "read"
                  ? "Please switch to Read/Write access mode to enable actions."
                  : "Actions can not be performed on protected resources."
              }
            >
              <InfoOutlinedIcon
                sx={{ verticalAlign: "middle", color: "text.secondary", ml: 1 }}
                fontSize="small"
              />
            </Tooltip>
          )}
        </Typography>
      </ListSubheader>

      {options?.map((item) => (
        <ToolbarCleanActionItem
          item={item}
          key={item.type}
          onClose={onClose}
          resourceType={resourceType}
        />
      ))}

      <Divider />
    </Fragment>
  );
};
