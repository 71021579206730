import { FC, Fragment, useEffect, useRef, useState } from "react";
import { ColumnResizeMode, Table } from "@tanstack/react-table";
import { Table as TableMUI, TableContainer } from "@mui/material";
import { SxProps } from "@mui/system";
import { useCounter } from "rooks";
import { Pagination } from "./table-footer/Pagination";
import { TableBodyComponent } from "./table-body/TableBodyComponent";
import { TableToolbar } from "./toolbar/TableToolbar";
import {
  TableHeadComponent,
  VirtualColumnsConfig,
} from "./table-header/TableHeadComponent";
import { TableBodyVirtualizedRows } from "./table-body/TableBodyVirtualizedRows";
import {
  Enableness,
  RowExpandingConfig,
  StylesProps,
  PaginationConfig,
  ToolbarConfig,
} from "./utils/types/prop-types";
import { WithGetRowProps } from "./table-body/RowDnDWrapper";

interface DataGridComponentProps
  extends StylesProps,
    Omit<Required<Enableness>, "enableUniqueValuesOnSearch">,
    WithGetRowProps {
  table: Table<any>;
  globalFilter: string;
  enableColumnsSort: boolean;
  enableColumnVisibility: boolean;
  enableGlobalFilter: boolean;
  showTableBody?: boolean;
  columnResizeMode?: ColumnResizeMode;
  rowExpandingConfig?: RowExpandingConfig;
  setGlobalFilter(f: string): void;
  toolbar?: ToolbarConfig;
  pagination?: PaginationConfig;
  includeHeader: boolean;
}

export const DataGridComponent: FC<DataGridComponentProps> = ({
  styles,
  table,
  showTableBody,
  globalFilter,
  enableRowDnD,
  setGlobalFilter,
  columnResizeMode,
  pagination,
  enableColumnsDnD,
  enableColumnsSort,
  enableGlobalFilter,
  enableColumnsFilter,
  rowExpandingConfig,
  enableStickyColumns,
  enableColumnVisibility,
  enableRowsVirtualization,
  toolbar,
  includeHeader,
  getRowProps,
  enableColumnVirtualization,
}) => {
  const tableContainerRef = useRef<HTMLTableElement>(null);
  const [virtualColumnsConfig, setVirtualColumnsConfig] =
    useState<VirtualColumnsConfig>({
      pl: 0,
      pr: 0,
      columns: [],
    });

  const { value: key, increment: rerender } = useCounter(1);
  const tableStyles: SxProps = { bgcolor: "inherit" };

  if (enableStickyColumns) {
    tableStyles.borderCollapse = "separate";
    tableStyles.tableLayout = "fixed";
  }

  if (columnResizeMode) {
    tableStyles.width = table.getTotalSize();
    tableStyles.minWidth = "100%";
    tableStyles.tableLayout = "fixed";
  }

  const rows = table.getRowModel()?.rows;

  const visibleCellsLength = rows
    ?.map((row) => row.getVisibleCells().length)
    ?.join("");

  useEffect(rerender, [
    tableContainerRef.current,
    visibleCellsLength,
    rerender,
  ]);

  if (!rows) {
    return null;
  }

  return (
    <Fragment>
      {typeof toolbar === "boolean"
        ? toolbar && (
            <TableToolbar
              table={table}
              globalFilter={globalFilter}
              styles={styles?.tableToolbar}
              setGlobalFilter={setGlobalFilter}
              globalFilterEnabled={enableGlobalFilter}
              columnShowHideEnabled={enableColumnVisibility}
            />
          )
        : toolbar?.renderToolbar({ table, globalFilter, setGlobalFilter })}

      <TableContainer sx={styles?.tableContainer} ref={tableContainerRef}>
        <TableMUI
          sx={{ ...tableStyles, ...styles?.table }}
          key={key.toString()}
        >
          {includeHeader && (
            <TableHeadComponent
              table={table}
              rerender={rerender}
              styles={styles?.tableHeaderRow}
              rowDnDEnabled={enableRowDnD}
              columnResizeMode={columnResizeMode}
              columnsDnDEnabled={enableColumnsDnD}
              columnsSortEnabled={enableColumnsSort}
              stickyColumnsEnabled={enableStickyColumns}
              columnsFilterEnabled={enableColumnsFilter}
              tableContainerRef={tableContainerRef}
              reAssignVirtualColumns={setVirtualColumnsConfig}
              enableColumnVirtualization={enableColumnVirtualization}
            />
          )}

          {showTableBody &&
            (enableRowsVirtualization ? (
              <TableBodyVirtualizedRows
                rows={[...rows]}
                getRowProps={getRowProps}
                styles={styles?.tableRow}
                tableContainerRef={tableContainerRef}
                rowDnDEnabled={enableRowDnD}
                stickyColumnsEnabled={enableStickyColumns}
                expandingRowConfig={rowExpandingConfig}
                globalFilter={globalFilter}
                virtualColumnsConfig={virtualColumnsConfig}
                enableColumnVirtualization={enableColumnVirtualization}
              />
            ) : (
              <TableBodyComponent
                rows={[...rows]}
                getRowProps={getRowProps}
                styles={styles?.tableRow}
                rowDnDEnabled={enableRowDnD}
                tableContainerRef={tableContainerRef}
                stickyColumnsEnabled={enableStickyColumns}
                expandingRowConfig={rowExpandingConfig}
                globalFilter={globalFilter}
                virtualColumnsConfig={virtualColumnsConfig}
                enableColumnVirtualization={enableColumnVirtualization}
              />
            ))}
        </TableMUI>
      </TableContainer>

      {pagination && <Pagination table={table} pagination={pagination} />}
    </Fragment>
  );
};
