import { Fragment, useCallback } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { GcpBillingExportsDrawer } from "./GcpBillingExportsDrawer";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { ResourceExplorerBillingExportDialog } from "../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-billing-export/components/resource-explorer-billing-export-dialog/ResourceExplorerBillingExportDialog";
import { useGetUsersMeOrganisationsCurrentGcpBillingExportDataQuery } from "../../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../../services/permissions";

export const GcpAddBillingExportButton = () => {
  const { cannot } = useAppAbility();

  const canNotEditResourceExplorer = cannot("edit", "resource-explorer");
  const { data, refetch } =
    useGetUsersMeOrganisationsCurrentGcpBillingExportDataQuery(undefined, {
      skip: canNotEditResourceExplorer,
    });
  const {
    open: drawerOpen,
    openDialog: openDrawer,
    closeDialog: closeDrawer,
  } = useDialog();
  const {
    open: billingExportModalOpen,
    openDialog: openBillingExportModal,
    closeDialog: closeBillingExportModal,
  } = useDialog();

  const addBillingExportButtonClickHandler = useCallback(() => {
    if (data?.length === 0) {
      openBillingExportModal();
    } else {
      openDrawer();
    }
  }, [openDrawer, data, openBillingExportModal]);

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <Button
        color="primary"
        onClick={addBillingExportButtonClickHandler}
        disabled={canNotEditResourceExplorer}
        startIcon={<AddIcon />}
      >
        Add Billing Export
      </Button>
      <GcpBillingExportsDrawer
        onOpenBillingExportModal={openBillingExportModal}
        open={drawerOpen}
        onClose={closeDrawer}
      />
      <ResourceExplorerBillingExportDialog
        open={billingExportModalOpen}
        onClose={closeBillingExportModal}
        onSuccess={refetch}
      />
    </Fragment>
  );
};
