import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { resourceExplorerPathLastItemFiltersSelector } from "../../selectors/current-resource-explorer/path/resourceExplorerPathLastItemFiltersSelector";
import { getLiveFilteredTrackedResourcesForViewThunk } from "../live-filtered-resources/getLiveFilteredTrackedResourcesForViewThunk";
import { addFiltersFromPathToResourceExplorerPayload } from "../../utils/helpers/addFiltersFromPathToResourceExplorerPayload";
import { resourceExplorerPayloadDataSelector } from "../../selectors/current-resource-explorer/resource-explorer-widget/resourceExplorerPayloadDataSelector";
import { ResourceExplorerPathFilters } from "../../utils/types/resourceExplorer";

export const getResourceExplorerLiveFilteredResourcesThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerLiveFilteredResources",
  (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const pathLastItemFilters =
      resourceExplorerPathLastItemFiltersSelector(state);

    const resourceExplorerDataGettingPayload =
      resourceExplorerPayloadDataSelector(state);

    let payload = resourceExplorerDataGettingPayload;
    if (!!pathLastItemFilters && Object.keys(pathLastItemFilters).length > 0) {
      payload = addFiltersFromPathToResourceExplorerPayload(
        pathLastItemFilters ?? ({} as ResourceExplorerPathFilters),
        resourceExplorerDataGettingPayload,
      );
    }

    dispatch(getLiveFilteredTrackedResourcesForViewThunk(payload));
  },
);
