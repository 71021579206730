import { FC, useCallback } from "react";
import { GroupingByTagValueTrigger } from "./GroupingByTagValueTrigger";
import { GroupingByTagValueOption } from "./GroupingByTagValueOption";
import { GroupingByTagValuesHeader } from "./GroupingByTagValuesHeader";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { DropdownSelect } from "../../../../../common/select/dropdown-select/DropdownSelect";
import { arrayIsEqual } from "../../../../../../utils/arrayIsEqual";
import { DropdownHeaderComponent } from "../../../../../common/select/dropdown-select/utils/types/types";
import { FilterTriggerComponentProps } from "../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";

interface GroupingByValueProps {
  options: { value: string; provider: ProviderType }[];
  values?: string[];
  label: string;
  onSubmit(values?: string[]): void;
  selected: boolean;
}

export const GroupingByValue: FC<GroupingByValueProps> = ({
  options,
  values,
  onSubmit,
  label,
  selected,
}) => {
  const dropdownOptions = options?.map(({ value, provider }) => ({
    value,
    label: (
      <GroupingByTagValueOption
        value={value}
        provider={provider as ProviderType}
      />
    ),
  }));

  const dropdownOnCloseHandler = useCallback(
    (newValues: string[]) => {
      if (values === null || (values && !arrayIsEqual(newValues, values))) {
        onSubmit(newValues);
      }
    },
    [values, onSubmit],
  );

  const DropdownHeader: DropdownHeaderComponent = useCallback(
    (props) => {
      return (
        <GroupingByTagValuesHeader
          {...props}
          onSubmit={dropdownOnCloseHandler}
        />
      );
    },
    [dropdownOnCloseHandler],
  );

  const TriggerComponent = useCallback(
    (props: FilterTriggerComponentProps) => {
      return <GroupingByTagValueTrigger {...props} selected={selected} />;
    },
    [selected],
  );

  if (!dropdownOptions) {
    return null;
  }

  return (
    <DropdownSelect
      label={label}
      options={dropdownOptions}
      values={values ?? []}
      TriggerComponent={TriggerComponent}
      DropdownHeader={DropdownHeader}
      showSearch={false}
      showSelectAll={false}
      listWidth={320}
      submitHandlerOnClose={dropdownOnCloseHandler}
      PopoverProps={{
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
    />
  );
};
