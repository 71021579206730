import { FC } from "react";
import { DatesPeriodType } from "../utils/types";
import { TrendChip } from "../../../../../../../../../../../common/chips/TrendChip";
import { useAppSelector } from "../../../../../../../../../../../../store/hooks";
import { getTrendTooltipTitle } from "../../../../../../../../../utils/helpers/trend-tooltip-title/getTrendTooltipTitle";
import { CostAndUsageSumWidgetFilterType } from "../../../../../../../../../../../../store/dashboards/utils/constants/costAndUsageSetupDefaultData";
import { costAndUsageSumSetupPropertyByKeySelector } from "../../../../../../../../../../../../store/dashboards/selectors/setups/cost-and-usage/costAndUsageSumSetupPropertyByKeySelector";

interface CostAndUsageTrendChipProps {
  currentDates?: DatesPeriodType;
  previousDates?: DatesPeriodType;
  trend: number;
  costAndUsageSumWidgetFilterType: CostAndUsageSumWidgetFilterType;
}

export const CostAndUsageTrendChip: FC<CostAndUsageTrendChipProps> = ({
  currentDates,
  previousDates,
  trend,
  costAndUsageSumWidgetFilterType,
}) => {
  const setupDate = useAppSelector(
    costAndUsageSumSetupPropertyByKeySelector("date"),
  );
  const dateLabel = setupDate.label;

  const tooltipTitle = getTrendTooltipTitle(
    currentDates?.from,
    currentDates?.to,
    previousDates?.from,
    previousDates?.to,
    costAndUsageSumWidgetFilterType,
    dateLabel,
  );

  const value = trend ?? 0;

  return <TrendChip value={value} tooltipTitle={tooltipTitle} />;
};
