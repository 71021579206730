import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

interface GetFilterTemplatesByAccountIdThunkArgs {
  accountId: string;
  forceRefetch?: boolean;
}
export const getFilterTemplatesByAccountIdThunk = createAsyncThunk(
  "filter/getFilterTemplatesByAccountId",
  async (
    { accountId, forceRefetch }: GetFilterTemplatesByAccountIdThunkArgs,
    { dispatch },
  ) => {
    const { getUsersMeAccountsByAccountIdFilterSetsV2 } =
      cloudChiprApi.endpoints;

    return await dispatch(
      getUsersMeAccountsByAccountIdFilterSetsV2.initiate(
        { accountId },
        { forceRefetch },
      ),
    );
  },
);
