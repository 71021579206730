import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProviderType } from "../../../../services/cloudchipr.api";
import { resourceExplorerFilterByKeyAndProviderSelector } from "../../selectors/filters/resourceExplorerFilterByKeyAndProviderSelector";
import { updateMultiFilterByProvider } from "../../resourceExplorerSlice";
import { RootState } from "../../../store";

export const filterCostAllocationTagFilterOperatorUpdateThunk =
  createAsyncThunk(
    "resourceExplorer/filterCostAllocationTagFilterOperatorUpdate",
    (
      {
        provider,
        combinationOperator,
      }: { provider: ProviderType; combinationOperator?: "and" | "or" },
      { dispatch, getState },
    ) => {
      const state = getState() as RootState;
      const currentSavedFilter = resourceExplorerFilterByKeyAndProviderSelector(
        state,
        "cost_allocation_tag",
        provider,
      );

      if (currentSavedFilter && combinationOperator) {
        dispatch(
          updateMultiFilterByProvider({
            filter: {
              ...currentSavedFilter,
              combination_operator: combinationOperator,
            },
            provider,
          }),
        );
      }
    },
  );
