import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import {
  ProviderType,
  cloudChiprApi,
} from "../../../../services/cloudchipr.api";

export const getCostAllocationTagEnablingFixedCacheKey = (key: string) =>
  `patchUsersMeOrganisationsCurrentProvidersByProviderTagKeys-${key}-fixed-cache-key`;

interface ChargeTypeFilterChangeThunkArg {
  key: string;
  provider: ProviderType;
}

export const enableCostAllocationTagKeyThunk = createAsyncThunk(
  "resourceExplorer/enableCostAllocationTagKeyThunk",
  async ({ key, provider }: ChargeTypeFilterChangeThunkArg, { dispatch }) => {
    const { patchUsersMeOrganisationsCurrentProvidersByProviderTagKeys } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        patchUsersMeOrganisationsCurrentProvidersByProviderTagKeys.initiate(
          {
            provider,
            body: { key },
          },
          { fixedCacheKey: getCostAllocationTagEnablingFixedCacheKey(key) },
        ),
      ).unwrap();

      const snackbarId: SnackbarKey = enqueueSnackbar(`Tag ${key} enabled`, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "success",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
