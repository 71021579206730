import { FC, useCallback, useMemo } from "react";
import { useWillUnmount } from "rooks";
import { Stack, Typography } from "@mui/material";
import { offHoursDismissedResourcesDataGridColumns } from "./offHoursDismissedResourcesDataGridColumns";
import { RecommendationGroupType } from "../../utils/types/types";
import { DataGrid } from "../../../../../../../storybook/data-grid/DataGrid";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import {
  resetDismiss,
  setSelectedIds,
} from "../../../../../../../store/recommendations/recommendationsSlice";
import { selectedIdsInGridFormatSelector } from "../../../../../../../store/recommendations/selectors/selected-ids/selectedIdsInGridFormatSelector";
import { OffHoursRecommendationsTableToolbar } from "../table/OffHoursRecommendationsTableToolbar";
import { offHoursRecommendationsPageLoadingSelector } from "../../../../../../../store/recommendations/selectors/off-hours/loading/offHoursRecommendationsPageLoadingSelector";
import { offHoursRecommendationsDismissedResourcesFilteredDataByGroupSelector } from "../../../../../../../store/recommendations/selectors/off-hours/dismissed-resources/offHoursRecommendationsDismissedResourcesFilteredDataByGroupSelector";
import { DataGridProvider } from "../../../../../../../storybook/data-grid/DataGridProvider";

interface OffHoursDismissedResourcesDataGridProps {
  group: RecommendationGroupType;
}

export const OffHoursDismissedResourcesDataGrid: FC<
  OffHoursDismissedResourcesDataGridProps
> = ({ group }) => {
  const dispatch = useAppDispatch();

  const selectedIds = useAppSelector(selectedIdsInGridFormatSelector);
  const loading = useAppSelector(offHoursRecommendationsPageLoadingSelector);

  const data = useAppSelector((state) =>
    offHoursRecommendationsDismissedResourcesFilteredDataByGroupSelector(
      state,
      group,
    ),
  );

  const columns = useMemo(() => {
    return offHoursDismissedResourcesDataGridColumns(group);
  }, [group]);

  const onRowSelectionChange = useCallback(
    (ids: Record<string, boolean>): void => {
      dispatch(setSelectedIds(Object.keys(ids)));
    },
    [dispatch],
  );

  useWillUnmount(() => {
    dispatch(resetDismiss());
  });

  return (
    <DataGridProvider>
      <DataGrid
        styles={styles}
        data={data ?? []}
        columns={columns}
        rowSelection={{
          initialSelectedItems: selectedIds,
          rowSelectionChange: onRowSelectionChange,
        }}
        toolbar={{
          renderToolbar: (props) => (
            <OffHoursRecommendationsTableToolbar
              {...props}
              group={group}
              tab="dismissed_resources"
            />
          ),
        }}
        pagination={!loading && !data?.length && footerToolbar}
      />
    </DataGridProvider>
  );
};

const styles = {
  table: {
    tableLayout: "fixed",
    borderCollapse: "separate",
  },
  tableHeaderRow: {
    "& th:nth-of-type(2)": { pl: 0 },
    "& th": { verticalAlign: "inherit" },
  },
};

const footerToolbar = {
  renderPagination: () => {
    return (
      <Stack p={2} alignItems="center" justifyContent="center">
        <Typography
          color="text.secondary"
          variant="subtitle1"
          fontWeight="medium"
        >
          No resources found
        </Typography>
      </Stack>
    );
  },
};
