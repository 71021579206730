import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { Elb } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const elbColumns: ColumnSetupType<Elb>[] = [
  {
    accessorKey: "load_balancer_name",
    header: "Load Balancer",
    type: "identifier",
    size: 290,
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "creation_data",
    header: "Created By",
    type: "createdBy",
    headerTooltip:
      "The Created By value is only available for resources created within the last 90 days.",
  },
  {
    accessorKey: "created_at",
    header: "Creation Time",
    type: "date",
  },
  {
    accessorKey: "unused_since",
    header: "No Traffic Since",
    type: "unusedSince",
  },
  {
    accessorKey: "dns_name",
    header: "DNS Name",
    type: "copyWithTooltip",
  },
  {
    accessorKey: "has_attachments",
    header: "Targets Empty",
    type: "hasField",
    headerTooltip:
      "Targets Empty: YES - indicates that the load balancer has no targets in a running state.",
  },
  { accessorKey: "type", header: "Type" },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Tags",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "Ticket Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
