import { PostUsersMeOrganisationsCurrentResourceExplorerApiArg } from "../../../../services/cloudchipr.api";
import { ResourceExplorerPathFilters } from "../types/resourceExplorer";

export const addFiltersFromPathToResourceExplorerPayload = (
  filtersFromPath: ResourceExplorerPathFilters,
  resourceExplorerPayload: PostUsersMeOrganisationsCurrentResourceExplorerApiArg["resourceExplorerFilterSchema"],
): PostUsersMeOrganisationsCurrentResourceExplorerApiArg["resourceExplorerFilterSchema"] => {
  const { aws, gcp, azure, ...restFiltersFromPath } = filtersFromPath;

  return {
    ...resourceExplorerPayload,
    ...restFiltersFromPath,
    aws_filters: {
      ...resourceExplorerPayload.aws_filters,
      ...aws,
    },
    gcp_filters: {
      ...resourceExplorerPayload.gcp_filters,
      ...gcp,
    },
    azure_filters: {
      ...resourceExplorerPayload.azure_filters,
      ...azure,
    },
  };
};
