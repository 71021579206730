import { costBreakdownWidgetByIdSelector } from "./costBreakdownWidgetByIdSelector";
import { RootState } from "../../../../store";
import { costBreakdownWidgetSetupSelector } from "../../setups/cost-breakdown/costBreakdownWidgetSetupSelector";

export const costBreakdownWidgetEditChangesExistSelector = (
  state: RootState,
) => {
  const setup = costBreakdownWidgetSetupSelector(state);

  if (!setup?.id) {
    return false;
  }

  const widget = costBreakdownWidgetByIdSelector(state, setup?.id);

  if (!widget) {
    return false;
  }

  return (
    setup.name !== widget.name ||
    setup.grouping !== widget.grouping ||
    setup.viewId !== widget.view_id ||
    setup.date.from !== widget.date_from ||
    setup.date.to !== widget.date_to ||
    setup.date.label !== widget.date_label ||
    setup.forecastOption !== widget.forecast_option ||
    setup.frequency !== widget.date_granularity ||
    setup.viewType !== widget.chart_type
  );
};
