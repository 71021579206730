import { createAsyncThunk } from "@reduxjs/toolkit";
import { DateDataPointNullable } from "../../../../../../services/cloudchipr.api";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { getCostBreakdownV2WidgetSetupDataThunk } from "../getCostBreakdownV2WidgetSetupDataThunk";
import { RootState } from "../../../../../store";
import { costBreakdownV2WidgetSetupSelector } from "../../../../selectors/setups/cost-breakdown-v2/costBreakdownV2WidgetSetupSelector";

export const costBreakdownV2FrequencyChangeThunk = createAsyncThunk(
  "dashboards/costBreakdownFrequencyChange",
  async (frequency: DateDataPointNullable, { dispatch, getState }) => {
    const state = getState() as RootState;
    const existingSetup = costBreakdownV2WidgetSetupSelector(state);

    if (!existingSetup || !frequency) {
      return;
    }
    dispatch(
      setWidgetSetup({
        ...existingSetup,
        frequency,
      }),
    );

    dispatch(getCostBreakdownV2WidgetSetupDataThunk());
  },
);
