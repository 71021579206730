import { FC, useMemo } from "react";
import { Grid } from "@mui/material";
import {
  ResourceFiltersWithAction,
  ScheduleActionType,
} from "../../../../../../../services/cloudchipr.api";
import { SchedulePreviewTable } from "../../../../common/create/preview/SchedulePreviewTable";
import {
  getActionValue,
  getCleanActionOptions,
} from "../../../../../../../utils/clean-options";
import { getResourceTypeName } from "../../../../../../../utils/helpers/resources/getResourceTypeName";

interface ScheduledJobsActionsPreviewProps {
  filter: ResourceFiltersWithAction[];
  action: ScheduleActionType;
}

export const ScheduledJobsActionsPreview: FC<
  ScheduledJobsActionsPreviewProps
> = ({ filter, action }) => {
  const isCleanEnabled = action?.includes("clean");

  const data = useMemo(() => {
    if (!isCleanEnabled) {
      return [];
    }

    return filter.map(({ action, snapshot, filter }, index) => {
      const actionText = getCleanActionOptions(filter.type).find(
        ({ value }) => value === getActionValue(action, snapshot),
      )?.label;

      return {
        id: `${index}`,
        title: getResourceTypeName(filter.type, { type: "long" }),
        label: actionText ?? "",
      };
    });
  }, [isCleanEnabled, filter]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <SchedulePreviewTable data={data} />
      </Grid>
    </Grid>
  );
};
