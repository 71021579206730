import { selectedViewSelector } from "./selectedViewSelector";
import {
  ProviderType,
  ResourceExplorerTag,
  ResourceExplorerTags,
} from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { isStringProviderType } from "../../../../../utils/helpers/providers/isStringProviderType";

export const viewTagsFilterSelector = (state: RootState) => {
  const view = selectedViewSelector(state);

  const tagFilters = view?.filters?.filter(
    (filter) => filter.key === "cost_allocation_tag",
  );

  return tagFilters?.reduce(
    (acc, filter) => {
      if (!isStringProviderType(filter.cloud_provider)) {
        return acc;
      }

      acc[filter.cloud_provider] = {
        combination_operator: "and",
        items: filter.value as ResourceExplorerTag[],
      };

      return acc;
    },
    {} as Record<ProviderType, ResourceExplorerTags>,
  );
};
