import { FC } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";

interface CollapseHeaderProps {
  collapsed: boolean;
  title: string;
  onClick(e: any): void;
}

export const CommitmentsDrawerCollapseHeader: FC<CollapseHeaderProps> = ({
  onClick,
  collapsed,
  title,
}) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <IconButton size="small" onClick={onClick}>
        {collapsed ? (
          <KeyboardArrowDownOutlinedIcon />
        ) : (
          <KeyboardArrowUpOutlinedIcon />
        )}
      </IconButton>

      <AnalyticsOutlinedIcon />

      <Typography variant="h5" fontWeight="medium">
        {title}
      </Typography>
    </Stack>
  );
};
