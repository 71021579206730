import { FC, useCallback, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { TooltipProps } from "recharts";
import moment from "moment";
import { red } from "@mui/material/colors";
import { ChartsTooltipContentStyles } from "../../../../../../../../storybook/charts/multi-type-chart/components/common/ChartsTooltipContent";
import { money } from "../../../../../../../../utils/numeral/money";
import { formatDate } from "../../../../../../../../utils/helpers/date-time/datetime-format";
import { labelByDateLabel } from "../../../../../../../common/date-range-picker/utils/constants/preDefinedRanges";
import {
  DateLabelNullable,
  NullableDate,
} from "../../../../../../../../services/cloudchipr.api";
import { Cube } from "../../../../../../../../storybook/charts/multi-type-chart/components/common/Cube";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { RootState } from "../../../../../../../../store/store";
import { costByCategoryFilteredResourcesTotalData } from "../../../../../../../../store/dashboards/selectors/widgets/cost-by-category/chart/costByCategoryFilteredResourcesTotalData";
import {
  costByCategoryChartPrefixes,
  multiStackChartPrefixSeparator,
} from "../../../../../../../../storybook/charts/multi-type-chart/components/chart-by-type/multi-stack-chart/utils/constants/constants";

interface CostByCategoryChartTooltipContentProps
  extends TooltipProps<any, any> {
  widgetId: string;
  displayFilteredResources: boolean;
  date: {
    label: DateLabelNullable;
    from: NullableDate;
    to: NullableDate;
  };
}

export const CostByCategoryChartTooltipContent: FC<
  CostByCategoryChartTooltipContentProps
> = ({ payload, date, widgetId, displayFilteredResources }) => {
  const selector = useCallback(
    (state: RootState) => {
      if (!displayFilteredResources) {
        return null;
      }

      return costByCategoryFilteredResourcesTotalData(state, widgetId);
    },
    [widgetId, displayFilteredResources],
  );

  const filteredResourcesTotalData = useAppSelector(selector);

  const dateLabel = useMemo(() => {
    if (date.label) {
      return labelByDateLabel.get(date.label)?.long;
    }

    const dateTo = formatDate(moment(date.to), {
      type: "date",
    });
    const dateFrom = formatDate(moment(date.from), {
      type: "date",
    });

    return `${dateFrom} - ${dateTo}`;
  }, [date]);

  const hoveredItem = payload?.at(0);

  if (!hoveredItem) {
    return null;
  }

  const filteredResourceKey = `${costByCategoryChartPrefixes.secondary}${multiStackChartPrefixSeparator}${hoveredItem.name}`;
  const filteredResourceValue =
    filteredResourcesTotalData?.[filteredResourceKey];

  return (
    <Stack sx={ChartsTooltipContentStyles} borderRadius={1}>
      <Typography variant="caption">{dateLabel}</Typography>
      <Typography variant="caption" fontWeight="medium" color="text.primary">
        {hoveredItem.name}
      </Typography>

      <Stack direction="row" alignItems="center">
        <Cube
          radius={0.5}
          width={12}
          height={12}
          color={hoveredItem.payload.fill}
        />
        <Typography variant="caption" color="text.primary">
          Total Cost:{" "}
          <Typography variant="inherit" fontWeight="bold" component="span">
            {money(hoveredItem.payload.label)}
          </Typography>
        </Typography>
      </Stack>

      {displayFilteredResources && (
        <Stack direction="row" alignItems="center">
          <Cube radius={0.5} width={12} height={12} color={red[500]} />
          <Typography variant="caption" color="text.primary">
            Filtered Resources:{" "}
            <Typography variant="inherit" fontWeight="bold" component="span">
              {money(filteredResourceValue)}
            </Typography>
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
