import { FC } from "react";
import { ForecastedCostHeaderCell } from "../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/ForecastedCostHeaderCell";
import { ForecastOption } from "../../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { costBreakdownWidgetViewForecastedCostSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownWidgetViewForecastedCostSelector";

interface CostBreakdownWidgetForecastedCostHeaderCellProps {
  forecastOption: ForecastOption;
}
export const CostBreakdownWidgetForecastedCostHeaderCell: FC<
  CostBreakdownWidgetForecastedCostHeaderCellProps
> = ({ forecastOption }) => {
  const forecastedCost = useAppSelector((state) =>
    costBreakdownWidgetViewForecastedCostSelector(state, forecastOption),
  );
  return (
    <ForecastedCostHeaderCell
      forecastOption={forecastOption}
      forecastedCost={forecastedCost}
    />
  );
};
