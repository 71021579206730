import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { utilizationAndCoverageDetailsDataSelector } from "./data/utilizationAndCoverageDetailsDataSelector";
import { SavingsPlanDateGranularDataPoint } from "../../../../../services/cloudchipr.api";

export const utilizationAndCoverageDetailsGranularDataGroupedByDateSelector =
  createDraftSafeSelector(
    [utilizationAndCoverageDetailsDataSelector],
    (data): Record<string, SavingsPlanDateGranularDataPoint> | undefined => {
      return data?.data?.reduce(
        (acc, data) => {
          acc[data.date] = data;

          return acc;
        },
        {} as Record<string, SavingsPlanDateGranularDataPoint>,
      );
    },
  );
