import { getUsersMeAccountsByAccountIdFilterSetsV2Selector } from "./getUsersMeAccountsByAccountIdFilterSetsV2Selector";
import { AccountFilterSetV2 } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { currentAccountIdSelector } from "../../../account/selectors/current-account/currentAccountIdSelector";

export const currentAccountFilterTemplatesSelector = (
  state: RootState,
): AccountFilterSetV2[] | undefined => {
  const accountId = currentAccountIdSelector(state);

  if (!accountId) {
    return undefined;
  }

  return getUsersMeAccountsByAccountIdFilterSetsV2Selector(state, accountId)
    ?.data;
};
