import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { money } from "../../../../../../../../utils/numeral/money";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { filterTemplatesEnabledSelector } from "../../../../../../../../store/account/selectors/current-account/resources/filterTemplatesEnabledSelector";

interface AccountNavItemStatsProps {
  ems: number;
  total: number;
}

export const AccountNavItemStats: FC<AccountNavItemStatsProps> = ({
  ems,
  total,
}) => {
  const filterTemplatesEnabled = useAppSelector(filterTemplatesEnabledSelector);
  return (
    <Box whiteSpace="nowrap">
      {filterTemplatesEnabled && (
        <Typography color="error" variant="caption" component="span">
          {money(ems, 1000)}
        </Typography>
      )}
      <Typography color="text.secondary" variant="caption" component="span">
        {filterTemplatesEnabled && " / "}
        {money(total, 1000)}
      </Typography>
    </Box>
  );
};
