import { FC } from "react";
import { Stack } from "@mui/material";
import { DrawerChartHeader } from "../common/data-grid/CommitmentsDrawerChartHeader";
import { useAppSelector } from "../../../../../../../store/hooks";
import { utilizationAndCoverageDetailsLoadingSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/data/utilizationAndCoverageDetailsLoadingSelector";
import { CommitmentsDrawerContentInfoCard } from "../common/CommitmentsDrawerContentInfoCard";
import { CommitmentsProgressBar } from "../../common/CommitmentsProgressBar";
import { money } from "../../../../../../../utils/numeral/money";
import { coverageDetailsMetaDataSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/resource-type/coverageDetailsMetaDataSelector";

export const CoverageDrawerChartHeader: FC = () => {
  const metadata = useAppSelector(coverageDetailsMetaDataSelector);
  const isLoading = useAppSelector(
    utilizationAndCoverageDetailsLoadingSelector,
  );

  if (!metadata) {
    return null;
  }

  return (
    <DrawerChartHeader>
      <Stack direction="row" flexWrap="wrap" gap={1} mt={3}>
        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="Coverage"
        >
          <CommitmentsProgressBar width="100%" value={metadata.coverage} />
        </CommitmentsDrawerContentInfoCard>

        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="Spend Covered by Commitment"
          value={money(metadata.covered_usage)}
        />

        <CommitmentsDrawerContentInfoCard
          cardSx={cardSx}
          loading={isLoading}
          title="On-Demand Spend"
          value={money(metadata.uncovered_usage)}
        />
      </Stack>
    </DrawerChartHeader>
  );
};

const cardSx = { minWidth: 256, flex: 1 };
