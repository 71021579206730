import { Stack } from "@mui/material";
import { FC } from "react";
import { ResourcesCount } from "./ResourcesCount";
import { ResourceType } from "../../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { isFilterAppliedToResourceSelector } from "../../../../../../../../../../store/filters/selectors/isFilterAppliedToResourceSelector";
import { resourceTypeDataLoadingSelector } from "../../../../../../../../../../store/account/selectors/resource-type-data/resourceTypeDataLoadingSelector";
import { resourceTypeDataTotalCountSelector } from "../../../../../../../../../../store/account/selectors/resource-type-data/resourceTypeDataTotalCountSelector";
import { resourceTypeResourcesCountSelector } from "../../../../../../../../../../store/account/selectors/resource-type-data/resourceTypeResourcesCountSelector";
import { resourceTypeChildResourcesCountSelector } from "../../../../../../../../../../store/account/selectors/resource-type-data/resourceTypeChildResourcesCountSelector";
import { ResourceFiltersTextView } from "../../../../resource-filters/ResourceFiltersTextView";
import { resourceViewIsWithoutFiltersSelector } from "../../../../../../../../../../store/account/selectors/current-account/resources/resourceViewIsWithoutFiltersSelector";
import { filterTemplatesEnabledSelector } from "../../../../../../../../../../store/account/selectors/current-account/resources/filterTemplatesEnabledSelector";

interface ResourceCardFilterContentProps {
  resourceType: ResourceType;
}
export const ResourceCardFilterContent: FC<ResourceCardFilterContentProps> = ({
  resourceType,
}) => {
  const filtersApplied = useAppSelector((state) =>
    isFilterAppliedToResourceSelector(state, resourceType),
  );

  const viewIsWithoutFilters = useAppSelector((state) =>
    resourceViewIsWithoutFiltersSelector(state, resourceType),
  );

  const isLoading = useAppSelector((state) =>
    resourceTypeDataLoadingSelector(state, resourceType),
  );

  const resourcesTotalCount = useAppSelector((state) =>
    resourceTypeDataTotalCountSelector(state, resourceType),
  );
  const resourcesCount = useAppSelector((state) =>
    resourceTypeResourcesCountSelector(state, resourceType),
  );
  const resourceChildrenCount = useAppSelector((state) =>
    resourceTypeChildResourcesCountSelector(state, resourceType),
  );

  const filterTemplatesEnabled = useAppSelector(filterTemplatesEnabledSelector);

  const count =
    resourceType === "rds_snapshot_source"
      ? resourceChildrenCount
      : resourcesCount;

  return (
    <Stack direction="row" spacing={0.5}>
      {!viewIsWithoutFilters && filterTemplatesEnabled && (
        <ResourceFiltersTextView resourceType={resourceType} />
      )}
      <ResourcesCount
        resourceType={resourceType}
        dataCount={count}
        isLoading={isLoading}
        totalDataCount={resourcesTotalCount}
        filtersApplied={filtersApplied}
      />
    </Stack>
  );
};
