import { RootState } from "../../../../store";
import { resourceExplorerFilterByKeyAndProviderSelector } from "../resourceExplorerFilterByKeyAndProviderSelector";
import {
  ResourceExplorerFilterItem,
  ProviderType,
} from "../../../../../services/cloudchipr.api";

export const dbSelectedInstanceFamilySelector = (
  state: RootState,
  provider: ProviderType,
): ResourceExplorerFilterItem => {
  const filter = resourceExplorerFilterByKeyAndProviderSelector(
    state,
    "instance_family",
    provider,
  );

  return {
    ...filter,
    cloud_provider: provider,
    operator: filter?.operator ?? "in",
    value: filter?.value ?? [],
    key: "instance_family",
  };
};
