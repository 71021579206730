import { getUsersMeAccountsByAccountIdFilterSetsV2Selector } from "./getUsersMeAccountsByAccountIdFilterSetsV2Selector";
import { getDefaultFilterTemplates } from "./utils/helpers/getDefaultFilterTemplates";
import { AccountFilterSetV2 } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

interface FilterTemplatesByAccountIdSelectorArgs {
  accountId: string;
}
export const filterTemplatesByAccountIdSelector = (
  state: RootState,
  { accountId }: FilterTemplatesByAccountIdSelectorArgs,
): AccountFilterSetV2[] => {
  if (!accountId) {
    return [];
  }
  const data = getUsersMeAccountsByAccountIdFilterSetsV2Selector(
    state,
    accountId,
  )?.data;

  if (!data) {
    return [];
  }

  const defaults = getDefaultFilterTemplates(data) ?? [];

  const rest = data
    .filter(({ filter_set }) => {
      return filter_set.source !== "cloudchipr";
    })
    .sort((first, second) => {
      if (
        first.filter_set.name.toLowerCase() >
        second.filter_set.name.toLowerCase()
      ) {
        return 1;
      }

      return -1;
    });

  return [...defaults, ...rest];
};
