import { widgetREViewDataSelector } from "./widgetREViewDataSelector";
import { RootState } from "../../../../../store";

export const widgetREPrevDateDetailsSelector = (
  state: RootState,
  widgetId?: string,
) => {
  const response = widgetREViewDataSelector(state, widgetId);

  if (!response?.previous_period_cost_date_details) {
    return;
  }
  const date = response?.previous_period_cost_date_details;
  return {
    to: date?.to_date,
    from: date?.from_date,
  };
};
