import { createAsyncThunk } from "@reduxjs/toolkit";
import { getFilterTemplatesByAccountIdThunk } from "./getFilterTemplatesByAccountIdThunk";
import { RootState } from "../../../store";

export const getCurrentAccountFilterTemplatesThunk = createAsyncThunk(
  "filter/getAccountFilterTemplatesThunk",
  async (_: void, { dispatch, getState }) => {
    const { account } = getState() as RootState;

    if (!account?.id) {
      return;
    }

    return await dispatch(
      getFilterTemplatesByAccountIdThunk({
        accountId: account?.id,
        forceRefetch: true,
      }),
    );
  },
);
