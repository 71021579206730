import { FC } from "react";
import { Stack } from "@mui/material";
import { ResourceExplorerFilterItem } from "./components/ResourceExplorerFilterItem";

import { useAppSelector } from "../../../../../../../store/hooks";
import { resourceExplorerFilterKeysAsProvidersSelector } from "../../../../../../../store/resource-explorer/selectors/filters/filter-providers/resourceExplorerFilterKeysAsProvidersSelector";
import { resourceExplorerProvidersSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerProvidersSelector";

interface ResourceExplorerFilterItemsProps {
  viewId: string;
}
export const ResourceExplorerFilterItems: FC<
  ResourceExplorerFilterItemsProps
> = ({ viewId }) => {
  const filters = useAppSelector(resourceExplorerFilterKeysAsProvidersSelector);
  const resourceExplorerProviders = useAppSelector(
    resourceExplorerProvidersSelector,
  );

  if (!filters.length) {
    return null;
  }

  const providersWithFilters = resourceExplorerProviders?.filter((item) =>
    filters.includes(item),
  );

  return (
    <Stack spacing={1}>
      {providersWithFilters.map((item) => {
        return (
          <ResourceExplorerFilterItem
            provider={item}
            viewId={viewId}
            key={item}
          />
        );
      })}
    </Stack>
  );
};
