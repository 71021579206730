import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { CommitmentsDateRangeSelector } from "../../common/CommitmentsDateRangeSelector";
import { utilizationAndCoverageDrawerDatesSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/options/utilizationAndCoverageDrawerDatesSelector";
import { getUtilizationAndCoverageDetailsThunk } from "../../../../../../../store/commitments/thunks/getUtilizationAndCoverageDetailsThunk";
import { setCoverageAndUtilizationDrawerDates } from "../../../../../../../store/commitments/commitmentsSlice";
import { Dates } from "../../../../../../../services/cloudchipr.api";

export const CommitmentsDrawerDateRange: FC = () => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(utilizationAndCoverageDrawerDatesSelector);

  const dateChangeHandler = useCallback(
    (dates: Dates) => {
      dispatch(setCoverageAndUtilizationDrawerDates(dates));
      dispatch(getUtilizationAndCoverageDetailsThunk());
    },
    [dispatch],
  );

  return (
    <CommitmentsDateRangeSelector dates={dates} onChange={dateChangeHandler} />
  );
};
