import { FC } from "react";
import { CostBreakdownV2WidgetChartView } from "./chart/CostBreakdownV2WidgetChartView";
import { CostBreakdownV2WidgetTableView } from "./chart/table/CostBreakdownV2WidgetTableView";
import { CostBreakdownV2WidgetNumeralView } from "./chart/numeral/CostBreakdownV2WidgetNumeralView";
import { useAppSelector } from "../../../../../../../store/hooks";
import { DashboardEmptyWidgetState } from "../../common/DashboardEmptyWidgetState";
import { ChartType } from "../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { DashboardNotFoundWidget } from "../../common/DashboardNotFoundWidget";
import { costBreakdownV2WidgetNotFoundSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetNotFoundSelector";
import { costBreakdownV2WidgetByIdSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetByIdSelector";

interface CostBreakdownWidgetContentProps {
  widgetId: string;
  viewName?: string;
}

export const CostBreakdownV2WidgetContent: FC<
  CostBreakdownWidgetContentProps
> = ({ widgetId, viewName }) => {
  const widgetDataNotFound = useAppSelector((state) =>
    costBreakdownV2WidgetNotFoundSelector(state, widgetId),
  );

  const widget = useAppSelector((state) =>
    costBreakdownV2WidgetByIdSelector(state, widgetId),
  );

  if (widgetDataNotFound) {
    return (
      <DashboardNotFoundWidget
        name={viewName ?? ""}
        label="resource explorer view"
      />
    );
  }

  if (!widget) {
    return <DashboardEmptyWidgetState infoCards={infoCards} />;
  }

  if (widget.chart_type === "table") {
    return <CostBreakdownV2WidgetTableView widgetId={widgetId} />;
  }

  if (widget.chart_type === "numeral") {
    return <CostBreakdownV2WidgetNumeralView widgetId={widgetId} />;
  }

  return (
    <CostBreakdownV2WidgetChartView
      widgetId={widgetId}
      dateDataPoint={widget.date_granularity}
      dateLabel={widget.dates?.label}
      chartType={widget.chart_type as ChartType}
    />
  );
};

const infoCards = [
  {
    value: "-",
    title: "Total Cost",
  },
  {
    value: "-",
    title: "Filtered Resources",
  },
];
