import { RootState } from "../../../../store";
import { resourceExplorerDimensionSelector } from "../dimension/resourceExplorerDimensionSelector";
import { resourceExplorerViewDataByIdSelector } from "../../resource-explorer-by-id/resourceExplorerViewDataByIdSelector";
import { resourceExplorerIdSelector } from "../../current-resource-explorer/resourceExplorerIdSelector";
import { isDeepEqual } from "../../../../../utils/is-deep-equal";

export const dimensionFiltersChangedSelector = (state: RootState) => {
  const viewId = resourceExplorerIdSelector(state);
  const resourceExplorerDimension = resourceExplorerDimensionSelector(state);
  const resourceExplorerViewFilters = resourceExplorerViewDataByIdSelector(
    state,
    viewId,
  )?.filters;

  const dimensionFilter = resourceExplorerViewFilters?.find(
    (filter) => filter.key === "dimension_id",
  );
  const dimensionId = dimensionFilter?.value?.at(0) as string;

  const categoryFilter = resourceExplorerViewFilters?.find(
    (filter) => filter.key === "category_id",
  );

  const categoryIds = (categoryFilter?.value ?? []) as string[];

  if (resourceExplorerDimension?.dimensionId !== dimensionId) {
    return true;
  }

  return !isDeepEqual(
    categoryIds,
    resourceExplorerDimension?.categoryIds ?? [],
  );
};
