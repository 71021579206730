import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCostBreakdownWidgetDataBySetupThunk } from "./fetchCostBreakdownWidgetDataBySetupThunk";
import { RootState } from "../../../../store";
import { setWidgetSetup } from "../../../dashboardsSlice";
import { costBreakdownWidgetSetupSelector } from "../../../selectors/setups/cost-breakdown/costBreakdownWidgetSetupSelector";
import { CostBreakdownWidgetSetupType } from "../../../utils/types/setups/costBreakdownSetupType";

export const costBreakdownSetupChangeThunk = createAsyncThunk(
  "dashboards/costBreakdownSetupChange",
  async (
    setup: Partial<CostBreakdownWidgetSetupType>,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const existingSetup = costBreakdownWidgetSetupSelector(state);

    if (!existingSetup) {
      return;
    }

    const newSetup = { ...existingSetup };

    let needToFetch = false;

    if (setup.id) {
      newSetup.id = setup.id;
    }

    if (setup.name !== undefined) {
      newSetup.name = setup.name;
    }

    if (setup.viewId) {
      newSetup.viewId = setup.viewId;
    }

    if (setup.grouping) {
      needToFetch = true;
      newSetup.grouping = setup.grouping;
      newSetup.group_values = setup.group_values ?? null;
    }

    if (setup.date) {
      needToFetch = true;
      newSetup.date = setup.date;
    }

    if (setup.frequency) {
      needToFetch = true;
      newSetup.frequency = setup.frequency;
    }
    if (setup.forecastOption) {
      needToFetch = true;
      newSetup.forecastOption = setup.forecastOption;
    }

    if (setup.viewType) {
      needToFetch = false;
      newSetup.viewType = setup.viewType;
    }

    dispatch(setWidgetSetup(newSetup));

    if (needToFetch) {
      dispatch(fetchCostBreakdownWidgetDataBySetupThunk());
    }
  },
);
