import { FC } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { ProviderLogo } from "../../../../../../../common/provider-logo/ProviderLogo";
import { getProviderName } from "../../../../../../../../utils/helpers/providers/getProviderName";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { rightsizingRecommendationsStatusesGroupedSelector } from "../../../../../../../../store/recommendations/selectors/rightsizing/statuses/rightsizingRecommendationsStatusesGroupedSelector";
import { ProviderType } from "../../../../../../../../services/cloudchipr.api";

interface ProviderFilterOptionProps {
  provider: ProviderType;
}

export const ProviderFilterOption: FC<ProviderFilterOptionProps> = ({
  provider,
}) => {
  const statuses = useAppSelector(
    rightsizingRecommendationsStatusesGroupedSelector,
  );

  const disabled = !!statuses[provider].disabled.length;
  const missingPermission = !!statuses[provider].missing_permission.length;

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" spacing={1}>
        <ProviderLogo provider={provider} width={24} />
        <Typography variant="body2">
          {getProviderName(provider, { title: true })}
        </Typography>
      </Stack>

      {(disabled || missingPermission) && (
        <Tooltip
          arrow
          placement="top"
          title="Recommendations are not enabled on some of the accounts."
        >
          <WarningAmberRoundedIcon color="warning" />
        </Tooltip>
      )}
    </Stack>
  );
};
