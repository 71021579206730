import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { currentAccountProviderTypeSelector } from "../../../account/selectors/current-account/currentAccountProviderTypeSelector";
import { currentAccountNameIdSelector } from "../../../account/selectors/current-account/currentAccountNameIdSelector";
import { currentAccountSelectedResourcesByResourceTypeSelector } from "../../../account/selectors/current-account/resources/selection/currentAccountSelectedResourcesByResourceTypeSelector";
import { getProviderName } from "../../../../utils/helpers/providers/getProviderName";
import { money } from "../../../../utils/numeral/money";
import { currentUserEmailSelector } from "../../../profile/profileSelectors";

export const slackMessageSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const userEmail = currentUserEmailSelector(state);
  const accountType = currentAccountProviderTypeSelector(state);
  const accountName = currentAccountNameIdSelector(state);
  const selectedResources =
    currentAccountSelectedResourcesByResourceTypeSelector(state, resourceType);

  if (!selectedResources || !accountType) {
    return "";
  }

  const provider = getProviderName(accountType, {
    nameAndTitle: true,
    capitalize: true,
  });

  const price = selectedResources.reduce(
    (acc, resource) => resource.resource.price_per_month + acc,
    0,
  );
  const accountURL = `<a href="${window.location.href}" target="_blank">${accountName}</a>`;
  const monthlyPrice = `Monthly Price - <b>${money(price)}</b>`;
  const user = userEmail
    ? `Sent from Cloudchipr by user - <a href="mailto:${userEmail}" target="_blank">${userEmail}</a> `
    : "";
  return `Please review attached resources and take necessary actions if required.<br/> <br/>  ${provider}</> - ${accountURL} <br/> ${monthlyPrice} <br/> ${user} `;
};
