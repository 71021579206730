import { FC, ReactNode } from "react";
import { TooltipProps } from "recharts";
import { Stack, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { ChartDataType } from "../../../../utils/types/types";
import { ChartsTooltipContentStyles } from "../../../common/ChartsTooltipContent";
import { money } from "../../../../../../../utils/numeral/money";
import {
  costByCategoryChartPrefixes,
  multiStackChartPrefixSeparator,
} from "../utils/constants/constants";
import { Cube } from "../../../common/Cube";

type ChartsTooltipContentProps = {
  barKey?: string;
  tooltipLabelFormatter?(label: string): ReactNode;
} & TooltipProps<any, any>;

export const MultiStackChartTooltipContent: FC<ChartsTooltipContentProps> = ({
  label,
  payload,
  barKey,
  tooltipLabelFormatter,
}) => {
  const data = payload?.[0]?.payload as ChartDataType;

  if (!data || !barKey) {
    return null;
  }

  const [, dataLabel] = barKey.split(multiStackChartPrefixSeparator);

  const primaryPrefix = costByCategoryChartPrefixes.primary;
  const secondaryPrefix = costByCategoryChartPrefixes.secondary;

  const primaryDataKey = barKey.includes(primaryPrefix)
    ? barKey
    : barKey.replace(secondaryPrefix, primaryPrefix);
  const secondaryDataKey = barKey.includes(secondaryPrefix)
    ? barKey
    : barKey.replace(primaryPrefix, secondaryPrefix);

  const primaryValue = data[primaryDataKey];
  const secondaryValue = data[secondaryDataKey];

  const color = payload?.find(
    ({ dataKey }) => dataKey === primaryDataKey,
  )?.color;

  return (
    <Stack sx={ChartsTooltipContentStyles} spacing={0.5} pl={0.5}>
      <Typography variant="caption" color="text.secondary">
        {tooltipLabelFormatter ? tooltipLabelFormatter(label) : label}
      </Typography>

      <Typography
        variant="caption"
        textTransform="capitalize"
        color="text.primary"
      >
        <Cube color={color} width={12} height={12} />
        {dataLabel}
      </Typography>

      <Typography
        variant="caption"
        textTransform="capitalize"
        fontWeight="medium"
        color="text.primary"
      >
        {primaryPrefix}: {money(primaryValue)}
      </Typography>

      <Typography variant="body2" color="error" textTransform="capitalize">
        <Cube color={red[500]} width={12} height={12} />
        {secondaryPrefix}: {money(secondaryValue)}
      </Typography>
    </Stack>
  );
};
