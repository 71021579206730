import { RootState } from "../../../../store";
import { getUsersMeAccountsByAccountIdFilterSetsV2Selector } from "../getUsersMeAccountsByAccountIdFilterSetsV2Selector";

export const defaultFilterTemplateIdSelector = (
  state: RootState,
  accountId: string,
) => {
  const data = getUsersMeAccountsByAccountIdFilterSetsV2Selector(
    state,
    accountId,
  )?.data;

  const defaultFilterSet = data?.find(
    ({ filter_set }) => filter_set.is_default,
  )?.filter_set;

  return defaultFilterSet?.id;
};
