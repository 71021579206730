import { FC, Fragment, useCallback } from "react";
import { Stack, Typography } from "@mui/material";
import { CategoryWithCosts } from "../../../../../../../services/cloudchipr.api";
import { DeleteConfirmationDialog } from "../../../../../../common/modals/DeleteConfirmationDialog";
import { deleteDimensionThunk } from "../../../../../../../store/dimensions/thunks/dimensions/deleteDimensionThunk";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { dimensionDeleteLoadingSelector } from "../../../../../../../store/dimensions/selectors/options/dimensions/dimensionDeleteLoadingSelector";
import { dimensionsLoadingSelector } from "../../../../../../../store/dimensions/selectors/dimensions-data/dimensionsLoadingSelector";

interface DeleteDimensionConfirmationDialogProps {
  open: boolean;
  dimensionId: string;
  categories: CategoryWithCosts[];
  name: string;
  onClose(): void;
}

export const DeleteDimensionConfirmationDialog: FC<
  DeleteDimensionConfirmationDialogProps
> = ({ dimensionId, categories, name, onClose, open }) => {
  const dispatch = useAppDispatch();
  const deleteLoading = useAppSelector(dimensionDeleteLoadingSelector);
  const getLoading = useAppSelector(dimensionsLoadingSelector);
  const loading = deleteLoading || getLoading;

  const deleteHandler = useCallback(async () => {
    await dispatch(deleteDimensionThunk(dimensionId));
    onClose();
  }, [dispatch, dimensionId, onClose]);

  return (
    <DeleteConfirmationDialog
      open={open}
      onClose={onClose}
      onDelete={deleteHandler}
      loading={loading}
      title="Delete Dimension?"
      content={
        <Fragment>
          <Typography color="text.secondary" variant="subtitle1">
            Are you sure you want to delete{" "}
            <Typography component="span" fontWeight="bold">
              {` ${name} `}
            </Typography>{" "}
            dimension? The following categories will be deleted:
          </Typography>

          <Stack>
            {categories.map((category) => {
              return (
                <Typography
                  key={category.name}
                  component="span"
                  fontWeight="bold"
                >
                  {category.name}
                </Typography>
              );
            })}
          </Stack>
        </Fragment>
      }
    />
  );
};
