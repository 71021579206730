import { cleanResourcesSelector } from "./cleanResourcesSelector";
import { RootState } from "../../store";

export const cleanResourcesTotalEMSSelector = (state: RootState) => {
  const resources = cleanResourcesSelector(state);

  return (
    resources?.reduce((acc, cur) => {
      return acc + cur.price_per_month;
    }, 0) ?? 0
  );
};
