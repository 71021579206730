import { FC, useCallback } from "react";
import { FiltersSourceButton } from "../../../../../common/filters/filters-selection/FiltersSourceButton";
import { liveUsageExcludedFilters } from "../resource-card-data/utils/constants";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { currentAccountIdSelector } from "../../../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import { appliedFiltersByResourceTypeSelector } from "../../../../../../../store/filters/selectors/appliedFiltersByResourceTypeSelector";
import { currentAccountApplyingFilterTemplateFiltersByResourceTypeSelector } from "../../../../../../../store/filters/selectors/filter-set/currentAccountApplyingFilterTemplateFiltersByResourceTypeSelector";
import { AccountResourceFilter2 } from "../../../../../../../services/cloudchipr.api";
import {
  setAppliedFilters,
  setFilterChangedManually,
} from "../../../../../../../store/filters/filtersSlice";
import { setViewWithoutFiltersResourceTypes } from "../../../../../../../store/account/accountSlice";
import { getCurrentAccountResourceTypeWithFiltersDataThunk } from "../../../../../../../store/account/thunks/filters-get/getCurrentAccountResourceTypeWithFiltersDataThunk";
import { WithResourceType } from "../../../../utils/types";
import { FilterSourceButton } from "../../../../../common/filters/filters-selection/utils/types/types";

interface ResourceFiltersProps extends WithResourceType {
  SourceButton?: FilterSourceButton;
}

export const ResourceFilters: FC<ResourceFiltersProps> = ({
  resourceType,
  SourceButton,
}) => {
  const dispatch = useAppDispatch();

  const accountId = useAppSelector(currentAccountIdSelector);
  const appliedFilters = useAppSelector((state) =>
    appliedFiltersByResourceTypeSelector(state, resourceType),
  );
  const accountApplyingFilters = useAppSelector((state) =>
    currentAccountApplyingFilterTemplateFiltersByResourceTypeSelector(
      state,
      resourceType,
    ),
  );

  const submitHandler = useCallback(
    (values: AccountResourceFilter2) => {
      if (!accountId) {
        return;
      }

      dispatch(
        setAppliedFilters({
          accountId,
          filters: values,
        }),
      );
      dispatch(setFilterChangedManually());
      dispatch(
        setViewWithoutFiltersResourceTypes({
          resourceType,
          value: false,
        }),
      );
      dispatch(getCurrentAccountResourceTypeWithFiltersDataThunk(resourceType));
    },
    [dispatch, accountId, resourceType],
  );

  if (!accountId || !appliedFilters || !accountApplyingFilters) {
    return null;
  }

  return (
    <FiltersSourceButton
      defaultFilters={accountApplyingFilters}
      excludedFilters={liveUsageExcludedFilters}
      accountIds={[accountId]}
      onSubmit={submitHandler}
      resourceType={resourceType}
      filters={appliedFilters}
      SourceButton={SourceButton}
    />
  );
};
