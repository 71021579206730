import { formatCostBreakdownWidgetTableViewLastGridItem } from "./utils/helpers";
import { costBreakdownWidgetViewDataSelector } from "./costBreakdownWidgetViewDataSelector";
import { RootState } from "../../../../../../store";
import { ResourceExplorerGridDataWithId } from "../../../../../../resource-explorer/utils/types/resourceExplorerDataGrid";

export const costBreakdownWidgetTableViewDataSelector = (
  state: RootState,
  widgetId?: string,
): ResourceExplorerGridDataWithId[] => {
  const data = costBreakdownWidgetViewDataSelector(state, widgetId);

  if (!data) {
    return [];
  }

  const lastItems = data.slice(itemsCount, data?.length);
  const startItems = data.slice(0, itemsCount);

  const lastItem = formatCostBreakdownWidgetTableViewLastGridItem(lastItems);

  const completeData = [...startItems];

  if (lastItems.length) {
    completeData.push(lastItem);
  }

  return completeData;
};

const itemsCount = 9;
