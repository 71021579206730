import { FC, useCallback, MouseEvent } from "react";
import { Chip, Tooltip, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

interface CountChipWithTooltipProps {
  values: string[];
}

export const CountChipWithTooltip: FC<CountChipWithTooltipProps> = ({
  values,
}) => {
  const stopPropagation = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  }, []);

  if (!values.length) {
    return null;
  }

  return (
    <Tooltip
      arrow
      title={
        <Stack
          p={1}
          maxHeight={300}
          sx={{ overflowY: "auto" }}
          onClick={stopPropagation}
        >
          {values.map((value) => (
            <Typography key={value} variant="caption">
              {value}
            </Typography>
          ))}
        </Stack>
      }
      componentsProps={{
        tooltip: { sx: customTooltipStyles },
      }}
    >
      <Chip
        label={values.length}
        size="small"
        sx={{ bgcolor: "primary.light", color: "primary.main" }}
      />
    </Tooltip>
  );
};

const customTooltipStyles = {
  color: "text.primary",
  bgcolor: "background.paper",
  "& .MuiTooltip-arrow": {
    color: "background.paper",
  },
  boxShadow: 7,
  p: 0,
};
