import {
  cloudChiprApi,
  ProviderType,
} from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { resourceExplorerAccountFilterIdsSelector } from "../checkers/resourceExplorerAccountFilterIdsSelector";

const selector =
  cloudChiprApi.endpoints
    .getUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderTagValues
    .select;

export const costAllocationTagValuesSelector = (
  state: RootState,
  provider: ProviderType,
  tagKey: string,
) => {
  const accountIds = resourceExplorerAccountFilterIdsSelector(state, provider);

  return selector({
    tagKey,
    provider,
    accountIds,
  })(state);
};
