import { DefinedRange } from "mui-daterange-picker";
import { DateLabelNullable } from "../../../../../services/cloudchipr.api";
import {
  getPreDefinedRanges,
  preDefinedThisMonth,
} from "../constants/preDefinedRanges";

export const generateDateFromDateLabelV1 = (
  label: DateLabelNullable,
  startQuarter?: number,
): DefinedRange => {
  if (!label) {
    return preDefinedThisMonth;
  }

  return (
    getPreDefinedRanges(startQuarter).daily[label] ??
    getPreDefinedRanges(startQuarter).monthly[label] ??
    getPreDefinedRanges(startQuarter).quarterly[label] ??
    getPreDefinedRanges(startQuarter).yearly[label] ??
    preDefinedThisMonth
  );
};
