import { enabledProvidersSelector } from "../../../common/selectors/billing-analytics/enabled-providers/enabledProvidersSelector";
import { RootState } from "../../../store";
import { ProviderType } from "../../../../services/cloudchipr.api";

export const recommendationsAvailableProvidersSelector = (
  state: RootState,
): ProviderType[] => {
  const providersSet = enabledProvidersSelector(state);

  return Array.from(providersSet);
};
