import { getCurrentProvidersByProviderBillingStatusSelector } from "../getCurrentProvidersByProviderBillingStatusSelector";
import { RootState } from "../../../../store";
import { enabledProvidersSelector } from "../../billing-analytics/enabled-providers/enabledProvidersSelector";
import { isBillingInProgressStatus } from "../../../utils/helpers/billing-status/isBillingInProgressStatus";
import { BillingAccountStatusWithProvider } from "../../../utils/types/types";

export const billingInProgressStatusesSelector = (
  state: RootState,
): BillingAccountStatusWithProvider[] => {
  const enabledProviders = enabledProvidersSelector(state);
  const enabledProvidersArr = Array.from(enabledProviders);

  return enabledProvidersArr.reduce((result, provider) => {
    const data = getCurrentProvidersByProviderBillingStatusSelector(
      state,
      provider,
    ).data;
    const billingStatuses = data?.billing_account_statuses ?? [];

    if (!billingStatuses.length && isBillingInProgressStatus(data?.status)) {
      result.push({
        status: data?.status,
        provider,
      });
    }
    billingStatuses?.forEach((item) => {
      if (isBillingInProgressStatus(item.status)) {
        result.push({
          ...item,
          provider,
        });
      }
    });

    return result;
  }, [] as BillingAccountStatusWithProvider[]);
};
