import { costBreakdownV2SetupPropertyByKeySelector } from "./costBreakdownV2SetupPropertyByKeySelector";
import { costBreakdownV2WidgetEditChangesExistSelectorV2 } from "../../widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetEditChangesExistSelectorV2";
import { RootState } from "../../../../store";

export const costBreakdownV2SubmitButtonDisabledSelector = (
  state: RootState,
): boolean => {
  const editDisabledByChange =
    costBreakdownV2WidgetEditChangesExistSelectorV2(state);
  const viewId = costBreakdownV2SetupPropertyByKeySelector("viewId")(state);
  const name = costBreakdownV2SetupPropertyByKeySelector("name")(state);
  const id = costBreakdownV2SetupPropertyByKeySelector("id")(state);

  return (id && !editDisabledByChange) || !name || !viewId;
};
