import { FC, useCallback } from "react";
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DeleteActionItemDialog } from "./DeleteActionItemDialog";
import { RenameActionItemDialog } from "./RenameActionItemDialog";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";

interface ResourceExplorerListItemActionsMenuProps {
  id: string;
  name: string;
  open: boolean;
  closeMenu(): void;
  anchor: HTMLElement | null;
}

export const ResourceExplorerListItemActionsMenu: FC<
  ResourceExplorerListItemActionsMenuProps
> = ({ name, id, open, anchor, closeMenu }) => {
  const {
    open: removeViewDialogOpen,
    openDialog: openRemoveViewDialog,
    closeDialog: closeRemoveViewDialog,
  } = useDialog();

  const {
    open: renameViewDialogOpen,
    openDialog: openRenameViewDialog,
    closeDialog: closeRenameViewDialog,
  } = useDialog();

  const eventStopHandler = useCallback((event: any) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const removeDialogCloseHandler = useCallback(() => {
    closeMenu();
    closeRemoveViewDialog();
    closeRenameViewDialog();
  }, [closeMenu, closeRemoveViewDialog, closeRenameViewDialog]);

  return (
    <Box onClick={eventStopHandler}>
      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={anchor}
        disablePortal={false}
      >
        <MenuItem onClick={openRenameViewDialog}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>

        <MenuItem onClick={openRemoveViewDialog}>
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: "error" }}>
            Delete
          </ListItemText>
        </MenuItem>
      </Menu>

      {removeViewDialogOpen && (
        <DeleteActionItemDialog
          open
          id={id}
          name={name}
          closeMenu={removeDialogCloseHandler}
        />
      )}

      {renameViewDialogOpen && (
        <RenameActionItemDialog
          open
          id={id}
          name={name}
          closeMenu={removeDialogCloseHandler}
        />
      )}
    </Box>
  );
};
