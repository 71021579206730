import { RootState } from "../../../../../store";
import { filterChangedByKeySelector } from "../filterChangedByKeySelector";
import { resourceExplorerFilterKeysAsProvidersSelector } from "../../filter-providers/resourceExplorerFilterKeysAsProvidersSelector";
import { resourceExplorerIdSelector } from "../../../current-resource-explorer/resourceExplorerIdSelector";

export const chargeTypeFilterChangedSelector = (state: RootState) => {
  const viewId = resourceExplorerIdSelector(state);
  const providers = resourceExplorerFilterKeysAsProvidersSelector(state);

  return providers.reduce((result, provider) => {
    const isChanged = filterChangedByKeySelector(state, {
      viewId,
      key: "charge_type",
      provider,
    });
    if (isChanged) {
      result = true;
    }

    return result;
  }, false);
};
