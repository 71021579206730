import { utilizationAndCoverageDrawerTargetTypeSelector } from "../options/utilizationAndCoverageDrawerTargetTypeSelector";
import { selectedCoverageDataSelector } from "../resource-type/selectedCoverageDataSelector";
import { GetUsersMeOrganisationsCurrentSavingsPlansRecommendationsApiArg } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";
import { commitmentsCurrentOrgIdSelector } from "../../../commitmentsCurrentOrgIdSelector";

export const coverageRecommendationsPayloadSelector = (
  state: RootState,
): GetUsersMeOrganisationsCurrentSavingsPlansRecommendationsApiArg => {
  const orgId = commitmentsCurrentOrgIdSelector(state);
  const type = utilizationAndCoverageDrawerTargetTypeSelector(state);

  if (!type) {
    return {};
  }

  const payloadData: GetUsersMeOrganisationsCurrentSavingsPlansRecommendationsApiArg =
    {
      providerOrganisationId: orgId,
    };

  if (type === "resource_type") {
    const coverageData = selectedCoverageDataSelector(state);

    payloadData.resourceType = coverageData?.resource_type?.resource_type;
    payloadData.region = coverageData?.resource_type?.region ?? undefined;
    payloadData.instanceFamily =
      coverageData?.resource_type?.instance_family ?? undefined;
  }

  if (type === "account") {
    const coverageData = selectedCoverageDataSelector(state);

    payloadData.accountId = coverageData?.account?.id;
  }

  return payloadData;
};
