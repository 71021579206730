import { FC } from "react";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions } from "@mui/material";

interface DashboardCreateDialogActionsProps {
  disabled: boolean;
  isLoading: boolean;
  onSubmit: () => void;
  onCloseDialog: () => void;
}

export const DashboardCreateDialogActions: FC<
  DashboardCreateDialogActionsProps
> = ({ isLoading, disabled, onSubmit, onCloseDialog }) => {
  return (
    <DialogActions sx={{ py: 2, px: 3 }}>
      <Button onClick={onCloseDialog} color="tertiary">
        Cancel
      </Button>
      <LoadingButton
        variant="contained"
        loading={isLoading}
        onClick={onSubmit}
        disabled={disabled}
      >
        Add
      </LoadingButton>
    </DialogActions>
  );
};
