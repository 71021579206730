import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { costBreakdownV2WidgetSetupCostsSelector } from "./costBreakdownV2WidgetSetupCostsSelector";

export const costBreakdownV2WidgetSetupTotalCostSelector =
  createDraftSafeSelector(
    [costBreakdownV2WidgetSetupCostsSelector],
    (costs) => {
      return costs.total;
    },
  );
