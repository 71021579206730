import { createAsyncThunk } from "@reduxjs/toolkit";
import { setResourceExplorerPathThunk } from "./setResourceExplorerPathThunk";
import { getResourceExplorerDataThunk } from "../getResourceExplorerDataThunk";
import { setResourceExplorerGrouping } from "../../../resourceExplorerSlice";
import { ResourceExplorerGrouping } from "../../../../../services/cloudchipr.api";
import { resourceExplorerPathSelector } from "../../../selectors/current-resource-explorer/path/resourceExplorerPathSelector";
import { RootState } from "../../../../store";

export const pathLastItemGroupingChangeThunk = createAsyncThunk(
  "resourceExplorer/pathLastItemGroupingChangeThunk",
  (grouping: ResourceExplorerGrouping, { dispatch, getState }) => {
    if (!grouping) {
      return;
    }

    const state = getState() as RootState;
    const path = resourceExplorerPathSelector(state);

    const newPath = path.map((pathItem, index) => {
      if (index !== path.length - 1) {
        return pathItem;
      }

      return { ...pathItem, groupedBy: grouping };
    });

    const lastItem = newPath.at(-1);
    if (!lastItem) {
      return;
    }

    dispatch(setResourceExplorerGrouping({ grouping }));
    dispatch(
      getResourceExplorerDataThunk({
        filtersFromPath: lastItem.includedFilters,
      }),
    );
    dispatch(setResourceExplorerPathThunk(newPath));
  },
);
