import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { AzIp } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const azIpColumns: ColumnSetupType<AzIp>[] = [
  {
    accessorKey: "ip",
    header: "IP",
    type: "identifier",
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "creation_data",
    header: "Created By",
    type: "createdBy",
    headerTooltip:
      "The Created By value is only available for resources created within the last 90 days.",
  },
  { accessorKey: "is_in_use", header: "Status", type: "attached" },
  {
    accessorKey: "resource_group",
    header: "Resource Group",
    type: "copyWithTooltip",
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Tags",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "Ticket Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
