import { FC } from "react";
import { TextField } from "@mui/material";
import { GroupingByTagValuesTriggerEndAdornment } from "./GroupingByTagValuesTriggerEndAdornment";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { resourceExplorerGroupingSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { resourceExplorerProvidersSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerProvidersSelector";
import { UseMenuHookOpenMenu } from "../../../../../../../../../../utils/hooks/useMenu.hook";
import { getResourcesExplorerGroupingLabel } from "../../../../../../resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";

interface GroupingByTagValuesTriggerProps {
  open: boolean;
  onClick: UseMenuHookOpenMenu;
}

export const GroupingByTagValuesTrigger: FC<
  GroupingByTagValuesTriggerProps
> = ({ onClick, open }) => {
  const groupBy = useAppSelector(resourceExplorerGroupingSelector);
  const providers = useAppSelector(resourceExplorerProvidersSelector);

  return (
    <TextField
      size="xsmall"
      label="Group by"
      onClick={onClick}
      contentEditable={false}
      value={getResourcesExplorerGroupingLabel(providers, groupBy, true)}
      InputProps={{
        inputProps: {
          style: {
            cursor: "pointer",
            caretColor: "transparent",
          },
        },
        sx: {
          cursor: "pointer",
        },
        endAdornment: <GroupingByTagValuesTriggerEndAdornment open={open} />,
      }}
    />
  );
};
