import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { NatGateway } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const natGatewayColumns: ColumnSetupType<NatGateway>[] = [
  {
    accessorKey: "name",
    header: "Name",
    type: "identifierWithRecommendationLink",
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "nat_gateway_id",
    header: "NAT Gateway ID",
    size: 200,
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "processed_data_value",
    header: "Processed Data",
    type: "processedData",
  },
  {
    accessorKey: "active_connections_value",
    header: "Connections",
    type: "statistic",
    headerTooltip: "The total number of connections for the last 7 days.",
  },
  {
    accessorKey: "created_at",
    header: "Created Date",
    type: "date",
  },
  {
    accessorKey: "creation_data",
    header: "Created By",
    type: "createdBy",
  },
  {
    accessorKey: "public_addresses",
    header: "Public IPv4 Address",
    type: "natGatewayIdCell",
    size: 200,
  },
  {
    accessorKey: "vpc",
    header: "VPC",
    size: 200,
    type: "withTooltipCell",
  },
  {
    accessorKey: "network_interface_ids",
    header: "Network Interface ID",
    type: "natGatewayIdCell",
    size: 200,
  },
  {
    accessorKey: "private_addresses",
    header: "Private IPv4 Address",
    type: "natGatewayIdCell",
    size: 200,
  },
  {
    accessorKey: "connectivity_type",
    header: "Connectivity Type",
  },
  { accessorKey: "tags", header: "Tags", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Tags",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "Ticket Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
