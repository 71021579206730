import { RootState } from "../../../../../../store";

import { commitmentsDataForGranularSelector } from "../../commitmentsDataForGranularSelector";

export const savingsPlansGranularDataTotalUtilizationSelector = (
  state: RootState,
) => {
  const data = commitmentsDataForGranularSelector(state);

  return data?.date_granular_data?.total_utilization;
};
