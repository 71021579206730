import { FC, useCallback } from "react";
import { Drawer, Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { CostByCategorySetup } from "./widget-setups/cost-by-category/CostByCategorySetup";
import { CostBreakdownSetup } from "./widget-setups/cost-breakdown/CostBreakdownSetup";
import { CostAndUsageSumSetup } from "./widget-setups/cost-and-usage/CostAndUsageSumSetup";
import { LargestCostChangesSetup } from "./widget-setups/larges-cost-changes/LargestCostChangesSetup";
import { CostBreakdownV2Setup } from "./widget-setups/cost-breakdown-v2/CostBreakdownV2Setup";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import {
  setWidgetSelectorState,
  setWidgetSetup,
} from "../../../../../../store/dashboards/dashboardsSlice";
import { widgetSetupWidgetTypeSelector } from "../../../../../../store/dashboards/selectors/setups/common/widgetSetupWidgetTypeSelector";

export const WidgetCreateDrawer: FC = () => {
  const widgetType = useAppSelector(widgetSetupWidgetTypeSelector);

  const dispatch = useAppDispatch();
  const enableCostBreakdownNewEndpoints = useFlag(
    "EnableCostBreakdownNewEndpoints",
  );

  const closeHandler = useCallback(() => {
    dispatch(setWidgetSetup(null));
  }, [dispatch]);

  const widgetCreateHandler = useCallback(() => {
    dispatch(setWidgetSelectorState(false));
  }, [dispatch]);

  return (
    <Drawer anchor="right" open={!!widgetType} onClose={closeHandler}>
      <Stack width="95dvw" flexGrow={1} overflow="hidden">
        {widgetType === "costs_by_category_v2" && (
          <CostByCategorySetup
            onClose={closeHandler}
            onSubmit={widgetCreateHandler}
          />
        )}
        {widgetType === "cost_breakdown" &&
          (enableCostBreakdownNewEndpoints ? (
            <CostBreakdownV2Setup
              onClose={closeHandler}
              onSubmit={widgetCreateHandler}
            />
          ) : (
            <CostBreakdownSetup
              onClose={closeHandler}
              onSubmit={widgetCreateHandler}
            />
          ))}
        {widgetType === "cost_and_usage_summary" && (
          <CostAndUsageSumSetup
            onClose={closeHandler}
            onSubmit={widgetCreateHandler}
          />
        )}
        {widgetType === "largest_cost_changes" && (
          <LargestCostChangesSetup
            onClose={closeHandler}
            onSubmit={widgetCreateHandler}
          />
        )}
      </Stack>
    </Drawer>
  );
};
