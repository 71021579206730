import { FC } from "react";
import { Box, Collapse } from "@mui/material";
import { useToggle } from "rooks";
import { CoverageDrawerChartHeader } from "./CoverageDrawerChartHeader";
import { CommitmentsDrawerDataGrid } from "../common/data-grid/CommitmentsDrawerDataGrid";
import { CommitmentsDrawerCollapseHeader } from "../common/CommitmentsDrawerCollapseHeader";
import { CommitmentsDrawerChart } from "../common/CommitmentsDrawerChart";

export const CoverageDrawerCollapsableSection: FC = () => {
  const [collapsed, toggleCollapsed] = useToggle();

  return (
    <Box bgcolor="grey.50" py={2} px={1}>
      <CommitmentsDrawerCollapseHeader
        title="Coverage"
        collapsed={collapsed}
        onClick={toggleCollapsed}
      />

      <Collapse in={!collapsed}>
        <Box py={2} px={1}>
          <CommitmentsDrawerChart ChartHeader={CoverageDrawerChartHeader} />

          <CommitmentsDrawerDataGrid viewTab="coverage" />
        </Box>
      </Collapse>
    </Box>
  );
};
