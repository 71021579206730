import { FC, useCallback } from "react";
import {
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import {
  ForecastedCostRe,
  ForecastOption,
} from "../../../../../../../../../services/cloudchipr.api";
import { forecastedCostOptionLabels } from "../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/utils/constants";
import { money } from "../../../../../../../../../utils/numeral/money";

interface WidgetForecastSelectProps {
  forecast: ForecastOption;
  onChange(value: SelectChangeEvent<ForecastOption | null>): void;
  options?: ForecastedCostRe;
}
export const WidgetForecastSelect: FC<WidgetForecastSelectProps> = ({
  forecast,
  onChange,
  options,
}) => {
  const renderValue = useCallback((value: ForecastOption) => {
    if (!value) {
      return;
    }
    return `Forecasted ${forecastedCostOptionLabels.get(value)} Cost`;
  }, []);

  return (
    <Select
      size="small"
      fullWidth
      value={forecast}
      onChange={onChange}
      renderValue={renderValue}
      disabled={!options?.length}
    >
      {options?.map((item) => {
        return (
          <MenuItem value={item.forecast_option} key={item.forecast_option}>
            <Tooltip
              title={`Forecasted Cost from ${item.date_from} to ${item.date_to}`}
              arrow
              placement="right"
            >
              <ListItemText
                primary={`Forecasted ${forecastedCostOptionLabels.get(
                  item.forecast_option,
                )} Cost`}
                secondary={`~${money(item?.cost)}`}
              />
            </Tooltip>
          </MenuItem>
        );
      })}
    </Select>
  );
};
