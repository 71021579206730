import { FC, useCallback, useMemo } from "react";
import { MenuItem } from "@mui/material";
import {
  CleanActionTypeDetails,
  CleanActionTypes,
} from "../../../../../../../../../../../utils/clean-options";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../../../../../services/permissions";
import { currentAccountCleanDisabledSelector } from "../../../../../../../../../../../store/account/selectors/current-account/currentAccountCleanDisabledSelector";
import { currentAccountSelectedResourcesByResourceTypeSelector } from "../../../../../../../../../../../store/account/selectors/current-account/resources/selection/currentAccountSelectedResourcesByResourceTypeSelector";
import { createCleanThunk } from "../../../../../../../../../../../store/clean/thunks/createCleanThunk";
import { setCleanActionType } from "../../../../../../../../../../../store/clean/cleanSlice";
import { currentAccountVisibilitiesByResourceTypeSelector } from "../../../../../../../../../../../store/account/selectors/current-account/currentAccountVisibilitiesByResourceTypeSelector";

interface ToolbarCleanActionItemProps {
  item: CleanActionTypeDetails;
  resourceType: ResourceType;
  onClose(): void;
}

export const ToolbarCleanActionItem: FC<ToolbarCleanActionItemProps> = ({
  item,
  resourceType,
  onClose,
}) => {
  const resourceVisibility = useAppSelector((state) =>
    currentAccountVisibilitiesByResourceTypeSelector(state, resourceType),
  );

  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotCleanResource = cannot("clean", "resource");

  const cleanDisabled = useAppSelector(currentAccountCleanDisabledSelector);
  const selectedResources = useAppSelector((state) =>
    currentAccountSelectedResourcesByResourceTypeSelector(state, resourceType),
  );

  const selectedResourcesLength = selectedResources?.length;

  const onCleanItemClick = useCallback(
    (type: CleanActionTypes) => () => {
      dispatch(setCleanActionType(type));
      dispatch(createCleanThunk(resourceType));
      onClose();
    },
    [dispatch, resourceType, onClose],
  );

  const isAllResourcesDisabledForSomeReason = useMemo(() => {
    return selectedResources?.every(
      ({ resource }) =>
        resource.is_protected ||
        resource.marked_for_deletion ||
        resource.not_allowed_actions.includes(item.type),
    );
  }, [selectedResources, item.type]);

  const itemDisabled =
    !selectedResourcesLength ||
    canNotCleanResource ||
    isAllResourcesDisabledForSomeReason ||
    cleanDisabled ||
    resourceVisibility.actions[item.type] === false;

  return (
    <MenuItem
      dense
      key={item.type}
      onClick={onCleanItemClick(item.type)}
      disabled={itemDisabled}
    >
      {item.label}
    </MenuItem>
  );
};
