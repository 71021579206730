import { getUsersMeAccountsByAccountIdFilterSetsV2Selector } from "./getUsersMeAccountsByAccountIdFilterSetsV2Selector";
import { RootState } from "../../../store";

export const filterTemplatesLoadingByAccountIdSelector = (
  state: RootState,
  accountId: string,
): boolean => {
  return !!getUsersMeAccountsByAccountIdFilterSetsV2Selector(state, accountId)
    ?.isLoading;
};
