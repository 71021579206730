import { costBreakdownV2WidgetSetupDataSelector } from "./costBreakdownV2WidgetSetupDataSelector";
import { RootState } from "../../../../../store";
import { ChartDataType } from "../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { formatResourceExplorerChartData } from "../../../../../resource-explorer/utils/helpers/formatResourceExplorerChartData";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";

export const costBreakdownV2WidgetSetupChartDataSelector = (
  state: RootState,
): ChartDataType[] | [] => {
  const data = costBreakdownV2WidgetSetupDataSelector(state)?.data;
  const frequency =
    costBreakdownV2SetupPropertyByKeySelector("frequency")(state);

  if (!data) {
    return [];
  }

  return formatResourceExplorerChartData(data, frequency);
};
