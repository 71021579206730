import { Fragment } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { AwsMissingPermissions } from "./components/AwsMissingPermissions";
import { MissingPermissionsAlertButton } from "../common/MissingPermissionsAlertButton";
import { useGetUsersMeProvidersAwsAccountsByAccountIdMissingPermissionsQuery } from "../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../store/hooks";
import { currentAccountIdSelector } from "../../../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { currentAwsAccountIsRootSelector } from "../../../../../../../store/account/selectors/current-account/currentAwsAccountIsRootSelector";

export const AwsMissings = () => {
  const { open, openDialog, closeDialog } = useDialog();

  const enableMissingPermissionButtonForAWSRootAccount = useFlag(
    "EnableMissingPermissionButtonForAWSRootAccount",
  );
  const currentAccountId = useAppSelector(currentAccountIdSelector);
  const isRootAccount = useAppSelector(currentAwsAccountIsRootSelector);

  const { data } =
    useGetUsersMeProvidersAwsAccountsByAccountIdMissingPermissionsQuery(
      { accountId: currentAccountId || "" },
      { skip: !currentAccountId },
    );

  if (
    data?.length ||
    (enableMissingPermissionButtonForAWSRootAccount && isRootAccount)
  ) {
    return (
      <Fragment>
        <MissingPermissionsAlertButton
          onOpen={openDialog}
          title="Missing Permissions"
        />

        <AwsMissingPermissions open={open} onClose={closeDialog} />
      </Fragment>
    );
  }

  return null;
};
