import { FC, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Box, Stack } from "@mui/material";
import { FilterDropdownTrigger } from "../../../FilterDropdownTrigger";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import {
  ResourceExplorerFilterItem,
  ProviderType,
} from "../../../../../../../../../services/cloudchipr.api";
import { resourceTypeFilterChangeThunk } from "../../../../../../../../../store/resource-explorer/thunks/filters/resourceTypeFilterChangeThunk";
import { resourceExplorerFilterByKeyAndProviderSelector } from "../../../../../../../../../store/resource-explorer/selectors/filters/resourceExplorerFilterByKeyAndProviderSelector";
import { resourceExplorerPossibleFiltersByProviderSelector } from "../../../../../../../../../store/resource-explorer/selectors/filters/possible-filters/resourceExplorerPossibleFiltersByProviderSelector";
import { isDeepEqual } from "../../../../../../../../../utils/is-deep-equal";
import { dbSelectedResourceTypeSelector } from "../../../../../../../../../store/resource-explorer/selectors/filters/selected/dbSelectedResourceTypeSelector";
import { DropdownSelect } from "../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { DropdownSelectOption } from "../../../../../../../../common/select/dropdown-select/utils/types/types";
import AwsMarketplaceIcon from "../../../../../../../../../assets/images/icons/aws_marketplace.svg";

interface ResourceExplorerServiceFilterProps {
  viewId: string;
  provider: ProviderType;
}

export const ResourceExplorerServiceFilter: FC<
  ResourceExplorerServiceFilterProps
> = ({ viewId, provider }) => {
  const dispatch = useDispatch();
  const filter = useAppSelector((state) =>
    resourceExplorerFilterByKeyAndProviderSelector(state, "service", provider),
  );

  const possibleFilters = useAppSelector((state) =>
    resourceExplorerPossibleFiltersByProviderSelector(state, provider),
  )?.service;

  const selectedFilter = useAppSelector((state) =>
    dbSelectedResourceTypeSelector(state, provider),
  );

  const options = useMemo(() => {
    const marketPlaceServicesOptions: DropdownSelectOption[] = [];
    const restOptions: DropdownSelectOption[] = [];

    possibleFilters?.values?.forEach((option) => {
      if (option.is_marketplace_service) {
        marketPlaceServicesOptions.push({
          value: option.value,
          rawValue: { keywords: ["marketplace services"] },
          label: (
            <Stack direction="row" alignItems="center">
              <Box mr={1} fontSize={0}>
                <img src={AwsMarketplaceIcon} alt="AWS_Marketplace" />
              </Box>

              {option.title || option.value}
            </Stack>
          ),
        });
      } else {
        restOptions.push({
          value: option.value,
          label: option.title || option.value,
        });
      }
    });

    if (!marketPlaceServicesOptions.length) {
      return restOptions;
    }

    return [
      ...restOptions,
      {
        value: "marketplace_services",
        nestedOptions: marketPlaceServicesOptions,
        expandable: false,
        label: (
          <Stack direction="row" alignItems="center">
            <Box mr={1} fontSize={0}>
              <img src={AwsMarketplaceIcon} alt="AWS_Marketplace" />
            </Box>
            Marketplace Services
          </Stack>
        ),
      },
    ];
  }, [possibleFilters]);

  const submitHandlerOnClose = useCallback(
    (value: string[]) => {
      const newFilter: ResourceExplorerFilterItem = {
        ...filter,
        value,
        key: "service",
        operator: "in",
        cloud_provider: provider,
        include_values: filter?.include_values ?? null,
      };

      const isEqual = filter && isDeepEqual(newFilter, filter);

      if (!isEqual) {
        dispatch(
          resourceTypeFilterChangeThunk({ filter: newFilter, provider }),
        );
      }
    },
    [dispatch, filter, provider],
  );

  const TriggerComponent = useCallback(
    (props: any) => (
      <FilterDropdownTrigger
        {...props}
        viewId={viewId}
        provider={provider}
        filterKey="service"
        title="Services"
      />
    ),
    [provider, viewId],
  );

  return (
    <DropdownSelect
      label="Services"
      options={options}
      initialSelectedValues={(selectedFilter.value ?? []) as string[]}
      submitHandlerOnClose={submitHandlerOnClose}
      filterFn={filterFn}
      TriggerComponent={TriggerComponent}
      values={(selectedFilter.value ?? []) as string[]}
    />
  );
};

const filterFn = (option: DropdownSelectOption, keyword: string): boolean => {
  keyword = keyword.trim().toLowerCase();

  return (
    option.value.trim().toLowerCase().includes(keyword) ||
    option.rawValue?.keywords?.some((kw: string) =>
      kw.toLowerCase().includes(keyword),
    ) ||
    !!option.nestedOptions?.some((option) => filterFn(option, keyword))
  );
};
