import { RootState } from "../../../../store";
import { enabledProvidersSelector } from "../../../../common/selectors/billing-analytics/enabled-providers/enabledProvidersSelector";
import { billingAccountStatusAllProvidersSelector } from "../../../../common/selectors/billing-status/billingAccountStatusAllProvidersSelector";
import { getCurrentProvidersByProviderBillingStatusSelector } from "../../../../common/selectors/billing-status/getCurrentProvidersByProviderBillingStatusSelector";

export const isResourceExplorerAvailableSelector = (
  state: RootState,
): boolean => {
  const enabledProviders = enabledProvidersSelector(state);
  const enabledProvidersArr = Array.from(enabledProviders);

  const provider = enabledProvidersArr[0];
  const billingStatusesAllProviders =
    billingAccountStatusAllProvidersSelector(state);

  const billingStatuses = getCurrentProvidersByProviderBillingStatusSelector(
    state,
    provider,
  ).data?.billing_account_statuses;

  const activeAccountExist = !!billingStatuses?.some(
    (item) => item.status === "active",
  );

  return (
    enabledProvidersArr.length !== 1 ||
    billingStatusesAllProviders[provider]?.status === "active" ||
    activeAccountExist
  );
};
