import { FC, RefObject } from "react";
import { TableCell, TableRow } from "@mui/material";
import { Row } from "@tanstack/react-table";
import { RowExpandingConfig } from "../utils/types/prop-types";

export interface ExpandedRowProps {
  row: Row<any>;
  config?: RowExpandingConfig;
  globalFilter?: string;
  tableContainerRef: RefObject<HTMLTableElement>;
}

export const ExpandedRow: FC<ExpandedRowProps> = ({
  globalFilter,
  config,
  row,
  tableContainerRef,
}) => {
  if (!row.getIsExpanded()) {
    return null;
  }

  return (
    <TableRow sx={config?.parentRowStyles} id={generateExpandedRowId(row.id)}>
      <TableCell colSpan={row.getVisibleCells().length}>
        {config?.renderExpandedRowSubRow?.(
          row,
          tableContainerRef,
          globalFilter,
        )}
      </TableCell>
    </TableRow>
  );
};

export const generateExpandedRowId = (id: string) => {
  return `${id}_expanded`;
};
