import { FC, ReactNode, useCallback } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import { PieChartOutlineOutlined } from "@mui/icons-material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { ChartType } from "../../../../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { costByCategorySetupChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/cost-by-category/costByCategorySetupChangeThunk";
import { costByCategorySetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-by-category/costByCategorySetupPropertyByKeySelector";

export const CostByCategoryChartTypeSelect: FC = () => {
  const dispatch = useAppDispatch();

  const displayFilteredResources = useAppSelector(
    costByCategorySetupPropertyByKeySelector("displayFilteredResources"),
  );
  const chartType = useAppSelector(
    costByCategorySetupPropertyByKeySelector("chartType"),
  );
  const frequency = useAppSelector(
    costByCategorySetupPropertyByKeySelector("frequency"),
  );

  const changeHandler = useCallback(
    (e: any) => {
      const type = e.target.value;
      const withoutFilteredResources =
        type === "multiStack" && !displayFilteredResources;

      dispatch(
        costByCategorySetupChangeThunk({
          chartType: withoutFilteredResources ? "bar" : type,
          frequency: type === "pie" ? "monthly" : frequency,
        }),
      );
    },
    [dispatch, displayFilteredResources, frequency],
  );

  return (
    <Select
      size="small"
      fullWidth
      value={chartType}
      onChange={changeHandler}
      renderValue={(selected: ChartType) => {
        return (
          <Stack direction="row" spacing={1}>
            {icons[selected]}

            <Typography variant="body1">{labels[selected]}</Typography>
          </Stack>
        );
      }}
    >
      <MenuItem value="multiStack">
        <ListItemIcon>{icons["multiStack"]} </ListItemIcon>
        <ListItemText>Bar Chart</ListItemText>
      </MenuItem>

      <MenuItem value="pie">
        <ListItemIcon>{icons["pie"]} </ListItemIcon>
        <ListItemText>Pie Chart</ListItemText>
      </MenuItem>
    </Select>
  );
};

const icons: Partial<Record<ChartType, ReactNode>> = {
  multiStack: <BarChartIcon fontSize="small" color="action" />,
  bar: <BarChartIcon fontSize="small" color="action" />,
  pie: <PieChartOutlineOutlined fontSize="small" color="action" />,
};

const labels: Partial<Record<ChartType, string>> = {
  multiStack: "Bar Chart",
  bar: "Bar Chart",
  pie: "Pie Chart",
};
