import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { cleanStateSelector } from "./cleanStateSelector";
import { CleanDetails } from "../cleanSlice";

export const newCleanProcessSelector = createDraftSafeSelector(
  [cleanStateSelector],
  (cleanState): CleanDetails | null => {
    return cleanState.new;
  },
);
