import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { money } from "../../../../../../../utils/numeral/money";
import { useAppSelector } from "../../../../../../../store/hooks";
import { currentAccountEmsSelector } from "../../../../../../../store/account/selectors/current-account/currentAccountEmsSelector";
import { currentAccountSpendingSelector } from "../../../../../../../store/account/selectors/current-account/currentAccountSpendingSelector";
import { filterTemplatesEnabledSelector } from "../../../../../../../store/account/selectors/current-account/resources/filterTemplatesEnabledSelector";
import { useToggledResourceTypes } from "../../../../utils/hooks/useToggledResources";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";

export const ServicesCosts: FC = () => {
  const toggledResourceTypes = useToggledResourceTypes();
  const ems = useAppSelector((state) =>
    currentAccountEmsSelector(state, toggledResourceTypes),
  );
  const spending = useAppSelector((state) =>
    currentAccountSpendingSelector(state, toggledResourceTypes),
  );
  const filterTemplatesEnabled = useAppSelector(filterTemplatesEnabledSelector);
  return (
    <Stack direction="row" justifyContent="space-between" pl={2} pr={3}>
      <TypographyWithTooltip variant="subtitle2" title="Services" />
      <Typography variant="subtitle2" noWrap>
        {filterTemplatesEnabled && (
          <Typography color="error" variant="inherit" component="span">
            {money(ems, 1000)}
          </Typography>
        )}
        <Typography color="text.secondary" variant="inherit" component="span">
          {filterTemplatesEnabled && " / "} {money(spending, 1000)}
        </Typography>
      </Typography>
    </Stack>
  );
};
