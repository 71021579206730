import { currentAccountIdSelector } from "./currentAccountIdSelector";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

export const currentAccountSelector = (state: RootState) => {
  const currentAccountId = currentAccountIdSelector(state);
  if (!currentAccountId) {
    return null;
  }

  return (
    cloudChiprApi.endpoints.getUsersMeAccountsByAccountId.select({
      accountId: currentAccountId,
    })(state).data || null
  );
};
