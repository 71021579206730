import { widgetSetupSelector } from "./widgetSetupSelector";
import { RootState } from "../../../store";

export const widgetSetupViewIdSelector = (
  state: RootState,
): string | undefined => {
  const setup = widgetSetupSelector(state);

  return setup && "viewId" in setup ? setup?.viewId : undefined;
};
