import { Stack, Typography } from "@mui/material";
import { CostByCategorySelectionCell } from "./CostByCategorySelectionCell";
import { ColumnSetupType } from "../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { money } from "../../../../../../../../../../../../utils/numeral/money";
import { AccountsWithPopover } from "../../../../../../../../../../../common/accounts-with-popover/AccountsWithPopover";
import { SelectAllHeaderCheckbox } from "../../../../../../../../../../../../storybook/data-grid/common/SelectAllCheckboxHeaderCell";
import { LiveIndicator } from "../../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/live-usage/LiveIndicator";
import { CostByCategoryWidgetCategory } from "../../../../utils/types/types";
import { TypographyWithTooltip } from "../../../../../../../../../../../common/TypographyWithTooltip";

export const costByCategoryWidgetSetupTableColumns: ColumnSetupType<CostByCategoryWidgetCategory>[] =
  [
    {
      size: 40,
      maxSize: 40,
      id: "checkbox",
      enableSorting: false,
      header: (props) => {
        return <SelectAllHeaderCheckbox {...props} sxProps={{ pl: 2 }} />;
      },
      cell: CostByCategorySelectionCell,
    },
    {
      accessorKey: "category",
      header: "Category",
      cell: (cell) => {
        const categoryName = cell.getValue() as string;
        return <TypographyWithTooltip variant="body2" title={categoryName} />;
      },
      meta: { alwaysShowSortIcon: true },
    },
    {
      minSize: 200,
      id: "accounts",
      accessorKey: "total_cost",
      filterFn: (row, _, filterValue) => {
        const accounts = row.original.accounts;
        const aim = accounts
          .map(
            (account) =>
              `${account.provider_account_id} ${account.provider_account_name} ${account.provider}`,
          )
          .join(" ");

        return `${aim}`.includes(filterValue);
      },
      header: "Accounts",
      cell: (cell) => {
        const accounts = cell.row.original.accounts;

        return (
          <AccountsWithPopover maxWidth={170} chipView accounts={accounts} />
        );
      },
      meta: { alwaysShowSortIcon: true },
    },
    {
      id: "total_cost",
      accessorKey: "total_cost",
      header: () => {
        return (
          <Typography
            fontWeight="inherit"
            flexGrow={1}
            fontSize="inherit"
            align="right"
          >
            Total Cost
          </Typography>
        );
      },
      cell: (cell) => {
        const value = (cell.getValue() as string) ?? "";
        return (
          <Typography variant="body2" fontWeight="medium" align="right" pr={2}>
            {money(value)}
          </Typography>
        );
      },
      meta: { alwaysShowSortIcon: true },
    },
    {
      accessorKey: "total_filtered",
      header: () => {
        return (
          <Typography
            fontWeight="inherit"
            flexGrow={1}
            fontSize="inherit"
            align="right"
          >
            Filtered Resources
          </Typography>
        );
      },
      cell: (cell) => {
        const value = (cell.getValue() as string) ?? "";
        return (
          <Typography variant="body2" align="right" pr={2}>
            {money(value)}
          </Typography>
        );
      },
      meta: { alwaysShowSortIcon: true },
    },
    {
      accessorKey: "live_filtered",
      header: () => {
        return (
          <Typography
            fontWeight="inherit"
            flexGrow={1}
            fontSize="inherit"
            align="right"
          >
            Live Filtered
          </Typography>
        );
      },
      cell: (cell) => {
        const value = (cell.getValue() as string) ?? "";
        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            spacing={1}
          >
            <LiveIndicator />
            <Typography variant="body2" color="error.main" pr={2}>
              {money(value)}
            </Typography>
          </Stack>
        );
      },
      meta: { alwaysShowSortIcon: true },
    },
  ];
