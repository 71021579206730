import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { awsNonDeletedAndNotBillingAccountsSelector } from "../../accounts/selectors/aws/awsNonDeletedAndNotBillingAccountsSelector";
import { gcpNonDeletedAndNotBillingAccountsSelector } from "../../accounts/selectors/gcp/gcpNonDeletedAndNotBillingAccountsSelector";
import { azureNonDeletedAndNotBillingAccountsSelector } from "../../accounts/selectors/azure/azureNonDeletedAndNotBillingAccountsSelector";
import { Account, ProviderType } from "../../../services/cloudchipr.api";

export const workflowDefaultProviderSelector = createDraftSafeSelector(
  [
    awsNonDeletedAndNotBillingAccountsSelector,
    gcpNonDeletedAndNotBillingAccountsSelector,
    azureNonDeletedAndNotBillingAccountsSelector,
  ],
  (
    awsAccounts: Account[] | null,
    gcpAccounts: Account[] | null,
    azureAccounts: Account[] | null,
  ): ProviderType | null => {
    if (awsAccounts?.length) {
      return "aws";
    }

    if (gcpAccounts?.length) {
      return "gcp";
    }

    if (azureAccounts?.length) {
      return "azure";
    }

    return null;
  },
);
