import { resourceTypeDataResourcesSelector } from "./resourceTypeDataResourcesSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const resourceTypeChildResourcesCountSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const resources = resourceTypeDataResourcesSelector(state, resourceType);
  return resources?.reduce(
    (total, resource) =>
      total + (resource?.child_resource?.resources?.length ?? 0),
    0,
  );
};
