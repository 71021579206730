import { FC } from "react";
import { TextField } from "@mui/material";
import { GroupingByTagValuesTriggerEndAdornment } from "./GroupingByTagValuesTriggerEndAdornment";
import { UseMenuHookOpenMenu } from "../../../../../../utils/hooks/useMenu.hook";
import { getResourcesExplorerGroupingLabel } from "../../../../resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import {
  ProviderType,
  ResourceExplorerGrouping,
} from "../../../../../../services/cloudchipr.api";

interface GroupingByTagValuesTriggerProps {
  open: boolean;
  onClick: UseMenuHookOpenMenu;
  groupBy: ResourceExplorerGrouping;
  providers: ProviderType[];
  groupValues?: string[];
  disabled?: boolean;
}

export const GroupingByTagValuesTrigger: FC<
  GroupingByTagValuesTriggerProps
> = ({ onClick, open, groupBy, groupValues, providers, disabled }) => {
  return (
    <TextField
      size="small"
      label="Group by"
      onClick={onClick}
      disabled={disabled}
      contentEditable={false}
      value={getResourcesExplorerGroupingLabel(providers, groupBy, true)}
      InputProps={{
        inputProps: {
          style: {
            cursor: "pointer",
            caretColor: "transparent",
          },
        },
        sx: {
          cursor: "pointer",
        },
        endAdornment: (
          <GroupingByTagValuesTriggerEndAdornment
            open={open}
            values={groupValues}
          />
        ),
      }}
    />
  );
};
