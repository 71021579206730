import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { WidgetNumeralView } from "../../../common/view-type/WidgetNumeralView";
import { WidgetNumeralViewItem } from "../../../common/view-type/WidgetNumeralViewItem";
import { costBreakdownV2WidgetSetupLoadingSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupLoadingSelector";
import { costBreakdownV2WidgetSetupNoChartViewDataSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupNoChartViewDataSelector";
import { getCostBreakdownV2NumeralItemSecondaryLabel } from "../../../../../../widgets/cost-breakdown-v2/components/chart/numeral/utils/helpers/getCostBreakdownV2NumeralItemSecondaryLabel";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";

export const CostBreakdownV2WidgetSetupNumeralView: FC = () => {
  const data = useAppSelector(
    costBreakdownV2WidgetSetupNoChartViewDataSelector,
  );
  const forecastOption = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("forecastOption"),
  );

  const grouping = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("grouping"),
  );

  const loading = useAppSelector(costBreakdownV2WidgetSetupLoadingSelector);

  return (
    <WidgetNumeralView loading={loading} skeletonCount={data?.length}>
      {data?.map((item) => {
        const providers = item?.multiProviders ?? [item.cloud_provider];

        const secondaryLabel = getCostBreakdownV2NumeralItemSecondaryLabel({
          monthlyCost: item?.monthly_forecast?.cost,
          quarterlyCost: item?.quarterly_forecast?.cost,
          grouping,
          forecastOption,
        });

        return (
          <WidgetNumeralViewItem
            key={item.cloud_provider + item.field}
            providers={item.cloud_provider ? providers : []}
            label={item.field}
            cost={item.total_cost}
            trend={item.trend}
            itemsCount={data.length}
            secondaryLabel={secondaryLabel}
          />
        );
      })}
    </WidgetNumeralView>
  );
};
