import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { TrendColumnHeaderCell } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/TrendColumnHeaderCell";
import { costBreakdownV2WidgetTableTrendSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetTableTrendSelector";

interface CostBreakdownWidgetTableTrendHeaderCellProps {
  widgetId: string;
}
export const CostBreakdownV2WidgetTableTrendHeaderCell: FC<
  CostBreakdownWidgetTableTrendHeaderCellProps
> = ({ widgetId }) => {
  const trend = useAppSelector((state) =>
    costBreakdownV2WidgetTableTrendSelector(state, widgetId),
  );
  return <TrendColumnHeaderCell trend={trend} />;
};
