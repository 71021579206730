import { CostAndUsageDataGridType } from "../../../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/cost-and-usage/content/components/data-grid/utils/types";
import { CostAndUsageSumWidgetFilterType } from "../../../../../utils/constants/costAndUsageSetupDefaultData";

export const generateCostAndUsageSumFilterTypeData = (
  costAndUsageSumWidgetFilterType: CostAndUsageSumWidgetFilterType,
  label: string,
  cost: number | null,
  trend?: number | null,
  currentDatesFrom?: string,
  currentDatesTo?: string,
  previousDatesFrom?: string,
  previousDatesTo?: string,
): CostAndUsageDataGridType => ({
  id: costAndUsageSumWidgetFilterType,
  costAndUsageSumWidgetFilterType,
  label,
  cost,
  trendDetails:
    trend !== null
      ? {
          trend: trend ?? 0,
          currentDates: {
            from: currentDatesFrom,
            to: currentDatesTo,
          },
          previousDates: {
            from: previousDatesFrom,
            to: previousDatesTo,
          },
        }
      : null,
});
