import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { Disk } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const diskColumns: ColumnSetupType<Disk>[] = [
  {
    accessorKey: "name",
    header: "Disk Name",
    type: "identifier",
    size: 200,
    meta: { sticky: "left", hideFromSettings: true },
  },
  { accessorKey: "type", header: "Disk Type" },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "created_at",
    header: "Creation Time",
    type: "date",
  },
  { accessorKey: "size", header: "Size", type: "byteToGib" },
  { accessorKey: "state", header: "State" },
  {
    accessorKey: "availability_zone",
    header: "Av. Zone",
    type: "withTooltipCell",
  },
  { accessorKey: "tags", header: "Labels", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Labels",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "Ticket Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
