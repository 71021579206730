import { Fragment } from "react";
import { AwsMissings } from "./components/aws-missings/AwsMissings";
import { GcpMissings } from "./components/gcp-missings/GcpMissings";
import { AzureMissings } from "./components/azure-missings/AzureMissings";
import { useAppSelector } from "../../../../../store/hooks";
import { currentAccountProviderTypeSelector } from "../../../../../store/account/selectors/current-account/currentAccountProviderTypeSelector";

export const Messages = () => {
  const provider = useAppSelector(currentAccountProviderTypeSelector);

  return (
    <Fragment>
      {provider === "aws" && <AwsMissings />}

      {provider === "gcp" && <GcpMissings />}

      {provider === "azure" && <AzureMissings />}
    </Fragment>
  );
};
