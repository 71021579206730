import { Typography } from "@mui/material";
import { FC } from "react";
import { grey } from "@mui/material/colors";
import { useDateFromNow } from "../../utils/hooks/useDateFromNow";
import { useAppSelector } from "../../../../../store/hooks";
import { currentAccountLastResourceUpdatedDateSelector } from "../../../../../store/account/selectors/current-account/currentAccountLastResourceUpdatedDateSelector";

export const HeaderLastUpdatedDate: FC = () => {
  const lastResourceUpdatedDate = useAppSelector(
    currentAccountLastResourceUpdatedDateSelector,
  );
  const fromNow = useDateFromNow(lastResourceUpdatedDate);

  if (!fromNow) {
    return null;
  }

  return (
    <Typography variant="caption" color={grey[500]}>
      The data is no older than {fromNow}
    </Typography>
  );
};
