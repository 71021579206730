import { FC } from "react";
import { Button, Stack, Typography } from "@mui/material";
import {
  MessagesDialog,
  MessagesDialogProps,
} from "../../common/MessagesDialog";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { useGetUsersMeProvidersAzureAccountsByAccountIdMissingRolesQuery } from "../../../../../../../../services/cloudchipr.api";
import { currentAccountIdSelector } from "../../../../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import { azureAccountServicePrincipalSelector } from "../../../../../../../../store/account/selectors/current-account/azureAccountServicePrincipalSelector";
import { CommandSnippet } from "../../../../../../common/connectors/components/CommandSnippet";
import { getAzureRoleAssignmentCommand } from "../../../utils/helpers/getAzureRoleAssignmentCommand";
import { azureAccountSubscriptionIdSelector } from "../../../../../../../../store/account/selectors/current-account/azureAccountSubscriptionIdSelector";
import { MissingPermissionsRecheck } from "../../common/MissingPermissionsRecheck";

export const AzureMissingRolesDialog: FC<MessagesDialogProps> = ({
  onClose,
  open,
}) => {
  const currentAccountId = useAppSelector(currentAccountIdSelector);

  const currentAccountServicePrincipal = useAppSelector(
    azureAccountServicePrincipalSelector,
  );
  const currentAccountSubscriptionId = useAppSelector(
    azureAccountSubscriptionIdSelector,
  );

  const { data, refetch } =
    useGetUsersMeProvidersAzureAccountsByAccountIdMissingRolesQuery(
      { accountId: currentAccountId || "" },
      { skip: !currentAccountId },
    );

  if (!currentAccountServicePrincipal) {
    return null;
  }

  return (
    <MessagesDialog
      name="Permissions"
      open={open}
      onClose={onClose}
      actions={
        <Button color="tertiary" onClick={onClose}>
          Close
        </Button>
      }
    >
      <Stack spacing={2}>
        <Typography variant="body2">
          To assign role, please run the following command in your terminal.
        </Typography>

        {data?.map(({ title, name }) => {
          const role = title || name;

          return role && currentAccountServicePrincipal ? (
            <CommandSnippet
              key={role}
              code={getAzureRoleAssignmentCommand(
                currentAccountServicePrincipal ?? "",
                role,
                `/subscriptions/${currentAccountSubscriptionId}`,
              )}
            >
              az role assignment create --assignee{" "}
              <Typography
                fontFamily="monospace"
                color="warning.light"
                component="span"
              >
                {currentAccountServicePrincipal}
              </Typography>{" "}
              --role{" "}
              <Typography
                component="span"
                fontFamily="monospace"
                color="success.light"
              >
                "{role}"
              </Typography>{" "}
              --scope /subscriptions/
              <Typography
                fontFamily="monospace"
                color="warning.light"
                component="span"
              >
                {currentAccountSubscriptionId}
              </Typography>
            </CommandSnippet>
          ) : null;
        })}

        <MissingPermissionsRecheck forApi refetch={refetch} />
      </Stack>
    </MessagesDialog>
  );
};
