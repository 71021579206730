import { RootState } from "../../../../store";
import { widgetREViewDataSelector } from "../common/widget-re-view/widgetREViewDataSelector";
import { buildingInProgressWidgetId } from "../../../thunks/widgets/utils/constants";
import { ResourceExplorerGridTotal } from "../../../../../services/cloudchipr.api";

interface CostAndUsageSumWidgetDataGridDataSelectorArgs {
  widgetId?: string;
}
export const largestCostChangesWidgetDataSelector = (
  state: RootState,
  { widgetId }: CostAndUsageSumWidgetDataGridDataSelectorArgs,
): ResourceExplorerGridTotal[] => {
  const data = widgetREViewDataSelector(
    state,
    widgetId ?? buildingInProgressWidgetId,
  );

  if (!data?.total) {
    return [];
  }
  return data.total;
};
