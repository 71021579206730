import { DropdownSelectOption } from "../../../../../../common/select/dropdown-select/utils/types/types";
import { ResourceExplorerPossibleFilter } from "../../../../../../../services/cloudchipr.api";

type ResourceExplorerRegionOptionRawValue = Pick<
  ResourceExplorerPossibleFilter["values"][0],
  "title" | "value"
>;

export const resourceExplorerAccountOptionFilterFn = (
  option: DropdownSelectOption,
  keyword: string,
) => {
  if (!option.rawValue) {
    return false;
  }

  const { title, value }: ResourceExplorerRegionOptionRawValue =
    option.rawValue;

  return (
    title?.trim()?.toLowerCase()?.includes(keyword.trim().toLowerCase()) ||
    value.trim().toLowerCase().includes(keyword.trim().toLowerCase())
  );
};
