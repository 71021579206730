import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getResourceExplorersThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorers",
  async (_, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentResourceExplorerViews } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        getUsersMeOrganisationsCurrentResourceExplorerViews.initiate(
          {},
          { forceRefetch: true },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
