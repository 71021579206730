import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProviderType } from "../../../../services/cloudchipr.api";
import { getResourceExplorerPossibleFiltersThunk } from "../../../resource-explorer/thunks/resource-explorer/getResourceExplorerPossibleFiltersThunk";

export const getResourceExplorerPossibleFiltersByProvidersThunk =
  createAsyncThunk(
    "resourceExplorer/getResourceExplorerPossibleFiltersByProvidersThunk",
    async (providers: ProviderType[], { dispatch }) => {
      const promises = providers.map((provider) => {
        return dispatch(getResourceExplorerPossibleFiltersThunk({ provider }));
      });

      await Promise.all(promises);
    },
  );
