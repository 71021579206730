import { Button, Link, Typography } from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { Row } from "@tanstack/react-table";
import { ColumnSetupType } from "../../../../../../../../storybook/data-grid/utils/types/types";
import { SavingsPlanRecommendation } from "../../../../../../../../services/cloudchipr.api";
import {
  savingsPlanAccountScopeLabels,
  savingsPlanLockBackPeriodDays,
  savingsPlanLockBackPeriodLabels,
  savingsPlansPaymentOptionLabels,
  savingsPlansTypeLabels,
  savingsPlanTermLabels,
} from "../../../../utils/constants/labels";
import { money } from "../../../../../../../../utils/numeral/money";

export const coverageDrawerRecommendationsGridColumns: ColumnSetupType<SavingsPlanRecommendation>[] =
  [
    {
      minSize: 180,
      accessorKey: "term_in_years",
      header: "Savings Plans term",
      cell: ({ row }) => {
        return savingsPlanTermLabels[row.original.term_in_years];
      },
    },
    {
      accessorKey: "savings_plan_type",
      header: "Type",
      cell: ({ row }) => {
        return savingsPlansTypeLabels[row.original.savings_plan_type];
      },
    },
    {
      minSize: 200,
      accessorKey: "account_scope",
      header: "Recommendation Level",
      cell: ({ row }) => {
        return savingsPlanAccountScopeLabels[row.original.account_scope];
      },
    },
    {
      accessorKey: "lookback_period",
      header: "Lookback Period",
      cell: ({ row }) => {
        return savingsPlanLockBackPeriodLabels[row.original.lookback_period];
      },
      sortingFn: (
        currentRow: Row<SavingsPlanRecommendation>,
        nextRow: Row<SavingsPlanRecommendation>,
      ) => {
        const current = currentRow.original.lookback_period;
        const next = nextRow.original.lookback_period;

        const currentValue = savingsPlanLockBackPeriodDays[current];
        const nextValue = savingsPlanLockBackPeriodDays[next];

        return (currentValue ?? 0) - (nextValue ?? 0);
      },
    },
    {
      accessorKey: "payment_option",
      header: "Payment Option",
      cell: ({ row }) => {
        return savingsPlansPaymentOptionLabels[row.original.payment_option];
      },
    },
    {
      accessorKey: "hourly_commitment",
      header: "Commitment",
      cell: ({ row }) => `${row.original.hourly_commitment}/hour`,
    },
    {
      minSize: 180,
      accessorKey: "estimated_coverage",
      header: "Estimated Coverage",
      cell: ({ row }) => `${row.original.estimated_coverage}%`,
    },
    {
      minSize: 230,
      accessorKey: "estimated_monthly_savings",
      header: "Estimated Monthly Savings",
      cell: ({ row }) => {
        return (
          <Typography variant="body2" color="success.light">
            {money(row.original.estimated_monthly_savings)} (
            {row.original.estimated_savings_percentage}%)
          </Typography>
        );
      },
    },
    {
      size: 135,
      accessorKey: "action_url",
      header: "",
      enableSorting: false,
      meta: { sticky: "right" },
      cell: (cell) => {
        return (
          <Button
            component={Link}
            href={cell.getValue() as string}
            target="_blank"
            variant="outlined"
            endIcon={<OpenInNewOutlinedIcon />}
            size="small"
          >
            GO TO AWS
          </Button>
        );
      },
    },
  ];
