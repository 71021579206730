import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCostAndUsageSumWidgetDataBySetupThunk } from "./fetchCostAndUsageSumWidgetDataBySetupThunk";
import { RootState } from "../../../../store";
import { setWidgetSetup } from "../../../dashboardsSlice";
import { resourceExplorerViewsDataSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewsDataSelector";
import { costAndUsageSumSetupDefaultData } from "../../../utils/constants/costAndUsageSetupDefaultData";

export const initiateCostAndUsageSumWidgetThunk = createAsyncThunk(
  "dashboards/initiateCostAndUsageSumWidget",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;

    const views = resourceExplorerViewsDataSelector(state);

    const defaultViewId = views?.find((view) => view.is_default)?.id;

    if (!defaultViewId) {
      dispatch(setWidgetSetup(costAndUsageSumSetupDefaultData));
      return;
    }

    dispatch(
      setWidgetSetup({
        ...costAndUsageSumSetupDefaultData,
        viewId: defaultViewId,
      }),
    );

    dispatch(fetchCostAndUsageSumWidgetDataBySetupThunk());
  },
);
