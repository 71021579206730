import { FC, memo } from "react";

import { IconButton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

interface ResourceExplorerCostAllocationTagHeaderProps {
  onCloseIconClick(): void;
  title: string;
}
export const ResourceExplorerCostAllocationTagHeader: FC<ResourceExplorerCostAllocationTagHeaderProps> =
  memo(({ onCloseIconClick, title }) => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pl={3}
        pr={2}
        py={1.5}
      >
        <Typography color="text.primary" variant="subtitle1" fontWeight={500}>
          {title}
        </Typography>
        <IconButton onClick={onCloseIconClick}>
          <Close />
        </IconButton>
      </Stack>
    );
  });
