import { FC, useCallback, useState } from "react";
import { Button, DialogActions } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { SnackbarKey, useSnackbar } from "notistack";
import { createFilterTemplateFromAppliedFiltersThunk } from "../../../../../store/filters/thunks/filter-set/createFilterTemplateFromAppliedFiltersThunk";
import { patchFilterTemplateFromAppliedFiltersThunk } from "../../../../../store/filters/thunks/filter-set/patchFilterTemplateFromAppliedFiltersThunk";
import { useAppDispatch } from "../../../../../store/hooks";

interface FilterTemplateSaveAsNewDialogActionsProps {
  name: string;
  saveAsNew: boolean;
  disabled: boolean;
  filterTemplateId: string;
  onClose(): void;
}

export const FilterTemplateSaveAsNewDialogActions: FC<
  FilterTemplateSaveAsNewDialogActionsProps
> = ({ filterTemplateId, saveAsNew, name, disabled, onClose }) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [filterSetCreating, setFilterSetCreating] = useState(false);

  const notifyHandler = useCallback(() => {
    const key: SnackbarKey = enqueueSnackbar(
      "The Filter Template successfully saved.",
      {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          icon: false,
          severity: "success",
          onClose: () => closeSnackbar(key),
        },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      },
    );
  }, [enqueueSnackbar, closeSnackbar]);

  const filterTemplateSaveHandler = useCallback(async () => {
    setFilterSetCreating(true);

    let response;
    if (saveAsNew) {
      response = await dispatch(
        patchFilterTemplateFromAppliedFiltersThunk(filterTemplateId),
      );
    } else {
      response = await dispatch(
        createFilterTemplateFromAppliedFiltersThunk(name),
      );
    }

    setFilterSetCreating(false);
    onClose();

    if (response.payload) {
      notifyHandler();
    }
  }, [dispatch, onClose, notifyHandler, saveAsNew, name, filterTemplateId]);

  return (
    <DialogActions sx={{ py: 2, px: 3 }}>
      <Button onClick={onClose} color="tertiary">
        Cancel
      </Button>

      <LoadingButton
        type="button"
        disabled={disabled || (saveAsNew ? !filterTemplateId : !name)}
        loading={filterSetCreating}
        variant="contained"
        onClick={filterTemplateSaveHandler}
      >
        Save
      </LoadingButton>
    </DialogActions>
  );
};
