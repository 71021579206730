import { FC } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../../../../../../store/hooks";
import { dimensionDrawerModeSelector } from "../../../../../../../store/dimensions/selectors/slice-data/dimensionDrawerModeSelector";
import { dimensionNameSelector } from "../../../../../../../store/dimensions/selectors/slice-data/dimensionNameSelector";

interface DrawerHeaderProps {
  onClose(): void;
}

export const DimensionDrawerHeader: FC<DrawerHeaderProps> = ({ onClose }) => {
  const mode = useAppSelector(dimensionDrawerModeSelector);
  const name = useAppSelector(dimensionNameSelector);

  return (
    <Stack
      px={2}
      py={1.5}
      direction="row"
      borderBottom={1}
      alignItems="center"
      borderColor="grey.300"
      justifyContent="space-between"
    >
      {mode === "dimensionCreate" && (
        <Typography variant="body2">Add Dimension</Typography>
      )}

      {mode === "dimensionEdit" && (
        <Typography variant="body2">
          <Typography variant="inherit" component="span" fontWeight="bold">
            {name}
          </Typography>{" "}
          Dimension
        </Typography>
      )}

      <IconButton onClick={onClose} size="small">
        <CloseIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};
