import { largestCostChangesWidgetByIdSelector } from "./largestCostChangesWidgetByIdSelector";
import { RootState } from "../../../../store";
import { largestCostChangesSetupSelector } from "../../setups/larges-cost-changes/largestCostChangesSetupSelector";

export const largestCostChangesWidgetEditChangesExistSelector = (
  state: RootState,
) => {
  const setup = largestCostChangesSetupSelector(state);

  if (!setup?.id) {
    return false;
  }

  const widget = largestCostChangesWidgetByIdSelector(state, setup?.id);

  if (!widget) {
    return false;
  }

  return (
    setup.name !== widget.name ||
    setup.viewId !== widget.view_id ||
    setup.grouping !== widget.grouping ||
    setup.date.from !== widget.date_from ||
    setup.date.to !== widget.date_to ||
    setup.date.label !== widget.date_label ||
    !!widget.min_cost_change !== !!setup.minCostChange?.state ||
    setup.minCostChange?.value !== widget.min_cost_change ||
    setup.priceDirection !== widget.price_direction ||
    setup.sortingBy !== widget.sort_by ||
    !!widget.min_percentage_change !== !!setup.minPercentageChange?.state ||
    setup.minPercentageChange?.value !== widget.min_percentage_change ||
    setup.itemsCount !== widget.items_count
  );
};
