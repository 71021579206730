import { FC } from "react";
import { Stack, Typography } from "@mui/material";

interface ResourceExplorerAccountFilterLabelProps {
  title: string;
  accountId?: string;
}

export const ResourceExplorerAccountFilterLabel: FC<
  ResourceExplorerAccountFilterLabelProps
> = ({ title, accountId }) => {
  return (
    <Stack justifyContent="space-between">
      <Typography variant="body2">{title}</Typography>
      {accountId !== title && (
        <Typography variant="caption" color="text.secondary">
          {accountId}
        </Typography>
      )}
    </Stack>
  );
};
