import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { widgetViewNameByViewIdIdSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/common/widgetViewNameByViewIdIdSelector";
import { WidgetSetupSubHeader } from "../../common/WidgetSetupSubHeader";
import { getResourcesExplorerGroupingLabel } from "../../../../../../../resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { selectedViewProvidersSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/common/SelectedViewProvidersSelector";
import { objectToUrl } from "../../../../../../../../utils/helpers/dataToUrlToDataConvertors";
import { costBreakdownWidgetSetupSelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownWidgetSetupSelector";

export const CostBreakdownSetupSubHeader: FC = () => {
  const widgetSetup = useAppSelector(costBreakdownWidgetSetupSelector);
  const viewId = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("viewId"),
  );
  const viewName = useAppSelector((state) =>
    widgetViewNameByViewIdIdSelector(state, viewId),
  );
  const providers = useAppSelector(selectedViewProvidersSelector);

  const groupingLabel = getResourcesExplorerGroupingLabel(
    providers,
    widgetSetup?.grouping ?? "service",
    true,
  );

  const dates = {
    from: widgetSetup?.dates?.from,
    to: widgetSetup?.dates?.to,
    label: widgetSetup?.dates?.label,
  };

  const path = objectToUrl([
    {
      groupedBy: widgetSetup?.grouping,
      groupValues: widgetSetup?.group_values,
      forecastOption: widgetSetup?.forecastOption,
      dates: dates,
      dateGranularity: widgetSetup?.frequency,
      chartType: widgetSetup?.viewType,
      includedFilters: {},
    },
  ]);

  return (
    <WidgetSetupSubHeader
      name={widgetSetup?.name ?? ""}
      icon="pie"
      date={widgetSetup?.date ?? {}}
      title={viewName ?? ""}
      menuTitle="Open in Resource Explorer"
      menuUrl={`/resource-explorer/${viewId}?path=${path}`}
      grouping={groupingLabel}
    />
  );
};
