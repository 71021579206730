import { FC } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useAppAbility } from "../../../../../../../../../../../../../services/permissions";
import { useAppSelector } from "../../../../../../../../../../../../../store/hooks";
import { currentAccountCleanDisabledSelector } from "../../../../../../../../../../../../../store/account/selectors/current-account/currentAccountCleanDisabledSelector";

interface ProtectActionItemProps {
  isProtected: boolean;
  onOpen(): void;
}

export const ProtectActionItem: FC<ProtectActionItemProps> = ({
  onOpen,
  isProtected,
}) => {
  const { cannot } = useAppAbility();
  const canNotCleanResource = cannot("protect", "resource");
  const cleanDisabled = useAppSelector(currentAccountCleanDisabledSelector);

  return (
    <MenuItem
      dense
      onClick={onOpen}
      disabled={cleanDisabled || canNotCleanResource}
    >
      <ListItemIcon>
        {!isProtected ? (
          <LockOutlinedIcon fontSize="small" />
        ) : (
          <LockOpenIcon fontSize="small" />
        )}
      </ListItemIcon>

      <ListItemText>{isProtected ? "Unprotect" : "Protect"}</ListItemText>
    </MenuItem>
  );
};
