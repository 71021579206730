import { FC } from "react";
import { Link, Tooltip, Typography } from "@mui/material";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { Link as RouterLink } from "react-router-dom";
import { UpcomingAction } from "../../../../../../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../../../../../../utils/helpers/date-time/datetime-format";

interface UpcomingActionIconProps {
  upcomingActions?: UpcomingAction[];
}

export const UpcomingActionIcon: FC<UpcomingActionIconProps> = ({
  upcomingActions,
}) => {
  const firstAction = upcomingActions?.at(0);

  if (!firstAction) {
    return null;
  }

  const { action_date, schedule_name, schedule_id, schedule_type } =
    firstAction;

  const type = schedule_type === "off_hours" ? "off-hours" : "classic";
  const typeName = schedule_type === "standard" ? "workflow" : "off-hours";

  const url = `/schedule/${type}/edit/${schedule_id}`;
  const date = formatDate(action_date, { type: "date" });

  const link = (
    <Link
      component={RouterLink}
      fontSize="inherit"
      to={url}
      padding={0}
      color="inherit"
      margin={0}
      target="_blank"
      display="inline-block"
    >
      {schedule_name}
    </Link>
  );

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <Typography fontWeight="medium" variant="caption">
          {link} automation {typeName} will take action on this resource on{" "}
          {date}.
        </Typography>
      }
    >
      <TimerOutlinedIcon fontSize="small" sx={{ color: "grey.600" }} />
    </Tooltip>
  );
};
