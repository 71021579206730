import { coverageDataSelector } from "../../data/coverageDataSelector";
import { utilizationAndCoverageDrawerTargetIdSelector } from "../options/utilizationAndCoverageDrawerTargetIdSelector";
import { RootState } from "../../../../../store";
import { SavingsPlanCoverageDataWithId } from "../../../../../../components/pages/commtiments/utilization-and-coverage/utils/types";

export const selectedCoverageDataSelector = (
  state: RootState,
): SavingsPlanCoverageDataWithId | undefined => {
  const coverageData = coverageDataSelector(state);
  const id = utilizationAndCoverageDrawerTargetIdSelector(state);

  return coverageData?.find((data) => data.id === id);
};
