import { createAsyncThunk } from "@reduxjs/toolkit";
import { filterCostAllocationTagFilterOperatorUpdateThunk } from "./costAllocationTagFilterOperatorUpdateThunk";
import { updateMultiFilterByProvider } from "../../resourceExplorerSlice";
import {
  ResourceExplorerFilterItem,
  ResourceExplorerTag,
  ProviderType,
} from "../../../../services/cloudchipr.api";
import { getResourceExplorerWidgetDataThunk } from "../widgets/getResourceExplorerWidgetDataThunk";
import { RootState } from "../../../store";
import { resourceExplorerFilterByKeyAndProviderSelector } from "../../selectors/filters/resourceExplorerFilterByKeyAndProviderSelector";
import { isDeepEqual } from "../../../../utils/is-deep-equal";
import { getValidatedCostAllocationTags } from "../../../../components/pages/resource-explorer/components/filters/components/filter-items/components/filters/cost-allocation-tag-filter/utils/helpers";

interface ChargeTypeFilterChangeThunkArg {
  filter: ResourceExplorerFilterItem;
  provider: ProviderType;
}

export const costAllocationTagFilterChangeThunk = createAsyncThunk(
  "resourceExplorer/costAllocationTagFilterChange",
  (
    { filter, provider }: ChargeTypeFilterChangeThunkArg,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const currentSavedFilter = resourceExplorerFilterByKeyAndProviderSelector(
      state,
      "cost_allocation_tag",
      provider,
    );

    filter = {
      ...filter,
      value: getValidatedCostAllocationTags(
        filter.value as ResourceExplorerTag[],
      ),
    };

    if (!currentSavedFilter && !filter.value?.length) {
      return;
    }
    const isEqual = filter && isDeepEqual(currentSavedFilter ?? {}, filter);

    dispatch(
      updateMultiFilterByProvider({
        filter,
        provider,
      }),
    );
    if (isEqual) {
      return;
    }

    /**
     * --Temporary hacky solution :) --
     * In case if another provider also has cost_allocation_tag filter applied,
     * we update the combination operator for both of them, as we don't support multi-provider filers.
     * This should be changed (removed) after implementing multi-provider filters functionality
     */

    const oppositeProvider = provider === "aws" ? "gcp" : "aws";

    dispatch(
      filterCostAllocationTagFilterOperatorUpdateThunk({
        provider: oppositeProvider,
        combinationOperator: filter.combination_operator,
      }),
    );

    /**
     * END of hacky solution :)
     */

    dispatch(getResourceExplorerWidgetDataThunk());
  },
);
