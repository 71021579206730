import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dates } from "../../../../../../services/cloudchipr.api";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { getCostBreakdownV2WidgetSetupDataThunk } from "../getCostBreakdownV2WidgetSetupDataThunk";
import { RootState } from "../../../../../store";
import { costBreakdownV2WidgetSetupSelector } from "../../../../selectors/setups/cost-breakdown-v2/costBreakdownV2WidgetSetupSelector";

export const costBreakdownV2DateRangeChangeThunk = createAsyncThunk(
  "dashboards/costBreakdownDateRangeChange",
  async (dates: Dates, { dispatch, getState }) => {
    const state = getState() as RootState;
    const existingSetup = costBreakdownV2WidgetSetupSelector(state);

    if (!existingSetup || !dates) {
      return;
    }
    dispatch(
      setWidgetSetup({
        ...existingSetup,
        dates,
      }),
    );

    dispatch(getCostBreakdownV2WidgetSetupDataThunk());
  },
);
