import { RootState } from "../../../store";
import { CommitmentsRecommendationsPayload } from "../../utils/types/types";
import { commitmentsSliceSelector } from "../commitmentsSliceSelector";
import { commitmentsCurrentOrgIdSelector } from "../commitmentsCurrentOrgIdSelector";

export const commitmentsRecommendationsPayloadDataSelector = (
  state: RootState,
): CommitmentsRecommendationsPayload => {
  const recommendations = commitmentsSliceSelector(state).recommendations;
  const orgId = commitmentsCurrentOrgIdSelector(state);

  return {
    provider_organisation_id: orgId,
    ...recommendations,
  };
};
