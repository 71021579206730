import { FC, memo, RefObject } from "react";
import { Row } from "@tanstack/react-table";
import { ResourcesDataGridSubRowCardView } from "./ResourcesDataGridSubRowCardView";
import { ResourcesDataGridSubRowTableView } from "./ResourcesDataGridSubRowTableView";
import { ResourceEmptyState, SubRowDataGridProps } from "./SubRowDataGridNF";
import { getFirstResourceTypeFromResources } from "../utils/helpers/getFirstResourceTypeFromResources";
import { ResourceDataType } from "../../../../../../../../../../store/account/utils/types/types";
import { nestedResourcesExcludedRowsByParentDataGenerator } from "../utils/data-grid/columns-setup/nestedResourcesExcludedRowsByParentDataGenerator";
import {
  RdsSnapshot,
  RdsSnapshotSource,
} from "../../../../../../../../../../services/cloudchipr.api";
import { dataGridViewTypeByResourceType } from "../utils/data-grid/constants";

interface ResourcesDataGridSubRowProps {
  resource: SubRowDataGridProps["resource"] | null;
  globalFilter?: string;
  parentSource?: string;
  parentContainerRef: RefObject<HTMLTableElement>;
  exportedColumns?: string[];
}

const ResourcesSubRowNF: FC<ResourcesDataGridSubRowProps> = memo(
  ({
    exportedColumns,
    parentContainerRef,
    resource,
    globalFilter,
    parentSource,
  }) => {
    const subRowResourceType = getFirstResourceTypeFromResources(
      resource?.resources,
    );

    if (!subRowResourceType || !resource) {
      return <ResourceEmptyState />;
    }

    const viewType = dataGridViewTypeByResourceType[subRowResourceType];

    if (viewType === "table") {
      return (
        <ResourcesDataGridSubRowTableView
          resource={resource}
          globalFilter={globalFilter}
          parentSource={parentSource ?? ""}
          parentContainerRef={parentContainerRef}
          exportedColumns={exportedColumns}
        />
      );
    }

    if (viewType === "card") {
      return (
        <ResourcesDataGridSubRowCardView
          resource={resource}
          globalFilter={globalFilter}
        />
      );
    }

    return null;
  },
);

export const resourcesDataGridSubRow = (
  row: Row<ResourceDataType>,
  parentContainerRef: RefObject<HTMLTableElement>,
  globalFilter?: string,
) => {
  return (
    <ResourcesSubRowNF
      resource={row.original.child_resource}
      parentSource={row.original.resource.id}
      globalFilter={globalFilter}
      parentContainerRef={parentContainerRef}
      exportedColumns={nestedResourcesExcludedRowsByParentDataGenerator(
        row?.original?.resource_type,
        {
          isAurora: (row?.original?.resource as RdsSnapshot | RdsSnapshotSource)
            ?.is_aurora,
        },
      )}
    />
  );
};
