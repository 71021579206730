import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { setCoverageAndUtilizationDrawerDateGranularity } from "../../../../../../../store/commitments/commitmentsSlice";
import { DateDataPoint } from "../../../../../../../services/cloudchipr.api";
import { CommitmentsDateGranularitySelector } from "../../common/CommitmentsDateGranularitySelector";
import { utilizationAndCoverageDrawerDateGranularitySelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/options/utilizationAndCoverageDrawerDateGranularitySelector";
import { getUtilizationAndCoverageDetailsThunk } from "../../../../../../../store/commitments/thunks/getUtilizationAndCoverageDetailsThunk";

export const CommitmentsDrawerDateGranularity: FC = () => {
  const dispatch = useAppDispatch();

  const dateGranularity = useAppSelector(
    utilizationAndCoverageDrawerDateGranularitySelector,
  );

  const changeHandler = useCallback(
    (dg: DateDataPoint) => {
      dispatch(setCoverageAndUtilizationDrawerDateGranularity(dg));
      dispatch(getUtilizationAndCoverageDetailsThunk());
    },
    [dispatch],
  );

  if (!dateGranularity) {
    return null;
  }

  return (
    <CommitmentsDateGranularitySelector
      size="small"
      onChange={changeHandler}
      dateGranularity={dateGranularity}
    />
  );
};
