import { FC, Fragment, useCallback, useMemo } from "react";
import { Divider, ListSubheader, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CleanActionItem } from "./CleanActionItem";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../../store/hooks";
import {
  CleanActionTypes,
  getResourcesCleanActionTypes,
} from "../../../../../../../../../../../../../utils/clean-options";
import { setCleanActionType } from "../../../../../../../../../../../../../store/clean/cleanSlice";
import { createSingleCleanThunk } from "../../../../../../../../../../../../../store/clean/thunks/createSingleCleanThunk";
import { ResourceType } from "../../../../../../../../../../../../../services/cloudchipr.api";
import { currentAccountVisibilitiesByResourceTypeSelector } from "../../../../../../../../../../../../../store/account/selectors/current-account/currentAccountVisibilitiesByResourceTypeSelector";
import { currentAccountAccessTypeSelector } from "../../../../../../../../../../../../../store/account/selectors/current-account/currentAccountAccessTypeSelector";
import { isResourceNotCleanable } from "../../../../../../../../../../../../../store/clean/utils/helpers";

interface CleanActionItemsProps {
  resourceType: ResourceType;
  id: string;
  isProtected: boolean;
  inProgress?: boolean;
  unavailableActions?: string[];
  closeMenu(): void;
}

export const CleanActionItems: FC<CleanActionItemsProps> = ({
  unavailableActions,
  inProgress,
  isProtected,
  id,
  resourceType,
  closeMenu,
}) => {
  const dispatch = useAppDispatch();

  const visibility = useAppSelector((state) =>
    currentAccountVisibilitiesByResourceTypeSelector(state, resourceType),
  );
  const accessType = useAppSelector(currentAccountAccessTypeSelector);

  const options = useMemo(() => {
    if (!visibility?.actions) {
      return null;
    }

    return getResourcesCleanActionTypes(resourceType).filter((option) => {
      return visibility?.actions?.[option.type] !== null;
    });
  }, [resourceType, visibility]);

  const cleanItemClickHandler = useCallback(
    (actionType: CleanActionTypes) => {
      dispatch(setCleanActionType(actionType));
      dispatch(
        createSingleCleanThunk({
          resourceType,
          resourceId: id,
        }),
      );
      closeMenu();
    },
    [dispatch, resourceType, closeMenu, id],
  );

  if (!options?.length) {
    return null;
  }

  return (
    <Fragment>
      <ListSubheader>
        <Typography fontSize="smaller">
          ACTIONS
          {(accessType === "read" || isProtected) && (
            <Tooltip
              arrow
              title={
                accessType === "read"
                  ? "Please switch to Read/Write access mode to enable actions."
                  : "Actions can not be performed on protected resources."
              }
            >
              <InfoOutlinedIcon
                sx={{
                  verticalAlign: "middle",
                  color: "text.secondary",
                  ml: 1,
                }}
                fontSize="small"
              />
            </Tooltip>
          )}
        </Typography>
      </ListSubheader>

      {options.map((item) => {
        return (
          <CleanActionItem
            key={item.type}
            data={item}
            isDisabled={isResourceNotCleanable(
              {
                isProtected,
                markedForDeletion: !!inProgress,
                notAllowedActions: unavailableActions,
              },
              item.type,
            )}
            resourceType={resourceType}
            onClick={cleanItemClickHandler}
          />
        );
      })}

      <Divider />
    </Fragment>
  );
};
