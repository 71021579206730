import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { getResourcesExplorerGroupingLabel } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { costBreakdownV2WidgetTableDataCountSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetTableDataCountSelector";
import { costBreakdownV2WidgetGroupingSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetGroupingSelector";
import { costBreakdownV2WidgetProvidersSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetProvidersSelector";

interface CostBreakdownWidgetTableViewLabelHeaderCellProps {
  widgetId: string;
}
export const CostBreakdownV2WidgetTableLabelHeaderCell: FC<
  CostBreakdownWidgetTableViewLabelHeaderCellProps
> = ({ widgetId }) => {
  const dataCount = useAppSelector((state) =>
    costBreakdownV2WidgetTableDataCountSelector(state, widgetId),
  );
  const grouping = useAppSelector((state) =>
    costBreakdownV2WidgetGroupingSelector(state, widgetId),
  );
  const providers = useAppSelector((state) =>
    costBreakdownV2WidgetProvidersSelector(state, widgetId),
  );
  if (!grouping) {
    return null;
  }

  return (
    <Stack pl={1}>
      {getResourcesExplorerGroupingLabel(providers, grouping, true)}
      <Typography variant="tiny" color="text.disabled" fontWeight="medium">
        {dataCount}
      </Typography>
    </Stack>
  );
};
