import { Stack, Typography } from "@mui/material";
import { AccountsTableActionCell } from "./AccountsTableActionCell";
import { ProviderAccountNameCell } from "../ProviderAccountNameCell";
import { money } from "../../../../../../utils/numeral/money";
import {
  Account,
  ProviderAccessType,
  AccountStatus,
} from "../../../../../../services/cloudchipr.api";
import { AccountAccessTypeChip } from "../../../../../common/chips/account-access-type-chip/AccountAccessTypeChip";
import { AccountStatusChip } from "../../../../../common/chips/account-status-chip/AccountStatusChip";
import { BarChart } from "../../../../../../storybook/charts/bar-chart/BarChart";
import { ColumnSetupType } from "../../../../../../storybook/data-grid/utils/types/types";
import { AccountInsightsCell } from "../../../../account/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/AccountInsightsCell";
import { nonDetachableAccountStatuses } from "../../../../../../utils/constants/accounts";
import { HeaderCellInfoIcon } from "../../../../account/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/header-cells/HeaderCellInfoIcon";
import { previousMonthSamePeriod } from "../../../utils/helpers/previousMonthSamePeriod";
import { FilterTemplateCell } from "../FilterTemplateCell";
import { MissingPermissionsTooltip } from "../account-card/MissingPermissionsTooltip";

interface GetAccountsTableColumnsArgs {
  includeAccountCardFilterTemplateSection: boolean;
}
export const getAccountsTableColumns = (
  configs: GetAccountsTableColumnsArgs,
): ColumnSetupType<Account>[] => {
  const { includeAccountCardFilterTemplateSection } = configs;
  if (!includeAccountCardFilterTemplateSection) {
    return columns.filter((column) => column?.id !== "filter_template");
  }

  return columns;
};

const columns: ColumnSetupType<Account>[] = [
  {
    accessorKey: "provider_account_id",
    header: "Account",
    cell: ({ row }) => {
      return (
        <ProviderAccountNameCell
          providerId={row.original.provider_account_id}
          providerName={row.original.provider_account_name}
          providerDetails={row.original.provider_details}
        />
      );
    },
  },
  {
    id: "filter_template",
    accessorKey: "id",
    header: "Filter Template",
    cell: ({ row }) => {
      return (
        <FilterTemplateCell
          accountId={row.original.id}
          provider={row.original.type}
          filterSetId={row.original.filter_set_id}
          status={row.original.status}
        />
      );
    },
  },
  {
    id: "chartCell",
    header: "Filtered Resources/Tracked total",
    cell: ({ row }) => {
      const ems = row.original.ems;
      const total = row.original.total_costs;
      return (
        <Stack>
          <Typography variant="body2">
            <Typography variant="inherit" component="span" color="error">
              {money(ems)}
            </Typography>{" "}
            / {money(total)}
          </Typography>
          <BarChart
            partial={ems}
            total={total}
            error={row.original.permission_failed_for_all_resources}
            empty={!ems && !total}
          />
        </Stack>
      );
    },
  },
  {
    accessorKey: "last_month_total_cost",
    header: "Last Month",
    cell: (cell) => (
      <Typography variant="body2">
        {money(cell.getValue() as number)}
      </Typography>
    ),
  },
  {
    id: "last_month_trend",
    header: () => (
      <Stack direction="row" alignItems="center">
        Trend
        <HeaderCellInfoIcon
          title={`Last month comparison (${previousMonthSamePeriod()})`}
        />
      </Stack>
    ),
    cell: ({ row }) => (
      <AccountInsightsCell data={row.original?.billing?.last_month_trend} />
    ),
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: (cell) => {
      return <AccountStatusChip status={cell.getValue() as AccountStatus} />;
    },
  },
  {
    accessorKey: "access_type",
    header: "Access",
    cell: (cell) => {
      const missingPermissions = cell.row.original.missing_permissions;

      return (
        <AccountAccessTypeChip
          accessType={cell.getValue() as ProviderAccessType}
          textType="long"
          warning={!!missingPermissions?.length}
          customWarning={
            <MissingPermissionsTooltip
              missingPermissions={missingPermissions}
            />
          }
          customTooltipStyles={
            missingPermissions?.length ? warningTooltipStyles : undefined
          }
        />
      );
    },
  },
  {
    id: "actionCell",
    header: "",
    cell: ({ row }) => {
      const disableDetach = nonDetachableAccountStatuses.has(
        row.original.status,
      );
      return (
        <AccountsTableActionCell
          accountId={row.original.id}
          provider={row.original.type}
          disableDetach={disableDetach}
          attach={row.original.status === "connected_for_billing"}
          retry={row.original.status === "inactive"}
          accessMode={row.original.access_type}
          subAccount={row.original.is_sub_account}
        />
      );
    },
  },
];

const warningTooltipStyles = {
  color: "text.primary",
  bgcolor: "background.paper",
  "& .MuiTooltip-arrow": {
    color: "background.paper",
  },
  boxShadow: 7,
  p: 0,
};
