import { FC, useCallback, useMemo } from "react";
import { Box } from "@mui/material";
import { Table } from "@tanstack/react-table";
import { IndeterminateCheckbox } from "../../../../../../../../../../../storybook/data-grid/common/IndeterminateCheckbox";
import { ResourceDataType } from "../../../../../../../../../../../store/account/utils/types/types";

interface RowSelectCheckboxHeaderCellProps {
  table: Table<ResourceDataType>;
}

export const RowSelectCheckboxHeaderCell: FC<
  RowSelectCheckboxHeaderCellProps
> = ({ table }) => {
  // todo: use SelectAllHeaderCheckbox here
  const availableRows = table
    .getRowModel()
    .rows.filter(
      (row) =>
        !row.original.resource.marked_for_deletion ||
        row.original.resource?.account?.provider_access_type !== "read",
    );

  const allSelected = useMemo(() => {
    return (
      !!availableRows.length &&
      availableRows.every((row) => row.getIsSelected())
    );
  }, [availableRows]);

  const indeterminate = useMemo(() => {
    return (
      !!availableRows.length && availableRows.some((row) => row.getIsSelected())
    );
  }, [availableRows]);

  const changeHandler = useCallback(() => {
    if (allSelected) {
      table.setRowSelection({});
      return;
    }

    table.setRowSelection(
      availableRows.reduce(
        (acc, row) => {
          acc[row.id] = true;

          return acc;
        },
        {} as Record<string, boolean>,
      ),
    );
  }, [allSelected, table, availableRows]);

  return (
    <Box sx={{ backgroundColor: "transparent" }}>
      <IndeterminateCheckbox
        disabled={!availableRows?.length}
        indeterminate={indeterminate}
        onChange={changeHandler}
        checked={allSelected}
      />
    </Box>
  );
};
