import { FC, useCallback } from "react";
import { MenuItem, Tooltip } from "@mui/material";

interface MoreDateRangeItemProps {
  value: string;
  label: string;
  onClick(value: string): void;
  selected?: boolean;
  tooltip: string;
  disabled?: boolean;
}
export const MoreDateRangePopoverItem: FC<MoreDateRangeItemProps> = ({
  onClick,
  value,
  label,
  selected,
  tooltip,
  disabled,
}) => {
  const dateRangeClickHandler = useCallback(() => {
    onClick(value);
  }, [value, onClick]);

  return (
    <Tooltip title={tooltip} arrow placement="right">
      <MenuItem
        onClick={dateRangeClickHandler}
        value={value}
        selected={selected}
        disabled={disabled}
      >
        {label}
      </MenuItem>
    </Tooltip>
  );
};
