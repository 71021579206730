import { generateLabelByPeriod } from "./period/generateLabelByPeriod";
import { labelByDateLabel } from "../../../date-range-picker/utils/constants/preDefinedRanges";
import { Dates } from "../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";

export const generateLabelByDatesData = (selectedDates: Dates) => {
  const dateLabelFromLabel = selectedDates.label
    ? labelByDateLabel.get(selectedDates.label)?.long
    : null;

  const dateLabelFromPeriod = generateLabelByPeriod(selectedDates);

  let label = dateLabelFromLabel ?? dateLabelFromPeriod;

  if (!label && selectedDates.to && selectedDates.from) {
    const from = formatDate(selectedDates.from, { type: "date" });
    const to = formatDate(selectedDates.to, { type: "date" });

    label = `${from} - ${to}`;
  }

  return label;
};
