import { RootState } from "../../../../store";
import { commitmentsRecommendationsPayloadDataSelector } from "../commitmentsRecommendationsPayloadDataSelector";
import { SavingsPlanAccountScope } from "../../../../../services/cloudchipr.api";

export const commitmentsRecommendationsAccountScopeSelector = (
  state: RootState,
): SavingsPlanAccountScope | undefined => {
  const data = commitmentsRecommendationsPayloadDataSelector(state);

  return data.account_scope;
};
