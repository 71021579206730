import { RootState } from "../../../store";
import { getPreDefinedRanges } from "../../../../components/common/date-range-picker/utils/constants/preDefinedRanges";
import { organisationQuarterStartMonthSelector } from "../org-current-settings/organisationQuarterStartMonthSelector";

export const dateRangesListInDashboardWidgetSelector = (state: RootState) => {
  const data = organisationQuarterStartMonthSelector(state);

  return [
    getPreDefinedRanges(data).daily.past_seven_days,
    getPreDefinedRanges(data).daily.past_fourteen_days,
    getPreDefinedRanges(data).daily.month_to_date,
    getPreDefinedRanges(data).daily.past_thirty_days,
    getPreDefinedRanges(data).daily.past_forty_five_days,
    getPreDefinedRanges(data).daily.past_ninety_days,
    getPreDefinedRanges(data).daily.past_one_hundred_eighty_days,
    getPreDefinedRanges(data).quarterly.quarter_to_date,
    getPreDefinedRanges(data).quarterly.last_quarter,
    getPreDefinedRanges(data).yearly.year_to_date,
    getPreDefinedRanges(data).monthly.last_one_month,
    getPreDefinedRanges(data).monthly.last_three_months,
    getPreDefinedRanges(data).monthly.last_six_months,
    getPreDefinedRanges(data).monthly.last_twelve_months,
  ];
};
