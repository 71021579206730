import { FC } from "react";
import { useEffectOnceWhen } from "rooks";
import {
  PreviewDataGridContent,
  PreviewDataGridContentProps,
} from "./PreviewDataGridContent";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { scheduleResourcesDataSelector } from "../../../../../../../store/schedules/selectors/schedule-resources-preview/scheduleResourcesDataSelector";
import { scheduleResourcesLoadingSelector } from "../../../../../../../store/schedules/selectors/schedule-resources-preview/scheduleResourcesLoadingSelector";
import { getScheduleSelectedResourcesThunk } from "../../../../../../../store/schedules/thunks/getScheduleSelectedResourcesThunk";
import { scheduleResourcesTotalMonthlySpendingSelector } from "../../../../../../../store/schedules/selectors/schedule-resources-preview/selected-resources/scheduleResourcesTotalMonthlySpendingSelector";

type SchedulePreviewDataGridProps = Omit<
  PreviewDataGridContentProps,
  "loading" | "data"
>;

export const SchedulePreviewDataGrid: FC<SchedulePreviewDataGridProps> = (
  props,
) => {
  const dispatch = useAppDispatch();

  const {
    accountIds,
    regions,
    resourceFilter,
    resourceType,
    scheduleId,
    singleGrid,
  } = props;

  const data = useAppSelector((state) =>
    scheduleResourcesDataSelector(state, accountIds, resourceType, scheduleId),
  );
  const totalMonthlySpending = useAppSelector((state) =>
    scheduleResourcesTotalMonthlySpendingSelector(
      state,
      resourceType,
      scheduleId,
    ),
  );
  const loading = useAppSelector((state) =>
    scheduleResourcesLoadingSelector(state, resourceType, scheduleId),
  );

  useEffectOnceWhen(() => {
    if (!resourceFilter) {
      return;
    }

    dispatch(
      getScheduleSelectedResourcesThunk({
        scheduleId,
        accountIds,
        resourceFilters: resourceFilter,
        regions,
      }),
    );
  }, !data && !!resourceFilter);
  return (
    <PreviewDataGridContent
      {...props}
      data={data}
      loading={loading}
      totalMonthlySpending={singleGrid ? undefined : totalMonthlySpending}
    />
  );
};
