import { RootState } from "../../../../../store";
import { commitmentsSliceSelector } from "../../../commitmentsSliceSelector";
import { SavingsPlanCoverageGrouping } from "../../../../../../services/cloudchipr.api";

export const utilizationAndCoverageDrawerCoverageGroupingSelector = (
  state: RootState,
): SavingsPlanCoverageGrouping => {
  const slice = commitmentsSliceSelector(state);

  return slice.coverageAndUtilization.detailsDrawer.coverageGrouping;
};
