import { costBreakdownWidgetByIdSelector } from "./costBreakdownWidgetByIdSelector";
import { RootState } from "../../../../store";
import { ResourceExplorerGrouping } from "../../../../../services/cloudchipr.api";
import { costBreakdownSetupPropertyByKeySelector } from "../../setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";

export const costBreakdownWidgetGroupingSelector = (
  state: RootState,
  widgetId?: string,
): ResourceExplorerGrouping | undefined => {
  let grouping = costBreakdownSetupPropertyByKeySelector("grouping")(state);

  const data = costBreakdownWidgetByIdSelector(state, widgetId);
  if (data) {
    grouping = data?.grouping;
  }
  return grouping;
};
