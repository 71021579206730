import { RootState } from "../../store";
import { AppliedFilterType } from "../filtersSlice";

export const appliedFiltersSelector = (
  state: RootState,
): AppliedFilterType | null => {
  const currentAccount = state.account.id;

  if (!currentAccount) {
    return null;
  }

  return state.filters.appliedFilters[currentAccount];
};
