import { selectedSavingsPlanUUIdSelector } from "./savings-plan/selectedSavingsPlanUUIdSelector";
import { utilizationAndCoverageDrawerDateGranularitySelector } from "./options/utilizationAndCoverageDrawerDateGranularitySelector";
import { utilizationAndCoverageDrawerDatesSelector } from "./options/utilizationAndCoverageDrawerDatesSelector";
import { utilizationAndCoverageDrawerCoverageGroupingSelector } from "./options/utilizationAndCoverageDrawerCoverageGroupingSelector";
import { utilizationAndCoverageDrawerTargetTypeSelector } from "./options/utilizationAndCoverageDrawerTargetTypeSelector";
import { selectedCoverageDataSelector } from "./resource-type/selectedCoverageDataSelector";
import { GetUsersMeOrganisationsCurrentSavingsPlansDataApiArg } from "../../../../../services/cloudchipr.api";
import { commitmentsCurrentOrgIdSelector } from "../../commitmentsCurrentOrgIdSelector";
import { RootState } from "../../../../store";
import { covertDatesToQueryParam } from "../../../../../utils/helpers/covertDatesToQueryParam";

export const utilizationAndCoverageDetailsPayloadSelector = (
  state: RootState,
): GetUsersMeOrganisationsCurrentSavingsPlansDataApiArg | undefined => {
  const providerOrganisationId = commitmentsCurrentOrgIdSelector(state);
  const dates = utilizationAndCoverageDrawerDatesSelector(state);
  const type = utilizationAndCoverageDrawerTargetTypeSelector(state);
  const dataGranularity =
    utilizationAndCoverageDrawerDateGranularitySelector(state);
  const coverageGrouping =
    utilizationAndCoverageDrawerCoverageGroupingSelector(state);

  if (!type) {
    return;
  }

  const payloadData: GetUsersMeOrganisationsCurrentSavingsPlansDataApiArg = {
    type,
    dataGranularity,
    coverageGrouping,
    providerOrganisationId,

    ...covertDatesToQueryParam(dates),
  };

  if (type === "savings_plan") {
    payloadData.savingsPlanId = selectedSavingsPlanUUIdSelector(state);
  }

  if (type === "account") {
    const coverageData = selectedCoverageDataSelector(state);
    payloadData.accountId = coverageData?.account?.id;
  }

  if (type === "resource_type") {
    const coverageData = selectedCoverageDataSelector(state);
    payloadData.resourceType = coverageData?.resource_type?.resource_type;
    payloadData.region = coverageData?.resource_type?.region ?? undefined;
    payloadData.instanceFamily =
      coverageData?.resource_type?.instance_family ?? undefined;
  }

  return payloadData;
};
