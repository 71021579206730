import { ProviderType } from "../../../../services/cloudchipr.api";

export const mergeREViewAndEnabledProviders = (
  enableProvidersSet: Set<ProviderType>,
  providers: ProviderType[],
  isDefault: boolean,
): ProviderType[] => {
  const data = providers.filter((item) => enableProvidersSet.has(item));

  if (isDefault) {
    return Array.from(enableProvidersSet);
  }

  return data.length ? data : Array.from(enableProvidersSet);
};
