import { FC } from "react";
import { getCostBreakdownNumeralItemSecondaryLabel } from "./utils/helpers/getCostBreakdownNumeralItemSecondaryLabel";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { costBreakdownWidgetTableViewDataSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/table/costBreakdownWidgetTableViewDataSelector";
import { costBreakdownGetWidgetDataLoadingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownGetWidgetDataLoadingSelector";
import { WidgetNumeralView } from "../../../../../adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralView";
import { WidgetNumeralViewItem } from "../../../../../adding-widget/widget-create/widget-setups/common/view-type/WidgetNumeralViewItem";
import { costBreakdownWidgetForecastOptionSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownWidgetForecastOptionSelector";
import { costBreakdownWidgetGroupingSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownWidgetGroupingSelector";

interface CostBreakdownWidgetNumeralViewProps {
  widgetId?: string;
}
export const CostBreakdownWidgetNumeralView: FC<
  CostBreakdownWidgetNumeralViewProps
> = ({ widgetId }) => {
  const data = useAppSelector((state) =>
    costBreakdownWidgetTableViewDataSelector(state, widgetId),
  );
  const forecastOption = useAppSelector((state) =>
    costBreakdownWidgetForecastOptionSelector(state, widgetId),
  );

  const loading = useAppSelector(costBreakdownGetWidgetDataLoadingSelector);

  const grouping = useAppSelector((state) =>
    costBreakdownWidgetGroupingSelector(state, widgetId),
  );

  return (
    <WidgetNumeralView loading={loading} skeletonCount={data?.length}>
      {data?.map((item) => {
        const providers = item?.multiProviders ?? [item.cloud_provider];

        const secondaryLabel = getCostBreakdownNumeralItemSecondaryLabel({
          monthlyCost: item?.monthly_forecast?.cost,
          quarterlyCost: item?.quarterly_forecast?.cost,
          grouping,
          forecastOption,
        });

        return (
          <WidgetNumeralViewItem
            key={item.cloud_provider + item.field}
            providers={item.cloud_provider ? providers : []}
            label={item.field}
            cost={item.total_cost}
            trend={item.trend}
            itemsCount={data.length}
            secondaryLabel={secondaryLabel}
          />
        );
      })}
    </WidgetNumeralView>
  );
};
