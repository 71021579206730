import { RootState } from "../../../../../store";
import { ChartColorsType } from "../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { ChartsColors } from "../../../../../../storybook/charts/multi-type-chart/utils/constants/colors";
import {
  costByCategoryChartPrefixes,
  multiStackChartPrefixSeparator,
} from "../../../../../../storybook/charts/multi-type-chart/components/chart-by-type/multi-stack-chart/utils/constants/constants";
import { costByCategoryWidgetDataCategoriesWithoutFiltersSelector } from "../data/costByCategoryWidgetDataCategoriesWithoutFiltersSelector";

export const costByCategoryChartColorsSelector = (
  state: RootState,
  widgetId: string,
  addFilteredResources?: boolean,
): ChartColorsType | undefined => {
  const widget =
    costByCategoryWidgetDataCategoriesWithoutFiltersSelector(state);
  const categories = widget?.map(({ category }) => category);

  if (!categories) {
    return undefined;
  }

  return categories.reduce((acc, category, index) => {
    const primaryPrefix = costByCategoryChartPrefixes.primary;

    const key = !addFilteredResources
      ? category
      : `${primaryPrefix}${multiStackChartPrefixSeparator}${category}`;

    acc[key] = ChartsColors[index];

    return acc;
  }, {} as ChartColorsType);
};
