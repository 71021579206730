import { FC } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { Table } from "@tanstack/react-table";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { GlobalFilter } from "../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { ColumnsSwitcher } from "../../../../../storybook/data-grid/toolbar/ColumnsSwitcher";
import { useMenuHook } from "../../../../../utils/hooks/useMenu.hook";

interface ScheduledJobsToolbarProps {
  globalFilter: string | number;
  setGlobalFilter: (value: string) => void;
  table: Table<any>;
}

export const SchedulesToolbar: FC<ScheduledJobsToolbarProps> = ({
  globalFilter,
  setGlobalFilter,
  table,
}) => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  return (
    <Stack py={1} px={1.5} justifyContent="flex-end" direction="row">
      <GlobalFilter
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        size="xsmall"
      />

      <Tooltip arrow title="Table Settings" placement="top">
        <IconButton onClick={openMenu} size="small" sx={{ ml: 1.5 }}>
          <SettingsOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <ColumnsSwitcher
        open={open}
        onClose={closeMenu}
        anchor={anchor}
        table={table}
      />
    </Stack>
  );
};
