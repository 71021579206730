import { resourceExplorerAppliedFiltersSelector } from "./resourceExplorerAppliedFiltersSelector";
import { resourceExplorerPossibleFiltersByProviderSelector } from "./possible-filters/resourceExplorerPossibleFiltersByProviderSelector";
import { resourceExplorerDimensionSelector } from "./dimension/resourceExplorerDimensionSelector";
import { RootState } from "../../../store";
import {
  ProviderType,
  ResourceExplorerFilterItem,
  ResourceExplorerFilterKeyType,
} from "../../../../services/cloudchipr.api";

export const resourceExplorerAppliedFiltersToSaveOnViewSelector = (
  state: RootState,
): ResourceExplorerFilterItem[] => {
  const appliedFilters = resourceExplorerAppliedFiltersSelector(state);
  const providerTypes = Object.keys(appliedFilters) as ProviderType[];

  const filters = providerTypes.reduce((result, providerType) => {
    const possibleFilters = resourceExplorerPossibleFiltersByProviderSelector(
      state,
      providerType,
    );

    const providerFilterItems = appliedFilters?.[providerType];
    const filterKeys = Object.keys(
      providerFilterItems ?? {},
    ) as ResourceExplorerFilterKeyType[];

    filterKeys.forEach((filterKey) => {
      const appliedFilterValues = providerFilterItems?.[filterKey]?.value;
      const possibleFilterValues = possibleFilters?.[filterKey]?.values;
      const filterItem = providerFilterItems?.[filterKey];

      if (!appliedFilterValues || !possibleFilterValues || !filterItem) {
        return result;
      }

      result.push({
        ...filterItem,
        value:
          appliedFilterValues.length !== possibleFilterValues.length
            ? filterItem.value
            : [],
      });
    });

    return result;
  }, [] as ResourceExplorerFilterItem[]);

  const dimension = resourceExplorerDimensionSelector(state);

  if (dimension?.dimensionId) {
    filters.push({
      key: "dimension_id",
      value: [dimension.dimensionId],
      operator: "in",
      include_values: true,
      combination_operator: "and",
      cloud_provider: "global",
    });

    filters.push({
      key: "category_id",
      value: dimension.categoryIds,
      operator: "in",
      include_values: true,
      combination_operator: "and",
      cloud_provider: "global",
    });
  }

  return filters;
};
