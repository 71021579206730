import { getUsersMeAccountsByAccountIdFilterSetsV2Selector } from "./getUsersMeAccountsByAccountIdFilterSetsV2Selector";
import { RootState } from "../../../store";
import { currentAccountIdSelector } from "../../../account/selectors/current-account/currentAccountIdSelector";

export const filterTemplatesLoadingSelector = (
  state: RootState,
): boolean | undefined => {
  const accountId = currentAccountIdSelector(state);

  if (!accountId) {
    return true;
  }

  return getUsersMeAccountsByAccountIdFilterSetsV2Selector(state, accountId)
    ?.isLoading;
};
