import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useCallback,
  useMemo,
} from "react";
import { Box } from "@mui/material";
import { ResourceExplorerTagFilterItem } from "./filter-item/ResourceExplorerTagFilterItem";
import { ResourceExplorerCostAllocationTagAddButton } from "./ResourceExplorerCostAllocationTagAddButton";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { resourceExplorerPossibleFiltersByProviderSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/filters/possible-filters/resourceExplorerPossibleFiltersByProviderSelector";
import {
  ResourceExplorerFilterItem,
  ResourceExplorerTag,
  ProviderType,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { operatorTypeChanged } from "../../../../../../../../../common/filters/utils/helpers/operatorTypeChanged";

interface ResourceExplorerCostAllocationTagFilterItemsProps {
  filter: ResourceExplorerFilterItem | null;
  setFilter: Dispatch<SetStateAction<ResourceExplorerFilterItem | null>>;
  provider: ProviderType;
  onTagsEnable(): void;
}
export const ResourceExplorerCostAllocationTagFilterItems: FC<
  ResourceExplorerCostAllocationTagFilterItemsProps
> = ({ filter, setFilter, provider, onTagsEnable }) => {
  const possibleTags = useAppSelector((state) =>
    resourceExplorerPossibleFiltersByProviderSelector(state, provider),
  )?.cost_allocation_tag;

  const filterValue = useMemo(() => {
    return (filter?.value as ResourceExplorerTag[])?.map((item, index) => ({
      ...item,
      id: index,
    }));
  }, [filter?.value]);

  const alreadyFilteredTagKeys: string[] = useMemo(() => {
    const arr: string[] = [];
    (filter?.value as ResourceExplorerTag[])?.forEach((item) => {
      if (item?.tag_key) {
        arr.push(item.tag_key);
      }
    });
    return arr;
  }, [filter]);

  const tagRemoveHandler = useCallback(
    (index: number) => {
      setFilter((filter) => {
        if (!filter) {
          return null;
        }
        const data = filter?.value ? [...filter.value] : [];
        data.splice(+index, 1);
        return {
          ...filter,
          value: data,
        };
      });
    },
    [setFilter],
  );

  const tagChangeHandler = useCallback(
    (index: any, tag: any) => {
      setFilter((filter) => {
        if (!filter) {
          return null;
        }
        if (!tag) {
          return filter;
        }
        const items = (filter?.value as ResourceExplorerTag[]) ?? [];
        const updatedList = items?.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              tag_key: tag,
            };
          }
          return {
            ...item,
          };
        });
        return {
          ...filter,
          value: updatedList,
        };
      });
    },
    [setFilter],
  );

  const valueChangeHandler = useCallback(
    (index: number, data: string[] | string) => {
      setFilter((filter) => {
        if (!filter) {
          return null;
        }
        const items = (filter?.value as ResourceExplorerTag[]) ?? [];
        const updatedList = items?.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              tag_values: data,
            };
          }
          return {
            ...item,
          };
        });
        return {
          ...filter,
          value: updatedList,
        };
      });
    },
    [setFilter],
  );

  const operatorChangeHandler = useCallback(
    (index: any, operator: any) => {
      setFilter((filter) => {
        if (!filter) {
          return null;
        }
        const items = (filter?.value as ResourceExplorerTag[]) ?? [];
        const updatedList = items?.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              operator: operator,
              tag_values: operatorTypeChanged(item?.operator, operator)
                ? item.tag_values
                : [],
            };
          }
          return {
            ...item,
          };
        });
        return {
          ...filter,
          value: updatedList,
        };
      });
    },
    [setFilter],
  );

  const combinationOperatorChangeHandler = useCallback(
    (operator: "and" | "or") => {
      setFilter((filter) => {
        if (!filter) {
          return null;
        }
        return {
          ...filter,
          combination_operator: operator,
        };
      });
    },
    [setFilter],
  );

  return (
    <Fragment>
      {filterValue?.map((item, index) => {
        return (
          <ResourceExplorerTagFilterItem
            index={index}
            filter={item}
            key={item.id}
            provider={provider}
            options={possibleTags}
            onTagsEnable={onTagsEnable}
            onTagChange={tagChangeHandler}
            onItemRemove={tagRemoveHandler}
            onValueChange={valueChangeHandler}
            onOperatorChange={operatorChangeHandler}
            alreadyFilteredTagKeys={alreadyFilteredTagKeys}
            combinationOperator={filter?.combination_operator}
            onCombinationOperatorChange={combinationOperatorChangeHandler}
          />
        );
      })}
      <Box p={2}>
        <ResourceExplorerCostAllocationTagAddButton
          filter={filter}
          setFilter={setFilter}
          provider={provider}
        />
      </Box>
    </Fragment>
  );
};
