import { FC } from "react";
import { ListItemText, Stack, Typography } from "@mui/material";
import { ProviderLogo } from "../../provider-logo/ProviderLogo";
import { money } from "../../../../utils/numeral/money";
import { AccountStatusChip } from "../../chips/account-status-chip/AccountStatusChip";
import { AccountAccessTypeChip } from "../../chips/account-access-type-chip/AccountAccessTypeChip";
import { AccountOptionType } from "../utils/types/types";
import { useAppSelector } from "../../../../store/hooks";
import { accountEmsByAccountIdSelector } from "../../../../store/accounts/selectors/costs/accountEmsByAccountIdSelector";
import { accountTotalSpendByAccountIdSelector } from "../../../../store/accounts/selectors/costs/accountTotalSpendByAccountIdSelector";
import { TypographyWithTooltip } from "../../TypographyWithTooltip";

interface AccountOptionLabelProps {
  option: AccountOptionType;
  ems?: boolean;
  total?: boolean;
  showStatus?: boolean;
  showAccountId?: boolean;
}

export const AccountOptionLabel: FC<AccountOptionLabelProps> = ({
  option,
  ems = true,
  total = true,
  showStatus,
  showAccountId,
}) => {
  const emsValue = useAppSelector((state) =>
    accountEmsByAccountIdSelector(state, option.value),
  );
  const totalValue = useAppSelector((state) =>
    accountTotalSpendByAccountIdSelector(state, option.value),
  );

  return (
    <Stack
      spacing={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={1} overflow="hidden">
        {option.accountType && (
          <ProviderLogo provider={option.accountType} width={24} />
        )}

        <ListItemText
          primary={
            <TypographyWithTooltip title={option.label} fontSize="inherit" />
          }
          secondary={
            <Stack component="span">
              <span>
                {ems && (
                  <Typography color="error" variant="caption" component="span">
                    {money(emsValue)}
                  </Typography>
                )}

                {total && (
                  <Typography
                    color="text.secondary"
                    variant="caption"
                    component="span"
                  >
                    {" / "} {money(totalValue)}
                  </Typography>
                )}
              </span>

              {showAccountId &&
                option.providerAccountId &&
                !option.label.includes(option.providerAccountId) && (
                  <TypographyWithTooltip
                    title={`ID: ${option.providerAccountId}`}
                    variant="caption"
                    color="text.disabled"
                  />
                )}

              {option.orgId && option?.groupName?.includes(option.orgId) && (
                <TypographyWithTooltip
                  variant="caption"
                  color="text.disabled"
                  title={`Org ID: ${option.orgId}`}
                />
              )}
            </Stack>
          }
        />
      </Stack>

      <Stack direction="row">
        {showStatus && <AccountStatusChip status={option.status} />}

        {option.accessType && (
          <AccountAccessTypeChip
            accessType={option.accessType}
            warning={option.warning}
          />
        )}
      </Stack>
    </Stack>
  );
};
