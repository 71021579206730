import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCostByCategoryWidgetDataThunk } from "./fetchCostByCategoryWidgetDataThunk";
import { RootState } from "../../../../store";
import { costByCategorySetupSelector } from "../../../selectors/setups/cost-by-category/costByCategorySetupSelector";
import { buildingInProgressWidgetId } from "../utils/constants";

export const fetchCostByCategoryWidgetDataBySetupThunk = createAsyncThunk(
  "dashboards/fetchCostByCategoryWidgetDataBySetupThunk",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;
    const setup = costByCategorySetupSelector(state);

    if (!setup) {
      return;
    }

    await dispatch(
      fetchCostByCategoryWidgetDataThunk({
        dateTo: setup.date.to,
        dateFrom: setup.date.from,
        dateLabel: setup.date.label,
        frequency: setup.frequency,
        id: buildingInProgressWidgetId,
        categoryIds: setup.categoryIds ?? [],
        displayFilteredResources: setup.displayFilteredResources,
      }),
    );
  },
);
