import { createAsyncThunk } from "@reduxjs/toolkit";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { RootState } from "../../../../../store";
import { ResourceExplorerGrouping } from "../../../../../../services/cloudchipr.api";
import { getCostBreakdownV2WidgetSetupDataThunk } from "../getCostBreakdownV2WidgetSetupDataThunk";
import { costBreakdownV2WidgetSetupSelector } from "../../../../selectors/setups/cost-breakdown-v2/costBreakdownV2WidgetSetupSelector";

interface CostBreakdownGroupingChangeThunkArgs {
  grouping: ResourceExplorerGrouping;
  groupValues?: string[];
}
export const costBreakdownV2GroupingChangeThunk = createAsyncThunk(
  "dashboards/costBreakdownGroupingChange",
  async (
    { grouping, groupValues }: CostBreakdownGroupingChangeThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const existingSetup = costBreakdownV2WidgetSetupSelector(state);

    if (!existingSetup) {
      return;
    }
    dispatch(
      setWidgetSetup({
        ...existingSetup,
        grouping,
        group_values: groupValues ?? null,
      }),
    );
    dispatch(getCostBreakdownV2WidgetSetupDataThunk());
  },
);
