import { createAsyncThunk } from "@reduxjs/toolkit";
import { setResourceExplorerPathThunk } from "./setResourceExplorerPathThunk";
import { getResourceExplorerDataThunk } from "../getResourceExplorerDataThunk";
import { RootState } from "../../../../store";
import { setResourceExplorerGrouping } from "../../../resourceExplorerSlice";
import { ResourceExplorerPath } from "../../../utils/types/resourceExplorer";
import { resourceExplorerPathSelector } from "../../../selectors/current-resource-explorer/path/resourceExplorerPathSelector";

export const changePathOnBreadcrumbSelectThunk = createAsyncThunk(
  "resourceExplorer/changePathOnBreadcrumbSelectThunk",
  (index: number, { dispatch, getState }) => {
    const state = getState() as RootState;

    const path = resourceExplorerPathSelector(state);

    const beforeCurrentItems = path.slice(0, index + 1);

    if (!beforeCurrentItems) {
      return;
    }

    dispatch(setResourceExplorerPathThunk(beforeCurrentItems));

    const pathListItem =
      beforeCurrentItems.at(-1) ?? ({} as ResourceExplorerPath);

    dispatch(
      setResourceExplorerGrouping({
        grouping: pathListItem?.groupedBy,
        groupValues: pathListItem?.groupValues,
      }),
    );
    dispatch(
      getResourceExplorerDataThunk({
        filtersFromPath: pathListItem?.includedFilters,
      }),
    );
  },
);
