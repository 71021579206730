import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { RootState } from "../../store";
import { cloudChiprApi, ProviderType } from "../../../services/cloudchipr.api";
import { resourceExplorerAccountFilterIdsSelector } from "../selectors/filters/checkers/resourceExplorerAccountFilterIdsSelector";

interface GetCostAllocationTagValuesThunkArg {
  tagKey: string;
  provider: ProviderType;
}
export const getCostAllocationTagValuesThunk = createAsyncThunk(
  "resourceExplorer/getCostAllocationTagValues",
  async (
    { tagKey, provider }: GetCostAllocationTagValuesThunkArg,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const accountIds = resourceExplorerAccountFilterIdsSelector(
      state,
      provider,
    );

    const {
      getUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderTagValues,
    } = cloudChiprApi.endpoints;

    try {
      return await dispatch(
        getUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderTagValues.initiate(
          {
            tagKey,
            accountIds,
            provider,
          },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error //TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
