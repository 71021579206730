import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Drawer } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useToggle } from "rooks";
import { AccountResourceAreaDrawerContent } from "./AccountResourceAreaDrawerContent";
import { ResourceGroupType } from "../../../../../../utils/constants/resources/resourceGroups";
import { ResourceType } from "../../../../../../services/cloudchipr.api";
import {
  ACCOUNT_DRAWER_ICON_WIDTH,
  ACCOUNT_DRAWER_WIDTH,
} from "../../../../../../utils/constants/style-sizes";

interface AccountResourceAreaDrawerProps {
  resourceGroup: ResourceGroupType[];
  cardItemsRef: Record<string, HTMLElement | null>;
  activeGroupType: ResourceType;
  parentHeight?: number;
}

export const AccountResourceAreaDrawer: FC<AccountResourceAreaDrawerProps> =
  memo(({ resourceGroup, cardItemsRef, activeGroupType, parentHeight }) => {
    const [activeResource, setActiveResource] = useState<
      ResourceType | undefined
    >(undefined);

    const resourceTypes: ResourceType[] = useMemo(() => {
      return (
        resourceGroup.find((item) => item.value === activeGroupType)
          ?.resourceTypes ?? []
      );
    }, [activeGroupType, resourceGroup]);

    const [isOpen, toggleOpen] = useToggle(false);

    const onItemClick = useCallback(
      (key: ResourceType) => {
        if (!cardItemsRef?.[key]) {
          return;
        }

        cardItemsRef[key]?.scrollIntoView({
          behavior: "smooth",
        });
        setActiveResource(key);
      },
      [cardItemsRef],
    );

    useEffect(() => {
      setActiveResource(resourceTypes[0]);
    }, [resourceTypes]);

    return (
      <Box
        display="flex"
        position="sticky"
        top={parentHeight}
        border={0}
        height={`calc(100vh - ${parentHeight}px)`}
        flexGrow={0}
        flexShrink={1}
        flexBasis={
          isOpen
            ? ACCOUNT_DRAWER_WIDTH + ACCOUNT_DRAWER_ICON_WIDTH
            : ACCOUNT_DRAWER_ICON_WIDTH
        }
      >
        <Box sx={drawerIconSx} onClick={toggleOpen}>
          {isOpen ? <NavigateNextIcon /> : <NavigateBeforeIcon />}
        </Box>
        <Drawer
          variant="persistent"
          anchor="right"
          open={isOpen}
          sx={{
            width: ACCOUNT_DRAWER_WIDTH,
            flexShrink: 0,
            display: isOpen ? "inherit" : "none",
            overflow: isOpen ? "hidden" : "visible",
            "& .MuiDrawer-paper": {
              width: ACCOUNT_DRAWER_WIDTH,
              position: "sticky",
            },
          }}
        >
          <Box mt={3}>
            {resourceTypes.map((resourceType) => {
              return (
                <AccountResourceAreaDrawerContent
                  key={resourceType}
                  activeResource={activeResource}
                  onResourceSelect={onItemClick}
                  resourceType={resourceType}
                />
              );
            })}
          </Box>
        </Drawer>
      </Box>
    );
  });

const drawerIconSx = {
  borderTopLeftRadius: 8,
  borderBottomLeftRadius: 8,
  border: "1px solid",
  cursor: "pointer",
  boxShadow: 2,
  mt: 3,
  bgcolor: "white",
  height: 56,
  width: 32,
  py: 2,
  borderRight: 0,
  borderColor: "grey.300",
  display: "flex",
  justifyContent: "center",
};
