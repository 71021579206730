import { FC, Fragment } from "react";
import { Stack, Typography } from "@mui/material";
import { OffHoursRecommendationCreateButton } from "./OffHoursRecommendationCreateButton";
import { RecommendationDismiss } from "../../../common/dismiss/RecommendationDismiss";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";
import { RecommendationDataType } from "../../utils/types/types";
import { RecommendationsTabType } from "../OffHoursRecommendationsContent";
import { RecommendationRestoreButton } from "../../../common/dismiss/RecommendationRestoreButton";

interface OffHoursRecommendationsActionCellProps {
  tab: RecommendationsTabType;
  recommendation: RecommendationDataType;
}

export const OffHoursRecommendationsActionCell: FC<
  OffHoursRecommendationsActionCellProps
> = ({ tab, recommendation }) => {
  const dismissUntilDate = recommendation.is_forever_dismissed
    ? null
    : recommendation.dismiss_until_date;

  return (
    <Stack direction="row" spacing={1}>
      {tab === "active" && (
        <Fragment>
          <OffHoursRecommendationCreateButton data={recommendation} />

          <RecommendationDismiss
            recommendationId={recommendation.id}
            recommendationType="off-hours"
          />
        </Fragment>
      )}

      {tab === "dismissed" && (
        <Stack alignItems="start" spacing={0.5}>
          <RecommendationRestoreButton recommendationId={recommendation.id} />

          {dismissUntilDate && (
            <Typography variant="caption" color="text.secondary">
              Will be restored on{" "}
              <Typography fontWeight="bold" component="span" variant="inherit">
                {formatDate(dismissUntilDate, { type: "date" })}
              </Typography>
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
};
