import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import {
  cloudChiprApi,
  PostV2UsersMeSchedulesApiArg,
} from "../../../services/cloudchipr.api";
import { RootState } from "../../store";
import { schedulesDataSelector } from "../selectors/schedule/schedulesDataSelector";

interface DuplicateOffHourThunkArgs {
  id: string;
  name: string;
}
export const duplicateWorkflowThunk = createAsyncThunk(
  "schedules/duplicateWorkflow",
  async ({ id, name }: DuplicateOffHourThunkArgs, { dispatch, getState }) => {
    const { postV2UsersMeSchedules } = cloudChiprApi.endpoints;
    const state = getState() as RootState;

    const data = schedulesDataSelector(state);

    const workflow = data?.find((item) => item.id === id);

    if (!workflow) {
      return;
    }

    const {
      start_date_time,
      end_date_time,
      cron,
      regions,
      action,
      time_zone,
      min_threshold,
      emails,
      accounts,
      notifications,
      is_snoozed,
      grace_period,
      weekly_schedule_interval,
      daily_schedule_interval,
    } = workflow;
    const accountIds = accounts.map((item) => item.id);

    const body: PostV2UsersMeSchedulesApiArg = {
      body: {
        name,
        start_date_time,
        end_date_time,
        cron,
        regions,
        action,
        notifications,
        time_zone,
        min_threshold,
        is_snoozed,
        grace_period,
        emails,
        weekly_schedule_interval,
        daily_schedule_interval,
        account_ids: accountIds ?? [],
        filter: workflow?.filter ?? [],
        status: "inactive",
      },
    };
    try {
      return await dispatch(
        postV2UsersMeSchedules.initiate(body, {
          fixedCacheKey: id,
        }),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
