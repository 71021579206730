import { DateLabel } from "../../../../../../services/cloudchipr.api";
import { labelByDateLabel } from "../../../../../common/date-range-picker/utils/constants/preDefinedRanges";

export const getLastPastTrendTooltipTitle = (
  currentDatePeriod: string,
  previousDatesPeriod: string,
  dateLabel: DateLabel,
) => {
  const label = labelByDateLabel.get(dateLabel)?.long;
  const withoutPrefix = labelByDateLabel.get(dateLabel)?.withoutPrefix;

  return `${label} (${currentDatePeriod}) compared with the preceding ${withoutPrefix} (${previousDatesPeriod}).`;
};
