import { RootState } from "../../../../../store";
import { commitmentsSliceSelector } from "../../../commitmentsSliceSelector";
import { DateDataPoint } from "../../../../../../services/cloudchipr.api";

export const utilizationAndCoverageDrawerDateGranularitySelector = (
  state: RootState,
): DateDataPoint => {
  const slice = commitmentsSliceSelector(state);

  return slice.coverageAndUtilization.detailsDrawer.dateGranularity;
};
