import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setMultiFilters,
  setResourceExplorerDimension,
} from "../../../resourceExplorerSlice";
import { resourceExplorerGroupByChangeThunk } from "../../resource-explorer/resourceExplorerGroupByChangeThunk";

interface InitiateResourceExplorerThunkArgs {
  dimensionId?: string;
  categoryId?: string;
}

export const setResourceExplorerDimensionFromPathThunk = createAsyncThunk(
  "resourceExplorer/setResourceExplorerDimensionFromPath",
  async (
    { dimensionId, categoryId }: InitiateResourceExplorerThunkArgs,
    { dispatch },
  ) => {
    if (!dimensionId) {
      return;
    }

    dispatch(
      setResourceExplorerDimension({
        dimensionId,
        categoryIds: categoryId ? [categoryId] : [],
      }),
    );

    dispatch(setMultiFilters({}));

    dispatch(resourceExplorerGroupByChangeThunk({ grouping: "category" }));
  },
);
