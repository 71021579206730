import { FC, useCallback } from "react";
import SettingsInputHdmiOutlinedIcon from "@mui/icons-material/SettingsInputHdmiOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Divider, List } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { NavItem } from "../nav-item/NavItem";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";

export const BottomNavigation: FC = () => {
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);
  const navigate = useNavigate();

  const location = useLocation();
  const settingsSelected = location.pathname.startsWith("/settings");

  const handleSettingsClick = useCallback(() => {
    navigate("/settings/profile");
  }, [navigate]);

  return (
    <List dense component="nav">
      <NavItem
        to="/integrations"
        primary="Integrations"
        icon={SettingsInputHdmiOutlinedIcon}
        navCollapsed={navigationCollapsed}
      />
      <NavItem
        to="/execution-log"
        primary="Execution Logs"
        icon={DescriptionOutlinedIcon}
        navCollapsed={navigationCollapsed}
      />
      <Divider sx={{ my: 0.75 }} />
      <NavItem
        onButtonClick={handleSettingsClick}
        primary="Settings"
        icon={SettingsOutlinedIcon}
        navCollapsed={navigationCollapsed}
        selected={settingsSelected}
      />
    </List>
  );
};
