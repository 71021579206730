import { filterTemplateByIdSelector } from "./filterTemplateByIdSelector";
import { RootState } from "../../../store";

export const filterTemplateNameByIdSelector = (
  state: RootState,
  id: string,
): string | undefined => {
  const filterSet = filterTemplateByIdSelector(state, id);

  return filterSet?.name;
};
