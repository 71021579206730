import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { selectedViewSelector } from "./selectedViewSelector";
import { Credits } from "../../../../../services/cloudchipr.api";

export const viewCreditTypeFilterSelector = createDraftSafeSelector(
  [selectedViewSelector],
  (view) => {
    if (!view) {
      return undefined;
    }

    const creditTypeFilter = view.filters.find(
      (filter) => filter.key === "credit_type",
    );

    if (!creditTypeFilter) {
      return undefined;
    }

    return {
      include_values: creditTypeFilter.include_values ?? null,
      credit_types: creditTypeFilter.value,
    } as Credits;
  },
);
