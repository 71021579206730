import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { costBreakdownSetupChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/costBreakdownSetupChangeThunk";
import { resourceExplorerViewByIdLoadingSelector } from "../../../../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import { Dates } from "../../../../../../../../../../services/cloudchipr.api";
import { costBreakdownV2DateRangeChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2DateRangeChangeThunk";
import { WidgetDatePickerVersionsWrapper } from "../../../common/toolbar/WidgetDatePickerVersionsWrapper";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";

export const CostBreakdownV2WidgetSetupDateRangeSelect: FC = () => {
  const dispatch = useAppDispatch();

  const viewId = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("viewId"),
  );
  const setupDate = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("date"),
  );
  const setupDates = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("dates"),
  );
  const dateChangeHandler = useCallback(
    (date: any) => {
      dispatch(costBreakdownSetupChangeThunk({ date }));
    },
    [dispatch],
  );

  const dateRangeChangeHandler = useCallback(
    (dates: Dates) => {
      dispatch(costBreakdownV2DateRangeChangeThunk(dates));
    },
    [dispatch],
  );

  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );

  return (
    <WidgetDatePickerVersionsWrapper
      oldDates={setupDate}
      newDates={setupDates}
      onNewDateChange={dateRangeChangeHandler}
      onOldDateChange={dateChangeHandler}
      disabled={viewGettingLoading}
    />
  );
};
