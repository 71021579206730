import moment from "moment";
import { RootState } from "../../../../../store";
import { utilizationAndCoverageDataViewTabSelector } from "../../view-options/utilizationAndCoverageDataViewTabSelector";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";

export const commitmentsCSVFileNameSelector = (state: RootState) => {
  const tab = utilizationAndCoverageDataViewTabSelector(state);

  if (tab === "coverage") {
    return `${formatDate(moment(), { type: "dateWithComma" })}_Coverage_CSV`;
  }

  if (tab === "savingsPlans") {
    return `${formatDate(moment(), { type: "dateWithComma" })}_Commitments_CSV`;
  }

  return "";
};
