import { RootState } from "../../../../../store";
import { buildingInProgressWidgetId } from "../../../../thunks/widgets/utils/constants";
import { widgetREViewDataLoadingSelector } from "../../common/widget-re-view/widgetREViewDataLoadingSelector";
import { largestCostChangesSetupPropertyByKeySelector } from "../../../setups/larges-cost-changes/largestCostChangesSetupPropertyByKeySelector";

export const largestCostChangesGetWidgetDataLoadingSelector = (
  state: RootState,
  widgetId?: string,
): boolean => {
  const setupViewId =
    largestCostChangesSetupPropertyByKeySelector("viewId")(state);

  const data = setupViewId ? buildingInProgressWidgetId : widgetId;
  return widgetREViewDataLoadingSelector(state, data ?? "");
};
