import { createAsyncThunk } from "@reduxjs/toolkit";
import { setResourceExplorerInitialDataGridViewTypeFromStorageThunk } from "./setResourceExplorerInitialDataGridViewTypeFromStorageThunk";
import { getResourceExplorerViewByIdThunk } from "./getResourceExplorerViewByIdThunk";
import { getResourceExplorerPossibleFiltersThunk } from "./getResourceExplorerPossibleFiltersThunk";
import { setResourceExplorerPathThunk } from "../widgets/path/setResourceExplorerPathThunk";
import { setResourceExplorerDimensionFromPathThunk } from "../filters/dimensions/setResourceExplorerDimensionFromPathThunk";
import { resetResourceExplorer } from "../../resourceExplorerSlice";
import { getAllProvidersBillingStatusThunk } from "../../../common/thunks/billing-status/getAllProvidersBillingStatusThunk";
import { getResourceExplorerDataThunk } from "../widgets/getResourceExplorerDataThunk";
import { resourceExplorerPathLastItemFiltersSelector } from "../../selectors/current-resource-explorer/path/resourceExplorerPathLastItemFiltersSelector";
import { RootState } from "../../../store";

interface InitiateResourceExplorerThunkArgs {
  path: string | null;
  viewId: string;
  dimensionId?: string;
  categoryId?: string;
}

export const initiateResourceExplorerThunk = createAsyncThunk(
  "resourceExplorer/initiateResourceExplorer",
  async (
    {
      path,
      viewId,
      dimensionId,
      categoryId,
    }: InitiateResourceExplorerThunkArgs,
    { dispatch, getState },
  ) => {
    dispatch(resetResourceExplorer());

    dispatch(getAllProvidersBillingStatusThunk());
    dispatch(getResourceExplorerPossibleFiltersThunk({ provider: "global" }));
    await dispatch(getResourceExplorerViewByIdThunk(viewId));

    dispatch(setResourceExplorerPathThunk(!!path));
    dispatch(
      setResourceExplorerDimensionFromPathThunk({
        dimensionId,
        categoryId,
      }),
    );

    dispatch(
      setResourceExplorerInitialDataGridViewTypeFromStorageThunk(viewId),
    );
    const state = getState() as RootState;

    const filtersFromPath = resourceExplorerPathLastItemFiltersSelector(state);
    dispatch(
      getResourceExplorerDataThunk({
        filtersFromPath,
      }),
    );
  },
);
