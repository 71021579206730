import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { getResourceExplorersThunk } from "./getResourceExplorersThunk";
import { getResourceExplorerViewsHierarchyThunk } from "../resource-explorer-hierarchy/getResourceExplorerViewsHierarchyThunk";
import {
  cloudChiprApi,
  ResourceExplorerFilterItem,
  ProviderType,
  ResourceExplorerData,
} from "../../../../services/cloudchipr.api";

export const createResourceExplorerThunkFixedCacheKey =
  "create-resource-explorer";

interface CreateResourceExplorerThunkArgs {
  providers: ProviderType[];
  name: string;
  data?: ResourceExplorerData;
  filters?: ResourceExplorerFilterItem[];
}
export const createResourceExplorerThunk = createAsyncThunk(
  "resourceExplorer/createResourceExplorer",
  async (
    { providers, name, filters, data }: CreateResourceExplorerThunkArgs,
    { dispatch },
  ) => {
    const { postUsersMeOrganisationsCurrentResourceExplorerViews } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        postUsersMeOrganisationsCurrentResourceExplorerViews.initiate(
          {
            body: {
              name: name,
              cloud_provider: providers[0],
              cloud_providers: providers,
              filters: filters ?? [],
              data,
            },
          },
          { fixedCacheKey: createResourceExplorerThunkFixedCacheKey },
        ),
      ).unwrap();

      if (response) {
        dispatch(getResourceExplorersThunk());
        dispatch(getResourceExplorerViewsHierarchyThunk());

        const snackbarId: SnackbarKey = enqueueSnackbar(
          "View successfully created.",
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
              onClose: () => closeSnackbar(snackbarId),
            },
          },
        );
      }

      return response;
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
