import { createAsyncThunk } from "@reduxjs/toolkit";
import { WidgetType } from "../../../../../services/cloudchipr.api";
import { duplicateCostByCategoryWidgetThunk } from "../cost-by-category/duplicateCostByCategoryWidgetThunk";
import { duplicateCostBreakdownWidgetThunk } from "../cost-breakdown/duplicateCostBreakdownWidgetThunk";
import { scrollPageToBottom } from "../../../../../components/pages/utils/helpers/page-scrolling-functions";
import { duplicateCostAndUsageSumWidgetThunk } from "../cost-and-usage/duplicateCostAndUsageSumWidgetThunk";
import { duplicateLargesCostChangesWidgetThunk } from "../largest-cost-changes/duplicateLargesCostChangesWidgetThunk";

interface EditWidgetThunkArgs {
  widgetId: string;
  widgetType: WidgetType;
  widgetName: string;
}

export const duplicateWidgetThunk = createAsyncThunk(
  "dashboards/editWidget",
  async (
    { widgetId, widgetType, widgetName }: EditWidgetThunkArgs,
    { dispatch },
  ) => {
    let response;

    if (widgetType === "costs_by_category_v2") {
      response = await dispatch(
        duplicateCostByCategoryWidgetThunk({ widgetId, widgetName }),
      ).unwrap();
    }

    if (widgetType === "cost_breakdown") {
      response = await dispatch(
        duplicateCostBreakdownWidgetThunk({ widgetId, widgetName }),
      ).unwrap();
    }

    if (widgetType === "largest_cost_changes") {
      response = await dispatch(
        duplicateLargesCostChangesWidgetThunk({ widgetId, widgetName }),
      ).unwrap();
    }

    if (widgetType === "cost_and_usage_summary") {
      response = await dispatch(
        duplicateCostAndUsageSumWidgetThunk({ widgetId, widgetName }),
      ).unwrap();
    }

    if (response?.id) {
      scrollPageToBottom();
    }
  },
);
