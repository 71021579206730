import { Fragment } from "react";
import { GcpMissingApisDialog } from "./components/apis-dialog/GcpMissingApisDialog";
import { GcpMissingRolesDialog } from "./components/roles-dialog/GcpMissingRolesDialog";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import {
  useGetUsersMeProvidersGcpAccountsByAccountIdMissingApisQuery,
  useGetUsersMeProvidersGcpAccountsByAccountIdMissingRolesQuery,
} from "../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../store/hooks";
import { currentAccountIdSelector } from "../../../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import { MissingPermissionsAlertButton } from "../common/MissingPermissionsAlertButton";

export const GcpMissings = () => {
  const currentAccountId = useAppSelector(currentAccountIdSelector);
  const {
    open: rolesOpen,
    openDialog: openRoles,
    closeDialog: closeRoles,
  } = useDialog();
  const {
    open: apisOpen,
    openDialog: openApis,
    closeDialog: closeApis,
  } = useDialog();

  const { data: missingRoles } =
    useGetUsersMeProvidersGcpAccountsByAccountIdMissingRolesQuery(
      { accountId: currentAccountId || "" },
      { skip: !currentAccountId },
    );

  const { data: missingApis } =
    useGetUsersMeProvidersGcpAccountsByAccountIdMissingApisQuery(
      { accountId: currentAccountId || "" },
      { skip: !currentAccountId },
    );

  if (!missingApis?.length && !missingRoles?.length) {
    return null;
  }

  return (
    <Fragment>
      {!!missingRoles?.length && (
        <MissingPermissionsAlertButton
          onOpen={openRoles}
          title="Missing Permissions"
        />
      )}

      {!!missingApis?.length && (
        <MissingPermissionsAlertButton onOpen={openApis} title="Missing APIs" />
      )}

      <GcpMissingRolesDialog open={rolesOpen} onClose={closeRoles} />
      <GcpMissingApisDialog open={apisOpen} onClose={closeApis} />
    </Fragment>
  );
};
