import { FC, useCallback, useMemo } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import {
  ResourceExplorerTag,
  ProviderType,
} from "../../../../../../../../../../../../services/cloudchipr.api";
import { costAllocationTagLabel } from "../../utils/constants";
import { TypographyWithTooltip } from "../../../../../../../../../../../common/TypographyWithTooltip";
import { AutocompleteSelect } from "../../../../../../../../../../../common/select/autocomplete-select/AutocompleteSelect";
import { CostAllocationTagKeyDropdownFooter } from "../tags-enabling/CostAllocationTagKeyDropdownFooter";

interface ResourceExplorerTagKeySelectProps {
  options: {
    value: string;
    title: string | null;
  }[];
  filters?: ResourceExplorerTag;
  onChange: (tag: string) => void;
  alreadyFilteredTagKeys: string[];
  provider: ProviderType;
  onTagsEnable(): void;
}

export const ResourceExplorerTagKeySelect: FC<
  ResourceExplorerTagKeySelectProps
> = ({
  provider,
  alreadyFilteredTagKeys,
  options,
  filters,
  onChange,
  onTagsEnable,
}) => {
  const enableCustomTags = useFlag("EnableCustomTagsEnablementForAWS");

  const dropdownOptions = useMemo(() => {
    return (
      options?.map(({ value, title }) => {
        return {
          value: value,
          label: (
            <TypographyWithTooltip variant="body2" title={title || value} />
          ),
          disabled: !!alreadyFilteredTagKeys?.find(
            (member) => member === value && filters?.tag_key !== member,
          ),
        };
      }) ?? []
    );
  }, [options, alreadyFilteredTagKeys, filters?.tag_key]);

  const submitHandlerOnClose = useCallback(
    (values: string[]) => {
      const value = values[0];

      value && onChange(value);
    },
    [onChange],
  );

  const DropdownFooter: FC = useCallback(() => {
    if (!enableCustomTags || provider !== "aws") {
      return null;
    }

    return <CostAllocationTagKeyDropdownFooter onClick={onTagsEnable} />;
  }, [onTagsEnable, enableCustomTags, provider]);

  return (
    <AutocompleteSelect
      size="xsmall"
      freeSolo
      creatable
      singleSelect
      DropdownFooter={DropdownFooter}
      label={`Select ${costAllocationTagLabel?.[provider]?.short}`}
      placeholder={`Select ${costAllocationTagLabel?.[provider]?.short}`}
      options={dropdownOptions ?? []}
      initialSelectedValues={filters?.tag_key ? [filters.tag_key] : []}
      onChange={submitHandlerOnClose}
      listWidth={500}
    />
  );
};
