import { themeBreakpointsValues } from "../../../../../theme/constants/breakpoints";

export const reactGridLayoutStyles = {
  "&": {
    ".react-grid-item.react-grid-placeholder": {
      backgroundColor: "silver",
    },
    ".react-resizable-handle": {
      width: "30px",
      height: "30px",
    },
    "react-resizable-handle-se": {
      width: "30px",
      height: "30px",
    },
  },
};

export const draggableHandleClassnames = {
  target: "draggableHandle",
  wrapper: ".draggableHandle",
};

export const reactGridLayoutBreakpoints = {
  lg: themeBreakpointsValues.lg,
  md: themeBreakpointsValues.md,
  sm: themeBreakpointsValues.sm,
};

export const reactGridLayoutMargin: [number, number] = [16, 16];

export const reactGridLayoutCols = { lg: 12, md: 10, sm: 6 };
