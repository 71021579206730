import {
  ProviderType,
  ResourceExplorerFilterItem,
  ResourceExplorerFilterKeyType,
} from "../../../../../services/cloudchipr.api";

export const filtersFromViewCombiner = (
  filterKey: ResourceExplorerFilterKeyType,
  filters?: ResourceExplorerFilterItem[],
) => {
  const initialState: Record<ProviderType, string[]> = {
    aws: [],
    gcp: [],
    azure: [],
  };
  if (!filters) {
    return initialState;
  }

  return filters?.reduce((acc, filter) => {
    if (filter.key === filterKey && filter.cloud_provider !== "global") {
      acc[filter.cloud_provider] = filter.value as string[];
    }

    return acc;
  }, initialState);
};
