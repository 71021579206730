import { FC } from "react";
import { Stack, Typography, Chip } from "@mui/material";
import { money } from "../../../../../../../../../utils/numeral/money";

interface ResourceExplorerTableFooterProps {
  count: number;
  gridDataCount: number;
  threshold: number;
  totalCost: number;
}

export const ResourceExplorerTableFooter: FC<
  ResourceExplorerTableFooterProps
> = ({ count, threshold, totalCost, gridDataCount }) => {
  return (
    <Stack
      px={2}
      py={1}
      border={1}
      direction="row"
      alignItems="center"
      borderColor="grey.300"
      borderRadius="0 0 8px 8px"
      justifyContent="space-between"
    >
      <Stack gap={1}>
        <Typography variant="body2">
          Showing{" "}
          <Typography component="span" fontWeight="medium" variant="body2">
            {gridDataCount}
          </Typography>{" "}
          of{" "}
          <Typography component="span" fontWeight="medium" variant="body2">
            {gridDataCount + count}
          </Typography>{" "}
          resources
        </Typography>
        <Chip
          size="xsmall"
          label={`Combined cost of ${count} resources costing less than ${money(
            threshold,
          )} is ${money(totalCost)}.`}
          sx={{
            fontWeight: "medium",
            color: "text.secondary",
            borderRadius: 2,
          }}
        />
      </Stack>
    </Stack>
  );
};
