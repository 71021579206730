import { RootState } from "../../../../store";
import {
  ResourceExplorerFilterItem,
  ProviderType,
} from "../../../../../services/cloudchipr.api";
import { resourceExplorerFilterByKeyAndProviderSelector } from "../resourceExplorerFilterByKeyAndProviderSelector";

// todo: check all selectors in this folder, I can't understand why we have them

export const dbSelectedAccountSelector = (
  state: RootState,
  provider: ProviderType,
): ResourceExplorerFilterItem => {
  const filter = resourceExplorerFilterByKeyAndProviderSelector(
    state,
    "account",
    provider,
  );

  return {
    ...filter,
    cloud_provider: provider,
    operator: filter?.operator ?? "in",
    value: filter?.value ?? [],
    key: "account",
  };
};
