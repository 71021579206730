import { FC, useCallback, useMemo } from "react";
import { PopoverProps, Typography } from "@mui/material";
import { DimensionSelectDropDownFooter } from "./DimensionSelectDropDownFooter";
import { DimensionSelectDropDownTrigger } from "./DimensionSelectDropDownTrigger";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { possibleFilterDimensionsSelector } from "../../../../../../../store/resource-explorer/selectors/filters/possible-filters/global/possibleFilterDimensionsSelector";
import {
  setResourceExplorerDimension,
  setResourceExplorerDimensionId,
} from "../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { getResourceExplorerDataThunk } from "../../../../../../../store/resource-explorer/thunks/widgets/getResourceExplorerDataThunk";
import { resourceExplorerDimensionSelector } from "../../../../../../../store/resource-explorer/selectors/filters/dimension/resourceExplorerDimensionSelector";
import { DropdownSelect } from "../../../../../../common/select/dropdown-select/DropdownSelect";
import { filterByDimensionDropdownFilterFn } from "../../utils/filterByDimensionDropdownFilterFn";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { resourceExplorerGroupByChangeThunk } from "../../../../../../../store/resource-explorer/thunks/resource-explorer/resourceExplorerGroupByChangeThunk";

export const ResourceExplorerDimensionSelect: FC = () => {
  const dispatch = useAppDispatch();
  const dimensionFilters = useAppSelector(possibleFilterDimensionsSelector);
  const selectedDimension = useAppSelector(resourceExplorerDimensionSelector);

  const selectedDimensionId = selectedDimension?.dimensionId;
  const empty = !dimensionFilters?.values?.length;

  const changeHandler = useCallback(
    (selected: string[]) => {
      if (!selected.length) {
        dispatch(setResourceExplorerDimension(null));
        return;
      }

      const id = selected.at(0);

      if (!id || id === selectedDimensionId) {
        return;
      }

      dispatch(setResourceExplorerDimensionId(id));
      dispatch(getResourceExplorerDataThunk());

      if (!selectedDimensionId) {
        dispatch(resourceExplorerGroupByChangeThunk({ grouping: "category" }));
      }
    },
    [dispatch, selectedDimensionId],
  );

  const renderOptions = useMemo(() => {
    return (
      dimensionFilters?.values?.map((dimension) => {
        return {
          value: dimension.value,
          rawValue: dimension,
          label: (
            <TypographyWithTooltip variant="body2" title={dimension.title} />
          ),
        };
      }) ?? []
    );
  }, [dimensionFilters?.values]);

  const noItemsLabel = useMemo(() => noItemsText(empty), [empty]);

  return (
    <DropdownSelect
      label=""
      singleSelect
      options={renderOptions}
      wrapperVariant="popover"
      PopoverProps={popoverProps}
      noItemsLabel={noItemsLabel}
      submitHandlerOnClose={changeHandler}
      filterFn={filterByDimensionDropdownFilterFn}
      DropdownFooter={DimensionSelectDropDownFooter}
      TriggerComponent={DimensionSelectDropDownTrigger}
      values={selectedDimensionId ? [selectedDimensionId] : []}
    />
  );
};

const noItemsText = (empty: boolean) => (keyword: string) => (
  <Typography variant="body2">
    {empty
      ? "You have not created any Dimensions yet."
      : `No dimensions found for “${keyword}”`}
  </Typography>
);

const popoverProps: Partial<PopoverProps> = {
  anchorOrigin: { vertical: "bottom", horizontal: "left" },
  transformOrigin: { vertical: "top", horizontal: "left" },
};
