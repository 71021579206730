import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { getResourceExplorerViewsHierarchyThunk } from "./getResourceExplorerViewsHierarchyThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const createResourceExplorerFolderThunkFixedCacheKey =
  "create-resource-explorer-folder";

export const createResourceExplorerHierarchyFolderThunk = createAsyncThunk(
  "resourceExplorer/createResourceExplorerHierarchyFolder",
  async (name: string, { dispatch }) => {
    const { postUsersMeOrganisationsCurrentResourceExplorerFolders } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        postUsersMeOrganisationsCurrentResourceExplorerFolders.initiate(
          {
            body: {
              name: name,
            },
          },
          {
            fixedCacheKey: createResourceExplorerFolderThunkFixedCacheKey,
          },
        ),
      ).unwrap();

      if (response) {
        dispatch(getResourceExplorerViewsHierarchyThunk());
        const snackbarId: SnackbarKey = enqueueSnackbar(
          "Folder successfully created.",
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
              onClose: () => closeSnackbar(snackbarId),
            },
          },
        );
      }
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
