import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getCurrentAccountFilterTemplatesThunk } from "./getCurrentAccountFilterTemplatesThunk";
import { applyFilterTemplateToCurrentAccount } from "./applyFilterSetToCurrentAccount";
import { RootState } from "../../../store";
import { currentAccountProviderTypeSelector } from "../../../account/selectors/current-account/currentAccountProviderTypeSelector";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { filterTemplateNameByIdSelector } from "../../selectors/filter-set/filterTemplateNameByIdSelector";

export const patchFilterTemplateFromAppliedFiltersThunk = createAsyncThunk(
  "filter/patchFilterTemplateFromAppliedFiltersThunk",
  async (filterTemplateId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const {
      filters: { appliedFilters },
      account: { id },
    } = state;

    const provider = currentAccountProviderTypeSelector(state);
    const filterTemplateName = filterTemplateNameByIdSelector(
      state,
      filterTemplateId,
    );
    const { patchUsersMeOrganisationsCurrentFilterSetsByFilterSetIdV2 } =
      cloudChiprApi.endpoints;

    if (!id || !provider) {
      return;
    }

    const filters = Object.values(appliedFilters[id]);

    if (!filters) {
      return;
    }

    try {
      const response = await dispatch(
        patchUsersMeOrganisationsCurrentFilterSetsByFilterSetIdV2.initiate({
          filterSetId: filterTemplateId,
          body: {
            name: filterTemplateName,
            filters,
            cloud_provider: provider,
          },
        }),
      ).unwrap();

      if (response?.id) {
        await dispatch(applyFilterTemplateToCurrentAccount(response.id));
        await dispatch(getCurrentAccountFilterTemplatesThunk());
      }

      return response;
    } catch (e) {
      // @ts-expect-error //todo: remove this when backend will add types
      enqueueSnackbar(e?.data?.message ?? "Something went wrong", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
