import { FC, useCallback } from "react";
import { ListItem, ListItemButton, ListItemText, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { resourceExplorerGroupingSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { resourceExplorerGroupByChangeThunk } from "../../../../../../../../../../store/resource-explorer/thunks/resource-explorer/resourceExplorerGroupByChangeThunk";
import { resourceExplorerDimensionFilterAppliedSelector } from "../../../../../../../../../../store/resource-explorer/selectors/filters/dimension/resourceExplorerDimensionFilterAppliedSelector";

interface CategoryGroupingOptionProps {
  onClose(): void;
}

//todo: add this into new GroupingsPopover component when Dimensions will be applied on widgets as well
export const CategoryGroupingOption: FC<CategoryGroupingOptionProps> = ({
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const activeGrouping = useAppSelector(resourceExplorerGroupingSelector);
  const dimensionFilterApplied = useAppSelector(
    resourceExplorerDimensionFilterAppliedSelector,
  );

  const groupingItemClickHandler = useCallback(() => {
    if (activeGrouping !== "category") {
      dispatch(resourceExplorerGroupByChangeThunk({ grouping: "category" }));
    }

    onClose?.();
  }, [dispatch, onClose, activeGrouping]);

  return (
    <ListItem
      disablePadding
      secondaryAction={
        dimensionFilterApplied ? undefined : (
          <Tooltip
            arrow
            title="Filter by Dimension to group by Category."
            placement="top"
          >
            <InfoOutlinedIcon color="action" fontSize="small" />
          </Tooltip>
        )
      }
    >
      <ListItemButton
        disabled={!dimensionFilterApplied}
        onClick={groupingItemClickHandler}
        selected={activeGrouping === "category"}
      >
        <ListItemText primary="Category" />
      </ListItemButton>
    </ListItem>
  );
};
