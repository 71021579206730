import { utilizationAndCoverageDetailsDataSelector } from "../data/utilizationAndCoverageDetailsDataSelector";
import { RootState } from "../../../../../store";
import { SavingsPlanUtilizationMetadata } from "../../../../../../services/cloudchipr.api";

export const savingsPlanDetailsMetaDataSelector = (
  state: RootState,
): SavingsPlanUtilizationMetadata | undefined => {
  return utilizationAndCoverageDetailsDataSelector(state)
    ?.metadata as SavingsPlanUtilizationMetadata;
};
