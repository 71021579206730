import { FC, useCallback, useMemo } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { WidgetWithREViewDisabledAction } from "./disabled-actions/WidgetWithREViewDisabledAction";
import { WidgetOptionCard } from "../../WidgetOptionCard";
import Cover from "../../../../utils/assets/images/widget-covers/cost_and_usage.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { initiateCostAndUsageSumWidgetThunk } from "../../../../../../../store/dashboards/thunks/widgets/cost-and-usage/initiateCostAndUsageSumWidgetThunk";
import { isResourceExplorerAvailableSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/checkers/isResourceExplorerAvailableSelector";
import { defaultResourceExplorerViewIdSelector } from "../../../../../../../store/resource-explorer/selectors/all-resource-explorers/defaultResourceExplorerViewIdSelector";

export const CostAndUsageSumCard: FC = () => {
  const dispatch = useAppDispatch();
  const isResourceExplorerAvailable = useAppSelector(
    isResourceExplorerAvailableSelector,
  );
  const enableCostAndUsageSumWidget = useFlag(
    "EnableCostAndUsageSummaryWidget",
  );
  const clickHandler = useCallback(() => {
    dispatch(initiateCostAndUsageSumWidgetThunk());
  }, [dispatch]);

  const defaultViewId = useAppSelector(defaultResourceExplorerViewIdSelector);

  const disabledAction = useMemo(() => {
    if (!isResourceExplorerAvailable || !enableCostAndUsageSumWidget) {
      return (
        <WidgetWithREViewDisabledAction
          viewId={defaultViewId}
          disabled={!enableCostAndUsageSumWidget}
          available={isResourceExplorerAvailable}
        />
      );
    }
  }, [enableCostAndUsageSumWidget, isResourceExplorerAvailable, defaultViewId]);

  return (
    <WidgetOptionCard
      title="Cost and usage summary"
      description="View the overall cost and usage summary based on your preferred Resource Explorer view."
      onClick={clickHandler}
      disabledAction={disabledAction}
    >
      <img src={Cover} alt="ConstAndUsageCard" />
    </WidgetOptionCard>
  );
};
