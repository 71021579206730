import { FC, memo, useCallback } from "react";
import { Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Table } from "@tanstack/react-table";
import { SubRowDataGridNF, SubRowDataGridProps } from "./SubRowDataGridNF";
import { resourcesDataGridSubRow } from "./ResourcesSubRowNF";
import { resourcesDataGridSubRowFooterToolbar } from "./ResourcesDataGridSubRowFooterToolbar";
import { useDataGridContext } from "../../../../../../../../../../storybook/data-grid/DataGridProvider";
import { getFirstResourceTypeFromResources } from "../utils/helpers/getFirstResourceTypeFromResources";
import { nestedGridPageSize } from "../utils/data-grid/constants";

interface ResourcesDataGridSubRowProps {
  resource: SubRowDataGridProps["resource"];
  globalFilter?: string;
}

export const ResourcesDataGridSubRowCardView: FC<ResourcesDataGridSubRowProps> =
  memo(({ resource, globalFilter }) => {
    const {
      rowExpanding: { reEstimateSize },
    } = useDataGridContext();
    const subRowResourceType = getFirstResourceTypeFromResources(
      resource.resources,
    );

    const renderPagination = useCallback(
      (table: Table<any>) => {
        return resourcesDataGridSubRowFooterToolbar(table, reEstimateSize);
      },
      [reEstimateSize],
    );

    if (!subRowResourceType) {
      return null;
    }

    return (
      <Stack mx={3} pt={1}>
        <SubRowDataGridNF
          resourceType={subRowResourceType}
          resource={resource}
          toolbar={false}
          includeHeader={false}
          globalFilter={globalFilter}
          styles={resourcesDataGridSubRowStyles}
          pagination={{
            renderPagination,
            pageSize: nestedGridPageSize,
          }}
          rowExpanding={{
            renderExpandedRowSubRow: resourcesDataGridSubRow,
            parentRowStyles: {
              bgcolor: grey[100],
              "& > td": { p: 0 },
            },
          }}
        />
      </Stack>
    );
  });

const resourcesDataGridSubRowStyles = {
  tableContainer: {
    maxHeight: 1000,
  },
  tableRow: {
    bgcolor: "white",
    height: "40px",
    "& > td:first-of-type": {
      borderLeft: `2px solid ${grey[300]}`,
    },
  },
  table: {
    tableLayout: "initial",
    borderSpacing: "0 16px",
    borderCollapse: "separate",
  },
};
