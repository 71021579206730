import { FC, useCallback } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AccountNavItemStats } from "./AccountNavItemStats";
import { IconByResourceType } from "../../../IconByResourceTypeV2";
import { getResourceTypeName } from "../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { resourceGroupEmsSelector } from "../../../../../../../../store/account/selectors/resource-group-data/resourceGroupEmsSelector";
import { resourceGroupSpendingSelector } from "../../../../../../../../store/account/selectors/resource-group-data/resourceGroupSpendingSelector";
import {
  ProviderType,
  ResourceType,
} from "../../../../../../../../services/cloudchipr.api";
import { useToggledResourceTypes } from "../../../../../utils/hooks/useToggledResources";
import { useAccountPageNavigate } from "../../../utils/hooks/useAccountPageNavigate.hook";
import { TypographyWithTooltip } from "../../../../../../../common/TypographyWithTooltip";
import { useHover } from "../../../../../../../../utils/hooks/useHover.hook";

interface AccountNavItemMainProps {
  resourceType: ResourceType;
  provider: ProviderType;
  selected?: boolean;
  expandable?: boolean;
  expanded?: boolean;
  clickable?: boolean;
  onClick?(): void;
}

export const AccountNavItemMain: FC<AccountNavItemMainProps> = ({
  resourceType,
  provider,
  selected,
  expandable,
  expanded,
  clickable = true,
  onClick,
}) => {
  const handleAccountPageNavigation = useAccountPageNavigate(resourceType);
  const { ref, hovered } = useHover();

  const handleMainItemClick = useCallback(() => {
    if (expandable) {
      onClick?.();
    } else {
      handleAccountPageNavigation();
    }
  }, [expandable, onClick, handleAccountPageNavigation]);

  const toggledResourceTypes = useToggledResourceTypes();

  const ems = useAppSelector((state) =>
    resourceGroupEmsSelector(
      state,
      provider,
      resourceType,
      toggledResourceTypes,
    ),
  );

  const total = useAppSelector((state) =>
    resourceGroupSpendingSelector(
      state,
      provider,
      resourceType,
      toggledResourceTypes,
    ),
  );

  return (
    <ListItem sx={listItemSx}>
      <ListItemButton
        sx={{
          ...listItemButtonSx,
          bgcolor: clickable ? "grey.50" : undefined,
          pointerEvents: !clickable ? "none" : undefined,
        }}
        selected={selected}
        onClick={handleMainItemClick}
        ref={ref}
      >
        <ListItemIcon sx={listItemIconSx}>
          {expandable && hovered ? (
            <ArrowDropDownIcon
              sx={{ transform: expanded ? "rotate(0deg)" : "rotate(-90deg)" }}
            />
          ) : (
            <IconByResourceType
              resourceType={resourceType}
              primary={selected}
            />
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <TypographyWithTooltip
                variant="subtitle2"
                title={getResourceTypeName(resourceType, {
                  service: true,
                  singular: true,
                  type: "abbreviation",
                })}
              />
              <AccountNavItemStats ems={ems} total={total} />
            </Stack>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

const listItemSx = { px: 1, py: 0.25 };
const listItemButtonSx = { borderRadius: 2, pl: 1 };
const listItemIconSx = { minWidth: 32 };
