import { FC } from "react";
import { TooltipProps } from "recharts";
import { Stack, Typography } from "@mui/material";
import moment from "moment";
import { ResourceExplorerCostsDateDetails } from "../../../../../../../../services/cloudchipr.api";
import { ChartsTooltipContentStyles } from "../../../../../../../../storybook/charts/multi-type-chart/components/common/ChartsTooltipContent";
import { getPercentage } from "../../../../../../../../utils/numeral/getPercentage";
import { money } from "../../../../../../../../utils/numeral/money";
import { formatDate } from "../../../../../../../../utils/helpers/date-time/datetime-format";

interface CostBreakdownChartTooltipContentProps extends TooltipProps<any, any> {
  total: number;
  dateDetails?: ResourceExplorerCostsDateDetails;
}

export const CostBreakdownChartTooltipContent: FC<
  CostBreakdownChartTooltipContentProps
> = ({ payload, total, dateDetails }) => {
  const dateTo = formatDate(moment(dateDetails?.to_date), {
    type: "date",
  });
  const dateFrom = formatDate(moment(dateDetails?.from_date), {
    type: "date",
  });

  return (
    <Stack sx={ChartsTooltipContentStyles} borderRadius={1}>
      <Typography variant="caption" pl={0.5} color="text.primary">
        {dateFrom} - {dateTo}
      </Typography>

      {payload?.map((data) => {
        const percentage = getPercentage(data.payload.label, total).toFixed(2);

        return (
          <Stack
            pl={1}
            key={data.name}
            borderLeft={3}
            borderColor={data.payload.fill}
          >
            <Typography variant="caption" color="text.primary">
              {data.name} ({percentage}%)
            </Typography>

            <Typography
              variant="caption"
              fontWeight="bold"
              color="text.primary"
            >
              {money(data.payload.label)}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
};
