import { Fragment } from "react";
import { Bar } from "recharts";
import { red } from "@mui/material/colors";
import { ChartColorsType, ChartDataType } from "../../../../utils/types/types";
import {
  costByCategoryChartPrefixes,
  multiStackChartPrefixSeparator,
} from "../utils/constants/constants";
import { getPercentage } from "../../../../../../../utils/numeral/getPercentage";

export const multiStackChartBars = ({
  keys,
  onMouseOver,
  colors,
  highlighted,
  data,
}: {
  data: ChartDataType[];
  keys: string[];
  highlighted: string;
  onMouseOver: (payload: any) => void;
  colors: ChartColorsType;
}) => {
  const primarySecondaryKeyEntries = keys.reduce(
    (acc, key) => {
      const [prefix, value] = key.split(multiStackChartPrefixSeparator);

      if (prefix === costByCategoryChartPrefixes.primary) {
        acc[key] =
          `${costByCategoryChartPrefixes.secondary}${multiStackChartPrefixSeparator}${value}`;
      } else if (prefix === costByCategoryChartPrefixes.secondary) {
        acc[
          `${costByCategoryChartPrefixes.primary}${multiStackChartPrefixSeparator}${value}`
        ] = key;
      }

      return acc;
    },
    {} as Record<string, string>,
  );

  return Object.entries(primarySecondaryKeyEntries).map(
    ([primaryKey, secondaryKey]) => {
      const filter = highlighted === primaryKey ? "none" : "grayscale(1)";
      const opacity = highlighted === primaryKey ? 1 : 0.2;

      const props = {
        onMouseOver,
        isAnimationActive: false,
        filter: highlighted ? filter : "none",
        opacity: highlighted ? opacity : "none",
      };

      return (
        <Fragment key={primaryKey}>
          <Bar
            unit={primaryKey}
            fill={colors[primaryKey]}
            dataKey={primaryKey}
            xAxisId={costByCategoryChartPrefixes.primary}
            {...props}
          />

          <Bar
            fill={red[500]}
            unit={secondaryKey}
            dataKey={secondaryKey}
            xAxisId={costByCategoryChartPrefixes.secondary}
            label={getCustomLabel(secondaryKey, data)}
            {...props}
          />
        </Fragment>
      );
    },
  );
};

const getCustomLabel = (key: string, data: ChartDataType[]) => (props: any) => {
  const value = Math.round(props.value);
  const barWidth = props.width;
  const symbolWidth = 6.2;

  if (!value || props.height < 15) {
    return <text />;
  }

  const twinKey = key.replace(
    costByCategoryChartPrefixes.secondary,
    costByCategoryChartPrefixes.primary,
  );
  const twinValue = data?.at(props.index)?.[twinKey];
  const percentage = value && twinValue ? getPercentage(value, twinValue) : 0;

  const result = percentage ? `${Math.round(percentage)}%` : "";
  const valueLength = result.length * symbolWidth;

  if (valueLength > barWidth) {
    return <text />;
  }

  const barCenter = barWidth / 2 - valueLength / 2;

  return (
    <text
      x={Math.round(props.x + barCenter)}
      y={props.y + 10}
      fontSize={10}
      fill="white"
    >
      {result}
    </text>
  );
};
