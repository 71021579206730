import { FC } from "react";
import { Button, Link, Stack } from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { CellContext } from "@tanstack/table-core";
import { ProviderLogo } from "../../../../../../../common/provider-logo/ProviderLogo";
import { SavingsPlanRecommendation } from "../../../../../../../../services/cloudchipr.api";

export const CommitmentsRecommendationsDataGridActionCell: FC<
  CellContext<SavingsPlanRecommendation, any>
> = ({ row }) => {
  return (
    <Stack direction="row" spacing={1}>
      <ProviderLogo provider={row.original.account.provider} width="20" />

      <Button
        component={Link}
        href={row.original.action_url}
        target="_blank"
        endIcon={<OpenInNewOutlinedIcon />}
        size="small"
      >
        Purchase
      </Button>
    </Stack>
  );
};
