import { FC, Fragment, useCallback } from "react";
import {
  AlertTitle,
  Box,
  Button,
  CssBaseline,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import C8RLogo from "../../assets/images/cloudchipr-text.svg";

export const WhiteListError: FC = () => {
  const { logout, error } = useAuth0();

  const handleLogoutClick = useCallback(() => {
    logout({ returnTo: window.location.origin });
  }, [logout]);

  return (
    <Fragment>
      <CssBaseline />
      <Box
        sx={{
          py: 2,
          pl: 3,
          borderBottom: 1,
          borderColor: "grey.300",
        }}
      >
        <img src={C8RLogo} alt="Cloudchipr" />
      </Box>
      <Grid container pt={7} justifyContent="center">
        <Grid item>
          <Typography variant="h3" color="primary">
            {error?.message}
          </Typography>
          <Typography variant="h5" my={3}>
            We track these errors automatically, but if the problem persists
            please feel free to contact us.
          </Typography>

          <AlertTitle>
            <Link
              href="mailto:support@cloudchipr.com"
              color="primary"
              underline="hover"
            >
              Contact Us
            </Link>
            <Link href="" color="primary" underline="hover" mx={4}>
              Help center
            </Link>
            <Link href="" color="primary" underline="hover">
              FAQ
            </Link>
          </AlertTitle>
          <Button onClick={handleLogoutClick} variant="outlined" sx={{ mt: 3 }}>
            Back to login
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};
