import { FC, Fragment } from "react";
import { Button, Divider, Link, Stack } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { useAppSelector } from "../../../../../../../store/hooks";
import { possibleFilterDimensionsSelector } from "../../../../../../../store/resource-explorer/selectors/filters/possible-filters/global/possibleFilterDimensionsSelector";

export const DimensionSelectDropDownFooter: FC = () => {
  const dimensionFilters = useAppSelector(possibleFilterDimensionsSelector);
  const empty = !dimensionFilters?.values?.length;

  return (
    <Fragment>
      <Divider />
      <Stack p={1} alignItems="start">
        <Button
          size="small"
          target="_blank"
          component={Link}
          endIcon={<LaunchIcon />}
          href="/settings/dimensions"
          sx={{ textTransform: "none" }}
        >
          {empty ? "Create Dimension" : "Manage Dimensions"}
        </Button>
      </Stack>
    </Fragment>
  );
};
