import { FC, useCallback, useMemo } from "react";
import { Table } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import { useWillUnmount } from "rooks";
import { Stack, Typography } from "@mui/material";
import { rightsizingRecommendationsSubRow } from "./subrow/RightsizingRecommendationsSubRow";
import { rightsizingRecommendationsTableColumns } from "./rightsizingRecommendationsTableColumns";
import { RightsizingRecommendationsTableToolbar } from "./RightsizingRecommendationsTableToolbar";
import { DataGrid } from "../../../../../../../storybook/data-grid/DataGrid";
import { expandRowAfterFirstRender } from "../../../../../../../utils/helpers/expandRowAfterFirstRender";
import { DataGridProvider } from "../../../../../../../storybook/data-grid/DataGridProvider";
import { RightSizingRecommendation } from "../../../../../../../services/cloudchipr.api";
import { RecommendationsTabType } from "../../../off-hours-recommendations/components/OffHoursRecommendationsContent";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { selectedIdsInGridFormatSelector } from "../../../../../../../store/recommendations/selectors/selected-ids/selectedIdsInGridFormatSelector";
import {
  resetDismiss,
  setSelectedIds,
} from "../../../../../../../store/recommendations/recommendationsSlice";
import { rightsizingRecommendationsPageLoadingSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/loading/rightsizingRecommendationsPageLoadingSelector";

interface RightsizingRecommendationsTableProps {
  tab: RecommendationsTabType;
  recommendations: RightSizingRecommendation[];
}

export const RightsizingRecommendationsTable: FC<
  RightsizingRecommendationsTableProps
> = ({ recommendations, tab }) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get?.("id");
  const dispatch = useAppDispatch();

  const selectedIds = useAppSelector(selectedIdsInGridFormatSelector);
  const loading = useAppSelector(rightsizingRecommendationsPageLoadingSelector);

  const columns = useMemo(() => {
    return rightsizingRecommendationsTableColumns(tab);
  }, [tab]);

  //TODO: Deprecated functionality only lived for old urls
  const afterRenderCBFn = useCallback(
    (table: Table<any>) => {
      if (id) {
        return expandRowAfterFirstRender(table, [id]);
      }
    },
    [id],
  );

  const onRowSelectionChange = useCallback(
    (ids: Record<string, boolean>): void => {
      dispatch(setSelectedIds(Object.keys(ids)));
    },
    [dispatch],
  );

  useWillUnmount(() => {
    dispatch(resetDismiss());
  });

  return (
    <DataGridProvider>
      <DataGrid
        styles={styles}
        columns={columns}
        data={recommendations ?? []}
        columnResizeMode="onEnd"
        columnSorting={sortingConfig}
        pagination={paginationConfig}
        afterFirstRenderCallback={
          !loading && recommendations?.length ? afterRenderCBFn : undefined
        }
        rowExpanding={{
          renderExpandedRowSubRow: rightsizingRecommendationsSubRow,
        }}
        rowSelection={{
          initialSelectedItems: selectedIds,
          rowSelectionChange: onRowSelectionChange,
        }}
        toolbar={{
          renderToolbar: (props) => (
            <RightsizingRecommendationsTableToolbar {...props} tab={tab} />
          ),
        }}
      />

      {!loading && !recommendations?.length && (
        <Stack p={2} alignItems="center" justifyContent="center">
          <Typography
            fontWeight="medium"
            variant="subtitle1"
            color="text.secondary"
          >
            No recommendations found
          </Typography>
        </Stack>
      )}
    </DataGridProvider>
  );
};

const styles = {
  tableHeaderRow: {
    "& th:nth-of-type(2)": { pl: 0 },
    "& th": { verticalAlign: "inherit" },
  },
};

const sortingConfig = {
  initialSort: [{ id: "potential_saving", desc: true }],
};

const paginationConfig = {
  pageSize: 10,
};
