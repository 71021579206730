import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { AzNodePool } from "../../../../../../../../../../../../../../services/cloudchipr.api";
import { modeCellParentStyles } from "../../../../../cells/NodeGroupModeChip";

export const azNodePoolColumns: ColumnSetupType<AzNodePool>[] = [
  { accessorKey: "name", header: "Name", type: "noWrapText" },
  {
    id: "cardTableFilteredResults",
    header: "Count",
    type: "cardTableFilteredResults",
  },
  {
    accessorKey: "mode",
    header: "Mode",
    type: "nodeGroupMode",
    meta: {
      cellStyles: modeCellParentStyles,
    },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "nodeGroupTotalCostChip",
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
];
