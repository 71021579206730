import { FC } from "react";
import { Grid, Tooltip } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { money } from "../../../../../utils/numeral/money";
import { StatisticCard } from "../../../../common/statisticCard/StatisticCard";

interface MessageCardsProps {
  ems: number;
  spending: number;
  saving?: number;
}

export const AccountCashFlowMessages: FC<MessageCardsProps> = ({
  ems,
  spending,
  saving,
}) => {
  const enableLifetimeSaving = useFlag("EnableLifetimeSaving");

  const showLifetimeSavingCard = enableLifetimeSaving && !!saving;

  return (
    <Grid container spacing={3}>
      <Grid item xs={!showLifetimeSavingCard ? 6 : 4}>
        <StatisticCard
          title="Live costs for all filtered resources"
          value={money(ems)}
          color="red"
          size="large"
          sx={{
            borderWidth: 4,
          }}
          endAdornment={
            <Tooltip
              arrow
              placement="left"
              title="Monthly prices are calculated based on the on-demand list price of each resource."
            >
              <InfoOutlinedIcon fontSize="small" color="action" />
            </Tooltip>
          }
        />
      </Grid>
      <Grid item xs={!showLifetimeSavingCard ? 6 : 4}>
        <StatisticCard
          title="Live costs for all tracked resources"
          value={money(spending)}
          color="green"
          size="large"
          endAdornment={
            <Tooltip
              arrow
              placement="left"
              title="Monthly prices are calculated based on the on-demand list price of each resource."
            >
              <InfoOutlinedIcon fontSize="small" color="action" />
            </Tooltip>
          }
        />
      </Grid>
      {showLifetimeSavingCard && (
        <Grid item xs={!showLifetimeSavingCard ? 6 : 4}>
          <StatisticCard
            title="Saved with Cloudchipr"
            value={money(saving)}
            color="blue"
            size="large"
            endAdornment={
              <Tooltip
                arrow
                placement="left"
                title="Combined monthly price of all resources terminated by Cloudchipr users and automation workflows."
              >
                <InfoOutlinedIcon fontSize="small" color="action" />
              </Tooltip>
            }
          />
        </Grid>
      )}
    </Grid>
  );
};
