import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

export const currentAccountCleanDisabledSelector = (state: RootState) => {
  const currentAccountId = state.account.id;

  if (!currentAccountId) {
    return true;
  }

  const currentAccount =
    cloudChiprApi.endpoints.getUsersMeAccountsByAccountId.select({
      accountId: currentAccountId,
    })(state).data;

  if (!currentAccount) {
    return true;
  }

  return currentAccount.outdated || currentAccount.access_type !== "read_write";
};
