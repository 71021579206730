import { FC } from "react";
import { Row } from "@tanstack/react-table";
import { IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { ResourceDataType } from "../../../../../../../../../../../store/account/utils/types/types";

interface RowExpandingCellProps {
  row: Row<ResourceDataType>;
}
export const RowExpandingCell: FC<RowExpandingCellProps> = ({ row }) => {
  return (
    <IconButton size="small" onClick={row.getToggleExpandedHandler()}>
      {row.getIsExpanded() ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
    </IconButton>
  );
};
