import { resourceExplorerFilterByKeyAndProviderSelector } from "../resourceExplorerFilterByKeyAndProviderSelector";
import { RootState } from "../../../../store";
import { resourceExplorerPossibleFiltersByProviderSelector } from "../possible-filters/resourceExplorerPossibleFiltersByProviderSelector";
import { ProviderType } from "../../../../../services/cloudchipr.api";

export const resourceExplorerAccountFilterIdsSelector = (
  state: RootState,
  provider: ProviderType,
): string => {
  const possibleAccounts = resourceExplorerPossibleFiltersByProviderSelector(
    state,
    provider,
  )?.account;
  const filter = resourceExplorerFilterByKeyAndProviderSelector(
    state,
    "account",
    provider,
  );

  if (!state?.resourceExplorer.filters?.[provider]?.account) {
    return possibleAccounts?.values.map((item) => item.value).join(",") || "";
  }

  return (filter?.value as string[])?.join(",");
};
