import { FC, useCallback } from "react";
import { ListItemText, MenuItem } from "@mui/material";
import {
  CleanActionTypeDetails,
  CleanActionTypes,
} from "../../../../../../../../../../../../../utils/clean-options";
import { useAppSelector } from "../../../../../../../../../../../../../store/hooks";
import { currentAccountVisibilitiesByResourceTypeSelector } from "../../../../../../../../../../../../../store/account/selectors/current-account/currentAccountVisibilitiesByResourceTypeSelector";
import { ResourceType } from "../../../../../../../../../../../../../services/cloudchipr.api";
import { currentAccountCleanDisabledSelector } from "../../../../../../../../../../../../../store/account/selectors/current-account/currentAccountCleanDisabledSelector";
import { useAppAbility } from "../../../../../../../../../../../../../services/permissions";

interface CleanActionItemProps {
  isDisabled: boolean;
  resourceType: ResourceType;
  data: CleanActionTypeDetails;
  onClick: (actionType: CleanActionTypes) => void;
}

export const CleanActionItem: FC<CleanActionItemProps> = ({
  data,
  onClick,
  resourceType,
  isDisabled,
}) => {
  const { cannot } = useAppAbility();
  const canNotCleanResource = cannot("clean", "resource");
  const cleanDisabled = useAppSelector(currentAccountCleanDisabledSelector);

  const resourceVisibility = useAppSelector((state) =>
    currentAccountVisibilitiesByResourceTypeSelector(state, resourceType),
  );

  const onMenuItemClick = useCallback(() => {
    onClick(data.type);
  }, [data, onClick]);

  const disabled =
    resourceVisibility.actions[data.type] === false ||
    cleanDisabled ||
    canNotCleanResource ||
    isDisabled;

  return (
    <MenuItem
      dense
      onClick={onMenuItemClick}
      key={data.type}
      disabled={disabled}
    >
      <ListItemText>{data.label}</ListItemText>
    </MenuItem>
  );
};
