import { RootState } from "../../../../store";
import { commitmentsRecommendationsPayloadDataSelector } from "../commitmentsRecommendationsPayloadDataSelector";
import { SavingsPlanType } from "../../../../../services/cloudchipr.api";

export const commitmentsRecommendationsSavingsPlanTypeSelector = (
  state: RootState,
): SavingsPlanType | undefined => {
  const data = commitmentsRecommendationsPayloadDataSelector(state);

  return data.savings_plan_type;
};
