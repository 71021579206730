import { costAndUsageSumWidgetDataGridDataSelector } from "./costAndUsageSumWidgetDataGridDataSelector";
import { costAndUsageSumWidgetFiltersByIdSelector } from "./costAndUsageSumWidgetFiltersByIdSelector";
import { RootState } from "../../../../store";
import { buildingInProgressWidgetIdWithNoFilter } from "../../../thunks/widgets/utils/constants";
import { costAndUsageSumSetupPropertyByKeySelector } from "../../setups/cost-and-usage/costAndUsageSumSetupPropertyByKeySelector";
import { CostAndUsageDataGridType } from "../../../../../components/pages/dashboard/components/adding-widget/widget-create/widget-setups/cost-and-usage/content/components/data-grid/utils/types";

export const costAndUSageSumWidgetViewDataSelector = (
  state: RootState,
  widgetId?: string,
): CostAndUsageDataGridType[] | undefined => {
  const data = costAndUsageSumWidgetDataGridDataSelector(
    state,
    widgetId ?? buildingInProgressWidgetIdWithNoFilter,
  );

  let filter = costAndUsageSumSetupPropertyByKeySelector("filter")(state);

  if (widgetId) {
    filter = costAndUsageSumWidgetFiltersByIdSelector(state, widgetId);
  }

  if (!data) {
    return;
  }

  if (!filter?.length) {
    return data;
  }

  return data.filter((item) => {
    return filter?.includes(item.costAndUsageSumWidgetFilterType);
  });
};
