import { createAsyncThunk } from "@reduxjs/toolkit";
import { setResourceExplorerPathThunk } from "./setResourceExplorerPathThunk";
import { getResourceExplorerDataThunk } from "../getResourceExplorerDataThunk";
import { setResourceExplorerGrouping } from "../../../resourceExplorerSlice";
import {
  ProviderType,
  ResourceExplorerGrouping,
  ResourceExplorerTag,
} from "../../../../../services/cloudchipr.api";
import {
  ResourceExplorerPath,
  ResourceExplorerPathFilters,
} from "../../../utils/types/resourceExplorer";
import {
  filterByCurrentGrouping,
  nextGroupingByCurrentGrouping,
  possibleGroupingsByCurrentGrouping,
} from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/constants/groupings";
import { resourceExplorerProvidersSelector } from "../../../selectors/current-resource-explorer/resourceExplorerProvidersSelector";
import { RootState } from "../../../../store";

type Args = {
  pathName: string;
  filterValue?: string;
  currentGroupBy: ResourceExplorerGrouping;
  currentPath: ResourceExplorerPath[];
  aggregated: boolean;
  provider: ProviderType;
};

export const changePathOnRowSelectThunk = createAsyncThunk(
  "resourceExplorer/changePathOnRowSelectThunk",
  (
    {
      filterValue,
      pathName,
      currentPath,
      currentGroupBy,
      aggregated = false,
      provider,
    }: Args,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;

    if (!currentGroupBy) {
      return currentPath;
    }

    const filterKey = filterByCurrentGrouping[currentGroupBy]?.key;
    const filterType = filterByCurrentGrouping[currentGroupBy]?.type;

    const pathListItem = currentPath.at(-1) ?? ({} as ResourceExplorerPath);
    const pathListItemFilters =
      pathListItem?.includedFilters ?? ({} as ResourceExplorerPathFilters);

    const providers = resourceExplorerProvidersSelector(state);
    const includedFilters: ResourceExplorerPathFilters = {
      ...pathListItemFilters,
      cloud_providers: provider ? [provider] : providers,
    };

    if (filterValue && filterKey) {
      const isTagRelated = [
        "cost_allocation_tag_value",
        "cost_allocation_tag",
      ].includes(currentGroupBy);

      if (provider && !includedFilters[provider]) {
        includedFilters[provider] = {};
      }

      if (isTagRelated && filterType === "object") {
        const isTagKeyFilter = currentPath.length > 1;
        const key = isTagKeyFilter ? pathListItem.name : filterValue;
        const value = isTagKeyFilter ? [filterValue] : [];

        let tagFilterItems: ResourceExplorerTag[];

        if (!aggregated) {
          tagFilterItems = [
            {
              tag_key: key,
              tag_values: value,
              operator: isTagKeyFilter ? "in" : "exists",
            },
          ];
        } else {
          tagFilterItems =
            pathListItem.groupValues?.map((key) => ({
              tag_key: key,
              tag_values: [],
              operator: "does_not_exist",
            })) || [];
        }

        includedFilters[provider] = {
          ...includedFilters[provider],
          [filterKey]: { items: tagFilterItems, combination_operator: "and" },
        };
      } else {
        if (provider) {
          includedFilters[provider] = {
            ...includedFilters[provider],
            [filterKey]: filterType === "array" ? [filterValue] : filterValue,
          };
        } else if (filterKey === "category_ids") {
          includedFilters.category_ids = [filterValue];
        }
      }
    }

    const nextGrouping = nextGroupingByCurrentGrouping[currentGroupBy];

    if (!nextGrouping) {
      return currentPath;
    }

    const pathItem: ResourceExplorerPath = {
      name: pathName,
      includedFilters,
      groupedBy: nextGrouping,
      groupingOptions: possibleGroupingsByCurrentGrouping[nextGrouping],
      providers: providers,
    };

    dispatch(setResourceExplorerPathThunk([...currentPath, pathItem]));
    dispatch(setResourceExplorerGrouping({ grouping: nextGrouping }));
    dispatch(
      getResourceExplorerDataThunk({ filtersFromPath: includedFilters }),
    );
  },
);
