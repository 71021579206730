import { createAsyncThunk } from "@reduxjs/toolkit";
import { AccountId, cloudChiprApi } from "../../../services/cloudchipr.api";
import { RootState } from "../../store";
import { currentAccountProviderTypeSelector } from "../selectors/current-account/currentAccountProviderTypeSelector";

export const getCurrentAccountVisibilitiesThunk = createAsyncThunk(
  "getCurrentAccountVisibilitiesThunk",
  async (accountId: AccountId, { getState, dispatch }) => {
    const state = getState() as RootState;

    const provider = currentAccountProviderTypeSelector(state);

    if (!accountId || !provider) {
      return;
    }

    await dispatch(
      cloudChiprApi.endpoints.getUsersMeProvidersByProviderAccountsResourcesVisibilities.initiate(
        {
          provider,
          accountIds: accountId,
        },
      ),
    );
  },
);
