import { FC, useCallback, useMemo } from "react";
import { costAndUsageSumDataGridColumns } from "./columns/costAndUsageSumDataGridColumns";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { DataGrid } from "../../../../../../../../../../../storybook/data-grid/DataGrid";
import { costAndUsageSumSetupPropertyByKeySelector } from "../../../../../../../../../../../store/dashboards/selectors/setups/cost-and-usage/costAndUsageSumSetupPropertyByKeySelector";
import { costAndUsageSumWidgetDataGridDataSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-and-usage/costAndUsageSumWidgetDataGridDataSelector";
import { costAndUsageSumFilterChangeThunk } from "../../../../../../../../../../../store/dashboards/thunks/widgets/cost-and-usage/widget-change/costAndUsageSumFilterChangeThunk";
import { DataGridProvider } from "../../../../../../../../../../../storybook/data-grid/DataGridProvider";
import { widgetDataGridStyle } from "../../../../../../../../utils/constants/stylings";
import { ToolbarConfig } from "../../../../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { WidgetSetupTableToolbar } from "../../../../common/WidgetSetupTableToolbar";

interface CostAndUsageSumWidgetSetupDataGridProps {
  loading: boolean;
}
export const CostAndUsageSumWidgetSetupDataGrid: FC<
  CostAndUsageSumWidgetSetupDataGridProps
> = ({ loading }) => {
  const dispatch = useAppDispatch();

  const filter = useAppSelector(
    costAndUsageSumSetupPropertyByKeySelector("filter"),
  ) as string[];

  const initialSelectedItems = useMemo(() => {
    return filter.reduce((acc, id) => ({ ...acc, [id]: true }), {}) ?? {};
  }, [filter]);

  const gridData = useAppSelector(costAndUsageSumWidgetDataGridDataSelector);

  const onRowSelectionChange = useCallback(
    (rows: Record<string, boolean>) => {
      const filter = Object.keys(rows);
      dispatch(costAndUsageSumFilterChangeThunk(filter));
    },
    [dispatch],
  );

  if (!gridData || loading) {
    return null;
  }

  return (
    <DataGridProvider>
      <DataGrid
        toolbar={toolbar}
        includeHeader={false}
        data={gridData ?? []}
        styles={widgetDataGridStyle}
        columns={costAndUsageSumDataGridColumns}
        rowSelection={{
          initialSelectedItems,
          rowSelectionChange: onRowSelectionChange,
        }}
      />
    </DataGridProvider>
  );
};

const toolbar: ToolbarConfig = {
  renderToolbar: (props) => <WidgetSetupTableToolbar {...props} />,
};
