import { FC, Fragment, useCallback, useEffect } from "react";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { AccountsPageBody } from "./components/accounts/AccountsPageBody";
import { AccountsHeader } from "./components/header/AccountsHeader";
import { noOrgAccountOrgId } from "./utils/helpers/noOrganizationConstants";
import { useAppSelector } from "../../../store/hooks";
import { ProviderType } from "../../../services/cloudchipr.api";
import { noProviderConnectedSelector } from "../../../store/accounts/selectors/noProviderConnectedSelector";
import { initialProviderSelector } from "../../../store/accounts/selectors/initialProviderSelector";
import { filteredAccountsConvertedForProviderAccountsPageByOrgIdSelector } from "../../../store/accounts/selectors/filteredAccountsConvertedForProviderAccountsPageByOrgIdSelector";
import { RootState } from "../../../store/store";
import { allAccountsGroupedByProviderAndOrgSelector } from "../../../store/accounts/selectors/all-providers/allAccountsGroupedByProviderAndOrgSelector";

export const ProviderAccounts: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const accountType = searchParams.get("type");
  const { provider: providerFromUrl, orgId } = useParams<{
    orgId: string;
    provider: ProviderType;
  }>();

  const noProviderConnected = useAppSelector(noProviderConnectedSelector);
  const initialProvider = useAppSelector(initialProviderSelector);
  const allAccountsGroupedByProviderAndOrg = useAppSelector(
    allAccountsGroupedByProviderAndOrgSelector,
  );

  const provider =
    !providerFromUrl && initialProvider ? initialProvider : providerFromUrl;

  const orgIdIsWrong = !!(
    provider &&
    orgId &&
    !allAccountsGroupedByProviderAndOrg?.[provider]?.[orgId]
  );

  const accountsSelector = useCallback(
    (state: RootState) => {
      return filteredAccountsConvertedForProviderAccountsPageByOrgIdSelector(
        state,
        provider,
        orgId,
      );
    },
    [provider, orgId],
  );
  const accounts = useAppSelector(accountsSelector);

  useEffect(() => {
    if (!orgIdIsWrong || !provider || !allAccountsGroupedByProviderAndOrg) {
      return;
    }

    const providerOrgs = Object.keys(
      allAccountsGroupedByProviderAndOrg[provider],
    );

    const firstOrgId = providerOrgs.at(0) ?? noOrgAccountOrgId;

    navigate(`/${provider}/${firstOrgId}/accounts`);
  }, [orgIdIsWrong, navigate, allAccountsGroupedByProviderAndOrg, provider]);

  if (!orgId || !provider || !accounts) {
    return null;
  }

  if (noProviderConnected) {
    return <Navigate to="/account/setup" />;
  }

  return (
    <Fragment>
      <AccountsHeader
        provider={provider}
        orgId={orgId}
        accountsExist={!!(accounts.active?.length || accounts.inactive?.length)}
      />

      {accounts && (
        <AccountsPageBody
          accounts={accounts}
          provider={provider}
          accountsType={accountType}
          key={`${orgId}${accountType}`}
          orgId={orgId}
        />
      )}
    </Fragment>
  );
};
