import { FC, Fragment } from "react";
import { Tooltip } from "@mui/material";
import { ToolbarActionsMenu } from "./ToolbarActionsMenu";
import { ToolbarActionsButton } from "./ToolbarActionsButton";
import { useMenuHook } from "../../../../../../../../../../../utils/hooks/useMenu.hook";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { resourceCleanActionsButtonDisabledReasonSelector } from "../../../../../../../../../../../store/account/selectors/resource-type-data/resourceCleanActionsButtonDisabledReasonSelector";
import { cleanActionDisabledReasonText } from "../../utils/constants/constants";

interface ToolbarActionsProps {
  resourceType: ResourceType;
}

export const ToolbarActions: FC<ToolbarActionsProps> = ({ resourceType }) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const resourceCleanActionsButtonDisabledReason = useAppSelector((state) =>
    resourceCleanActionsButtonDisabledReasonSelector(state, resourceType),
  );

  const title = resourceCleanActionsButtonDisabledReason
    ? cleanActionDisabledReasonText.get(
        resourceCleanActionsButtonDisabledReason,
      )
    : "";
  return (
    <Fragment>
      <Tooltip arrow placement="top" title={title ?? ""}>
        <span>
          <ToolbarActionsButton onClick={openMenu} disabled={!!title} />
        </span>
      </Tooltip>
      <ToolbarActionsMenu
        open={open}
        anchor={anchor}
        onClose={closeMenu}
        resourceType={resourceType}
      />
    </Fragment>
  );
};
