import { cleanResourcesSelector } from "./cleanResourcesSelector";
import { cleanActionTypeSelector } from "./cleanActionTypeSelector";
import { RootState } from "../../store";

export const cleanResourcesGridDataCountSelector = (
  state: RootState,
): number => {
  const resources = cleanResourcesSelector(state) ?? [];
  const cleanActionType = cleanActionTypeSelector(state);

  return resources?.map((r) => {
    return {
      ...r,
      cleanAction: cleanActionType || "delete",
    };
  }).length;
};
