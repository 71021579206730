import { FC, useCallback } from "react";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import {
  useDeleteUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdMutation,
  useGetUsersMeOrganisationsCurrentBudgetsQuery,
} from "../../../../../../services/cloudchipr.api";
import { getResourceExplorersThunk } from "../../../../../../store/resource-explorer/thunks/resource-explorer/getResourceExplorersThunk";
import { DeleteConfirmationDialog } from "../../../../../common/modals/DeleteConfirmationDialog";
import { defaultResourceExplorerViewIdSelector } from "../../../../../../store/resource-explorer/selectors/all-resource-explorers/defaultResourceExplorerViewIdSelector";

interface DeleteActionItemDialogProps {
  id: string;
  open: boolean;
  name: string;
  closeMenu(): void;
}

export const DeleteActionItemDialog: FC<DeleteActionItemDialogProps> = ({
  closeMenu,
  id,
  name,
  open,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { viewId } = useParams<{ viewId: string }>();

  const [removeResourceExplorerView, { isLoading }] =
    useDeleteUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdMutation();
  const { refetch } = useGetUsersMeOrganisationsCurrentBudgetsQuery();

  const defaultResourceExplorerId = useAppSelector(
    defaultResourceExplorerViewIdSelector,
  );

  const removeResourceExplorerViewHandler = useCallback(async () => {
    try {
      await removeResourceExplorerView({
        resourceExplorerViewId: id,
      }).unwrap();

      const snackbarId: SnackbarKey = enqueueSnackbar(
        "View successfully deleted.",
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
            onClose: () => closeSnackbar(snackbarId),
          },
        },
      );

      dispatch(getResourceExplorersThunk());

      if (viewId === id) {
        navigate({
          pathname: `/resource-explorer/${defaultResourceExplorerId}`,
        });
      }

      refetch();
    } catch (e) {
      // @ts-expect-error //TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  }, [
    refetch,
    dispatch,
    navigate,
    viewId,
    id,
    defaultResourceExplorerId,
    removeResourceExplorerView,
  ]);

  const confirmRemoveViewHandler = useCallback(async () => {
    await removeResourceExplorerViewHandler();
    closeMenu();
  }, [closeMenu, removeResourceExplorerViewHandler]);

  return (
    <DeleteConfirmationDialog
      open={open}
      title="Delete View?"
      onClose={closeMenu}
      onDelete={confirmRemoveViewHandler}
      loading={isLoading}
      content={
        <Typography>
          Please confirm if you want to delete
          <Typography component="span" fontWeight="bold">
            {" "}
            {name}{" "}
          </Typography>
          view.
        </Typography>
      }
    />
  );
};
