import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { getDashboardsHierarchyThunk } from "./getDashboardsHierarchyThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

interface UpdateDashboardHierarchyThunkArg {
  id: string;
  folderId?: string;
  index?: number;
}

export const updateDashboardHierarchyThunk = createAsyncThunk(
  "dashboard/updateDashboardHierarchy",
  async (
    { id, folderId, index }: UpdateDashboardHierarchyThunkArg,
    { dispatch },
  ) => {
    const { patchUsersMeOrganisationsCurrentDashboardHierarchy } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        patchUsersMeOrganisationsCurrentDashboardHierarchy.initiate({
          body: {
            id,
            parent_id: folderId,
            index,
          },
        }),
      ).unwrap();
      dispatch(getDashboardsHierarchyThunk());
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
