import { FC, useCallback } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { Integration as IntegrationSchema } from "../../../../../../../../services/cloudchipr.api";
import { IntegrationLogo } from "../../../../../../../common/integration-dialogs/components/common/IntegrationLogo";

export type IntegrationType = IntegrationSchema["type"];

interface IntegrationProps {
  type: IntegrationType;
  selected: boolean;
  onClick(type: IntegrationType): void;
  disabled: boolean;
}

export const IntegrationTypeCard: FC<IntegrationProps> = ({
  type,
  selected,
  onClick,
  disabled,
}) => {
  const clickHandler = useCallback(() => {
    onClick(type);
  }, [onClick, type]);

  return (
    <Card
      variant="outlined"
      sx={{
        flex: 1,
        borderWidth: selected ? 2 : 1,
        borderColor: selected ? "primary.main" : "",
        opacity: selected || !disabled ? 1 : 0.4,
      }}
    >
      <CardActionArea onClick={clickHandler} disabled={disabled}>
        <CardContent sx={{ py: 0 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            p={3}
          >
            <IntegrationLogo type={type} width={32} />
            <Typography variant="body1" textTransform="capitalize" ml={1}>
              {type}
            </Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
