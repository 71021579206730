import { FC, Fragment } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Box, Card, Popover, Stack, Typography } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useMenuHook } from "../../../../../../../../../../../utils/hooks/useMenu.hook";

interface ProtectionProps {
  protectedBy: string | null;
}

export const Protection: FC<ProtectionProps> = ({ protectedBy }) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const icon = <LockOutlinedIcon fontSize="small" sx={{ color: "grey.600" }} />;

  if (!protectedBy) {
    return icon;
  }

  return (
    <Fragment>
      <Box fontSize={0} onMouseEnter={openMenu} onMouseLeave={closeMenu}>
        {icon}
      </Box>

      <Popover
        sx={{ pointerEvents: "none", mr: 4, pr: 4 }}
        open={open}
        anchorEl={anchor}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        onClose={closeMenu}
        disableRestoreFocus
        PaperProps={{
          sx: {
            bgcolor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Stack position="relative" p={1} pr={1.5}>
          <Card elevation={3} sx={{ px: 1.5, py: 1 }}>
            <PlayArrowIcon
              sx={{
                right: -4,
                top: "50%",
                color: "white",
                position: "absolute",
                transform: "translate(0, -50%)",
              }}
              fontSize="medium"
            />

            <Typography variant="caption" color="text.secondary">
              Protection enabled by
            </Typography>
            <Typography variant="body2" fontWeight="medium">
              {protectedBy}
            </Typography>
          </Card>
        </Stack>
      </Popover>
    </Fragment>
  );
};
