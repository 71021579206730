import { costBreakdownV2WidgetSetupSelector } from "./costBreakdownV2WidgetSetupSelector";
import { RootState } from "../../../../store";
import { CostBreakdownWidgetSetupType } from "../../../utils/types/setups/costBreakdownSetupType";

export const costBreakdownV2SetupPropertyByKeySelector =
  (key: keyof CostBreakdownWidgetSetupType) =>
  (state: RootState): any => {
    const setup = costBreakdownV2WidgetSetupSelector(state);

    if (!setup) {
      return null;
    }

    return setup[key];
  };
