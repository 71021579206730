export const autocompleteFilterTypes: Set<string> = new Set([
  "resource_group",
  "creator",
  "status",
  "capacity_mode",
  "instance_type",
  "node_type",
  "storage_type",
  "instance_family",
  "provider_unique_identifier",
  "type",
  "name",
  "snapshot_id",
  "source_name",
  "storage_tier",
  "volume_id",
  "snapshot_status",
  "table",
  "protected_by",
  "ip_addresses",
  "vpc",
  "nat_gateway_id",
  "capacity_provider",
]);
