import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { getDashboardsHierarchyThunk } from "./getDashboardsHierarchyThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { createDashboardFixedCacheKey } from "../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";

export const createDashboardHierarchyItemThunk = createAsyncThunk(
  "dashboard/createDashboardHierarchyItem",
  async (name: string, { dispatch }) => {
    const { postUsersMeOrganisationsCurrentDashboards } =
      cloudChiprApi.endpoints;
    let response;

    try {
      response = await dispatch(
        postUsersMeOrganisationsCurrentDashboards.initiate(
          {
            body: {
              name: name,
            },
          },
          { fixedCacheKey: createDashboardFixedCacheKey },
        ),
      ).unwrap();

      if (response) {
        dispatch(getDashboardsHierarchyThunk());

        const snackbarId: SnackbarKey = enqueueSnackbar(
          "Dashboard successfully created.",
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
              onClose: () => closeSnackbar(snackbarId),
            },
          },
        );
      }
      return response;
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
