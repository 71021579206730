import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import { largestCostChangesWidgetByIdSelector } from "../../../selectors/widgets/largest-cost-changes/largestCostChangesWidgetByIdSelector";
import { createWidgetThunk } from "../common/createWidgetThunk";
import { CreateDashboardWidgetRequestType } from "../../../utils/types/types";
import { openWidgetBuildingSnackbar } from "../utils/openWidgetBuildingSnackbar";

export const duplicateLargesCostChangesWidgetThunk = createAsyncThunk(
  "dashboards/duplicateLargesCostChangesWidget",
  async (
    { widgetId, widgetName }: { widgetId: string; widgetName: string },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const widget = largestCostChangesWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    const response = await dispatch(
      createWidgetThunk({
        ...widget,
        name: widgetName,
      } as CreateDashboardWidgetRequestType),
    ).unwrap();
    if (response) {
      openWidgetBuildingSnackbar(widget.type, "duplicate");
    }

    return response;
  },
);
