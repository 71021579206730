import { resourceExplorerAllPossibleInitialFiltersSelector } from "../filters/possible-filters/resourceExplorerAllPossibleInitialFiltersSelector";
import { RootState } from "../../../store";
import { ProviderType } from "../../../../services/cloudchipr.api";
import { billingAccountDisabledReasonsByProviderSelector } from "../../../common/selectors/billing-status/billingAccountDisabledReasonsByProviderSelector";
import { getAccountsFilteredByCategoryList } from "../../utils/helpers/getAccountsFilteredByCategoryList";
import { categoryAccountsByCategoryIdSelector } from "../../../categories/selectors/categoryAccountsByCategoryIdSelector";

export const groupedCategoryAccountsByAccountFilterSelector = (
  state: RootState,
  categoryId?: string,
) => {
  if (!categoryId) {
    return;
  }

  const accounts = categoryAccountsByCategoryIdSelector(state, categoryId);

  const possibleFilters =
    resourceExplorerAllPossibleInitialFiltersSelector(state);

  const accountsByProvider = accounts?.reduce(
    (result, account) => {
      const provider = account.provider;
      const id = account?.billing_account_id ?? account.id;
      const name = account.provider_account_name ?? account.provider_account_id;
      const newAccountObj = {
        id,
        name,
      };
      if (result?.[provider]) {
        result[provider].push(newAccountObj);
      } else {
        result = {
          ...result,
          [provider]: [newAccountObj],
        };
      }

      return result;
    },
    {} as Record<ProviderType, { id: string; name: string }[]>,
  );

  const providers = (
    accountsByProvider ? Object.keys(accountsByProvider) : []
  ) as ProviderType[];

  return providers.reduce(
    (parentResult, provider) => {
      const possAccountFilters = possibleFilters?.[provider]?.find(
        (item) => item.key === "account",
      );
      const disabled = billingAccountDisabledReasonsByProviderSelector(
        state,
        provider,
      );

      const filteredAccounts = getAccountsFilteredByCategoryList({
        provider,
        disabled,
        possAccountFilters,
        accounts: accountsByProvider?.[provider] ?? [],
      });

      if (filteredAccounts?.prefillingAccounts?.length) {
        const accountData = parentResult?.includedAccounts?.[provider];

        if (accountData) {
          parentResult.includedAccounts[provider] = accountData.concat(
            filteredAccounts?.prefillingAccounts,
          );
        } else {
          parentResult.includedAccounts[provider] =
            filteredAccounts?.prefillingAccounts;
        }
      }

      if (filteredAccounts?.excludedAccounts?.length) {
        parentResult = {
          ...parentResult,
          excludedAccounts: parentResult.excludedAccounts.concat(
            filteredAccounts.excludedAccounts,
          ),
        };
      }
      return parentResult;
    },
    { excludedAccounts: [], includedAccounts: {} } as {
      excludedAccounts: { provider: ProviderType; id: string; name: string }[];
      includedAccounts: Partial<Record<ProviderType, string[]>>;
    },
  );
};
