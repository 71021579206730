import { resourceExplorerProvidersSelector } from "../resourceExplorerProvidersSelector";
import { RootState } from "../../../../store";

export const isResourceExplorerViewMultiCloudSelector = (
  state: RootState,
): boolean => {
  const providers = resourceExplorerProvidersSelector(state);

  return providers.length > 1;
};
