import { getWidgetDataByViewIdSelector } from "./getWidgetDataByViewIdSelector";
import { RootState } from "../../../../../store";
import { getFetchWidgetViewDataThunkFixedCacheKey } from "../../../../utils/helpers/fixedCahcheKeys";
import { PostUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse } from "../../../../../../services/cloudchipr.api";

export const widgetREViewDataSelector = (
  state: RootState,
  widgetId?: string,
):
  | PostUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse
  | undefined => {
  const response = getWidgetDataByViewIdSelector(
    getFetchWidgetViewDataThunkFixedCacheKey(widgetId),
  )(state);

  return response?.data;
};
