import { postUsersMeAccountsByAccountIdFiltersV2Selector } from "./postUsersMeAccountsByAccountIdFiltersV2Selector";
import { RootState } from "../../../store";

export const applyFilterSetLoadingSelector = (
  state: RootState,
  filterSetId: string,
): boolean | undefined => {
  return postUsersMeAccountsByAccountIdFiltersV2Selector(state, filterSetId)
    ?.isLoading;
};
