import { RootState } from "../../../../store";
import { widgetREViewDataLoadingSelector } from "../common/widget-re-view/widgetREViewDataLoadingSelector";
import { costBreakdownSetupPropertyByKeySelector } from "../../setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { buildingInProgressWidgetId } from "../../../thunks/widgets/utils/constants";

export const costBreakdownGetWidgetDataLoadingSelector = (
  state: RootState,
  widgetId?: string,
): boolean => {
  const setupViewId = costBreakdownSetupPropertyByKeySelector("viewId")(state);

  const data = setupViewId ? buildingInProgressWidgetId : widgetId;
  return widgetREViewDataLoadingSelector(state, data ?? "");
};
