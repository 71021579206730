import { FC, memo } from "react";
import { IconButton } from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { SortDirection } from "@tanstack/react-table";

interface TableHeaderCellSortIconsProps {
  sortedBy: false | SortDirection;
  canSort: boolean;
  hover: boolean;
  onClick?(event: unknown): void;
}

export const TableHeaderCellSortIcons: FC<TableHeaderCellSortIconsProps> = memo(
  ({ canSort, sortedBy, hover, onClick }) => {
    if (!canSort) {
      return null;
    }

    return (
      <IconButton sx={{ ml: 1, p: 0.25 }} size="small" onClick={onClick}>
        {!sortedBy && (
          <ImportExportIcon
            color="disabled"
            fontSize="small"
            opacity={canSort && hover ? 1 : 0}
          />
        )}

        {sortedBy === "asc" && (
          <ArrowDownwardIcon color="disabled" fontSize="small" />
        )}
        {sortedBy === "desc" && (
          <ArrowUpwardIcon color="disabled" fontSize="small" />
        )}
      </IconButton>
    );
  },
);
