export const commitmentTypesLabels = {
  savings_plan: "Savings Plan",
};

export const savingsPlansTypeLabels = {
  sage_maker: "SageMaker",
  compute: "Compute",
  ec2_instance: "EC2",
};

export const savingsPlansResourceTypeNames = {
  ec2: "EC2",
  lambda: "Lambda",
  fargate: "Fargate",
  sagemaker: "SageMaker",
  eks: "EKS",
};

export const savingsPlansPaymentOptionLabels = {
  no_upfront: "No Upfront",
  partial_upfront: "Partial Upfront",
  all_upfront: "All Upfront",
};

export const savingsPlansStateLabels = {
  active: "Active",
  expired: "Expired",
  expires_soon: "Expires Soon",
};

export const coverageGroupingNames = {
  resource_type: "Resources",
  account: "Accounts",
  commitment: "Commitments",
};

export const savingsPlanTermLabels = {
  one_year: "1-year",
  three_years: "3-years",
};

export const savingsPlanLockBackPeriodLabels = {
  seven_days: "7 days",
  thirty_days: "30 days",
  sixty_days: "60 days",
};

export const savingsPlanLockBackPeriodDays = {
  seven_days: 7,
  thirty_days: 30,
  sixty_days: 60,
};

export const savingsPlanAccountScopeLabels = {
  payer: "Payer",
  linked: "Linked",
};
