import { FC, useCallback, useMemo } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { WidgetWithREViewDisabledAction } from "./disabled-actions/WidgetWithREViewDisabledAction";
import { WidgetOptionCard } from "../../WidgetOptionCard";
import Cover from "../../../../utils/assets/images/widget-covers/largest_cost.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { isResourceExplorerAvailableSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/checkers/isResourceExplorerAvailableSelector";
import { initiateLargestCostChangesWidgetThunk } from "../../../../../../../store/dashboards/thunks/widgets/largest-cost-changes/initiateLargestCostChangesWidgetThunk";
import { dashboardWidgetLabels } from "../../../../utils/constants/constants";
import { defaultResourceExplorerViewIdSelector } from "../../../../../../../store/resource-explorer/selectors/all-resource-explorers/defaultResourceExplorerViewIdSelector";

export const LargestCostCard: FC = () => {
  const dispatch = useAppDispatch();

  const enableLargestCostChangesWidget = useFlag(
    "EnableLargestCostChangesWidget",
  );
  const isResourceExplorerAvailable = useAppSelector(
    isResourceExplorerAvailableSelector,
  );
  const defaultViewId = useAppSelector(defaultResourceExplorerViewIdSelector);

  const clickHandler = useCallback(() => {
    dispatch(initiateLargestCostChangesWidgetThunk());
  }, [dispatch]);

  const disabledAction = useMemo(() => {
    if (!isResourceExplorerAvailable || !enableLargestCostChangesWidget) {
      return (
        <WidgetWithREViewDisabledAction
          viewId={defaultViewId}
          disabled={!enableLargestCostChangesWidget}
          available={isResourceExplorerAvailable}
        />
      );
    }
  }, [
    enableLargestCostChangesWidget,
    isResourceExplorerAvailable,
    defaultViewId,
  ]);

  return (
    <WidgetOptionCard
      title={dashboardWidgetLabels.largest_cost_changes ?? ""}
      description="Understand the most significant cost increases and decreases across different time ranges."
      onClick={clickHandler}
      disabledAction={disabledAction}
    >
      <img src={Cover} alt="ConstAndUsageCard" />
    </WidgetOptionCard>
  );
};
