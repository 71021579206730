import { Button, Stack, Typography } from "@mui/material";
import { Row } from "@tanstack/react-table";
import { ColumnSetupType } from "../../../../../../../../storybook/data-grid/utils/types/types";
import { SavingsPlan } from "../../../../../../../../services/cloudchipr.api";
import { ProviderUniqueIdCell } from "../components/cells/ProviderUniqueIdCell";
import {
  savingsPlansPaymentOptionLabels,
  savingsPlansTypeLabels,
} from "../../../../utils/constants/labels";
import { AccountCell } from "../components/cells/AccountCell";
import { UtilizationCell } from "../components/cells/UtilizationCell";
import { money } from "../../../../../../../../utils/numeral/money";
import { DatesCell } from "../components/cells/DatesCell";
import { TagCell } from "../../../../../../../common/data-grid-cells/tag-cell/TagCell";
import { labelsFilterFn } from "../../../../utils/helpers/labelsFilterFn";
import { HeaderCellWidthDateTooltip } from "../components/cells/HeaderCellWidthDateTooltip";
import { CommitmentsTableName } from "../components/cells/CommitmentsTableName";

const cellStyles = (row: Row<any>) => {
  return {
    bgcolor: row.original.state === "expires_soon" ? "#ed6c0216" : undefined,
  };
};

export const savingsPlansTableColumns: ColumnSetupType<SavingsPlan>[] = [
  {
    accessorKey: "provider_unique_id",
    header: "Savings Plan ID",
    cell: ({ row }) => (
      <ProviderUniqueIdCell id={row.original.provider_unique_id} />
    ),
    meta: { cellStyles },
  },

  {
    accessorKey: "type",
    header: "Type",
    cell: ({ row }) => {
      const type = row.original.type;

      return savingsPlansTypeLabels[type] ?? null;
    },
    meta: { cellStyles },
    filterFn: (row, _, filterValue) =>
      labelsFilterFn(row.original.type, savingsPlansTypeLabels, filterValue),
  },

  {
    accessorKey: "account",
    header: "Linked Account",
    cell: ({ row }) => (
      <AccountCell
        accountId={row.original.account?.provider_account_id}
        accountName={row.original.account?.provider_account_name}
        isRootAccount={row.original.account?.is_provider_root_account}
      />
    ),
    meta: { cellStyles },
  },

  { accessorKey: "region", header: "Region", meta: { cellStyles } },

  {
    accessorKey: "payment_option",
    header: "Payment Option",
    cell: ({ row }) => {
      const option = row.original.payment_option;

      return savingsPlansPaymentOptionLabels[option] ?? null;
    },
    meta: { cellStyles },
    filterFn: (row, _, filterValue) =>
      labelsFilterFn(
        row.original.payment_option,
        savingsPlansPaymentOptionLabels,
        filterValue,
      ),
  },

  {
    enableSorting: false,
    accessorKey: "used_commitment_to_date",
    header: () => (
      <HeaderCellWidthDateTooltip title="Utilization / Commitment" />
    ),
    cell: ({ row }) => <UtilizationCell row={row} />,
    meta: { cellStyles },
  },

  {
    accessorKey: "net_savings_to_date",
    header: () => (
      <Stack alignItems="end" flex={1}>
        <HeaderCellWidthDateTooltip title="Net Savings" />
      </Stack>
    ),
    cell: ({ row }) => (
      <Typography align="right" pr={4}>
        {money(row.original.net_savings_to_date)}
      </Typography>
    ),
    meta: { cellStyles },
  },

  {
    accessorKey: "start_date",
    header: "Start Date",
    cell: ({ row }) => <DatesCell date={row.original.start_date} />,
    meta: { cellStyles },
  },

  {
    accessorKey: "end_date",
    header: "End Date",
    cell: ({ row }) => (
      <DatesCell date={row.original.end_date} state={row.original.state} />
    ),
    meta: { cellStyles },
  },

  {
    enableSorting: false,
    accessorKey: "tags",
    header: "Tags",
    cell: ({ row }) => <TagCell tags={row.original.tags} />,
    meta: { cellStyles },
  },

  {
    enableSorting: false,
    accessorKey: "has_recommendation",
    header: "Recommendation",
    cell: ({ row }) => {
      const hasRecommendation = row.original.has_recommendation;

      if (hasRecommendation) {
        return (
          <Button
            size="small"
            variant="outlined"
            sx={{ textTransform: "initial" }}
          >
            <CommitmentsTableName
              id={row.original.provider_unique_id}
              type="savings_plan"
              name="Review"
            />
          </Button>
        );
      }

      return (
        <Typography variant="body2" color="text.disabled">
          No recommendation
        </Typography>
      );
    },
    meta: { cellStyles },
  },
];
