import { FC, useCallback } from "react";
import { useWillUnmount } from "rooks";
import { Stack, Typography } from "@mui/material";
import { rightsizingDismissedResourcesDataGridColumns } from "./rightsizingDismissedResourcesDataGridColumns";
import { DataGrid } from "../../../../../../../storybook/data-grid/DataGrid";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import {
  resetDismiss,
  setSelectedIds,
} from "../../../../../../../store/recommendations/recommendationsSlice";
import { selectedIdsInGridFormatSelector } from "../../../../../../../store/recommendations/selectors/selected-ids/selectedIdsInGridFormatSelector";
import { RightsizingRecommendationsTableToolbar } from "../table/RightsizingRecommendationsTableToolbar";
import { rightsizingRecommendationsPageLoadingSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/loading/rightsizingRecommendationsPageLoadingSelector";
import { rightsizingRecommendationsDismissedResourcesFilteredDataSelector } from "../../../../../../../store/recommendations/selectors/rightsizing/dismissed-resources/rightsizingRecommendationsDismissedResourcesFilteredDataSelector";
import { DataGridProvider } from "../../../../../../../storybook/data-grid/DataGridProvider";

export const RightsizingDismissedResourcesDataGrid: FC = () => {
  const dispatch = useAppDispatch();

  const selectedIds = useAppSelector(selectedIdsInGridFormatSelector);
  const loading = useAppSelector(rightsizingRecommendationsPageLoadingSelector);
  const data = useAppSelector(
    rightsizingRecommendationsDismissedResourcesFilteredDataSelector,
  );

  const onRowSelectionChange = useCallback(
    (ids: Record<string, boolean>): void => {
      dispatch(setSelectedIds(Object.keys(ids)));
    },
    [dispatch],
  );

  useWillUnmount(() => {
    dispatch(resetDismiss());
  });

  return (
    <DataGridProvider>
      <DataGrid
        styles={styles}
        data={data ?? []}
        columns={rightsizingDismissedResourcesDataGridColumns}
        rowSelection={{
          initialSelectedItems: selectedIds,
          rowSelectionChange: onRowSelectionChange,
        }}
        toolbar={{
          renderToolbar: (props) => (
            <RightsizingRecommendationsTableToolbar
              {...props}
              tab="dismissed_resources"
            />
          ),
        }}
        pagination={!loading && !data?.length && footerToolbar}
      />
    </DataGridProvider>
  );
};

const styles = {
  tableHeaderRow: {
    "& th:nth-of-type(2)": { pl: 0 },
    "& th": { verticalAlign: "inherit" },
  },
};

const footerToolbar = {
  renderPagination: () => {
    return (
      <Stack p={2} alignItems="center" justifyContent="center">
        <Typography
          color="text.secondary"
          variant="subtitle1"
          fontWeight="medium"
        >
          No resources found
        </Typography>
      </Stack>
    );
  },
};
