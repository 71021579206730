import { FC, Fragment } from "react";
import { Stack, TableCell, TableRow } from "@mui/material";
import { TableNameCell } from "./cells/TableNameCell";
import { TableSpendingCell } from "./cells/TableSpendingCell";
import { TableFilteredResourcesCell } from "./cells/TableFilteredResourcesCell";
import { SubCategoryActionsCell } from "./sub-category-table/SubCategoryActionsCell";
import { OpenResourceExplorer } from "./cells/OpenResourceExplorer";
import { AccountCategoryWithCost } from "../../../../../services/cloudchipr.api";
import { AccountsWithPopover } from "../../../../common/accounts-with-popover/AccountsWithPopover";

interface CategoriesListSubRowProps {
  category: AccountCategoryWithCost;
}

export const CategoriesListSubRow: FC<CategoriesListSubRowProps> = ({
  category,
}) => {
  return (
    <Fragment>
      <TableRow
        sx={{
          bgcolor: "grey.50",
          "& td": { borderColor: "grey.100" },
        }}
      >
        <TableCell sx={{ width: 66, minWidth: 66 }} />

        <TableNameCell subRow name={category.value} />

        <TableSpendingCell spending={category.total_cost} />

        <TableFilteredResourcesCell costs={category.filtered_resource_costs} />

        <TableCell>
          <Stack spacing={0.5} direction="row">
            <AccountsWithPopover chipView accounts={category.accounts} />
            <OpenResourceExplorer categoryId={category.id} />
          </Stack>
        </TableCell>

        <SubCategoryActionsCell category={category} />
      </TableRow>
    </Fragment>
  );
};
