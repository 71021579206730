import { FC, useRef } from "react";
import { useEffectOnceWhen } from "rooks";
import { Stack, Typography } from "@mui/material";
import { FilterTriggerComponentProps } from "../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { FilterButton } from "../../../filters/components/FilterButton";
import { useAppSelector } from "../../../../../../../store/hooks";
import { possibleFilterDimensionsSelector } from "../../../../../../../store/resource-explorer/selectors/filters/possible-filters/global/possibleFilterDimensionsSelector";
import { resourceExplorerDimensionSelector } from "../../../../../../../store/resource-explorer/selectors/filters/dimension/resourceExplorerDimensionSelector";

export const DimensionSelectDropDownTrigger: FC<FilterTriggerComponentProps> = (
  props,
) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const dimensionFilters = useAppSelector(possibleFilterDimensionsSelector);
  const selectedDimension = useAppSelector(resourceExplorerDimensionSelector);
  const selectedDimensionId = selectedDimension?.dimensionId;

  useEffectOnceWhen(() => {
    if (ref.current) {
      props.onClick({
        currentTarget: ref.current,
      });
    }
  }, !!selectedDimension && !selectedDimensionId);

  const selectedDimensionName =
    dimensionFilters?.values?.find(
      (dimension) => dimension.value === selectedDimensionId,
    )?.title ?? "";

  return (
    <div ref={ref}>
      <FilterButton {...props} color="primary" variant="contained">
        <Stack spacing={1} direction="row">
          <Typography variant="body2" color="text.secondary">
            Dimension:
          </Typography>
          <Typography variant="body2">{selectedDimensionName}</Typography>
        </Stack>
      </FilterButton>
    </div>
  );
};
