import { ColumnSetupType } from "../../../../../../../../storybook/data-grid/utils/types/types";
import {
  ResourceType,
  Tags,
} from "../../../../../../../../services/cloudchipr.api";
import { IdentifierCell } from "../../../../../../account/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/cells/identifier-cell/IdentifierCell";
import { money } from "../../../../../../../../utils/numeral/money";
import { TagCell } from "../../../../../../../common/data-grid-cells/tag-cell/TagCell";
import { columnsMap } from "../../../../../../account/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/data-grid/columns-setup/columnsMap";
import { ResourceItemType } from "../../../../../../../../store/account/utils/types/types";

export const getCleanDialogGridColumns = (
  resourceType: ResourceType,
): ColumnSetupType<ResourceItemType>[] => {
  const firstCell = columnsMap.get(resourceType)?.[0];
  const firstCellAccessorKey =
    (firstCell?.accessorKey as keyof ResourceItemType) ||
    "provider_unique_identifier";

  const tag = columnsMap
    .get(resourceType)
    ?.find((column) => column.accessorKey === "tags");

  const columns: ColumnSetupType<ResourceItemType>[] = [
    {
      accessorKey: firstCellAccessorKey,
      header: firstCell?.header || "Id",
      type: "identifier",
      minSize: 160,
      cell: (cell) => {
        return <IdentifierCell data={cell.getValue() as string} />;
      },
      meta: { alwaysShowSortIcon: true },
    },
    {
      accessorKey: "price_per_month",
      header: "Monthly Price",
      cell: (cell) => money(cell.getValue() as string),
      meta: { alwaysShowSortIcon: true },
    },
  ];

  if (tag) {
    columns.push({
      accessorKey: "tags",
      header: tag.header,
      minSize: 120,
      enableSorting: false,
      cell: (cell) => {
        return <TagCell tags={cell.getValue() as Tags} widthToEllipsis={100} />;
      },
    });
  }

  return columns;
};
