import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateMultiFilterByProvider } from "../../resourceExplorerSlice";
import {
  ResourceExplorerFilterItem,
  ProviderType,
} from "../../../../services/cloudchipr.api";
import { getResourceExplorerWidgetDataThunk } from "../widgets/getResourceExplorerWidgetDataThunk";
import { RootState } from "../../../store";
import { resourceExplorerFilterByKeyAndProviderSelector } from "../../selectors/filters/resourceExplorerFilterByKeyAndProviderSelector";

interface AccountFilterChangeThunkArg {
  filter: ResourceExplorerFilterItem;
  provider: ProviderType;
}

export const accountFilterChangeThunk = createAsyncThunk(
  "resourceExplorer/accountFilterChange",
  (
    { filter, provider }: AccountFilterChangeThunkArg,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const currentSavedFilter = resourceExplorerFilterByKeyAndProviderSelector(
      state,
      "account",
      provider,
    );
    if (!currentSavedFilter && !filter.value?.length) {
      return;
    }
    dispatch(
      updateMultiFilterByProvider({
        filter,
        provider,
      }),
    );
    dispatch(getResourceExplorerWidgetDataThunk());
  },
);
