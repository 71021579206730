import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { Ecs } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const ecsColumns: ColumnSetupType<Ecs>[] = [
  {
    accessorKey: "name",
    header: "Name",
    type: "identifierWithRecommendationLink",
    size: 210,
    meta: { sticky: "left", hideFromSettings: true },
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
  },

  {
    accessorKey: "creation_data",
    header: "Created By",
    type: "createdBy",
    headerTooltip:
      "The Created By value is only available for resources created within the last 90 days.",
  },
  { accessorKey: "region", header: "Region", type: "withCopy" },
  {
    accessorKey: "capacity_providers",
    header: "Capacity Providers",
    size: 200,
  },
  {
    accessorKey: "instances",
    header: "Instances",
  },

  { accessorKey: "tags", header: "Labels", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Labels",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },
  {
    accessorKey: "issues",
    header: "Ticket Status",
    type: "jiraTicketStatus",
    enableSorting: false,
  },
];
