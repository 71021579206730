import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  resetAllSelectedResources,
  resetViewWithoutFiltersResourceTypes,
  setFilterTemplatesEnabled,
} from "../accountSlice";

export const resetResourcesConfigsPerAccountThunk = createAsyncThunk(
  "account/resetResourcesConfigsPerAccountThunk",
  (_, { dispatch }) => {
    dispatch(setFilterTemplatesEnabled(true));
    dispatch(resetViewWithoutFiltersResourceTypes());
    dispatch(resetAllSelectedResources());
  },
);
