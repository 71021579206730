import { RootState } from "../../../../store";
import { commitmentsSliceSelector } from "../../commitmentsSliceSelector";

export const utilizationAndCoverageCoverageGroupingSelector = (
  state: RootState,
) => {
  const slice = commitmentsSliceSelector(state);

  return slice.coverageAndUtilization.coverageGrouping;
};
