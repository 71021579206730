import { FC, useCallback, useMemo } from "react";
import { blue, deepPurple } from "@mui/material/colors";
import { UtilizationAndCoverageContentChartLegendContent } from "./UtilizationAndCoverageContentChartLegendContent";
import { UtilizationAndCoverageContentChartTooltipContent } from "./UtilizationAndCoverageContentChartTooltipContent";
import { MultiTypeChartProvider } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { MultiTypeChart } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChart";
import { MultiTypeChartProviderProps } from "../../../../../../../storybook/charts/multi-type-chart/utils/types/contextTypes";
import { chartDataPointKey } from "../../../../../../../storybook/charts/multi-type-chart/utils/constants/constants";
import {
  SavingsPlanDateGranularDataPoint,
  SavingsPlanDateGranularDataPointValue,
} from "../../../../../../../services/cloudchipr.api";
import {
  ChartDataType,
  ChartType,
} from "../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { ChartsTooltipProps } from "../../../../../../../storybook/charts/multi-type-chart/components/common/ChartsTooltipContent";
import { capitalizeString } from "../../../../../../../utils/helpers/capitalizeString";

interface CommitmentsChartProps {
  loading: boolean;
  labelFormatter?: ChartsTooltipProps["tooltipLabelFormatter"];
  dataGroupedByDate?: Record<string, SavingsPlanDateGranularDataPoint>;
  ChartHeader?: FC;
  availableTypes: ChartType[];
}

export const CommitmentsChart: FC<CommitmentsChartProps> = ({
  dataGroupedByDate,
  labelFormatter,
  loading,
  ChartHeader,
  availableTypes,
}) => {
  const data = useMemo(() => {
    if (!dataGroupedByDate) {
      return null;
    }

    return Object.values(dataGroupedByDate).map((point) => {
      const data = {
        [chartDataPointKey]: point.date,
      } as ChartDataType;

      if (point.utilization) {
        data["utilization"] = point.utilization?.percent;
      }

      if (point.coverage) {
        data["coverage"] = point.coverage?.percent;
      }

      return data;
    });
  }, [dataGroupedByDate]);

  const tooltipValueFormatter = useCallback(
    (payload: any) => {
      const key = payload?.dataKey as keyof SavingsPlanDateGranularDataPoint;
      const date = payload.payload?.[chartDataPointKey] as string;
      const dataPoint = dataGroupedByDate?.[
        date
      ] as SavingsPlanDateGranularDataPoint;
      const data = dataPoint?.[key] as SavingsPlanDateGranularDataPointValue;
      const percent = data?.percent;
      const amount = data?.amount;

      return `${percent}% (${amount})`;
    },
    [dataGroupedByDate],
  );

  const initialChartType = availableTypes.at(0) as ChartType;
  const contextValue: MultiTypeChartProviderProps["value"] = useMemo(() => {
    return {
      data: data ?? [],
      initialChartType,
      colors: {
        utilization: deepPurple[300],
        coverage: blue[400],
      },
    };
  }, [data, initialChartType]);

  const TooltipContent = useCallback(
    (props: any) => {
      return (
        <UtilizationAndCoverageContentChartTooltipContent
          {...props}
          chartData={dataGroupedByDate}
        />
      );
    },
    [dataGroupedByDate],
  );

  return (
    <MultiTypeChartProvider value={contextValue}>
      {ChartHeader && <ChartHeader />}

      <MultiTypeChart
        highlight
        selectable
        loading={loading}
        showLoader={false}
        selectionType="select"
        yAxisFormatter={yAxisFormatter}
        availableTypes={availableTypes}
        yAxisProps={{ domain: [0, 100] }}
        tooltipProps={{
          // todo: refactor this logic, remove all formatters make it easy to set custom labels
          tooltipValueFormatter,
          tooltipLabelFormatter: labelFormatter,
          tooltipValueNameFormatter: capitalizeFormatter,
        }}
        xAxisTickProps={{ labelFormatter }}
        TooltipContent={TooltipContent}
        legendItemLabelFormatter={capitalizeFormatter}
        LegendContent={UtilizationAndCoverageContentChartLegendContent}
      />
    </MultiTypeChartProvider>
  );
};

const yAxisFormatter = (value: number | string) => `${value}%`;
const capitalizeFormatter = (name: string) => capitalizeString(name);
