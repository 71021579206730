import React, { FC, Fragment } from "react";
import { CleanFAB } from "./pages/common/clean/CleanFAB";
import { BillingData } from "./pages/common/billing/billing-data/BillingData";
import { useRefreshTokenHook } from "./utils/hooks/useRefreshTokenHook";

export const Common: FC = () => {
  useRefreshTokenHook();
  return (
    <Fragment>
      <CleanFAB />
      <BillingData />
    </Fragment>
  );
};
