import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { Dates } from "../../../../services/cloudchipr.api";
import {
  setResourceExplorerDates,
  setResourceExplorerSearch,
} from "../../resourceExplorerSlice";
import { getResourceExplorerDataThunk } from "../widgets/getResourceExplorerDataThunk";
import { resourceExplorerPathLastItemFiltersSelector } from "../../selectors/current-resource-explorer/path/resourceExplorerPathLastItemFiltersSelector";

export const changeResourceExplorerDateThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerDataThunk",
  async (dates: Dates, { dispatch, getState }) => {
    const pathLastItemFilters = resourceExplorerPathLastItemFiltersSelector(
      getState() as RootState,
    );

    dispatch(setResourceExplorerDates(dates));
    dispatch(
      getResourceExplorerDataThunk({ filtersFromPath: pathLastItemFilters }),
    );
    dispatch(setResourceExplorerSearch(""));
  },
);
