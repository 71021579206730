import { FC, useCallback } from "react";
import { Box, Card, Radio, Skeleton, Stack, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { green, red } from "@mui/material/colors";
import { money } from "../../../../../../../../utils/numeral/money";

export type ResourceCardStatisticsType = "all" | "filtered";

interface ResourceCardStatisticsCardProps {
  price: number;
  instances: string;
  title: string;
  tooltip?: string;
  selected: boolean;
  loading?: boolean;
  value: ResourceCardStatisticsType;
  onChange(type: ResourceCardStatisticsType): void;
  hideRadioButton?: boolean;
}

export const ResourceCardStatisticsCard: FC<
  ResourceCardStatisticsCardProps
> = ({
  title,
  onChange,
  price,
  value,
  instances,
  selected,
  loading,
  tooltip,
  hideRadioButton,
}) => {
  const changeHandler = useCallback(() => {
    onChange(value);
  }, [value, onChange]);

  return (
    <Card
      variant="outlined"
      onClick={changeHandler}
      sx={{
        cursor: "pointer",
        bgcolor: selected ? "grey.100" : undefined,
        borderColor: "grey.100",
      }}
    >
      <Stack direction="row" justifyContent="space-between" height="100%">
        <Box bgcolor={colorsByValue[value].dark} width={4} />
        {!hideRadioButton && (
          <Radio
            disableRipple
            checked={selected}
            size="small"
            sx={{
              alignSelf: "center",
            }}
          />
        )}
        <Stack flexGrow={1} p={hideRadioButton ? 1.5 : 0.5}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">
              {loading ? (
                <Skeleton variant="text" height={32} width={150} />
              ) : (
                money(price)
              )}
            </Typography>

            {tooltip && (
              <Tooltip arrow title={tooltip} placement="top">
                <InfoOutlinedIcon
                  sx={{ color: "text.secondary" }}
                  fontSize="small"
                />
              </Tooltip>
            )}
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Typography
              variant="caption"
              color="text.secondary"
              fontWeight="bold"
            >
              {title}
            </Typography>

            <Box
              px={1}
              ml={1}
              borderRadius={1}
              alignItems="center"
              bgcolor={colorsByValue[value].light}
            >
              <Typography
                variant="caption"
                color={colorsByValue[value].dark}
                fontWeight="medium"
                noWrap
              >
                {instances}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

const colorsByValue = {
  filtered: {
    dark: red[500],
    light: red[50],
  },
  all: {
    dark: green[500],
    light: green[50],
  },
};
