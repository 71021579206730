import { FC, useCallback } from "react";
import { List, Popover, PopoverProps } from "@mui/material";
import { GroupingsPopoverItem } from "./GroupingsPopoverItem";
import { GroupingsPopoverDisabledOptions } from "./GroupingsPopoverDisabledOptions";
import { getDefaultGroupings } from "../../../../resource-explorer/components/resource-explorer-card/utils/helpers/getDefaultGroupings";
import {
  ProviderType,
  ResourceExplorerGrouping,
} from "../../../../../../services/cloudchipr.api";

interface GroupingsPopoverProps {
  open: boolean;
  anchor: PopoverProps["anchorEl"];
  onClose(): void;
  providers: ProviderType[];
  groupValues?: string[];
  onGroupingSelect?(
    grouping: ResourceExplorerGrouping,
    groupValues?: string[],
  ): void;
  groupValueOptions?: { value: string; provider: ProviderType }[];
  activeGrouping: ResourceExplorerGrouping;
}

export const GroupingsPopover: FC<GroupingsPopoverProps> = ({
  open,
  anchor,
  onClose,
  providers,
  groupValues,
  onGroupingSelect,
  groupValueOptions,
  activeGrouping,
}) => {
  const { groupingOptions, disabledGroupings } = getDefaultGroupings(providers);
  const groupingSelectHandler = useCallback(
    (grouping: ResourceExplorerGrouping, groupValues?: string[]) => {
      onGroupingSelect?.(grouping, groupValues);
      onClose();
    },
    [onGroupingSelect, onClose],
  );

  let width = 240;

  if (anchor && "clientWidth" in anchor) {
    width = anchor?.clientWidth;
  }
  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <List
        dense
        sx={{
          width,
        }}
      >
        {groupingOptions
          .filter((grouping) => grouping !== "resource")
          .map((grouping) => {
            return (
              <GroupingsPopoverItem
                key={grouping}
                grouping={grouping}
                providers={providers}
                groupValues={groupValues}
                onSelect={groupingSelectHandler}
                selected={activeGrouping === grouping}
                groupingValueOptions={
                  grouping === "cost_allocation_tag"
                    ? groupValueOptions
                    : undefined
                }
              />
            );
          })}

        <GroupingsPopoverDisabledOptions
          disabledGroupings={disabledGroupings}
        />
      </List>
    </Popover>
  );
};
