import { FC, Fragment, useCallback } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  ProviderType,
  ResourceExplorerGrouping,
} from "../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../store/hooks";
import { enabledProvidersSelector } from "../../../../../../store/common/selectors/billing-analytics/enabled-providers/enabledProvidersSelector";
import { specificGroupings } from "../../../../resource-explorer/components/resource-explorer-card/utils/constants/groupings";
import { getResourcesExplorerGroupingLabel } from "../../../../resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { ProviderLogo } from "../../../../../common/provider-logo/ProviderLogo";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";

interface GroupingsPopoverDisabledOptionsProps {
  disabledGroupings: Record<string, ProviderType[]>;
}

export const GroupingsPopoverDisabledOptions: FC<
  GroupingsPopoverDisabledOptionsProps
> = ({ disabledGroupings }) => {
  const enabledProviders = useAppSelector(enabledProvidersSelector);
  const enabledProvidersArr = Array.from(enabledProviders);

  const generateTooltipMessage = useCallback(
    (grouping: string, availableProviders: ProviderType[]) => {
      const notAvailableProviders = enabledProvidersArr.filter(
        (provider) =>
          !availableProviders.includes(provider) &&
          !specificGroupings[provider].includes(
            grouping as ResourceExplorerGrouping,
          ),
      );

      const notAvailableProviderLabel = concatLabels(notAvailableProviders);
      const availableProvidersLabel = concatLabels(availableProviders);

      const label = getResourcesExplorerGroupingLabel(
        availableProviders,
        grouping as ResourceExplorerGrouping,
        true,
      );

      const tooltip = `Grouping by ${label} is available on ${availableProvidersLabel}. Turn off ${notAvailableProviderLabel} to group by ${label}.`;

      return { label, tooltip };
    },
    [enabledProvidersArr],
  );

  return (
    <Fragment>
      {Object.entries(disabledGroupings)?.map(([grouping, providers]) => {
        if (!grouping) {
          return null;
        }

        const { label, tooltip } = generateTooltipMessage(grouping, providers);

        return (
          <ListItem
            key={grouping}
            value={grouping}
            disablePadding
            secondaryAction={
              <Tooltip arrow title={tooltip} placement="top">
                <InfoOutlinedIcon color="action" fontSize="small" />
              </Tooltip>
            }
          >
            <ListItemButton disabled>
              <ListItemText
                primary={
                  <Stack direction="row" spacing={1}>
                    <Typography variant="inherit">{label}</Typography>
                    {providers.map((provider) => (
                      <ProviderLogo key={provider} provider={provider} />
                    ))}
                  </Stack>
                }
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </Fragment>
  );
};

const concatLabels = (providers: ProviderType[]) =>
  providers
    .map((provider) => getProviderName(provider, { title: true }))
    .join(" and ");
