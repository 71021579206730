import { FC, useCallback } from "react";
import { Divider, Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { TotalAndAverageCost } from "./components/TotalAndAverageCost";
import { ResourceExplorerBudgets } from "./components/budgets/ResourceExplorerBudgets";
import { ResourceExplorerLiveFiltered } from "./components/live-usage/ResourceExplorerLiveFiltered";
import { ResourceExplorerForecastedCosts } from "./components/forecasted/ResourceExplorerForecastedCosts";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { resourceExplorerWidgetTotalCostSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resource-explorer-widget/resourceExplorerWidgetTotalCostSelector";
import { resourceExplorerWidgetAverageDataSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resource-explorer-widget/resourceExplorerWidgetAverageDataSelector";
import { resourceExplorerDataPointSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerDataPointSelector";
import { resourceExplorerDataGridDataTotalTrendSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataTotalTrendSelector";
import { resourceExplorerLiveUsageTotalCostSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/live-usage/resourceExplorerLiveUsageTotalCostSelector";
import { resourceExplorerForecastOptionSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerForecastOptionSelector";
import { setResourceExplorerForecastOption } from "../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { resourceExplorerForecastedCostsSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/forecasted-cost/resourceExplorerForecastedCostsSelector";
import { resourceExplorerDataGridDataTotalCostDateDetailsSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataTotalCostDateDetailsSelector";
import { resourceExplorerDateLabelSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerDateLabelSelector";
import { resourceExplorerDataGridDataPreviousCostDateDetailsSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataPreviousCostDateDetailsSelector";

export const ResourceExplorerMetadata: FC = () => {
  const budgetsEnabled = useFlag("EnableResourcesExplorerBudgets");
  const enableLiveUsageOnResourceExplorer = useFlag(
    "EnableLiveUsageOnResourceExplorer",
  );

  const trend = useAppSelector(resourceExplorerDataGridDataTotalTrendSelector);
  const totalCostDates = useAppSelector(
    resourceExplorerDataGridDataTotalCostDateDetailsSelector,
  );
  const previousPeriodCostDates = useAppSelector(
    resourceExplorerDataGridDataPreviousCostDateDetailsSelector,
  );
  const dateLabel = useAppSelector(resourceExplorerDateLabelSelector);
  const forecastedCosts = useAppSelector(
    resourceExplorerForecastedCostsSelector,
  );

  const liveUsageTotalCost = useAppSelector(
    resourceExplorerLiveUsageTotalCostSelector,
  );

  const dispatch = useAppDispatch();

  const totalCost = useAppSelector(resourceExplorerWidgetTotalCostSelector);
  const averageData = useAppSelector(resourceExplorerWidgetAverageDataSelector);
  const dataPoint = useAppSelector(resourceExplorerDataPointSelector);
  const forecastOption = useAppSelector(resourceExplorerForecastOptionSelector);

  const forecastChangeHandler = useCallback(
    (option: any) => {
      dispatch(setResourceExplorerForecastOption(option));
    },
    [dispatch],
  );

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack
        direction="row"
        spacing={2}
        divider={<Divider light orientation="vertical" flexItem />}
      >
        <TotalAndAverageCost
          totalCost={totalCost}
          average={averageData?.cost}
          averageTrend={averageData?.trend}
          totalCostTrend={trend}
          totalCostDates={totalCostDates}
          previousPeriodCostDates={previousPeriodCostDates}
          dateLabel={dateLabel}
          dataPoint={dataPoint ?? "monthly"}
        />

        {budgetsEnabled && <ResourceExplorerBudgets />}
      </Stack>

      <Stack direction="row" spacing={2}>
        <ResourceExplorerForecastedCosts
          forecastedCosts={forecastedCosts}
          selectedForecastOption={forecastOption}
          onForecastChange={forecastChangeHandler}
        />

        {enableLiveUsageOnResourceExplorer && (
          <ResourceExplorerLiveFiltered
            totalCost={liveUsageTotalCost}
            loadFrom="resource-explorer"
            loadFor="view"
          />
        )}
      </Stack>
    </Stack>
  );
};
