import { cleanResourcesSelector } from "./cleanResourcesSelector";
import { cleanActionTypeSelector } from "./cleanActionTypeSelector";
import { RootState } from "../../store";
import { CleanResourcesDataType } from "../../../components/pages/common/clean/clean-dialog/utils/types";

export const cleanResourcesGridDataSelector = (
  state: RootState,
): CleanResourcesDataType => {
  const resources = cleanResourcesSelector(state) ?? [];
  const cleanActionType = cleanActionTypeSelector(state);

  return resources?.map((r) => {
    return {
      ...r,
      cleanAction: cleanActionType || "delete",
    };
  });
};
