// @ts-expect-error | no types
import moment from "moment-fquarter";
import { DateLabel } from "../../../../../services/cloudchipr.api";
import { PreDefinedRange } from "../constants/types";
import { labelByDateLabel } from "../constants/preDefinedRanges";

export const getQuarterRange = (
  quarterStartMonth: number,
  dateLabel: DateLabel,
): PreDefinedRange => {
  const currentQuarter = moment().fquarter(quarterStartMonth);

  let endDate;
  let startDate;
  if (dateLabel === "last_quarter") {
    startDate = moment(currentQuarter.start)
      .subtract(3, "month")
      .startOf("month")
      ?.format();

    endDate = moment(currentQuarter.end)
      .subtract(3, "month")
      .endOf("month")
      ?.format();
  } else if (dateLabel === "quarter_to_date") {
    startDate = moment(currentQuarter.start).startOf("month")?.format();
    endDate = moment().endOf("day")?.format();
  }

  return {
    dateLabel,
    endDate,
    startDate,
    label: labelByDateLabel.get(dateLabel)?.long ?? dateLabel,
    shortLabel: labelByDateLabel.get(dateLabel)?.short ?? dateLabel,
  };
};
