import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { awsBillingStatusSelector } from "./awsBillingStatusSelector";
import { gcpBillingStatusSelector } from "./gcpBillingStatusSelector";
import { azureBillingStatusSelector } from "./azureBillingStatusSelector";
import { ProviderType } from "../../../../services/cloudchipr.api";

export const billingProvidersStatusesSelector = createDraftSafeSelector(
  [
    awsBillingStatusSelector,
    gcpBillingStatusSelector,
    azureBillingStatusSelector,
  ],
  (aws, gcp, azure) => {
    if (!aws || !gcp || !azure) {
      return null;
    }

    const providers: ProviderType[] = [];

    aws?.is_initial_billing_collection_in_progress !== null &&
      providers.push("aws");
    gcp?.is_initial_billing_collection_in_progress !== null &&
      providers.push("gcp");
    azure?.is_initial_billing_collection_in_progress !== null &&
      providers.push("azure");

    return providers;
  },
);
