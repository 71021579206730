import { ChangeEvent, FC } from "react";
import { Stack, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useDebounce } from "rooks";

interface FilterTemplateSearchBoxProps {
  onChange(value: ChangeEvent): void;
}

export const FilterTemplateSearchBox: FC<FilterTemplateSearchBoxProps> = ({
  onChange,
}) => {
  const handleSearchDebounced = useDebounce(onChange, 200);
  return (
    <Stack p={1}>
      <TextField
        autoFocus
        size="xsmall"
        variant="outlined"
        placeholder="Search for filter template"
        onChange={handleSearchDebounced}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};
