import { FC } from "react";
import { UtilizationAndCoverageDateRange } from "./options/UtilizationAndCoverageDateRange";
import { useAppSelector } from "../../../../../store/hooks";
import { commitmentsForTableLoadingSelector } from "../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/commitmentsForTableLoadingSelector";
import { PageHeader } from "../../../common/PageHeader";
import { commitmentsForGranularLoadingSelector } from "../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/commitmentsForGranularLoadingSelector";

export const UtilizationAndCoveragePageHeader: FC = () => {
  const tableDataLoading = useAppSelector(commitmentsForTableLoadingSelector);
  const granularDataLoading = useAppSelector(
    commitmentsForGranularLoadingSelector,
  );

  return (
    <PageHeader
      sticky
      title="Utilization & Coverage"
      actions={<UtilizationAndCoverageDateRange />}
      loading={tableDataLoading || granularDataLoading}
    />
  );
};
