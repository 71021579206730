import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getDashboardsThunk } from "./getDashboardsThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { createDashboardFixedCacheKey } from "../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";

interface CreateDashboardThunkArgs {
  name: string;
}
export const createDashboardThunk = createAsyncThunk(
  "dashboards/createDashboard",
  async ({ name }: CreateDashboardThunkArgs, { dispatch }) => {
    const { postUsersMeOrganisationsCurrentDashboards } =
      cloudChiprApi.endpoints;
    let response;

    try {
      response = await dispatch(
        postUsersMeOrganisationsCurrentDashboards.initiate(
          { body: { name: name } },
          { fixedCacheKey: createDashboardFixedCacheKey },
        ),
      ).unwrap();

      if (response) {
        dispatch(getDashboardsThunk());
        enqueueSnackbar("Dashboard successfully created.", {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
          },
        });
      }
      return response;
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
