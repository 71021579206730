import {
  ProviderType,
  ResourceType,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { resourceGroups } from "../../../../utils/constants/resources/resourceGroups";
import { resourceTypeDataSpendingSelector } from "../resource-type-data/resourceTypeDataSpendingSelector";

export const resourceGroupSpendingSelector = (
  state: RootState,
  provider: ProviderType,
  resourceGroupKey: ResourceType,
  resourceTypesToSkip?: Map<ResourceType, boolean>,
) => {
  const resourceGroupTypes = resourceGroups
    .get(provider)
    ?.find((resourceGroup) => resourceGroup.value === resourceGroupKey)
    ?.resourceTypes.filter(
      (resourceType) => !resourceTypesToSkip?.get(resourceType),
    );

  return (
    resourceGroupTypes
      ?.map((resourceType) =>
        resourceTypeDataSpendingSelector(state, resourceType),
      )
      ?.reduce((acc, currentValue) => acc + currentValue, 0) ?? 0
  );
};
