import { FC, useCallback } from "react";
import moment from "moment/moment";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  setExecutionLogDates,
  setExecutionLogPagination,
} from "../../../../store/execution-log/executionLogSlice";
import { getExecutionLogThunk } from "../../../../store/execution-log/thunks/getExecutionLogThunk";
import { Dates } from "../../../../services/cloudchipr.api";
import { DateRangePicker } from "../../../common/date-range-picker-v2/DateRangePicker";
import { executionLogDatesSelector } from "../../../../store/execution-log/selectors/executionLogDatesSelector";
import {
  MAX_DATE_MONTHS,
  preDefinedAllRangesForExecutionLogs,
} from "../utils/constants/dates";

export const ExecutionLogsDateRangeSelector: FC = () => {
  const dispatch = useAppDispatch();
  const dates = useAppSelector(executionLogDatesSelector);

  const submitHandler = useCallback(
    (dates: Dates) => {
      dispatch(setExecutionLogDates(dates));
      dispatch(setExecutionLogPagination({ page: 0 }));
      dispatch(getExecutionLogThunk());
    },
    [dispatch],
  );

  return (
    <DateRangePicker
      highlightToday
      minDate={minDate}
      selectedDates={dates as Dates}
      onDateSelect={submitHandler}
      ranges={preDefinedAllRangesForExecutionLogs}
    />
  );
};

const minDate = moment()
  .subtract(MAX_DATE_MONTHS, "month")
  .startOf("month")
  .format();
