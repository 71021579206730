import { createAsyncThunk } from "@reduxjs/toolkit";

import { createDashboardHierarchyItemThunk } from "./createDashboardHierarchyItemThunk";
import { createDashboardHierarchyFolderThunk } from "./createDashboardHierarchyFolderThunk";
import { createDashboardHierarchyItemByFolderIdThunk } from "./createDashboardHierarchyItemByFolderIdThunk";
import { NavigationHierarchyItemType } from "../../../navigation/utils/types";

interface CreateDashboardHierarchyFolderOrItemThunkArg {
  type: NavigationHierarchyItemType;
  folderId?: string;
  name: string;
}
export const createDashboardHierarchyFolderOrItemThunk = createAsyncThunk(
  "dashboard/createDashboardHierarchyFolderOrItem",
  async (
    { folderId, name, type }: CreateDashboardHierarchyFolderOrItemThunkArg,
    { dispatch },
  ) => {
    let response;
    if (type === "item") {
      if (folderId) {
        response = await dispatch(
          createDashboardHierarchyItemByFolderIdThunk({
            name,
            folderId,
          }),
        ).unwrap();
      } else {
        response = await dispatch(
          createDashboardHierarchyItemThunk(name),
        ).unwrap();
      }
    } else {
      await dispatch(createDashboardHierarchyFolderThunk(name));
    }

    return response;
  },
);
