import { createAsyncThunk } from "@reduxjs/toolkit";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { RootState } from "../../../../../store";
import { cloudChiprApi } from "../../../../../../services/cloudchipr.api";
import { getResourceExplorerPreferencesKey } from "../../../../../../components/pages/resource-explorer/utils/hooks/useResourceExplorerPreferences.hook";
import { costBreakdownWidgetDefaultData } from "../../../../utils/constants/costBreakdownSetupDefaultData";
import { getCostBreakdownV2WidgetSetupDataThunk } from "../getCostBreakdownV2WidgetSetupDataThunk";
import { costBreakdownV2WidgetSetupSelector } from "../../../../selectors/setups/cost-breakdown-v2/costBreakdownV2WidgetSetupSelector";

export const costBreakdownV2REViewChangeThunk = createAsyncThunk(
  "dashboards/costBreakdownREViewChange",
  async (viewId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const setup = costBreakdownV2WidgetSetupSelector(state);

    if (!setup) {
      return;
    }

    dispatch(setWidgetSetup({ ...setup, viewId, loading: true }));

    const {
      getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId,
      getUsersMeCurrentPreferenceByKey,
    } = cloudChiprApi.endpoints;

    const response = await dispatch(
      getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId.initiate(
        { resourceExplorerViewId: viewId },
      ),
    ).unwrap();

    const key = getResourceExplorerPreferencesKey(viewId);

    let preference;
    try {
      preference = await dispatch(
        getUsersMeCurrentPreferenceByKey.initiate({ key }),
      ).unwrap();
    } catch (e) {
      preference = null;
    }

    const viewType = preference?.value
      ? JSON.parse(preference?.value)?.chart?.type
      : "stack";

    if (!response) {
      return;
    }

    const { data } = response;

    dispatch(
      setWidgetSetup({
        ...(setup ?? costBreakdownWidgetDefaultData),
        loading: true,
        grouping: data.grouping === "resource" ? "service" : data.grouping,
        group_values: data.group_values,
        frequency: data.data_point,
        viewId: viewId,
        viewType: viewType ?? costBreakdownWidgetDefaultData.viewType,
        dates: data.dates ?? {},
      }),
    );

    await dispatch(getCostBreakdownV2WidgetSetupDataThunk());

    const updatedSetup = costBreakdownV2WidgetSetupSelector(
      getState() as RootState,
    );
    if (updatedSetup) {
      dispatch(setWidgetSetup({ ...updatedSetup, loading: false }));
    }
  },
);
