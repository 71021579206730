import { RootState } from "../../../../store";
import {
  DashboardWidgetAccountsLiveUsage,
  DashboardWidgetCostAndUsageSummary,
  DashboardWidgetCostBreakdown,
  DashboardWidgetCostsByCategory,
  DashboardWidgetCostsByCategoryV2,
  DashboardWidgetLargestCostChanges,
  DashboardWidgetResourceExplorer,
} from "../../../../../services/cloudchipr.api";
import { dashboardWidgetsSelector } from "../../dashboard/dashboardWidgetsSelector";

export const widgetByIdSelector = (
  state: RootState,
  widgetId: string,
):
  | DashboardWidgetCostsByCategory
  | DashboardWidgetResourceExplorer
  | DashboardWidgetAccountsLiveUsage
  | DashboardWidgetCostsByCategoryV2
  | DashboardWidgetCostBreakdown
  | DashboardWidgetCostAndUsageSummary
  | DashboardWidgetLargestCostChanges
  | undefined => {
  if (!widgetId) {
    return;
  }

  const widgets = dashboardWidgetsSelector(state);

  return widgets?.find((widget) => widget.id === widgetId);
};
