import { resourceExplorerViewsDataSelector } from "./resourceExplorerViewsDataSelector";
import { RootState } from "../../../store";
import { ProviderType } from "../../../../services/cloudchipr.api";

export const resourceExplorerCloudProvidersByViewIdSelector = (
  state: RootState,
  viewId?: string,
): ProviderType[] | undefined => {
  if (!viewId) {
    return;
  }

  const views = resourceExplorerViewsDataSelector(state);

  return views.find((item) => item.id === viewId)?.cloud_providers;
};
