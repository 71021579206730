import { currentAccountSelector } from "./currentAccountSelector";
import { RootState } from "../../../store";

export const currentGCPAccountServiceAccountSelector = (state: RootState) => {
  const currentAccount = currentAccountSelector(state);

  if (
    !currentAccount ||
    !(
      currentAccount?.provider_details &&
      "gcp" in currentAccount.provider_details
    )
  ) {
    return;
  }

  return currentAccount.provider_details.gcp?.service_account;
};
