import { costBreakdownWidgetDataSelector } from "./costBreakdownWidgetDataSelector";
import { RootState } from "../../../../store";

export const costBreakdownWidgetViewTotalCostDateDetailsSelector = (
  state: RootState,
  widgetId: string,
) => {
  return costBreakdownWidgetDataSelector(state, widgetId)
    ?.total_cost_date_details;
};
