import { costBreakdownWidgetByIdSelector } from "./costBreakdownWidgetByIdSelector";
import { RootState } from "../../../../store";
import { costBreakdownSetupPropertyByKeySelector } from "../../setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { DateDataPoint } from "../../../../../services/cloudchipr.api";

export const costBreakdownWidgetFrequencySelector = (
  state: RootState,
  widgetId?: string,
): DateDataPoint => {
  let frequency = costBreakdownSetupPropertyByKeySelector("frequency")(state);

  const data = costBreakdownWidgetByIdSelector(state, widgetId);

  if (data) {
    frequency = data?.date_granularity;
  }

  return frequency;
};
