import { costByCategoryWidgetByIdSelector } from "./costByCategoryWidgetByIdSelector";
import { costByCategoryWidgetNotFoundSelector } from "./metadata/costByCategoryWidgetNotFoundSelector";
import { RootState } from "../../../../store";
import { WidgetTitleProps } from "../../../../../components/pages/dashboard/components/widgets/common/widget-header/source-and-name/WidgetSourceInfo";

export const costByCategoryWidgetTitlePropsSelector = (
  state: RootState,
  widgetId: string,
): WidgetTitleProps | null => {
  const widget = costByCategoryWidgetByIdSelector(state, widgetId);
  const viewNotFound = costByCategoryWidgetNotFoundSelector(state, widgetId);

  if (!widget) {
    return null;
  }

  return {
    widgetName: widget.name,
    dates: {
      label: widget?.date_label,
      from: widget?.date_from,
      to: widget?.date_to,
    },
    sourceData: viewNotFound
      ? undefined
      : {
          icon: "list",
          title: widget.category_group_key ?? "",
          menu: [
            {
              title: "Manage Categories",
              url: `/settings/categories`,
            },
          ],
        },
  };
};
