import { FC, useCallback, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { updateDashboardNameByIdThunk } from "../../../../../../store/dashboards/thunks/dashboard/updateDashboardNameByIdThunk";
import { updateDashboardByIdLoadingSelector } from "../../../../../../store/dashboards/selectors/dashboard/loadings/updateDashboardByIdLoadingSelector";

interface DashboardRenameActionItemDialogProps {
  open: boolean;
  id: string;
  name: string;
  closeDialog(): void;
}

export const DashboardRenameActionItemDialog: FC<
  DashboardRenameActionItemDialogProps
> = ({ open, closeDialog, id, name }) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(name);

  const updateDashboardByIdLoading = useAppSelector(
    updateDashboardByIdLoadingSelector,
  );

  const closeHandler = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  const renameHandler = useCallback(async () => {
    try {
      await dispatch(
        updateDashboardNameByIdThunk({
          name: value,
          dashboardId: id,
        }),
      );
      closeHandler();
    } catch (e) {
      // @ts-expect-error //TODO: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  }, [dispatch, closeHandler, id, value]);

  const inputChangeHandler = useCallback((event: any) => {
    setValue(event.target.value);
  }, []);

  useEffect(() => {
    name && setValue(name);
  }, [name]);

  return (
    <Dialog open={open} fullWidth onClose={closeHandler}>
      <DialogTitleClosable title="Rename Dashboard" onClose={closeHandler} />

      <DialogContent dividers sx={{ py: 3 }}>
        <Typography variant="caption" mb={1}>
          Dashboard Name
        </Typography>
        <TextField
          autoFocus
          fullWidth
          type="text"
          value={value}
          size="small"
          variant="outlined"
          onChange={inputChangeHandler}
        />
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3 }}>
        <Button color="tertiary" onClick={closeHandler}>
          Cancel
        </Button>
        <LoadingButton
          onClick={renameHandler}
          loading={updateDashboardByIdLoading}
          variant="contained"
          disabled={name.trim() === value.trim() || !value.length}
        >
          Rename
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
