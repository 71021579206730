import { ResourceType } from "../../../services/cloudchipr.api";

export type NestedResourcesHierarchy = Partial<{
  [key in ResourceType]: NestedResourcesHierarchy | null;
}>;

// todo: hierarchies should be in `resourceGroups` for all services
export const nestedResourcesHierarchy: NestedResourcesHierarchy = {
  rds_snapshot_source: {
    rds_snapshot: null,
  },
  az_aks: {
    az_node_pool: {
      az_aks_vm: null,
    },
  },
  asg: {
    asg_ec2: null,
  },
  eks: {
    node_group: {
      eks_ec2: null,
    },
  },
  gke: {
    node_pool: {
      gke_vm: null,
    },
  },
  mig: {
    mig_vm: null,
  },
  ecs: {
    ecs_workload: null,
  },
  az_vmss: {
    az_vmss_vm: null,
  },
};
