import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCostByCategoryWidgetDataBySetupThunk } from "./fetchCostByCategoryWidgetDataBySetupThunk";
import { RootState } from "../../../../store";
import { costByCategorySetupSelector } from "../../../selectors/setups/cost-by-category/costByCategorySetupSelector";
import { setWidgetSetup } from "../../../dashboardsSlice";
import { CostByCategoryWidgetSetupType } from "../../../utils/types/setups/costByCategorySetupType";
import { categoryIdsByCategoryGroupNameSelector } from "../../../../categories/selectors/categoryIdsByCategoryGroupNameSelector";

export const costByCategorySetupChangeThunk = createAsyncThunk(
  "dashboards/costByCategorySetupChangeThunk",
  async (
    setup: Partial<CostByCategoryWidgetSetupType>,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const existingSetup = costByCategorySetupSelector(state);

    if (!existingSetup) {
      return;
    }

    const newSetup = { ...existingSetup };

    let needToFetch = false;

    if (setup.id) {
      newSetup.id = setup.id;
    }

    if (setup.name !== undefined) {
      newSetup.name = setup.name;
    }

    if (setup.categoryGroup) {
      needToFetch = true;
      newSetup.categoryGroup = setup.categoryGroup;
    }

    if (setup.forecastOption) {
      newSetup.forecastOption = setup.forecastOption;
    }

    if (setup.categoryIds) {
      newSetup.categoryIds = setup.categoryIds;
    }

    if (setup.date) {
      needToFetch = true;
      newSetup.date = setup.date;
    }

    if (setup.frequency && setup.frequency !== existingSetup.frequency) {
      needToFetch = true;
      newSetup.frequency = setup.frequency;
    }

    if (setup.displayFilteredResources !== undefined) {
      needToFetch = true;
      newSetup.displayFilteredResources = setup.displayFilteredResources;
    }

    if (setup.chartType) {
      newSetup.chartType = setup.chartType;
    }

    if (!existingSetup.categoryIds?.length && !setup.categoryIds?.length) {
      newSetup.categoryIds = categoryIdsByCategoryGroupNameSelector(
        state,
        newSetup?.categoryGroup ?? "",
      );
    }

    dispatch(setWidgetSetup(newSetup));

    if (needToFetch && newSetup.categoryGroup) {
      return await dispatch(fetchCostByCategoryWidgetDataBySetupThunk());
    }
  },
);
