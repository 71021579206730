import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { getResourceExplorerViewsHierarchyThunk } from "./getResourceExplorerViewsHierarchyThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

interface UpdateResourceExplorerHierarchyThunkArg {
  viewId: string;
  folderId?: string;
  index?: number;
}

export const updateResourceExplorerHierarchyThunk = createAsyncThunk(
  "resourceExplorer/updateResourceExplorerHierarchy",
  async (
    { viewId, folderId, index }: UpdateResourceExplorerHierarchyThunkArg,
    { dispatch },
  ) => {
    const { patchUsersMeOrganisationsCurrentResourceExplorerViewsHierarchy } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        patchUsersMeOrganisationsCurrentResourceExplorerViewsHierarchy.initiate(
          {
            body: {
              id: viewId,
              parent_id: folderId,
              index,
            },
          },
        ),
      ).unwrap();
      dispatch(getResourceExplorerViewsHierarchyThunk());
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
