import { Grid, IconButton, Stack } from "@mui/material";
import { FC, useCallback } from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useDidMount } from "rooks";
import { ResourceExplorerTagKeySelect } from "./ResourceExplorerTagKeySelect";
import { ResourceExplorerTagValueSelect } from "./ResourceExplorerTagValueSelect";
import { ResourceExplorerTagOperatorSelect } from "./ResourceExplorerTagOperatorSelect";
import { ResourceExplorerTagFilterOperator } from "./ResourceExplorerTagFilterOperator";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../store/hooks";
import {
  ResourceExplorerFilterOperatorType,
  ResourceExplorerPossibleFilter,
  ResourceExplorerTag,
  ProviderType,
} from "../../../../../../../../../../../../services/cloudchipr.api";
import { costAllocationTagValuesDataSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/filters/cost-allocations-tags/costAllocationTagValuesDataSelector";
import { getCostAllocationTagValuesLoadingSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/filters/cost-allocations-tags/getCostAllocationTagValuesLoadingSelector";
import { getCostAllocationTagValuesThunk } from "../../../../../../../../../../../../store/resource-explorer/thunks/getCostAllocationTagValuesThunk";
import { RootState } from "../../../../../../../../../../../../store/store";

interface ResourceExplorerTagFilterItemProps {
  options?: ResourceExplorerPossibleFilter;
  filter?: ResourceExplorerTag;
  onTagChange: (index: number, tag: string) => void;
  onValueChange: (index: number, values: string[] | string) => void;
  onOperatorChange: (
    index: number,
    operator: ResourceExplorerFilterOperatorType,
  ) => void;
  index: number;
  onItemRemove: (index: number) => void;
  alreadyFilteredTagKeys: string[];
  provider: ProviderType;
  combinationOperator?: "and" | "or";
  onCombinationOperatorChange: (operator: "and" | "or") => void;
  onTagsEnable(): void;
}
export const ResourceExplorerTagFilterItem: FC<
  ResourceExplorerTagFilterItemProps
> = ({
  alreadyFilteredTagKeys,
  onValueChange,
  onItemRemove,
  index,
  options,
  filter,
  onTagChange,
  provider,
  onOperatorChange,
  combinationOperator = "and",
  onCombinationOperatorChange,
  onTagsEnable,
}) => {
  const dispatch = useAppDispatch();

  const tagValuesSelector = useCallback(
    (state: RootState) =>
      costAllocationTagValuesDataSelector(state, provider, filter?.tag_key),
    [provider, filter?.tag_key],
  );

  const tagValues = useAppSelector(tagValuesSelector);

  const loadingSelector = useCallback(
    (state: RootState) =>
      getCostAllocationTagValuesLoadingSelector(
        state,
        provider,
        filter?.tag_key,
      ),
    [provider, filter?.tag_key],
  );
  const isLoading = useAppSelector(loadingSelector);

  const isValuesFilterHidden = ["exists", "does_not_exist"].includes(
    filter?.operator ?? "",
  );

  const costAllocationTagChangeHandler = useCallback(
    (value: any) => {
      onTagChange(index, value);

      dispatch(
        getCostAllocationTagValuesThunk({
          tagKey: value,
          provider,
        }),
      );

      onValueChange(index, []);
    },
    [onTagChange, onValueChange, index, dispatch, provider],
  );

  const removeTagClickHandler = useCallback(() => {
    onItemRemove(index);
  }, [index, onItemRemove]);

  const costAllocationTagOperatorChange = useCallback(
    (operator: any) => {
      onOperatorChange(index, operator);
    },
    [index, onOperatorChange],
  );

  useDidMount(() => {
    if (!tagValues && filter?.tag_key) {
      dispatch(
        getCostAllocationTagValuesThunk({
          tagKey: filter?.tag_key,
          provider,
        }),
      );
    }
  });

  const operatorChangeHandler = useCallback(() => {
    onCombinationOperatorChange(combinationOperator === "and" ? "or" : "and");
  }, [combinationOperator, onCombinationOperatorChange]);

  return (
    <Stack px={2}>
      {index > 0 && (
        <ResourceExplorerTagFilterOperator
          disabled={index > 1}
          operator={combinationOperator}
          onClick={operatorChangeHandler}
        />
      )}

      <Stack direction="row" spacing={1} pt={1.5}>
        <Grid container spacing={1} overflow="auto">
          <Grid item md={4.5}>
            <ResourceExplorerTagKeySelect
              filters={filter}
              provider={provider}
              onTagsEnable={onTagsEnable}
              options={options?.values || []}
              onChange={costAllocationTagChangeHandler}
              alreadyFilteredTagKeys={alreadyFilteredTagKeys}
            />
          </Grid>

          <Grid item md={isValuesFilterHidden ? 7.5 : 3}>
            <ResourceExplorerTagOperatorSelect
              value={filter?.operator}
              onChange={costAllocationTagOperatorChange}
            />
          </Grid>

          {!isValuesFilterHidden && (
            <Grid item md={4.5}>
              <ResourceExplorerTagValueSelect
                index={index}
                filter={filter}
                provider={provider}
                onValueChange={onValueChange}
              />
            </Grid>
          )}
        </Grid>

        <IconButton
          onClick={removeTagClickHandler}
          size="small"
          disabled={isLoading || !options?.values.length}
        >
          <DeleteOutlinedIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
};
