import { FC, useCallback } from "react";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { GroupingByTagValue } from "./GroupingByTagValue";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { resourceExplorerProvidersSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerProvidersSelector";
import { ResourceExplorerGrouping } from "../../../../../../../../../../services/cloudchipr.api";
import { resourceExplorerGroupByChangeThunk } from "../../../../../../../../../../store/resource-explorer/thunks/resource-explorer/resourceExplorerGroupByChangeThunk";
import { getResourcesExplorerGroupingLabel } from "../../../../../../resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { resourceExplorerGroupingSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";

interface GroupingsPopoverItemProps {
  grouping: ResourceExplorerGrouping;
  onClick?(): void;
}

export const GroupingsPopoverItem: FC<GroupingsPopoverItemProps> = ({
  grouping,
  onClick,
}) => {
  const dispatch = useAppDispatch();
  const providers = useAppSelector(resourceExplorerProvidersSelector);
  const activeGrouping = useAppSelector(resourceExplorerGroupingSelector);

  const groupingItemClickHandler = useCallback(() => {
    if (activeGrouping === grouping) {
      onClick?.();
      return;
    }

    dispatch(resourceExplorerGroupByChangeThunk({ grouping }));
    onClick?.();
  }, [grouping, dispatch, onClick, activeGrouping]);

  const groupingByTagSelectHandler = useCallback(
    (groupValues?: string[]) => {
      dispatch(resourceExplorerGroupByChangeThunk({ grouping, groupValues }));
      onClick?.();
    },
    [grouping, dispatch, onClick],
  );

  return (
    <ListItem disablePadding>
      {grouping === "cost_allocation_tag" ? (
        <GroupingByTagValue onSubmit={groupingByTagSelectHandler} />
      ) : (
        <ListItemButton
          onClick={groupingItemClickHandler}
          selected={activeGrouping === grouping}
        >
          <ListItemText
            primary={getResourcesExplorerGroupingLabel(
              providers,
              grouping,
              true,
            )}
          />
        </ListItemButton>
      )}
    </ListItem>
  );
};
