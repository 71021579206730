import { FC, useCallback } from "react";
import { Button } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useFlag } from "@unleash/proxy-client-react";
import { editWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/common/editWidgetThunk";
import { useAppDispatch } from "../../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../../services/permissions";
import { WidgetType } from "../../../../../../../../services/cloudchipr.api";
import { editWidgetThunkV2 } from "../../../../../../../../store/dashboards/thunks/widgets/common/editWidgetThunkV2";

interface WidgetEditButtonProps {
  widgetId: string;
  widgetType: WidgetType;
}

export const WidgetEditButton: FC<WidgetEditButtonProps> = ({
  widgetType,
  widgetId,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotEditWidget = cannot("edit", "dashboard");
  const enableCostBreakdownNewEndpoints = useFlag(
    "EnableCostBreakdownNewEndpoints",
  );

  const editWidgetHandler = useCallback(() => {
    if (enableCostBreakdownNewEndpoints) {
      dispatch(
        editWidgetThunkV2({
          widgetId,
          widgetType,
        }),
      );
      return;
    }
    dispatch(
      editWidgetThunk({
        widgetId,
        widgetType,
      }),
    );
  }, [dispatch, widgetId, widgetType, enableCostBreakdownNewEndpoints]);

  return (
    <Button
      size="small"
      variant="outlined"
      sx={{ whiteSpace: "nowrap" }}
      onClick={editWidgetHandler}
      disabled={canNotEditWidget}
      startIcon={<EditOutlinedIcon fontSize="small" />}
    >
      Edit Widget
    </Button>
  );
};
