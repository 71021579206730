import { costByCategoryWidgetDataCategoriesSelector } from "./costByCategoryWidgetDataCategoriesSelector";
import { costByCategoryWidgetSelectedCategoryIdsSelector } from "./costByCategoryWidgetSelectedCategoryIdsSelector";
import { RootState } from "../../../../../store";
import { PostUsersMeOrganisationsCurrentWidgetsCostByCategoryApiResponse } from "../../../../../../services/cloudchipr.api";
import { costByCategorySetupPropertyByKeySelector } from "../../../setups/cost-by-category/costByCategorySetupPropertyByKeySelector";

export const costByCategoryWidgetDataFilteredByCategoryIdsSelector = (
  state: RootState,
  widgetId: string,
  categoryIdsSource: "view" | "setup",
):
  | PostUsersMeOrganisationsCurrentWidgetsCostByCategoryApiResponse["categories_data"]
  | undefined => {
  const widget = costByCategoryWidgetDataCategoriesSelector(state, widgetId);

  const categoryIdsFromSetup =
    costByCategorySetupPropertyByKeySelector("categoryIds")(state);
  const categoryIdsFromWidget = costByCategoryWidgetSelectedCategoryIdsSelector(
    state,
    widgetId,
  );

  const categoryIds =
    categoryIdsSource === "setup"
      ? categoryIdsFromSetup
      : categoryIdsFromWidget;

  return widget?.filter(({ category_id }) =>
    categoryIds?.includes(category_id),
  );
};
