import { utilizationAndCoverageDrawerDetailsSelector } from "./utilizationAndCoverageDrawerDetailsSelector";
import { RootState } from "../../../../../store";

export const utilizationAndCoverageDrawerTargetIdSelector = (
  state: RootState,
) => {
  const details = utilizationAndCoverageDrawerDetailsSelector(state);

  return details.targetId;
};
