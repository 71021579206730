import { FC, Fragment } from "react";
import { Box, Typography } from "@mui/material";
import { ResourceWithActions } from "./ResourceWithActions";
import {
  ProviderType,
  ResourceType,
  ResourceFiltersWithAction,
} from "../../../../../../../../../services/cloudchipr.api";
import { CleanActionTypes } from "../../../../../../../../../utils/clean-options";
import { getActionableResourceTypeByParentResourceType } from "../../../../../../../../../utils/helpers/resources/getActionableResourceTypeByParentResourceType";

interface ResourcesActionsListProps {
  accountIds: string[];
  provider: ProviderType;
  resourceFilters: ResourceFiltersWithAction[];
  onChange(resourceActions: Record<ResourceType, CleanActionTypes>): void;
}

export const ResourcesActionsList: FC<ResourcesActionsListProps> = ({
  resourceFilters,
  onChange,
  accountIds,
  provider,
}) => {
  return (
    <Fragment>
      <Typography variant="body2" color="text.secondary">
        Select action
      </Typography>

      <Box>
        {resourceFilters.map((resource) => {
          return (
            <ResourceWithActions
              key={resource.filter.type}
              provider={provider}
              accountIds={accountIds}
              onChange={onChange}
              action={resource.action}
              snapshot={resource.snapshot}
              resourceType={getActionableResourceTypeByParentResourceType(
                resource.filter.type,
              )}
            />
          );
        })}
      </Box>
    </Fragment>
  );
};
