import { FC, Fragment } from "react";
import { List } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { resourceTypeSearchParams } from "./utils/constants";
import { AccountNavItem } from "./components/account-nav-item/AccountNavItem";
import { ServicesCosts } from "./components/services-costs/ServicesCosts";
import { useToggledResourceTypes } from "../../utils/hooks/useToggledResources";
import { useAppSelector } from "../../../../../store/hooks";
import { currentAccountProviderTypeSelector } from "../../../../../store/account/selectors/current-account/currentAccountProviderTypeSelector";
import { resourceGroups } from "../../../../../utils/constants/resources/resourceGroups";
import { ResourceType } from "../../../../../services/cloudchipr.api";

interface AccountNavigationProps {
  collapsed: boolean;
}

export const AccountNavigation: FC<AccountNavigationProps> = ({
  collapsed,
}) => {
  const [searchParams] = useSearchParams();

  const provider = useAppSelector(currentAccountProviderTypeSelector);
  const toggledResourceTypes = useToggledResourceTypes();

  const searchParamsResourceType = searchParams.get(
    resourceTypeSearchParams,
  ) as ResourceType;

  if (!provider) {
    return null;
  }

  const resources = resourceGroups.get(provider);
  const currentResourceType = searchParamsResourceType ?? resources?.[0].value;

  return (
    <Fragment>
      {!collapsed && <ServicesCosts />}

      <List dense>
        {resources
          ?.filter((item) => !toggledResourceTypes.get(item.value))
          ?.map((resourceGroup) => (
            <AccountNavItem
              provider={provider}
              key={resourceGroup.value}
              selectedResourceType={currentResourceType}
              sidebarCollapsed={collapsed}
              {...resourceGroup}
            />
          ))}
      </List>
    </Fragment>
  );
};
