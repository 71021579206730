import { FC, Fragment, useCallback, useState } from "react";
import { Divider, Menu } from "@mui/material";
import { ToolbarCleanActionItems } from "./ToolbarCleanActionItems";
import { ToolbarIntegrationsActionItems } from "./ToolbarIntegrationsActionItems";
import { ProtectionMenuItems } from "./ProtectionMenuItems";
import { useDialog } from "../../../../../../../../../../../utils/hooks/useDialog.hook";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { createProtectThunk } from "../../../../../../../../../../../store/clean/thunks/createProtectThunk";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import { ProtectDialog } from "../../cells/actions-menu-cell/menu-items/protect/ProtectDialog";
import { resourcesProtectionLoadingSelector } from "../../../../../../../../../../../store/clean/selectors/resourcesProtectionLoadingSelector";
import { currentAccountIdSelector } from "../../../../../../../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import { currentAccountSelectedRowsIdsByResourceTypeSelector } from "../../../../../../../../../../../store/account/selectors/current-account/resources/selection/currentAccountSelectedRowsIdsByResourceTypeSelector";
interface ToolbarActionsMenuProps {
  resourceType: ResourceType;
  open: boolean;
  anchor: Element | null;
  onClose(): void;
}

export const ToolbarActionsMenu: FC<ToolbarActionsMenuProps> = ({
  resourceType,
  open,
  onClose,
  anchor,
}) => {
  const dispatch = useAppDispatch();

  const accountId = useAppSelector(currentAccountIdSelector);

  const selectedResourceIds = useAppSelector((state) =>
    currentAccountSelectedRowsIdsByResourceTypeSelector(state, resourceType),
  );

  const protectionLoading = useAppSelector((state) =>
    resourcesProtectionLoadingSelector(
      state,
      accountId ?? "",
      Object.keys(selectedResourceIds ?? {}),
    ),
  );

  const [protectAction, setProtectAction] = useState<"protect" | "unprotect">();
  const {
    open: protectDialogOpen,
    openDialog: openProtectDialog,
    closeDialog: closeProtectDialog,
  } = useDialog();

  const onProtectDialogConfirm = useCallback(() => {
    if (!protectAction) {
      return;
    }

    dispatch(
      createProtectThunk({
        resourceType,
        actionType: protectAction,
        callBack: closeProtectDialog,
      }),
    );
  }, [dispatch, closeProtectDialog, protectAction, resourceType]);

  return (
    <Fragment>
      <Menu anchorEl={anchor} open={open} onClose={onClose}>
        <ToolbarCleanActionItems
          onClose={onClose}
          resourceType={resourceType}
        />

        <ToolbarIntegrationsActionItems resourceType={resourceType} />

        <Divider />

        <ProtectionMenuItems
          onClose={onClose}
          resourceType={resourceType}
          setProtectAction={setProtectAction}
          openProtectDialog={openProtectDialog}
        />
      </Menu>

      {protectDialogOpen && (
        <ProtectDialog
          onClose={closeProtectDialog}
          onConfirm={onProtectDialogConfirm}
          isProtected={protectAction !== "protect"}
          isLoading={protectionLoading}
        />
      )}
    </Fragment>
  );
};
