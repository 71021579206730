import { FC, memo, useCallback } from "react";
import { Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import { AutomationTabType } from "../../utils/types/types";

interface ScheduleTabsProps {
  tabs: string[];
  activeTab: AutomationTabType;
  setActiveTab(tab: AutomationTabType): void;
}

export const ScheduleTabs: FC<ScheduleTabsProps> = memo(
  ({ activeTab, tabs, setActiveTab }) => {
    const tabChangeHandler = useCallback(
      (_: any, tab: AutomationTabType) => setActiveTab(tab),
      [setActiveTab],
    );

    return (
      <Tabs
        orientation="vertical"
        value={activeTab}
        onChange={tabChangeHandler}
        hidden={false}
        TabIndicatorProps={{
          style: { display: "none" },
        }}
      >
        {tabs.map((tabName) => (
          <Tab
            sx={{ width: 150, mr: 6 }}
            key={tabName}
            value={tabName}
            label={tabName}
          />
        ))}
      </Tabs>
    );
  },
);
