import { RootState } from "../../../../store";
import { commitmentsSliceSelector } from "../../commitmentsSliceSelector";
import { DateDataPoint } from "../../../../../services/cloudchipr.api";

export const utilizationAndCoverageDateGranularitySelector = (
  state: RootState,
): DateDataPoint => {
  const slice = commitmentsSliceSelector(state);

  return slice.coverageAndUtilization.dateGranularity;
};
