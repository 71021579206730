import { resourceExplorerViewsHierarchySelector } from "./resourceExplorerViewsHierarchySelector";
import { RootState } from "../../../store";
import {
  ResourceExplorer,
  ResourceExplorerFolder,
} from "../../../../services/cloudchipr.api";

export const resourceExplorerViewsHierarchyDataSelector = (
  state: RootState,
): (ResourceExplorer | ResourceExplorerFolder)[] | undefined => {
  return resourceExplorerViewsHierarchySelector(state)?.data;
};
