import { FC } from "react";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import { useLocation } from "react-router-dom";
import { DashboardCreateDialog } from "./components/create-dialog/DashboardCreateDialog";
import { DashboardActionButton } from "./components/actions-menu/DashboardActionButton";
import { NavItem } from "../nav-item/NavItem";
import { useAppAbility } from "../../../../services/permissions";
import { getDashboardsThunk } from "../../../../store/dashboards/thunks/dashboard/getDashboardsThunk";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { sortedDashboardsSelector } from "../../../../store/dashboards/selectors/dashboard/sortedDashboardsSelector";
import { useDialog } from "../../../../utils/hooks/useDialog.hook";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";

export const DashboardNavigation: FC = () => {
  const { open, openDialog, closeDialog } = useDialog();

  const dispatch = useAppDispatch();
  const { cannot, can } = useAppAbility();

  const location = useLocation();
  const selected = location.pathname.startsWith("/dashboards");

  const cannotCreateDashboard = cannot("create", "dashboard");
  const dashboardsAvailable = can("view", "dashboard");

  useEffectOnceWhen(() => {
    dispatch(getDashboardsThunk());
  }, dashboardsAvailable);

  const sortedDashboards = useAppSelector(sortedDashboardsSelector);
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  return (
    <NavItem
      primary="Dashboards"
      icon={DashboardCustomizeOutlinedIcon}
      selected={selected}
      secondaryAction={
        <IconButton
          size="small"
          disabled={cannotCreateDashboard}
          onClick={openDialog}
        >
          <AddIcon fontSize="inherit" />
        </IconButton>
      }
      navCollapsed={navigationCollapsed}
    >
      {sortedDashboards.map((dashboard) => (
        <NavItem
          nested
          key={dashboard.id}
          to={`/dashboards/${dashboard.id}`}
          primary={dashboard.name}
          icon={GridViewOutlinedIcon}
          secondaryAction={
            <DashboardActionButton id={dashboard.id} name={dashboard.name} />
          }
          secondaryActionHover
        />
      ))}
      <NavItem
        nested
        primary="Add"
        disabled={cannotCreateDashboard}
        onButtonClick={openDialog}
        primaryTypographyProps={{ color: "primary" }}
        icon={AddIcon}
        iconProps={{ color: "primary" }}
      />
      <DashboardCreateDialog open={open} onClose={closeDialog} />
    </NavItem>
  );
};
