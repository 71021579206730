import { resourceExplorerGlobalPossibleFiltersSelector } from "./resourceExplorerGlobalPossibleFiltersSelector";
import { RootState } from "../../../../../store";
import { ResourceExplorerPossibleFilter } from "../../../../../../services/cloudchipr.api";

export const possibleFilterCategoriesSelector = (
  state: RootState,
): ResourceExplorerPossibleFilter | undefined => {
  const filters = resourceExplorerGlobalPossibleFiltersSelector(state);

  return filters?.find((filter) => filter.key === "category_id");
};
