import { RootState } from "../../../../store";
import { resourceExplorerFilterByKeyAndProviderSelector } from "../resourceExplorerFilterByKeyAndProviderSelector";
import { Credits } from "../../../../../services/cloudchipr.api";
import { resourceExplorerPossibleFiltersByProviderSelector } from "../possible-filters/resourceExplorerPossibleFiltersByProviderSelector";

// Credit type is only for GCP
export const appliedCreditTypesSelector = (
  state: RootState,
): Credits | undefined => {
  const possibleFilters = resourceExplorerPossibleFiltersByProviderSelector(
    state,
    "gcp",
  );
  const filter = resourceExplorerFilterByKeyAndProviderSelector(
    state,
    "credit_type",
    "gcp",
  );

  let creditTypes = filter?.value as string[];

  if (!creditTypes) {
    creditTypes =
      possibleFilters?.["credit_type"]?.values.map((item) => item.value) ?? [];
  }

  return {
    credit_types: creditTypes ?? [],
    include_values: !!creditTypes?.length,
  };
};
