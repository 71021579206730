import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCostByCategoryWidgetDataThunk } from "./fetchCostByCategoryWidgetDataThunk";
import { RootState } from "../../../../store";
import { costByCategoryWidgetByIdSelector } from "../../../selectors/widgets/cost-by-category/costByCategoryWidgetByIdSelector";

export const fetchCostByCategoryWidgetDatByWidgetIdThunk = createAsyncThunk(
  "dashboards/fetchCostByCategoryWidgetDatByWidgetIdThunk",
  async (widgetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    const widget = costByCategoryWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    await dispatch(
      fetchCostByCategoryWidgetDataThunk({
        id: widgetId,
        dateTo: widget.date_to,
        dateFrom: widget.date_from,
        dateLabel: widget.date_label,
        frequency: widget.date_granularity,
        categoryIds: widget.categories.map(({ id }) => id),
        displayFilteredResources: widget.display_filtered_resources,
      }),
    );
  },
);
