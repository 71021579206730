import { postUsersMeOrganisationsCurrentWidgetsCostByCategorySelector } from "./postUsersMeOrganisationsCurrentWidgetsCostByCategorySelector";
import { RootState } from "../../../../../store";
import { PostUsersMeOrganisationsCurrentWidgetsCostByCategoryApiResponse } from "../../../../../../services/cloudchipr.api";
import { buildingInProgressWidgetIdWithNoFilter } from "../../../../thunks/widgets/utils/constants";

export const costByCategoryWidgetDataCategoriesWithoutFiltersSelector = (
  state: RootState,
):
  | PostUsersMeOrganisationsCurrentWidgetsCostByCategoryApiResponse["categories_data"]
  | undefined => {
  const response = postUsersMeOrganisationsCurrentWidgetsCostByCategorySelector(
    buildingInProgressWidgetIdWithNoFilter,
  )(state);

  return response?.data?.categories_data;
};
