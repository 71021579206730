import { FC, memo, useCallback, useEffect, useState } from "react";
import { Chip, FormControlLabel, Stack, Switch, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { SchedulesActionType } from "../../utils/types/types";

interface ActionTypeSwitchProps {
  label: string;
  info?: string;
  value?: SchedulesActionType;
  isChecked: boolean;
  disabled?: boolean;
  onChange(action?: SchedulesActionType): void;
  // TEMPORARY PROP
  beta?: boolean;
}

export const ActionTypeSwitch: FC<ActionTypeSwitchProps> = memo(
  ({ isChecked, onChange, value, label, disabled, info, beta = false }) => {
    const [checked, setChecked] = useState(isChecked);

    const changeHandler = useCallback(() => {
      onChange(value);
      setChecked((checked) => !checked);
    }, [onChange, value]);

    useEffect(() => {
      setChecked(isChecked);
    }, [isChecked]);

    return (
      <FormControlLabel
        disabled={disabled}
        onChange={changeHandler}
        checked={checked}
        sx={{ width: "fit-content" }}
        control={<Switch size="small" />}
        label={
          <Stack direction="row" alignItems="center">
            {label}

            {info && (
              <Tooltip arrow title={info} placement="top">
                <InfoOutlinedIcon
                  color="info"
                  fontSize="small"
                  sx={{ ml: 1 }}
                />
              </Tooltip>
            )}

            {beta && (
              <Chip
                label="Beta"
                size="small"
                color="success"
                variant="outlined"
                sx={{ ml: 1 }}
              />
            )}
          </Stack>
        }
      />
    );
  },
);
