import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { cleanStateSelector } from "./cleanStateSelector";
import { CleanDetails } from "../cleanSlice";

export const cleanProcessesSelector = createDraftSafeSelector(
  [cleanStateSelector],
  (cleanState): Record<string, CleanDetails> => {
    return cleanState.processes;
  },
);
