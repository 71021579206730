import { ProviderType } from "../../../../../../../../../../../services/cloudchipr.api";

export const costAllocationTagLabel: Partial<
  Record<ProviderType, { short: string; long: string }>
> = {
  aws: {
    long: "Tags",
    short: "Tag",
  },
  gcp: {
    long: "Labels",
    short: "Label",
  },
  azure: {
    long: "Tags",
    short: "Tag",
  },
};
