import { costByCategoryChartDataCombiner } from "./costByCategoryChartDataCombiner";
import { costByCategoryWidgetDataFilteredByCategoryIdsSelector } from "../data/costByCategoryWidgetDataFilteredByCategoryIdsSelector";
import { RootState } from "../../../../../store";

export const costByCategoryViewChartDataSelector = (
  state: RootState,
  widgetId: string,
  addFilteredResources?: boolean,
) => {
  const data = costByCategoryWidgetDataFilteredByCategoryIdsSelector(
    state,
    widgetId,
    "view",
  );

  if (!data) {
    return;
  }

  const groupedByDate = costByCategoryChartDataCombiner(
    data,
    !!addFilteredResources,
  );

  return Object.values(groupedByDate);
};
