import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ResourceExplorer } from "../../../../../../../../../services/cloudchipr.api";

interface WidgetViewSelectProps {
  viewId?: string;
  views: ResourceExplorer[];
  onChange(event: SelectChangeEvent): void;
}
export const WidgetViewSelect: FC<WidgetViewSelectProps> = ({
  viewId,
  views,
  onChange,
}) => {
  const renderValue = useCallback(
    (viewId: any) => {
      const selectedItem = views.find((item) => item.id === viewId);

      return selectedItem?.name ?? "";
    },
    [views],
  );

  return (
    <FormControl size="small" fullWidth>
      <InputLabel>Select Resource Explorer View</InputLabel>

      <Select
        value={viewId ?? ""}
        onChange={onChange}
        label="Select Resource Explorer View"
        renderValue={renderValue}
      >
        {views.map((item) => {
          return (
            <MenuItem key={item.id} value={item.id}>
              <ListItemText>{item.name}</ListItemText>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
