import { FC } from "react";
import { ForecastedCostHeaderCell } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/ForecastedCostHeaderCell";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { costBreakdownV2WidgetSetupForecastedCostsSumSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupForecastedCostsSumSelector";
import { costBreakdownV2WidgetSetupForecastedOptionSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupForecastedOptionSelector";

export const CostBreakdownV2WidgetSetupTableForecastedCostHeaderCell: FC =
  () => {
    const forecastedCost = useAppSelector(
      costBreakdownV2WidgetSetupForecastedCostsSumSelector,
    );
    const forecastedOption = useAppSelector(
      costBreakdownV2WidgetSetupForecastedOptionSelector,
    );

    if (!forecastedOption) {
      return null;
    }
    return (
      <ForecastedCostHeaderCell
        forecastOption={forecastedOption}
        forecastedCost={forecastedCost}
      />
    );
  };
