import { FC } from "react";
import { CSVLink } from "react-csv";
import { IconButton, Tooltip } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { utilizationAndCoverageDetailsCSVDataSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/data/utilizationAndCoverageDetailsCSVDataSelector";
import { utilizationAndCoverageCSVFileNameSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/utilizationAndCoverageCSVFileNameSelector";
import { useAppSelector } from "../../../../../../../../store/hooks";

export const CommitmentsDrawerCSVDownloader: FC = () => {
  const fileName = useAppSelector(utilizationAndCoverageCSVFileNameSelector);
  const data = useAppSelector(utilizationAndCoverageDetailsCSVDataSelector);

  if (!data) {
    return null;
  }

  return (
    <CSVLink filename={fileName} data={data}>
      <Tooltip title="Export CSV" placement="top">
        <IconButton size="medium">
          <DownloadOutlinedIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
    </CSVLink>
  );
};
