import { currentAccountProviderTypeSelector } from "./currentAccountProviderTypeSelector";
import { RootState } from "../../../store";
import { resourceTypeDataEmsSelector } from "../resource-type-data/resourceTypeDataEmsSelector";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { resourceTypesByProvider } from "../../../../utils/helpers/providers/resourceTypesByProvider";

export const currentAccountEmsSelector = (
  state: RootState,
  resourceTypesToSkip?: Map<ResourceType, boolean>,
) => {
  const provider = currentAccountProviderTypeSelector(state);
  if (!provider) {
    return;
  }

  const resourceTypes = resourceTypesByProvider(provider).filter(
    (resourceType) => !resourceTypesToSkip?.get(resourceType),
  );

  return (
    resourceTypes
      ?.map((resourceType) => resourceTypeDataEmsSelector(state, resourceType))
      ?.reduce((acc, currentValue) => acc + currentValue, 0) || 0
  );
};
