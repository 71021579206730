import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { costBreakdownSetupChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown/costBreakdownSetupChangeThunk";
import { WidgetViewTypeSelect } from "../../common/WidgetViewTypeSelect";

export const CostBreakdownViewTypeSelect: FC = () => {
  const dispatch = useAppDispatch();

  const viewType = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("viewType"),
  );
  const frequency = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("frequency"),
  );

  const changeHandler = useCallback(
    (e: any) => {
      const type = e.target.value;

      dispatch(
        costBreakdownSetupChangeThunk({
          viewType: type,
          frequency: type === "pie" ? "monthly" : frequency,
        }),
      );
    },
    [dispatch, frequency],
  );

  return (
    <WidgetViewTypeSelect
      viewType={viewType}
      options={["bar", "stack", "line", "pie", "table", "numeral"]}
      onChange={changeHandler}
    />
  );
};
