import { costAllocationTagValuesSelector } from "./costAllocationTagValuesSelector";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";

export const getCostAllocationTagValuesLoadingSelector = (
  state: RootState,
  provider: ProviderType,
  tagKey?: string,
) => {
  if (!tagKey) {
    return;
  }

  const response = costAllocationTagValuesSelector(state, provider, tagKey);

  return response?.isLoading;
};
