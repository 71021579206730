import { costBreakdownWidgetByIdSelector } from "./costBreakdownWidgetByIdSelector";
import { RootState } from "../../../../store";
import { resourceExplorerViewsDataSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewsDataSelector";

export const costBreakdownWidgetNotFoundSelector = (
  state: RootState,
  widgetId: string,
) => {
  const widget = costBreakdownWidgetByIdSelector(state, widgetId);
  const views = resourceExplorerViewsDataSelector(state);

  if (!widget) {
    return false;
  }

  return !views.find((item) => item.id === widget.view_id);
};
