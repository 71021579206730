import { ChangeEvent, FC, Fragment, useCallback, useState } from "react";
import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import { useToggle } from "rooks";

interface SchedulePreviewTableProps {
  title: string;
  label: string;
  onFieldChange?(key: string, value: any): void;
}

export const SchedulePreviewNameRow: FC<SchedulePreviewTableProps> = ({
  title,
  label,
  onFieldChange,
}) => {
  const [isEditing, setIsEditing] = useToggle();
  const [editableLabel, setEditableLabel] = useState(label || "");

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const inputBlurHandler = useCallback(() => {
    if (onFieldChange) {
      onFieldChange("name", editableLabel);
    }
  }, [onFieldChange, editableLabel]);

  const labelChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEditableLabel(e.target.value);
  }, []);

  return (
    <Fragment>
      <TableRow>
        <TableCell
          component="th"
          sx={{
            width: isLargeScreen ? 200 : "unset",
            px: 2,
            pt: 1,
            fontWeight: "medium",
            verticalAlign: "top",
          }}
        >
          {title}
        </TableCell>
        <TableCell sx={{ px: 2 }}>
          <Stack direction="row" alignItems="center">
            {isEditing ? (
              <TextField
                fullWidth
                variant="outlined"
                size="xsmall"
                value={editableLabel}
                onChange={labelChangeHandler}
                error={!editableLabel}
                helperText={!editableLabel && "Name is required"}
                onBlur={inputBlurHandler}
                autoFocus
              />
            ) : (
              editableLabel
            )}
            <IconButton
              onClick={setIsEditing}
              disabled={!editableLabel}
              size="small"
              sx={{ ml: 1 }}
            >
              {isEditing ? (
                <CheckIcon fontSize="inherit" />
              ) : (
                <EditIcon fontSize="inherit" />
              )}
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
