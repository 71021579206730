import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addMultiFilterByProvider,
  updateMultiFilterByProvider,
} from "../../resourceExplorerSlice";
import { ProviderType } from "../../../../services/cloudchipr.api";
import { getResourceExplorerPossibleFiltersThunk } from "../resource-explorer/getResourceExplorerPossibleFiltersThunk";
import { getResourceExplorerFiltersWithInitialValues } from "../../utils/helpers/getResourceExplorerFiltersWithInitialValues";
import { getResourceExplorerWidgetDataThunk } from "../widgets/getResourceExplorerWidgetDataThunk";

interface ChargeTypeFilterChangeThunkArg {
  provider: ProviderType;
}

export const addResourceExplorerViewFilterThunk = createAsyncThunk(
  "resourceExplorer/addResourceExplorerViewFilter",
  async ({ provider }: ChargeTypeFilterChangeThunkArg, { dispatch }) => {
    const filter = await dispatch(
      getResourceExplorerPossibleFiltersThunk({
        provider,
      }),
    ).unwrap();

    if (provider === "gcp") {
      // for GCP credit initial Filters
      const filters = getResourceExplorerFiltersWithInitialValues(
        {
          gcp: {},
        },
        {
          gcp: filter ?? [],
        },
      );
      if (filters?.["gcp"]?.["credit_type"]) {
        dispatch(
          updateMultiFilterByProvider({
            provider,
            filter: filters?.["gcp"]?.["credit_type"] ?? {},
          }),
        );
      }
    } else {
      dispatch(addMultiFilterByProvider(provider));
    }
    dispatch(getResourceExplorerWidgetDataThunk());
  },
);
