import { CleanDetails } from "../cleanSlice";
import { RootState } from "../../store";
import { NEW_CLEAN_ID } from "../utils/constants";

export const currentOpenCleanProcessSelector = (
  state: RootState,
): CleanDetails | null => {
  const cleanState = state.clean;
  if (cleanState.currentOpenId === NEW_CLEAN_ID) {
    return cleanState.new;
  }

  if (
    !cleanState.currentOpenId ||
    !cleanState.processes[cleanState.currentOpenId]
  ) {
    return null;
  }

  return cleanState.processes[cleanState.currentOpenId];
};
