import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { enabledProvidersSelector } from "../../selectors/billing-analytics/enabled-providers/enabledProvidersSelector";
import { RootState } from "../../../store";

export const getAllProvidersBillingStatusThunk = createAsyncThunk(
  "resourceExplorer/getAllProvidersBillingStatus",
  async (_, { dispatch, getState }) => {
    const { getV2UsersMeOrganisationsCurrentProvidersByProviderBillingStatus } =
      cloudChiprApi.endpoints;

    const state = getState() as RootState;
    const enableProviders = enabledProvidersSelector(state);
    const enabledProvidersArr = Array.from(enableProviders);
    try {
      const promises = [];
      for (const provider of enabledProvidersArr) {
        promises.push(
          dispatch(
            getV2UsersMeOrganisationsCurrentProvidersByProviderBillingStatus.initiate(
              {
                provider,
              },
            ),
          ).unwrap(),
        );
      }
      Promise.all(promises);
    } catch (e) {
      // @ts-expect-error//TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
