import { ExportedResourceDataOptions } from "./types";
import { generateExportedResourceDataByColumns } from "./generateExportedResourceDataByColumns";
import { getResourceTypeColumns } from "../data-grid/columns-setup/getResourceTypeColumns";
import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import {
  ResourceDataType,
  ResourceItemType,
} from "../../../../../../../../../../../store/account/utils/types/types";

export const generateExportedResourceData = (
  resourceType: ResourceType,
  resourceData: ResourceDataType[],
  disabledColumns: string[],
  options?: ExportedResourceDataOptions,
) => {
  const columns = getResourceTypeColumns(
    resourceType,
    disabledColumns,
  ) as ColumnSetupType<ResourceItemType>[];

  return generateExportedResourceDataByColumns(
    resourceData,
    columns,
    disabledColumns,
    options,
  );
};
