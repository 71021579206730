import { costBreakdownWidgetDataSelector } from "./costBreakdownWidgetDataSelector";
import { RootState } from "../../../../store";
import { formatCostBreakdownREDataGridData } from "../../../utils/helpers/costBreakdown/formatCostBreakdownREDataGridData";
import { ResourceExplorerGridDataWithId } from "../../../../resource-explorer/utils/types/resourceExplorerDataGrid";
import { buildingInProgressWidgetId } from "../../../thunks/widgets/utils/constants";

export const costBreakdownWidgetViewDataGridDataSelector = (
  state: RootState,
  includeDuplications?: boolean,
): ResourceExplorerGridDataWithId[] | undefined => {
  const data = costBreakdownWidgetDataSelector(
    state,
    buildingInProgressWidgetId,
  )?.total;

  if (!data) {
    return;
  }

  return formatCostBreakdownREDataGridData(data, includeDuplications) ?? [];
};
