import { FC, useCallback } from "react";
import { GroupingByTagValueTrigger } from "./GroupingByTagValueTrigger";
import { GroupingByTagValueOption } from "./GroupingByTagValueOption";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { resourceExplorerDataGroupingAvailableTagsSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerDataGroupingAvailableTagsSelector";
import { DropdownSelect } from "../../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { ProviderType } from "../../../../../../../../../../services/cloudchipr.api";
import { resourceExplorerGroupValuesSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupValuesSelector";
import { resourceExplorerProvidersSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerProvidersSelector";
import { GroupingByTagValuesHeader } from "../../../../../../../../common/resource-explorer/group-by-selector/components/GroupingByTagValuesHeader";
import { DropdownHeaderComponent } from "../../../../../../../../../common/select/dropdown-select/utils/types/types";
import { getResourcesExplorerGroupingLabel } from "../../../../../../resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";

interface GroupingByTagValueProps {
  onSubmit(values?: string[]): void;
}

export const GroupingByTagValue: FC<GroupingByTagValueProps> = ({
  onSubmit,
}) => {
  const costAllocationTags = useAppSelector(
    resourceExplorerDataGroupingAvailableTagsSelector,
  );

  const providers = useAppSelector(resourceExplorerProvidersSelector);

  const values = useAppSelector(resourceExplorerGroupValuesSelector);

  const options = costAllocationTags
    ?.filter(({ provider }) => providers.includes(provider as ProviderType))
    ?.map(({ value, provider }) => ({
      value,
      label: (
        <GroupingByTagValueOption
          value={value}
          provider={provider as ProviderType}
        />
      ),
    }));

  const DropdownHeader: DropdownHeaderComponent = useCallback(
    (props) => {
      return <GroupingByTagValuesHeader {...props} onSubmit={onSubmit} />;
    },
    [onSubmit],
  );

  if (!options) {
    return null;
  }

  return (
    <DropdownSelect
      label={getResourcesExplorerGroupingLabel(
        providers,
        "cost_allocation_tag",
      )}
      options={options}
      values={values ?? []}
      TriggerComponent={GroupingByTagValueTrigger}
      DropdownHeader={DropdownHeader}
      showSearch={false}
      showSelectAll={false}
      listWidth={320}
      submitHandlerOnClose={onSubmit}
      PopoverProps={{
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
    />
  );
};
