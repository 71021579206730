import { ChangeEvent, FC, useCallback, useMemo, useRef, useState } from "react";
import {
  Box,
  List,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { formatDate } from "../../../../utils/helpers/date-time/datetime-format";
import { PreDefinedRange, PreDefinedRanges } from "../utils/constants/types";
import {
  DateLabel,
  DateLabelNullable,
} from "../../../../services/cloudchipr.api";

interface DateRangePickerDateLabelsSectionProps {
  minDate?: string;
  ranges: PreDefinedRanges;
  selectedLabel?: DateLabelNullable;
  onSelect(label: DateLabel): void;
}

export const DateRangePickerDateLabelsSection: FC<
  DateRangePickerDateLabelsSectionProps
> = ({ ranges, onSelect, selectedLabel, minDate }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [search, setSearch] = useState("");

  const filteredRanges = useMemo(() => {
    return Object.values(ranges).filter((range) => {
      return range?.label?.toLowerCase().includes(search.toLowerCase().trim());
    });
  }, [ranges, search]);

  const searchChangeHandle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  return (
    <Stack ref={ref}>
      <Box p={1.5}>
        <TextField
          fullWidth
          size="xsmall"
          variant="outlined"
          InputProps={InputProps}
          onChange={searchChangeHandle}
          placeholder="Search quick ranges"
        />
      </Box>

      {!!filteredRanges.length && (
        <List sx={{ pt: 0, maxHeight: 330, overflow: "auto" }}>
          {filteredRanges.map((range) => {
            if (!range) {
              return null;
            }

            const disabled = moment(range.dateFrom).isBefore(minDate);

            return (
              <MenuItem
                key={range.label}
                disabled={disabled}
                selected={selectedLabel === range.dateLabel}
                onClick={onSelect.bind(null, range.dateLabel)}
              >
                <Tooltip arrow placement="right" title={getTooltipDates(range)}>
                  <Box flex={1}>{range.label}</Box>
                </Tooltip>
              </MenuItem>
            );
          })}
        </List>
      )}

      {!filteredRanges.length && (
        <Typography
          p={1.5}
          align="center"
          variant="caption"
          sx={{ wordBreak: "break-word" }}
          maxWidth={ref?.current?.clientWidth}
        >
          No labels found for “{search}”
        </Typography>
      )}
    </Stack>
  );
};

const InputProps = {
  startAdornment: (
    <InputAdornment position="start">
      <SearchIcon />
    </InputAdornment>
  ),
};

const getTooltipDates = (range: PreDefinedRange) =>
  range.dateFrom && range.dateTo
    ? `${formatDate(moment(range.dateFrom), {
        type: "date",
      })} - ${formatDate(moment(range.dateTo), { type: "date" })}`
    : "";
