import moment from "moment/moment";
import { resourceExplorerDatePeriodByIdSelector } from "./resourceExplorerDatePeriodByIdSelector";
import { resourceExplorerDateLabelSelector } from "./resourceExplorerDateLabelSelector";
import { RootState } from "../../../../store";
import { generateDateFromDateLabelV1 } from "../../../../../components/common/date-range-picker/utils/helpers/generateDateFromDateLabelV1";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";
import { organisationQuarterStartMonthSelector } from "../../../../common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { DateLabelNullable } from "../../../../../services/cloudchipr.api";

export const resourceExplorerDatesToFetchSelector = (
  state: RootState,
): {
  to: string;
  from: string;
  label: DateLabelNullable;
} => {
  const datePeriod = resourceExplorerDatePeriodByIdSelector(state);
  const quarterStartMonth = organisationQuarterStartMonthSelector(state);

  const label = resourceExplorerDateLabelSelector(state);
  const range = generateDateFromDateLabelV1(label, quarterStartMonth);

  const dateTo = formatDate(moment(range?.endDate), {
    type: "yearMonthDay",
  });
  const dateFrom = formatDate(moment(range?.startDate), {
    type: "yearMonthDay",
  });

  return {
    from: datePeriod.from || dateFrom,
    to: datePeriod.to || dateTo,
    label: label,
  };
};
