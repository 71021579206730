import { FC, useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { LiveFilteredTitle } from "./LiveFilteredTitle";
import { LiveFilteredResourcesDrawerOpenButton } from "./LiveFilteredResourcesOpenButton";
import { money } from "../../../../../../../../../utils/numeral/money";
import { useHover } from "../../../../../../../../../utils/hooks/useHover.hook";
import { LoadTrackedResourcesFor } from "../../../../../../../../../services/cloudchipr.api";
interface ResourceExplorerLiveFilteredProps {
  totalCost?: number;
  tooltip?: string;
  loadFor?: LoadTrackedResourcesFor;
  loadFrom: "widget" | "resource-explorer";
}
export const ResourceExplorerLiveFiltered: FC<
  ResourceExplorerLiveFilteredProps
> = ({ totalCost, loadFor, tooltip, loadFrom }) => {
  const enableLiveFilterCardDrawer = useFlag("EnableLiveFilterCardDrawer");
  const { ref, hovered } = useHover();

  const [total, setTotal] = useState(totalCost);

  const tooltipTitle =
    tooltip ??
    "Total monthly price of live filtered resources found in Live Usage & Mgmt for the current view.";

  useEffect(() => {
    if (totalCost || totalCost === 0) {
      setTotal(totalCost);
    }
  }, [totalCost]);

  return (
    <Stack
      bgcolor="grey.100"
      p={1}
      borderRadius={2}
      ref={ref}
      sx={{ position: "relative" }}
    >
      <LiveFilteredTitle tooltipTitle={tooltipTitle} />

      <Stack direction="row" alignItems="center">
        <Typography variant="h6" fontWeight="medium">
          {money(total)}
          {total !== undefined && (
            <Typography pl={0.25} variant="caption" color="text.secondary">
              /mo
            </Typography>
          )}
        </Typography>

        {enableLiveFilterCardDrawer && loadFor && (
          <LiveFilteredResourcesDrawerOpenButton
            hovered={hovered}
            loadFrom={loadFrom}
          />
        )}
      </Stack>
    </Stack>
  );
};
