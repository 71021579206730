import { CostByCategoryWidgetSetupType } from "../types/setups/costByCategorySetupType";
export const costByCategoryWidgetDefaultData: Omit<
  CostByCategoryWidgetSetupType,
  "categoryGroup" | "categoryIds"
> = {
  forecastOption: "month",
  widgetType: "costs_by_category_v2",
  chartType: "multiStack",
  frequency: "monthly",
  name: "Cost by category",
  displayFilteredResources: true,
  date: { from: null, to: null, label: "last_one_month" },
};
