import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../../store";
import { costAndUsageSumWidgetSetupSelector } from "../../../../selectors/setups/cost-and-usage/costAndUsageSumWidgetSetupSelector";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { DateLabelNullable } from "../../../../../../services/cloudchipr.api";
import { fetchCostAndUsageSumWidgetDataBySetupThunk } from "../fetchCostAndUsageSumWidgetDataBySetupThunk";

export const costAndUsageSumDateChangeThunk = createAsyncThunk(
  "dashboards/costAndUsageDateChange",
  async (
    date: {
      from: string | null;
      to: string | null;
      label: DateLabelNullable;
    },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const setup = costAndUsageSumWidgetSetupSelector(state);

    if (!setup) {
      return;
    }
    dispatch(setWidgetSetup({ ...setup, date }));
    dispatch(fetchCostAndUsageSumWidgetDataBySetupThunk());
  },
);
