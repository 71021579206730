import { FC } from "react";
import { Chip, Stack, Typography } from "@mui/material";
import { TagsPopover } from "./TagsPopover";
import { Tags } from "../../../../services/cloudchipr.api";
import { useMenuHook } from "../../../../utils/hooks/useMenu.hook";
import { TypographyWithTooltip } from "../../TypographyWithTooltip";
import { useAppSelector } from "../../../../store/hooks";
import { smartTagCustomValueSelector } from "../../../../store/settings/selectors/smartTagCustomValueSelector";

interface TagCellProps {
  tags: Tags;
  smartTags?: boolean;
  widthToEllipsis?: number;
}

export const TagCell: FC<TagCellProps> = ({
  tags,
  smartTags,
  widthToEllipsis,
}) => {
  const smartTagCustomValue = useAppSelector(smartTagCustomValueSelector);

  if (smartTags) {
    tags = tags.filter(({ key }) => smartTagCustomValue === key);
  }

  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  if (tags.length === 0) {
    return null;
  }

  const nameTag = smartTags
    ? tags[0]
    : tags.find((t) => t.key === "Name") || tags[0];

  return (
    <Stack direction="row" alignItems="center">
      <Chip
        size="small"
        onClick={openMenu}
        label={
          <TypographyWithTooltip
            variant="inherit"
            maxWidth={widthToEllipsis}
            title={`${nameTag.key}: ${nameTag.value}`}
          >
            <Typography
              variant="inherit"
              color={smartTags ? "primary" : "text.secondary"}
              component="span"
            >
              {nameTag.key}:{" "}
            </Typography>
            {nameTag.value}
          </TypographyWithTooltip>
        }
        color={smartTags ? "primary" : "default"}
        variant={smartTags ? "outlined" : "filled"}
      />
      {tags.length > 1 && (
        <Chip
          label={`+${tags.length - 1}`}
          onClick={openMenu}
          variant="outlined"
          size="small"
          sx={{ ml: 0.5 }}
        />
      )}
      {open && (
        <TagsPopover
          open={open}
          anchor={anchor}
          otherTags={tags}
          closeMenu={closeMenu}
        />
      )}
    </Stack>
  );
};
