import { FC } from "react";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import { useLocation } from "react-router-dom";
import { NavItem } from "../nav-item/NavItem";
import AWSSvgIcon from "../../../../assets/images/logos/providers/AWSSvgIcon";
import GCPSvgIcon from "../../../../assets/images/logos/providers/GCPSvgIcon";
import AzureSvgIcon from "../../../../assets/images/logos/providers/AzureSvgIcon";
import { useAppSelector } from "../../../../store/hooks";
import { liveUsageNavigationItemsSelector } from "../../../../store/accounts/selectors/liveUsageNavigationItemsSelector";
import { ProviderType } from "../../../../services/cloudchipr.api";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import { currentAccountSelector } from "../../../../store/account/selectors/current-account/currentAccountSelector";
import { noOrgAccountOrgId } from "../../../pages/accounts-group/utils/helpers/noOrganizationConstants";

export const LiveUsageNavigation: FC = () => {
  const allAccountsGroupedByProviderAndOrg = useAppSelector(
    liveUsageNavigationItemsSelector,
  );
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);
  const currentAccount = useAppSelector(currentAccountSelector);

  const location = useLocation();
  const selected = ["/aws", "/gcp", "/azure"].some((item) =>
    location.pathname.startsWith(item),
  );

  return (
    <NavItem
      primary="Live Usage and Mgmt"
      icon={PlayCircleOutlineOutlinedIcon}
      navCollapsed={navigationCollapsed}
      selected={selected}
    >
      {allAccountsGroupedByProviderAndOrg.map(({ org, provider, path }) => {
        const selected =
          !!currentAccount &&
          path.includes(
            `/${currentAccount?.type}/${
              currentAccount?.group_id ?? noOrgAccountOrgId
            }`,
          );

        return (
          <NavItem
            nested
            key={path}
            primary={org}
            icon={providerIconsMap.get(provider)}
            to={path}
            selected={selected}
          />
        );
      })}
    </NavItem>
  );
};

const providerIconsMap = new Map<ProviderType, any>([
  ["aws", AWSSvgIcon],
  ["gcp", GCPSvgIcon],
  ["azure", AzureSvgIcon],
]);
