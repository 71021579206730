import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { getCostAllocationTagEnablingFixedCacheKey } from "../../../thunks/filters/enableCostAllocationTagKeyThunk";
import { RootState } from "../../../../store";

export const enableCostAllocationTagKeyLoadingSelector = (
  state: RootState,
  key: string,
) => {
  return cloudChiprApi.endpoints.patchUsersMeOrganisationsCurrentProvidersByProviderTagKeys.select(
    getCostAllocationTagEnablingFixedCacheKey(key),
  )(state)?.isLoading;
};
