import { costBreakdownWidgetCostsSelector } from "./costBreakdownWidgetCostsSelector";
import { RootState } from "../../../../store";
import {
  ForecastedCost,
  ForecastOption,
} from "../../../../../services/cloudchipr.api";

export const costBreakdownWidgetViewForecastedCostSelector = (
  state: RootState,
  forecastedOption: ForecastOption,
): ForecastedCost | undefined => {
  const costs = costBreakdownWidgetCostsSelector(state);

  return costs?.forecasted_costs?.find(
    (item) => item.forecast_option === forecastedOption,
  );
};
