import { FC, useCallback, useMemo } from "react";
import { Box, IconButton } from "@mui/material";
import { Table } from "@tanstack/react-table";
import { UnfoldLess, UnfoldMore } from "@mui/icons-material";
import { ResourceDataType } from "../../../../../../../../../../../store/account/utils/types/types";
import { getAllExpandedRowsIds } from "../../utils/helpers/getAllExpandedRowsIds";

interface RowSelectCheckboxHeaderCellProps {
  table: Table<ResourceDataType>;
}

export const RowExpandingHeaderCell: FC<RowSelectCheckboxHeaderCellProps> = ({
  table,
}) => {
  const isAllExpanded = table.getIsAllRowsExpanded();
  const rows = table.getRowModel().rows;

  const expandedRowsIds = useMemo(() => {
    return getAllExpandedRowsIds(rows, true);
  }, [rows]);

  const expanderClickHandler = useCallback(() => {
    if (!isAllExpanded) {
      table.setExpanded(expandedRowsIds);
    } else {
      table.toggleAllRowsExpanded();
    }
  }, [table, isAllExpanded, expandedRowsIds]);

  return (
    <Box sx={{ backgroundColor: "transparent" }}>
      <IconButton
        size="small"
        onClick={expanderClickHandler}
        disabled={!rows.length}
      >
        {isAllExpanded ? <UnfoldLess /> : <UnfoldMore />}
      </IconButton>
    </Box>
  );
};
