import { FC, useCallback } from "react";
import { ListItemButton, ListItemText } from "@mui/material";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { CountChipWithTooltip } from "./CountChipWithTooltip";
import { FilterTriggerComponentProps } from "../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";

interface GroupingByTagValueTriggerProps extends FilterTriggerComponentProps {
  selected: boolean;
}

export const GroupingByTagValueTrigger: FC<GroupingByTagValueTriggerProps> = ({
  onClick,
  label,
  selectedValues,
  selected,
}) => {
  const tagsClickHandler = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      onClick(event);
    },
    [onClick],
  );

  return (
    <ListItemButton
      disableRipple
      onClick={tagsClickHandler}
      selected={selected}
    >
      <ListItemText primary={label} />
      <CountChipWithTooltip values={selectedValues} />
      <KeyboardArrowRightOutlinedIcon fontSize="inherit" />
    </ListItemButton>
  );
};
