import { filterTemplatesByAccountIdSelector } from "./filterTemplatesByAccountIdSelector";
import { AccountFilterSetV2 } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { currentAccountIdSelector } from "../../../account/selectors/current-account/currentAccountIdSelector";

export const getAccountSortedFilterTemplatesSelector = (
  state: RootState,
): AccountFilterSetV2[] => {
  const accountId = currentAccountIdSelector(state);

  if (!accountId) {
    return [];
  }

  return filterTemplatesByAccountIdSelector(state, {
    accountId,
  });
};
