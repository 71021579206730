import { FC, useCallback } from "react";
import { Box } from "@mui/material";
import { useAppSelector } from "../../../../../../../store/hooks";
import { utilizationAndCoverageDrawerDateGranularitySelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/options/utilizationAndCoverageDrawerDateGranularitySelector";
import { formatDateByFrequencyType } from "../../../../../../../utils/helpers/date-time/datetime-format";
import { CommitmentsChart } from "../../common/chart/CommitmentsChart";
import { utilizationAndCoverageDetailsGranularDataGroupedByDateSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/utilizationAndCoverageDetailsGranularDataGroupedByDateSelector";
import { utilizationAndCoverageDetailsLoadingSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/data/utilizationAndCoverageDetailsLoadingSelector";

interface SavingsPlanDrawerChartProps {
  ChartHeader?: FC;
}

export const CommitmentsDrawerChart: FC<SavingsPlanDrawerChartProps> = ({
  ChartHeader,
}) => {
  const dataGroupedByDate = useAppSelector(
    utilizationAndCoverageDetailsGranularDataGroupedByDateSelector,
  );
  const dataPoint = useAppSelector(
    utilizationAndCoverageDrawerDateGranularitySelector,
  );
  const loading = useAppSelector(utilizationAndCoverageDetailsLoadingSelector);

  const labelFormatter = useCallback(
    (value: string) => formatDateByFrequencyType(dataPoint, value),
    [dataPoint],
  );

  return (
    <Box mt={2}>
      <CommitmentsChart
        loading={loading}
        availableTypes={["area"]}
        labelFormatter={labelFormatter}
        dataGroupedByDate={dataGroupedByDate}
        ChartHeader={ChartHeader}
      />
    </Box>
  );
};
