import {
  ColumnDef,
  FilterFn,
  Row,
  SortingFn,
  TableOptions,
  TableState,
} from "@tanstack/react-table";
import { InitialTableState } from "@tanstack/table-core";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";

type CellStylesCbType = (row: Row<any>) => SxProps<Theme>;
export const isCellStylesCallable = (
  cellStyles: any,
): cellStyles is CellStylesCbType => typeof cellStyles === "function";

declare module "@tanstack/table-core" {
  interface FilterFns {
    tagFilter: FilterFn<unknown>;
    defaultFilter: FilterFn<unknown>;
    moneyFilter: FilterFn<unknown>;
    dateFilter: FilterFn<unknown>;
    globalFilter: FilterFn<unknown>;
    strictEqualityFilter: FilterFn<unknown>;
    cpuFilter: FilterFn<unknown>;
    accountDataFilter: FilterFn<unknown>;
    createdByFilter: FilterFn<unknown>;
  }
  interface SortingFns {
    sortWithConvertingToNumber: SortingFn<unknown>;
    sortWithCreatedBy: SortingFn<unknown>;
  }
  // @ts-expect-error | types looks ok, can't understand what's the reason
  interface ColumnMeta {
    alwaysShowSortIcon?: boolean;
    sticky?: "left" | "right";
    hideFromSettings?: boolean;
    cellStyles?: SxProps<Theme> | CellStylesCbType;

    // if the title does not just text, this can be used to display in other places so as not to render unnecessary components.
    headerTitle?: string;
  }
}

// todo: this types should be moved to resources
export type TableCellTypes =
  | "tag"
  | "smartTag"
  | "money"
  | "nodeGroupTotalCostChip"
  | "identifierWithRecommendationLink"
  | "date"
  | "statistic"
  | "cpuStatistic"
  | "pricingTier"
  | "hasField"
  | "identifier"
  | "copyWithTooltip"
  | "withCopy"
  | "byteToGib"
  | "attached"
  | "nodesCount"
  | "withTooltipCell"
  | "createdBy"
  | "noWrapText"
  | "accessMode"
  | "account"
  | "unusedSince"
  | "jiraTicketStatus"
  | "instances"
  | "cardTableFilteredResults"
  | "availability"
  | "state"
  | "nodeGroupMode"
  | "providerStatus"
  | "resourceState"
  | "inUse"
  | "billable"
  | "exists"
  | "numeralWithNA"
  | "stringWithNA"
  | "capacityMode"
  | "wcuReservation"
  | "rcuReservation"
  | "readUsage"
  | "writeUsage"
  | "moneyStoppedCell"
  | "backupType"
  | "stateWithUnusedSinceCell"
  | "networkInOut"
  | "vCpuReservation"
  | "processedData"
  | "natGatewayIdCell"
  | "computeMetricsChart"
  | "memoryUsedMetricsChart"
  | "memoryAvailableMetricsChart"
  | "dbMetricsChart"
  | "storageMetricsChart"
  | "filterTemplate"
  | "liveFilteredIdentifier"
  | "liveFilteredAccount"
  | undefined;

export type ColumnSetupType<T> = ColumnDef<T> & {
  type?: TableCellTypes;
  headerTooltip?: string;
  accessorKey?: keyof T;
};

export type TableOptionsType = TableOptions<any> & {
  state: Partial<TableState>;
  initialState: InitialTableState;
};
