import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResourceType } from "../../../services/cloudchipr.api";
import { RootState } from "../../store";
import {
  CleanDetails,
  setCleanOpen,
  setCurrentCleanId,
  setNewClean,
} from "../cleanSlice";
import { currentAccountIdSelector } from "../../account/selectors/current-account/currentAccountIdSelector";
import { currentAccountProviderTypeSelector } from "../../account/selectors/current-account/currentAccountProviderTypeSelector";
import { cleanProcessResourcesSelector } from "../selectors/cleanProcessResourcesSelector";
import { NEW_CLEAN_ID } from "../utils/constants";
import { currentAccountSelectedRowsWithHierarchyByResourceTypeSelector } from "../../account/selectors/current-account/currentAccountSelectedRowsWithHierarchyByResourceTypeSelector";

export const createCleanThunk = createAsyncThunk(
  "createCleanThunk",
  (resourceType: ResourceType, { dispatch, getState }) => {
    const state = getState() as RootState;
    const accountId = currentAccountIdSelector(state);
    const currentAccountType = currentAccountProviderTypeSelector(state);

    const selectedResources =
      currentAccountSelectedRowsWithHierarchyByResourceTypeSelector(
        state,
        resourceType,
      );

    const type = Object.keys(selectedResources ?? {})[0] as ResourceType;
    const data = Object.values(selectedResources ?? {})[0];

    if (!type || !data) {
      return;
    }
    const { resources, excludedResources } = cleanProcessResourcesSelector(
      state,
      type,
      data ?? {},
    );

    if (accountId && currentAccountType) {
      const cleanProcess: CleanDetails = {
        accountId,
        resourceType: type,
        resources,
        excludedResources,
      };

      dispatch(setNewClean(cleanProcess));
      dispatch(setCurrentCleanId(NEW_CLEAN_ID));
      dispatch(setCleanOpen(true));
    }
  },
);
