import { FC, useCallback } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useFlag } from "@unleash/proxy-client-react";
import { useAppDispatch } from "../../../../../../../../store/hooks";
import { useAppAbility } from "../../../../../../../../services/permissions";
import { editWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/common/editWidgetThunk";
import { WidgetType } from "../../../../../../../../services/cloudchipr.api";
import { editWidgetThunkV2 } from "../../../../../../../../store/dashboards/thunks/widgets/common/editWidgetThunkV2";

interface WidgetEditActionMenuItemProps {
  widgetId: string;
  widgetType: WidgetType;
  onClose(): void;
}

export const WidgetEditActionMenuItem: FC<WidgetEditActionMenuItemProps> = ({
  widgetType,
  widgetId,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotEditWidget = cannot("edit", "dashboard");
  const enableCostBreakdownNewEndpoints = useFlag(
    "EnableCostBreakdownNewEndpoints",
  );
  const editWidgetHandler = useCallback(() => {
    if (enableCostBreakdownNewEndpoints) {
      dispatch(
        editWidgetThunkV2({
          widgetId,
          widgetType,
        }),
      );
    } else {
      dispatch(
        editWidgetThunk({
          widgetId,
          widgetType,
        }),
      );
    }

    onClose();
  }, [
    dispatch,
    onClose,
    widgetId,
    widgetType,
    enableCostBreakdownNewEndpoints,
  ]);

  return (
    <MenuItem onClick={editWidgetHandler} disabled={canNotEditWidget}>
      <ListItemIcon>
        <EditOutlinedIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="body2">Edit</Typography>
      </ListItemText>
    </MenuItem>
  );
};
