import { costBreakdownV2WidgetCostsSelector } from "./costBreakdownV2WidgetCostsSelector";
import { costBreakdownV2WidgetForecastOptionSelector } from "./costBreakdownV2WidgetForecastOptionSelector";
import { RootState } from "../../../../../store";
import { ForecastedCost } from "../../../../../../services/cloudchipr.api";

export const costBreakdownV2WidgetForecastedCostsSumSelector = (
  state: RootState,
  widgetId: string,
): ForecastedCost | undefined => {
  const forecastedOption = costBreakdownV2WidgetForecastOptionSelector(
    state,
    widgetId,
  );
  if (!forecastedOption) {
    return;
  }
  const costs = costBreakdownV2WidgetCostsSelector(state, widgetId);

  return costs?.forecasted_costs?.find(
    (item) => item.forecast_option === forecastedOption,
  );
};
