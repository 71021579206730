import { RootState } from "../../../../../store";
import { costAndUsageSumSetupPropertyByKeySelector } from "../../../setups/cost-and-usage/costAndUsageSumSetupPropertyByKeySelector";
import { buildingInProgressWidgetIdWithNoFilter } from "../../../../thunks/widgets/utils/constants";
import { widgetREViewDataLoadingSelector } from "../../common/widget-re-view/widgetREViewDataLoadingSelector";

export const costAndUsageGetWidgetDataLoadingSelector = (
  state: RootState,
  widgetId?: string,
): boolean => {
  const setupViewId =
    costAndUsageSumSetupPropertyByKeySelector("viewId")(state);

  const data = setupViewId ? buildingInProgressWidgetIdWithNoFilter : widgetId;
  return widgetREViewDataLoadingSelector(state, data ?? "");
};
