import { FC, Fragment, useCallback } from "react";
import { Divider, ListItem, ListItemButton, Typography } from "@mui/material";
import { FilterSearchBox } from "../../../../../common/select/dropdown-select/components/FilterSearchBox";
import { DropdownHeaderComponentProps } from "../../../../../common/select/dropdown-select/utils/types/types";

interface GroupingByValueAllKeysProps extends DropdownHeaderComponentProps {
  onSubmit(values: string[]): void;
}

export const GroupingByTagValuesHeader: FC<GroupingByValueAllKeysProps> = ({
  onSubmit,
  onSearchChange,
  onClose,
}) => {
  const handleClick = useCallback(() => {
    onSubmit([]);
    onClose();
  }, [onSubmit, onClose]);

  return (
    <Fragment>
      <FilterSearchBox onChange={onSearchChange} />
      <Divider />
      <ListItem sx={{ p: 0 }}>
        <ListItemButton sx={{ py: 2 }} onClick={handleClick}>
          <Typography variant="body2">All Keys</Typography>
        </ListItemButton>
      </ListItem>
    </Fragment>
  );
};
