import { FC } from "react";
import { ForecastedCostHeaderCell } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/ForecastedCostHeaderCell";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { costBreakdownV2WidgetForecastOptionSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetForecastOptionSelector";
import { costBreakdownV2WidgetForecastedCostsSumSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetForecastedCostsSumSelector";

interface CostBreakdownWidgetTableForecastedCostHeaderCellProps {
  widgetId: string;
}
export const CostBreakdownV2WidgetTableForecastedCostHeaderCell: FC<
  CostBreakdownWidgetTableForecastedCostHeaderCellProps
> = ({ widgetId }) => {
  const forecastOption = useAppSelector((state) =>
    costBreakdownV2WidgetForecastOptionSelector(state, widgetId),
  );

  const forecastedCost = useAppSelector((state) =>
    costBreakdownV2WidgetForecastedCostsSumSelector(state, widgetId),
  );

  if (!forecastOption) {
    return null;
  }

  return (
    <ForecastedCostHeaderCell
      forecastOption={forecastOption}
      forecastedCost={forecastedCost}
    />
  );
};
