import { FC, useMemo } from "react";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";
import { noOrgAccountOrgId } from "../../../../accounts-group/utils/helpers/noOrganizationConstants";
import { Breadcrumbs } from "../../../../common/breadcrumbs/Breadcrumbs";
import { useAppSelector } from "../../../../../../store/hooks";
import { currentAccountProviderTypeSelector } from "../../../../../../store/account/selectors/current-account/currentAccountProviderTypeSelector";
import { SelectedAccountNavigation } from "../../navigation/components/selected-account/SelectedAccountNavigation";

export const AccountBreadcrumbs: FC = () => {
  const currentAccountProviderType = useAppSelector(
    currentAccountProviderTypeSelector,
  );

  const accountPageBreadcrumbs = useMemo(() => {
    if (!currentAccountProviderType) {
      return null;
    }

    return [
      {
        title: `${getProviderName(currentAccountProviderType, {
          title: true,
        })} ${getProviderName(currentAccountProviderType, {
          plural: true,
          capitalize: true,
        })}`,

        to: `/${currentAccountProviderType}/${noOrgAccountOrgId}/accounts`,
      },
      {
        title: <SelectedAccountNavigation />,
      },
    ];
  }, [currentAccountProviderType]);

  if (!accountPageBreadcrumbs) {
    return null;
  }

  return <Breadcrumbs breadcrumbs={accountPageBreadcrumbs} />;
};
