import { FC, useCallback } from "react";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { ListItemButton, ListItemText } from "@mui/material";
import { GroupingByTagChip } from "./GroupingByTagChip";
import { FilterTriggerComponentProps } from "../../../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { resourceExplorerGroupingSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";

export const GroupingByTagValueTrigger: FC<FilterTriggerComponentProps> = ({
  onClick,
  label,
}) => {
  const activeGrouping = useAppSelector(resourceExplorerGroupingSelector);
  const selected = activeGrouping === "cost_allocation_tag";
  const tagsClickHandler = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      onClick(event);
    },
    [onClick],
  );

  return (
    <ListItemButton
      disableRipple
      onClick={tagsClickHandler}
      selected={selected}
    >
      <ListItemText primary={label} />
      <GroupingByTagChip />
      <KeyboardArrowRightOutlinedIcon fontSize="inherit" />
    </ListItemButton>
  );
};
