import { getCurrentProvidersByProviderBillingStatusSelector } from "./getCurrentProvidersByProviderBillingStatusSelector";
import {
  BillingAccountStatus,
  BillingCollectionStatus,
  ProviderType,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { enabledProvidersSelector } from "../billing-analytics/enabled-providers/enabledProvidersSelector";

export const billingAccountStatusAllProvidersSelector = (
  state: RootState,
): Partial<
  Record<
    ProviderType,
    {
      status?: BillingCollectionStatus;
      error?: string;
    }
  >
> => {
  const enabledProviders = enabledProvidersSelector(state);
  const enabledProvidersArr = Array.from(enabledProviders);

  return enabledProvidersArr.reduce(
    (result, provider) => {
      const data = getCurrentProvidersByProviderBillingStatusSelector(
        state,
        provider,
      )?.data;
      result[provider] = data?.billing_account_statuses?.find(
        (item) => item.status === data.status,
      );
      return result;
    },
    {} as Partial<Record<ProviderType, BillingAccountStatus>>,
  );
};
