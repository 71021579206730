import { RootState } from "../../../../store";
import { CostByCategoryWidgetSetupType } from "../../../utils/types/setups/costByCategorySetupType";
import { widgetSetupSelector } from "../widgetSetupSelector";

export const costByCategorySetupSelector = (
  state: RootState,
): CostByCategoryWidgetSetupType | undefined => {
  if (!widgetSetupSelector(state)) {
    return;
  }
  return widgetSetupSelector(state) as CostByCategoryWidgetSetupType;
};
