import { FC, memo } from "react";
import { Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../../../store/hooks";
import {
  DateDataPointNullable,
  DateLabelNullable,
} from "../../../../../../../../services/cloudchipr.api";
import { CostBreakdownV2WidgetContentCosts } from "../CostBreakdownV2WidgetContentCosts";
import { ChartType } from "../../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { MultiTypeChartProvider } from "../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { costBreakdownV2WidgetChartDataSelector } from "../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetChartDataSelector";
import { costBreakdownV2WidgetTotalCostDateDetailsSelector } from "../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetTotalCostDateDetailsSelector";
import { CostBreakdownV2WidgetChart } from "../../../../adding-widget/widget-create/widget-setups/cost-breakdown-v2/components/chart-view/CostBreakdownV2WidgetChart";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";

interface CostBreakdownWidgetChartViewProps {
  widgetId: string;
  chartType: ChartType;
  dateLabel?: DateLabelNullable;
  dateDataPoint: DateDataPointNullable;
}

export const CostBreakdownV2WidgetChartView: FC<CostBreakdownWidgetChartViewProps> =
  memo(({ chartType, dateDataPoint, widgetId, dateLabel }) => {
    const chartData = useAppSelector((state) =>
      costBreakdownV2WidgetChartDataSelector(state, widgetId),
    );

    const dateDetails = useAppSelector((state) =>
      costBreakdownV2WidgetTotalCostDateDetailsSelector(state, widgetId),
    );
    const loading = useAppSelector(
      costBreakdownV2SetupPropertyByKeySelector("loading"),
    );
    if (!chartData) {
      return null;
    }

    return (
      <Stack px={2} py={1} gap={1}>
        <MultiTypeChartProvider
          value={{
            initialChartType: chartType,
            data: chartData ?? [],
          }}
        >
          <CostBreakdownV2WidgetContentCosts
            widgetId={widgetId}
            dateLabel={dateLabel}
          />
          <CostBreakdownV2WidgetChart
            dateDataPoint={dateDataPoint}
            chartType={chartType}
            dateDetails={dateDetails}
            loading={loading}
          />
        </MultiTypeChartProvider>
      </Stack>
    );
  });
