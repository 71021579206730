import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerDataGridDataPrevPeriodTotalCostSelector } from "./resourceExplorerDataGridDataPrevPeriodTotalCostSelector";
import { resourceExplorerWidgetTotalCostSelector } from "../resource-explorer-widget/resourceExplorerWidgetTotalCostSelector";
import { calculateTotalCostTrend } from "../../../../../components/pages/utils/helpers/calculateTotalCostTrend";

export const resourceExplorerDataGridDataTotalTrendSelector =
  createDraftSafeSelector(
    [
      resourceExplorerWidgetTotalCostSelector,
      resourceExplorerDataGridDataPrevPeriodTotalCostSelector,
    ],
    (totalCost, previousPeriodCost): number | null => {
      const total = totalCost ?? 0;
      const previous = previousPeriodCost ?? 0;

      const trend = calculateTotalCostTrend(total, previous);

      return previous > 0 ? trend : null;
    },
  );
