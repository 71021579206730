import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { resourceExplorerAppliedFiltersSelector } from "../resourceExplorerAppliedFiltersSelector";

export const resourceExplorerFilterKeysAsProvidersSelector =
  createDraftSafeSelector(
    [resourceExplorerAppliedFiltersSelector],
    (filters) => {
      return (Object.keys(filters) as ProviderType[]) ?? [];
    },
  );
