import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { TrendColumnHeaderCell } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/TrendColumnHeaderCell";
import { costBreakdownWidgetTableViewTrendSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/widget-view/table/costBreakdownWidgetTableViewTrendSelector";

interface CostBreakdownWidgetTableViewTrendProps {
  widgetId?: string;
}
export const CostBreakdownWidgetTableViewTrend: FC<
  CostBreakdownWidgetTableViewTrendProps
> = ({ widgetId }) => {
  const trend = useAppSelector((state) =>
    costBreakdownWidgetTableViewTrendSelector(state, widgetId),
  );
  return <TrendColumnHeaderCell trend={trend} />;
};
