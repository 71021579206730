import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { costBreakdownV2WidgetCostsSelector } from "./costBreakdownV2WidgetCostsSelector";
import { calculateTotalCostTrend } from "../../../../../../components/pages/utils/helpers/calculateTotalCostTrend";

export const costBreakdownV2WidgetTableTrendSelector = createDraftSafeSelector(
  [costBreakdownV2WidgetCostsSelector],
  (costs): number | null => {
    const total = costs?.total.cost ?? 0;
    const previous = costs?.previousPeriodTotal?.cost ?? 0;

    const trend = calculateTotalCostTrend(total, previous);

    return previous > 0 ? trend : null;
  },
);
