import { costByCategoryWidgetDataSelector } from "./costByCategoryWidgetDataSelector";
import { RootState } from "../../../../../store";
import { PostUsersMeOrganisationsCurrentWidgetsCostByCategoryApiResponse } from "../../../../../../services/cloudchipr.api";

export const costByCategoryWidgetDataCategoriesSelector = (
  state: RootState,
  id: string,
):
  | PostUsersMeOrganisationsCurrentWidgetsCostByCategoryApiResponse["categories_data"]
  | undefined => {
  const data = costByCategoryWidgetDataSelector(state, id);

  return data?.categories_data;
};
