import { FC } from "react";
import { Stack } from "@mui/material";
import {
  ColumnOrderState,
  ColumnResizeMode,
  flexRender,
  Header,
} from "@tanstack/react-table";
import { HeaderDnDWrapper } from "./HeaderDnDWrapper";
import { TableHeaderCellSortIcons } from "./TableHeaderCellSortIcons";
import { ResizeBox } from "./ResizeBox";
import { Filter } from "../filters/Filter";

interface TableHeaderCellContentProps {
  header: Header<any, any>;
  filtersEnabled: boolean;
  sortEnabled: boolean;
  dndEnabled: boolean;
  hovered: boolean;
  resizeMode?: ColumnResizeMode;
  onResizeEndHandler(): void;
  resizingDeltaOffset: number | null;
  columnOrder: ColumnOrderState;
  setColumnOrder(order: ColumnOrderState): void;
}

export const TableHeaderCellContent: FC<TableHeaderCellContentProps> = ({
  header,
  filtersEnabled,
  dndEnabled,
  sortEnabled,
  resizeMode,
  hovered,
  onResizeEndHandler,
  resizingDeltaOffset,
  columnOrder,
  setColumnOrder,
}) => {
  if (header.isPlaceholder) {
    return null;
  }

  const sortedBy = header.column.getIsSorted();
  const canSort = header.column.getCanSort();
  const showSort = header.column.columnDef.meta?.alwaysShowSortIcon;
  const canResize = header.column.getCanResize();
  const isResizing =
    header.column.getIsResizing() ||
    (hovered && canResize && resizingDeltaOffset === null);

  return (
    <HeaderDnDWrapper
      header={header}
      wrap={dndEnabled}
      columnOrder={columnOrder}
      setColumnOrder={setColumnOrder}
    >
      <Stack sx={{ whiteSpace: "nowrap" }}>
        <Stack direction="row" alignItems="center">
          {flexRender(header.column.columnDef.header, header.getContext())}

          {sortEnabled && (
            <TableHeaderCellSortIcons
              canSort={canSort}
              sortedBy={sortedBy}
              hover={showSort || hovered}
              onClick={
                sortEnabled
                  ? header.column.getToggleSortingHandler()
                  : undefined
              }
            />
          )}
        </Stack>

        {filtersEnabled && <Filter column={header.column} />}
      </Stack>

      {resizeMode && canResize && (
        <ResizeBox
          resizing={isResizing}
          resizeEndCallback={onResizeEndHandler}
          resizeHandler={header.getResizeHandler()}
          resizeMode={resizeMode}
          deltaOffset={resizingDeltaOffset}
        />
      )}
    </HeaderDnDWrapper>
  );
};
