import { getCurrentProvidersByProviderBillingStatusSelector } from "./getCurrentProvidersByProviderBillingStatusSelector";
import {
  BillingCollectionStatus,
  ProviderType,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

export const billingStatusByProviderSelector = (
  state: RootState,
  provider: ProviderType,
): BillingCollectionStatus | undefined => {
  return getCurrentProvidersByProviderBillingStatusSelector(state, provider)
    .data?.status;
};
