import { formatDate } from "./date-time/datetime-format";
import {
  Date,
  DateLabel,
  Dates,
  DateType,
  DateUnit,
} from "../../services/cloudchipr.api";

export const covertDatesToQueryParam = (
  dates: Dates,
): {
  dateFrom?: Date;
  dateTo?: Date;
  dateLabel?: DateLabel;
  dateType?: DateType;
  dateValue?: number;
  dateUnit?: DateUnit;
} => {
  return {
    dateFrom: dates.from
      ? formatDate(dates.from, { type: "yearMonthDay" })
      : undefined,
    dateTo: dates.to
      ? formatDate(dates.to, { type: "yearMonthDay" })
      : undefined,
    dateLabel: dates.label ?? undefined,
    dateType: dates.type ?? undefined,
    dateValue: dates.value ?? undefined,
    dateUnit: dates.unit ?? undefined,
  };
};
