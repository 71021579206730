import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  ResourceType,
} from "../../../../services/cloudchipr.api";
import { currentAccountIdSelector } from "../../selectors/current-account/currentAccountIdSelector";
import { RootState } from "../../../store";
import { currentAccountSelectedRegionsSelector } from "../../selectors/current-account/currentAccountSelectedRegionsSelector";
import { appliedFiltersByResourceTypeSelector } from "../../../filters/selectors/appliedFiltersByResourceTypeSelector";
import { addRegionsToFilters } from "../../../../utils/helpers/addRegionsToFilters";
import { getFirstParentInHierarchyResourceType } from "../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";

export const getCurrentAccountResourceTypeWithFiltersDataThunk =
  createAsyncThunk(
    "getCurrentAccountResourceTypeWithFiltersDataThunk",
    async (resourceType: ResourceType, { dispatch, getState }) => {
      const state = getState() as RootState;
      const accountId = currentAccountIdSelector(state);
      const regions = currentAccountSelectedRegionsSelector(state);

      const type =
        getFirstParentInHierarchyResourceType(resourceType) ?? resourceType;
      if (!accountId) {
        return;
      }

      let appliedFilter = appliedFiltersByResourceTypeSelector(state, type);

      if (!appliedFilter) {
        return;
      }

      appliedFilter = addRegionsToFilters(regions, appliedFilter);

      dispatch(
        cloudChiprApi.endpoints.postUsersMeV2OrganisationsCurrentByTypeResources.initiate(
          {
            type: type,
            body: {
              account_ids: [accountId],
              filter: appliedFilter,
            },
          },
          { fixedCacheKey: type },
        ),
      ).unwrap();
    },
  );
