import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { resourceExplorerProvidersSelector } from "../../selectors/current-resource-explorer/resourceExplorerProvidersSelector";
import { ProviderType } from "../../../../services/cloudchipr.api";
import {
  addProvider,
  removeFilterByProvider,
  removeProvider,
  setResourceExplorerData,
} from "../../resourceExplorerSlice";
import { getResourceExplorerWidgetDataThunk } from "../widgets/getResourceExplorerWidgetDataThunk";
import { resourceExplorerGroupingSelector } from "../../selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { resourceExplorerDefaultData } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/constants/common";
import { setResourceExplorerPathThunk } from "../widgets/path/setResourceExplorerPathThunk";
import { getDefaultGroupings } from "../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/helpers/getDefaultGroupings";

export const resourceExplorerProviderChangeThunk = createAsyncThunk(
  "resourceExplorer/resourceExplorerProviderChange",
  async (provider: ProviderType, { dispatch, getState }) => {
    const state = getState() as RootState;

    const providers = resourceExplorerProvidersSelector(state);

    if (providers.includes(provider)) {
      dispatch(removeProvider(provider));
      dispatch(removeFilterByProvider(provider));
    } else {
      dispatch(addProvider(provider));
    }

    const updatedState = getState() as RootState;

    const groupBy = resourceExplorerGroupingSelector(updatedState);
    const { disabledGroupings } = getDefaultGroupings([...providers, provider]);

    if (groupBy && !!disabledGroupings?.[groupBy]) {
      dispatch(setResourceExplorerData(resourceExplorerDefaultData));
      dispatch(setResourceExplorerPathThunk(false));
    }

    dispatch(getResourceExplorerWidgetDataThunk());
  },
);
