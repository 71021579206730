import { costBreakdownV2WidgetSetupCostsSelector } from "./costBreakdownV2WidgetSetupCostsSelector";
import { costBreakdownV2WidgetSetupForecastedOptionSelector } from "./costBreakdownV2WidgetSetupForecastedOptionSelector";
import { RootState } from "../../../../../store";
import { ForecastedCost } from "../../../../../../services/cloudchipr.api";

export const costBreakdownV2WidgetSetupForecastedCostsSumSelector = (
  state: RootState,
): ForecastedCost | undefined => {
  const forecastedOption =
    costBreakdownV2WidgetSetupForecastedOptionSelector(state);
  if (!forecastedOption) {
    return;
  }
  const costs = costBreakdownV2WidgetSetupCostsSelector(state);

  return costs?.forecasted_costs?.find(
    (item) => item.forecast_option === forecastedOption,
  );
};
