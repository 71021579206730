import { DropdownSelectOption } from "../../../../../../common/select/dropdown-select/utils/types/types";
import { ResourceExplorerPossibleFilter } from "../../../../../../../services/cloudchipr.api";

type ResourceExplorerAccountOptionRawValue = Pick<
  ResourceExplorerPossibleFilter["values"][0],
  "title" | "provider_account_id" | "value"
>;

export const resourceExplorerAccountOptionFilterFn = (
  option: DropdownSelectOption,
  keyword: string,
) => {
  if (!option.rawValue) {
    return false;
  }

  const {
    title,
    provider_account_id,
    value,
  }: ResourceExplorerAccountOptionRawValue = option.rawValue;

  return (
    title?.trim()?.toLowerCase()?.includes(keyword.trim().toLowerCase()) ||
    provider_account_id
      ?.trim()
      ?.toLowerCase()
      ?.includes(keyword.trim().toLowerCase()) ||
    value.trim().toLowerCase().includes(keyword.trim().toLowerCase())
  );
};
