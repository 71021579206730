import { FC } from "react";
import { Alert, AlertTitle, Typography } from "@mui/material";
import { convertSecondsToUnits } from "../../../utils/helpers/gracePeriodCalculations";

interface GracePeriodAlertProps {
  period: number;
}

export const GracePeriodAlert: FC<GracePeriodAlertProps> = ({ period }) => {
  const { message } = convertSecondsToUnits(period);

  return (
    <Alert severity="info" variant="outlined">
      <AlertTitle>Grace Period</AlertTitle>
      Cloudchipr will notify{" "}
      <Typography variant="inherit" fontWeight="bold" component="span">
        {message}
      </Typography>{" "}
      before taking actions.
    </Alert>
  );
};
