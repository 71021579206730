import { FC } from "react";
import { TextField } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField/TextField";

interface ResourceExplorerV2ViewCreateFolderOrViewDialogContentProps {
  value: string;
  onChange: TextFieldProps["onChange"];
  label: string;
}
export const ResourceExplorerV2ViewCreateFolderOrViewDialogContent: FC<
  ResourceExplorerV2ViewCreateFolderOrViewDialogContentProps
> = ({ value, onChange, label }) => {
  return (
    <TextField
      value={value}
      onChange={onChange}
      name="name"
      label={label}
      placeholder={label}
      fullWidth
      size="small"
      autoFocus
    />
  );
};
