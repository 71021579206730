import { costByCategoryWidgetDataSelector } from "./data/costByCategoryWidgetDataSelector";
import { RootState } from "../../../../store";
import { buildingInProgressWidgetId } from "../../../thunks/widgets/utils/constants";
import { ForecastedCostRe } from "../../../../../services/cloudchipr.api";

export const costByCategoryWidgetForecastedOptionsSelector = (
  state: RootState,
  widgetId?: string,
): ForecastedCostRe | undefined => {
  const widgetData = costByCategoryWidgetDataSelector(
    state,
    widgetId ?? buildingInProgressWidgetId,
  );

  return widgetData?.forecasted_costs;
};
