import { FC, useCallback } from "react";
import { Stack, TextField, Typography } from "@mui/material";

interface WeeksIntervalProps {
  value: number | null;
  setFieldValue(key: string, date: string): void;
}

export const WeeksInterval: FC<WeeksIntervalProps> = ({
  setFieldValue,
  value,
}) => {
  const changeHandler = useCallback(
    (event: any) => {
      const val = event.target.value;

      if (val < 1 || !val) {
        return;
      }

      setFieldValue("weeklyInterval", val);
    },
    [setFieldValue],
  );

  return (
    <Stack direction="row" spacing={3} alignItems="baseline" mt={4}>
      <Typography variant="subtitle2">Repeat every</Typography>

      <Stack>
        <TextField
          type="number"
          size="small"
          sx={{ width: 80 }}
          value={value || 1}
          onChange={changeHandler}
        />
      </Stack>

      <Typography variant="subtitle2" fontWeight="normal">
        week{!!(value && value > 1) && "s"}
      </Typography>
    </Stack>
  );
};
