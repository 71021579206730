import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { useDidMount } from "rooks";
import { CoverageDrawerContentInfoCards } from "./CoverageDrawerContentInfoCards";
import { CoverageDrawerCollapsableSection } from "./CoverageDrawerCollapsableSection";
import { CoverageDrawerRecommendationsSection } from "./recommendations/CoverageDrawerRecommendationsSection";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { CommitmentsDrawerHeader } from "../common/CommitmentsDrawerHeader";
import { getUtilizationAndCoverageDetailsThunk } from "../../../../../../../store/commitments/thunks/getUtilizationAndCoverageDetailsThunk";
import { utilizationAndCoverageDrawerTargetTypeSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/options/utilizationAndCoverageDrawerTargetTypeSelector";
import { selectedCoverageDataAccountNameAndIdSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/resource-type/selectedCoverageDataAccountNameAndIdSelector";

interface CoverageDrawerProps {
  onClose(): void;
}

export const CoverageDrawerContent: FC<CoverageDrawerProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const accountId = useAppSelector(
    selectedCoverageDataAccountNameAndIdSelector,
  );
  const targetType = useAppSelector(
    utilizationAndCoverageDrawerTargetTypeSelector,
  );

  useDidMount(() => {
    dispatch(getUtilizationAndCoverageDetailsThunk());
  });

  return (
    <Fragment>
      <CommitmentsDrawerHeader
        title={targetType === "resource_type" ? "Resource Type" : accountId}
        onClose={onClose}
      />

      <Box width="80dvw">
        {targetType === "resource_type" && <CoverageDrawerContentInfoCards />}
        <CoverageDrawerCollapsableSection />
        <CoverageDrawerRecommendationsSection />
      </Box>
    </Fragment>
  );
};
