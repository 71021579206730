import { FC, Fragment, useCallback } from "react";
import {
  ResourceUnprotectDialog,
  ResourceUnprotectDialogProps,
} from "./ResourceUnprotectDialog";
import { ConfirmationDialog } from "../../../../../../common/modals/ConfirmationDialog";
import { scheduleResourcesLoadingSelector } from "../../../../../../../store/schedules/selectors/schedule-resources-preview/scheduleResourcesLoadingSelector";
import { previewResourcesProtectionLoadingSelector } from "../../../../../../../store/schedules/selectors/schedule-resources-preview/selected-resources/previewResourcesProtectionLoadingSelector";
import { useAppSelector } from "../../../../../../../store/hooks";
import { ResourceItemType } from "../../../../../../../store/account/utils/types/types";
import { protectorResourcesLoadingSelector } from "../../../../../../../store/schedules/selectors/resources-for-protector/protectorResourcesLoadingSelector";

interface ResourceProtectionDialogsProps {
  unprotectDialogProps: ResourceUnprotectDialogProps | null;
  protectDialogProps: {
    open: boolean;
    onClose(): void;
    onSubmit(): void;
  } | null;
  resources: ResourceItemType[];
  scheduleId: string;
  batchId?: string;
}

export const ResourceProtectionDialogs: FC<ResourceProtectionDialogsProps> = ({
  unprotectDialogProps,
  protectDialogProps,
  resources,
  scheduleId,
  batchId,
}) => {
  const loadingSelector = useCallback(
    (state: any) => {
      return resources
        .map((resource) => {
          const resourceType = resource?.resource_type;
          const accountId = resource?.account?.id;

          if (!resourceType || !accountId) {
            return false;
          }

          return [
            protectorResourcesLoadingSelector(state, scheduleId, batchId),
            scheduleResourcesLoadingSelector(state, resourceType, scheduleId),
            previewResourcesProtectionLoadingSelector(state, accountId),
          ];
        })
        .flat()
        .some((loading) => loading);
    },
    [resources, scheduleId, batchId],
  );

  const isLoading = useAppSelector(loadingSelector);

  return (
    <Fragment>
      {protectDialogProps && (
        <ConfirmationDialog
          open={protectDialogProps.open}
          title="Enable Protection?"
          dialogContent="Resources with Enabled Protection will be untouched by Cloudchipr."
          CancelButtonProps={{
            onClick: protectDialogProps.onClose,
          }}
          ConfirmButtonProps={{
            loading: isLoading,
            children: "Enable",
            onClick: protectDialogProps.onSubmit,
          }}
          onClose={protectDialogProps.onClose}
        />
      )}

      {unprotectDialogProps && (
        <ResourceUnprotectDialog
          {...unprotectDialogProps}
          isLoading={isLoading}
        />
      )}
    </Fragment>
  );
};
