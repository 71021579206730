import { FC, Fragment } from "react";
import { Paper } from "@mui/material";
import { OffHoursDetailsPreview } from "./OffHoursDetailsPreview";
import { SchedulePreviewEnvironment } from "../../../../common/create/preview/SchedulePreviewEnvironment";
import { SchedulePreviewResources } from "../../../../common/create/preview/SchedulePreviewResources";
import { SchedulePreviewNotifications } from "../../../../common/create/preview/notifications/SchedulePreviewNotifications";
import { NextPrevious } from "../../../../common/create/NextPrevious";
import { SchedulePreviewSectionTitle } from "../../../../common/create/preview/SchedulePreviewSectionTitle";
import { OffHoursFormData } from "../../../utils/types/types";
import { useOffHoursPreviewDatesInfoHook } from "../../../utils/hooks/useOffHoursPreviewDatesInfo.hook";
import { useOffHoursNextRunDates } from "../../../utils/hooks/useOffHoursNextRunDates.hook";

interface OffHoursPreviewProps {
  isLoading: boolean;
  onSubmit(): void;
  onPrevious(): void;
  onFieldChange(key: string, value: any): void;
}

export const OffHoursPreview: FC<OffHoursPreviewProps & OffHoursFormData> = ({
  id,
  name,
  provider,
  regions,
  filter,
  end_date_time,
  start_date_time,
  time_zone,
  start_cron,
  stop_cron,
  notifications,
  onSubmit,
  onPrevious,
  isLoading,
  account_ids,
  min_threshold,
  onFieldChange,
  emails,
  status,
  action,
}) => {
  const { nextRunStartDate: nextStartRun, nextRunStopDate: nextStopRun } =
    useOffHoursNextRunDates({
      endDate: end_date_time,
      startDate: start_date_time,
      startCron: start_cron,
      stopCron: stop_cron,
      timeZone: time_zone,
    });

  const { nextRunStartDate, nextRunStopDate, nextRuns, frequency } =
    useOffHoursPreviewDatesInfoHook({
      timeZone: time_zone,
      startCron: start_cron,
      stopCron: stop_cron,
      nextStartRun,
      nextStopRun,
    });

  return (
    <Fragment>
      <Paper>
        <SchedulePreviewEnvironment
          name={name}
          provider={provider}
          accountIds={account_ids}
          regions={regions}
          onFieldChange={onFieldChange}
        />

        {filter && (
          <SchedulePreviewResources
            scheduleId={id}
            scheduleAction={action}
            frequency={frequency}
            nextRuns={nextRuns}
            regions={regions}
            status={status}
            name={name}
            filters={filter}
            accountIds={account_ids}
          />
        )}

        <SchedulePreviewSectionTitle title="Notification" />
        <SchedulePreviewNotifications
          snoozed
          emails={emails}
          notifications={notifications}
          minThreshold={min_threshold}
        />

        <OffHoursDetailsPreview
          instanceStop={frequency?.at(0)?.tooltip}
          instanceStart={frequency?.at(1)?.tooltip}
          nextRunStartDate={nextRunStartDate}
          nextRunStopDate={nextRunStopDate}
          timeZone={time_zone}
          startDate={start_date_time}
          endDate={end_date_time}
        />
      </Paper>

      <NextPrevious
        isLoading={isLoading}
        submitText={id ? "Save Changes" : "Confirm"}
        onNext={onSubmit}
        onPrevious={onPrevious}
        isNextDisabled={!name}
      />
    </Fragment>
  );
};
