import { scheduleResourcesByResourceTypeEmsSelector } from "./scheduleResourcesByResourceTypeEmsSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const scheduleAllResourcesEmsSelector = (
  state: RootState,
  resourceTypes: ResourceType[],
  scheduleId: string,
): number => {
  return resourceTypes.reduce(
    (sum, rt) => {
      const spending = scheduleResourcesByResourceTypeEmsSelector(
        state,
        rt,
        scheduleId,
      );

      return spending + sum;
    },

    0,
  );
};
