import { FC } from "react";
import { Divider, Stack } from "@mui/material";
import {
  DateDataPoint,
  DateLabelNullable,
  ResourceExplorerCostsDateDetails,
} from "../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../utils/numeral/money";
import { capitalizeString } from "../../../../../../../../utils/helpers/capitalizeString";
import { TrendChip } from "../../../../../../../common/chips/TrendChip";
import { InfoCard } from "../../../../../../common/info-cards/InfoCard";
import { getTrendTooltipTitle } from "../../../../../../dashboard/utils/helpers/trend-tooltip-title/getTrendTooltipTitle";

interface TotalAndAverageCostProps {
  dataPoint: DateDataPoint;
  totalCost: number;
  average?: number | null;
  totalCostTrend: number | null;
  totalCostDates?: ResourceExplorerCostsDateDetails;
  previousPeriodCostDates?: ResourceExplorerCostsDateDetails;
  dateLabel?: DateLabelNullable;
  averageTrend?: number | null;
}

export const TotalAndAverageCost: FC<TotalAndAverageCostProps> = ({
  totalCost,
  average,
  dataPoint,
  totalCostTrend,
  totalCostDates,
  previousPeriodCostDates,
  dateLabel,
  averageTrend,
}) => {
  const tooltipTitle = getTrendTooltipTitle(
    totalCostDates?.from_date,
    totalCostDates?.to_date,
    previousPeriodCostDates?.from_date,
    previousPeriodCostDates?.to_date,
    undefined,
    dateLabel,
  );

  return (
    <Stack
      direction="row"
      gap={2}
      flexWrap="wrap"
      alignItems="start"
      divider={<Divider light orientation="vertical" flexItem />}
    >
      <Stack direction="row" alignItems="end" spacing={1}>
        <InfoCard title="Total Cost" value={money(totalCost)} />

        <TrendChip value={totalCostTrend} tooltipTitle={tooltipTitle} />
      </Stack>

      {average !== null && (
        <Stack direction="row" alignItems="end" spacing={1}>
          <InfoCard
            title={`${capitalizeString(dataPoint)} Average`}
            value={money(average)}
          />

          <TrendChip value={averageTrend} tooltipTitle={tooltipTitle} />
        </Stack>
      )}
    </Stack>
  );
};
