import { RootState } from "../../../../store";
import { commitmentsRecommendationsPayloadDataSelector } from "../commitmentsRecommendationsPayloadDataSelector";
import { SavingsPlanTermInYears } from "../../../../../services/cloudchipr.api";

export const commitmentsRecommendationsSavingsPlanTermSelector = (
  state: RootState,
): SavingsPlanTermInYears | undefined => {
  const data = commitmentsRecommendationsPayloadDataSelector(state);

  return data.term_in_years;
};
