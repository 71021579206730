import { FC } from "react";
import { Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ResourceType } from "../../../../../../../../services/cloudchipr.api";

const infoMessages: Map<ResourceType, string> = new Map([
  [
    "elc",
    "Please note that Memcached has no backup feature, so it may only be deleted.",
  ],
]);

interface InfoIconForSpecificResourceProps {
  resourceType: ResourceType;
}

export const InfoIconForSpecificResource: FC<
  InfoIconForSpecificResourceProps
> = ({ resourceType }) => {
  if (!infoMessages.has(resourceType)) {
    return null;
  }

  return (
    <Tooltip arrow title={infoMessages.get(resourceType) ?? ""}>
      <InfoOutlinedIcon
        sx={{ verticalAlign: "middle", color: "text.secondary", ml: 1 }}
        fontSize="small"
      />
    </Tooltip>
  );
};
