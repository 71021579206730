import { createAsyncThunk } from "@reduxjs/toolkit";
import { applyFilterTemplateToAccountsThunk } from "./applyFilterTemplateToAccountsThunk";
import { RootState } from "../../../store";
import { applyAccountFiltersThunk } from "../applyAccountFiltersThunk";
import { getCurrentAccountThunk } from "../../../account/thunks/getCurrentAccountThunk";
import { getCurrentAccountResourceTypeWithFiltersDataThunk } from "../../../account/thunks/filters-get/getCurrentAccountResourceTypeWithFiltersDataThunk";
import { filterTemplateByIdSelector } from "../../selectors/filter-set/filterTemplateByIdSelector";

export const applyFilterTemplateToCurrentAccount = createAsyncThunk(
  "filter/applyFilterTemplateToCurrentAccount",
  async (filterSetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const {
      account: { id: accountId },
    } = state;

    if (!accountId) {
      return;
    }

    const response = await dispatch(
      applyFilterTemplateToAccountsThunk({
        accountIds: [accountId],
        filterSetId: filterSetId,
      }),
    );

    await dispatch(getCurrentAccountThunk(accountId));
    await dispatch(applyAccountFiltersThunk(accountId));

    const filterSet = filterTemplateByIdSelector(state, filterSetId);

    filterSet?.filters?.forEach(({ type }) => {
      dispatch(getCurrentAccountResourceTypeWithFiltersDataThunk(type));
    });

    return response;
  },
);
