import { FC, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { FilterDropdownTrigger } from "../../../FilterDropdownTrigger";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import {
  ResourceExplorerFilterItem,
  ProviderType,
} from "../../../../../../../../../services/cloudchipr.api";
import { regionFilterChangeThunk } from "../../../../../../../../../store/resource-explorer/thunks/filters/regionFilterChangeThunk";
import { resourceExplorerFilterByKeyAndProviderSelector } from "../../../../../../../../../store/resource-explorer/selectors/filters/resourceExplorerFilterByKeyAndProviderSelector";
import { resourceExplorerPossibleFiltersByProviderSelector } from "../../../../../../../../../store/resource-explorer/selectors/filters/possible-filters/resourceExplorerPossibleFiltersByProviderSelector";
import { isDeepEqual } from "../../../../../../../../../utils/is-deep-equal";
import { dbSelectedRegionSelector } from "../../../../../../../../../store/resource-explorer/selectors/filters/selected/dbSelectedRegionSelector";
import { DropdownSelect } from "../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { resourceExplorerAccountOptionFilterFn } from "../../../../utils/helpers/resourceExplorerRegionOptionFilterFn";

interface ResourceExplorerRegionFilterProps {
  viewId: string;
  provider: ProviderType;
}
export const ResourceExplorerRegionFilter: FC<
  ResourceExplorerRegionFilterProps
> = ({ viewId, provider }) => {
  const dispatch = useDispatch();
  const filter = useAppSelector((state) =>
    resourceExplorerFilterByKeyAndProviderSelector(state, "region", provider),
  );

  const possibleFilters = useAppSelector((state) =>
    resourceExplorerPossibleFiltersByProviderSelector(state, provider),
  )?.region;

  const selectedFilter = useAppSelector((state) =>
    dbSelectedRegionSelector(state, provider),
  );

  const options = useMemo(() => {
    return (
      possibleFilters?.values?.map((region) => {
        return {
          value: region.value,
          label: (
            <Stack direction="row" spacing={1} justifyContent="space-between">
              <Typography variant="body2">{region.title}</Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                textAlign="right"
              >
                {region.value}
              </Typography>
            </Stack>
          ),
          rawValue: region,
        };
      }) ?? []
    );
  }, [possibleFilters]);

  const submitHandlerOnClose = useCallback(
    (value: string[]) => {
      const newFilter: ResourceExplorerFilterItem = {
        ...filter,
        value,
        key: "region",
        operator: "in",
        cloud_provider: provider,
        include_values: filter?.include_values ?? null,
      };

      const isEqual = filter && isDeepEqual(newFilter, filter);

      if (!isEqual) {
        dispatch(
          regionFilterChangeThunk({
            filter: newFilter,
            provider,
          }),
        );
      }
    },
    [dispatch, filter, provider],
  );

  const TriggerComponent = useCallback(
    (props: any) => (
      <FilterDropdownTrigger
        {...props}
        viewId={viewId}
        provider={provider}
        filterKey="region"
        title="Regions"
      />
    ),
    [provider, viewId],
  );

  return (
    <DropdownSelect
      label="Regions"
      options={options}
      filterFn={resourceExplorerAccountOptionFilterFn}
      initialSelectedValues={(selectedFilter.value ?? []) as string[]}
      submitHandlerOnClose={submitHandlerOnClose}
      TriggerComponent={TriggerComponent}
      values={(selectedFilter?.value ?? []) as string[]}
    />
  );
};
