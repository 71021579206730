import { anyChangesExistOnDimensionEditSelector } from "./anyChangesExistOnDimensionEditSelector";
import { categoriesCreationDataIsInvalidSelector } from "../categories/categoriesCreationDataIsInvalidSelector";
import { dimensionNameSelector } from "../../slice-data/dimensionNameSelector";
import { dimensionCategoriesEntriesSelector } from "../../slice-data/dimensionCategoriesEntriesSelector";
import { RootState } from "../../../../store";

export const dimensionCreateDisabledSelector = (state: RootState) => {
  const dimensionName = dimensionNameSelector(state);
  const categoriesInvalid = categoriesCreationDataIsInvalidSelector(state);
  const categories = dimensionCategoriesEntriesSelector(state);
  const somethingChanged = anyChangesExistOnDimensionEditSelector(state);

  return (
    !dimensionName.trim() ||
    !categories.length ||
    categoriesInvalid ||
    !somethingChanged
  );
};
