import { FC, memo } from "react";
import {
  Alert,
  Box,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { cleanExcludedResourcesIdentifiersSelector } from "../../../../../../../../store/clean/selectors/cleanExcludedResourcesIdentifiersSelector";
import { resourceTypeDataLoadingSelector } from "../../../../../../../../store/account/selectors/resource-type-data/resourceTypeDataLoadingSelector";
import { newCleanResourceTypeSelector } from "../../../../../../../../store/clean/selectors/newCleanResourceTypeSelector";

interface CleanExcludedResourcesInfoProps {
  actionType: string;
}

export const CleanExcludedResourcesInfo: FC<CleanExcludedResourcesInfoProps> =
  memo(({ actionType }) => {
    const data = useAppSelector(cleanExcludedResourcesIdentifiersSelector);
    const resourceType = useAppSelector(newCleanResourceTypeSelector);
    const loading = useAppSelector((state) =>
      resourceType
        ? resourceTypeDataLoadingSelector(state, resourceType)
        : false,
    );

    if (!data.length) {
      return null;
    }

    return (
      <Box sx={{ px: 3 }}>
        <Divider />
        <Alert
          severity="info"
          sx={{
            mt: 2,
            mb: 3,
          }}
        >
          <Stack>
            <Typography variant="body1">
              <Typography
                variant="body1"
                textTransform="capitalize"
                component="span"
              >
                {actionType}{" "}
              </Typography>
              action can not be performed on the following resource
              {data?.length > 1 ? "s" : ""}:
            </Typography>

            {loading ? (
              <Skeleton variant="text" height={26} width={140} />
            ) : (
              <Typography variant="subtitle1" fontWeight="medium">
                {data?.map((item, index) => {
                  return `${item}${index === data?.length - 1 ? "" : ", "}`;
                })}
                .
              </Typography>
            )}
          </Stack>
        </Alert>
      </Box>
    );
  });
