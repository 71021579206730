import { resourceExplorerDataSelector } from "./resourceExplorerDataSelector";
import { RootState } from "../../../../store";
import { DateLabelNullable } from "../../../../../services/cloudchipr.api";

export const resourceExplorerDateLabelSelector = (
  state: RootState,
): DateLabelNullable => {
  const data = resourceExplorerDataSelector(state);

  return data?.dates?.label ?? null;
};
