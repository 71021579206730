import { FC } from "react";
import { Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { AddFilterButton } from "./components/AddFilterButton";
import { ResourceExplorerFilterItems } from "./components/filter-items/ResourceExplorerFilterItems";
import { ResourceExplorerProviders } from "../providers/ResourceExplorerProviders";
import { useAppSelector } from "../../../../../store/hooks";
import { enabledProvidersSelector } from "../../../../../store/common/selectors/billing-analytics/enabled-providers/enabledProvidersSelector";
import { isResourceExplorerAvailableSelector } from "../../../../../store/resource-explorer/selectors/current-resource-explorer/checkers/isResourceExplorerAvailableSelector";
import { FilterByDimensionButton } from "../dimensions-filters/components/FilterByDimensionButton";
import { ResourceExplorerDimensionFilterItem } from "../dimensions-filters/components/ResourceExplorerDimensionFilterItem";

interface ResourceExplorerFiltersProps {
  viewId: string;
}

export const ResourceExplorerFilters: FC<ResourceExplorerFiltersProps> = ({
  viewId,
}) => {
  const enableDimensions = useFlag("EnableDimensions");

  const enableProviders = useAppSelector(enabledProvidersSelector);
  const enableProvidersArr = Array.from(enableProviders);
  const isResourceExplorerAvailable = useAppSelector(
    isResourceExplorerAvailableSelector,
  );

  if (!isResourceExplorerAvailable) {
    return null;
  }

  return (
    <Stack
      spacing={1}
      mt={1}
      px={2}
      py={1}
      borderBottom={1}
      borderColor="grey.300"
    >
      {enableProvidersArr.length > 1 && (
        <Stack direction="row" spacing={1} alignItems="center">
          <ResourceExplorerProviders />
          <AddFilterButton />

          {enableDimensions && <FilterByDimensionButton />}
        </Stack>
      )}

      <ResourceExplorerFilterItems viewId={viewId} />

      <ResourceExplorerDimensionFilterItem />
    </Stack>
  );
};
