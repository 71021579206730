import {
  ProviderType,
  ResourceExplorerFilterKeyType,
} from "../../../../../services/cloudchipr.api";
import { MultiFilters } from "../../../utils/types/resourceExplorer";

export const listOfStringsTypeFiltersCombiner = (
  filterKey: ResourceExplorerFilterKeyType,
  filters: MultiFilters,
) => {
  return Object.entries(filters).reduce(
    (acc, [provider, filter]) => {
      acc[provider as ProviderType] =
        (filter[filterKey]?.value as string[]) ?? [];

      return acc;
    },
    {} as Record<ProviderType, string[]>,
  );
};
