import { RootState } from "../../../../store";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { sortedResourceExplorersSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/sortedResourceExplorersSelector";
import { widgetSetupViewIdSelector } from "../../setups/widgetSetupViewIdSelector";

export const selectedViewProvidersSelector = (
  state: RootState,
): ProviderType[] => {
  const views = sortedResourceExplorersSelector(state);
  const selectedViewId = widgetSetupViewIdSelector(state);

  return (views?.find((item) => item.id === selectedViewId)?.cloud_providers ??
    []) as ProviderType[];
};
