import { FC, Fragment } from "react";
import { Card, Container } from "@mui/material";
import { ScheduledJobsGridEmptyState } from "./ScheduledJobsGridEmptyState";
import { ScheduledJobsDataGrid } from "./scheduled-jobs-list-grid/ScheduledJobsDataGrid";
import { PageTitle } from "../../../common/PageTitle";
import { useAppSelector } from "../../../../../store/hooks";
import { schedulesDataSelector } from "../../../../../store/schedules/selectors/schedule/schedulesDataSelector";
import { schedulesLoadingSelector } from "../../../../../store/schedules/selectors/schedule/schedulesLoadingSelector";

export const ScheduledJobsGrid: FC = () => {
  const data = useAppSelector(schedulesDataSelector);
  const loading = useAppSelector(schedulesLoadingSelector);

  return (
    <Container sx={{ p: 2 }} disableGutters maxWidth={false}>
      <Fragment>
        {!loading && <ScheduledJobsGridEmptyState />}

        {!!data?.length && (
          <Card variant="outlined">
            <PageTitle
              title="Workflow"
              subTitle={`${data?.length} scheduled ${
                data?.length === 1 ? "Workflow" : "Workflows"
              }.`}
            />

            <ScheduledJobsDataGrid schedules={data} />
          </Card>
        )}
      </Fragment>
    </Container>
  );
};
