import { FC, useMemo } from "react";
import { grey } from "@mui/material/colors";
import { Theme } from "@mui/material";

import { getCostBreakdownV2WidgetSetupTableViewColumns } from "./getCostBreakdownV2WidgetSetupTableViewColumns";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { DataGrid } from "../../../../../../../../../../storybook/data-grid/DataGrid";
import { widgetSetupContentChartHeight } from "../../../../../../../utils/constants/stylings";
import { costBreakdownV2WidgetSetupNoChartViewDataSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupNoChartViewDataSelector";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";

export const CostBreakdownV2WidgetSetupTableView: FC = () => {
  const gridData = useAppSelector(
    costBreakdownV2WidgetSetupNoChartViewDataSelector,
  );
  const grouping = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("grouping"),
  );

  const forecastOption = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("forecastOption"),
  );

  const columns = useMemo(() => {
    return getCostBreakdownV2WidgetSetupTableViewColumns({
      forecastOption,
      grouping,
    });
  }, [forecastOption, grouping]);

  return (
    <DataGrid
      styles={styles}
      columns={columns ?? []}
      data={!gridData ? [] : gridData}
      enableRowsVirtualization={gridData?.length > 30}
    />
  );
};

const styles = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
    "& tr:hover td": { bgcolor: grey[100] },
    "& tr td": { borderColor: grey[100], py: 0.5, pl: 2 },
  },

  tableHeaderRow: {
    "& th": {
      p: 1,
      verticalAlign: "inherit",
      bgcolor: grey[100],
    },
    top: 0,
    position: "sticky",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 1,
  },
  tableRow: {
    height: 40,
  },

  tableContainer: {
    border: 1,
    borderColor: grey[200],
    maxHeight: `${widgetSetupContentChartHeight}px`,
  },
};
