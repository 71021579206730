import { useCallback, useMemo, useState } from "react";
import { SortingState } from "@tanstack/table-core";
import { Updater } from "@tanstack/react-table";

import { ColumnSortingConfig } from "../types/prop-types";
import { ColumnSetupType } from "../types/types";
export function useColumnsSorting(
  columns: ColumnSetupType<any>[],
  columnSortingConfig?: ColumnSortingConfig,
) {
  const initialSorting = useMemo(() => {
    return typeof columnSortingConfig === "boolean"
      ? []
      : columnSortingConfig?.initialSort || [];
  }, [columnSortingConfig]);

  const [columnSorting, setColumnSorting] =
    useState<SortingState>(initialSorting);

  const columnsSortingChangeHandler = useCallback(
    (updater: Updater<SortingState>) => {
      if (typeof columnSortingConfig !== "boolean") {
        if (
          typeof updater === "function" &&
          columnSortingConfig?.onColumnsSortingChange
        ) {
          const updatedState = updater(columnSorting);
          columnSortingConfig.onColumnsSortingChange(updatedState);
        }
      }

      setColumnSorting(updater);
    },
    [columnSorting, columnSortingConfig],
  );

  return useMemo(() => {
    const firstSortingId = columnSorting?.at(0)?.id;
    const existingSortingColumn = columns?.find(
      (item) =>
        item?.accessorKey === firstSortingId || item?.id === firstSortingId,
    );

    if (!!firstSortingId && !existingSortingColumn) {
      return {
        columnSorting: initialSorting,
        columnsSortingChangeHandler: columnsSortingChangeHandler,
      };
    }
    if (!columnSortingConfig) {
      return {
        columnSorting: undefined,
        columnsSortingChangeHandler: undefined,
      };
    }

    return {
      columnSorting,
      columnsSortingChangeHandler,
    };
  }, [
    columnSortingConfig,
    columnSorting,
    columnsSortingChangeHandler,
    columns,
    initialSorting,
  ]);
}
