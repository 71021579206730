import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import {
  LargestCostChangesWidgetCardHeaderDate,
  LargestCostChangesWidgetCardHeaderDateProps,
} from "./LargestCostChangesWidgetCardHeaderDate";
import { SourceDataChip } from "../../../common/widget-header/source-and-name/SourceDataChip";
import { InfoDataChip } from "../../../common/widget-header/source-and-name/InfoDataChip";
import { WidgetTitleProps } from "../../../common/widget-header/source-and-name/WidgetSourceInfo";

interface LargestCostChangesWidgetHeaderSourceDataProps
  extends LargestCostChangesWidgetCardHeaderDateProps,
    WidgetTitleProps {}

export const LargestCostChangesWidgetHeaderCardSourceData: FC<
  LargestCostChangesWidgetHeaderSourceDataProps
> = ({ prevDate, grouping, ...props }) => {
  return (
    <Stack direction="row" gap={1} alignItems="center" overflow="auto">
      {props.sourceData && <SourceDataChip {...props.sourceData} />}

      {grouping && (
        <InfoDataChip>
          Group By:{" "}
          <Typography
            component="span"
            variant="inherit"
            fontWeight="medium"
            color="text.primary"
          >
            {grouping}
          </Typography>
        </InfoDataChip>
      )}

      <LargestCostChangesWidgetCardHeaderDate
        prevDate={prevDate}
        date={props.dates}
      />
    </Stack>
  );
};
