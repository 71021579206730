import { FC, Fragment } from "react";
import { useDidMount } from "rooks";
import { Stack } from "@mui/material";
import { CostBreakdownV2WidgetContent } from "./components/CostBreakdownV2WidgetContent";
import { WidgetLoadingState } from "../common/WidgetLoadingState";
import { resourceExplorerViewNameByViewIdSelector } from "../../../../../../store/resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { costBreakdownV2WidgetByWidgetIdLoadingSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/loading/costBreakdownV2WidgetByWidgetIdLoadingSelector";
import { getCostBreakdownV2WidgetDataByWidgetIdThunk } from "../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/getCostBreakdownV2WidgetDataByWidgetIdThunk";
import { costBreakdownV2WidgetTitlePropsSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetTitlePropsSelector";
import { WidgetCardHeaderV2 } from "../common/widget-header/WidgetCardHeaderV2";
import { costBreakdownV2WidgetViewIdByWidgetIdSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetViewIdByWidgetIdSelector";

interface DashboardCostBreakdownWidgetProps {
  widgetId: string;
}

export const DashboardCostBreakdownV2Widget: FC<
  DashboardCostBreakdownWidgetProps
> = ({ widgetId }) => {
  const dispatch = useAppDispatch();
  const { ref, hovered } = useHover();

  const viewId = useAppSelector((state) =>
    costBreakdownV2WidgetViewIdByWidgetIdSelector(state, widgetId),
  );

  const widgetTitleProps = useAppSelector((state) =>
    costBreakdownV2WidgetTitlePropsSelector(state, widgetId),
  );

  const loading = useAppSelector((state) =>
    costBreakdownV2WidgetByWidgetIdLoadingSelector(state, widgetId),
  );

  const viewName = useAppSelector((state) =>
    resourceExplorerViewNameByViewIdSelector(state, viewId),
  );

  useDidMount(() => {
    dispatch(getCostBreakdownV2WidgetDataByWidgetIdThunk(widgetId));
  });

  return (
    <Stack ref={ref} flexGrow={1} overflow="auto">
      {loading ? (
        <WidgetLoadingState />
      ) : (
        <Fragment>
          <WidgetCardHeaderV2
            hovered={hovered}
            widgetId={widgetId}
            widgetType="cost_breakdown"
            widgetTitleProps={widgetTitleProps}
          />
          <CostBreakdownV2WidgetContent
            viewName={viewName}
            widgetId={widgetId}
          />
        </Fragment>
      )}
    </Stack>
  );
};
