import { FC, useCallback } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { costByCategorySetupChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/cost-by-category/costByCategorySetupChangeThunk";
import { costByCategorySetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-by-category/costByCategorySetupPropertyByKeySelector";

export const CostByCategoryFilteredResourcesSwitcher: FC = () => {
  const dispatch = useAppDispatch();

  const displayFilteredResources = useAppSelector(
    costByCategorySetupPropertyByKeySelector("displayFilteredResources"),
  );
  const chartType = useAppSelector(
    costByCategorySetupPropertyByKeySelector("chartType"),
  );

  const changeHandler = useCallback(async () => {
    await dispatch(
      costByCategorySetupChangeThunk({
        displayFilteredResources: !displayFilteredResources,
      }),
    );

    if (chartType === "bar" || chartType === "multiStack") {
      dispatch(
        costByCategorySetupChangeThunk({
          chartType: displayFilteredResources ? "bar" : "multiStack",
        }),
      );
    }
  }, [dispatch, chartType, displayFilteredResources]);

  return (
    <FormControlLabel
      onChange={changeHandler}
      control={<Switch checked={displayFilteredResources} />}
      label="Display filtered resources "
    />
  );
};
