import { RootState } from "../../../../store";
import { resourceExplorerAllPossibleInitialFiltersSelector } from "../../filters/possible-filters/resourceExplorerAllPossibleInitialFiltersSelector";
import { ProviderType } from "../../../../../services/cloudchipr.api";

type GroupingTagOption = {
  title: string | null;
  provider: ProviderType;
  value: string;
};

export const resourceExplorerDataGroupingAvailableTagsSelector = (
  state: RootState,
): GroupingTagOption[] | null => {
  const allPossibleFilters =
    resourceExplorerAllPossibleInitialFiltersSelector(state);

  if (!allPossibleFilters) {
    return null;
  }

  const tagFilterValues: GroupingTagOption[][] = [];

  const providers = Object.keys(allPossibleFilters) as ProviderType[];

  providers.forEach((provider) => {
    const tagFilter = allPossibleFilters[provider]?.find(
      ({ key }) => key === "cost_allocation_tag",
    );

    tagFilterValues.push(
      tagFilter?.values?.map(({ value, title }) => ({
        provider,
        value,
        title,
      })) ?? [],
    );
  });

  return tagFilterValues.flat();
};
