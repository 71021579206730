import {
  cloudChiprApi,
  ResourceExplorerPossibleFilter,
  ProviderType,
} from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { enabledProvidersSelector } from "../../../../common/selectors/billing-analytics/enabled-providers/enabledProvidersSelector";

const selector =
  cloudChiprApi.endpoints
    .getUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderFilters
    .select;

export const resourceExplorerAllPossibleInitialFiltersSelector = (
  state: RootState,
) => {
  const enabledProviders = enabledProvidersSelector(state);
  const enabledProviderArr = Array.from(enabledProviders);

  return enabledProviderArr.reduce(
    (result, provider) => {
      result[provider] = selector({ provider })(state)?.data;

      return result;
    },
    {} as Partial<Record<ProviderType, ResourceExplorerPossibleFilter[]>>,
  );
};
