import { FC, memo } from "react";
import { TabList } from "@mui/lab";
import Tab from "@mui/material/Tab";
import { Link, useLocation } from "react-router-dom";
import { Stack } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../../utils/helpers/providers/getProviderName";

interface AccountsTabsProps {
  provider: ProviderType;
  activeCount: number;
  inactiveCount: number;
}

export const AccountsTabs: FC<AccountsTabsProps> = memo(
  ({ provider, activeCount, inactiveCount }) => {
    const location = useLocation();

    return (
      <TabList variant="fullWidth" sx={{ background: "white" }}>
        <Tab
          component={Link}
          to={`${location.pathname}?type=active`}
          label={
            <Stack direction="row" gap={3}>
              Active {getProviderName(provider, { plural: true })} (
              {activeCount ?? 0})
            </Stack>
          }
          value="active"
          sx={{
            borderBottom: 1,
            borderColor: "grey.300",
          }}
        />
        <Tab
          component={Link}
          to={`${location.pathname}?type=inactive`}
          label={
            <Stack direction="row" gap={3}>
              Inactive {getProviderName(provider, { plural: true })} (
              {inactiveCount})
              {!!inactiveCount && (
                <WarningAmberIcon color="warning" fontSize="small" />
              )}
            </Stack>
          }
          value="inactive"
          disabled={!inactiveCount}
          sx={{
            borderBottom: 1,
            borderColor: "grey.300",
          }}
        />
      </TabList>
    );
  },
);
