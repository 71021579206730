import { FC, useCallback } from "react";
import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { GroupingByValue } from "./GroupingByValue";
import {
  ProviderType,
  ResourceExplorerGrouping,
} from "../../../../../../services/cloudchipr.api";
import { getResourcesExplorerGroupingLabel } from "../../../../resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";

interface GroupingsPopoverItemProps {
  grouping: ResourceExplorerGrouping;
  providers: ProviderType[];
  groupValues?: string[];
  groupingValueOptions?: { value: string; provider: ProviderType }[];
  onSelect?(grouping: ResourceExplorerGrouping, groupValues?: string[]): void;
  selected: boolean;
}

export const GroupingsPopoverItem: FC<GroupingsPopoverItemProps> = ({
  grouping,
  providers,
  groupValues,
  groupingValueOptions,
  onSelect,
  selected,
}) => {
  const groupingItemClickHandler = useCallback(() => {
    onSelect?.(grouping);
  }, [grouping, onSelect]);

  const groupingByTagSelectHandler = useCallback(
    (groupValues?: string[]) => {
      onSelect?.(grouping, groupValues);
    },
    [grouping, onSelect],
  );

  return (
    <ListItem disablePadding>
      {groupingValueOptions ? (
        <GroupingByValue
          onSubmit={groupingByTagSelectHandler}
          options={groupingValueOptions}
          values={groupValues}
          label={getResourcesExplorerGroupingLabel(
            providers,
            "cost_allocation_tag",
          )}
          selected={selected}
        />
      ) : (
        <ListItemButton onClick={groupingItemClickHandler} selected={selected}>
          <ListItemText
            primary={getResourcesExplorerGroupingLabel(
              providers,
              grouping,
              true,
            )}
          />
        </ListItemButton>
      )}
    </ListItem>
  );
};
