import { FC, Fragment } from "react";
import { Box, IconButton, Link, Stack, Tooltip } from "@mui/material";
import { CloudOutlined, PlayCircleOutlined } from "@mui/icons-material";
import { CopyIconButton } from "../../../../../../../../../common/CopyIconButton";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";
import { useHover } from "../../../../../../../../../../utils/hooks/useHover.hook";
import {
  ProviderType,
  ResourceType,
} from "../../../../../../../../../../services/cloudchipr.api";
import { getProviderName } from "../../../../../../../../../../utils/helpers/providers/getProviderName";

interface LiveFilteredIdentifierCellProps {
  data: string;
  link?: string;
  provider: ProviderType;
  accountId: string;
  resourceType: ResourceType;
}

export const LiveFilteredIdentifierCell: FC<
  LiveFilteredIdentifierCellProps
> = ({ data, link, accountId, provider, resourceType }) => {
  const { ref, hovered } = useHover();

  if (!data) {
    return <Fragment>-</Fragment>;
  }

  return (
    <Stack
      ref={ref}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <TypographyWithTooltip
        title={data}
        fontSize="inherit"
        sx={{ py: 0.75 }}
      />

      <Stack direction="row">
        <Box
          display={hovered ? "flex" : "none"}
          visibility={hovered ? "visible" : "hidden"}
        >
          <CopyIconButton data={data} visible={hovered} sx={{ ml: 0.5 }} />

          {link && (
            <Tooltip
              title={`View in ${getProviderName(provider, {
                title: true,
              })}.`}
              arrow
              placement="top"
            >
              <IconButton
                size="small"
                component={Link}
                href={link || ""}
                target="_blank"
              >
                <CloudOutlined fontSize="small" color="action" />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="View in Live Usage & Mgmt." arrow placement="top">
            <IconButton
              size="small"
              component={Link}
              href={`/${provider}/${accountId}?rt=${resourceType}`}
              target="_blank"
            >
              <PlayCircleOutlined fontSize="small" color="action" />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
    </Stack>
  );
};
