import { FC, Fragment, useCallback, useMemo, useState } from "react";
import {
  ResponsiveContainer,
  BarChart as RechartsBarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";
import { useTheme } from "@mui/material";
import { multiStackChartBars } from "./components/multiStackChartBars";
import { MultiStackChartTooltipContent } from "./components/MultiStackChartTooltipContent";
import {
  costByCategoryChartPrefixes,
  multiStackChartPrefixSeparator,
} from "./utils/constants/constants";
import { ReferenceLineType } from "../../../utils/types/types";
import {
  CustomizedYAxisTick,
  YAxisFormatter,
} from "../../common/CustomizedYAxisTick";
import {
  CustomizedXAxisTick,
  XAxisTickProps,
} from "../../common/CustomizedXAxisTick";
import { ChartsTooltipProps } from "../../common/ChartsTooltipContent";
import { LegendContent } from "../../common/legend/LegentContent";
import { useMultiTypeChartContext } from "../../../MultiTypeChartProvider";
import { calculateLabelInterval } from "../../../utils/helpers/helpers";
import { getYAxisMaxValue } from "../../../utils/helpers/getYAxisMaxValue";
import { getLoadingLabel } from "../../common/LoadingLabel";
import { chartDataPointKey } from "../../../utils/constants/constants";

interface MultiStackChartProps {
  highlight: boolean;
  selectable: boolean;
  loading: boolean;
  yAxisFormatter?: YAxisFormatter;
  xAxisTickProps?: XAxisTickProps;
  tooltipProps?: ChartsTooltipProps;
  referenceLines?: ReferenceLineType[];
  emptyText?: string;
}

export const MultiStackChart: FC<MultiStackChartProps> = ({
  highlight,
  yAxisFormatter,
  xAxisTickProps,
  selectable,
  tooltipProps,
  referenceLines,
  loading,
  emptyText,
}) => {
  const { data, keys, colors, hover, chartType } = useMultiTypeChartContext();
  const setVisibleKeys = keys.setVisibleKeys;

  const theme = useTheme();
  const [barKey, setBarKey] = useState<string>("");

  const onMouseOver = useCallback((payload: any) => {
    setBarKey(payload?.tooltipPayload?.[0]?.unit);
  }, []);

  const isEmpty = !data.length;

  const { maxDataValue: yAxisMaxData, minDataValue: yAxisMinData } = useMemo(
    () => getYAxisMaxValue(data, chartType?.type, referenceLines),
    [data, chartType?.type, referenceLines],
  );

  const { keysForLegend, visibleKeysForLegend } = useMemo(() => {
    return {
      keysForLegend: keys.allKeys.filter((key) =>
        key.includes(costByCategoryChartPrefixes.primary),
      ),
      visibleKeysForLegend: keys.visibleKeys.filter((key) =>
        key.includes(costByCategoryChartPrefixes.primary),
      ),
    };
  }, [keys]);

  const legendItemLabelFormatter = useCallback((key: string) => {
    const primaryPrefix = costByCategoryChartPrefixes.primary;
    const secondaryPrefix = costByCategoryChartPrefixes.secondary;

    const primaryKey = key.includes(primaryPrefix)
      ? key
      : key.replace(secondaryPrefix, primaryPrefix);

    return primaryKey.split(multiStackChartPrefixSeparator)[1];
  }, []);

  const visibleKeysSetHandler = useCallback(
    (keys: string[]) => {
      const keysWithTwinTypes = keys.map((key) => {
        return [
          key.replace(
            costByCategoryChartPrefixes.primary,
            costByCategoryChartPrefixes.secondary,
          ),
          key,
        ];
      });

      setVisibleKeys(keysWithTwinTypes.flat());
    },
    [setVisibleKeys],
  );

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <ResponsiveContainer width="100%" height={300}>
        <RechartsBarChart
          barGap={8}
          data={data}
          barSize={32}
          height={335}
          margin={{ top: 20 }}
          stackOffset="sign"
        >
          <CartesianGrid
            vertical={false}
            strokeOpacity={0.5}
            strokeDasharray="3 3"
          />

          <XAxis
            xAxisId={costByCategoryChartPrefixes.primary}
            height={xAxisTickProps?.parentHeight}
            padding={xAxisTickProps?.parentPadding}
            dataKey={chartDataPointKey}
            strokeOpacity={0.2}
            strokeDasharray="3 3"
            tick={
              <CustomizedXAxisTick
                chartType="bar"
                empty={isEmpty}
                xAxisTickProps={xAxisTickProps}
              />
            }
            interval={calculateLabelInterval(data.length)}
          >
            {getLoadingLabel({
              loading,
              empty: isEmpty,
              emptyText,
            })}
          </XAxis>

          <XAxis
            hide
            dataKey={chartDataPointKey}
            xAxisId={costByCategoryChartPrefixes.secondary}
          />

          <YAxis
            domain={[yAxisMinData >= 0 ? 0 : "dataMin", yAxisMaxData]}
            strokeOpacity={0.2}
            strokeDasharray="3 3"
            tick={
              <CustomizedYAxisTick
                empty={isEmpty || loading}
                formatter={yAxisFormatter}
              />
            }
          />

          <Tooltip
            cursor={{ fill: "transparent" }}
            wrapperStyle={{
              zIndex: theme.zIndex.tooltip,
            }}
            content={
              <MultiStackChartTooltipContent
                barKey={barKey}
                {...tooltipProps}
              />
            }
          />

          {keys.visibleKeys && (
            <Fragment>
              {multiStackChartBars({
                keys: keys.visibleKeys,
                highlighted: hover.key,
                onMouseOver,
                colors,
                data,
              })}
            </Fragment>
          )}

          {referenceLines?.map((props) => (
            <ReferenceLine {...props} key={props.uniqueId} />
          ))}
        </RechartsBarChart>
      </ResponsiveContainer>

      {keys.visibleKeys && (
        <Legend
          wrapperStyle={{ position: "initial" }}
          content={
            <LegendContent
              keys={keysForLegend}
              colors={colors}
              selectable={selectable}
              referenceLines={referenceLines}
              visibleKeys={visibleKeysForLegend}
              highlightable={highlight}
              setVisibleKeys={visibleKeysSetHandler}
              legendItemLabelFormatter={legendItemLabelFormatter}
            />
          }
        />
      )}
    </Fragment>
  );
};
