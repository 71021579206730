import { FC, Fragment, useCallback } from "react";
import {
  Button,
  List,
  ListItemButton,
  Popover,
  Stack,
  Tooltip,
} from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { addResourceExplorerViewFilterThunk } from "../../../../../../store/resource-explorer/thunks/filters/addResourceExplorerViewFilterThunk";
import { resourceExplorerProvidersWithoutFiltersSelector } from "../../../../../../store/resource-explorer/selectors/filters/filter-providers/resourceExplorerProvidersWithoutFiltersSelector";
import { ProviderLogo } from "../../../../../common/provider-logo/ProviderLogo";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";
import { resourceExplorerDimensionFilterAppliedSelector } from "../../../../../../store/resource-explorer/selectors/filters/dimension/resourceExplorerDimensionFilterAppliedSelector";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";

export const AddFilterButton: FC = () => {
  const dispatch = useAppDispatch();

  const { open, openMenu, closeMenu, anchor } = useMenuHook();

  const resourceExplorerProvidersWithoutFilters = useAppSelector(
    resourceExplorerProvidersWithoutFiltersSelector,
  );
  const dimensionFilterApplied = useAppSelector(
    resourceExplorerDimensionFilterAppliedSelector,
  );

  const onViewTypeItemSelect = useCallback(
    (provider: ProviderType) => {
      dispatch(
        addResourceExplorerViewFilterThunk({
          provider,
        }),
      );

      closeMenu();
    },
    [dispatch, closeMenu],
  );

  return (
    <Fragment>
      <Tooltip
        title={
          dimensionFilterApplied
            ? "Remove the Dimension filter to add custom filters."
            : ""
        }
        arrow
        placement="top"
      >
        <span>
          <Button
            onClick={openMenu}
            startIcon={<FilterAltOutlinedIcon />}
            disabled={
              resourceExplorerProvidersWithoutFilters.length === 0 ||
              dimensionFilterApplied
            }
          >
            Add Filters
          </Button>
        </span>
      </Tooltip>

      <Popover
        open={open}
        anchorEl={anchor}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ p: 0 }}
      >
        <List sx={{ p: 0, minWidth: 145 }}>
          {resourceExplorerProvidersWithoutFilters.map((item) => {
            return (
              <ListItemButton
                key={item}
                onClick={onViewTypeItemSelect.bind(null, item)}
              >
                <Stack direction="row" gap={1} alignItems="center">
                  {<ProviderLogo provider={item} />}
                  {getProviderName(item, { title: true })}
                </Stack>
              </ListItemButton>
            );
          })}
        </List>
      </Popover>
    </Fragment>
  );
};
