import { FC, useCallback, useMemo, useState } from "react";
import { useEffectOnceWhen, useIntervalWhen, useTimeoutWhen } from "rooks";
import { SnackbarKey, useSnackbar } from "notistack";
import { Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  ResourceType,
  useGetV3UsersMeExecutionLogsByExecutionIdQuery,
} from "../../../../services/cloudchipr.api";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  removeCleanProcess,
  setCurrentCleanId,
} from "../../../../store/clean/cleanSlice";
import { cleanActionTypeSelector } from "../../../../store/clean/selectors/cleanActionTypeSelector";
import { getCleanActionOptions } from "../../../../utils/clean-options";
import { getResourceTypeName } from "../../../../utils/helpers/resources/getResourceTypeName";
import { getCurrentAccountFilterTemplatesThunk } from "../../../../store/filters/thunks/filter-set/getCurrentAccountFilterTemplatesThunk";
import { getCurrentAccountResourceTypeDataThunk } from "../../../../store/account/thunks/filters-get/getCurrentAccountResourceTypeDataThunk";

interface CleanProcessProps {
  executionLogId: string;
  resourceType: ResourceType;
}
export const CleanProcess: FC<CleanProcessProps> = ({
  executionLogId,
  resourceType,
}) => {
  const dispatch = useAppDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [complete, setComplete] = useState(false);

  const cleanType = useAppSelector(cleanActionTypeSelector);

  const cleanActionOptions = resourceType
    ? getCleanActionOptions(resourceType)
    : undefined;

  const cleanActionType = cleanActionOptions?.find(
    (item) => item.value === cleanType,
  );

  const { data, refetch } = useGetV3UsersMeExecutionLogsByExecutionIdQuery(
    { executionId: executionLogId },
    {
      pollingInterval: 5000,
      skip: complete,
    },
  );

  const snackbarExitedHandler = useCallback(() => {
    dispatch(removeCleanProcess(executionLogId));
  }, [dispatch, executionLogId]);

  const enqueueSnackbarHandler = useCallback(
    (severity: "error" | "warning" | "success", description: any) => {
      const key: SnackbarKey = enqueueSnackbar(
        `${cleanActionType?.label} ${getResourceTypeName(resourceType)}:`,
        {
          variant: "snackbarAlert",
          autoHideDuration: 5000,
          onExited: snackbarExitedHandler,
          AlertSnackBarProps: {
            severity,
            onClose: () => closeSnackbar(key),
            description: description ? (
              <Typography
                to={`/execution-log?ids=${data?.id}`}
                component={RouterLink}
                variant="body2"
                fontWeight="bold"
                color="text.primary"
                px={4}
              >
                {description}
              </Typography>
            ) : null,
          },
        },
      );
    },
    [
      data,
      cleanActionType,
      closeSnackbar,
      enqueueSnackbar,
      resourceType,
      snackbarExitedHandler,
    ],
  );

  const executionLogReady = useMemo(() => {
    return data?.response?.every(({ resources }) =>
      resources?.every(({ succeded }) => typeof succeded === "boolean"),
    );
  }, [data]);

  useEffectOnceWhen(() => {
    if (!data?.response) {
      return;
    }

    data.response.forEach((item) => {
      let successLength = 0;
      let failureLength = 0;

      item.resources.forEach(({ succeded }) =>
        succeded ? successLength++ : failureLength++,
      );

      if (successLength && failureLength) {
        enqueueSnackbarHandler(
          "warning",
          `${successLength} succeeded,
                    ${failureLength} failed.`,
        );
      } else if (successLength) {
        enqueueSnackbarHandler("success", `${successLength} succeeded.`);
      } else if (failureLength) {
        enqueueSnackbarHandler("error", `${failureLength} failed.`);
      }
    });

    setComplete(true);
    dispatch(getCurrentAccountFilterTemplatesThunk());
  }, !!executionLogReady);

  useIntervalWhen(refetch, 3000, !executionLogReady && !!data?.response, true);

  useTimeoutWhen(
    () => {
      dispatch(setCurrentCleanId(null));
      dispatch(getCurrentAccountResourceTypeDataThunk(resourceType));
    },
    500,
    complete,
  );

  return null;
};
