import { createAsyncThunk } from "@reduxjs/toolkit";
import { getResourceExplorerPossibleFiltersThunk } from "./getResourceExplorerPossibleFiltersThunk";
import { enabledProvidersArraySelector } from "../../../common/selectors/billing-analytics/enabled-providers/enabledProvidersArraySelector";
import { RootState } from "../../../store";

export const getResourceExplorerEnabledProvidersPossibleFiltersThunk =
  createAsyncThunk(
    "resourceExplorer/getResourceExplorerEnabledProvidersPossibleFiltersThunk",
    async (_, { dispatch, getState }) => {
      const state = getState() as RootState;

      const availableProviders = enabledProvidersArraySelector(state);

      availableProviders.forEach((provider) => {
        dispatch(
          getResourceExplorerPossibleFiltersThunk({
            provider,
          }),
        );
      });
    },
  );
