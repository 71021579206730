import { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { setCoverageAndUtilizationDateGranularity } from "../../../../../../store/commitments/commitmentsSlice";
import { utilizationAndCoverageDateGranularitySelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/view-options/utilizationAndCoverageDateGranularitySelector";
import { DateDataPoint } from "../../../../../../services/cloudchipr.api";
import { CommitmentsDateGranularitySelector } from "../common/CommitmentsDateGranularitySelector";
import { getSavingsPlansForGranularThunk } from "../../../../../../store/commitments/thunks/savings-plans/getSavingsPlansForGranularThunk";

export const UtilizationAndCoverageDateGranularity: FC = () => {
  const dispatch = useAppDispatch();

  const dateGranularity = useAppSelector(
    utilizationAndCoverageDateGranularitySelector,
  );

  const changeHandler = useCallback(
    (dg: DateDataPoint) => {
      dispatch(setCoverageAndUtilizationDateGranularity(dg));
      dispatch(getSavingsPlansForGranularThunk());
    },
    [dispatch],
  );

  if (!dateGranularity) {
    return null;
  }

  return (
    <CommitmentsDateGranularitySelector
      onChange={changeHandler}
      dateGranularity={dateGranularity}
    />
  );
};
