import { billingErrorAccountStatusesByProviderSelector } from "./billingErrorAccountStatusesByProviderSelector";

import { RootState } from "../../../../store";
import { enabledProvidersSelector } from "../../billing-analytics/enabled-providers/enabledProvidersSelector";
import { BillingAccountStatusWithProvider } from "../../../utils/types/types";

export const billingErrorAccountStatusesSelector = (
  state: RootState,
): BillingAccountStatusWithProvider[] => {
  const enabledProviders = enabledProvidersSelector(state);
  const enabledProvidersArr = Array.from(enabledProviders);

  return enabledProvidersArr.reduce((result, provider) => {
    const data = billingErrorAccountStatusesByProviderSelector(state, provider);
    result = result.concat(data);

    return result;
  }, [] as BillingAccountStatusWithProvider[]);
};
