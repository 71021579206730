import { FC, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { useAppDispatch } from "../../../../../../store/hooks";
import { usePatchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdMutation } from "../../../../../../services/cloudchipr.api";
import { getResourceExplorersThunk } from "../../../../../../store/resource-explorer/thunks/resource-explorer/getResourceExplorersThunk";

interface RenameActionItemDialogProps {
  open: boolean;
  id: string;
  name: string;
  closeMenu(): void;
}

export const RenameActionItemDialog: FC<RenameActionItemDialogProps> = ({
  open,
  closeMenu,
  id,
  name,
}) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(name);

  const [patchTrigger, { isLoading }] =
    usePatchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdMutation(
      {},
    );

  const renameHandler = useCallback(async () => {
    try {
      await patchTrigger({
        resourceExplorerViewId: id,
        body: { name: value },
      }).unwrap();

      enqueueSnackbar("View name successfully updated.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "success" },
      });

      dispatch(getResourceExplorersThunk());
    } catch (e) {
      // @ts-expect-error //TODO: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }

    closeMenu();
  }, [dispatch, closeMenu, id, patchTrigger, value]);

  const inputChangeHandler = useCallback((event: any) => {
    setValue(event.target.value);
  }, []);

  return (
    <Dialog open={open} fullWidth onClose={closeMenu}>
      <DialogTitleClosable title="Rename View" onClose={closeMenu} />

      <DialogContent dividers sx={{ py: 3 }}>
        <Typography variant="caption" mb={1}>
          View Name
        </Typography>
        <TextField
          autoFocus
          fullWidth
          type="text"
          value={value}
          size="small"
          variant="outlined"
          onChange={inputChangeHandler}
        />
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3 }}>
        <Button color="tertiary" onClick={closeMenu}>
          Cancel
        </Button>
        <LoadingButton
          disabled={value === name || !value}
          onClick={renameHandler}
          loading={isLoading}
          variant="contained"
        >
          Rename
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
