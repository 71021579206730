import { FC, useMemo } from "react";
import { Stack } from "@mui/material";
import { CommitmentsDrawerContentInfoCard } from "../common/CommitmentsDrawerContentInfoCard";
import { useAppSelector } from "../../../../../../../store/hooks";
import { selectedCoverageDataSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/resource-type/selectedCoverageDataSelector";
import { savingsPlansResourceTypeNames } from "../../../utils/constants/labels";
import { SavingsPlanResourceType } from "../../../../../../../services/cloudchipr.api";

export const CoverageDrawerContentInfoCards: FC = () => {
  const coverageData = useAppSelector(selectedCoverageDataSelector);

  const data = useMemo(() => {
    const resourceType = coverageData?.resource_type
      ?.resource_type as SavingsPlanResourceType;

    return {
      "Resource Type": resourceType
        ? savingsPlansResourceTypeNames[resourceType]
        : "",
      "Instance Family": coverageData?.resource_type?.instance_family,
      Region: coverageData?.resource_type?.region,
    };
  }, [coverageData]);

  if (!data) {
    return null;
  }

  return (
    <Stack direction="row" gap={1} flexWrap="wrap" p={2}>
      {Object.entries(data).map(([title, value]) => {
        return (
          <CommitmentsDrawerContentInfoCard
            key={title}
            width={200}
            title={title}
            value={value}
          />
        );
      })}
    </Stack>
  );
};
