import { FC } from "react";
import { Fade, Stack } from "@mui/material";
import { WidgetTitleProps } from "./source-and-name/WidgetSourceInfo";
import { WidgetActions } from "./actions/WidgetActions";
import { WidgetEditButton } from "./actions/WidgetEditButton";
import { WidgetName } from "./source-and-name/widget-name/WidgetName";
import { WidgetType } from "../../../../../../../services/cloudchipr.api";

interface WidgetCardHeaderActionRowProps {
  hovered: boolean;
  widgetId: string;
  widgetType: WidgetType;
  widgetTitleProps: WidgetTitleProps | null;
}

export const WidgetCardHeaderActionRow: FC<WidgetCardHeaderActionRowProps> = ({
  widgetId,
  widgetTitleProps,
  widgetType,
  hovered,
}) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      {widgetTitleProps && (
        <WidgetName
          hovered={hovered}
          widgetId={widgetId}
          widgetType={widgetType}
          name={widgetTitleProps?.widgetName}
        />
      )}

      <Stack direction="row" spacing={1} alignItems="center">
        <Fade in={hovered}>
          <div>
            <WidgetEditButton widgetId={widgetId} widgetType={widgetType} />
          </div>
        </Fade>

        <WidgetActions
          widgetId={widgetId}
          widgetType={widgetType}
          widgetName={widgetTitleProps?.widgetName ?? ""}
        />
      </Stack>
    </Stack>
  );
};
