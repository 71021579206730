import { FC, Fragment, useMemo } from "react";
import { Grid } from "@mui/material";
import { getTimeZoneObject } from "../../../../../../../utils/helpers/date-time/timeZones";
import { getAutomationPreviewFormatDate } from "../../../../common/utils/helpers/common";
import { SchedulePreviewSectionTitle } from "../../../../common/create/preview/SchedulePreviewSectionTitle";
import { SchedulePreviewTable } from "../../../../common/create/preview/SchedulePreviewTable";
import { NextRunDatesTime } from "../../../../common/create/scheduler/NextRunDatesTime";
import { AutomationTabType } from "../../../../common/utils/types/types";

interface ScheduledJobsDetailsPreviewProps {
  timeZone: string;
  startDate: string;
  nextRunDate: string;
  endDate: string | null;
  scheduleText?: string;
  hideEnds?: boolean;
  tabType?: AutomationTabType;
}

export const ScheduledJobsDetailsPreview: FC<
  ScheduledJobsDetailsPreviewProps
> = ({
  timeZone,
  endDate,
  nextRunDate,
  scheduleText,
  startDate,
  hideEnds,
  tabType,
}) => {
  const data = useMemo(() => {
    const rowsSource = [
      {
        title: "Schedule",
        label: scheduleText,
      },

      {
        title: "Next Run at",
        label: hideEnds ? (
          nextRunDate
        ) : (
          <NextRunDatesTime timeZone={timeZone} tabType={tabType} />
        ),
        labelSx: {
          py: 0,
        },
      },
      {
        title: "Begins",
        label: startDate ? getAutomationPreviewFormatDate(startDate) : "now",
      },
      {
        title: "Ends",
        label: endDate ? getAutomationPreviewFormatDate(endDate) : "never",
      },
      {
        title: "Time zone",
        label: getTimeZoneObject(timeZone).name,
      },
    ] as const;

    const hiddenRows: Partial<
      Record<(typeof rowsSource)[number]["title"], boolean>
    > = {
      Schedule: !scheduleText,
      Ends: hideEnds,
    };

    return rowsSource.filter((row) => !hiddenRows[row.title]);
  }, [
    nextRunDate,
    endDate,
    scheduleText,
    timeZone,
    startDate,
    hideEnds,
    tabType,
  ]);

  return (
    <Fragment>
      <SchedulePreviewSectionTitle title="Schedule Details" />

      <Grid container>
        <Grid item xs={12} md={12}>
          <SchedulePreviewTable data={data} />
        </Grid>
      </Grid>
    </Fragment>
  );
};
