import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

export const currentAccountNameIdSelector = (state: RootState) => {
  const currentAccountId = state.account.id;

  if (!currentAccountId) {
    return "";
  }

  const currentAccount =
    cloudChiprApi.endpoints.getUsersMeAccountsByAccountId.select({
      accountId: currentAccountId,
    })(state).data;

  return (
    currentAccount?.provider_account_name ||
    currentAccount?.provider_account_id ||
    ""
  );
};
