import { createAsyncThunk } from "@reduxjs/toolkit";
import { WidgetType } from "../../../../../services/cloudchipr.api";
import { editCostByCategoryWidgetThunk } from "../cost-by-category/editCostByCategoryWidgetThunk";
import { editCostBreakdownWidgetThunk } from "../cost-breakdown/editCostBreakdownWidgetThunk";
import { editCostAndUsageSumWidgetThunk } from "../cost-and-usage/editCostAndUsageSumWidgetThunk";
import { editLargestCostChangesWidgetThunk } from "../largest-cost-changes/editLargestCostChangesWidgetThunk";

interface EditWidgetThunkArgs {
  widgetId: string;
  widgetType: WidgetType;
}
export const editWidgetThunk = createAsyncThunk(
  "dashboards/editWidget",
  ({ widgetId, widgetType }: EditWidgetThunkArgs, { dispatch }) => {
    if (widgetType === "costs_by_category_v2") {
      dispatch(editCostByCategoryWidgetThunk(widgetId));
    }
    if (widgetType === "cost_breakdown") {
      dispatch(editCostBreakdownWidgetThunk(widgetId));
    }
    if (widgetType === "cost_and_usage_summary") {
      dispatch(editCostAndUsageSumWidgetThunk(widgetId));
    }
    if (widgetType === "largest_cost_changes") {
      dispatch(editLargestCostChangesWidgetThunk(widgetId));
    }
  },
);
