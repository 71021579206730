import { resourceTypeDataSelector } from "./resourceTypeDataSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const filteredResourcesCountSelector = (
  state: RootState,
  resourceType: ResourceType,
): number | undefined => {
  const data = resourceTypeDataSelector(state, resourceType);

  return data?.resources?.length;
};
