import { costByCategoryViewChartDataSelector } from "./costByCategoryViewChartDataSelector";
import { costByCategorySetupChartDataSelector } from "./costByCategorySetupChartDataSelector";
import { RootState } from "../../../../../store";
import { buildingInProgressWidgetId } from "../../../../thunks/widgets/utils/constants";
import { chartDataPointKey } from "../../../../../../storybook/charts/multi-type-chart/utils/constants/constants";

export const costByCategoryFilteredResourcesTotalData = (
  state: RootState,
  widgetId: string,
) => {
  let chartData;
  if (widgetId !== buildingInProgressWidgetId) {
    chartData = costByCategoryViewChartDataSelector(state, widgetId, true);
  } else {
    chartData = costByCategorySetupChartDataSelector(
      state,
      buildingInProgressWidgetId,
      true,
    );
  }

  return chartData?.reduce(
    (acc, item) => {
      Object.entries(item).forEach(([key, value]) => {
        if (key !== chartDataPointKey) {
          acc[key] = (acc[key] ?? 0) + +(value ?? 0);
        }
      });

      return acc;
    },
    {} as Record<string, number>,
  );
};
