import { FC, memo } from "react";
import { Stack } from "@mui/material";
import { CostBreakdownWidgetChart } from "./CostBreakdownWidgetChart";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { MultiTypeChartProvider } from "../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { costBreakdownWidgetChartDataSelector } from "../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownWidgetChartDataSelector";
import { ChartType } from "../../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { CostBreakdownWidgetContentCosts } from "../CostBreakdownWidgetContentCosts";
import { DateLabelNullable } from "../../../../../../../../services/cloudchipr.api";

interface CostBreakdownWidgetChartViewProps {
  widgetId: string;
  chartType: ChartType;
  dateLabel?: DateLabelNullable;
}

export const CostBreakdownWidgetChartView: FC<CostBreakdownWidgetChartViewProps> =
  memo(({ chartType, widgetId, dateLabel }) => {
    const chartData = useAppSelector((state) =>
      costBreakdownWidgetChartDataSelector(state, widgetId),
    );

    if (!chartData) {
      return null;
    }

    return (
      <Stack px={2} py={1} gap={1}>
        <MultiTypeChartProvider
          value={{
            initialChartType: chartType,
            data: chartData ?? [],
          }}
        >
          <CostBreakdownWidgetContentCosts
            widgetId={widgetId}
            dateLabel={dateLabel}
          />
          <CostBreakdownWidgetChart widgetId={widgetId} chartType={chartType} />
        </MultiTypeChartProvider>
      </Stack>
    );
  });
