import { FC, memo, useCallback } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { ResourceType } from "../../../../../../services/cloudchipr.api";
import { ResourceStatistic } from "../../common/ResourceStatistic";
import { useAppSelector } from "../../../../../../store/hooks";
import { resourceTypeDataEmsSelector } from "../../../../../../store/account/selectors/resource-type-data/resourceTypeDataEmsSelector";
import { resourceTypeDataSpendingSelector } from "../../../../../../store/account/selectors/resource-type-data/resourceTypeDataSpendingSelector";
import { resourceTypeDataLoadingSelector } from "../../../../../../store/account/selectors/resource-type-data/resourceTypeDataLoadingSelector";
import { money } from "../../../../../../utils/numeral/money";
import { getResourceTypeName } from "../../../../../../utils/helpers/resources/getResourceTypeName";

interface AccountResourceAreaDrawerProps {
  resourceType: ResourceType;
  activeResource?: ResourceType;
  onResourceSelect(type: ResourceType): void;
}

export const AccountResourceAreaDrawerContent: FC<AccountResourceAreaDrawerProps> =
  memo(({ onResourceSelect, resourceType, activeResource }) => {
    const ems = useAppSelector((state) =>
      resourceTypeDataEmsSelector(state, resourceType),
    );

    const spending = useAppSelector((state) =>
      resourceTypeDataSpendingSelector(state, resourceType),
    );

    const loading = useAppSelector((state) =>
      resourceTypeDataLoadingSelector(state, resourceType),
    );

    const clickHandler = useCallback(() => {
      onResourceSelect(resourceType);
    }, [onResourceSelect, resourceType]);

    return (
      <Grid
        key={resourceType}
        borderLeft={2}
        borderColor={
          activeResource === resourceType ? "grey.800" : "transparent"
        }
        container
        direction="row"
        alignItems="center"
        px={3}
      >
        <Grid item sm={6}>
          <Typography
            variant="subtitle2"
            onClick={clickHandler}
            fontSize={14}
            color="text.primary"
            sx={{
              cursor: "pointer",
              textDecoration:
                activeResource !== resourceType ? "underline" : "",
            }}
            fontWeight={activeResource === resourceType ? "bold" : "inherit"}
          >
            {getResourceTypeName(resourceType, {
              type: "long",
            })}
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Stack
            direction="row"
            spacing={1}
            py={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <ResourceStatistic
              hasBackground
              color="red"
              value={money(ems)}
              loading={loading}
              size="small"
            />
            <ResourceStatistic
              color="blue"
              value={money(spending)}
              loading={loading}
              hasBackground
              size="small"
            />
          </Stack>
        </Grid>
      </Grid>
    );
  });
