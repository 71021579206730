import { FC, useCallback } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useDidMount } from "rooks";
import { TabPanel } from "@mui/lab";
import {
  formatDate,
  getDateNowByTimeZone,
} from "../../../../../../../utils/helpers/date-time/datetime-format";
import { DateTimePicker } from "../../../../common/create/scheduler/DateTimePicker";
import { ScheduleDatesInterface } from "../../../../common/create/scheduler/DateTimeContent";
import { NextPrevious } from "../../../../common/create/NextPrevious";
import { TimeZones } from "../../../../common/create/scheduler/TimeZones";
import { onceAutomationValidationSchema } from "../../../../common/utils/constants/validation";
import { WorkflowSchedulerData } from "../../../utils/types/types";

const initialValues: ScheduleDatesInterface = {
  id: "",
  timeZone: "",
  startDate: null,
  endDate: null,
};

const validationSchema = Yup.object({
  startDate: onceAutomationValidationSchema,
});

interface OnceProps {
  setScheduleDatesData(data: WorkflowSchedulerData): void;
  onPreviousStep(): void;
  hideCancelButton?: boolean;
  isDirty: boolean;
}

export const Once: FC<OnceProps & ScheduleDatesInterface> = ({
  startDate,
  setScheduleDatesData,
  endDate,
  timeZone,
  onPreviousStep,
  id,
  isDirty,
  hideCancelButton,
}) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setScheduleDatesData({
        startDate: values.startDate,
        endDate: values.endDate,
        cron: null,
        timeZone: values.timeZone,
      });
    },
  });

  const {
    values,
    errors,
    touched,
    submitForm,
    setFieldValue,
    resetForm,
    dirty,
    isValid,
    setValues,
  } = formik;

  const dateChangeHandler = useCallback(
    (value: any) => {
      if (value) {
        setFieldValue("startDate", formatDate(value));
      }
    },
    [setFieldValue],
  );

  useDidMount(() => {
    const values = { startDate, endDate, timeZone, id };

    if (id) {
      resetForm({ values });
    } else {
      setValues(values);
    }
  });

  return (
    <TabPanel value="once">
      <form>
        <Stack direction="row" spacing={3} alignItems="baseline">
          <Typography variant="subtitle2">Runs On</Typography>
          <DateTimePicker
            date={values.startDate}
            error={touched.startDate && errors.startDate}
            onChange={dateChangeHandler}
            minDateTime={getDateNowByTimeZone(values.timeZone)}
          />
        </Stack>
        <Stack mt={4}>
          <Divider />
          <TimeZones timeZone={values.timeZone} setFieldValue={setFieldValue} />
        </Stack>

        <Box mt={10}>
          <NextPrevious
            hideCancelButton={hideCancelButton}
            isNextDisabled={!id ? !dirty || !isValid : !isValid}
            onNext={submitForm}
            onPrevious={onPreviousStep}
            isDirty={dirty || isDirty}
          />
        </Box>
      </form>
    </TabPanel>
  );
};
