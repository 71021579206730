import { RootState } from "../../../../store";
import {
  ResourceExplorerFilterItem,
  ResourceExplorerTag,
  ProviderType,
} from "../../../../../services/cloudchipr.api";
import { costAllocationTagValuesDataSelector } from "../cost-allocations-tags/costAllocationTagValuesDataSelector";
import { resourceExplorerPossibleFiltersByProviderSelector } from "../possible-filters/resourceExplorerPossibleFiltersByProviderSelector";

export const isAllCostAllocationTagsSelectedSelector = (
  state: RootState,
  filter: ResourceExplorerFilterItem | null,
  provider: ProviderType,
): boolean => {
  if (!filter) {
    return false;
  }

  const possibleCostAllocationTags =
    resourceExplorerPossibleFiltersByProviderSelector(
      state,
      provider,
    )?.cost_allocation_tag;

  const allSelectedTagsCount = (filter.value as ResourceExplorerTag[])?.reduce(
    (result, item) => {
      const possibleOptions = costAllocationTagValuesDataSelector(
        state,
        provider,
        item?.tag_key ?? [],
      );
      if (
        possibleOptions &&
        possibleOptions?.length === item?.tag_values?.length &&
        possibleOptions?.length > 0
      ) {
        ++result;
      }
      return result;
    },
    0,
  );

  return (
    possibleCostAllocationTags?.values?.length === filter?.value?.length &&
    filter.value?.length === allSelectedTagsCount
  );
};
