import { costBreakdownV2WidgetSetupSelector } from "./costBreakdownV2WidgetSetupSelector";
import { costBreakdownV2WidgetSetupDataPayloadSelector } from "./costBreakdownV2WidgetSetupDataPayloadSelector";
import { RootState } from "../../../../../store";

export const costBreakdownV2WidgetSetupDataSelector = (state: RootState) => {
  const payload = costBreakdownV2WidgetSetupDataPayloadSelector(state);
  if (!payload) {
    return;
  }
  const response = costBreakdownV2WidgetSetupSelector(payload)(state);

  return response?.data;
};
