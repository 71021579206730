import { useCallback, useMemo } from "react";
import { Box, Switch, Tooltip } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { filterTemplatesEnabledSelector } from "../../../../../store/account/selectors/current-account/resources/filterTemplatesEnabledSelector";
import { currentAccountProviderTypeSelector } from "../../../../../store/account/selectors/current-account/currentAccountProviderTypeSelector";
import { resourceGroups } from "../../../../../utils/constants/resources/resourceGroups";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { useToggledResourceTypes } from "../../utils/hooks/useToggledResources";
import { filterTemplateEnabledChangeThunk } from "../../../../../store/account/thunks/filterTemplateEnabledChangeThunk";
import { resourceTypeSearchParams } from "../navigation/utils/constants";

export const EnableFilterTemplatesSwitcher = () => {
  const dispatch = useAppDispatch();
  const filterTemplatesEnabled = useAppSelector(filterTemplatesEnabledSelector);
  const currentAccountProviderType = useAppSelector(
    currentAccountProviderTypeSelector,
  );
  const [searchParams] = useSearchParams();

  const resourceTypeFromRouter = searchParams.get(
    resourceTypeSearchParams,
  ) as ResourceType;
  const resourceGroup =
    currentAccountProviderType &&
    resourceGroups.get(currentAccountProviderType);
  const toggledResourceTypes = useToggledResourceTypes();

  const activeResourceGroup = useMemo(() => {
    return resourceGroup?.find(
      (rg) =>
        rg.value === resourceTypeFromRouter ||
        rg.resourceTypes.includes(resourceTypeFromRouter),
    );
  }, [resourceGroup, resourceTypeFromRouter]);

  const switchChangeHandler = useCallback(() => {
    dispatch(
      filterTemplateEnabledChangeThunk({
        resourceTypes: activeResourceGroup?.resourceTypes ?? [],
        toggledResourceTypes,
      }),
    );
  }, [dispatch, activeResourceGroup, toggledResourceTypes]);

  return (
    <Box px={1}>
      <Tooltip
        title={`Turn ${filterTemplatesEnabled ? "Off" : "On"} Filter Template`}
        arrow
        placement="top"
      >
        <Switch
          size="small"
          checked={filterTemplatesEnabled}
          onChange={switchChangeHandler}
        />
      </Tooltip>
    </Box>
  );
};
