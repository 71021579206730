import { resourceExplorerIdSelector } from "./resourceExplorerIdSelector";
import { RootState } from "../../../store";
import { resourceExplorerViewDataByIdSelector } from "../resource-explorer-by-id/resourceExplorerViewDataByIdSelector";

export const currentResourceExplorerInitialGroupingSelector = (
  state: RootState,
) => {
  const viewId = resourceExplorerIdSelector(state);
  const resourceExplorerView = resourceExplorerViewDataByIdSelector(
    state,
    viewId,
  );

  return resourceExplorerView?.data?.grouping;
};
