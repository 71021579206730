import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { InfoDataChip } from "./InfoDataChip";
import { SourceDataChip, SourceDataChipProps } from "./SourceDataChip";
import { DateInfoChip, DateInfoChipProps } from "./DateInfoChip";

export interface WidgetTitleProps {
  widgetName: string;
  grouping?: string;
  dates: DateInfoChipProps;
  sourceData?: SourceDataChipProps;
}

type WidgetSourceInfoProps = Omit<WidgetTitleProps, "widgetName">;

export const WidgetSourceInfo: FC<WidgetSourceInfoProps> = ({
  grouping,
  dates,
  sourceData,
}) => {
  return (
    <Stack direction="row" gap={1} alignItems="center" overflow="auto">
      {sourceData && <SourceDataChip {...sourceData} />}

      {grouping && (
        <InfoDataChip>
          Group By:{" "}
          <Typography
            component="span"
            variant="inherit"
            fontWeight="medium"
            color="text.primary"
          >
            {grouping}
          </Typography>
        </InfoDataChip>
      )}

      <DateInfoChip {...dates} />
    </Stack>
  );
};
