import { FC } from "react";
import { Button, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EmptyDashboardImage from "../../../../../assets/images/empty-dashboard.png";
import { DashboardCreateDialog } from "../../../../navigation/components/dashboard/components/create-dialog/DashboardCreateDialog";
import { useDialog } from "../../../../../utils/hooks/useDialog.hook";
import { useAppAbility } from "../../../../../services/permissions";
export const EmptyDashboards: FC = () => {
  const { cannot } = useAppAbility();
  const { open, openDialog, closeDialog } = useDialog();
  const canNotCreateDashboard = cannot("create", "dashboard");

  return (
    <Stack alignItems="center" gap={2} mt={6}>
      <img src={EmptyDashboardImage} alt="EmptyDashboard" />

      <Typography variant="h5" fontWeight="medium">
        Dashboards
      </Typography>

      <Typography variant="subtitle1" color="text.secondary">
        Create your first dashboard and share it with others.
      </Typography>

      <Button
        disabled={canNotCreateDashboard}
        variant="contained"
        startIcon={<AddIcon />}
        onClick={openDialog}
      >
        Add Dashboard
      </Button>
      <DashboardCreateDialog open={open} onClose={closeDialog} />
    </Stack>
  );
};
