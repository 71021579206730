import { getCurrentProvidersByProviderBillingStatusSelector } from "../getCurrentProvidersByProviderBillingStatusSelector";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { isBillingErrorStatus } from "../../../utils/helpers/billing-status/isBillingErrorStatus";
import { BillingAccountStatusWithProvider } from "../../../utils/types/types";
import { isBillingWarningStatus } from "../../../utils/helpers/billing-status/isBillingWarningStatus";

export const billingErrorAccountStatusesByProviderSelector = (
  state: RootState,
  provider: ProviderType,
): BillingAccountStatusWithProvider[] => {
  const data = getCurrentProvidersByProviderBillingStatusSelector(
    state,
    provider,
  ).data;

  const billingStatuses = data?.billing_account_statuses ?? [];

  if (!billingStatuses.length && isBillingErrorStatus(data?.status)) {
    return [{ status: data?.status, provider }];
  }

  const result: BillingAccountStatusWithProvider[] = [];
  billingStatuses?.forEach((item) => {
    if (
      isBillingErrorStatus(item.status) ||
      isBillingWarningStatus(item.status)
    ) {
      result.push({
        ...item,
        provider,
      });
    }
  });

  return result;
};
