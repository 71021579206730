import { Ability, AbilityBuilder, AbilityClass } from "@casl/ability";
import { createContextualCan, useAbility } from "@casl/react";
import { createContext } from "react";

export type Actions =
  | "view"
  | "create"
  | "edit"
  | "delete"
  | "self-delete"
  | "clean"
  | "apply"
  | "protect";

export type Subjects =
  | "user"
  | "account"
  | "account-filter"
  | "schedule"
  | "organisation"
  | "budget"
  | "billing"
  | "resource-explorer"
  | "integration"
  | "resource"
  | "sso"
  | "api-key"
  | "filter-set"
  | "dashboard"
  | "recommendation"
  | "category"
  | "commitment";
export type ApiScopeTuple = [Actions, Subjects];

export type AppAbilityType = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbilityType>;

export function createAbility() {
  const ability = new AppAbility();
  ability.can = ability.can.bind(ability);
  ability.cannot = ability.cannot.bind(ability);

  return ability;
}

export function defineRulesFor(permissions: ApiScopeTuple[]) {
  const { can, rules } = new AbilityBuilder(AppAbility);

  permissions.forEach((permission) => {
    can(permission[0], permission[1]);
  });

  return rules;
}

export const AbilityContext = createContext<AppAbilityType>(
  null as unknown as AppAbilityType,
);
export const useAppAbility = () => useAbility(AbilityContext);

export const Can = createContextualCan(AbilityContext.Consumer);
