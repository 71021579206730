import { RootState } from "../../../../../store";
import { GetUsersMeOrganisationsCurrentSavingsPlansApiArg } from "../../../../../../services/cloudchipr.api";
import { commitmentsCurrentOrgIdSelector } from "../../../commitmentsCurrentOrgIdSelector";
import { utilizationAndCoverageDatesSelector } from "../../view-options/utilizationAndCoverageDatesSelector";
import { utilizationAndCoverageDateGranularitySelector } from "../../view-options/utilizationAndCoverageDateGranularitySelector";
import { utilizationAndCoverageCoverageGroupingSelector } from "../../view-options/utilizationAndCoverageCoverageGroupingSelector";
import { covertDatesToQueryParam } from "../../../../../../utils/helpers/covertDatesToQueryParam";

export const commitmentsPayloadDataSelector = (
  state: RootState,
): GetUsersMeOrganisationsCurrentSavingsPlansApiArg => {
  const dates = utilizationAndCoverageDatesSelector(state);
  const orgId = commitmentsCurrentOrgIdSelector(state);
  const dataPoint = utilizationAndCoverageDateGranularitySelector(state);
  const coverageGrouping =
    utilizationAndCoverageCoverageGroupingSelector(state);

  return {
    dataGranularity: dataPoint,
    providerOrganisationId: orgId || undefined,
    coverageGrouping: coverageGrouping,

    ...covertDatesToQueryParam(dates),
  };
};
