import { FC, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { WidgetViewTypeSelect } from "../../../common/WidgetViewTypeSelect";
import { costBreakdownV2ViewTypeChangeThunk } from "../../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2ViewTypeChangeThunk";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";

export const CostBreakdownV2WidgetViewTypeSelect: FC = () => {
  const dispatch = useAppDispatch();

  const viewType = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("viewType"),
  );

  const changeHandler = useCallback(
    (e: any) => {
      const type = e.target.value;

      dispatch(costBreakdownV2ViewTypeChangeThunk(type));
    },
    [dispatch],
  );

  return (
    <WidgetViewTypeSelect
      viewType={viewType}
      options={["bar", "stack", "line", "pie", "table", "numeral"]}
      onChange={changeHandler}
    />
  );
};
