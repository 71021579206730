import { FC, useCallback } from "react";
import { Checkbox, ListItemButton, ListItemText, Theme } from "@mui/material";
import { SxProps } from "@mui/system";

interface ResourceExplorerCreditTypeFilterContentItemProps {
  title: string;
  onClick(key?: string): void;
  itemKey?: string;
  checked: boolean;
  selected: boolean;
  sx?: SxProps<Theme>;
}
export const ResourceExplorerCreditTypeFilterContentItem: FC<
  ResourceExplorerCreditTypeFilterContentItemProps
> = ({ selected, title, onClick, itemKey, checked, sx }) => {
  const itemClickHandler = useCallback(() => {
    onClick(itemKey);
  }, [itemKey, onClick]);

  return (
    <ListItemButton
      sx={{ px: 1, pl: 5, ...sx }}
      onClick={itemClickHandler}
      selected={selected}
      dense
    >
      <Checkbox checked={checked} size="small" />
      <ListItemText
        sx={{
          textOverflow: "ellipsis",
          whiteSpace: "break-spaces",
          wordWrap: "break-word",
        }}
      >
        {title}
      </ListItemText>
    </ListItemButton>
  );
};
