import { FC, Fragment } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { TrendChip } from "../../../../../common/chips/TrendChip";
import { previousMonthSamePeriod } from "../../../utils/helpers/previousMonthSamePeriod";

interface AccountCardPeriodComparisonProps {
  lastMonthTrend?: number;
}

export const AccountCardPeriodComparison: FC<
  AccountCardPeriodComparisonProps
> = ({ lastMonthTrend }) => {
  return (
    <Stack direction="row" px={2} gap={1} alignItems="center">
      {lastMonthTrend ? (
        <Fragment>
          <Box py={1}>
            <TrendChip value={lastMonthTrend} />
          </Box>
          <Typography
            variant="subtitle1"
            fontSize={12}
            color="text.secondary"
            lineHeight={1.3}
          >
            Last month comparison ({previousMonthSamePeriod()})
          </Typography>
        </Fragment>
      ) : (
        <Typography
          fontSize={12}
          variant="subtitle1"
          color="text.secondary"
          py={1.5}
        >
          No insights yet
        </Typography>
      )}
    </Stack>
  );
};
