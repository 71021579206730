import { currentAccountVisibilitiesSelector } from "./currentAccountVisibilitiesSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";

export const currentAccountVisibilitiesByResourceTypeSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const accountVisibilities = currentAccountVisibilitiesSelector(state);
  return accountVisibilities?.[resourceType];
};
