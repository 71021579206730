import { CellContext, Row } from "@tanstack/react-table";
import { AccountLight } from "../../../../../../../../services/cloudchipr.api";
import { AccountInfoData } from "../../../../../../../common/AccountInfoData";
import { ResourceDataType } from "../../../../../../../../store/account/utils/types/types";

export const previewDataGridAccountColumn = {
  id: "account",
  accessorKey: "id",
  header: "Account",
  minSize: 250,
  filterFn: (row: Row<any>, columnId: string, keyword: string) => {
    const account = row.original?.resource?.account;

    const aim =
      `${account?.provider_account_id} ${account?.provider_account_name} ${account.provider}`
        .toLowerCase()
        .trim();

    return aim.includes(keyword?.toLowerCase().trim());
  },
  cell: (cell: CellContext<ResourceDataType, any>) => {
    const account = cell.row.original?.resource?.account as AccountLight;

    return (
      <AccountInfoData
        fontSize={14}
        provider={account.provider}
        accessType={account.provider_access_type}
        accountName={
          account.provider_account_name ?? account.provider_account_id
        }
        link={`/${account.provider}/${account.id}`}
      />
    );
  },
};
