import { createAsyncThunk } from "@reduxjs/toolkit";
import { protectThunk } from "./protectThunk";
import { ResourceType } from "../../../services/cloudchipr.api";
import { resetSelectedResources } from "../../account/accountSlice";
import { RootState } from "../../store";
import { currentAccountSelectedRowsIdsByResourceTypeSelector } from "../../account/selectors/current-account/resources/selection/currentAccountSelectedRowsIdsByResourceTypeSelector";
import { getCurrentAccountResourceTypeDataThunk } from "../../account/thunks/filters-get/getCurrentAccountResourceTypeDataThunk";

interface CreateProtectThunkArgs {
  resourceType: ResourceType;
  actionType: "protect" | "unprotect";
  callBack?(): void;
}
export const createProtectThunk = createAsyncThunk(
  "protectResource",
  async (
    { resourceType, actionType, callBack }: CreateProtectThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;

    const selectedResources =
      currentAccountSelectedRowsIdsByResourceTypeSelector(
        state,
        resourceType,
      ) ?? [];

    await dispatch(
      protectThunk({
        resources: selectedResources ? Object.keys(selectedResources) : [],
        actionType,
        resourceType: resourceType,
      }),
    );

    dispatch(getCurrentAccountResourceTypeDataThunk(resourceType));
    if (callBack) {
      callBack();
    }
    dispatch(resetSelectedResources(resourceType));
  },
);
