import { FC } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { WidgetTotalsSummary } from "../../common/WidgetTotalsSummary";
import { DateLabelNullable } from "../../../../../../../services/cloudchipr.api";
import { costBreakdownV2WidgetCostsSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetCostsSelector";
import { costBreakdownV2WidgetFrequencySelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetFrequencySelector";
import { costBreakdownV2WidgetForecastOptionSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetForecastOptionSelector";

interface CostBreakdownWidgetContentCostsProps {
  widgetId: string;
  dateLabel?: DateLabelNullable;
}

export const CostBreakdownV2WidgetContentCosts: FC<
  CostBreakdownWidgetContentCostsProps
> = ({ widgetId, dateLabel }) => {
  const costsData = useAppSelector((state) =>
    costBreakdownV2WidgetCostsSelector(state, widgetId),
  );

  const frequency = useAppSelector((state) =>
    costBreakdownV2WidgetFrequencySelector(state, widgetId),
  );

  const forecastOption = useAppSelector((state) =>
    costBreakdownV2WidgetForecastOptionSelector(state, widgetId),
  );

  if (!costsData || !frequency || !forecastOption) {
    return null;
  }

  return (
    <WidgetTotalsSummary
      frequency={frequency}
      costsData={costsData}
      forecastOption={forecastOption}
      dateLabel={dateLabel}
    />
  );
};
