import { Color } from "@mui/material";
import { blue, purple } from "@mui/material/colors";
import { MetricType } from "./types";

export const colorsByMetricType = new Map<MetricType, Color>([
  ["compute", blue],
  ["storage", blue],
  ["database", blue],
  ["memory_used", purple],
  ["memory_available", purple],
]);
