import { categoryByIdSelector } from "./categoryByIdSelector";
import { RootState } from "../../store";
import { EmbeddedBillingAccount } from "../../../services/cloudchipr.api";

export const categoryAccountsByCategoryIdSelector = (
  state: RootState,
  categoryId: string,
): EmbeddedBillingAccount[] | undefined => {
  return categoryByIdSelector({ categoryId })(state)?.data?.accounts ?? [];
};
