import { costBreakdownV2WidgetByWidgetIdSelector } from "./costBreakdownV2WidgetByWidgetIdSelector";
import { RootState } from "../../../../../store";

export const costBreakdownV2WidgetDataByWidgetIdSelector = (
  state: RootState,
  widgetId: string,
) => {
  const response = costBreakdownV2WidgetByWidgetIdSelector({ widgetId })(state);

  return response?.data;
};
