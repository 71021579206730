import { FC, useCallback, useMemo } from "react";
import { PopoverProps, Skeleton } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DropdownHeader } from "./DropdownHeader";
import { SelectedAccountNavigationTriggerComponent } from "./SelectedAccountNavigationTriggerComponent";
import { AccountOptionLabel } from "../../../../../../common/account-select/components/AccountOptionLabel";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { currentAccountIdSelector } from "../../../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import { currentAccountProviderTypeSelector } from "../../../../../../../store/account/selectors/current-account/currentAccountProviderTypeSelector";
import { SIDEBAR_WIDTH } from "../../../../../../../utils/constants/style-sizes";
import { allAccountsSelectOptionsSelector } from "../../../../../../../store/accounts/selectors/all-providers/allAccountsSelectOptionsSelector";
import { isStringResourceType } from "../../../../../../../utils/helpers/resources/isStringResourceType";
import { DropdownSelect } from "../../../../../../common/select/dropdown-select/DropdownSelect";
import { accountOptionFilterFn } from "../../../../../../common/account-select/utils/helpers/accountOptionFilterFn";
import { resourceTypesByProvider } from "../../../../../../../utils/helpers/providers/resourceTypesByProvider";
import { resourceTypeSearchParams } from "../../utils/constants";
import { resetResourcesConfigsPerAccountThunk } from "../../../../../../../store/account/thunks/resetResourcesConfigsPerAccountThunk";

export const SelectedAccountNavigation: FC = () => {
  const [searchParams] = useSearchParams();
  const activeResourceType = searchParams.get(resourceTypeSearchParams);
  const currentAccountId = useAppSelector(currentAccountIdSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const groupedAllAccounts = useAppSelector(allAccountsSelectOptionsSelector);
  const currentAccountProvider = useAppSelector(
    currentAccountProviderTypeSelector,
  );

  const options = useMemo(() => {
    return groupedAllAccounts.map((option) => {
      let listItemHeight = 60;

      if (
        option.providerAccountId &&
        !option.label.includes(option.providerAccountId)
      ) {
        listItemHeight += 20;
      }

      return {
        value: option.value,
        disabled: option.disabled || option.status !== "connected",
        rawValue: option,
        listItemHeight,
        groupName: option.orgId,
        label: (
          <AccountOptionLabel
            option={{ ...option, orgId: undefined }}
            showAccountId
            showStatus={option.status === "deactivated"}
          />
        ),
      };
    });
  }, [groupedAllAccounts]);

  const accountChangeHandler = useCallback(
    ([accountId]: string[]) => {
      const account = groupedAllAccounts.find(
        (account) => account.value === accountId,
      );

      const providerType = account?.accountType;

      let resourceType = activeResourceType ?? "";

      if (!providerType) {
        return;
      }

      if (
        currentAccountProvider !== providerType ||
        !isStringResourceType(resourceType)
      ) {
        resourceType = resourceTypesByProvider(providerType).at(0) ?? "";
      }
      dispatch(resetResourcesConfigsPerAccountThunk());
      navigate(
        `/${providerType}/${accountId}?${resourceTypeSearchParams}=${resourceType}`,
      );
    },
    [
      navigate,
      groupedAllAccounts,
      activeResourceType,
      currentAccountProvider,
      dispatch,
    ],
  );

  if (!currentAccountId || !currentAccountProvider) {
    return <Skeleton variant="text" height={100} width={SIDEBAR_WIDTH - 20} />;
  }

  return (
    <DropdownSelect
      singleSelect
      label="Accounts"
      listWidth={400}
      options={options}
      filterFn={accountOptionFilterFn}
      PopoverProps={popoverProps}
      submitHandlerOnClose={accountChangeHandler}
      DropdownHeader={DropdownHeader}
      initialSelectedValues={[currentAccountId ?? ""]}
      TriggerComponent={SelectedAccountNavigationTriggerComponent}
    />
  );
};

const popoverProps = {
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
} as PopoverProps;
