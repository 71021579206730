import { RootState } from "../../../../store";
import { generateScheduleResourcesProtectionFixedCacheKey } from "../../../../../components/pages/schedule/common/utils/helpers/fixedCacheKeys";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";

export const previewResourcesProtectionLoadingSelector = (
  state: RootState,
  accountId: string,
) => {
  const key = generateScheduleResourcesProtectionFixedCacheKey(accountId);

  return !!cloudChiprApi.endpoints.patchUsersMeAccountsByAccountIdResources.select(
    key,
  )(state)?.isLoading;
};
