import { FC, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { FilterDropdownTrigger } from "../../../FilterDropdownTrigger";
import { getResourcesExplorerGroupingLabel } from "../../../../../resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { resourceExplorerPossibleFiltersByProviderSelector } from "../../../../../../../../../store/resource-explorer/selectors/filters/possible-filters/resourceExplorerPossibleFiltersByProviderSelector";
import { resourceExplorerFilterByKeyAndProviderSelector } from "../../../../../../../../../store/resource-explorer/selectors/filters/resourceExplorerFilterByKeyAndProviderSelector";
import {
  ResourceExplorerFilterItem,
  ProviderType,
} from "../../../../../../../../../services/cloudchipr.api";
import { isDeepEqual } from "../../../../../../../../../utils/is-deep-equal";
import { DropdownSelect } from "../../../../../../../../common/select/dropdown-select/DropdownSelect";
import { instanceTypeFilterChangeThunk } from "../../../../../../../../../store/resource-explorer/thunks/filters/instanceTypeFilterChangeThunk";
import { dbSelectedInstanceTypeSelector } from "../../../../../../../../../store/resource-explorer/selectors/filters/selected/dbSelectedInstanceTypeSelector";

interface ResourceExplorerInstanceTypeFilterProps {
  viewId: string;
  provider: ProviderType;
}
export const ResourceExplorerInstanceTypeFilter: FC<
  ResourceExplorerInstanceTypeFilterProps
> = ({ viewId, provider }) => {
  const dispatch = useDispatch();
  const filter = useAppSelector((state) =>
    resourceExplorerFilterByKeyAndProviderSelector(
      state,
      "instance_type",
      provider,
    ),
  );

  const possibleFilters = useAppSelector((state) =>
    resourceExplorerPossibleFiltersByProviderSelector(state, provider),
  )?.instance_type;

  const selectedFilter = useAppSelector((state) =>
    dbSelectedInstanceTypeSelector(state, provider),
  );

  const options = useMemo(() => {
    return (
      possibleFilters?.values?.map((option) => {
        return {
          value: option.value,
          label: option.title || option.value,
        };
      }) ?? []
    );
  }, [possibleFilters]);

  const submitHandlerOnClose = useCallback(
    (value: string[]) => {
      const newFilter: ResourceExplorerFilterItem = {
        ...filter,
        value,
        key: "instance_type",
        operator: "in",
        cloud_provider: provider,
        include_values: filter?.include_values ?? null,
      };

      const isEqual = filter && isDeepEqual(newFilter, filter);

      if (!isEqual) {
        dispatch(
          instanceTypeFilterChangeThunk({ filter: newFilter, provider }),
        );
      }
    },
    [dispatch, filter, provider],
  );

  const label =
    getResourcesExplorerGroupingLabel([provider], "instance_type") ?? "";

  const TriggerComponent = useCallback(
    (props: any) => (
      <FilterDropdownTrigger
        {...props}
        viewId={viewId}
        provider={provider}
        filterKey="instance_type"
        title={label}
      />
    ),
    [provider, viewId, label],
  );

  return (
    <DropdownSelect
      label="Instance Type"
      options={options}
      initialSelectedValues={(selectedFilter.value ?? []) as string[]}
      submitHandlerOnClose={submitHandlerOnClose}
      TriggerComponent={TriggerComponent}
      values={(selectedFilter.value ?? []) as string[]}
    />
  );
};
