import { costAllocationTagValuesSelector } from "./costAllocationTagValuesSelector";
import { RootState } from "../../../../store";
import { ProviderType } from "../../../../../services/cloudchipr.api";

export const costAllocationTagValuesDataSelector = (
  state: RootState,
  provider: ProviderType,
  tagKey?: string,
): string[] | undefined => {
  if (!tagKey) {
    return;
  }

  return costAllocationTagValuesSelector(state, provider, tagKey)?.data;
};
