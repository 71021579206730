import { FC, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { FilterDropdownTrigger } from "../../../FilterDropdownTrigger";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import {
  ResourceExplorerFilterItem,
  ProviderType,
} from "../../../../../../../../../services/cloudchipr.api";
import { resourceExplorerFilterByKeyAndProviderSelector } from "../../../../../../../../../store/resource-explorer/selectors/filters/resourceExplorerFilterByKeyAndProviderSelector";
import { resourceExplorerPossibleFiltersByProviderSelector } from "../../../../../../../../../store/resource-explorer/selectors/filters/possible-filters/resourceExplorerPossibleFiltersByProviderSelector";
import { isDeepEqual } from "../../../../../../../../../utils/is-deep-equal";
import { dbSelectedProductFamilySelector } from "../../../../../../../../../store/resource-explorer/selectors/filters/selected/dbSelectedProductFamilySelector";
import { productFamilyFilterChangeThunk } from "../../../../../../../../../store/resource-explorer/thunks/filters/productFamilyFilterChangeThunk";
import { getResourcesExplorerGroupingLabel } from "../../../../../resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { DropdownSelect } from "../../../../../../../../common/select/dropdown-select/DropdownSelect";

interface ResourceExplorerProductFamilyFilterProps {
  viewId: string;
  provider: ProviderType;
}
export const ResourceExplorerProductFamilyFilter: FC<
  ResourceExplorerProductFamilyFilterProps
> = ({ viewId, provider }) => {
  const dispatch = useDispatch();
  const filter = useAppSelector((state) =>
    resourceExplorerFilterByKeyAndProviderSelector(
      state,
      "product_family",
      provider,
    ),
  );

  const possibleFilters = useAppSelector((state) =>
    resourceExplorerPossibleFiltersByProviderSelector(state, provider),
  )?.product_family;

  const selectedFilter = useAppSelector((state) =>
    dbSelectedProductFamilySelector(state, provider),
  );

  const submitHandlerOnClose = useCallback(
    (value: string[]) => {
      const newFilter: ResourceExplorerFilterItem = {
        ...filter,
        value,
        key: "product_family",
        operator: "in",
        cloud_provider: provider,
        include_values: filter?.include_values ?? null,
      };

      const isEqual = filter && isDeepEqual(newFilter, filter);

      if (!isEqual) {
        dispatch(
          productFamilyFilterChangeThunk({
            filter: newFilter,
            provider,
          }),
        );
      }
    },
    [dispatch, filter, provider],
  );

  const options = useMemo(() => {
    return (
      possibleFilters?.values?.map((option) => {
        return {
          value: option.value,
          label: option.title || option.value,
        };
      }) ?? []
    );
  }, [possibleFilters]);

  const label =
    getResourcesExplorerGroupingLabel([provider], "product_family") ?? "";

  const TriggerComponent = useCallback(
    (props: any) => (
      <FilterDropdownTrigger
        {...props}
        viewId={viewId}
        provider={provider}
        filterKey="product_family"
        title={label}
      />
    ),
    [label, provider, viewId],
  );

  return (
    <DropdownSelect
      label={label}
      options={options}
      initialSelectedValues={(selectedFilter.value ?? []) as string[]}
      submitHandlerOnClose={submitHandlerOnClose}
      TriggerComponent={TriggerComponent}
      values={(selectedFilter.value ?? []) as string[]}
    />
  );
};
