import { firstActiveAccountIdByProviderSelector } from "./firstActiveAccountIdByProviderSelector";
import { RootState } from "../../store";
import { ProviderType } from "../../../services/cloudchipr.api";
import { currentAccountFilterTemplatesByAccountIdSelector } from "../../filters/selectors/filter-set/applied-by-account-id/currentAccountFilterTemplatesByAccountIdSelector";

interface AccountFilterTemplateNameSelectorArgs {
  accountId?: string;
  provider: ProviderType;
  filterSetId: string | null;
}
export const accountFilterTemplateNameSelector = (
  state: RootState,
  { accountId, provider, filterSetId }: AccountFilterTemplateNameSelectorArgs,
): string | undefined => {
  if (!accountId || !filterSetId) {
    return;
  }

  let filterSetsList = currentAccountFilterTemplatesByAccountIdSelector(
    state,
    accountId,
  );

  const firstAccountId = firstActiveAccountIdByProviderSelector(
    state,
    provider,
  );

  if (!filterSetsList) {
    filterSetsList = currentAccountFilterTemplatesByAccountIdSelector(
      state,
      firstAccountId ?? "",
    );
  }

  return (
    filterSetsList?.find(({ filter_set }) => filter_set?.id === filterSetId)
      ?.filter_set?.name ?? ""
  );
};
