import { Fragment } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { Row } from "@tanstack/react-table";
import { RowSelectionOptions } from "@tanstack/table-core";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { ToolbarActions } from "./actions/ToolbarActions";
import { CsvDataDownloader } from "./CsvDataDownloader";
import { CreateWorkflowToolbarAction } from "./actions/CreateWorkflowToolbarAction";
import { ResourceCardFilterContent } from "./ResourceCardFilterContent";
import { generateMetricsFrequenciesFromContextData } from "../utils/csv-data/generateMetricsFrequenciesFromContextData";
import { ColumnsSwitcher } from "../../../../../../../../../../storybook/data-grid/toolbar/ColumnsSwitcher";
import { useMenuHook } from "../../../../../../../../../../utils/hooks/useMenu.hook";
import { GlobalFilter } from "../../../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { WithResourceType } from "../../../../../../../utils/types";
import { ResourceCardStatistics } from "../../../resource-card-statistics/ResourceCardStatistics";
import { RenderToolbar } from "../../../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { getActionableResourceTypeByParentResourceType } from "../../../../../../../../../../utils/helpers/resources/getActionableResourceTypeByParentResourceType";
import { useDataGridContext } from "../../../../../../../../../../storybook/data-grid/DataGridProvider";

interface ResourceCardToolbarProps extends RenderToolbar<WithResourceType> {
  rowSelectingConfigFns?: RowSelectionOptions<any> & {
    getSelectedRowsData: (data: Row<any>[]) => void;
  };
}

export const ResourceCardToolbar: ResourceCardToolbarProps = ({
  table,
  setGlobalFilter,
  globalFilter,
  resourceType,
}) => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();
  const context = useDataGridContext();
  const data = context?.cellSpecificMetadata?.data;
  const metricsFrequencies = generateMetricsFrequenciesFromContextData(data);

  return (
    <Fragment>
      <Stack
        p={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <ResourceCardStatistics resourceType={resourceType} />

        <Stack direction="row" spacing={2}>
          <CreateWorkflowToolbarAction
            resourceType={getActionableResourceTypeByParentResourceType(
              resourceType,
            )}
          />
          <ToolbarActions
            resourceType={getActionableResourceTypeByParentResourceType(
              resourceType,
            )}
          />
        </Stack>
      </Stack>

      <Stack
        px={2}
        pb={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={0.5}>
          <ResourceCardFilterContent resourceType={resourceType} />
        </Stack>

        <Stack alignItems="center" direction="row" spacing={2}>
          <GlobalFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            size="xsmall"
          />

          <CsvDataDownloader
            resourceType={resourceType}
            metricsFrequencies={metricsFrequencies}
          />

          <Tooltip arrow title="Table Settings" placement="top">
            <IconButton onClick={openMenu} size="small">
              <SettingsOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>

          <ColumnsSwitcher
            open={open}
            onClose={closeMenu}
            anchor={anchor}
            table={table}
          />
        </Stack>
      </Stack>
    </Fragment>
  );
};
