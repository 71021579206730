import moment from "moment";
import { RootState } from "../../../../store";
import { resourceExplorerGroupingSelector } from "../data/resourceExplorerGroupingSelector";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";
import { capitalizeString } from "../../../../../utils/helpers/capitalizeString";
import { getResourcesExplorerGroupingLabel } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { resourceExplorerProvidersSelector } from "../resourceExplorerProvidersSelector";

export const resourceExplorerCsvFileNameSelector = (state: RootState) => {
  const grouping = resourceExplorerGroupingSelector(state);
  const providers = resourceExplorerProvidersSelector(state);
  const label = getResourcesExplorerGroupingLabel(providers, grouping);
  const formattedLabel = label.replaceAll(" ", "_");
  const date = formatDate(moment(), {
    type: "dateTime",
  })
    .replaceAll(" ", "_")
    .replace(":", "_")
    .replace("PM", "pm")
    .replace("AM", "am");

  return `${capitalizeString(formattedLabel ?? "")}_ResourceExplorer_${date}`;
};
