import { FC } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { DateLabel } from "../../../../../../../../../../services/cloudchipr.api";
import { generateDateRangeString } from "../../../../../../../../../common/date-range-picker/utils/helpers/generateDateRangeString";
import { PreDefinedRange } from "../../../../../../../../../common/date-range-picker/utils/constants/types";

interface MoreDateRangeProps {
  dateLabel?: DateLabel | null;
  options?: (PreDefinedRange | undefined)[];
  open: boolean;
}
export const MoreDateRange: FC<MoreDateRangeProps> = ({
  dateLabel,
  options,
  open,
}) => {
  const selectedDate = options?.find((item) => item?.dateLabel === dateLabel);

  return (
    <Tooltip
      arrow
      placement="top"
      title={selectedDate ? generateDateRangeString(selectedDate) : ""}
    >
      <Stack direction="row">
        <Typography
          variant="body2"
          textTransform="capitalize"
          whiteSpace="nowrap"
        >
          {selectedDate?.shortLabel || "More"}
        </Typography>

        {!open ? (
          <ArrowDropDownIcon fontSize="small" />
        ) : (
          <ArrowDropUpIcon fontSize="small" />
        )}
      </Stack>
    </Tooltip>
  );
};
