import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCurrentAccountResourceTypeWithoutFiltersDataThunk } from "./getCurrentAccountResourceTypeWithoutFiltersDataThunk";
import { resourceTypesByProvider } from "../../../../utils/helpers/providers/resourceTypesByProvider";
import { currentAccountProviderTypeSelector } from "../../selectors/current-account/currentAccountProviderTypeSelector";
import { RootState } from "../../../store";

export const getCurrentAccountAllResourceTypesDataWithoutFilterThunk =
  createAsyncThunk(
    "getCurrentAccountAllResourceTypesDataWithoutFilter",
    async (_: void, { dispatch, getState }) => {
      const state = getState() as RootState;
      const provider = currentAccountProviderTypeSelector(state);

      if (!provider) {
        return;
      }

      const resourceTypes = resourceTypesByProvider(provider);
      resourceTypes?.forEach((resourceType) => {
        dispatch(
          getCurrentAccountResourceTypeWithoutFiltersDataThunk(resourceType),
        );
      });
    },
  );
