import { costBreakdownWidgetDataSelector } from "./costBreakdownWidgetDataSelector";
import { costBreakdownWidgetFrequencySelector } from "./costBreakdownWidgetFrequencySelector";
import { RootState } from "../../../../store";
import { ChartDataType } from "../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { formatResourceExplorerChartData } from "../../../../resource-explorer/utils/helpers/formatResourceExplorerChartData";

export const costBreakdownWidgetChartDataSelector = (
  state: RootState,
  widgetId?: string,
): ChartDataType[] | [] => {
  const data = costBreakdownWidgetDataSelector(state, widgetId)?.data;
  const frequency = costBreakdownWidgetFrequencySelector(state, widgetId);

  if (!data) {
    return [];
  }

  return formatResourceExplorerChartData(data, frequency);
};
