import { FC, memo } from "react";
import {
  FormControlLabel,
  Radio,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { ProviderLogo } from "../../../../../common/provider-logo/ProviderLogo";

interface ProviderProps {
  type: ProviderType;
  label: string;
  tooltipTitle: string;
  disabled: boolean;
  isActive: boolean;
}

export const CloudProvider: FC<ProviderProps> = memo(
  ({ type, tooltipTitle, disabled, label, isActive }) => {
    return (
      <Tooltip title={tooltipTitle} placement="right" arrow>
        <FormControlLabel
          disabled={disabled}
          value={type}
          control={<Radio checked={isActive} />}
          label={
            <Stack direction="row" spacing={1.5} alignItems="center" ml={0.5}>
              <ProviderLogo width={24} provider={type} />
              <Typography
                variant="body1"
                color={disabled ? "text.disabled" : "text.secondary"}
              >
                {label}
              </Typography>
            </Stack>
          }
        />
      </Tooltip>
    );
  },
);
