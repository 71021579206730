import { DropdownSelectOption } from "../../../../../common/select/dropdown-select/utils/types/types";

export const filterByDimensionDropdownFilterFn = (
  option: DropdownSelectOption,
  keyword: string,
) => {
  keyword = keyword.trim().toLowerCase();

  if (!option.rawValue) {
    return false;
  }

  const { value, title } = option.rawValue;

  const aim = `${value} ${title} `.toLowerCase();

  return aim.includes(keyword);
};
