import { RootState } from "../../../../../store";
import { ResourceType } from "../../../../../../services/cloudchipr.api";
import { ResourceDataType } from "../../../../utils/types/types";
import { getFirstParentInHierarchyResourceType } from "../../../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { currentAccountSelectedRowIdsWithHierarchyByResourceTypeSelector } from "../../currentAccountSelectedRowIdsWithHierarchyByResourceTypeSelector";
import { resourceTypeDataWithChildrenSelector } from "../../../resource-type-data/resourceTypeDataWithChildrenSelector";

export const currentAccountSelectedResourcesByResourceTypeSelector = (
  state: RootState,
  resourceType: ResourceType,
): ResourceDataType[] | undefined => {
  const parentResourceType =
    getFirstParentInHierarchyResourceType(resourceType);

  const selectedResources =
    currentAccountSelectedRowIdsWithHierarchyByResourceTypeSelector(
      state,
      resourceType,
    );

  const selectedResourceIds = selectedResources
    ? Object.keys(selectedResources)
    : [];
  const allResources = resourceTypeDataWithChildrenSelector(
    state,
    parentResourceType ?? resourceType,
  );

  return allResources?.filter(({ resource }) =>
    selectedResourceIds?.includes(resource?.id),
  );
};
