import { FilterKeyType } from "../../../../../../../../services/cloudchipr.api";

export const liveUsageExcludedFilters: FilterKeyType[] = [
  "provider_unique_identifier",
];

export const betaResourceTypes = new Set([
  "ecs",
  "ebs_snapshot",
  "rds_snapshot_source",
]);

export const commonExcludedFilters: FilterKeyType[] = [
  "resource_unique_identifier",
];
