import moment from "moment/moment";
import { DefinedRange } from "mui-daterange-picker";
import { DateLabelNullable } from "../../../../../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../../../../../utils/helpers/date-time/datetime-format";
import { labelByDateLabel } from "../../../../../../../../../common/date-range-picker/utils/constants/preDefinedRanges";
import { generateDateFromDateLabelV1 } from "../../../../../../../../../common/date-range-picker/utils/helpers/generateDateFromDateLabelV1";

export const generateLargesCostChangesDateInfo = (
  date: {
    from?: string | null;
    to?: string | null;
    label?: DateLabelNullable;
  },
  quarterStartMonth?: number,
  labelType: "long" | "short" | "withoutPrefix" = "long",
) => {
  const datesFromLabel: DefinedRange = generateDateFromDateLabelV1(
    date?.label ?? null,
    quarterStartMonth,
  );

  let dateTo = date?.to ?? datesFromLabel?.endDate;
  let dateFrom = date?.from ?? datesFromLabel?.startDate;

  dateTo = formatDate(moment(dateTo), {
    type: "date",
  });
  dateFrom = formatDate(moment(dateFrom), {
    type: "date",
  });

  const dateLabel =
    date?.label && labelByDateLabel.get(date?.label)?.[labelType];

  return {
    label: date?.label ? dateLabel : `${dateFrom} - ${dateTo}`,
    dateRange: date?.label ? `${dateFrom} - ${dateTo}` : "",
  };
};
