import { FC } from "react";
import { Button, Theme, Stack } from "@mui/material";
import { Table } from "@tanstack/react-table";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { SxProps } from "@mui/system";
import { ColumnsSwitcher } from "./ColumnsSwitcher";
import { GlobalFilter } from "./GlobalFilter";
import { useMenuHook } from "../../../utils/hooks/useMenu.hook";
interface TableToolbarProps {
  styles?: SxProps<Theme>;
  table: Table<any>;
  globalFilter: string | number;
  setGlobalFilter: (value: string) => void;
  globalFilterEnabled: boolean;
  columnShowHideEnabled: boolean;
}

export const TableToolbar: FC<TableToolbarProps> = ({
  globalFilter,
  setGlobalFilter,
  table,
  columnShowHideEnabled,
  globalFilterEnabled,
  styles,
}) => {
  const { open, openMenu, closeMenu, anchor } = useMenuHook();

  if (!globalFilterEnabled && !columnShowHideEnabled) {
    return null;
  }

  return (
    <Stack direction="row" justifyContent="space-between" sx={styles}>
      {globalFilterEnabled && (
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}

      {columnShowHideEnabled && (
        <Button
          variant="outlined"
          size="small"
          startIcon={<SettingsOutlinedIcon />}
          onClick={openMenu}
        >
          Table Settings
        </Button>
      )}

      <ColumnsSwitcher
        open={open}
        onClose={closeMenu}
        anchor={anchor}
        table={table}
      />
    </Stack>
  );
};
