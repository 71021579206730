import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { Dates } from "../../../../../../../../../../services/cloudchipr.api";
import { allDateRangesByQuarterStartMonthSelector } from "../../../../../../../../../../store/common/selectors/date-ranges/allDateRangesByQuarterStartMonthSelector";
import { DateRangePicker } from "../../../../../../../../../common/date-range-picker-v2/DateRangePicker";

interface WidgetSetupDateRangeSelectV2Props {
  dates: Dates;
  onDateChange(dates: Dates): void;
}
export const WidgetSetupDateRangeSelectV2: FC<
  WidgetSetupDateRangeSelectV2Props
> = ({ dates, onDateChange }) => {
  const dateRanges = useAppSelector(allDateRangesByQuarterStartMonthSelector);
  const orgQuarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  if (!dates) {
    return null;
  }

  return (
    <DateRangePicker
      highlightToday
      ranges={dateRanges}
      selectedDates={dates}
      onDateSelect={onDateChange}
      quarterStartMonth={orgQuarterStartMonth}
      fullWidth
    />
  );
};
