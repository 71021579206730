import { FC, useCallback, useMemo } from "react";
import { ProviderFilterOption } from "./ProviderFilterOption";
import { RightsizingRecommendationsFilterTrigger } from "../RightsizingRecommendationsFilterTrigger";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { recommendationsAvailableProvidersSelector } from "../../../../../../../../store/recommendations/selectors/filters/recommendationsAvailableProvidersSelector";
import { ProviderType } from "../../../../../../../../services/cloudchipr.api";
import { DropdownSelect } from "../../../../../../../common/select/dropdown-select/DropdownSelect";
import { FilterTriggerComponentProps } from "../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { setRecommendationsProviderFilter } from "../../../../../../../../store/recommendations/recommendationsSlice";

export const RightsizingProviderFilter: FC = () => {
  const dispatch = useAppDispatch();
  const providers = useAppSelector(recommendationsAvailableProvidersSelector);

  const options = useMemo(() => {
    return providers.map((provider) => ({
      value: provider,
      hideOnly: true,
      label: <ProviderFilterOption provider={provider} />,
    }));
  }, [providers]);

  const TriggerComponent = useCallback((props: FilterTriggerComponentProps) => {
    return (
      <RightsizingRecommendationsFilterTrigger
        {...props}
        tooltip="Recommendations are not enabled on some of the cloud providers."
      />
    );
  }, []);

  const changeHandler = useCallback(
    (providers: ProviderType[]) => {
      dispatch(setRecommendationsProviderFilter(providers));
    },
    [dispatch],
  );

  return (
    <DropdownSelect
      label="Cloud Providers"
      options={options}
      submitHandlerOnClose={changeHandler}
      TriggerComponent={TriggerComponent}
    />
  );
};
