import { Button } from "@mui/material";
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from "react";
import { Add } from "@mui/icons-material";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { resourceExplorerPossibleFiltersByProviderSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/filters/possible-filters/resourceExplorerPossibleFiltersByProviderSelector";
import {
  ResourceExplorerFilterItem,
  ResourceExplorerTag,
  ProviderType,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { costAllocationTagLabel } from "../utils/constants";
import { getValidatedCostAllocationTags } from "../utils/helpers";

interface ResourceExplorerCostAllocationTagAddButtonProps {
  filter: ResourceExplorerFilterItem | null;
  setFilter: Dispatch<SetStateAction<ResourceExplorerFilterItem | null>>;
  provider: ProviderType;
}
export const ResourceExplorerCostAllocationTagAddButton: FC<
  ResourceExplorerCostAllocationTagAddButtonProps
> = ({ provider, filter, setFilter }) => {
  const possibleTags = useAppSelector((state) =>
    resourceExplorerPossibleFiltersByProviderSelector(state, provider),
  )?.cost_allocation_tag;

  const addNewCostAllocationTagClickHandler = useCallback(() => {
    setFilter((filter) => {
      let filtersNewRef = filter ? { ...filter } : null;
      const emptyTag: ResourceExplorerTag = {
        tag_key: "",
        tag_values: [],
        operator: "in",
      };

      if (!filtersNewRef?.value) {
        filtersNewRef = {
          cloud_provider: provider,
          key: "cost_allocation_tag",
          value: [emptyTag],
          operator: "in",
          include_values: null,
          combination_operator: filter?.combination_operator,
        };
      } else {
        filtersNewRef = {
          cloud_provider: provider,
          key: "cost_allocation_tag",
          value: [...filtersNewRef.value, emptyTag],
          operator: filtersNewRef.operator,
          include_values: null,
          combination_operator: filter?.combination_operator,
        };
      }
      return filtersNewRef;
    });
  }, [setFilter, provider]);

  const disabled = useMemo(() => {
    if (!possibleTags?.values.length) {
      return true;
    }

    if (
      filter?.value?.length !==
      getValidatedCostAllocationTags(filter?.value as ResourceExplorerTag[])
        ?.length
    ) {
      return true;
    }
    return filter?.value?.length === possibleTags?.values.length;
  }, [filter, possibleTags]);

  return (
    <Button
      variant="text"
      onClick={addNewCostAllocationTagClickHandler}
      disabled={disabled}
      startIcon={
        <Add
          sx={{
            color: !disabled ? "primary.main" : "text.disabled",
          }}
        />
      }
    >
      Add {costAllocationTagLabel?.[provider]?.short}
    </Button>
  );
};
