import { FC, Fragment, useEffect } from "react";
import { Card, Box, LinearProgress } from "@mui/material";
import { useDidMount, useEffectOnceWhen, useIntervalWhen } from "rooks";
import TabContext from "@mui/lab/TabContext";
import { useLocation, useNavigate } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AccountsGridView } from "./accounts-grid/AccountsGridView";
import { AccountsEmptyTemplate } from "./AccountsEmptyTemplate";
import { ProviderAccountCashs } from "./ProviderAccountCashs";
import { AccountsTabs } from "./AccountsTabs";
import { AccountsToolbar } from "./toolbar/AccountsToolbar";
import { AccountsListView } from "./accounts-list/AccountsListView";
import { AccountSetupDialog } from "../../../common/connectors/AccountSetupDialog";
import { Account, ProviderType } from "../../../../../services/cloudchipr.api";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { accountsFilterValueSelector } from "../../../../../store/accounts/selectors/slice-data/accountsFilterValueSelector";
import { accountsViewSelector } from "../../../../../store/accounts/selectors/slice-data/accountsViewSelector";
import { getAccountsThunk } from "../../../../../store/accounts/thunks/getAccountsThunk";
import { useAccountsDnD } from "../../../account/utils/hooks/useAccountsDnD";
import {
  accountTabs,
  defaultAccountTab,
} from "../../utils/constants/constants";
import { AccountTabs } from "../../utils/types/types";
import { accountsNeededInfoLoadingSelector } from "../../../../../store/accounts/selectors/loadings/accountsNeededInfoLoadingSelector";
import { getFilterTemplatesByAccountIdThunk } from "../../../../../store/filters/thunks/filter-set/getFilterTemplatesByAccountIdThunk";
import { firstActiveAccountIdByProviderSelector } from "../../../../../store/accounts/selectors/firstActiveAccountIdByProviderSelector";

interface AccountsPageBodyProps {
  accounts: Record<AccountTabs, Account[]>;
  provider: ProviderType;
  accountsType: string | null;
  orgId: string;
}

export const AccountsPageBody: FC<AccountsPageBodyProps> = ({
  accounts,
  provider,
  accountsType,
  orgId,
}) => {
  const tabValue = (accountsType || defaultAccountTab) as AccountTabs;

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const filterValue = useAppSelector(accountsFilterValueSelector);
  const accountsView = useAppSelector(accountsViewSelector);
  const loading = useAppSelector((state) =>
    accountsNeededInfoLoadingSelector(state, provider, tabValue, orgId),
  );

  const firstAccountId = useAppSelector((state) =>
    firstActiveAccountIdByProviderSelector(state, provider),
  );

  const { sortedData, onSortingChange } = useAccountsDnD(
    provider,
    accounts[tabValue],
    tabValue,
    orgId,
  );

  useDidMount(() => {
    dispatch(getAccountsThunk());
  });

  useEffectOnceWhen(() => {
    dispatch(
      getFilterTemplatesByAccountIdThunk({ accountId: firstAccountId ?? "" }),
    );
  }, !!firstAccountId);

  useIntervalWhen(
    () => {
      // TODO: Instead of using interval, we should use polling mechanism from RTK
      dispatch(getAccountsThunk());
    },
    60_000,
    true,
  );

  useEffect(() => {
    if (
      !accountsType ||
      !accountTabs.find((item) => item.value === accountsType)
    ) {
      navigate(`${location.pathname}?type=${defaultAccountTab}`, {
        replace: true,
      });
    }
  }, [accountsType, navigate, location.pathname]);

  useEffectOnceWhen(
    () => {
      navigate(`${location.pathname}?type=${defaultAccountTab}`, {
        replace: true,
      });
    },
    accountsType === "inactive" && !accounts.inactive?.length,
  );

  if (loading) {
    return <LinearProgress />;
  }

  if (!accounts) {
    return null;
  }

  const accountsByView = {
    grid: (
      <AccountsGridView
        accounts={sortedData}
        provider={provider}
        onSortingChange={onSortingChange}
      />
    ),
    list: (
      <AccountsListView
        accounts={sortedData}
        onSortingChange={onSortingChange}
        accountsType={tabValue}
      />
    ),
    bar: (
      <AccountsListView
        withBar
        accounts={sortedData}
        onSortingChange={onSortingChange}
        accountsType={tabValue}
      />
    ),
  };

  const activeCount = accounts.active?.length ?? 0;
  const inactiveCount = accounts.inactive?.length ?? 0;
  const accountsExist = !!(inactiveCount || activeCount);
  const showContent = !!(accounts[tabValue]?.length || filterValue);

  return (
    <Fragment>
      <TabContext value={tabValue}>
        {accountsExist && (
          <AccountsTabs
            activeCount={activeCount}
            inactiveCount={inactiveCount}
            provider={provider}
          />
        )}

        {tabValue === "active" && showContent && (
          <Box px={2} pt={3}>
            <ProviderAccountCashs organizationId={orgId} provider={provider} />
          </Box>
        )}

        <Box py={3} px={2}>
          {showContent ? (
            <Fragment>
              <Card
                variant="outlined"
                sx={{
                  p: 2,
                  borderBottom: accountsView === "list" ? "none" : "",
                  borderRadius: accountsView === "list" ? "8px 8px 0 0" : "",
                }}
              >
                <AccountsToolbar provider={provider} tabValue={tabValue} />
              </Card>

              <DndProvider backend={HTML5Backend} context={window}>
                {!!sortedData && accountsView && accountsByView[accountsView]}
              </DndProvider>
            </Fragment>
          ) : (
            <AccountsEmptyTemplate
              provider={provider}
              onlyInActive={!!accounts.inactive?.length}
            />
          )}

          <AccountSetupDialog />
        </Box>
      </TabContext>
    </Fragment>
  );
};
