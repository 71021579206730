import { resourceExplorerAppliedFiltersSelector } from "./resourceExplorerAppliedFiltersSelector";
import { RootState } from "../../../store";
import {
  ResourceExplorerFilterItem,
  ProviderType,
  ResourceExplorerFilterKeyType,
} from "../../../../services/cloudchipr.api";

export const resourceExplorerFilterByKeyAndProviderSelector = (
  state: RootState,
  key: ResourceExplorerFilterKeyType,
  provider: ProviderType,
): ResourceExplorerFilterItem | undefined => {
  const filters = resourceExplorerAppliedFiltersSelector(state);

  return filters?.[provider]?.[key];
};
