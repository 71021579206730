import { FC, Fragment, useCallback } from "react";
import { Popover, PopoverOrigin, Skeleton, Stack } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { AccountCardFilterTemplatePopoverContent } from "./AccountCardFilterTemplatePopoverContent";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import { ProviderType } from "../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { firstActiveAccountIdByProviderSelector } from "../../../../../../../store/accounts/selectors/firstActiveAccountIdByProviderSelector";
import { accountFilterTemplateNameSelector } from "../../../../../../../store/accounts/selectors/accountFilterTemplateNameSelector";
import { getFilterTemplatesByAccountIdThunk } from "../../../../../../../store/filters/thunks/filter-set/getFilterTemplatesByAccountIdThunk";
import { filterTemplatesLoadingByAccountIdSelector } from "../../../../../../../store/filters/selectors/filter-set/filterTemplatesLoadingByAccountIdSelector";

interface AccountCardFilterTemplateNameProps {
  accountId: string;
  provider: ProviderType;
  filterSetId: string | null;
}

export const AccountCardFilterTemplateName: FC<
  AccountCardFilterTemplateNameProps
> = ({ accountId, provider, filterSetId }) => {
  const dispatch = useAppDispatch();
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const firstAccountId = useAppSelector((state) =>
    firstActiveAccountIdByProviderSelector(state, provider),
  );

  const loading = useAppSelector((state) =>
    filterTemplatesLoadingByAccountIdSelector(state, firstAccountId ?? ""),
  );

  const name = useAppSelector((state) =>
    accountFilterTemplateNameSelector(state, {
      accountId: accountId,
      provider,
      filterSetId,
    }),
  );

  const filterClickHandler = useCallback(
    (event: any) => {
      openMenu(event);
      event.preventDefault();
      event.stopPropagation();
      dispatch(getFilterTemplatesByAccountIdThunk({ accountId }));
    },
    [openMenu, dispatch, accountId],
  );

  const closeHandler = useCallback(
    (event: any) => {
      closeMenu();
      event.preventDefault();
      event.stopPropagation();
    },
    [closeMenu],
  );

  return (
    <Fragment>
      {loading && <Skeleton width="100%" />}
      <Stack
        direction="row"
        overflow="hidden"
        spacing={0.25}
        onClick={filterClickHandler}
        flex={1}
      >
        <FilterAltIcon color="primary" fontSize="small" />
        <TypographyWithTooltip
          title={name ?? ""}
          placement="top"
          color="primary.main"
          variant="subtitle2"
          fontWeight={400}
        />
      </Stack>
      <Popover
        open={open}
        anchorEl={anchor}
        onClose={closeHandler}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={paperProps}
      >
        <AccountCardFilterTemplatePopoverContent
          accountId={accountId}
          provider={provider}
          onClose={closeMenu}
          filterSetId={filterSetId}
        />
      </Popover>
    </Fragment>
  );
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const paperProps = {
  style: { maxHeight: 400, width: 380 },
};
