import { FC, Fragment, useMemo } from "react";
import { Alert } from "@mui/material";
import { ResourcesActionsList } from "./ResourcesActionsList";
import {
  ProviderType,
  ResourceFiltersWithAction,
} from "../../../../../../../../../services/cloudchipr.api";
import { CleanActionTypes } from "../../../../../../../../../utils/clean-options";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { stateAccountsSelector } from "../../../../../../../../../store/accounts/selectors/slice-data/stateAccountsSelector";

interface ResourcesActionsProps {
  accountIds: string[];
  provider: ProviderType;
  resourceFilters?: ResourceFiltersWithAction[];
  onFilterActionChange(resourceActions: Record<string, CleanActionTypes>): void;
}

export const ResourcesActions: FC<ResourcesActionsProps> = ({
  resourceFilters,
  onFilterActionChange,
  accountIds,
  provider,
}) => {
  const accounts = useAppSelector(stateAccountsSelector);

  const bothAccountTypesExist = useMemo(() => {
    const readAccounts = accounts?.filter(({ id }) => accountIds?.includes(id));

    return (
      readAccounts?.some(({ access_type }) => access_type === "read") &&
      readAccounts?.some(({ access_type }) => access_type === "read_write")
    );
  }, [accountIds, accounts]);

  return (
    <Fragment>
      {bothAccountTypesExist && (
        <Alert
          variant="outlined"
          severity="warning"
          sx={{ display: "flex", alignItems: "center" }}
        >
          Actions will be performed only on accounts with Read/Write access.
        </Alert>
      )}

      <ResourcesActionsList
        provider={provider}
        accountIds={accountIds}
        resourceFilters={resourceFilters || []}
        onChange={onFilterActionChange}
      />
    </Fragment>
  );
};
