import { Typography } from "@mui/material";
import { CostBreakdownWidgetTableViewLabelHeaderCell } from "./header/CostBreakdownWidgetTableViewLabelHeaderCell";
import { CostBreakdownWidgetTableViewTotalCostHeaderCell } from "./header/CostBreakdownWidgetTableViewTotalCostHeaderCell";
import { CostBreakdownWidgetTableViewPrevPeriodTotalCostHeaderCell } from "./header/CostBreakdownWidgetTableViewPrevPeriodTotalCostHeaderCell";
import { CostBreakdownWidgetTableViewTrend } from "./header/CostBreakdownWidgetTableViewTrend";
import { CostBreakdownWidgetLabelCell } from "./CostBreakdownWidgetLabelCell";
import { CostBreakdownWidgetForecastedCostHeaderCell } from "./CostBreakdownWidgetForecastedCostHeaderCell";
import {
  ForecastOption,
  NullableForecastedCost,
  ResourceExplorerGrouping,
} from "../../../../../../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { TrendChip } from "../../../../../../../../../common/chips/TrendChip";
import { labelColumnSortingFn } from "../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/utils/helpers/sortingFunctions";
import { forecastedCostOptionLabels } from "../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/utils/constants";
import { groupingsExcludingForecastedCostsColumns } from "../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/utils/constants/groupingsExcludingForecastedCostsColumns";
import { ResourceExplorerGridDataWithId } from "../../../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";

const getColumnsStartPart = (
  widgetId?: string,
): ColumnSetupType<ResourceExplorerGridDataWithId>[] => {
  return [
    {
      size: 400,
      minSize: 150,
      accessorKey: "label",
      header: () => {
        return (
          <CostBreakdownWidgetTableViewLabelHeaderCell widgetId={widgetId} />
        );
      },
      sortingFn: labelColumnSortingFn,
      cell: CostBreakdownWidgetLabelCell,
    },
    {
      minSize: 110,
      accessorKey: "total_cost",
      header: () => (
        <CostBreakdownWidgetTableViewTotalCostHeaderCell widgetId={widgetId} />
      ),
      cell: (cell) => {
        const val = cell.getValue() as number;

        return (
          <Typography variant="body2" align="right">
            {isNaN(val) ? "N / A" : money(val)}
          </Typography>
        );
      },
    },
    {
      minSize: 170,
      accessorKey: "previous_period_total_cost",
      header: () => (
        <CostBreakdownWidgetTableViewPrevPeriodTotalCostHeaderCell
          widgetId={widgetId}
        />
      ),
      cell: (cell) => {
        const val = cell.getValue() as number;

        return (
          <Typography variant="body2" align="right">
            {isNaN(val) ? "N / A" : money(val)}
          </Typography>
        );
      },
    },
    {
      size: 100,
      accessorKey: "trend",
      header: () => <CostBreakdownWidgetTableViewTrend widgetId={widgetId} />,
      cell: (cell) => {
        const data = cell.getValue() as number;

        if (data === null) {
          return "-";
        }

        return <TrendChip value={data} />;
      },
    },
  ];
};

interface GetCostBreakdownWidgetTableViewColumnsArgs {
  widgetId?: string;
  forecastOption?: ForecastOption;
  grouping?: ResourceExplorerGrouping;
}

export const getCostBreakdownWidgetTableViewColumns = ({
  widgetId,
  forecastOption,
  grouping,
}: GetCostBreakdownWidgetTableViewColumnsArgs): ColumnSetupType<ResourceExplorerGridDataWithId>[] => {
  const startPart = getColumnsStartPart(widgetId);
  if (
    !grouping ||
    groupingsExcludingForecastedCostsColumns.has(grouping) ||
    !forecastOption
  ) {
    return startPart;
  }
  return [
    ...startPart,
    {
      minSize: 190,
      accessorKey:
        forecastOption === "month" ? "monthly_forecast" : "quarterly_forecast",
      header: () => (
        <CostBreakdownWidgetForecastedCostHeaderCell
          forecastOption={forecastOption}
        />
      ),
      meta: {
        headerTitle: `Forecasted ${forecastedCostOptionLabels.get(
          "month",
        )} Cost`,
      },
      cell: (cell) => {
        const data: NullableForecastedCost = cell.getValue();

        return (
          <Typography variant="body2" align="right" pr={1}>
            {data === null || data === undefined
              ? "-"
              : `~${money(data?.cost)}`}
          </Typography>
        );
      },
    },
  ];
};
