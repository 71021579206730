import { useCallback } from "react";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../store/hooks";
import { currentAccountIdSelector } from "../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import {
  generateStorageKey,
  useLocalStorageHook,
} from "../../../../../utils/hooks/useLocalStorage.hook";

export function useResourceCardCollapsedState(
  resourceType: ResourceType,
  initialState?: boolean,
) {
  const { getItem, setItem } = useLocalStorageHook();

  const accountId = useAppSelector(currentAccountIdSelector) || "";
  const key = generateStorageKey({
    accountId,
    resourceType,
    identifier: "resourceCardConfig",
  });

  const config = getItem(key);
  const initialCollapsed = config?.collapsed || initialState;

  const setCollapsedState = useCallback(
    (collapsed: boolean) => {
      setItem(key, { ...config, collapsed });
    },
    [key, setItem, config],
  );

  return {
    collapsedState: initialCollapsed,
    setCollapsedState,
  };
}
