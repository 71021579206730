import { RootState } from "../../../../store";
import { updateDashboardFixedCacheKey } from "../../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";

const selector =
  cloudChiprApi.endpoints
    .patchUsersMeOrganisationsCurrentDashboardsByDashboardId.select;

export const updateDashboardHierarchyItemNameLoadingSelector = (
  state: RootState,
): boolean => {
  return !!selector(updateDashboardFixedCacheKey)(state)?.isLoading;
};
