import { v4 as uuid } from "uuid";
import { SavingsPlanCoverageDataWithId } from "../../../../components/pages/commtiments/utilization-and-coverage/utils/types";
import { SavingsPlanCoverageData } from "../../../../services/cloudchipr.api";

export const addIdInCoverageData = (
  data?: SavingsPlanCoverageData[],
): SavingsPlanCoverageDataWithId[] | undefined => {
  return data?.map((item) => ({
    ...item,
    id: uuid(),
  }));
};
