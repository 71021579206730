import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { currentAccountSelectedRowsWithHierarchyByResourceTypeSelector } from "../current-account/currentAccountSelectedRowsWithHierarchyByResourceTypeSelector";

export const resourceCleanActionsButtonDisabledReasonSelector = (
  state: RootState,
  resourceType: ResourceType,
): "non_selected_data" | "multi_resource_type_selection" | undefined => {
  const selectedResourcesWithHierarchy =
    currentAccountSelectedRowsWithHierarchyByResourceTypeSelector(
      state,
      resourceType,
    );

  const selectedItems = Object.keys(selectedResourcesWithHierarchy ?? {});

  if (!selectedItems?.length) {
    return "non_selected_data";
  }

  if (selectedItems.length > 1) {
    return "multi_resource_type_selection";
  }
};
