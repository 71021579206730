import { FC, useMemo } from "react";
import { usePreviousDifferent } from "rooks";
import { coverageTableColumns } from "./columns/coverageTableColumns";
import { DataGrid } from "../../../../../../../storybook/data-grid/DataGrid";
import { useAppSelector } from "../../../../../../../store/hooks";
import { coverageDataSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/coverageDataSelector";
import { ToolbarConfig } from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import { utilizationAndCoverageCoverageGroupingSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/view-options/utilizationAndCoverageCoverageGroupingSelector";
import { EmptyDataGridFooter } from "../../common/EmptyDataGridFooter";
import { commitmentsForTableLoadingSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/commitmentsForTableLoadingSelector";
import { getCommitmentsTableStyles } from "../../../utils/helpers/getCommitmentsTableStyles";
import { coverageGroupingNames } from "../../../utils/constants/labels";

interface CoverageDataGridProps {
  toolbar: ToolbarConfig;
}

export const CoverageDataGrid: FC<CoverageDataGridProps> = ({ toolbar }) => {
  const data = useAppSelector(coverageDataSelector);
  const loading = useAppSelector(commitmentsForTableLoadingSelector);
  const grouping = useAppSelector(
    utilizationAndCoverageCoverageGroupingSelector,
  );

  const prevData = usePreviousDifferent(data);
  const gridData = data ?? prevData;
  const empty = !loading && !gridData?.length;

  const columns = coverageTableColumns(grouping);

  const pagination = useMemo(() => {
    if (!empty) {
      return undefined;
    }

    return {
      renderPagination: () => {
        return <EmptyDataGridFooter name={coverageGroupingNames[grouping]} />;
      },
    };
  }, [empty, grouping]);

  if (!gridData) {
    return null;
  }

  return (
    <DataGrid
      toolbar={toolbar}
      styles={getCommitmentsTableStyles(empty)}
      data={gridData}
      columnSorting={coverageSortingConfig}
      columns={columns}
      pagination={pagination}
    />
  );
};

const coverageSortingConfig = {
  initialSort: [{ id: "covered_usage", desc: true }],
};
