import * as Sentry from "@sentry/react";
import { SnackbarProvider } from "notistack";
import { useFlag } from "@unleash/proxy-client-react";
import { AppRouter } from "./components/router/AppRouter";
import { UserAbilities } from "./components/UserAbilities";
import { Authentication } from "./components/Authentication";
import { SnackbarAlert } from "./components/pages/common/snackbar-alert/SnackbarAlert";
import { Common } from "./components/Common";
import { MaintenanceMode } from "./components/error-pages/MaintenanceMode";

export const App = Sentry.withProfiler(() => {
  const enableMaintenanceMode = useFlag("EnableMaintenanceMode");

  if (
    process.env.REACT_APP_MAINTENANCE_MODE === "true" ||
    enableMaintenanceMode
  ) {
    return <MaintenanceMode />;
  }

  return (
    <Authentication>
      <UserAbilities>
        <SnackbarProvider
          maxSnack={4}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          Components={{ snackbarAlert: SnackbarAlert }}
        >
          <AppRouter />
          <Common />
        </SnackbarProvider>
      </UserAbilities>
    </Authentication>
  );
});
