import { FC, Fragment } from "react";
import { ListItem, ListItemText, Typography } from "@mui/material";
import moment from "moment";
import { NullableDate } from "../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { cutScheduleNextRunDateTimeZoneName } from "../utils/helpers";
import { getTimeZoneObject } from "../../../../../../utils/helpers/date-time/timeZones";

interface ScheduledNextRunListItemProps {
  nextRun?: NullableDate;
  timeZone?: string;
  label?: string;
}

export const ScheduledNextRunListItem: FC<ScheduledNextRunListItemProps> = ({
  nextRun,
  timeZone,
  label,
}) => {
  if (!nextRun) {
    return null;
  }

  const primary = (
    <Fragment>
      {label && (
        <Typography display="block" color="grey.500" variant="caption">
          {label}
        </Typography>
      )}
      {`${moment(nextRun).format("ddd")}, ${formatDate(nextRun, {
        type: "dateWithComma",
      })} ${formatDate(nextRun, {
        type: "time",
      })}`}
    </Fragment>
  );

  const secondary = cutScheduleNextRunDateTimeZoneName(
    getTimeZoneObject(timeZone).name,
  );

  return (
    <ListItem sx={{ flexDirection: "column", alignItems: "flex-start", py: 0 }}>
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={{
          variant: "body2",
        }}
        secondaryTypographyProps={{
          fontSize: 10,
          color: "grey.600",
          lineHeight: 1,
        }}
      />
    </ListItem>
  );
};
