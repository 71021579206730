import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { rollbackState } from "../../resourceExplorerSlice";
import { resourceExplorerPostFixedCacheKey } from "../../selectors/current-resource-explorer/resource-explorer-widget/resourceExplorerWidgetSelector";
import { ResourceExplorerDataGridView } from "../../utils/types/resourceExplorerDataGrid";
import { resourceExplorerGranularDataFixedCacheKey } from "../../selectors/current-resource-explorer/data-grid/granular-costs-view/resourceExplorerGranularViewResponseSelector";
import { resourceExplorerDataGridViewSelector } from "../../selectors/current-resource-explorer/resourceExplorerDataGridViewSelector";
import { resourceExplorerPayloadDataSelector } from "../../selectors/current-resource-explorer/resource-explorer-widget/resourceExplorerPayloadDataSelector";
import { addFiltersFromPathToResourceExplorerPayload } from "../../utils/helpers/addFiltersFromPathToResourceExplorerPayload";
import { ResourceExplorerPathFilters } from "../../utils/types/resourceExplorer";

type ArgsType = {
  filtersFromPath?: ResourceExplorerPathFilters;
  gridViewOverridden?: ResourceExplorerDataGridView;
} | void;

export const getResourceExplorerDataThunk = createAsyncThunk(
  "resourceExplorer/getResourceExplorerDataThunk",
  async (args: ArgsType, { dispatch, getState }) => {
    const filtersFromPath =
      args?.filtersFromPath ?? ({} as ResourceExplorerPathFilters);
    const gridViewOverridden = args?.gridViewOverridden;

    const state = getState() as RootState;
    const resourceExplorerDataGettingPayload =
      resourceExplorerPayloadDataSelector(state);
    const dataGridView =
      gridViewOverridden ?? resourceExplorerDataGridViewSelector(state);

    const {
      postUsersMeOrganisationsCurrentResourceExplorer,
      postUsersMeOrganisationsCurrentResourceExplorerGranularCosts,
    } = cloudChiprApi.endpoints;

    if (!resourceExplorerDataGettingPayload.group_by) {
      return;
    }

    const payload = addFiltersFromPathToResourceExplorerPayload(
      filtersFromPath,
      resourceExplorerDataGettingPayload,
    );

    try {
      const promises: Promise<any>[] = [];

      if (!gridViewOverridden || gridViewOverridden === "total") {
        promises.push(
          dispatch(
            postUsersMeOrganisationsCurrentResourceExplorer.initiate(
              { resourceExplorerFilterSchema: payload },
              { fixedCacheKey: resourceExplorerPostFixedCacheKey },
            ),
          ).unwrap(),
        );
      }

      if (dataGridView === "granular") {
        promises.push(
          dispatch(
            postUsersMeOrganisationsCurrentResourceExplorerGranularCosts.initiate(
              { resourceExplorerFilterSchema: payload },
              { fixedCacheKey: resourceExplorerGranularDataFixedCacheKey },
            ),
          ).unwrap(),
        );
      }

      const responses = await Promise.all(promises);
      return responses.at(0);
    } catch (e) {
      dispatch(rollbackState());

      // @ts-expect-error //todo: remove this when backend will add types
      enqueueSnackbar(e?.data?.message ?? "Something went wrong", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
