import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { getDashboardsThunk } from "./getDashboardsThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { removeDashboardFixedCacheKey } from "../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";

interface RemoveDashboardByIdThunkArgs {
  dashboardId: string;
}
export const removeDashboardByIdThunk = createAsyncThunk(
  "dashboards/removeDashboardById",
  async ({ dashboardId }: RemoveDashboardByIdThunkArgs, { dispatch }) => {
    const { deleteUsersMeOrganisationsCurrentDashboardsByDashboardId } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        deleteUsersMeOrganisationsCurrentDashboardsByDashboardId.initiate(
          { dashboardId },
          { fixedCacheKey: removeDashboardFixedCacheKey },
        ),
      );

      dispatch(getDashboardsThunk());

      enqueueSnackbar("Dashboard successfully deleted.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "success",
        },
      });
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
