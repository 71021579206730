import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { TooltipText } from "../../../../../../../../../common/TooltipText";
import { InfoDataChip } from "../../../../../../widgets/common/widget-header/source-and-name/InfoDataChip";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import {
  DateLabelNullable,
  NullableDate,
} from "../../../../../../../../../../services/cloudchipr.api";
import { formatLargestCostChangesWidgetPrevDate } from "../../utils/helpers/formatLargestCostChangesWidgetPrevDate";

interface LargestCostChangesPrevDateDetailsProps {
  date: {
    from?: NullableDate;
    to?: NullableDate;
    label?: DateLabelNullable;
  };
}
export const LargestCostChangesPrevDateDetails: FC<
  LargestCostChangesPrevDateDetailsProps
> = ({ date }) => {
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const formattedDate = formatLargestCostChangesWidgetPrevDate({
    date,
    quarterStartMonth,
  });

  return (
    <InfoDataChip>
      <Stack direction="row" spacing={0.5} alignItems="center" component="span">
        <AccessTimeIcon sx={{ fontSize: 18 }} />
        {formattedDate?.range ? (
          <TooltipText
            tooltipTitle={formattedDate.range}
            tooltipPlacement="top"
          >
            {formattedDate.label}
          </TooltipText>
        ) : (
          <Typography variant="body2" color="text.primary" component="span">
            {formattedDate.label}
          </Typography>
        )}
      </Stack>
    </InfoDataChip>
  );
};
