import { FC } from "react";
import { IconButton, Link, Stack, Typography } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import CloseIcon from "@mui/icons-material/Close";

interface DrawerHeaderProps {
  title?: string;
  url?: string;
  onClose(): void;
}

export const CommitmentsDrawerHeader: FC<DrawerHeaderProps> = ({
  onClose,
  title,
  url,
}) => {
  return (
    <Stack
      px={2}
      py={1}
      top={0}
      borderBottom={1}
      borderColor="grey.300"
      position="sticky"
      direction="row"
      alignItems="center"
      bgcolor="white"
      zIndex="appBar"
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography fontWeight="medium" variant="body1">
          {title}
        </Typography>

        {url && (
          <Link href={url} target="_blank" sx={{ fontSize: 0 }}>
            <LaunchIcon fontSize="small" color="action" />
          </Link>
        )}
      </Stack>

      <IconButton onClick={onClose} size="small">
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};
