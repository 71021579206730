import {
  cloudChiprApi,
  PostUsersMeSchedulesNextRunDateApiResponse,
} from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { AutomationTabType } from "../../../../../components/pages/schedule/common/utils/types/types";
import { getScheduleNestRunDatesFixedCacheKey } from "../../../../../components/pages/schedule/classic/components/create/tabs/utils/constants";

const selector = cloudChiprApi.endpoints.postUsersMeSchedulesNextRunDate.select;
export const scheduleNextRunDatesSelector = (
  state: RootState,
  frequency?: AutomationTabType,
): PostUsersMeSchedulesNextRunDateApiResponse | undefined => {
  return selector(getScheduleNestRunDatesFixedCacheKey(frequency))(state)?.data;
};
