import { columnsMap } from "./columnsMap";
import { ResourceType } from "../../../../../../../../../../../../services/cloudchipr.api";

export const getResourceTypeColumns = (
  resourceType: ResourceType,
  disabledColumns: string[] = [],
) => {
  return (
    columnsMap.get(resourceType)?.filter((value) => {
      return !(
        value.accessorKey &&
        disabledColumns.includes(value.accessorKey as string)
      );
    }) ?? null
  );
};
