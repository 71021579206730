import { FC, useMemo } from "react";
import { Box } from "@mui/material";
import { usePreviousDifferent } from "rooks";
import { CommitmentsDrawerDataGridToolbar } from "./CommitmentsDrawerDataGridToolbar";
import { commitmentsDrawerDataGridColumns } from "./columns/commitmentsDrawerDataGridColumns";
import { DataGrid } from "../../../../../../../../storybook/data-grid/DataGrid";
import { ToolbarConfig } from "../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { utilizationAndCoverageDetailsCoverageDataSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/data/utilizationAndCoverageDetailsCoverageDataSelector";
import { utilizationAndCoverageDrawerCoverageGroupingSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/options/utilizationAndCoverageDrawerCoverageGroupingSelector";
import { utilizationAndCoverageDetailsLoadingSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/data/utilizationAndCoverageDetailsLoadingSelector";
import { CommitmentsGridLoadingLayer } from "../../../common/CommitmentsGridLoadingLayer";
import { DataViewTab } from "../../../../../../../../store/commitments/utils/types/types";
import { EmptyDataGridFooter } from "../../../common/EmptyDataGridFooter";
import { getCommitmentsTableStyles } from "../../../../utils/helpers/getCommitmentsTableStyles";
import { coverageGroupingNames } from "../../../../utils/constants/labels";

interface CommitmentsDrawerDataGridProps {
  viewTab: DataViewTab;
}
export const CommitmentsDrawerDataGrid: FC<CommitmentsDrawerDataGridProps> = ({
  viewTab,
}) => {
  const data = useAppSelector(
    utilizationAndCoverageDetailsCoverageDataSelector,
  );
  const loading = useAppSelector(utilizationAndCoverageDetailsLoadingSelector);
  const grouping = useAppSelector(
    utilizationAndCoverageDrawerCoverageGroupingSelector,
  );

  const prevData = usePreviousDifferent(data);
  const gridData = data ?? prevData ?? [];

  const columns = useMemo(() => {
    return commitmentsDrawerDataGridColumns?.[viewTab]?.[grouping] ?? [];
  }, [grouping, viewTab]);

  const empty = !loading && !gridData.length;

  const pagination = useMemo(() => {
    if (!empty) {
      return undefined;
    }

    return {
      renderPagination: () => {
        return <EmptyDataGridFooter name={coverageGroupingNames[grouping]} />;
      },
    };
  }, [empty, grouping]);

  if (!columns) {
    return null;
  }

  return (
    <Box mt={3} position="relative" zIndex={1}>
      {loading && <CommitmentsGridLoadingLayer />}

      <DataGrid
        columnSorting
        toolbar={toolbar}
        styles={getCommitmentsTableStyles(empty)}
        data={gridData}
        columns={columns}
        pagination={pagination}
      />
    </Box>
  );
};

const toolbar: ToolbarConfig = {
  renderToolbar: (props) => <CommitmentsDrawerDataGridToolbar {...props} />,
};
