import { FC, Fragment } from "react";
import { ListSubheader, Typography } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { IntegrationMenuItem } from "./IntegrationMenuItem";
import { integrationOptions } from "../../../../utils/constants/constants";
import { ResourceType } from "../../../../../../../../../../../../../services/cloudchipr.api";

interface IntegrationMenuItemsProps {
  resourceType: ResourceType;
  id: string;
}

export const IntegrationMenuItems: FC<IntegrationMenuItemsProps> = ({
  id,
  resourceType,
}) => {
  const enableSlackFromAccountPage = useFlag("EnableSlackFromAccountPage");
  const enableEmailFromAccountPage = useFlag("EnableEmailFromAccountPage");

  return (
    <Fragment>
      <ListSubheader>
        <Typography fontSize="smaller">INTEGRATIONS</Typography>
      </ListSubheader>

      {integrationOptions.map(({ type, label }) => (
        <IntegrationMenuItem
          id={id}
          key={type}
          type={type}
          disabled={
            (type === "slack" && !enableSlackFromAccountPage) ||
            (type === "email" && !enableEmailFromAccountPage)
          }
          resourceType={resourceType}
          label={label}
        />
      ))}
    </Fragment>
  );
};
