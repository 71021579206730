import { currentAccountSelectedResourcesByResourceTypeSelector } from "./currentAccountSelectedResourcesByResourceTypeSelector";
import { RootState } from "../../../../../store";
import { ResourceType } from "../../../../../../services/cloudchipr.api";
import { generateExportedResourceData } from "../../../../../../components/pages/account/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/generateExportedResourceData";

export const selectedResourcesCSVDataDisabledColumns = ["issues"];

export const currentAccountSelectedResourcesByResourceTypeCsvDataSelector = (
  state: RootState,
  resourceType: ResourceType,
  disabledColumns?: string[],
) => {
  const resources = currentAccountSelectedResourcesByResourceTypeSelector(
    state,
    resourceType,
  );

  if (!resources) {
    return null;
  }

  return generateExportedResourceData(resourceType, resources, [
    ...(disabledColumns ?? []),
    ...selectedResourcesCSVDataDisabledColumns,
  ]);
};
