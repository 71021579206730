import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { commitmentsRecommendationsDataSelector } from "./commitmentsRecommendationsDataSelector";
import { SavingsPlanRecommendation } from "../../../../../services/cloudchipr.api";

export const commitmentsRecommendationsListSelector = createDraftSafeSelector(
  [commitmentsRecommendationsDataSelector],
  (data): (SavingsPlanRecommendation & { id: string })[] | undefined => {
    return data?.recommendations?.map((rec) => ({ ...rec, id: uuid() }));
  },
);
