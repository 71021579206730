import { FC, Fragment, ReactNode } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { WidgetSourceInfo } from "../../../../widgets/common/widget-header/source-and-name/WidgetSourceInfo";
import { DateInfoChipProps } from "../../../../widgets/common/widget-header/source-and-name/DateInfoChip";
import { SourceDataChipProps } from "../../../../widgets/common/widget-header/source-and-name/SourceDataChip";

interface WidgetSetupSubHeaderProps {
  name: string;
  icon: "pie" | "list";
  date: DateInfoChipProps;
  title: string;
  menuTitle: ReactNode;
  menuUrl: string;
  grouping?: string;
}

export const WidgetSetupSubHeader: FC<WidgetSetupSubHeaderProps> = ({
  icon,
  name,
  date,
  title,
  menuTitle,
  menuUrl,
  grouping,
}) => {
  const widgetSourceData: SourceDataChipProps = {
    icon,
    title,
    menu: [
      {
        url: menuUrl,
        title: menuTitle,
      },
    ],
  };

  return (
    <Fragment>
      <Stack px={2} py={1}>
        <Typography variant="body1" fontWeight="bold" minHeight={24}>
          {name}
        </Typography>

        <WidgetSourceInfo
          dates={date}
          sourceData={widgetSourceData}
          grouping={grouping}
        />
      </Stack>

      <Divider />
    </Fragment>
  );
};
