import {
  ResourceExplorerFilterItem,
  ResourceExplorerFilterKeyType,
  ResourceExplorerPossibleFilter,
} from "../../../../../../services/cloudchipr.api";

interface SyncCurrentFiltersWithPossibleFiltersArgs {
  key: ResourceExplorerFilterKeyType;
  initialFilter?: ResourceExplorerFilterItem;
  possibleFilters?: ResourceExplorerPossibleFilter;
}
export const syncCurrentFiltersWithPossibleFilters = ({
  key,
  initialFilter,
  possibleFilters,
}: SyncCurrentFiltersWithPossibleFiltersArgs) => {
  if (!initialFilter?.value) {
    return;
  }
  if (key === "charge_type") {
    const syncedInitialDataWithPossibleFilters = initialFilter?.value?.filter(
      (item) =>
        possibleFilters?.values?.find((member) => member.value === item),
    );

    return {
      ...initialFilter,
      value: syncedInitialDataWithPossibleFilters,
    };
  }
  return initialFilter;
};
