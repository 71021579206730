import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../store";
import { accountFiltersByCategoryIdSelector } from "../../selectors/filter-by-category/accountFiltersByCategoryIdSelector";
import {
  setProviders,
  updateMultiFilterByProvider,
} from "../../resourceExplorerSlice";
import { ProviderType } from "../../../../services/cloudchipr.api";

export const setAccountFilterByCategoryThunk = createAsyncThunk(
  "setAccountFilterByCategory",
  (categoryId: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    if (!categoryId) {
      return;
    }
    const accounts = accountFiltersByCategoryIdSelector(state, categoryId);
    const providers = (accounts ? Object.keys(accounts) : []) as ProviderType[];

    dispatch(setProviders(providers));

    providers?.forEach((provider) => {
      dispatch(
        updateMultiFilterByProvider({
          provider: provider,
          filter: {
            key: "account",
            value: accounts?.[provider] ?? [],
            operator: "in",
            include_values: null,
            cloud_provider: provider,
          },
        }),
      );
    });
  },
);
