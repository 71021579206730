import { currentAccountProviderTypeSelector } from "./currentAccountProviderTypeSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { resourceGroups } from "../../../../utils/constants/resources/resourceGroups";

export const currentAccountResourceTypesHierarchySelector = (
  state: RootState,
  resourceType: ResourceType,
): ResourceType[] => {
  const currentAccountProviderType = currentAccountProviderTypeSelector(state);

  if (
    !currentAccountProviderType ||
    !resourceGroups.get(currentAccountProviderType)
  ) {
    return [];
  }
  const groups = resourceGroups.get(currentAccountProviderType);
  return (
    groups?.find((item) => item.value === resourceType)?.hierarchy?.[
      resourceType
    ] ?? [resourceType]
  );
};
