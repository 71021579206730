import { FC, Fragment, useCallback, useEffect, useRef } from "react";
import { Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { orange } from "@mui/material/colors";
import { SnackbarKey, useSnackbar } from "notistack";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { FilterTemplateViewActions } from "./FilterTemplateViewActions";
import { FilterTemplateSaveAsNewDialog } from "./FilterTemplateSaveAsNewDialog";
import { FilterTemplateDropdown } from "./templates-dropdown/FilterTemplateDropdown";
import { useAppSelector } from "../../../../../store/hooks";
import { defaultFilterTemplateFiltersChangedSelector } from "../../../../../store/filters/selectors/filter-set/default/defaultFilterTemplateFiltersChangedSelector";
import { appliedFilterTemplateNameSelector } from "../../../../../store/filters/selectors/filter-set/applied/appliedFilterTemplateNameSelector";
import { useDialog } from "../../../../../utils/hooks/useDialog.hook";
import { filterTemplatesLoadingSelector } from "../../../../../store/filters/selectors/filter-set/filterTemplatesLoadingSelector";
import { isFilterChangedManuallySelector } from "../../../../../store/filters/selectors/isFilterChangedManuallySelector";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import filterTemplateIcon from "../../../../../assets/images/icons/filter_template.svg";
import filterTemplateOrangeIcon from "../../../../../assets/images/icons/filter_template_orange.svg";
import { useMenuHook } from "../../../../../utils/hooks/useMenu.hook";

export const FilterTemplateView: FC = () => {
  const snackBarKeyRef = useRef<SnackbarKey>("");
  const filterTemplateChanged = useAppSelector(
    defaultFilterTemplateFiltersChangedSelector,
  );
  const filterTemplateName = useAppSelector(appliedFilterTemplateNameSelector);
  const loading = useAppSelector(filterTemplatesLoadingSelector);
  const filterChanged = useAppSelector(isFilterChangedManuallySelector);

  const {
    open: dropdownOpen,
    openMenu: openDropdown,
    closeMenu: closeDropdown,
    anchor,
  } = useMenuHook();

  const {
    open: saveAsNewOpen,
    openDialog: openSaveAsNewDialog,
    closeDialog: closeSaveAsNewDialog,
  } = useDialog();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const closeChangesSnackBar = useCallback(() => {
    closeSnackbar(snackBarKeyRef.current);
  }, [closeSnackbar]);

  const notifyAboutChangesHandler = useCallback(() => {
    snackBarKeyRef.current = enqueueSnackbar("This view has unsaved changes", {
      variant: "snackbarAlert",
      AlertSnackBarProps: {
        icon: false,
        severity: "info",
        onClose: closeChangesSnackBar,
        footer: (
          <FilterTemplateViewActions
            snackBarUi
            closeParentsSnackBar={closeChangesSnackBar}
            openDialog={openSaveAsNewDialog}
          />
        ),
        description: (
          <Typography variant="caption" color="text.secondary">
            To save, click the
            <Typography
              fontWeight="medium"
              component="span"
              fontSize="inherit"
              mx={0.5}
            >
              SAVE
            </Typography>
            button.
            <br /> Saving updates the view for everyone
          </Typography>
        ),
      },
      autoHideDuration: 3000,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  }, [openSaveAsNewDialog, enqueueSnackbar, closeChangesSnackBar]);

  const wrapperClickHandler = useCallback(
    (event: any) => {
      if (saveAsNewOpen) {
        return;
      }
      if (dropdownOpen) {
        closeDropdown();
      } else {
        openDropdown(event);
      }
    },
    [dropdownOpen, closeDropdown, openDropdown, saveAsNewOpen],
  );

  useEffect(() => {
    if (filterChanged) {
      notifyAboutChangesHandler();
    }
  }, [filterChanged, notifyAboutChangesHandler]);

  return (
    <Fragment>
      <Tooltip
        title={filterTemplateChanged ? "You have unsaved changes" : ""}
        arrow
        placement="top"
      >
        <Stack
          direction="row"
          flexShrink={0}
          spacing={1}
          alignItems="center"
          bgcolor={filterTemplateChanged ? orange[50] : "inherit"}
          px={1}
          py={0.25}
          onClick={wrapperClickHandler}
          sx={{
            cursor: "pointer",
          }}
        >
          {filterTemplateChanged ? (
            <img src={filterTemplateOrangeIcon} alt="filter-template" />
          ) : (
            <img src={filterTemplateIcon} alt="filter-template" />
          )}

          <Stack direction="row" alignItems="center">
            <Stack>
              {loading ? (
                <Skeleton height={20} width={120} />
              ) : (
                <TypographyWithTooltip
                  width={120}
                  variant="body2"
                  fontWeight={400}
                  sx={
                    filterTemplateChanged
                      ? changedTemplateTextStyles
                      : undefined
                  }
                  title={filterTemplateName || ""}
                />
              )}

              {filterTemplateChanged ? (
                <FilterTemplateViewActions
                  closeParentsSnackBar={closeChangesSnackBar}
                  openDialog={openSaveAsNewDialog}
                />
              ) : (
                <Typography
                  variant="caption"
                  fontSize={10}
                  color="text.disabled"
                  fontWeight="medium"
                >
                  Filter template applied
                </Typography>
              )}
            </Stack>
          </Stack>
          <KeyboardArrowDownOutlinedIcon fontSize="small" color="primary" />
          <FilterTemplateSaveAsNewDialog
            open={saveAsNewOpen}
            onClose={closeSaveAsNewDialog}
          />
        </Stack>
      </Tooltip>
      <FilterTemplateDropdown
        open={dropdownOpen}
        anchor={anchor}
        onClose={closeDropdown}
      />
    </Fragment>
  );
};

const changedTemplateTextStyles = {
  textDecoration: "underline",
  textDecorationStyle: "dashed",
  textDecorationColor: orange[500],
  textUnderlineOffset: 4,
};
