import { FC, ReactNode } from "react";
import { TableRow as TableRowMui, TableRowProps, Theme } from "@mui/material";
import { Row } from "@tanstack/react-table";
import { SxProps } from "@mui/system";
import { DraggableRow } from "./DraggableRow";

export type WithGetRowProps = {
  getRowProps?: (row: Row<any>) => TableRowProps;
};

interface RowDnDWrapperProps extends WithGetRowProps {
  wrap: boolean;
  row: Row<any>;
  styles?: SxProps<Theme>;
  children?: ReactNode;
}
export const RowDnDWrapper: FC<RowDnDWrapperProps> = ({
  children,
  wrap,
  row,
  styles,
  getRowProps,
}) => {
  const rowProps = getRowProps?.(row);

  if (!wrap) {
    return (
      <TableRowMui sx={styles} id={row.id} {...rowProps}>
        {children}
      </TableRowMui>
    );
  }

  return (
    <DraggableRow row={row} styles={styles} rowProps={rowProps}>
      {children}
    </DraggableRow>
  );
};
