import { FC, Fragment, useCallback } from "react";
import { Button, Divider, Stack } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { SnackbarKey, useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { appliedFilterTemplateFiltersSelector } from "../../../../../store/filters/selectors/filter-set/applied/appliedFilterTemplateFiltersSelector";
import {
  setAppliedFilters,
  setFilterChangedManually,
} from "../../../../../store/filters/filtersSlice";
import { currentAccountIdSelector } from "../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import { getCurrentAccountResourceTypeWithFiltersDataThunk } from "../../../../../store/account/thunks/filters-get/getCurrentAccountResourceTypeWithFiltersDataThunk";
import { useAppAbility } from "../../../../../services/permissions";

interface FilterTemplateViewActionsProps {
  snackBarUi?: boolean;
  closeParentsSnackBar(): void;
  openDialog(): void;
}

export const FilterTemplateViewActions: FC<FilterTemplateViewActionsProps> = ({
  snackBarUi,
  closeParentsSnackBar,
  openDialog,
}) => {
  const { can } = useAppAbility();
  const canCreateFilterSet = can("create", "filter-set");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const accountId = useAppSelector(currentAccountIdSelector);
  const appliedFilterTemplateFilters = useAppSelector(
    appliedFilterTemplateFiltersSelector,
  );

  const notifyHandler = useCallback(() => {
    const key: SnackbarKey = enqueueSnackbar("Changes reverted", {
      variant: "snackbarAlert",
      AlertSnackBarProps: {
        icon: false,
        severity: "info",
        onClose: () => closeSnackbar(key),
      },
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
    closeParentsSnackBar();
  }, [enqueueSnackbar, closeSnackbar, closeParentsSnackBar]);

  const revertHandler = useCallback(
    (event: any) => {
      if (!accountId || !appliedFilterTemplateFilters) {
        return;
      }

      appliedFilterTemplateFilters.forEach((resourceFilters) => {
        dispatch(setAppliedFilters({ accountId, filters: resourceFilters }));
        dispatch(
          getCurrentAccountResourceTypeWithFiltersDataThunk(
            resourceFilters.type,
          ),
        );
      });

      dispatch(setFilterChangedManually(false));
      notifyHandler();
      event.stopPropagation();
      event.preventDefault();
    },
    [dispatch, notifyHandler, accountId, appliedFilterTemplateFilters],
  );

  const saveButtonClickHandler = useCallback(
    (event: any) => {
      openDialog();
      event.preventDefault();
      event.stopPropagation();
    },
    [openDialog],
  );

  return (
    <Stack
      mt={0.5}
      direction="row"
      justifyContent="space-between"
      height={snackBarUi ? "unset" : 16}
      minWidth={snackBarUi ? 340 : "unset"}
      divider={
        !snackBarUi && <Divider light orientation="vertical" sx={{ mr: 0.5 }} />
      }
    >
      {snackBarUi ? (
        <Fragment>
          <Button size="small" color="tertiary" onClick={revertHandler}>
            Revert
          </Button>

          <Button
            variant="contained"
            size="small"
            onClick={saveButtonClickHandler}
            disabled={!canCreateFilterSet}
          >
            Save
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <Button
            size="xsmall"
            sx={{ textTransform: "none" }}
            onClick={saveButtonClickHandler}
            disabled={!canCreateFilterSet}
            startIcon={<SaveIcon sx={{ fontSize: 16 }} />}
          >
            Save
          </Button>

          <Button
            size="xsmall"
            color="tertiary"
            onClick={revertHandler}
            sx={{ textTransform: "none" }}
            startIcon={<RotateLeftIcon sx={{ fontSize: 16 }} />}
          >
            Revert
          </Button>
        </Fragment>
      )}
    </Stack>
  );
};
