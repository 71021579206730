import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { getResourceExplorerViewsHierarchyThunk } from "./getResourceExplorerViewsHierarchyThunk";
import { createResourceExplorerThunkFixedCacheKey } from "../resource-explorer/createResourceExplorerThunk";
import { RootState } from "../../../store";
import { enabledProvidersSelector } from "../../../common/selectors/billing-analytics/enabled-providers/enabledProvidersSelector";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const createResourceExplorerHierarchyViewThunk = createAsyncThunk(
  "resourceExplorer/createResourceExplorerHierarchyView",
  async (name: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    const enableProviders = enabledProvidersSelector(state);

    const { postUsersMeOrganisationsCurrentResourceExplorerViews } =
      cloudChiprApi.endpoints;
    let response;

    try {
      response = await dispatch(
        postUsersMeOrganisationsCurrentResourceExplorerViews.initiate(
          {
            body: {
              name: name,
              cloud_providers: Array.from(enableProviders),
              filters: [],
            },
          },
          { fixedCacheKey: createResourceExplorerThunkFixedCacheKey },
        ),
      ).unwrap();

      if (response) {
        dispatch(getResourceExplorerViewsHierarchyThunk());

        const snackbarId: SnackbarKey = enqueueSnackbar(
          "View successfully created.",
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: {
              severity: "success",
              onClose: () => closeSnackbar(snackbarId),
            },
          },
        );
      }
      return response;
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
