import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useGetUsersMeProvidersGcpAccountsByAccountIdMissingApisQuery } from "../../../../../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../../../../../services/permissions";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { currentAccountIdSelector } from "../../../../../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import { CopyIconButton } from "../../../../../../../../common/CopyIconButton";
import { MessagesDialog } from "../../../common/MessagesDialog";
import { MissingPermissionsRecheck } from "../../../common/MissingPermissionsRecheck";

interface GcpMissingApisDialogProps {
  open: boolean;
  onClose(): void;
}
export const GcpMissingApisDialog: FC<GcpMissingApisDialogProps> = ({
  open,
  onClose,
}) => {
  const { can } = useAppAbility();
  const canEditAccount = can("edit", "account");
  const currentAccountId = useAppSelector(currentAccountIdSelector);

  const { data, refetch } =
    useGetUsersMeProvidersGcpAccountsByAccountIdMissingApisQuery(
      { accountId: currentAccountId || "" },
      { skip: !currentAccountId },
    );

  if (!data?.length) {
    return null;
  }
  return (
    <MessagesDialog
      name="APIs"
      open={open}
      onClose={onClose}
      actions={
        <Button color="tertiary" onClick={onClose}>
          Close
        </Button>
      }
    >
      <Stack spacing={3}>
        <Typography fontWeight="medium">Please enable missing APIs.</Typography>

        <Box>
          <Typography variant="body1">Missing APIs:</Typography>

          <ul>
            {data?.map((p) => {
              const role = p.title || p.name;
              return (
                <li key={role}>
                  {p.url && canEditAccount ? (
                    <Link href={p.url} target="_blank">
                      {role}
                    </Link>
                  ) : (
                    role
                  )}
                  {role && <CopyIconButton data={role} visible />}
                </li>
              );
            })}
          </ul>
        </Box>

        <MissingPermissionsRecheck forApi refetch={refetch} />
      </Stack>
    </MessagesDialog>
  );
};
