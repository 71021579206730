import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateMultiFilterByProvider } from "../../resourceExplorerSlice";
import {
  ResourceExplorerFilterItem,
  ProviderType,
} from "../../../../services/cloudchipr.api";
import { getResourceExplorerWidgetDataThunk } from "../widgets/getResourceExplorerWidgetDataThunk";

interface RegionFilterChangeThunkArg {
  filter: ResourceExplorerFilterItem;
  provider: ProviderType;
}

export const creditTypeFilterChangeThunk = createAsyncThunk(
  "resourceExplorer/creditTypeFilterChange",
  ({ filter, provider }: RegionFilterChangeThunkArg, { dispatch }) => {
    dispatch(
      updateMultiFilterByProvider({
        filter,
        provider,
      }),
    );
    dispatch(getResourceExplorerWidgetDataThunk());
  },
);
