import { FC } from "react";
import { Button, Divider, Stack } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

interface ResourceExplorerTagFilterOperatorProps {
  operator: "and" | "or";
  onClick(): void;
  disabled?: boolean;
}

export const ResourceExplorerTagFilterOperator: FC<
  ResourceExplorerTagFilterOperatorProps
> = ({ operator, onClick, disabled }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1} mt={1.5}>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        endIcon={<UnfoldMoreIcon />}
        onClick={onClick}
        disabled={disabled}
      >
        {operator}
      </Button>
      <Divider flexItem sx={{ flexGrow: 1, alignSelf: "center" }} />
    </Stack>
  );
};
