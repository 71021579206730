import { FC, useCallback, useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { DashboardCreateDialogActions } from "./components/DashboardCreateDialogActions";
import { DashboardCreateDialogContent } from "./components/DashboardCreateDialogContent";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { createDashboardThunk } from "../../../../../../store/dashboards/thunks/dashboard/createDashboardThunk";
import { createDashboardLoadingSelector } from "../../../../../../store/dashboards/selectors/dashboard/loadings/createDashboardLoadingSelector";

interface DashboardCreateDialogProps {
  open: boolean;
  onClose(): void;
}
export const DashboardCreateDialog: FC<DashboardCreateDialogProps> = ({
  open,
  onClose,
}) => {
  const [dashboardName, setDashboardName] = useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createDashboardLoading = useAppSelector(createDashboardLoadingSelector);

  const closeDialogHandler = useCallback(() => {
    onClose();
    setDashboardName("");
  }, [onClose]);

  const nameChangeHandler = useCallback((event: any) => {
    setDashboardName(event.target.value);
  }, []);

  const submitFormHandler = useCallback(() => {
    dispatch(
      createDashboardThunk({
        name: dashboardName,
      }),
    )
      .unwrap()
      .then((data: any) => {
        if (data) {
          closeDialogHandler();
          navigate({ pathname: `/dashboards/${data?.id}` });
        }
      });
  }, [dispatch, dashboardName, closeDialogHandler, navigate]);

  return (
    <Dialog open={open} onClose={closeDialogHandler} maxWidth="sm" fullWidth>
      <DialogTitleClosable title="Add Dashboard" onClose={closeDialogHandler} />
      <DialogContent
        sx={{
          pb: 4,
        }}
        dividers
      >
        <DashboardCreateDialogContent
          value={dashboardName}
          onTextFieldChange={nameChangeHandler}
        />
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <DashboardCreateDialogActions
          isLoading={createDashboardLoading}
          disabled={!dashboardName.trim()}
          onSubmit={submitFormHandler}
          onCloseDialog={closeDialogHandler}
        />
      </DialogActions>
    </Dialog>
  );
};
