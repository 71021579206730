import { FC } from "react";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import { useEffectOnceWhen } from "rooks";
import { useLocation } from "react-router-dom";
import { ResourceExplorerViewActionButton } from "./components/actions-menu/ResourceExplorerViewActionButton";
import { NavItem } from "../nav-item/NavItem";
import { useDialog } from "../../../../utils/hooks/useDialog.hook";
import { useAppAbility } from "../../../../services/permissions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { ResourceExplorerCreateOrEditDialog } from "../../../common/resource-explorer-create-or-edit-dialog/ResourceExplorerCreateOrEditDialog";
import { getResourceExplorersThunk } from "../../../../store/resource-explorer/thunks/resource-explorer/getResourceExplorersThunk";
import { sortedResourceExplorersSelector } from "../../../../store/resource-explorer/selectors/all-resource-explorers/sortedResourceExplorersSelector";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";

export const ResourceExplorerNavigation: FC = () => {
  const { open, openDialog, closeDialog } = useDialog();
  const { cannot, can } = useAppAbility();

  const cannotCreateView = cannot("create", "resource-explorer");
  const resourceExplorerAvailable = can("view", "resource-explorer");

  const location = useLocation();
  const resourceExplorerSelected =
    location.pathname.startsWith("/resource-explorer");

  const dispatch = useAppDispatch();

  useEffectOnceWhen(() => {
    dispatch(getResourceExplorersThunk());
  }, resourceExplorerAvailable);

  const sortedResourceExplorers = useAppSelector(
    sortedResourceExplorersSelector,
  );

  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  return (
    <NavItem
      primary="Resource Explorer"
      icon={BarChartOutlinedIcon}
      selected={resourceExplorerSelected}
      secondaryAction={
        <IconButton size="small" onClick={openDialog}>
          <AddIcon fontSize="inherit" />
        </IconButton>
      }
      navCollapsed={navigationCollapsed}
    >
      {sortedResourceExplorers.map((resourceExplorer) => (
        <NavItem
          nested
          key={resourceExplorer.id}
          primary={resourceExplorer.name}
          to={`/resource-explorer/${resourceExplorer.id}`}
          icon={InsertChartOutlinedOutlinedIcon}
          secondaryAction={
            !resourceExplorer.is_default ? (
              <ResourceExplorerViewActionButton
                id={resourceExplorer.id}
                name={resourceExplorer.name}
              />
            ) : null
          }
          secondaryActionHover
        />
      ))}
      <NavItem
        nested
        primary="Add"
        primaryTypographyProps={{ color: "primary" }}
        onButtonClick={openDialog}
        disabled={cannotCreateView}
        icon={AddIcon}
        iconProps={{ color: "primary" }}
      />
      <ResourceExplorerCreateOrEditDialog
        open={open}
        type="create"
        title="Add View"
        onClose={closeDialog}
      />
    </NavItem>
  );
};
