import { previewSelectedResourceIdsSelector } from "./previewSelectedResourceIdsSelector";
import { RootState } from "../../../../store";
import { ResourceType } from "../../../../../services/cloudchipr.api";

export const previewSelectedResourceIdsByResourceTypeSelector = (
  state: RootState,
  resourceType: ResourceType,
) => {
  const resources = previewSelectedResourceIdsSelector(state);

  return resources[resourceType];
};
