import { FC } from "react";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";

interface InfoCardProps {
  value?: number | string;
  title: string;
  titleSx?: SxProps<Theme>;
  valueSx?: SxProps<Theme>;
}

export const InfoCard: FC<InfoCardProps> = ({
  value,
  title,
  titleSx,
  valueSx,
}) => {
  return (
    <div>
      <Typography
        variant="subtitle2"
        color="text.secondary"
        fontWeight="regular"
        whiteSpace="nowrap"
        sx={{ ...titleSx }}
      >
        {title ?? "-"}
      </Typography>
      <Typography
        color="text.primary"
        variant="h6"
        whiteSpace="nowrap"
        sx={{ ...valueSx }}
      >
        {value ?? "-"}
      </Typography>
    </div>
  );
};
