import { FC, useMemo } from "react";
import { usePreviousDifferent } from "rooks";
import { savingsPlansTableColumns } from "./columns/savingsPlansTableColumns";
import { DataGrid } from "../../../../../../../storybook/data-grid/DataGrid";
import { useAppSelector } from "../../../../../../../store/hooks";
import { savingsPlansDataSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/savingsPlansDataSelector";
import { ToolbarConfig } from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import { EmptyDataGridFooter } from "../../common/EmptyDataGridFooter";
import { commitmentsForTableLoadingSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/commitmentsForTableLoadingSelector";
import { getCommitmentsTableStyles } from "../../../utils/helpers/getCommitmentsTableStyles";
import { coverageGroupingNames } from "../../../utils/constants/labels";

interface SavingsPlansDataGridProps {
  toolbar: ToolbarConfig;
}

export const SavingsPlansDataGrid: FC<SavingsPlansDataGridProps> = ({
  toolbar,
}) => {
  const data = useAppSelector(savingsPlansDataSelector);
  const loading = useAppSelector(commitmentsForTableLoadingSelector);

  const prevData = usePreviousDifferent(data);
  const gridData = data ?? prevData;
  const empty = !loading && !gridData?.length;

  const pagination = useMemo(() => {
    if (!empty) {
      return undefined;
    }

    return {
      renderPagination: () => {
        return <EmptyDataGridFooter name={coverageGroupingNames.commitment} />;
      },
    };
  }, [empty]);

  if (!gridData) {
    return null;
  }

  return (
    <DataGrid
      toolbar={toolbar}
      styles={getCommitmentsTableStyles(empty)}
      data={gridData}
      columns={savingsPlansTableColumns}
      columnSorting={sortingConfig}
      pagination={pagination}
    />
  );
};

const sortingConfig = {
  initialSort: [{ id: "end_date", desc: false }],
};
