import { FC } from "react";
import { Stack } from "@mui/material";
import { UrlCopyButton } from "./URLCopyButton";
import { DashboardAppBarNavigation } from "./breadcrumb-navigation/DashboardAppBarNavigation";
import { useAppSelector } from "../../../../../store/hooks";
import { AddingWidget } from "../adding-widget/AddingWidget";
import { dashboardWidgetsSelector } from "../../../../../store/dashboards/selectors/dashboard/dashboardWidgetsSelector";
import { PageHeader } from "../../../common/PageHeader";

interface DashboardHeaderProps {
  loading: boolean;
}

export const DashboardHeader: FC<DashboardHeaderProps> = ({ loading }) => {
  const widgetsData = useAppSelector(dashboardWidgetsSelector);

  return (
    <PageHeader
      sticky
      loading={loading}
      title={<DashboardAppBarNavigation loading={loading} />}
      actions={
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <UrlCopyButton disabled={loading} />

          {!!widgetsData?.length && (
            <AddingWidget startIcon disabled={loading} />
          )}
        </Stack>
      }
    />
  );
};
