import { costBreakdownV2WidgetSetupDataGettingLoadingSelector } from "../loading/costBreakdownV2WidgetSetupDataGettingLoadingSelector";
import { RootState } from "../../../../../store";
import { resourceExplorerByIdLoadingSelector } from "../../../../../resource-explorer/selectors/resource-explorer-by-id/resourceExplorerByIdLoadingSelector";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";

export const costBreakdownV2WidgetSetupLoadingSelector = (
  state: RootState,
): boolean => {
  const viewId = costBreakdownV2SetupPropertyByKeySelector("viewId")(state);
  const dataGettingLoading =
    costBreakdownV2WidgetSetupDataGettingLoadingSelector(state);

  const viewChangeLoading =
    costBreakdownV2SetupPropertyByKeySelector("loading")(state);

  const resourceExplorerViewLoading = resourceExplorerByIdLoadingSelector(
    state,
    viewId,
  );
  return dataGettingLoading || viewChangeLoading || resourceExplorerViewLoading;
};
