import { FC, useCallback, useEffect } from "react";
import { FormControl, IconButton, Stack } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { ResourceExplorerCsvDownloader } from "./csv/ResourceExplorerCSVDownloader";
import { ResourceExplorerDataGridViewTabs } from "./columns-view-tabs/ResourceExplorerDataGridViewTabs";
import { RenderToolbarProps } from "../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { GlobalFilter } from "../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { setResourceExplorerSearch } from "../../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { resourceExplorerSearchSelector } from "../../../../../../../../store/resource-explorer/selectors/resourceExplorerSearchSelector";
import { ResourceExplorerBreadcrumbs } from "../../resource-explorer-breadcrumbs/ResourceExplorerBreadcrumbs";
import { ColumnsSwitcher } from "../../../../../../../../storybook/data-grid/toolbar/ColumnsSwitcher";
import { useMenuHook } from "../../../../../../../../utils/hooks/useMenu.hook";
import { resourceExplorerDataGridViewSelector } from "../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerDataGridViewSelector";

export const ResourceExplorerCardDataGridToolbar: FC<RenderToolbarProps> = ({
  setGlobalFilter,
  table,
}) => {
  const { anchor, openMenu, closeMenu, open } = useMenuHook();

  const dispatch = useAppDispatch();
  const search = useAppSelector(resourceExplorerSearchSelector);
  const dataGridView = useAppSelector(resourceExplorerDataGridViewSelector);

  const onSearchChange = useCallback(
    (value: any) => {
      dispatch(setResourceExplorerSearch(value));
    },
    [dispatch],
  );

  useEffect(() => {
    setGlobalFilter(search);
  }, [search, setGlobalFilter]);

  return (
    <Stack direction="row" justifyContent="space-between" spacing={3}>
      <Stack direction="row" paddingY={2} alignItems="center" overflow="hidden">
        <ResourceExplorerBreadcrumbs />
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <GlobalFilter
            globalFilter={search}
            setGlobalFilter={onSearchChange}
            placeholder="Search"
            size="small"
            fullWidth
          />
        </FormControl>

        <ResourceExplorerDataGridViewTabs />

        <ResourceExplorerCsvDownloader />

        <IconButton
          onClick={openMenu}
          size="small"
          disabled={dataGridView === "granular"}
        >
          <SettingsOutlinedIcon fontSize="small" />
        </IconButton>

        <ColumnsSwitcher
          open={open}
          onClose={closeMenu}
          anchor={anchor}
          table={table}
        />
      </Stack>
    </Stack>
  );
};
