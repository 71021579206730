import { FC } from "react";
import {
  PreviewDataGridContent,
  PreviewDataGridContentProps,
} from "../common/resources-preview/components/data-grid/PreviewDataGridContent";
import { useAppSelector } from "../../../../store/hooks";
import { ResourceDataType } from "../../../../store/account/utils/types/types";
import { protectorResourcesLoadingSelector } from "../../../../store/schedules/selectors/resources-for-protector/protectorResourcesLoadingSelector";

interface ResourceProtectionDataGridProps
  extends Omit<PreviewDataGridContentProps, "loading" | "data"> {
  data: ResourceDataType[];
  batchId: string;
}

export const ResourceProtectionDataGrid: FC<ResourceProtectionDataGridProps> = (
  props,
) => {
  const { scheduleId, batchId } = props;

  const loading = useAppSelector((state) =>
    protectorResourcesLoadingSelector(state, scheduleId, batchId),
  );

  return (
    <PreviewDataGridContent {...props} loading={loading} batchId={batchId} />
  );
};
