import { createAsyncThunk } from "@reduxjs/toolkit";
import { protectThunk } from "./protectThunk";
import { ResourceType } from "../../../services/cloudchipr.api";
import { resetSelectedResources } from "../../account/accountSlice";
import { getCurrentAccountResourceTypeDataThunk } from "../../account/thunks/filters-get/getCurrentAccountResourceTypeDataThunk";

interface CreateSingleProtectThunkArgs {
  isProtected: boolean;
  id: string;
  resourceType: ResourceType;
}
export const createSingleProtectThunk = createAsyncThunk(
  "createSingleProtect",
  async (
    { isProtected, id, resourceType }: CreateSingleProtectThunkArgs,
    { dispatch },
  ) => {
    await dispatch(
      protectThunk({
        resources: [id],
        actionType: !isProtected ? "protect" : "unprotect",
        resourceType: resourceType,
      }),
    );
    dispatch(getCurrentAccountResourceTypeDataThunk(resourceType));
    dispatch(resetSelectedResources(resourceType));
  },
);
