import { costByCategorySetupSelector } from "./costByCategorySetupSelector";
import { RootState } from "../../../../store";
import { costByCategoryWidgetEditChangesExistSelector } from "../../widgets/cost-by-category/metadata/costByCategoryWidgetEditChangesExistSelector";

export const costByCategorySubmitButtonDisabledSelector = (
  state: RootState,
): boolean => {
  const editDisabledByChange =
    costByCategoryWidgetEditChangesExistSelector(state);
  const setup = costByCategorySetupSelector(state);
  return (
    (setup?.id && !editDisabledByChange) ||
    !setup?.categoryGroup ||
    !setup?.categoryIds.length ||
    !setup?.name
  );
};
