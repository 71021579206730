import { FC } from "react";
import { Box, Stack } from "@mui/material";
import { CellContext } from "@tanstack/react-table";
import {
  costByCategoryChartPrefixes,
  multiStackChartPrefixSeparator,
} from "../../../../../../../../../../../../storybook/charts/multi-type-chart/components/chart-by-type/multi-stack-chart/utils/constants/constants";
import { useMultiTypeChartContext } from "../../../../../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { RowSelectionCellCheckbox } from "../../../../../../../../../../../../storybook/data-grid/common/RowSelectionCellCheckbox";
import { CostByCategoryWidgetCategory } from "../../../../utils/types/types";

type CategoryNameCellProps = CellContext<CostByCategoryWidgetCategory, any>;

export const CostByCategorySelectionCell: FC<CategoryNameCellProps> = (
  cell,
) => {
  const name = cell.row.original.category ?? "";
  const keyWithPrefix = `${costByCategoryChartPrefixes.primary}${multiStackChartPrefixSeparator}${name}`;
  const { colors } = useMultiTypeChartContext();

  return (
    <Stack direction="row" spacing={0.5} alignItems="center" minWidth={60}>
      <Box
        width={4}
        height={30}
        borderRadius={1}
        bgcolor={colors[name] || colors[keyWithPrefix]}
      />

      <RowSelectionCellCheckbox row={cell.row} table={cell.table} />
    </Stack>
  );
};
