import { FC } from "react";
import { SnackbarKey, useSnackbar } from "notistack";
import { useEffectOnceWhen } from "rooks";
import {
  ProviderType,
  useGetV2UsersMeOrganisationsCurrentProvidersByProviderBillingStatusQuery,
} from "../../../../../../services/cloudchipr.api";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { billingStatusProcessByProviderSelector } from "../../../../../../store/common/selectors/billing-status/billingStatusProcessByProviderSelector";
import { removeBillingStatusByProvider } from "../../../../../../store/common/commonSlice";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";
import { getResourceExplorerPossibleFiltersThunk } from "../../../../../../store/resource-explorer/thunks/resource-explorer/getResourceExplorerPossibleFiltersThunk";
import { getResourceExplorerDataThunk } from "../../../../../../store/resource-explorer/thunks/widgets/getResourceExplorerDataThunk";
import { isCurrentResourceExplorerInitializedSelector } from "../../../../../../store/resource-explorer/selectors/current-resource-explorer/checkers/isCurrentResourceExplorerInitializedSelector";

interface BillingDataItemProps {
  provider: ProviderType;
}
export const BillingDataItem: FC<BillingDataItemProps> = ({ provider }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();

  const billingStatusProcess = useAppSelector((state) =>
    billingStatusProcessByProviderSelector(state, provider),
  );
  const resourceExplorerInitialized = useAppSelector(
    isCurrentResourceExplorerInitializedSelector,
  );

  const { data } =
    useGetV2UsersMeOrganisationsCurrentProvidersByProviderBillingStatusQuery(
      {
        provider: provider,
      },
      {
        pollingInterval: 5000,
        skip: !billingStatusProcess,
      },
    );

  useEffectOnceWhen(() => {
    const key: SnackbarKey = enqueueSnackbar(
      `${getProviderName(provider, {
        uppercase: true,
      })} billing data successfully collected.`,
      {
        variant: "snackbarAlert",
        autoHideDuration: 5000,
        AlertSnackBarProps: {
          onClose: () => closeSnackbar(key),
        },
      },
    );
    dispatch(
      getResourceExplorerPossibleFiltersThunk({
        provider,
        forceRefetch: true,
      }),
    );

    if (resourceExplorerInitialized) {
      dispatch(getResourceExplorerDataThunk());
    }
    dispatch(removeBillingStatusByProvider(provider));
  }, data?.status === "active");

  return null;
};
