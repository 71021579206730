import { FC, Fragment, useMemo } from "react";
import { Divider, Stack } from "@mui/material";
import { AppBarActions } from "./AppBarActions";
import { InfoCard } from "../InfoCard";
import { money } from "../../../../../../../utils/numeral/money";
import { ResourceFiltersWithAction } from "../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../store/hooks";
import { scheduleAllResourcesEmsSelector } from "../../../../../../../store/schedules/selectors/schedule-resources-preview/scheduleAllResourcesEmsSelector";
import { convertSecondsToUnits } from "../../../../classic/utils/helpers/gracePeriodCalculations";
import { protectorResourceTypesSelector } from "../../../../../../../store/schedules/selectors/resources-for-protector/protectorResourceTypesSelector";
import { protectorResourcesEmsSelector } from "../../../../../../../store/schedules/selectors/resources-for-protector/protectorResourcesEmsSelector";

interface ScheduleResourcesPreviewDrawerAppBarProps {
  accountIds: string[];
  regions?: string[];
  scheduleId: string;
  batchId?: string;
  gracePeriodValue?: number;
  nextRuns?: { title: string; date: string; utc?: string }[];
  frequency?: { title: string; label: string; tooltip: string }[];
  filters?: ResourceFiltersWithAction[];
}

export const ScheduleResourcesPreviewDrawerAppBar: FC<
  ScheduleResourcesPreviewDrawerAppBarProps
> = ({
  accountIds,
  gracePeriodValue,
  regions,
  nextRuns,
  frequency,
  filters,
  scheduleId,
  batchId,
}) => {
  const protectorResourcesResourceTypes = useAppSelector((state) =>
    protectorResourceTypesSelector(state, scheduleId, batchId),
  );

  const resourceTypes = useMemo(() => {
    if (batchId && protectorResourcesResourceTypes) {
      return protectorResourcesResourceTypes;
    }

    return filters?.map(({ filter }) => filter.type) ?? [];
  }, [filters, batchId, protectorResourcesResourceTypes]);

  const price = useAppSelector((state) =>
    batchId
      ? protectorResourcesEmsSelector(state, scheduleId, batchId)
      : scheduleAllResourcesEmsSelector(state, resourceTypes, scheduleId),
  );

  const gracePeriod = useMemo(() => {
    if (!gracePeriodValue) {
      return null;
    }
    const { message } = convertSecondsToUnits(gracePeriodValue);

    return message;
  }, [gracePeriodValue]);

  return (
    <Fragment>
      <Stack
        direction="row"
        px={3}
        my={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={2}>
          <InfoCard title="Monthly Price" value={money(price)} />

          {nextRuns?.map(({ date, title, utc }) => {
            return (
              <InfoCard
                key={title}
                title={title}
                value={date}
                tooltip={utc ? `${utc} in UTC time` : undefined}
              />
            );
          })}

          {gracePeriod && <InfoCard title="Grace Period" value={gracePeriod} />}

          {frequency?.map(({ tooltip, label, title }) => {
            return (
              <InfoCard
                key={title}
                title={title}
                value={label}
                tooltip={tooltip}
              />
            );
          })}
        </Stack>

        <AppBarActions
          scheduleId={scheduleId}
          regions={regions}
          filters={filters}
          accountIds={accountIds}
          batchId={batchId}
          resourceTypes={resourceTypes}
        />
      </Stack>

      <Divider />
    </Fragment>
  );
};
