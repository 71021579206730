import { useLocation, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { resourceTypeSearchParams } from "../constants";
import { ResourceType } from "../../../../../../../services/cloudchipr.api";

export const useAccountPageNavigate = (resourceType: ResourceType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  return useCallback(() => {
    navigate({
      pathname,
      search: `${resourceTypeSearchParams}=${resourceType}`,
    });
  }, [navigate, pathname, resourceType]);
};
