import { Dispatch, FC, SetStateAction, useCallback, useMemo } from "react";
import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { FilterListItemDisabledReasonTooltip } from "./FilterListItemDisabledReasonTooltip";
import { FilterListItemProps } from "./FilterListItem";
import { DropdownSelectOption } from "../utils/types/types";

interface FilterListParentItemProps extends FilterListItemProps {
  nestedOptions: DropdownSelectOption[];
  setSelectedValues: Dispatch<SetStateAction<string[]>>;
}

export const FilterListParentItem: FC<FilterListParentItemProps> = ({
  option,
  isSelected,
  isIndeterminate,
  nestedOptions,
  selectedValues,
  setSelectedValues,
  renderOption,
}) => {
  const { label, disabled, expandable } = option;
  const selected = useMemo(() => {
    return (
      isSelected ||
      !nestedOptions
        .filter((option) => !option.disabled)
        .some((v) => !selectedValues.includes(v.value))
    );
  }, [isSelected, nestedOptions, selectedValues]);

  const toggleHandler = useCallback(() => {
    const currentFilteredValues = nestedOptions
      .filter((option) => !option.disabled)
      .map((v) => v.value);

    if (!selected) {
      const newSelectedValues = currentFilteredValues.filter((v) => {
        return !selectedValues.includes(v);
      });

      setSelectedValues([...newSelectedValues, ...selectedValues]);
    } else {
      const newSelectedValues = selectedValues.filter(
        (v) => !currentFilteredValues.includes(v),
      );
      setSelectedValues(newSelectedValues);
    }
  }, [nestedOptions, selectedValues, setSelectedValues, selected]);

  const indeterminate =
    isIndeterminate ||
    nestedOptions.some(({ value }) => !!selectedValues?.includes(value));

  return (
    <ListItem
      disablePadding
      sx={{ mx: 0 }}
      onClick={
        expandable
          ? // todo: expanding logic should be implemented
            undefined
          : toggleHandler
      }
    >
      <ListItemButton
        dense
        sx={{
          px: 1,
          opacity: disabled ? 0.7 : 1,
          position: "relative",
          "&:hover": disabled ? { bgcolor: "white" } : undefined,
        }}
      >
        <Checkbox
          disableRipple
          size="small"
          tabIndex={-1}
          disabled={!!disabled}
          checked={selected}
          indeterminate={!disabled && !selected && indeterminate}
        />

        <ListItemText color={disabled ? "text.disabled" : undefined}>
          {renderOption ? renderOption(option) : label}
        </ListItemText>

        <FilterListItemDisabledReasonTooltip disabled={disabled} />
      </ListItemButton>
    </ListItem>
  );
};
