import { currentAccountProviderTypeSelector } from "./currentAccountProviderTypeSelector";
import { currentAccountIdSelector } from "./currentAccountIdSelector";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const currentAccountVisibilitiesSelector = (state: RootState) => {
  const accountId = currentAccountIdSelector(state);
  const provider = currentAccountProviderTypeSelector(state);

  if (!provider || !accountId) {
    return null;
  }

  const response =
    cloudChiprApi.endpoints.getUsersMeProvidersByProviderAccountsResourcesVisibilities.select(
      {
        provider,
        accountIds: accountId,
      },
    )(state);

  const transformedData = response.data?.reduce(
    (acc, { account_id, accounts_visibilities }) => {
      acc[account_id] = accounts_visibilities?.reduce(
        (acc, resource) => ({
          ...acc,
          [resource.type]: {
            show: resource.visibilities.show,
            actions: resource.visibilities.actions,
          },
        }),
        {},
      );

      return acc;
    },
    {} as Record<string, any>,
  );
  return transformedData?.[accountId] || null;
};
