import { syncCurrentFiltersWithPossibleFilters } from "./utils/syncCurrentFiltersWithPossibleFilters";
import { resourceExplorerFilterByKeyAndProviderSelector } from "../resourceExplorerFilterByKeyAndProviderSelector";
import { resourceExplorerViewDataByIdSelector } from "../../resource-explorer-by-id/resourceExplorerViewDataByIdSelector";
import { RootState } from "../../../../store";
import {
  ResourceExplorerFilterKeyType,
  ProviderType,
} from "../../../../../services/cloudchipr.api";
import { isDeepEqual } from "../../../../../utils/is-deep-equal";
import { resourceExplorerPossibleFiltersByProviderSelector } from "../possible-filters/resourceExplorerPossibleFiltersByProviderSelector";
import { getResourceExplorerRestructuredFilters } from "../../../utils/helpers/getResourceExplorerRestructuredFilters";
import { resourceExplorerProvidersSelector } from "../../current-resource-explorer/resourceExplorerProvidersSelector";
import { enabledProvidersSelector } from "../../../../common/selectors/billing-analytics/enabled-providers/enabledProvidersSelector";
import { appliedFiltersDependOnInitialOperatorSelector } from "../applied-filters/appliedFiltersDependOnInitialOperatorSelector";

interface FilterChangedByKeySelectorArg {
  viewId: string;
  key: ResourceExplorerFilterKeyType;
  provider: ProviderType;
}

export const filterChangedByKeySelector = (
  state: RootState,
  { viewId, key, provider }: FilterChangedByKeySelectorArg,
): boolean => {
  let filter = resourceExplorerFilterByKeyAndProviderSelector(
    state,
    key,
    provider,
  );

  const resourceExplorerViewFilters = resourceExplorerViewDataByIdSelector(
    state,
    viewId,
  )?.filters;

  const resourceExplorerProviders = resourceExplorerProvidersSelector(state);
  const enableProviders = enabledProvidersSelector(state);
  const enableProvidersArr = Array.from(enableProviders);

  const mutatedInitialFilter = resourceExplorerViewFilters
    ? getResourceExplorerRestructuredFilters(
        resourceExplorerViewFilters,
        resourceExplorerProviders,
        enableProvidersArr,
      )
    : null;
  const possibleFilters = resourceExplorerPossibleFiltersByProviderSelector(
    state,
    provider,
  )?.[key];

  // initial state case handling
  if (
    key === "credit_type" &&
    provider === "gcp" &&
    !resourceExplorerViewFilters?.filter(
      (item) => item.cloud_provider === "gcp",
    )?.length &&
    possibleFilters?.values?.length === filter?.value?.length
  ) {
    return false;
  }

  if (!filter && !!mutatedInitialFilter?.[provider]?.[key]) {
    return true;
  }

  if (
    key !== "credit_type" &&
    !filter?.value?.length &&
    !mutatedInitialFilter?.[provider]?.[key]
  ) {
    return false;
  }

  filter = appliedFiltersDependOnInitialOperatorSelector(state, {
    viewId,
    provider,
    key,
  });
  const initialFilter = mutatedInitialFilter?.[provider]?.[key];

  const syncedInitialFilter = syncCurrentFiltersWithPossibleFilters({
    key,
    initialFilter,
    possibleFilters,
  });

  const syncedFilter = syncCurrentFiltersWithPossibleFilters({
    key,
    initialFilter: filter,
    possibleFilters,
  });

  return !isDeepEqual(syncedFilter ?? {}, syncedInitialFilter ?? {});
};
