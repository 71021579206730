import { costByCategoryWidgetByIdSelector } from "./costByCategoryWidgetByIdSelector";
import { RootState } from "../../../../store";
import { ForecastOption } from "../../../../../services/cloudchipr.api";
import { costByCategorySetupPropertyByKeySelector } from "../../setups/cost-by-category/costByCategorySetupPropertyByKeySelector";

export const costByCategoryWidgetForecastOptionSelector = (
  state: RootState,
  widgetId?: string,
): ForecastOption => {
  let forecastOption =
    costByCategorySetupPropertyByKeySelector("forecastOption")(state);

  const data = costByCategoryWidgetByIdSelector(state, widgetId);

  if (data) {
    forecastOption = data?.forecast_option;
  }
  return forecastOption;
};
