import {
  OrganisationDailyOffHoursRecommendation,
  OrganisationWeeklyOffHoursRecommendation,
} from "../../../../services/cloudchipr.api";
import {
  RecommendationDataType,
  RecommendationGroupType,
} from "../../../../components/pages/recommendations/components/off-hours-recommendations/utils/types/types";
import { getResourceTypeName } from "../../../../utils/helpers/resources/getResourceTypeName";

export const recommendationGridDataCombiner = (
  group: RecommendationGroupType,
  data: (
    | OrganisationDailyOffHoursRecommendation
    | OrganisationWeeklyOffHoursRecommendation
  )[],
): RecommendationDataType[] => {
  return data.map((recommendation) => {
    const name = getResourceTypeName(recommendation.resource_type, {
      singular: true,
    });

    return {
      ...recommendation,
      name,
      group,
      frequency: recommendation.hasOwnProperty("from_day") ? "weekly" : "daily",
    };
  });
};
