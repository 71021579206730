import { resourceTypeDataResourcesSelector } from "./resourceTypeDataResourcesSelector";
import { RootState } from "../../../store";
import { ResourceType } from "../../../../services/cloudchipr.api";
import { generateExportedResourceData } from "../../../../components/pages/account/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/csv-data/generateExportedResourceData";
import { MetricsFrequenciesType } from "../../../../components/pages/account/components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/types/metricsFrequenciesType";

export const resourceTypeCsvDataSelector = (
  state: RootState,
  resourceType: ResourceType,
  disabledColumns: string[] = [],
  metricsFrequencies?: MetricsFrequenciesType,
) => {
  const resourceData = resourceTypeDataResourcesSelector(state, resourceType);

  if (!resourceData) {
    return null;
  }

  return generateExportedResourceData(
    resourceType,
    resourceData,
    disabledColumns,
    {
      includeNestedRows: true,
      metricsFrequencies,
    },
  );
};
