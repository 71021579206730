import { ResourceExplorerTag } from "../../../../../../../../../../../services/cloudchipr.api";

export const getValidatedCostAllocationTags = (
  filterValue: ResourceExplorerTag[],
) => {
  return filterValue?.filter((item) => {
    return (
      !!item.tag_key &&
      (["exists", "does_not_exist"].includes(item.operator) ||
        !!item?.tag_values?.length)
    );
  });
};
