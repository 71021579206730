import { FC, useCallback, useMemo } from "react";
import {
  ProviderType,
  ResourceExplorerTag,
} from "../../../../../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../../../../../common/TypographyWithTooltip";
import { useAppSelector } from "../../../../../../../../../../../../store/hooks";
import { getCostAllocationTagValuesLoadingSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/filters/cost-allocations-tags/getCostAllocationTagValuesLoadingSelector";
import { multipleValueSelectOperators } from "../../../../../../../../../../common/filters/utils/constants/filter-types/autocomplete-multipe-selection-operator-types";
import { AutocompleteSelect } from "../../../../../../../../../../../common/select/autocomplete-select/AutocompleteSelect";
import { RootState } from "../../../../../../../../../../../../store/store";
import { costAllocationTagValuesDataSelector } from "../../../../../../../../../../../../store/resource-explorer/selectors/filters/cost-allocations-tags/costAllocationTagValuesDataSelector";

interface ResourceExplorerTagValueSelectProps {
  index: number;
  provider: ProviderType;
  filter?: ResourceExplorerTag;
  onValueChange: (index: number, values: string[] | string) => void;
}

export const ResourceExplorerTagValueSelect: FC<
  ResourceExplorerTagValueSelectProps
> = ({ onValueChange, index, filter, provider }) => {
  const tagValuesSelector = useCallback(
    (state: RootState) =>
      costAllocationTagValuesDataSelector(state, provider, filter?.tag_key),
    [provider, filter?.tag_key],
  );

  const tagValues = useAppSelector(tagValuesSelector);

  const loadingSelector = useCallback(
    (state: RootState) =>
      getCostAllocationTagValuesLoadingSelector(
        state,
        provider,
        filter?.tag_key,
      ),
    [provider, filter?.tag_key],
  );
  const loading = useAppSelector(loadingSelector);

  const dropdownOptions = useMemo(() => {
    return (
      tagValues?.map((value) => {
        return {
          value: value,
          label: <TypographyWithTooltip variant="body2" title={value} />,
        };
      }) ?? []
    );
  }, [tagValues]);

  const singleSelect = !multipleValueSelectOperators.includes(
    filter?.operator ?? "",
  );
  const submitHandlerOnClose = useCallback(
    (selectedValue: any) => {
      const data = singleSelect ? selectedValue[0] : selectedValue;
      const value = data?.length ? data : [];
      onValueChange(index, value);
    },
    [singleSelect, onValueChange, index],
  );
  const values = useMemo(() => {
    const value = filter?.tag_values ?? [];
    const stringValue = value && typeof value === "string" ? [value] : [];
    return Array.isArray(value) ? value : stringValue;
  }, [filter]);

  return (
    <AutocompleteSelect
      size="xsmall"
      freeSolo={singleSelect}
      creatable={singleSelect}
      singleSelect={singleSelect}
      label={loading ? "Loading..." : "Select value"}
      placeholder={loading ? "Loading..." : "Select value"}
      options={dropdownOptions ?? []}
      initialSelectedValues={values}
      onChange={submitHandlerOnClose}
      disabled={loading}
      values={values}
      listWidth={320}
      placement="center"
    />
  );
};
