import { resourceExplorerFilterKeysAsProvidersSelector } from "./resourceExplorerFilterKeysAsProvidersSelector";
import { RootState } from "../../../../store";
import { ProviderType } from "../../../../../services/cloudchipr.api";
import { resourceExplorerProvidersSelector } from "../../current-resource-explorer/resourceExplorerProvidersSelector";
import { enabledProvidersSelector } from "../../../../common/selectors/billing-analytics/enabled-providers/enabledProvidersSelector";

export const resourceExplorerProvidersWithoutFiltersSelector = (
  state: RootState,
): ProviderType[] => {
  const resourceExplorerProviders = resourceExplorerProvidersSelector(state);
  const filterProviders = resourceExplorerFilterKeysAsProvidersSelector(state);

  const enabledProviders = enabledProvidersSelector(state);
  const enabledProvidersArr = Array.from(enabledProviders);

  return enabledProvidersArr.filter(
    (item) =>
      resourceExplorerProviders.includes(item) &&
      !filterProviders.includes(item),
  );
};
