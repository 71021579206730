import { currentAccountSelector } from "./currentAccountSelector";
import { RootState } from "../../../store";
import { ProviderAccessType } from "../../../../services/cloudchipr.api";

export const currentAccountAccessTypeSelector = (
  state: RootState,
): ProviderAccessType | undefined => {
  const account = currentAccountSelector(state);

  return account?.access_type;
};
