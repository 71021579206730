import { FC, memo, useEffect, useState } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { getResourceTypeColumns } from "./resouce-card-data-grid/utils/data-grid/columns-setup/getResourceTypeColumns";
import { ResourcesDataGrid } from "./resouce-card-data-grid/ResourcesDataGrid";
import { newMetricColumnsByFT } from "./resouce-card-data-grid/cells/metrics-cells/utils/helpers/newMetricColumnsEnablenes";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { WithResourceType } from "../../../../../utils/types";
import { DataGridProvider } from "../../../../../../../../storybook/data-grid/DataGridProvider";
import { resourceTypeDataResourcesSelector } from "../../../../../../../../store/account/selectors/resource-type-data/resourceTypeDataResourcesSelector";

interface ResourceCardGridProps extends WithResourceType {
  fullHeight?: boolean;
}

export const ResourceCardGrid: FC<ResourceCardGridProps> = memo(
  ({ resourceType, fullHeight }) => {
    const enableMetricsCharts = useFlag("EnableResourceSmallCharts");

    const columns = getResourceTypeColumns(
      resourceType,
      newMetricColumnsByFT(enableMetricsCharts),
    );

    const resourcesData = useAppSelector((state) =>
      resourceTypeDataResourcesSelector(state, resourceType),
    );

    const [gridData, setGridData] = useState(resourcesData || []);

    useEffect(() => {
      if (resourcesData) {
        setGridData(resourcesData);
      }
    }, [resourcesData]);

    if (!columns) {
      return null;
    }

    return (
      <DataGridProvider>
        <ResourcesDataGrid
          data={gridData}
          columns={columns}
          fullHeight={fullHeight}
          resourceType={resourceType}
        />
      </DataGridProvider>
    );
  },
);
