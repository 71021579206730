import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";

export const getUsersMeAccountsByAccountIdFilterSetsV2Selector = (
  state: RootState,
  accountId: string,
) =>
  cloudChiprApi.endpoints.getUsersMeAccountsByAccountIdFilterSetsV2.select({
    accountId,
  })(state);
