import { FC } from "react";
import { Divider, Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { TotalAndAverageCost } from "../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/TotalAndAverageCost";
import { InfoCard } from "../../../../common/info-cards/InfoCard";
import { money } from "../../../../../../utils/numeral/money";
import { ResourceExplorerForecastedCosts } from "../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/forecasted/ResourceExplorerForecastedCosts";
import {
  DateDataPoint,
  DateLabelNullable,
  ForecastOption,
} from "../../../../../../services/cloudchipr.api";
import { WidgetCosts } from "../../../../../../store/dashboards/utils/types/types";
import { ResourceExplorerLiveFiltered } from "../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-metadata/components/live-usage/ResourceExplorerLiveFiltered";

interface WidgetTotalsSummaryProps {
  costsData: WidgetCosts;
  frequency: DateDataPoint;
  forecastOption: ForecastOption;
  dateLabel?: DateLabelNullable;
}

export const WidgetTotalsSummary: FC<WidgetTotalsSummaryProps> = ({
  costsData,
  frequency,
  forecastOption,
  dateLabel,
}) => {
  const enableLiveUsageOnResourceExplorer = useFlag(
    "EnableLiveUsageOnResourceExplorer",
  );

  return (
    <Stack
      gap={1}
      flexWrap="wrap"
      direction="row"
      justifyContent="space-between"
    >
      <Stack
        direction="row"
        flexWrap="wrap"
        gap={2}
        divider={<Divider light orientation="vertical" flexItem />}
      >
        <TotalAndAverageCost
          totalCost={costsData.total.cost}
          totalCostTrend={costsData.total.trend}
          totalCostDates={costsData.total.dateDetails}
          previousPeriodCostDates={costsData?.previousPeriodTotal?.dateDetails}
          dateLabel={dateLabel}
          dataPoint={frequency ?? "monthly"}
          average={costsData.average.cost}
          averageTrend={costsData.average.trend}
        />

        {costsData.previousPeriodTotal && (
          <InfoCard
            title="Previous Period Cost"
            value={money(costsData.previousPeriodTotal.cost)}
          />
        )}
      </Stack>

      <Stack direction="row" gap={2} flexWrap="wrap">
        <ResourceExplorerForecastedCosts
          forecastedCosts={costsData.forecasted_costs}
          selectedForecastOption={forecastOption ?? "month"}
        />
        {enableLiveUsageOnResourceExplorer && (
          <ResourceExplorerLiveFiltered
            totalCost={costsData.liveUsageTotal.cost}
            tooltip={costsData.liveUsageTotal.tooltip}
            loadFrom="widget"
          />
        )}
      </Stack>
    </Stack>
  );
};
