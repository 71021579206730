import { currentAccountSelector } from "./currentAccountSelector";
import { RootState } from "../../../store";
import { NullableDate } from "../../../../services/cloudchipr.api";

export const currentAccountLastResourceUpdatedDateSelector = (
  state: RootState,
): NullableDate | undefined => {
  const account = currentAccountSelector(state);

  return account?.last_resource_updated_date;
};
