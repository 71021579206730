import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCostBreakdownWidgetDataBySetupThunk } from "./fetchCostBreakdownWidgetDataBySetupThunk";
import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { setWidgetSetup } from "../../../dashboardsSlice";
import { costBreakdownWidgetSetupSelector } from "../../../selectors/setups/cost-breakdown/costBreakdownWidgetSetupSelector";
import { RootState } from "../../../../store";
import { costBreakdownWidgetDefaultData } from "../../../utils/constants/costBreakdownSetupDefaultData";
import { getResourceExplorerPreferencesKey } from "../../../../../components/pages/resource-explorer/utils/hooks/useResourceExplorerPreferences.hook";

export const costBreakdownWidgetViewSelectChangeThunk = createAsyncThunk(
  "dashboards/costBreakdownWidgetViewSelectChange",
  async (viewId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const setup = costBreakdownWidgetSetupSelector(state);

    if (!setup) {
      return;
    }

    dispatch(setWidgetSetup({ ...setup, viewId, loading: true }));

    const {
      getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId,
      getUsersMeCurrentPreferenceByKey,
    } = cloudChiprApi.endpoints;

    const response = await dispatch(
      getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId.initiate(
        { resourceExplorerViewId: viewId },
      ),
    ).unwrap();

    const key = getResourceExplorerPreferencesKey(viewId);

    let preference;
    try {
      preference = await dispatch(
        getUsersMeCurrentPreferenceByKey.initiate({ key }),
      ).unwrap();
    } catch (e) {
      preference = null;
    }

    const viewType = preference?.value
      ? JSON.parse(preference?.value)?.chart?.type
      : "stack";

    if (!response) {
      return;
    }

    const { data } = response;

    const to = data?.to_date;
    const from = data?.from_date;
    const label = data?.date_label;

    dispatch(
      setWidgetSetup({
        ...(setup ?? costBreakdownWidgetDefaultData),
        loading: true,
        grouping: data.grouping === "resource" ? "service" : data.grouping,
        group_values: data.group_values,
        frequency: data.data_point,
        viewId: viewId,
        viewType: viewType ?? costBreakdownWidgetDefaultData.viewType,
        date: { label, to, from },
      }),
    );

    await dispatch(fetchCostBreakdownWidgetDataBySetupThunk());

    const updatedSetup = costBreakdownWidgetSetupSelector(
      getState() as RootState,
    );
    if (updatedSetup) {
      dispatch(setWidgetSetup({ ...updatedSetup, loading: false }));
    }
  },
);
