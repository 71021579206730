import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResourceExplorerFilterItem } from "../../../../../services/cloudchipr.api";
import { setResourceExplorerDimension } from "../../../resourceExplorerSlice";

export const getDimensionsFromFiltersThunk = createAsyncThunk(
  "resourceExplorer/getDimensionsFromFilters",
  (filters: ResourceExplorerFilterItem[], { dispatch }) => {
    const dimensionFilter = filters.find(
      (filter) => filter.key === "dimension_id",
    );
    const dimensionId = dimensionFilter?.value?.at(0) as string;

    if (!dimensionId) {
      return;
    }

    const categoryFilter = filters.find(
      (filter) => filter.key === "category_id",
    );

    const categoryIds = (categoryFilter?.value ?? []) as string[];

    dispatch(
      setResourceExplorerDimension({
        dimensionId,
        categoryIds,
      }),
    );
  },
);
