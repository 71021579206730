import { costBreakdownV2WidgetDataByWidgetIdSelector } from "./costBreakdownV2WidgetDataByWidgetIdSelector";
import { RootState } from "../../../../../store";

export const costBreakdownV2WidgetTotalCostDateDetailsSelector = (
  state: RootState,
  widgetId: string,
) => {
  return costBreakdownV2WidgetDataByWidgetIdSelector(state, widgetId)
    ?.total_cost_date_details;
};
