import { FC } from "react";
import { Table } from "@tanstack/react-table";
import { Box } from "@mui/material";
import {
  Pagination,
  PaginationChangeHandler,
} from "../../../../../../../../../../storybook/data-grid/common/Pagination";

interface ResourcesDataGridSubRowPaginationProps {
  table: Table<any>;
  onPageChange: PaginationChangeHandler;
}

const ResourcesDataGridSubRowFooterToolbar: FC<
  ResourcesDataGridSubRowPaginationProps
> = ({ table, onPageChange }) => {
  return (
    <Box
      display="flex"
      py={2}
      pl={2}
      flex={1}
      alignSelf="flex-end"
      alignItems="flex-end"
    >
      <Pagination table={table} onPageChange={onPageChange} />
    </Box>
  );
};

export const resourcesDataGridSubRowFooterToolbar = (
  table: Table<any>,
  onPageChange: PaginationChangeHandler,
) => {
  return (
    <ResourcesDataGridSubRowFooterToolbar
      table={table}
      onPageChange={onPageChange}
    />
  );
};
