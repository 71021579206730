import { FC, useCallback, useMemo } from "react";
import { Stack } from "@mui/material";
import { usePreviousImmediate } from "rooks";
import {
  ResourceCardStatisticsCard,
  ResourceCardStatisticsType,
} from "./ResourceCardStatisticsCard";
import { WithResourceType } from "../../../../../utils/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { resourceTypeDataEmsSelector } from "../../../../../../../../store/account/selectors/resource-type-data/resourceTypeDataEmsSelector";
import { resourceTypeDataLoadingSelector } from "../../../../../../../../store/account/selectors/resource-type-data/resourceTypeDataLoadingSelector";
import { resourceTypeDataSpendingSelector } from "../../../../../../../../store/account/selectors/resource-type-data/resourceTypeDataSpendingSelector";
import { resourceTypeDataTotalCountSelector } from "../../../../../../../../store/account/selectors/resource-type-data/resourceTypeDataTotalCountSelector";
import { getResourceTypeName } from "../../../../../../../../utils/helpers/resources/getResourceTypeName";
import { resourceTypesWithBillingDataCosts } from "../../../../../../../../utils/constants/resources/resources";
import { resourceViewIsWithoutFiltersSelector } from "../../../../../../../../store/account/selectors/current-account/resources/resourceViewIsWithoutFiltersSelector";
import {
  resetSelectedResources,
  setViewWithoutFiltersResourceTypes,
} from "../../../../../../../../store/account/accountSlice";
import { getCurrentAccountResourceTypeDataThunk } from "../../../../../../../../store/account/thunks/filters-get/getCurrentAccountResourceTypeDataThunk";
import { filteredResourcesCountSelector } from "../../../../../../../../store/account/selectors/resource-type-data/filteredResourcesCountSelector";
import { getAllResourcesInHierarchyByResourceType } from "../../../../../../../../utils/helpers/resources/getAllResourcesInHierarchyByResourceType";
import { filterTemplatesEnabledSelector } from "../../../../../../../../store/account/selectors/current-account/resources/filterTemplatesEnabledSelector";
import { filteredResourcesChildResourcesCountSelector } from "../../../../../../../../store/account/selectors/resource-type-data/filteredResourcesChildResourcesCountSelector";

interface ResourceCardStatisticsProps extends WithResourceType {}

export const ResourceCardStatistics: FC<ResourceCardStatisticsProps> = ({
  resourceType,
}) => {
  const dispatch = useAppDispatch();

  const viewIsWithoutFilters = useAppSelector((state) =>
    resourceViewIsWithoutFiltersSelector(state, resourceType),
  );
  const ems = useAppSelector((state) =>
    resourceTypeDataEmsSelector(state, resourceType),
  );
  const spending = useAppSelector((state) =>
    resourceTypeDataSpendingSelector(state, resourceType),
  );
  const loading = useAppSelector((state) =>
    resourceTypeDataLoadingSelector(state, resourceType),
  );
  const resourcesTotalCount = useAppSelector((state) =>
    resourceTypeDataTotalCountSelector(state, resourceType),
  );
  const resourcesCount = useAppSelector((state) =>
    filteredResourcesCountSelector(state, resourceType),
  );

  const resourceChildrenCount = useAppSelector((state) =>
    filteredResourcesChildResourcesCountSelector(state, resourceType),
  );

  const filterTemplatesEnabled = useAppSelector(filterTemplatesEnabledSelector);

  const tooltip = useMemo(() => {
    if (!resourceTypesWithBillingDataCosts.has(resourceType)) {
      return;
    }

    return `${getResourceTypeName(resourceType, {
      singular: true,
      type: "abbreviation",
    })} prices are calculated from cost and usage billing reports and updated  every 24 hours.`;
  }, [resourceType]);

  const count =
    resourceType === "rds_snapshot_source"
      ? resourceChildrenCount
      : resourcesCount;

  const onChange = useCallback(
    (value: ResourceCardStatisticsType) => {
      if ((value === "all") === viewIsWithoutFilters) {
        return;
      }
      const hierarchyResourceTypes =
        getAllResourcesInHierarchyByResourceType(resourceType);

      hierarchyResourceTypes.forEach((rt) => {
        dispatch(resetSelectedResources(rt));
        dispatch(
          setViewWithoutFiltersResourceTypes({
            resourceType: rt,
            value: value === "all",
          }),
        );
      });

      dispatch(getCurrentAccountResourceTypeDataThunk(resourceType));
    },
    [dispatch, resourceType, viewIsWithoutFilters],
  );

  const previousCount = usePreviousImmediate(count);
  const previousTotalCount = usePreviousImmediate(resourcesTotalCount);

  return (
    <Stack direction="row" spacing={2} alignItems="center" borderRadius={2}>
      {filterTemplatesEnabled && (
        <ResourceCardStatisticsCard
          price={ems}
          value="filtered"
          loading={loading}
          tooltip={tooltip}
          onChange={onChange}
          title="Filtered Resources"
          selected={!viewIsWithoutFilters}
          instances={`${count ?? previousCount ?? ""} ${getResourceTypeName(
            resourceType,
          )}`}
        />
      )}
      <ResourceCardStatisticsCard
        value="all"
        price={spending}
        loading={loading}
        tooltip={tooltip}
        onChange={onChange}
        title="All Resources"
        selected={viewIsWithoutFilters}
        instances={`${
          resourcesTotalCount ?? previousTotalCount ?? ""
        } ${getResourceTypeName(resourceType)}`}
        hideRadioButton={!filterTemplatesEnabled}
      />
    </Stack>
  );
};
