import { FC, Fragment, useCallback, useMemo } from "react";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { previewSelectedResourcesToProtectSelector } from "../../../../../../../store/schedules/selectors/schedule-resources-preview/selected-resources/previewSelectedResourcesToProtectSelector";
import {
  ResourceFiltersWithAction,
  ResourceType,
} from "../../../../../../../services/cloudchipr.api";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import { useDialog } from "../../../../../../../utils/hooks/useDialog.hook";
import { useAppAbility } from "../../../../../../../services/permissions";
import { scheduleResourcesProtectionThunk } from "../../../../../../../store/schedules/thunks/scheduleResourcesProtectionThunk";
import { previewSelectedResourcesSelector } from "../../../../../../../store/schedules/selectors/schedule-resources-preview/selected-resources/previewSelectedResourcesSelector";
import {
  getActionValue,
  getCleanActionLabel,
} from "../../../../../../../utils/clean-options";
import { ResourceProtectionDialogs } from "../protection/ResourceProtectionDialogs";
import { protectorResourceProtectThunk } from "../../../../../../../store/schedules/thunks/resources-for-protector/protectorResourceProtectThunk";
import { protectorResourceActionsByResourceTypeSelector } from "../../../../../../../store/schedules/selectors/resources-for-protector/protectorResourceActionsByResourceTypeSelector";
import { ScheduleProtectResources } from "../../../../../../../store/schedules/thunks/resourceProtectThunk";
import { protectorAllResourcesSelector } from "../../../../../../../store/schedules/selectors/resources-for-protector/protectorAllResourcesSelector";

interface AppBarActionsProps {
  resourceTypes: ResourceType[];
  accountIds: string[];
  regions?: string[];
  scheduleId: string;
  batchId?: string;
  filters?: ResourceFiltersWithAction[];
}

export const AppBarActions: FC<AppBarActionsProps> = ({
  resourceTypes,
  accountIds,
  filters,
  regions,
  scheduleId,
  batchId,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotProtectResource = cannot("protect", "resource");

  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const {
    open: protectOpen,
    openDialog: openProtectDialog,
    closeDialog: closeProtectDialog,
  } = useDialog();

  const {
    open: unProtectOpen,
    openDialog: openUnProtectDialog,
    closeDialog: closeUnProtectDialog,
  } = useDialog();

  const resources = useAppSelector((state) =>
    previewSelectedResourcesSelector(
      state,
      resourceTypes,
      accountIds,
      scheduleId,
    ),
  );

  const protectorResources = useAppSelector((state) =>
    protectorAllResourcesSelector(state, scheduleId, batchId),
  );

  const resourcesToProtect = useAppSelector((state) =>
    previewSelectedResourcesToProtectSelector(
      state,
      resourceTypes,
      accountIds,
      scheduleId,
      batchId,
    ),
  );

  const protectorResourceActions = useAppSelector((state) =>
    protectorResourceActionsByResourceTypeSelector(state, scheduleId, batchId),
  );

  const actionByResourceType = useMemo(() => {
    if (batchId && protectorResourceActions) {
      return protectorResourceActions;
    }

    return filters?.reduce(
      (acc, filter) => {
        const resourceType = filter.filter.type;
        const action = getActionValue(filter.action, filter.snapshot);

        acc[resourceType] = getCleanActionLabel(action, resourceType);

        return acc;
      },
      {} as Record<ResourceType, string>,
    );
  }, [filters, batchId, protectorResourceActions]);

  const { protectExist, unProtectExist } = useMemo(() => {
    let protectExist = false;
    let unProtectExist = false;

    const allItems = Object.values(resourcesToProtect).flat();

    allItems.forEach((item) => {
      if (item.is_protected) {
        protectExist = true;
      } else {
        unProtectExist = true;
      }
    });

    return {
      protectExist,
      unProtectExist,
    };
  }, [resourcesToProtect]);

  const handleProtect = useCallback(
    async (resourcesToProtect: ScheduleProtectResources) => {
      if (batchId) {
        await dispatch(
          protectorResourceProtectThunk({
            batchId,
            scheduleId,
            resourcesToProtect,
          }),
        );
      } else if (filters) {
        await dispatch(
          scheduleResourcesProtectionThunk({
            resourcesToProtect,
            resourceFilters: filters,
            accountIds,
            scheduleId,
            regions,
          }),
        );
      }
    },
    [dispatch, filters, batchId, accountIds, regions, scheduleId],
  );

  const submitHandler = useCallback(
    async (protect: boolean) => {
      const changed = Object.entries(resourcesToProtect).reduce(
        (acc, [accountId, resources]) => {
          acc[accountId] = resources.map((resource) => ({
            ...resource,
            is_protected: protect,
          }));

          return acc;
        },
        {} as ScheduleProtectResources,
      );

      await handleProtect(changed);
      closeMenu();
    },
    [closeMenu, handleProtect, resourcesToProtect],
  );

  const protectHandler = useCallback(async () => {
    await submitHandler(true);
    closeProtectDialog();
  }, [closeProtectDialog, submitHandler]);

  const unProtectHandler = useCallback(async () => {
    await submitHandler(false);
    closeUnProtectDialog();
  }, [closeUnProtectDialog, submitHandler]);

  return (
    <Fragment>
      <Button
        variant="contained"
        onClick={openMenu}
        disabled={!Object.values(resourcesToProtect)?.length}
        endIcon={<ArrowDropDownIcon />}
      >
        Actions
      </Button>

      <Menu open={open} onClose={closeMenu} anchorEl={anchor}>
        {protectExist && (
          <MenuItem
            dense
            onClick={openProtectDialog}
            disabled={canNotProtectResource}
          >
            <ListItemIcon>
              <LockOpenIcon fontSize="small" />
            </ListItemIcon>

            <ListItemText>Protect</ListItemText>
          </MenuItem>
        )}

        {unProtectExist && (
          <MenuItem
            dense
            onClick={openUnProtectDialog}
            disabled={canNotProtectResource}
          >
            <ListItemIcon>
              <LockOutlinedIcon fontSize="small" />
            </ListItemIcon>

            <ListItemText>Unprotect</ListItemText>
          </MenuItem>
        )}
      </Menu>

      <ResourceProtectionDialogs
        batchId={batchId}
        resources={batchId ? protectorResources : resources}
        scheduleId={scheduleId}
        protectDialogProps={{
          onClose: closeProtectDialog,
          open: protectOpen,
          onSubmit: protectHandler,
        }}
        unprotectDialogProps={{
          resources,
          open: unProtectOpen,
          onSubmit: unProtectHandler,
          onClose: closeUnProtectDialog,
          resourceTypeActions: actionByResourceType,
        }}
      />
    </Fragment>
  );
};
