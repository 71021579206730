import { RootState } from "../../../../../store";

import { commitmentsDataForGranularSelector } from "../commitmentsDataForGranularSelector";

export const savingsPlansNetSavingsSelector = (
  state: RootState,
): number | null => {
  const data = commitmentsDataForGranularSelector(state);

  return data?.totals?.total_net_savings ?? null;
};
