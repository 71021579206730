import { resourceExplorerViewsHierarchyDataSelector } from "./resourceExplorerViewsHierarchyDataSelector";
import { ResourceExplorer } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { isResourceExplorerFolder } from "../../../../components/navigation/components/resource-explorer-v2/utils/helpers/isResourceExplorerFolder";

export const resourceExplorerViewsHierarchyViewsSelector = (
  state: RootState,
): ResourceExplorer[] | undefined => {
  const hierarchy = resourceExplorerViewsHierarchyDataSelector(state);

  if (!hierarchy) {
    return;
  }
  return hierarchy.reduce((result, item) => {
    if (!isResourceExplorerFolder(item)) {
      result.push(item);
    } else {
      item?.views?.forEach((viewsInsideFolder) => {
        result.push(viewsInsideFolder);
      });
    }
    return result;
  }, [] as ResourceExplorer[]);
};
