import { FC } from "react";
import { Box } from "@mui/material";
import { CostBreakdownWidgetContentCosts } from "../../../../../widgets/cost-breakdown/components/CostBreakdownWidgetContentCosts";
import { CostBreakdownWidgetChart } from "../../../../../widgets/cost-breakdown/components/chart/CostBreakdownWidgetChart";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";
import { MultiTypeChartProvider } from "../../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { costBreakdownWidgetChartDataSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownWidgetChartDataSelector";
import { buildingInProgressWidgetId } from "../../../../../../../../../store/dashboards/thunks/widgets/utils/constants";

export const CostBreakdownWidgetSetupChartView: FC = () => {
  const data = useAppSelector((state) =>
    costBreakdownWidgetChartDataSelector(state, buildingInProgressWidgetId),
  );

  const setupDate = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("date"),
  );

  const chartType =
    useAppSelector(costBreakdownSetupPropertyByKeySelector("viewType")) ??
    "line";

  return (
    <MultiTypeChartProvider
      value={{
        data: data ?? [],
        initialChartType: chartType,
      }}
    >
      <Box p={2}>
        <CostBreakdownWidgetContentCosts dateLabel={setupDate?.label} />
        <CostBreakdownWidgetChart
          chartType={chartType}
          widgetId={buildingInProgressWidgetId}
        />
      </Box>
    </MultiTypeChartProvider>
  );
};
