import { currentAccountSelector } from "./currentAccountSelector";
import { RootState } from "../../../store";
import { AccountAwsProviderDetails } from "../../../../services/cloudchipr.api";

export const currentAwsAccountIsRootSelector = (
  state: RootState,
): boolean | undefined => {
  const account = currentAccountSelector(state);

  return (account?.provider_details as AccountAwsProviderDetails)?.aws?.is_root;
};
