import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import { getWidgetDataByViewIdThunk } from "../common/getWidgetDataByViewIdThunk";
import { largestCostChangesWidgetByIdSelector } from "../../../selectors/widgets/largest-cost-changes/largestCostChangesWidgetByIdSelector";

export const fetchLargestCostChangesWidgetDataByWidgetIdThunk =
  createAsyncThunk(
    "dashboards/fetchLargestCostChangesWidgetDataByWidgetId",
    async (widgetId: string, { dispatch, getState }) => {
      const state = getState() as RootState;

      const widget = largestCostChangesWidgetByIdSelector(state, widgetId);

      if (!widget) {
        return;
      }

      await dispatch(
        getWidgetDataByViewIdThunk({
          widgetType: widget.type,
          widgetId: widgetId,
          resourceExplorerViewId: widget.view_id,
          dateTo: widget.date_to,
          dateFrom: widget.date_from,
          frequency: widget.date_granularity,
          grouping: widget.grouping,
          groupValues: widget.group_values,
          dateLabel: widget.date_label,
          sortingBy: widget.sort_by,
          priceDirection: widget.price_direction,
          itemsCount: widget.items_count,
          minCostChange: widget.min_cost_change,
          minPercentageChange: widget.min_percentage_change,
        }),
      );
    },
  );
