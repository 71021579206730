import {
  ProviderType,
  ResourceExplorerPossibleFilter,
} from "../../../../services/cloudchipr.api";
import { DisabledByReasonType } from "../../../common/selectors/billing-status/billingAccountDisabledReasonsByProviderSelector";

interface GetAccountsFilteredByCategoryListArgs {
  provider: ProviderType;
  accounts: { id: string; name: string }[];
  disabled: DisabledByReasonType;
  possAccountFilters?: ResourceExplorerPossibleFilter;
}
export const getAccountsFilteredByCategoryList = ({
  provider,
  accounts,
  disabled,
  possAccountFilters,
}: GetAccountsFilteredByCategoryListArgs) => {
  return accounts?.reduce(
    (result, account) => {
      const { name, id } = account;
      if (disabled?.[id]) {
        return result;
      }

      const isPrefillingAccount = possAccountFilters?.values?.some(
        (possibleAccountItem) => possibleAccountItem?.value === id,
      );

      if (isPrefillingAccount) {
        result.prefillingAccounts.push(id);
      } else {
        result.excludedAccounts.push({
          id,
          provider,
          name: name,
        });
      }

      return result;
    },
    { prefillingAccounts: [], excludedAccounts: [] } as {
      prefillingAccounts: string[];
      excludedAccounts: {
        id: string;
        provider: ProviderType;
        name: string;
      }[];
    },
  );
};
