import { FC, Fragment } from "react";
import { useDidMount } from "rooks";
import { Stack } from "@mui/material";
import { CostByCategoryWidgetContent } from "./components/CostByCategoryWidgetContent";
import { WidgetLoadingState } from "../common/WidgetLoadingState";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { fetchCostByCategoryWidgetDatByWidgetIdThunk } from "../../../../../../store/dashboards/thunks/widgets/cost-by-category/fetchCostByCategoryWidgetDatByWidgetIdThunk";
import { costByCategoryWidgetLoadingSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-by-category/metadata/costByCategoryWidgetLoadingSelector";
import { WidgetCardHeader } from "../common/widget-header/WidgetCardHeader";
import { costByCategoryWidgetTitlePropsSelector } from "../../../../../../store/dashboards/selectors/widgets/cost-by-category/costByCategoryWidgetTitlePropsSelector";
import { useHover } from "../../../../../../utils/hooks/useHover.hook";

interface DashboardCostByCategoryWidgetProps {
  widgetId: string;
}
export const DashboardCostByCategoryWidget: FC<
  DashboardCostByCategoryWidgetProps
> = ({ widgetId }) => {
  const dispatch = useAppDispatch();
  const { ref, hovered } = useHover();

  const widgetTitleProps = useAppSelector((state) =>
    costByCategoryWidgetTitlePropsSelector(state, widgetId),
  );

  const loading = useAppSelector((state) =>
    costByCategoryWidgetLoadingSelector(state, widgetId),
  );

  useDidMount(() => {
    dispatch(fetchCostByCategoryWidgetDatByWidgetIdThunk(widgetId));
  });

  return (
    <Stack ref={ref} flexGrow={1} overflow="auto">
      {loading ? (
        <WidgetLoadingState />
      ) : (
        <Fragment>
          <WidgetCardHeader
            hovered={hovered}
            widgetId={widgetId}
            widgetType="costs_by_category_v2"
            widgetTitleProps={widgetTitleProps}
          />

          <CostByCategoryWidgetContent widgetId={widgetId} />
        </Fragment>
      )}
    </Stack>
  );
};
