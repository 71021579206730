import { FC } from "react";
import { Card, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { UtilizationAndCoverageGaugeChartCardLabel } from "./UtilizationAndCoverageGaugeChartCardLabel";
import { PieChart } from "../../../../../../../../storybook/charts/pie-chart/PieChart";
import { getColorByValue } from "../../data-grid/utils/helpers";

interface UtilizationAndCoverageGaugeChartProps {
  title: string;
  loading?: boolean;
  primary: {
    label: string;
    value?: number | null;
    percent?: number | null;
  };
  secondary: {
    label: string;
    value?: number | null;
  };
}

export const UtilizationAndCoverageGaugeChartCard: FC<
  UtilizationAndCoverageGaugeChartProps
> = ({ title, primary, secondary, loading }) => {
  const percent = primary.percent ?? null;

  const color = getColorByValue(percent);
  const hexColor = colors[color] ?? color;

  return (
    <Card sx={{ p: 2, flexGrow: 1 }} variant="outlined">
      <Stack gap={1}>
        <Typography
          fontWeight="bold"
          variant="subtitle1"
          color="text.secondary"
        >
          {title}
        </Typography>

        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack flexGrow={1} alignItems="center">
            <PieChart
              partial={primary.percent ?? 0}
              total={100}
              colors={{ total: grey[300], partial: hexColor }}
            />
          </Stack>

          <Stack flexGrow={1} spacing={1}>
            <UtilizationAndCoverageGaugeChartCardLabel
              label={primary.label}
              color={hexColor}
              value={primary.value}
              loading={loading}
              percent={primary.percent}
            />

            <UtilizationAndCoverageGaugeChartCardLabel
              label={secondary.label}
              color={grey[300]}
              loading={loading}
              value={secondary.value}
            />
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

const colors: Record<string, string> = {
  "error.light": "#d32f2f",
  "success.light": "#4caf50",
  "warning.main": "#ed6c02",
};
