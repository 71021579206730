import { FC } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { costBreakdownWidgetCostsSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownWidgetCostsSelector";
import { costBreakdownWidgetFrequencySelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownWidgetFrequencySelector";
import { costBreakdownWidgetForecastOptionSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownWidgetForecastOptionSelector";
import { WidgetTotalsSummary } from "../../common/WidgetTotalsSummary";
import { DateLabelNullable } from "../../../../../../../services/cloudchipr.api";

interface CostBreakdownWidgetContentCostsProps {
  widgetId?: string;
  dateLabel?: DateLabelNullable;
}

export const CostBreakdownWidgetContentCosts: FC<
  CostBreakdownWidgetContentCostsProps
> = ({ widgetId, dateLabel }) => {
  const costsData = useAppSelector((state) =>
    costBreakdownWidgetCostsSelector(state, widgetId),
  );

  const frequency = useAppSelector((state) =>
    costBreakdownWidgetFrequencySelector(state, widgetId),
  );

  const forecastOption = useAppSelector((state) =>
    costBreakdownWidgetForecastOptionSelector(state, widgetId),
  );

  if (!costsData) {
    return null;
  }

  return (
    <WidgetTotalsSummary
      frequency={frequency}
      costsData={costsData}
      forecastOption={forecastOption}
      dateLabel={dateLabel}
    />
  );
};
