import { FC } from "react";
import { Stack } from "@mui/material";
import { LargestCostChangesWidgetHeaderCardSourceData } from "./LargestCostChangesWidgetHeaderCardSourceData";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { largestCostChangesWidgetTitlePropsSelector } from "../../../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/largestCostChangesWidgetTitlePropsSelector";
import { WidgetCardHeaderActionRow } from "../../../common/widget-header/WidgetCardHeaderActionRow";
import { widgetREPrevDateDetailsSelector } from "../../../../../../../../store/dashboards/selectors/widgets/common/widget-re-view/WidgetREPrevDateDetailsSelector";

interface LargestCostChangesWidgetCardHeaderProps {
  hovered: boolean;
  widgetId: string;
}

export const LargestCostChangesWidgetCardHeader: FC<
  LargestCostChangesWidgetCardHeaderProps
> = ({ widgetId, hovered }) => {
  const widgetTitleProps = useAppSelector((state) =>
    largestCostChangesWidgetTitlePropsSelector(state, widgetId),
  );

  const prevDateDetails = useAppSelector((state) =>
    widgetREPrevDateDetailsSelector(state, widgetId),
  );

  return (
    <Stack
      pl={2}
      pr={0}
      py={1}
      borderBottom={1}
      sx={{
        borderBottomColor: "grey.300",
        position: "sticky",
        top: 0,
        bgcolor: "white",
        zIndex: 2,
      }}
    >
      <WidgetCardHeaderActionRow
        widgetId={widgetId}
        hovered={hovered}
        widgetType="largest_cost_changes"
        widgetTitleProps={widgetTitleProps}
      />
      {widgetTitleProps && (
        <LargestCostChangesWidgetHeaderCardSourceData
          prevDate={prevDateDetails}
          {...widgetTitleProps}
        />
      )}
    </Stack>
  );
};
