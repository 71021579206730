import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { createResourceExplorerHierarchyViewThunk } from "./createResourceExplorerHierarchyViewThunk";
import { updateResourceExplorerHierarchyThunk } from "./updateResourceExplorerHierarchyThunk";

interface CreateResourceExplorerHierarchyViewByFolderIdThunkArg {
  name: string;
  folderId: string;
}

export const createResourceExplorerHierarchyViewByFolderIdThunk =
  createAsyncThunk(
    "resourceExplorer/createResourceExplorerHierarchyViewByFolderId",
    async (
      { name, folderId }: CreateResourceExplorerHierarchyViewByFolderIdThunkArg,
      { dispatch },
    ) => {
      try {
        const response = await dispatch(
          createResourceExplorerHierarchyViewThunk(name),
        ).unwrap();

        if (!response?.id) {
          return;
        }

        await dispatch(
          updateResourceExplorerHierarchyThunk({
            folderId,
            viewId: response.id,
          }),
        );

        return response;
      } catch (e) {
        // @ts-expect-error TODO: return to this after adding error type
        const errMessage = e?.data?.message || "Something went wrong";
        const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "error",
            onClose: () => closeSnackbar(snackbarId),
          },
        });
      }
    },
  );
