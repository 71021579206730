import { FC } from "react";
import { Stack } from "@mui/material";
import { CostByCategoryWidgetChartViewChart } from "./CostByCategoryWidgetChartViewChart";
import { CostByCategoryWidgetContentCosts } from "./CostByCategoryWidgetContentCosts";
import { useAppSelector } from "../../../../../../../store/hooks";
import { DashboardNotFoundWidget } from "../../common/DashboardNotFoundWidget";
import { DashboardEmptyWidgetState } from "../../common/DashboardEmptyWidgetState";
import { costByCategoryWidgetByIdSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-by-category/costByCategoryWidgetByIdSelector";
import { costByCategoryWidgetNotFoundSelector } from "../../../../../../../store/dashboards/selectors/widgets/cost-by-category/metadata/costByCategoryWidgetNotFoundSelector";

interface CostByCategoryWidgetContentProps {
  widgetId: string;
}
export const CostByCategoryWidgetContent: FC<
  CostByCategoryWidgetContentProps
> = ({ widgetId }) => {
  const widgetDataNotFound = useAppSelector((state) =>
    costByCategoryWidgetNotFoundSelector(state, widgetId),
  );

  const widget = useAppSelector((state) =>
    costByCategoryWidgetByIdSelector(state, widgetId),
  );

  if (widgetDataNotFound) {
    return (
      <DashboardNotFoundWidget
        name={widget?.category_group_key ?? ""}
        label="category"
      />
    );
  }

  if (!widget) {
    return <DashboardEmptyWidgetState infoCards={infoCards} />;
  }

  return (
    <Stack px={2} py={1} gap={1}>
      <CostByCategoryWidgetContentCosts widgetId={widgetId} />

      <CostByCategoryWidgetChartViewChart widgetId={widgetId} />
    </Stack>
  );
};

const infoCards = [
  {
    value: "-",
    title: "Total Cost",
  },
  {
    value: "-",
    title: "Filtered Resources",
  },
];
