import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerViewsDataSelector } from "./resourceExplorerViewsDataSelector";

export const sortedResourceExplorersSelector = createDraftSafeSelector(
  [resourceExplorerViewsDataSelector],
  (resourceExplorerViews) => {
    return [...(resourceExplorerViews ?? [])].sort((a, b) => {
      return a.created_at.localeCompare(b.created_at);
    });
  },
);
