import { viewWithoutFiltersResourceTypesSelector } from "./viewWithoutFiltersResourceTypesSelector";
import { RootState } from "../../../../store";
import { ResourceType } from "../../../../../services/cloudchipr.api";

export const resourceViewIsWithoutFiltersSelector = (
  state: RootState,
  resourceType: ResourceType,
): boolean => {
  const viewWithoutFiltersResourceTypes =
    viewWithoutFiltersResourceTypesSelector(state);

  return !!viewWithoutFiltersResourceTypes[resourceType];
};
