import {
  ResourceExplorer,
  ResourceExplorerFolder,
} from "../../../../../../services/cloudchipr.api";

export const isResourceExplorerFolder = (
  view: ResourceExplorer | ResourceExplorerFolder,
): view is ResourceExplorerFolder => {
  return "views" in view;
};
