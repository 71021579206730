import { ChangeEvent, FC, useCallback } from "react";
import { Stack, TextField } from "@mui/material";
import { DimensionDeleteButton } from "./DimensionDeleteButton";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { setDimensionName } from "../../../../../../../store/dimensions/dimensionsSlice";
import { dimensionNameSelector } from "../../../../../../../store/dimensions/selectors/slice-data/dimensionNameSelector";

export const DimensionName: FC = () => {
  const dispatch = useAppDispatch();
  const dimensionName = useAppSelector(dimensionNameSelector);

  const changeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setDimensionName(event.target.value));
    },
    [dispatch],
  );

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
    >
      <TextField
        autoFocus
        fullWidth
        size="small"
        value={dimensionName}
        type="text"
        sx={{ mt: 0.5 }}
        label="Dimension Name"
        onChange={changeHandler}
      />

      <DimensionDeleteButton />
    </Stack>
  );
};
