import { FC } from "react";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { generateDateLabelByBudgetPeriod } from "./utils/helpers/generateDateLabelByBudgetPeriod";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { getNumberByPercentage } from "../../../../../../../../../../utils/numeral/getPercentage";
import { BudgetPeriod } from "../../../../../../../../../../services/cloudchipr.api";

interface ResourceExplorerBudgetTooltipProps {
  name?: string;
  progress: number;
  amount: number;
  startDate?: string;
  period: BudgetPeriod;
}

export const ResourceExplorerBudgetTooltipContent: FC<
  ResourceExplorerBudgetTooltipProps
> = ({ startDate, period, progress, name, amount }) => {
  const overspend: boolean = progress > 100;
  const dateLabel = startDate
    ? generateDateLabelByBudgetPeriod(startDate, period)
    : period;

  const balanceCost = getNumberByPercentage(100 - progress, amount);
  return (
    <Stack
      sx={{
        p: 1,
        whiteSpace: "nowrap",
      }}
    >
      {name && (
        <Typography variant="caption" color="text.secondary">
          {name}
        </Typography>
      )}
      <Typography variant="caption" textTransform="capitalize">
        {dateLabel} Budget:{" "}
        <Typography variant="caption" fontWeight="bold" component="span">
          {money(amount)}
        </Typography>
      </Typography>
      <Typography variant="caption" mt={0.5}>
        Used:{" "}
        <Typography variant="caption" fontWeight="bold" component="span">
          {money(getNumberByPercentage(progress, amount))}
        </Typography>
      </Typography>
      <Typography variant="caption" mt={0.5}>
        {overspend ? "Overspend: " : "Left: "}
        <Typography
          variant="caption"
          fontWeight="bold"
          component="span"
          color={overspend ? "error.main" : "inherit"}
        >
          {overspend ? money(Math.abs(balanceCost)) : money(balanceCost)}
        </Typography>
      </Typography>
    </Stack>
  );
};
