import { utilizationAndCoverageDrawerTargetIdSelector } from "../options/utilizationAndCoverageDrawerTargetIdSelector";
import { RootState } from "../../../../../store";
import { savingsPlansDataSelector } from "../../data/savingsPlansDataSelector";

export const selectedSavingsPlanUUIdSelector = (state: RootState) => {
  const id = utilizationAndCoverageDrawerTargetIdSelector(state);
  const savingsPlans = savingsPlansDataSelector(state);

  return savingsPlans?.find(
    (savingsPlan) => savingsPlan.provider_unique_id === id,
  )?.id;
};
