import { costBreakdownV2WidgetByIdSelector } from "./costBreakdownV2WidgetByIdSelector";
import { RootState } from "../../../../../store";

export const costBreakdownV2WidgetViewIdByWidgetIdSelector = (
  state: RootState,
  widgetId?: string,
): string | undefined => {
  if (!widgetId) {
    return;
  }

  const widget = costBreakdownV2WidgetByIdSelector(state, widgetId);

  return widget?.view_id;
};
