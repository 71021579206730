import { FC, Fragment } from "react";
import { Typography } from "@mui/material";
import { CommandSnippet } from "../../../../../../../../../common/connectors/components/CommandSnippet";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { currentAccountIdSelector } from "../../../../../../../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import { currentGCPAccountServiceAccountSelector } from "../../../../../../../../../../../store/account/selectors/current-account/currentGCPAccountServiceAccountSelector";
import { useGetUsersMeProvidersGcpAccountsByAccountIdMissingRolesQuery } from "../../../../../../../../../../../services/cloudchipr.api";
import { currentGCPAccountProjectIdSelector } from "../../../../../../../../../../../store/account/selectors/current-account/currentGCPAccountProjectIdSelector";
import { MissingPermissionsRecheck } from "../../../../../common/MissingPermissionsRecheck";

export const GcpCliTab: FC = () => {
  const currentAccountId = useAppSelector(currentAccountIdSelector);
  const serviceAccount = useAppSelector(
    currentGCPAccountServiceAccountSelector,
  );
  const projectId = useAppSelector(currentGCPAccountProjectIdSelector);

  const { data, refetch } =
    useGetUsersMeProvidersGcpAccountsByAccountIdMissingRolesQuery(
      { accountId: currentAccountId || "" },
      { skip: !currentAccountId },
    );

  if (!data?.length) {
    return null;
  }

  return (
    <Fragment>
      <Typography variant="body1">
        To assign role, please run the following command in your terminal.
      </Typography>

      {data?.map((role) => (
        <CommandSnippet
          key={role.name}
          code={`gcloud projects add-iam-policy-binding ${projectId} --member serviceAccount:${serviceAccount} --role "${role.name}"`}
        >
          gcloud projects add-iam-policy-binding{" "}
          <Typography
            fontFamily="monospace"
            color="warning.light"
            component="span"
          >
            {projectId}
          </Typography>{" "}
          --member serviceAccount:{serviceAccount} --role "{role.name}"
        </CommandSnippet>
      ))}

      <MissingPermissionsRecheck refetch={refetch} />
    </Fragment>
  );
};
