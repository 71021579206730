import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceTypeDataResourcesSelector } from "./resourceTypeDataResourcesSelector";
import { ResourceDataType } from "../../utils/types/types";

export const resourceTypeDataWithChildrenSelector = createDraftSafeSelector(
  [resourceTypeDataResourcesSelector],
  (data) => {
    const result: ResourceDataType[] = [];

    const processResource = (data: ResourceDataType) => {
      if (data?.resource) {
        result.push(data);
      }

      if (data?.child_resource?.resources) {
        data.child_resource.resources.forEach((child: ResourceDataType) => {
          processResource(child);
        });
      }
    };

    data?.forEach((item) => {
      processResource(item);
    });

    return result;
  },
);
