import { createDraftSafeSelector } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  ProviderBillingStatus,
} from "../../../../services/cloudchipr.api";

const billingStatusSelector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentProvidersByProviderBillingStatus.select(
    { provider: "aws" },
  );

export const awsBillingStatusSelector = createDraftSafeSelector(
  [billingStatusSelector],
  (billingStatus): ProviderBillingStatus | undefined => {
    return billingStatus?.data;
  },
);
