import { organizationsForCommitmentsSelector } from "./organizationsForCommitmentsSelector";
import { RootState } from "../../../store";
import { commitmentsCurrentOrgIdSelector } from "../commitmentsCurrentOrgIdSelector";
import { EmbeddedAccount } from "../../../../services/cloudchipr.api";

export const organizationAccountsByCurrentOrgIdSelector = (
  state: RootState,
): EmbeddedAccount[] | undefined => {
  const organizations = organizationsForCommitmentsSelector(state);
  const currentOrgId = commitmentsCurrentOrgIdSelector(state);

  const currentOrg = organizations?.find(
    (org) => org.organization_id === currentOrgId,
  );

  return currentOrg?.accounts;
};
