import { MetricType } from "./types";

export const metricsUnit: Record<MetricType, string> = {
  compute: "%",
  database: "",
  storage: "",
  memory_used: "%",
  memory_available: "%",
};

export const metricsLabel: Record<MetricType, string> = {
  compute: "Max CPU",
  database: "Connections",
  storage: "Requests",
  memory_used: "Memory Max",
  memory_available: "Unused Memory",
};

export const metricCellFrequencyValues: Record<string, "hourly" | "daily"> = {
  "24h": "hourly",
  "7d": "daily",
  "30d": "daily",
};
