import { SavingsPlanCoverageDataWithId } from "../../../../components/pages/commtiments/utilization-and-coverage/utils/types";
import {
  commitmentTypesLabels,
  savingsPlansResourceTypeNames,
} from "../../../../components/pages/commtiments/utilization-and-coverage/utils/constants/labels";
import {
  SavingsPlanCoverageGrouping,
  SavingsPlanResourceType,
} from "../../../../services/cloudchipr.api";
import { DataViewTab } from "../types/types";
import { commitmentsDrawerDynamicHeaderCellTitles } from "../../../../components/pages/commtiments/utilization-and-coverage/utils/constants/commitmentsDrawerDynamicHeaderCellTitles";

interface CommitmentsCSVDataGeneratorArgs {
  viewTab: DataViewTab;
  grouping: SavingsPlanCoverageGrouping;
  data?: SavingsPlanCoverageDataWithId[];
}
export const commitmentsCSVDataGenerator = ({
  viewTab,
  grouping,
  data,
}: CommitmentsCSVDataGeneratorArgs) => {
  return data?.map((item) => {
    const csv: Record<string, string | number | null | undefined> = {};

    if (item.resource_type) {
      const rt = item.resource_type?.resource_type as SavingsPlanResourceType;

      csv["Resource Type"] = rt ? savingsPlansResourceTypeNames[rt] : "";
      csv["Instance Family"] = item.resource_type?.instance_family;
      csv["Region"] = item.resource_type?.region;
    }

    if (item.account) {
      csv["Account"] = item.account?.name;
      csv["Account ID"] = item.account?.provider_account_id;
    }

    if (item.savings_plan) {
      csv["Commitment"] = item.savings_plan?.provider_id;
      csv["Commitment Type"] = commitmentTypesLabels[item.savings_plan?.type];
    }

    if (!item.savings_plan) {
      const coverageTitle =
        commitmentsDrawerDynamicHeaderCellTitles[viewTab][grouping]
          .coveredUsageCell;
      csv[coverageTitle] = item.covered_usage;
      if (viewTab === "coverage") {
        csv["On-Demand Spend"] = item.uncovered_usage;
      }
    }

    csv[
      commitmentsDrawerDynamicHeaderCellTitles[viewTab][grouping].coverageCell
    ] = `${item.coverage}%`;

    return csv;
  });
};
