import { FC, useCallback } from "react";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { removeDashboardByIdThunk } from "../../../../../../store/dashboards/thunks/dashboard/removeDashboardByIdThunk";
import { removeDashboardByIdLoadingSelector } from "../../../../../../store/dashboards/selectors/dashboard/loadings/removeDashboardByIdLoadingSelector";
import { sortedDashboardsSelector } from "../../../../../../store/dashboards/selectors/dashboard/sortedDashboardsSelector";
import { getNextDashboardId } from "../utils/getNextDashboardId";
import { DeleteConfirmationDialog } from "../../../../../common/modals/DeleteConfirmationDialog";

interface DashboardDeleteActionItemDialogProps {
  id: string;
  open: boolean;
  name: string;
  closeDialog(): void;
}

export const DashboardDeleteActionItemDialog: FC<
  DashboardDeleteActionItemDialogProps
> = ({ closeDialog, id, name, open }) => {
  const { dashboardId: activeDashboardId } = useParams<{
    dashboardId: string;
  }>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const removeDashboardLoading = useAppSelector(
    removeDashboardByIdLoadingSelector,
  );

  const sortedDashboards = useAppSelector(sortedDashboardsSelector);

  const removeDashboardClickHandler = useCallback(async () => {
    await dispatch(
      removeDashboardByIdThunk({
        dashboardId: id,
      }),
    );
    closeDialog();
    if (activeDashboardId === id) {
      const nextDashboardId = getNextDashboardId(id, sortedDashboards);
      navigate({ pathname: `/dashboards/${nextDashboardId}` });
    }
  }, [
    dispatch,
    navigate,
    id,
    activeDashboardId,
    closeDialog,
    sortedDashboards,
  ]);

  return (
    <DeleteConfirmationDialog
      open={open}
      onClose={closeDialog}
      onDelete={removeDashboardClickHandler}
      loading={removeDashboardLoading}
      title="Delete Dashboard?"
      content={
        <Typography>
          Please confirm if you want to delete
          <Typography
            component="span"
            fontWeight="bold"
            sx={{
              wordWrap: "break-word",
            }}
          >
            {" "}
            {name}{" "}
          </Typography>
          dashboard.
        </Typography>
      }
    />
  );
};
