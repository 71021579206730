import { useEffect, useState } from "react";
import moment from "moment";
import { usePostUsersMeSchedulesNextRunDateMutation } from "../../../../../../services/cloudchipr.api";
import { getAutomationPreviewFormatDate } from "../../../common/utils/helpers/common";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";

export function useWorkflowsNextRunDates({
  startDate,
  cron,
  timeZone,
}: {
  timeZone: string;
  startDate: string;
  cron: string | null;
}) {
  const startDateTime = startDate || moment().format() || "";

  const [nextRunDate, setNextRunDate] = useState(
    getAutomationPreviewFormatDate(startDateTime || ""),
  );

  const [getSchedulesDates] = usePostUsersMeSchedulesNextRunDateMutation();

  useEffect(() => {
    if (!cron) return;

    getSchedulesDates({
      body: {
        cron,
        start_date_time: startDate ? formatDate(startDate) : null,
        time_zone: timeZone,
      },
    })
      .unwrap()
      .then(({ next_run_date_time }) => {
        setNextRunDate(getAutomationPreviewFormatDate(next_run_date_time));
      });
  }, [getSchedulesDates, startDate, timeZone, cron]);

  return nextRunDate;
}
