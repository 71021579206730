import { costBreakdownWidgetByIdSelector } from "./costBreakdownWidgetByIdSelector";
import { costBreakdownWidgetNotFoundSelector } from "./costBreakdownWidgetNotFoundSelector";
import { RootState } from "../../../../store";
import { WidgetTitleProps } from "../../../../../components/pages/dashboard/components/widgets/common/widget-header/source-and-name/WidgetSourceInfo";
import { resourceExplorerViewNameByViewIdSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";
import { resourceExplorerCloudProvidersByViewIdSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerCloudProvidersByViewIdSelector";
import { getResourcesExplorerGroupingLabel } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { objectToUrl } from "../../../../../components/utils/helpers/dataToUrlToDataConvertors";

export const costBreakdownWidgetTitlePropsSelector = (
  state: RootState,
  widgetId: string,
): WidgetTitleProps | null => {
  const widget = costBreakdownWidgetByIdSelector(state, widgetId);

  if (!widget) {
    return null;
  }

  const viewName = resourceExplorerViewNameByViewIdSelector(
    state,
    widget.view_id,
  );

  const viewProviders =
    resourceExplorerCloudProvidersByViewIdSelector(state, widget.view_id) ?? [];

  const grouping = getResourcesExplorerGroupingLabel(
    viewProviders,
    widget.grouping,
    true,
  );
  const path = objectToUrl([
    {
      groupedBy: widget.grouping,
      groupValues: widget.group_values,
      forecastOption: widget.forecast_option,
      dates: widget.dates,
      dateGranularity: widget.date_granularity,
      chartType: widget.chart_type,
      includedFilters: {},
    },
  ]);

  const viewNotFound = costBreakdownWidgetNotFoundSelector(state, widgetId);

  return {
    grouping,
    widgetName: widget.name,
    dates: {
      label: widget?.date_label,
      from: widget?.date_from,
      to: widget?.date_to,
    },
    sourceData: viewNotFound
      ? undefined
      : {
          icon: "pie",
          title: viewName ?? "",
          menu: [
            {
              title: "Open in Resource Explorer",
              url: `/resource-explorer/${widget.view_id}?path=${path}`,
            },
          ],
        },
  };
};
