import { FC, Fragment, memo, useCallback, useMemo } from "react";
import { Box, CircularProgress, Stack, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useDebounce } from "rooks";
import { ResourceExplorerCardDataGridToolbar } from "./components/ResourceExplorerCardDataGridToolbar";
import { ResourceExplorerTotalViewFooter } from "./components/footer/ResourceExplorerTotalViewFooter";
import { ResourceExplorerGranularViewDataGrid } from "./components/data-grid/ResourceExplorerGranularViewDataGrid";
import { ResourceExplorerTotalViewDataGrid } from "./components/data-grid/ResourceExplorerTotalViewDataGrid";
import { ResourceExplorerGranularViewFooter } from "./components/footer/ResourceExplorerGranularViewFooter";
import { useAppSelector } from "../../../../../../../store/hooks";
import { resourceExplorerWidgetDataLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/loading/resourceExplorerWidgetDataLoadingSelector";
import { useMultiTypeChartContext } from "../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import {
  resourceExplorerChartDataOthersKey,
  resourceExplorerChartKeyGenerator,
} from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/chart/resourceExplorerChartDataSelector";
import { ToolbarConfig } from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import { resourceExplorerGranularDataLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/loading/resourceExplorerGranularDataLoadingSelector";
import { resourceExplorerDataGridViewSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerDataGridViewSelector";
import { resourceExplorerDataGridAggregatedDataSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridAggregatedDataSelector";

interface ResourceExplorerTableProps {
  fullHeight: boolean;
}

export const ResourceExplorerTable: FC<ResourceExplorerTableProps> = memo(
  ({ fullHeight }) => {
    const { hover, colors } = useMultiTypeChartContext();

    const dataGridView = useAppSelector(resourceExplorerDataGridViewSelector);
    const aggregatedData = useAppSelector(
      resourceExplorerDataGridAggregatedDataSelector,
    );
    const totalViewLoading = useAppSelector(
      resourceExplorerWidgetDataLoadingSelector,
    );
    const granularViewLoading = useAppSelector(
      resourceExplorerGranularDataLoadingSelector,
    );
    const loading = totalViewLoading || granularViewLoading;

    const tableStyles = useMemo(() => {
      return {
        ...styles,
        tableContainer: {
          ...styles.tableContainer,
          maxHeight: fullHeight ? "max(calc(100vh - 520px), 500px)" : 500,
          borderBottomLeftRadius: !aggregatedData ? 8 : 0,
          borderBottomRightRadius: !aggregatedData ? 8 : 0,
        },
      };
    }, [fullHeight, aggregatedData]);

    const toolbar: ToolbarConfig = useMemo(() => {
      return {
        renderToolbar: (props) => {
          return <ResourceExplorerCardDataGridToolbar {...props} />;
        },
      };
    }, []);

    const setHighlighted = useDebounce(hover.setKey, 400);

    const generateRowProps = useCallback(
      (row: any) => {
        const key = resourceExplorerChartKeyGenerator(
          row.original.field,
          row.original.label,
        );

        const keyInChart = colors[key]
          ? key
          : resourceExplorerChartDataOthersKey;

        return {
          onMouseEnter: () => setHighlighted(keyInChart),
          onMouseLeave: () => setHighlighted(""),
        };
      },
      [setHighlighted, colors],
    );

    return (
      <Box position="relative" zIndex={1}>
        {loading && (
          <Stack
            width="100%"
            height="100%"
            zIndex="appBar"
            fontWeight="bold"
            position="absolute"
            alignItems="center"
            justifyContent="center"
            bgcolor="rgba(255, 255, 255, 0.5)"
          >
            <CircularProgress size={24} color="info" />
          </Stack>
        )}

        {dataGridView === "total" && (
          <Fragment>
            <ResourceExplorerTotalViewDataGrid
              styles={tableStyles}
              toolbar={toolbar}
              getRowProps={generateRowProps}
            />
            <ResourceExplorerTotalViewFooter />
          </Fragment>
        )}

        {dataGridView === "granular" && (
          <Fragment>
            <ResourceExplorerGranularViewDataGrid
              styles={tableStyles}
              toolbar={toolbar}
              getRowProps={generateRowProps}
            />
            <ResourceExplorerGranularViewFooter />
          </Fragment>
        )}
      </Box>
    );
  },
);

const styles = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
    "& td": {
      py: 0.5,
      pl: 2,
      borderBottom: `1px solid ${grey[100]}`,
    },
    "& tr:hover td": {
      bgcolor: grey[100],
    },
  },
  tableRow: {
    background: "white",
  },
  tableHeaderRow: {
    top: 0,
    "& th": { p: 2, verticalAlign: "inherit" },
    position: "sticky",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 1,
    bgcolor: "grey.50",
  },

  tableContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    border: 1,
    borderColor: grey[300],
    maxHeight: 500,
  },
};
