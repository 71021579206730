import { FC } from "react";
import { Button, Stack, Tooltip, Typography } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface CostAllocationTagKeyDropdownFooterProps {
  onClick(): void;
}

export const CostAllocationTagKeyDropdownFooter: FC<
  CostAllocationTagKeyDropdownFooterProps
> = ({ onClick }) => {
  return (
    <Stack
      p={1}
      direction="row"
      alignItems="center"
      justifyContent="end"
      borderTop={1}
      spacing={1}
      borderColor="grey.300"
    >
      <Button
        size="small"
        variant="outlined"
        onClick={onClick}
        startIcon={<SettingsOutlinedIcon fontSize="small" />}
      >
        Enable Tags
      </Button>
      <Tooltip
        arrow
        placement="top"
        title={
          <Typography variant="inherit">
            Cloudchipr supports all tags including the ones which are not
            enabled for Cost Allocation. Please enable the Tags that you need
            for filtering billing data. <br />
            <Typography component="span" variant="inherit" fontStyle="italic">
              *It might take up to 12 hours to appear.
            </Typography>
          </Typography>
        }
      >
        <InfoOutlinedIcon color="action" fontSize="small" />
      </Tooltip>
    </Stack>
  );
};
