import {
  MouseEvent,
  Fragment,
  useCallback,
  useState,
  useEffect,
  useMemo,
  FC,
} from "react";
import { Divider, Popover, PopoverOrigin } from "@mui/material";
import { ResourceExplorerCostAllocationTagFilterItems } from "./components/ResourceExplorerCostAllocationTagFilterItems";
import { ResourceExplorerCostAllocationTagHeader } from "./components/ResourceExplorerCostAllocationTagHeader";
import { costAllocationTagLabel } from "./utils/constants";
import { CostAllocationTagsDrawer } from "./components/tags-enabling/CostAllocationTagsDrawer";
import {
  ResourceExplorerFilterItem,
  ResourceExplorerTag,
  ProviderType,
} from "../../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { costAllocationTagFilterChangeThunk } from "../../../../../../../../../../store/resource-explorer/thunks/filters/costAllocationTagFilterChangeThunk";
import { resourceExplorerFilterByKeyAndProviderSelector } from "../../../../../../../../../../store/resource-explorer/selectors/filters/resourceExplorerFilterByKeyAndProviderSelector";
import { isAllCostAllocationTagsSelectedSelector } from "../../../../../../../../../../store/resource-explorer/selectors/filters/checkers/isAllCostAllocationTagsSelectedSelector";
import { FilterButton } from "../../../../FilterButton";
import { resourceExplorerIsDefaultSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerIsDefaultSelector";
import { filterChangedByKeySelector } from "../../../../../../../../../../store/resource-explorer/selectors/filters/checkers/filterChangedByKeySelector";
import { useDialog } from "../../../../../../../../../../utils/hooks/useDialog.hook";

interface ResourceExplorerCostAllocationTagFilterProps {
  viewId: string;
  provider: ProviderType;
}
export const ResourceExplorerCostAllocationTagFilter: FC<
  ResourceExplorerCostAllocationTagFilterProps
> = ({ viewId, provider }) => {
  const dispatch = useAppDispatch();
  const { open, openDialog, closeDialog } = useDialog();

  const isViewDefault = useAppSelector(resourceExplorerIsDefaultSelector);
  const tagFilter = useAppSelector((state) =>
    resourceExplorerFilterByKeyAndProviderSelector(
      state,
      "cost_allocation_tag",
      provider,
    ),
  );

  const isFilterChanged = useAppSelector((state) =>
    filterChangedByKeySelector(state, {
      viewId,
      key: "cost_allocation_tag",
      provider,
    }),
  );
  const [filter, setFilter] = useState<ResourceExplorerFilterItem | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isAllCostAllocationTagsSelected = useAppSelector((state) =>
    isAllCostAllocationTagsSelectedSelector(state, filter, provider),
  );

  const filterButtonVariant = useMemo(() => {
    if (!filter?.value) {
      return "outlined";
    }

    const tagExistingFilterCount = (
      filter?.value as ResourceExplorerTag[]
    ).filter((item) => !!item?.tag_key).length;
    if (tagExistingFilterCount > 0) {
      return "contained";
    }

    return "outlined";
  }, [filter]);

  const filterButtonCount = useMemo(() => {
    if (!filter?.value) {
      return "";
    }

    if (isAllCostAllocationTagsSelected) {
      return "(All)";
    }

    const tagExistingFilterCount = (
      filter?.value as ResourceExplorerTag[]
    ).filter((item) => !!item?.tag_key).length;
    return tagExistingFilterCount > 0 ? `(${tagExistingFilterCount})` : "";
  }, [filter, isAllCostAllocationTagsSelected]);

  const selectClickHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );
  const popoverCloseHandler = useCallback(() => {
    if (filter) {
      dispatch(
        costAllocationTagFilterChangeThunk({
          filter,
          provider,
        }),
      );
    }
    setAnchorEl(null);
  }, [dispatch, filter, provider]);

  const drawerOpenHandler = useCallback(() => {
    openDialog();
    popoverCloseHandler();
  }, [popoverCloseHandler, openDialog]);

  useEffect(() => {
    if (tagFilter) {
      setFilter(tagFilter);
    }
  }, [setFilter, tagFilter]);

  return (
    <Fragment>
      <FilterButton
        title={costAllocationTagLabel?.[provider]?.long ?? ""}
        variant={isFilterChanged ? "contained" : filterButtonVariant}
        onClick={selectClickHandler}
        color={!isViewDefault && isFilterChanged ? "warning" : undefined}
        count={filterButtonCount}
      />

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={popoverCloseHandler}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={paperProps}
      >
        <Fragment>
          <ResourceExplorerCostAllocationTagHeader
            onCloseIconClick={popoverCloseHandler}
            title={costAllocationTagLabel?.[provider]?.long ?? ""}
          />

          <Divider />

          <ResourceExplorerCostAllocationTagFilterItems
            filter={filter}
            setFilter={setFilter}
            provider={provider}
            onTagsEnable={drawerOpenHandler}
          />
        </Fragment>
      </Popover>

      <CostAllocationTagsDrawer
        open={open}
        provider={provider}
        onClose={closeDialog}
      />
    </Fragment>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "center",
};
const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "center",
};
const paperProps = {
  sx: { width: 580, maxHeight: 500 },
};
