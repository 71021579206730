import { useCallback } from "react";
import { SortingState } from "@tanstack/table-core";
import { useAppSelector } from "../../../../../store/hooks";
import { currentAccountIdSelector } from "../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import {
  generateStorageKey,
  useLocalStorageHook,
} from "../../../../../utils/hooks/useLocalStorage.hook";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { ColumnSetupType } from "../../../../../storybook/data-grid/utils/types/types";
import { wrapWithParentKey } from "../../components/resource-card/resource-card-content/resource-card-data/resource-card-grid/resouce-card-data-grid/utils/nf-tmp-helpers";

export function useColumnsSorting(
  resourceType: ResourceType,
  columnsSetup: ColumnSetupType<any>[],
) {
  const { getItem, setItem } = useLocalStorageHook();

  const accountId = useAppSelector(currentAccountIdSelector) || "";
  const key = generateStorageKey({
    accountId,
    resourceType,
    identifier: "tableConfig",
  });

  const config = getItem(key);
  let initialSorting = config?.sorting || getDefaultSort(columnsSetup);

  const isSortingExist = columnsSetup.some((column) => {
    const id = initialSorting?.[0]?.id;

    if (typeof column.accessorKey === "string") {
      return wrapWithParentKey(column.accessorKey) === id;
    }

    return column.accessorKey === id;
  });

  if (!isSortingExist) {
    initialSorting = getDefaultSort(columnsSetup);
  }

  const setSorting = useCallback(
    (sorting: SortingState) => {
      setItem(key, { ...config, sorting });
    },
    [key, setItem, config],
  );

  return {
    sorting: initialSorting as { id: string; desc: boolean }[],
    setSorting,
  };
}

const getDefaultSort = (columnsSetup: ColumnSetupType<any>[]) => {
  const pricePerMonthColumn = columnsSetup.find(
    (column) => column.accessorKey === "price_per_month",
  );

  if (pricePerMonthColumn) {
    return [
      {
        id: "price_per_month",
        desc: true,
      },
    ];
  }

  const firstColumn = columnsSetup.find((column) => column.accessorKey);

  return [
    {
      id: firstColumn?.accessorKey,
      desc: true,
    },
  ];
};
