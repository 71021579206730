import {
  FC,
  Fragment,
  MutableRefObject,
  ReactElement,
  useCallback,
  useRef,
} from "react";
import { Button, Slide, SlideProps, Typography } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { FormikHelpers } from "formik";
import { FilterOperators, FiltersInitialValues } from "../utils/types/types";

interface SliderProps {
  when: boolean;
  dir: SlideProps["direction"];
  parentRef: MutableRefObject<null>;
  children: ReactElement<any, any>;
}

const Slider: FC<SliderProps> = ({ children, when, dir, parentRef }) => {
  if (!when) {
    return null;
  }

  return (
    <Slide
      direction={dir}
      in={when}
      mountOnEnter
      unmountOnExit
      container={parentRef.current}
    >
      {children}
    </Slide>
  );
};

interface FilterDialogListItemOperatorProps {
  index: number;
  indexOfOperator: number;
  operator: FilterOperators[0];
  setValues: FormikHelpers<FiltersInitialValues>["setValues"];
}

export const FilterDialogListItemOperator: FC<
  FilterDialogListItemOperatorProps
> = ({ index, operator, setValues, indexOfOperator }) => {
  const containerRef = useRef(null);
  const slide = operator === "OR";

  const operatorChangeHandler = useCallback(() => {
    setValues((values) => {
      const operators = [...values.operators];
      const currentOperator = operators[indexOfOperator];

      operators[indexOfOperator] = currentOperator === "OR" ? "AND" : "OR";
      return { ...values, operators };
    });
  }, [setValues, indexOfOperator]);

  if (index === 0) {
    return (
      <Typography variant="body1" color="text.secondary" minWidth={65}>
        Where
      </Typography>
    );
  }

  return (
    <Button
      size="small"
      variant="outlined"
      sx={{ overflow: "hidden", "& .MuiButton-endIcon": { ml: 0.5 } }}
      disabled={index !== 1}
      onClick={operatorChangeHandler}
      ref={containerRef}
      endIcon={
        index === 1 ? (
          <Fragment>
            <Slider parentRef={containerRef} when={slide} dir="up">
              <UnfoldMoreIcon />
            </Slider>
            <Slider parentRef={containerRef} when={!slide} dir="down">
              <UnfoldMoreIcon />
            </Slider>
          </Fragment>
        ) : undefined
      }
    >
      <Fragment>
        <Slider parentRef={containerRef} when={slide} dir="up">
          <span>{operator}</span>
        </Slider>
        <Slider parentRef={containerRef} when={!slide} dir="down">
          <span>{operator}</span>
        </Slider>
      </Fragment>
    </Button>
  );
};
